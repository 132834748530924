import React, { Component, useEffect, useState, useRef } from "react";
import CoverNav from "./CoverNav";
import avatar from "./Assets/avatar.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  HashRouter as Router,
  Route,
  Link,
  Navigate,
  useNavigate,
  DefaultRoute
} from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commonConfig } from '../config.js';
import axios from "axios";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import contact_icon from './Assets/icons/contactblack.png';
import mail_icon from './Assets/icons/mailblack.png';
import location_icon from './Assets/icons/locationblack.png';


function CoverStep4() {

  const [stepno, setStepno] = useState(3);
  const [page, setPage] = useState(false);
  const [isEdit, setIsEdit] = useState(0);
  const [resumeData, setResumeData] = useState([]);
  const [coverData, setCoverData] = useState([]);
  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const coverResumeId = localStorage.getItem('coverResumeId');
  const cover_id = localStorage.getItem('cover_id');



  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    import('./Css/CoverStep.scss');

    getEditdata();
    getResumedata();

    // setTimeout(() => {
    //   if (divRef.current) {
    //     const inputEvent = new InputEvent('input', {
    //       bubbles: true,
    //       cancelable: true,
    //     });
    //     divRef.current.dispatchEvent(inputEvent);
    //   }
    // }, 500);

    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const getEditdata = () => {
    if (cover_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "cover/finalize",
        data: { cover_id: cover_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        setCoverData(result);
        if(result.step == 4){
          setStepno(result.step);
        }else{
          setStepno(result.step + 1);
        }
        setIsEdit(1);

      }).catch(err => {

      });
    }
  }

  const getResumedata = () =>{
    if (coverResumeId) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "finalize",
				data: { resume_id: coverResumeId },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
        setResumeData(result.contact[0]);
        

			}).catch(err => {

			});
		}
  }


  const handleNext = () => {

    setPage(false);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "cover/Step3",
      data: '',
      headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + commonConfig.API_TOKEN, "user_id": userId, "step": stepno },
    }).then(res => {
      var decodedData = res.data;
      var status = res.status;
      if (status) {
        setPage(true);
        prompt(decodedData.data);
        // navigate('/coverStep2');
      }
    }).catch(err => {

    });
  };

  const printDocument = () => {
    const data = {
      htmlContent: divRef.current.innerHTML,
      cover_id: cover_id
    };
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "generate-coverpdf",
      data: data,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      const fileUrl = commonConfig.COVERPDF_URL + cover_id + ".pdf";
      const link = document.createElement('a');
      link.href = fileUrl;
      link.target = '_blank';
      link.download = cover_id+'.pdf'; // Specify the desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("Saved Successfully!!!");

    }).catch(err => {

    });
  };



  return (

    <div className="step1page">
      {page ? (
        <div>
          <ToastContainer position="top-center" theme="dark" />
          <main id="app" role="main">
            <section className="page-wrapper">
              <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
                <CoverNav value={stepno} />
                <div className="pale_blue_bg">
                  <div className="col-md-12">
                    <div className="col-md-8" style={{padding:'20px'}} ref={divRef}>
                      <div id="pdfContainer" contentEditable="true" className="coverletter shadow w-80 m-auto w-100" >
                        <div className="col-md-12 mt-50">
                          <div className="col-md-8">
                            <div className="f-32 bold">{resumeData.f_name} {resumeData.l_name}</div>
                            {/* <div className="f-20">{coverData.job_applied_to}</div> */}
                          </div>
                          <div className="col-md-4">
                            <div>
                              <div className="f-12" style={{marginTop:'10px'}}><img className="temp_icons" src={location_icon} /> {resumeData.city}</div>
                              <div className="f-12" style={{marginTop:'10px'}}><img className="temp_icons" src={contact_icon} /> {resumeData.mobile}</div>
                              <div className="f-12" style={{marginTop:'10px'}}><img className="temp_icons" src={mail_icon} /> {resumeData.email}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-50 mt-100">
                          <div className="col-md-8">
                            <div className="f-12 bold">To:</div>
                            <div className="f-12">{coverData.hr_name}</div>
                            <div className="f-12">{coverData.hr_email}</div>
                            <div className="f-12">{coverData.location}</div>
                          </div>
                          <div className="col-md-4">
                            <div>
                              <div className="f-12 bold">Date:</div>
                              <div className="f-12">{new Date(coverData.updated_at).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 mt-50 mt-100">
                          <div className="col-md-12">
                            <b>Sub:</b> Job application for the post of {coverData.job_applied_to}
                          </div>
                        </div>
                        <div className="col-md-12 mt-50">
                          <div className="col-md-12" style={{paddingBottom:'30px'}}>
                            <div dangerouslySetInnerHTML={{ __html: coverData?.GPTresp?.replace(/\n/g, '<br>')}} />
                          </div>
                        </div>
                        <div className="col-md-12 mt-50">
                          <div className="col-md-12" style={{paddingBottom:'30px'}}>
                            <div style={{paddingBottom:'20px'}}>Thanking you </div>

                            <div style={{paddingBottom:'5px'}}>Yours sincerely </div>

                            <div className="bold">{resumeData.f_name} {resumeData.l_name}</div>
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className="col-md-4 center">
                      <button id="btnBack" type="button" onClick={printDocument} className="btn-mangaii mt-50" >
                          Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Loading content="" />
      )}
    </div>
  );
}

export default CoverStep4;
