import React, { Component } from "react";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import './Css/Privacy.scss';
import Footer from "./Footer";
import MainNav from "./MainNav";
function Privacy() {
    return (
        <div className="privacy">
            <MainNav />
            <div>
<p className="p_privacy" >          
<h4 className="blue" style={{textAlign:'center'}}>Privacy Policy</h4><br />
<p>For the purpose of this document GOjobs.ai includes the Website <a href="http://www.GOjobs.ai">www.GOjobs.ai</a>, Companies and Organizations: G O BPO Services Pvt Ltd, Golden Opportunities Pvt Ltd, Yellow Co, Rohin Chawla HUF and various other websites owned and/or managed by them. The names of these entities or any reference to one can be interchanged in this document and will still refer and be considered as any or all of these entities. It also includes parents, directors, subsidiaries, Affiliate organizations, officers, employees, affiliates, agents, vendors, contractors, interns, suppliers, service providers, agents or licensors.</p>
<p>&nbsp;</p>
<p>This Privacy Policy describes how GOjobs.ai collects, uses, shares, stores and secures the personal information you provide. It also describes your choices regarding use, access and correction of your personal information. This privacy policy should be seen and read along with the terms and conditions of this website which is published on this website.</p>
<p>&nbsp;</p>
<p>Capitalized terms defined in this Privacy Policy shall have no other meaning but set forward in this section. The following terminology is applied to these Terms of Use and Privacy Policy: &ldquo;Client&rdquo;, &ldquo;User&rdquo;, &ldquo;You&rdquo; and &ldquo;Your&rdquo; refers to you, the person accessing this Website and accepting these Terms of Use. &ldquo;We&rdquo;, &ldquo;Us&rdquo; and &ldquo;Ourselves&rdquo; refers to Website. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p>
<p>&nbsp;</p>
<p>Your access to and use of Website are conditioned on your full acceptance and compliance with the Privacy Policy and this Website Terms and Conditions, which are published at Website and which are incorporated herein by reference (&ldquo;Privacy Policy&rdquo;). These Terms of Use and Privacy Policy are applied to all visitors, users and others who access or use this Website.</p>
<p>&nbsp;</p>
<h4>1.&nbsp;&nbsp; What information we collect</h4>
<p>&ldquo;Personal identifiable information" (PII) - means any information relating to an identified or identifiable natural person including common identifiers such as a name, an identification number, location data, an online identifier or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person and any other information that is so categorized by applicable laws. We collect information about you and/or your usage to provide better services and offerings. The Personal Information that we collect, and how we collect it, depends upon how you interact with us. We collect the following categories of Personal Information in the following ways:</p>
<ul>
<li>All information as entered in your resume.</li>
<li>Name, email address, password, country, city, contact number and company/organization that you are associated with, when the you sign up for alerts on the Platform;</li>
<li>Information that one would usually include in a resume, including name, contact details including e-mail address and mobile number, work experience, educational qualifications, data relating to your current and past remuneration or salary, a copy of your resume, etc. when you register on the Platform;</li>
<li>Information about the services that you use and how you use them, including log information and location information, when you are a user of the services through the Platform;</li>
<li>We may collect your Personal Information such as name, age, contact details, preferences, etc. through surveys and forms, when you choose to participate in these surveys etc.;</li>
<li>We may also collect information relating to your caste and information about whether you are eligible for any affirmative action programmes or policies, if you opt to provide such information;</li>
<li>When you communicate with GOjobs.ai or use the Platform to communicate with other Users (such as partners), we collect information about your communication and any information you choose to provide;</li>
<li>When you visit the Platform, we use cookies to automatically collect, store and use technical information about your system and interaction with our Platform;</li>
<li>when you use a device to access the Platform, GOjobs.ai may collect technical or other related information from the device as well as the device location;</li>
<li>We also gather certain information about your use of our site, such as what areas you visit and what services you access. Moreover, there is information about your computer hardware and software that is collected by GOjobs.ai. This information can include without limitation your IP address, browser type, domain names, access times and referring website addresses. We gather this information automatically and store it in log files. This information includes your Internet Protocol ("IP") address and your domain name. An IP address is a number that is automatically assigned to your computer by the ISP computer through which you access the web and a domain name is the name of the ISP computer itself through which you access the web.</li>
<li>In addition, GOjobs.ai may collect indirect information about you when you use certain third-party services on our site. For example, if you register on our site by using social media login name and password, you authorize us to have access to and store the information from your social media Account profiles.</li>
<li>ai receives information about you from certain of its third-party partners, such as Google, Facebook, Instagram, etc.</li>
<li>You can correct or update your GOjobs.ai account profile, resume(s) or email alerts at any time. Moreover, you can delete your resume(s) from GOjos.ai&rsquo;s online database or close your account(s) at any time, in which event we will remove all our copies of your resume and cover letters. We will retain your information for as long as your account is active or as needed to provide you services or as per the Indian law including Data protection, GST, Income tax laws. We will retain and use your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</li>
<li>To the extent permitted by law, GOjobs.ai may record and monitor your communications with us to ensure compliance with our legal and regulatory obligations and our internal policies. This may include the recording of telephone conversations, Chats, emails and other modes of communication;</li>
<li>If you choose to sign in with your social media account to access the Platform, or otherwise connect your social media account with the services of the Platform, you consent to our collection, storage, and use, in accordance with this Privacy Policy, of the information that you make available to us through the social media interface. Please see your social media provider's privacy policy and help centre for more information about how they share information when you choose to connect your account.</li>
<li>Testimonials, references and recommendations &ndash; We post testimonials and recommendations on our website and send it to people through emails. This may contain the name and other details of the individual.</li>
<li>In addition to above we also collect information from you when you subscribe to a newsletter, open a ticket, send us a mail. Etc.</li>
</ul>
<p>&nbsp;</p>
<p>The above information may be collected in different ways which include but not limited to:</p>
<ul>
<li>Information you provide to us by Registering and entering details on our website/s. you may also provide us with some information while communicating with us.</li>
<li>Information we collect automatically which may include but not limited to what happens when you use our services for example page views, time spent, links viewed etc. along with the information about your device for example screen size, network details, product details etc. We also collect information using cookies and other technologies which generally help us to provide you a better service and user experience.</li>
<li>Information we collect from other sources &ndash; If you log in using any social media account based on that we would collect information which is provided by the social media. This would depend on the authorization given by you to that social media and will be governed by the terms and conditions and Privacy Policy of the social media. We would strongly recommend that you check these from the social media&rsquo;s website.</li>
</ul>
<p>&nbsp;</p>
<p>Third Party Content:</p>
<ul>
<li>Please note that the website may contain links to other sites that are not governed by this Privacy Policy. Users are/may be directed to third-party sites for more information, such as advertisers, blogs, content sponsorships, vendor services, social networks, etc.</li>
<li>AI makes no representations or warranties regarding how your information is stored or used on third-party servers. We recommend that you review the applicable privacy statements and policies of each third-party site linked from the Platform to determine their use of your personal information</li>
</ul>
<p>&nbsp;</p>
<p><strong>Important Note:</strong></p>
<p>Children - GOjobs.ai is not intended for children/individuals under 18 years of age. We do not knowingly collect personal information from children/individuals under 18.</p><br/>
<h4>2.&nbsp;&nbsp; How do we use this Information?</h4>
<p>We may use information about you as mentioned above and for the purposes listed below:</p>
<p>To provide our Services &mdash; for example, to set up and maintain your account, store your resume, or host it at a public URL;</p>
<ul>
<li>Providing our Users with customer support;</li>
<li>We use information collected from cookies and other technologies to personalize your experience using our Services, provide content recommendations (for example, suggesting a job based on your Resume &amp; experience), target our marketing messages to groups of our users (for example, those who work in a specific field, if entered on their profile), and serve relevant advertisements to some users to improve your user experience and the overall quality of our services;</li>
<li>To use the aggregated information to measure the number of visits to our site, the average time spent on our site, the number of pages viewed, and various other site use statistics. Such monitoring helps us evaluate how our Sites are used and continuously improve the content we provide;</li>
<li>To further develop and improve our Services &mdash; for example by adding new features that we think our users will enjoy or will help them to create a resume or get a job more efficiently;</li>
<li>Use the information for statistical purpose. We do analysis on the data collected and analyse it on various parameters. It helps us understand which parts of our product are of greatest interest and also provides information used to improve our platform and develop exciting new features. This information is shared with our third-party analytics service&rsquo;s providers;</li>
<li>Conducting market research and surveys with the aim of improving our products and services;</li>
<li>To monitor and analyse trends and better understand how users interact with our Services, which helps us improve our Services and make them easier to use;</li>
<li>Sending you information about our products and services for marketing purposes and promotions; To communicate with you, for example through an email, about offers or promotions related to GOjobs.ai, to solicit your feedback, or keep you up to date on GOjobs.ai and our products;</li>
<li>To measure, gauge, and improve the effectiveness of our advertising, and better understand user retention and attrition &mdash; for example, we may analyse how many individuals upgraded their accounts after receiving a marketing message or the features used by those who continue to use our Services after a certain length of time;</li>
<li>To monitor and prevent any problems with our Services, protect the security of our Services, detect and prevent fraudulent transactions and other illegal activities, fight spam, and protect the rights and property of GOjobs.ai and others;</li>
<li>Preventing, detecting, investigating and taking action against crimes (including but not limited to fraud and other financial crimes), any other illegal activities, suspected fraud, or violations of GOjobs.ai&rsquo;s Terms of Use in any jurisdiction;</li>
<li>To the extent required for identity verification, government rules, requirements and sanctions screening and due diligence checks;</li>
<li>Establishing, exercising or defending legal rights in connection with legal proceedings (including any prospective legal proceedings) and seeking professional or legal advice in relation to such legal proceedings.</li>
</ul><br/>
<h4>3.&nbsp;&nbsp; Sharing of Information</h4>
<p>We value your privacy and are committed to safeguarding your personal information. We will not share or sell your personal information with any third party outside of our organization, except as outlined in this Privacy Policy and for the purpose of improving our services and enhancing your experience.</p>
<ul>
<li>Recruiters, Potential Recruiters, Aggregators, and Job Sites: We may disclose your information to recruiters, potential recruiters, aggregators, and job sites to facilitate the recruitment process. This includes employers, employees, and companies seeking suitable candidates. Our employees and independent contractors who access this information are bound by our Privacy Policy. You have an option to opt out of this feature.</li>
<li>Employees, Agents, Service Providers and Independent Contractors: We may share your information with employees, agents, third-party service providers and independent contractors who assist us in delivering our services. These entities are required to adhere to our Privacy Policy and maintain confidentiality. You have an option to opt out of this feature.</li>
<li>Enforcement, Regulatory, and Legal Authorities: We may disclose your information to enforcement, regulatory, and judicial authorities if required by law or if we believe in good faith that such action is necessary to comply with legal obligations, protect our rights, or prevent illegal activities.</li>
<li>Business Transactions: In the event of a merger, acquisition, or sale of our business assets, your information may be shared with the involved parties. This information transfer will be subject to our Privacy Policy and applicable laws.</li>
<li>Anonymous Data Sharing: We may share aggregated and anonymized data about website visitors with clients, partners, and third parties. This data helps them understand user demographics and usage patterns.</li>
<li>Interactions with Advertisements: While we do not provide personal information to advertisers, interacting with targeted advertisements may lead to assumptions being made about your preferences based on your interactions.</li>
</ul>
<p>&nbsp;</p>
<p>Please note that while we take measures to protect your personal information, data transmission over the internet is not entirely secure. You acknowledge and accept this inherent risk when using our services.</p><br/>
<h4>4.&nbsp;&nbsp; Indian Law and Your Rights as a User of Our Website</h4>
<p>We are committed to respecting your rights as a user of our website. We are an India Based company and servicing only Indian Clients. Hence, we follow the Indian Laws. In case you are not falling in the jurisdiction of the Indian Law then we would strongly recommend and advise you not to use our services.</p>
<p>&nbsp;</p>
<p>Your Rights as a user include:</p>
<ul>
<li>Right to Your Personal Data: You have the right to access to the personal data we hold about you. For this you need to log in to our site.</li>
<li>Right to Correct or Delete of Your Personal Data: You are free to correct or delete the inaccuracies in your personal data or delete it altogether by logging in your account. However certain data which is used for legal purposes may not be deleted like billing details, invoices and payment details etc.</li>
</ul>
<p>&nbsp;</p>
<p>Your Choices:</p>
<ul>
<li>Option for Information Removal and Opt-Out: You can opt-out of communications from us by unsubscribing from emails or contacting us.</li>
<li>Rights to Access, Correct, or Delete Your Information: To access, correct, or delete your individual profile information please login to your account. We may verify your identity before processing your request.</li>
<li>Closing Your Account: If you wish to close your account or limit our use of your information, please do so by logging in to your account. Note that this may affect your access to certain services.</li>
<li>You can opt out of any additional services of Data Sharing with the recruiters and for employment purpose as mentioned by following the opt out process mentioned on the Profile page.</li>
</ul><br/>
<h4>5.&nbsp;&nbsp; Cookie Policy</h4>
<p>Welcome to the section of Cookie Policy of GOJOBS.AI ("we," "us," "our," or "GOJOBS.AI"). This Policy is designed to explain how we use cookies and similar technologies on our websites. By using our websites, mobile sites, or mobile apps (collectively "the services"), you consent to the use of cookies as described in this policy.</p>
<p>&nbsp;</p>
<p><strong>Introduction</strong></p>
<p>This Cookie Policy is intended to provide clarity and transparency regarding our use of cookies. It applies to all products or services that link to or incorporate this policy.</p>
<p>&nbsp;</p>
<ul>
<li>What are Cookies?</li>
</ul>
<p>Cookies are small pieces of text stored by a website in your browser and are sent with each request to the website. They help enhance your experience and enable efficient navigation. Cookies can be categorized as "session" or "persistent" cookies.</p>
<ul>
<li>How and Why We Use Cookies</li>
</ul>
<p>We use cookies, tags, and similar technologies to improve user experience, analyze usage patterns, and optimize our services. By continuing to use our services, you agree to the use of cookies as outlined in this policy.</p>
<ul>
<li>Types of Cookies and Their Purposes
<ol>
<li>Essential Cookies: These cookies are necessary for basic functionality, such as session management and user authentication.</li>
<li>Analytics Cookies: We use cookies to analyze user behavior and improve our services. This includes tracking website visits, user preferences, and interactions to enhance the user experience.</li>
<li>Functionality or Preference Cookies: Cookies store user preferences and choices to personalize the website experience, such as language or region settings.</li>
<li>Targeting or Advertising Cookies: These cookies enable advertising networks to deliver relevant ads based on your online activities. They help us optimize marketing campaigns and understand user engagement.</li>
</ol>
</li>
<li>Our Use of Third-Party Cookies</li>
</ul>
<p>We work with third-party partners for advertising and analytics purposes. These partners may place cookies on your device to track performance and deliver tailored ads. The names and details of these third-party cookies are available in the respective privacy policies.</p>
<ul>
<li>Web Beacons and Tracking</li>
</ul>
<p>Web beacons, pixels, and similar technologies are used to track user interactions on our site and third-party websites for advertising and analytics purposes.</p>
<ul>
<li>Managing Your Cookie Preferences</li>
</ul>
<p>You can control cookies through your browser settings. Most browsers offer options to accept, reject, or notify you of cookies. However, note that certain essential cookies cannot be disabled without affecting website functionality.</p>
<ul>
<li>Changes to Cookie Policy</li>
</ul>
<p>Cookies and tracking technologies may evolve. Our policy may be updated accordingly. We recommend checking this policy periodically for any changes.</p>
<ul>
<li>Third-Party Opt-Out</li>
</ul>
<p>Third-party ad networks allow you to customize ad preferences or opt out of targeted advertising. You can find more information and opt-out options in their respective privacy policies.</p>
<ul>
<li>Google Analytics and Do Not Track Signals</li>
</ul>
<p>We use Google Analytics for website analysis and performance tracking. However, we do not currently respond to "Do Not Track" signals.</p>
<ul>
<li>Please note that this Cookie Policy is subject to updates, and we recommend reviewing it periodically for the latest information.</li>
<li>By continuing to use our services, you signify your understanding and acceptance of this Cookie Policy. Your privacy matters to us, and we are committed to ensuring your browsing experience is secure and personalized.</li>
</ul><br/>
<h4>6.&nbsp;&nbsp; Data Retention</h4>
<p>At GOJOBS.AI, we prioritize the responsible handling and retention of data. Here's how we manage data retention:</p>
<ul>
<li>Usage Information:
<ol>
<li>Data collected through Google Analytics is retained for up to 26 months after your last visit or as per the revised Google Policy. Afterward, it is used exclusively for aggregate reports.</li>
<li>Usage information from other apps is kept indefinitely for aggregate usage pattern analysis.</li>
</ol>
</li>
<li>Resume Content:
<ol>
<li>Resume content is stored for as long as your resume remains on our platform.</li>
<li>If you delete your resume or your account, all associated resume content is immediately removed from our database.</li>
</ol>
</li>
<li>Retention of Personal Information:
<ol>
<li>Your Personal Information will only be retained for as long as necessary for the purposes it was processed, or as required by legal, regulatory, contractual, or statutory obligations.</li>
</ol>
</li>
<li>Deletion and Archiving:
<ol>
<li>Following the applicable retention periods, your Personal Information will be deleted or archived in accordance with relevant laws.</li>
</ol>
</li>
<li>Accuracy and Updates:
<ol>
<li>You can keep your Personal Information accurate and updated.</li>
<li>You can rectify, update, or correct your Personal Information.</li>
<li>You can access your Personal Information and exercise data portability rights.</li>
</ol>
</li>
<li>Identity Verification:
<ol>
<li>To ensure respect for your rights, we may require valid proof of your identity when you make requests.</li>
</ol>
</li>
<li>Additional Authority or Fees:
<ol>
<li>For certain rights, you may need to approach relevant authorities.</li>
<li>We may charge a fee for fulfilling requests under applicable data protection laws.</li>
</ol>
</li>
<li>Rights are subject to limitations as per applicable data protection laws.</li>
<li>Retention of Information
<ol>
<li>We retain your information as long as your account is active or as needed to provide services. We also retain data for legal compliance, dispute resolution, and agreement enforcement purposes.</li>
</ol>
</li>
<li>Sensitive Data
<ol>
<li>We do not collect or process sensitive data unless required by law. This includes race, ethnic origin, political opinions, religious beliefs, trade union memberships, genetic or biometric data, health information, mortality data, and information about sex life or sexual orientation. Please do not provide this information to our resume writer or chat bot or any other application.</li>
</ol>
</li>
</ul><br/>
<h4>7.&nbsp;&nbsp; Changes to the Privacy Policy</h4>
<p>Our Privacy Policy may change from time to time and all updates will be posted on this page so that you will always know what information we gather, how we might use that information, and to whom we will disclose it.&nbsp;</p><br/>
<h4>8.&nbsp;&nbsp; Questions and Contact Information</h4>
<p>If you have any inquiries about this Privacy Policy, please refer to our FAQ page or visit our contact us page for various methods to get in touch with us.</p><br/>
<h4>9.&nbsp;&nbsp; Disclaimer</h4>
<p>At GOJOBS.AI, we want to clarify that we do not store any account-related details or credit/debit card information. While using our platform, any inadvertent disclosure of account information or payment details during online transactions or payments for our products and services will not hold GOJOBS.AI liable for any resulting loss or damage sustained by users.</p>
<p>&nbsp;</p>
<p>Furthermore, if you share Personal Information with GOJOBS.AI that is not specifically requested during registration, whether mandatory or optional, please be aware that GOJOBS.AI will not be responsible for any breaches of information security or disclosures related to such information.</p>
<p>&nbsp;</p>
<p>If you have queries about this Privacy Policy or the protection of your Personal Information, you can contact our Data Protection Officer or Grievance Officer through the contact us page.</p>
<p>&nbsp;</p>
<p>Your privacy is important to us, and we are committed to addressing your concerns and maintaining the security of your Personal Information.</p><br/>
<h4>10.&nbsp;&nbsp; Consent</h4>
<p>If you are a registered user or a visitor to the Website, the Company acts as the &lsquo;data controller&rsquo; of personal data. This means that the Company determines how and why your data are processed.</p>
<p>&nbsp;</p>
<p>By using our platform, you consent to the collection, use, sharing, storing of your information as described in this Privacy Policy.</p>
<p>&nbsp;</p>
<p>Your Personal Information may be processed by GOJOBS.AI, to the extent that such processing is necessary to allow GOJOBS.AI to comply with a legal obligation. An example of this would be if GOJOBS.AI is required to disclose your Personal Information to respond to a court order or if GOJOBS.AI is required to retain specific records for a fixed period to comply with requirements under any applicable law.</p>
<p>&nbsp;</p>
<p>You also give us the consent to contact you about site updates, informational and service-related communications, including important security updates; to provide you additional communications, information, and promotions such as newsletters and career advice; to inform you of other products or services available from GOjobs.ai or its affiliates; to enable you to provide feedback, to contact us and for us to respond to you; to conduct surveys and questionnaires, to generate internal reports about the use of our site; and to provide &lsquo;forward to a friend&rsquo; features.</p>
<p>&nbsp;</p>
<p>You are free to delete your account and data any time. However please note that some data will be stored and retained by us as per the law. This data may include but not limited to your billing details, invoices and payments made etc.</p>
<p>&nbsp;</p>
<p>&nbsp;</p>

</p><br />
            </div>
            
     
        <Footer />
        </div>

        );
}
export default Privacy;

