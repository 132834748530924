import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import ReactGA from 'react-ga';

ReactGA.initialize('G-7EP0N73V05');
ReactGA.initialize('AW-1046069944');

ReactDOM.render(
  // <BrowserRouter basename="/resume_ai">
  <BrowserRouter  basename="">
    <App/>
  </BrowserRouter>,
  document.getElementById('root')
)