import React,{ useState,useEffect }  from 'react'
// import { FadeLoader } from 'react-spinners';
// import '.Css/rsuite.scss';
import { Loader } from 'rsuite';

const GithubProfile = props => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      
      import('./Css/rsuite.scss');
    }, []);
    return (
        // <div className='sweet-loading' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        //   <FadeLoader color="#36d7b7" margin="2" />
        // </div>

        <div style={{ margin: 200 }}>
            
            <Loader size="lg" color="#43b0df" backdrop 
                content={props.content} />
        </div>
    );
}


export default GithubProfile