// config.js

export const commonConfig = {
  REACT_APP_API_URL:'https://gojobs.ai/resume_ai_api/public/api/',
  REACT_APP_BACKEND_URL:'https://gojobs.ai/resume_ai_api/public/',
  GPT_API_URL:'https://api.openai.com/v1/chat/completions',
//   GPT_API_URL:'https://api.openai.com/v1/engines/davinci/completions',
  // Demo
  // GPT_API_KEY:'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',

  // Live
  // GPT_API_KEY:'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',
  GPT_API_KEY:'sk-proj-U9IbzMmS1LopnSSnpB6UT3BlbkFJoBHwFPpvVoc5iHpr09Nb',
  API_TOKEN:"eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiN2E1ODhkNzcwZDM2ZGJiN2Y2NmI3MTNjMjU5ZTQ4NGNjMmQwNzUyYmJiY2JjNDdlOGZkN2YwYmRjNTUwZGJiMGIyOWY0ODljNzYyMzlkN2QiLCJpYXQiOjE2ODY3MjQ5NTAuNzM3MDU1LCJuYmYiOjE2ODY3MjQ5NTAuNzM3MDU3LCJleHAiOjE3MTgzNDczNTAuNzI3Mzg5LCJzdWIiOiI5Iiwic2NvcGVzIjpbXX0.Z2qo9ke8RJXSx7ZucREFAcd6vjf8B2i3OsS9GTVnAcyFaJQU3hQ6yN0CiegahxI7hKVNJYuAS7Cgq0s8iWjsdRUl1ZXQ7pn1lWinM5sp50gDUa3fICGg6RGCX2007n_-ENhWlBnLE_DpZ8HdPL1154BonGCv-lI0VXgjXItKluyhOhHZYNlAWzCQcaz6Vzh6ODAQM_sbv8M_K_l9lS8KSJZT3uWhlfHilFBTQRD6kctaz0rg06xftqRztGo1fNc3-BgEhU9i_Ebbz-AAUd4Ba-x7odGX25e5M5x8TgeqppkGZ38GBUcUZePmUZpW2WFLEcRfZS8wpq8iUwBNXMtvlQQLON1cn0TtoDekSXSeDWRJIomliiuGyStpBEVjeomBawowm4MMGnz9HrSy8TyZ582j1M8IqygmSuczyXrPI0l2fddn0j-APFbMVrGrwebPQRcF929X-27gf0-8f472_hUl35AsZ2XO2nVh-TZmGptCWE4k1Nu-PztBc4I8YjSo5cZx1D4YgROy1Ar95E3uHJHPbZ-66j1b-q74cjM96856MjDd2Q811pHFLHfCYgXqZitpNqcQgXPR4Ve51k44yjv4xaCPOA5JzKqYkafcdJGyKOx5GpARgGUUz529dodWTZ4cOboJpUdncqajNPGDOJofvTc1ymRROwRiKxt2aLg",
  IMAGE_URL:'http://localhost/GO/API_2/storage/app/public/',
  PDF_URL:'https://gojobs.ai/resume_ai_api/storage/app/pdf/',
  COVERPDF_URL:'https://gojobs.ai/resume_ai_api/storage/app/cover/',
  LOAD_TIME:500,
  REACT_APP_LINKEDIN_CLIENT_ID:'86grb94dxijgbe',
  REACT_APP_LINKEDIN_REDIRECT_URI:'http://localhost:3000/linkedin'
  // Other common configuration properties
};
