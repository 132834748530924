import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate } from "react-router-dom";
import Nav from "./Nav"; import { commonConfig } from "../config.js";
import axios from "axios";
import link_button from "./Assets/plus.png";
import link_button2 from "./Assets/minus.png";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import Formtemplate from "./Formtemplate";

function Step2_1() {
  // const [tabsData, setTabsData] = useState([]);
  const [expData, setExpData] = useState([]);
  const [respokData, setRespokData] = useState(true);
  const [page, setPage] = useState(false);
  const [stepno, setStepno] = useState(2);
  const resume_id = localStorage.getItem('resume_id');
  const userId = localStorage.getItem('userId');
  const CommonData = localStorage.getItem('common_data');
  const navigate = useNavigate();


  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    if (CommonData) {
      if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
        navigate('/step14');
      }
    }
    import("./Css/Step8.scss");
    Getexplist();
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);

  const Getexplist = () =>{
    if (resume_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        setExpData(result.experience);
        // console.log(result.experience);

        const contact = result.contact[0];
        if (contact.step === 100) {
          setStepno(contact.step);
        }else if(contact.step === 2) {
          setStepno(contact.step);
        }else{
          setStepno(contact.step + 1);
        }


      }).catch(err => {

      });
    }

  }



  const EditExp = (exp_id) => {
    navigate('/step2/' + exp_id);
  };

  const handleDelete = (exp_id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Experience?');
    if (isConfirmed) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "delete_exp",
        data: { exp_id: exp_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        Getexplist();
      }).catch(err => {

      });
    }
  };



  return (
    <div className="step8page">
      {respokData ? (
        <main id="app" role="main">
          <section className="page-wrapper">
            <div
              id="parent"
              className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
            >
              {page && <Nav value={stepno} />}

              <div className="col-lg-12">
                <div className="col-lg-7">
                  <div className="exp_section">
                    <h3 className="headtag">
                      <span className="blue">Work History</span>
                    </h3>
                    {expData?.map((item, index) => (<div className="exp_list">
                      <div className="exp_title center-start-end bold">
                        <div>{item.title}, {item.employer}</div>
                        <div>
                          <span className="mr-60 clickable" onClick={(event) => EditExp(item.id)} ><FontAwesomeIcon icon={faEdit} /></span>
                          <span className="clickable"><FontAwesomeIcon onClick={(event) => handleDelete(item.id)} icon={faTrashAlt} /></span>
                        </div>
                      </div>
                      <div className="exp_loc">
                        <div>
                          <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                          <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                        </div>
                      </div>

                      <div className="exp_loc">
                        <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                        {item.achievements != '[]' && item.achievements != '[null]' &&<div>
                          <b>Achievements:</b>
                          <ul>
                            {JSON.parse(item.achievements)?.map((item1, index) => (
                              item1 && <li key={index}>{item1}</li>
                            ))}
                          </ul><br />
                        </div>}
                      </div>
                    </div>))}


                    {/* <div className="mt-20">
                      <Link to="/step2" className="addmorebtn">+ Add More Experience</Link>
                    </div> */}
                    <div className="system_view">
                      <div className="col-lg-4 half" style={{ marginTop: '7%' }} >
                        <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                      </div>
                      <div className="col-lg-8 half" style={{ marginTop: '7%' }} >
                        <Link to="/step3" className="btn-mangaii">Continue to Education</Link>
                        <Link to="/step2" className="btn-mangaii-dark">+ Add More Experience</Link>
                      </div>
                    </div>
                    <div className="mobile_view">
                      <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                        <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                      </div>
                      <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                        <Link to="/step2" className="btn-mangaii-dark">+ Add More Experience</Link>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: '7%' }} >
                        <Link to="/step3"  className="btn-mangaii" style={{width:'100%',marginTop: '7%'}}>Continue to Education</Link>
                      </div>
                    </div>
                    
                  </div>

                </div>
                <div className="col-lg-5 system_view">
                  <div className="api_right">
                    <Link to="/template/step2_1" className="clickable p-10">Change Template</Link>
                    <Formtemplate />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <StepsFooter />
        </main>

      ) : (
        <div>
          <div className="system_view">
            <Loading content="Do Not Refresh the page as we are working on your Profile!" />
          </div>
          <div className="mobile_view">
            <Loading />
          </div>
        </div>

      )}
    </div>
  );
}

export default Step2_1;