import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { commonConfig } from '../config.js';
import axios from "axios";
import temp1 from './Assets/temp1.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import Logobar from "./Logobar";
import first_cv from './Assets/firstCV.png';
import first_cover from './Assets/first_cover.png';
import temp_1 from './Assets/temp_1.png';
import dash_mindset from './Assets/dash_mindset.png';
import dash_resume from './Assets/dash_resume.png';
import Loading from './Loading';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import image1 from './Assets/temp_1.png';
import image2 from './Assets/temp_2.png';
import image3 from './Assets/temp_3.png';
import image4 from './Assets/temp4.jpg';
import image5 from './Assets/temp5.jpeg';
import image6 from './Assets/Temp6.jpg';
import image7 from './Assets/Temp7.jpeg';
import image8 from './Assets/Temp8.jpg';
import image21 from './Assets/Temp21.jpeg';
import BlurImage from './BlurImage';
import BlurCoverImage from './BlurCoverImage';

function Step14() {

  const [tabsData, setTabsData] = useState([]);
  const [respData, setRespData] = useState([]);
  const [respokData, setRespokData] = useState(false);
  const [data, setData] = useState({});
  const [resp, setResp] = useState([]);
  const [coverResp, setCoverResp] = useState([]);
  const [plan, setPlan] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  
  
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const email = localStorage.getItem('email');
  const [page, setPage] = useState(false);
  const [percentage1, setPercentage1] = useState(100);
  const [percentage2, setPercentage2] = useState(100);
  const [hovered, setHovered] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  let images = [];
	images = [image1, image2, image3, image4, image5, image6, image7, image8, image21];
  
  // const percentage = 66;

  useEffect(() => {
    if (!userId) {
    	navigate('/login');
    }
    getResumes();
    getCoverletters();
    // setTimeout(() => {
    //   setPage(true);
    // }, commonConfig.LOAD_TIME);

    import("./Css/Step14.scss");
    


  }, []);

  const getResumes = () => {
    axios({
      method: "POST",
      data: { userId: userId },
      url: commonConfig.REACT_APP_API_URL + "my_cvs",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      setResp(result.contact);
      setPlan(result.plan);
      setPercentage1(result.plan.progress_1);
      setPercentage2(result.plan.progress_2);
      localStorage.setItem('common_data', JSON.stringify(result));
      setPage(true);
    }).catch(err => {

    });
  };
  const getCoverletters = () => {
    axios({
      method: "POST",
      data: { userId: userId },
      url: commonConfig.REACT_APP_API_URL + "my_coverletter",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      setCoverResp(result.cover);
      // setPage(true);
    }).catch(err => {

    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  // const EditCV
  const EditCV = (resume_id) => {
    localStorage.setItem('resume_id', resume_id);
    if (plan.can_edit == 1) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        const step = result.contact[0].step;

        localStorage.setItem('resume_data', JSON.stringify(result));
        if (step == 1) {
          navigate('/step2');
        } else if (step == 2) {
          navigate('/step3');
        } else if (step == 3) {
          navigate('/step4');
        } else if (step == 4) {
          navigate('/step5');
        } else if (step == 5) {
          navigate('/step6');
        } else if (step == 6) {
          navigate('/step9');
        } else if (step == 7) {
          navigate('/step2');
        } else if (step == 8) {
          navigate('/step2');
        } else if (step == 9) {
          navigate('/step13');
        } else if (step == 10) {
          navigate('/step13');
        } else if (step == 11) {
          navigate('/step13');
        } else if (step == 12) {
          navigate('/step13');
        } else {
          navigate('/step13');
        }
        // navigate('/step13');
      }).catch(err => {

      });
    } else {
      navigate('/pricing');
    }
    // navigate('/step2');
  }
  const CreateCV = () => {
    // if (plan.can_create == 1) {
    //   setIsOpen(true);
    // } else {
    //   navigate('/pricing');
    // }
    setIsOpen(true);
  }
  const CreateCL = () => {
    navigate('/cover');
  }
  const CreateCoverLetter = () => {
    // localStorage.removeItem('coverResumeId');
    // localStorage.removeItem('cover_id');
    navigate('/cover');
  }
  const Startblank = () => {
    // if (plan.can_create == 1) {
    //   localStorage.removeItem('resume_id');
    //   navigate('/template');
    // } else {
    //   navigate('/pricing');
    // }
    localStorage.removeItem('resume_id');
    navigate('/choose');
  }
  const Createfrom = (resume_id) => {
    if (plan.can_create == 1) {
      localStorage.removeItem('resume_id');
      localStorage.setItem('temp_resume_id', resume_id);
      navigate('/step1');
    } else {
      navigate('/pricing');
    }
  }
  const pricing = () => {
    window.open('https://goaccomplish.com', '_blank');
  }

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
    console.log(templateId);
  };

  return (
    <div className="step14">
      {page ? (
        <div>
          <main id="app" role="main">
            <section className="page-wrapper">
              <Logobar />
              <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
                <div className="inner1_step14 col-lg-12 center-start-end">
                  <div className="col-lg-3">
                    <div className="inner_section">
                      <div className="headtag">
                        <span className="blue">Welcome <div className="subheadtag">{email}</div></span>
                      </div>
                      <p className="p_tag" style={{fontSize:'16px',fontWeight:'500'}}>We can help you in crafting a standout resume that sets you apart from the competition</p>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner_border">
                      <div className="inner_section center-start-end">
                        <div className="half" style={{lineHeight:'2.5'}}>
                          <span>Current Plan</span><br />
                          <b style={{fontSize:'20px'}}>{plan?.pack}</b>
                        </div>
                        <div className="f-right" style={{ width: 100, height: 100 }}>
                          
                          <img className="temps14_pop" src={dash_resume} />
                        </div>
                      </div>
                      <div className="inner_section first_sec center-start-end">
                        <div className="inner_bottom half-35">
                          <span>Start Date</span><br />
                          <b>{plan?.start_date}</b>
                        </div>
                        <div className="inner_bottom half-35">
                          <span>End Date</span><br />
                          <b>{plan?.end_date}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="inner_border">
                      <div className="inner_section center-start-end">
                        <div className="half" style={{lineHeight:'1.8',width:'50%',fontSize:'17px'}}>
                          <b>Unlock your potential with our Soft Skills Mastery Courses</b><br />
                        </div>
                        <div className="f-right" style={{ width: 100, height: 100 }}>
                          
                          <img className="temps14_pop" src={dash_mindset} />
                        </div>
                      </div>
                      <div className="center p-5p">
                        <button  onClick={() => pricing()} className="up_btn btn btn-mangai clickable center" >Enroll Now</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="tab-header">
                  <div className={`tab-item1 bold ${activeTab === 0 ? 'active' : ''}`} onClick={() => setActiveTab(0)}>
                    Resume
                  </div>
                  <div className={`tab-item2 bold ${activeTab === 1 ? 'active' : ''}`} onClick={() => setActiveTab(1)}>
                    Cover Letter
                  </div>
                </div>


                {activeTab === 0 && (
                  <div>
                    {resp.length > 0 ? (
                      
                      <div>
                        <div className="inner2_step14 col-lg-12 bg_blue">
                          <div className="center-start-end-resp">
                            <div className="white" style={{ fontWeight: '700', padding: '18px' }}>My Resume</div>
                            {plan.can_create == 1 &&
                              <div><button onClick={() => CreateCV()} className="cvbtn clickable">+ New Resume</button></div>
                            }
                          </div>

                          <div class="container1">
                            <div class="row">
                              {resp.map((tab, index) => (
                                <div className="col-lg-3 p-none">
                                  <BlurImage key={index} can_download={plan.pack_status} can_edit={plan.can_edit}  imageUrl={temp_1} data={tab} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="inner2_step14 col-lg-12 bg_blue">
                          <Link to="/template" className="center"><img src={first_cv} style={{ width: '20%' }} /></Link>
                          <Link to="/template" className="center"><button onClick={() => CreateCV()} className="btn-riz clickable">Create Your First Resume</button></Link>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {activeTab === 1 && ( 
                  <div>      
                    {coverResp.length > 0 ? (
                      <div>
                        <div className="inner2_step14 col-lg-12 bg_blue">
                          <div className="center-start-end-resp">
                            <div className="white" style={{ fontWeight: '700', padding: '18px' }}>My Cover Letter</div>
                            {plan.can_create == 1 &&
                              <div><button onClick={() => CreateCoverLetter()} className="cvbtn clickable">+ New Cover Letter</button></div>
                            }
                          </div>

                          <div class="container1">
                            <div class="row">
                              {coverResp.map((tab, index) => (
                                <div className="col-lg-3 p-none">
                                  <BlurCoverImage key={index} can_download={plan.pack_status} can_edit={plan.can_edit}  imageUrl={temp_1} data={tab} />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div className="inner2_step14 col-lg-12 bg_blue">
                          <Link className="center" to="/cover"><img src={first_cover} style={{ width: '14%',marginTop: '5%',marginBottom: '1%' }} /></Link>
                          <Link className="center" to="/cover"><button  className="btn-riz clickable">Create Your First Cover Letter</button></Link>
                        </div>
                        
                      </div>
                    )}
                  </div>
                )}
              </div>

              {isOpen && (
                <div className="popup-overlay">
                  <div className="popup-container">
                    <FontAwesomeIcon className="close-button" onClick={handleClose} icon={faTimes} />
                    <p style={{ marginTop: '20px', fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>Start blank or use data from previous resumes</p>
                    <div className="resume_list">
                      <div class="container">
                        <div class="row">
                          {resp.map((tab, index) => (
                            <div className="col-lg-6">
                              <div className={`formcontrol3text_14 ${selectedTemplate === tab.id ? 'selected' : ''}`} onClick={() => handleTemplateSelect(tab.id)} style={{ cursor: "pointer" }}>
                                <div className="cv_list">
                                  <h4 className="d_resumename">{tab.resumename}</h4>
                                </div>
                                {tab.template ? (<img className="temps14_pop" src={images[tab.template]} />) : (<img className="temps14_pop" src={images[8]} />)}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    {selectedTemplate && <button id="btnBack" type="button" className="btn-stepsback full" style={{ marginTop: '40px' }} onClick={() => Createfrom(selectedTemplate)}>Select this resume to proceed</button>}
                    <button id="btnBack" type="submit" onClick={Startblank} className="btn-mangaii full" style={{ marginTop: '40px' }}  >Start Blank</button>
                  </div>
                </div>
              )}

              

            </section>
          </main>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
}

export default Step14;




