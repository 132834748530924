import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainNav from "./MainNav";
import Footer from "./Footer";
import "./Assets/CSS/aos.css";
import "./Assets/CSS/glightbox.min.css";
import "./Assets/CSS/swiper-bundle.min.css";
import "./Assets/CSS/style.css";
import LoadingGIF from './LoadingGIF';
import Loading from './Loading';
import ProgressBar from './ProgressBar';
import { commonConfig } from '../config.js';
import axios from "axios";
import { faCircleChevronUp,faCircleChevronDown } from '@fortawesome/free-solid-svg-icons';

function InterviewStep3() {
  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [boxopen, setBoxopen] = useState(false);
  const [resumeopen, setResumeopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem('userId');
  const interviewResumeId = localStorage.getItem('interviewResumeId');
  const interviewId = localStorage.getItem('interviewId');
  const [errors, setErrors] = useState([]);
  const [contact, setContact] = useState({});
  const [expData, setExpData] = useState([]);
  const [eduData, setEduData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [count, setCount] = useState(1);
  const [respData, setRespData] = useState([]);
  const [answers, setAnswers] = useState({});
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [answerVisibility, setAnswerVisibility] = useState({});
  const [questionVisibility, setQuestionVisibility] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [isResponse, setIsResponse] = useState(0);
  const [dbQustions, setDbQustions] = useState('');
  const [dbAnswers, setDbAnswers] = useState('');
  const colors = ['#ADBC00','#26BAC4','#43B0DF','#9CBEFF','#D4A8D2','#C2CCBA'];

  useEffect(() => {
    import('./Css/Interview.scss');
    import('./Css/InterviewStep.scss');
    console.log("interviewId",interviewId);
    getResumeDetails();
    getEditdata();
    
    // setTimeout(() => {
    //   setPage(true);
    //   setBoxopen(true);
    // }, 1500);
    
  }, []);

  const getResumeDetails = () => {
    if (interviewResumeId) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: interviewResumeId },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        setContact(result.contact[0]);
        setExpData(result.experience);
        setEduData(result.education);
        setSkills(result.skills[0]);

      }).catch(err => {

      });
    }
  }
  const getEditdata = () =>{
    if (interviewId) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "interviewFinalize",
				data: { interview_id: interviewId },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
        setIsResponse(result.is_response);
        setDbQustions(result.questions);
        setDbAnswers(JSON.parse(result.answers));
        setAnswers(JSON.parse(result.answers));
        prompt(result);
			}).catch(err => {

			});
		}
  }
  
  const prompt = (data) => {
    let experience = '';
    expData?.map((item, index) => {
      experience += item.employer+','+item.title+','+item.GPT_resp;
    });

    let education = '';
    eduData?.map((item, index) => {
      education += item.education+','+item.course+','+item.year_of_completion;
    });

    let skillsdata = skills;

    let question = 'You are a professional Recruiter and a Subject Matter Expert in the area of Interview.who is working in Company '+data.company+'.where I am applying for a job of '+data.job_applied_to+',in the Department '+data.department+' at '+data.location+'.You have been working in the area for more than 10 years as of now and are an expert in this area and also you are an expert recruiter and interviewer.Now I also want you to act as a Recruiting manager and conduct an interviewing for the positions of '+data.job_applied_to+' and has the following job details '+data.description+'.';

    question+='To conduct the interview, you would require my details about my experience and the Relevant jobs which I have done. They are: '+experience;

    question+='My Education which could be useful in forming questions ' +education;

    question+='Skills I possess: ' +skillsdata;

    question+='Based on the above information I want you to ask me about 10 questions in each of the following area and 6 sections:1.	Personal Questions – Questions which deal with me 2.	Questions about the company and job I have applied for as explained in the above section 3.	Questions about my experience and work.  Please include a.	(Company, Designation, Job Description) b.	How these jobs can be related to the job applied for c.	Add other jobs as selected d.	Special points about me: e.	Add other achievements as selected f.	Skills I possess: Provide a list of skills 4.	Extra-Curricular Activities and Achievements: a.	Provide a list 5.	Questions about the Job I have applied for as explained in the above section. 6.	Various soft skills including Team work, Management etc. which are relevant for the jobs I have done or I am likely to do in the applied job.';

    question+='Return these statement as a JSON Object in interview key.Do not return any non-json text or numbering';

    if(data.is_response == 0){
      chatGPT(question).then(responseData => {
        save_data(responseData,interviewId,"questions");
        setRespData(responseData);
        setPage(true);
      }).catch(error => {
        console.error('Error:', error);
      });
    }else{
      console.log("Joshua",JSON.parse(data.questions));
        setRespData(JSON.parse(data.questions));
        setPage(true);
    }
    
  }
  const prompt1 = (category) => {
    
    let req = respData?.interview?.[category];

    let question = 'Hi! Thanks for all the help. Now I want you to act as an expert and professional in the Area of Interview and suggest me the most appropriate answers to the questions which you have suggested in the Area of Company which can be given in a job interview for the job of PHP Developer. Given below are the questions which can be asked in the interview:';
    question+= req;
    question+= 'Please suggest the best and most appropriate answers for each of the questions keeping in mind my experience and the job I have applied to. Also make sure these answers should satisfy the interviewer and I should be able to clear the interview.The answer should be in 100 words';
    question+='Return these statement as a plain json array without questions and without answer key.Do not return any non-array text or numbering';

    if(dbAnswers?.[category] && dbAnswers?.[category].length > 0){
      // setAnswers()
    }else{
      setIsLoading(true);
      chatGPT(question).then(responseData => {
        setIsLoading(false);
        setAnswers((prevAnswers) => {
          const updatedAnswers = {
            ...prevAnswers,
            [category]: responseData
          };
          setDbAnswers(updatedAnswers);
          save_data(updatedAnswers,interviewId,"answers");
          return updatedAnswers; // Return the updated state
        });
        // console.log(answers);
        
      }).catch(error => {
        console.error('Error:', error);
      });
    }
    
  }

  

  const prompt2 = (q,a,index,category) => {
    setIsLoading(true);
    let question = 'Hi! For the Question "'+q+'" you have suggested that I should answer as "'+a+'".I am not very happy with the answer, can you please suggest some more options which I could use as answers for the question "'+q+'"';
    question+='The answer should be in 100 words.Return these statement as a plain json array as single sentence without questions and without answer key.Do not return any non-array text or numbering';

    console.log("answers",answers);

    chatGPT(question).then(responseData => {

      setIsLoading(false);
      setAnswers((prevAnswers) => {
        const updatedAnswers = { ...prevAnswers };
        updatedAnswers[category][index] = responseData[0];
        return updatedAnswers;
      });
      
    }).catch(error => {
      console.error('Error:', error);
    });
    
  }

  const prompt3 = (q,category,index) => {
    setIsLoading(true);
    let question = 'Hi! '+category+' is an interesting area where more questions can be asked.  Till now you have asked me the following questions:'+q+'';
    question+= 'As you are an expert in this area, please ask me more questions in '+category+'. Please ask at least 10 more questions which are different from the above.';
    question+='The answer should be in 100 words.Return these statement as a plain json array as single sentence without questions and without answer key.Do not return any non-array text or numbering';

    chatGPT(question).then(responseData => {
      setRespData((respData) =>{
        const updatedAnswers1 = {
          ...respData,
          interview: {
            ...respData.interview,
            [category]: [
              ...respData.interview[category],
              ...responseData
            ],
          },
        };
        return updatedAnswers1;
      });
      prompt4(category,responseData);
    }).catch(error => {
      console.error('Error:', error);
    });
    
  }

  const prompt4 = (category,questions) => {
    
    // let req = respData?.interview?.[category];

    let question = 'Hi! Thanks for all the help. Now I want you to act as an expert and professional in the Area of Interview and suggest me the most appropriate answers to the questions which you have suggested in the Area of Company which can be given in a job interview for the job of PHP Developer. Given below are the questions which can be asked in the interview:';
    question+= questions;
    question+= 'Please suggest the best and most appropriate answers for each of the questions keeping in mind my experience and the job I have applied to. Also make sure these answers should satisfy the interviewer and I should be able to clear the interview.The answer should be in 100 words. ';
    question+='Return these statement as a plain json array without questions and without answer key.Do not return any non-array text or numbering';

    // setIsLoading(true);
    console.log("questions",respData);
    chatGPT(question).then(responseData => {
      setIsLoading(false);
      setAnswers((prevAnswers) => {
        const updatedAnswers = { ...prevAnswers };
        updatedAnswers[category] = [
          ...updatedAnswers[category],
          ...responseData
        ];
        console.log("questions1",updatedAnswers);
        return updatedAnswers;
        
      });
    }).catch(error => {
      console.error('Error:', error);
    });
    
  }

  const chatGPT = async (question) => {
		return new Promise(async (resolve, reject) => {
			try {
				const apiUrl = commonConfig.GPT_API_URL;
				const apiKey = commonConfig.GPT_API_KEY;
				const requestBody = {
					"model": "gpt-3.5-turbo",
					"messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
					"temperature": 0.7
				};
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${apiKey}`
				};
				const { data } = await axios.post(apiUrl, requestBody, { headers });
				const result = data.choices[0].message.content;
				const json = JSON.parse(result);
				  resolve(json);
			} catch (error) {
				console.error('Error:', error);
				reject(error);
			}
		});
	};


  const save_data = (item, id,type) => {
		const requestData = {
			resp: item,
			id: id,
			type: type
		};
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "interview/GPTstore",
			data: requestData,
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN},
		}).then(res => {

		}).catch(err => {

		});
	}



  const toggleAnswerVisibility = (index, category) => {
    setAnswerVisibility((prev) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [index]: !prev[category]?.[index],
      },
    }));
  };
  const toggleQuestionVisibility = (category) => {
    setQuestionVisibility((prev) => ({
      ...prev,
      [category]: !prev[category],
    }));
    if(isResponse == 1){

    }
    prompt1(category);
  };

  const handleRadioChange = (category,index,value) => {
    setSelectedOptions(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [index]: value,
      },
    }));
  };
  const clickedNotHappy = (category,index) => {
    const question = respData?.interview?.[category][index];
    const answer = answers?.[category][index];
    prompt2(question,answer,index,category);
    
  };
  const deepDive = (category) => {
    const questionList = respData?.interview?.[category];
    prompt3(questionList,category);
  };

  return (
    <div className="interviewSteppage interviewpage" style={{backgroundColor:'#F5F5F5'}}>
      {page ? (
        <div>
          {isLoading &&<ProgressBar perc={count} value={count}/>}
          <MainNav value="7" />
          <section style={{marginTop:'6%'}}>
              <ol class="ProgressBar" style={{marginTop:'3%'}}>
							 <li class="ProgressBar-step is-complete">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel"><b>Enter Details</b></span>
							 </li>
							 <li class="ProgressBar-step is-complete">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel"><b>Verify Details</b></span>
							 </li>
							 <li class="ProgressBar-step is-complete">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel"><b>Interview Preparation</b></span>
							 </li>
						 </ol>
          </section>
          <section className="banner2">
            <div className="col-lg-12 center-start-end mt-10" style={{ width: '80%', margin: 'auto' }}>
              <div>
                <h6 >Questions and Suggested Answers </h6>
              </div>
            </div>
            {Object.entries(respData?.interview).map(([category, questions],index) => (
            <div className="step3_sec shadow">
              <div style={{ height: '10px', backgroundColor: colors[index] }}></div>
              <div className="center-start-end mt-10">
                <div>
                  <h6 className="f_1_3" style={{ color: colors[index]  }}>{category}</h6>
                </div>
                <div style={{cursor:'pointer'}} onClick={() => toggleQuestionVisibility(category)} 
                >
                  {questionVisibility[category] ? (<FontAwesomeIcon icon={faCircleChevronUp} style={{ color: "#95a0b1", }} size="2xl" />) : (<FontAwesomeIcon icon={faCircleChevronDown} style={{ color: "#95a0b1", }} size="2xl" />
                  )}
                </div>
              </div>
              <div style={{ display: questionVisibility[category] ? 'block' : 'none' }}>
                {questions.map((question, index) => (<div>
                  <div className="center-start-end mt-10">
                    <div>
                      <h6 className="f_1_0">{question}</h6>
                    </div>
                    <div>
                      <div className="blue pointer" onClick={() => toggleAnswerVisibility(index,category)} style={{fontWeight:'700'}}>{answerVisibility[category]?.[index] ? 'Hide' : 'Show'} Answer</div>
                    </div>
                  </div>
                  <div className="answer" style={{ display: answerVisibility[category]?.[index] ? 'block' : 'none' }}>
                    <div>
                      {answers?.[category] && answers[category][index]}
                    </div>
                  </div>

                  <div className="mt-10" style={{ display: answerVisibility[category]?.[index] ? 'block' : 'none' }}>
                    <div className="col-md-12">
                      <div className="formgroup">
                        <div className="col-md-4">
                          <label className="radio-button">
                            <input type="radio" value="happy" checked={!selectedOptions[category]?.[index] ||selectedOptions[category]?.[index] == 'happy'} onChange={() => handleRadioChange(category,index, 'happy')} />
                            <span className="radio-label">I am Happy with the answer</span>
                          </label>
                        </div>
                        <div className="col-md-4">
                          <label className="radio-button">
                            <input type="radio" value="notHappy" checked={selectedOptions[category]?.[index] == 'notHappy'} onChange={() => handleRadioChange(category,index, 'notHappy')} />
                            <span className="radio-label">I am not Happy</span>
                          </label>
                        </div>
                      </div>
                    </div>
                    {selectedOptions[category]?.[index] == 'notHappy' &&<div className="col-md-12 mt-10">
                      <div className="formgroup" style={{display:'flex'}}>
                        <div style={{marginRight:'20px',width:'90%'}}>
                          <textarea type="text" id="company" className="formcontrol shadow" style={{ height: "80px", padding: '10px',border:'1px solid #817c7c52' }}></textarea>
                          <label className="formlabel">
                            Please enter whats wrong with the answer and click submit for us to share a better answer
                          </label>
                        </div>
                        <div>
                        <button id="btnBack" onClick={() => clickedNotHappy(category,index)} type="button" className="btn-mangaii" >
                          Submit
                        </button>
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>))}
                <div>
                  <button onClick={() => deepDive(category)} type="button" className="deepdive">
                    Deep dive in to this topic for more questions
                  </button>
                </div>
              </div>
              
                
            </div>))}

          </section>


          <Footer />
        </div>
      ) : (
        <Loading content="" />
      )}
    </div>

  );
}

export default InterviewStep3;
