import React from 'react';
// import FacebookLogin from 'react-facebook-login';

const FacebookLoginButton = ({ onLogin }) => {
  const responseFacebook = (response) => {
    if (response.status === 'connected') {
      onLogin(response.accessToken);
    } else {
      console.log('Facebook login failed or was canceled.');
    }
  };

  return (
    <div>
      {/* <FacebookLogin
        appId="YOUR_APP_ID" // Replace with your Facebook App ID
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        textButton="Login with Facebook"
      /> */}
    </div>
  );
};

export default FacebookLoginButton;
