import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Nav from "./Nav";
import StepsFooter from "./StepsFooter";
import { commonConfig } from '../config.js';
import axios from "axios";

function Step10() {
  const [tabsData, setTabsData] = useState([]);
  const [respData, setRespData] = useState([]);
  const [respokData, setRespokData] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tagText, setTagText] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [results, setResults] = useState([]);
  const [data, setData] = useState({});
  const [skillsArray, setSkillsArray] = useState([]);
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [resp, setResp] = useState([]);
  const resume_id = localStorage.getItem('resume_id');
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    // import("./Css/Step10.css");
    let isMounted = true;
    axios({
      method: "GET",
      url: commonConfig.REACT_APP_API_URL + "experience",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
    }).then(res => {

      var decodedData = res.data;
      const result = decodedData.data;
      setResults(result);

    }).catch(err => {

    });
  }, []);


  // const chatGPT = async (question) => {
  //     return new Promise(async (resolve, reject) => {
  //         try {
  //             const apiUrl = commonConfig.GPT_API_URL;
  //             const apiKey = commonConfig.GPT_API_KEY;
  //             const requestBody = {
  //                 "model": "gpt-3.5-turbo",
  //                 "messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
  //                 "temperature": 0.7
  //             };
  //             const headers = {
  //                 'Content-Type': 'application/json',
  //                 'Authorization': `Bearer ${apiKey}`
  //             };
  //             const { data } = await axios.post(apiUrl, requestBody, { headers });
  //             const result = data.choices[0].message.content;
  //             const json = JSON.parse(result);
  //             setRespData(json);
  //             resolve(json);
  //         } catch (error) {
  //             console.error('Error:', error);
  //             reject(error);
  //         }
  //     });
  // };

  const [editorContent, setEditorContent] = useState("");
  const [blurredText, setBlurredText] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };
  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  const handleTagClick = (tag) => {

    setData(tag);

    if (textareaValue.includes(tag)) {
      setTextareaValue(tag);
    } else {
      setTextareaValue(tag);
    }
  };

  const getButtonClass = (tag) => {
    return textareaValue.includes(tag) ? 'tag1 active' : 'tag1';
  };

  const handleSubmit = (event) => {
    const resume_id = 1;
    // console.log("sss",JSON.stringify(childData));
    event.preventDefault();
    // axios({
    //   method: "POST",
    //   url: commonConfig.REACT_APP_API_URL + "contacts/summery_update",
    //   data: data,
    //   headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resume_id": resume_id },
    // }).then(res => {
    //   var decodedData = res.data;
    //   var status = res.status;
    //   if (status) {
        navigate('/step11');
    //   }
    // }).catch(err => {
    // });
  };

  const [formData, setFormData] = useState(Array(1).fill({ value: '' }));
  const [selectedDiv, setSelectedDiv] = useState([]);

  const handleAddTextbox = () => {
    setFormData(prevFormData => [...prevFormData, { value: '' }]);
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index] = { value };
    setFormData(updatedFormData);
  };

  const handleDivClick = (index) => {
    
    setSelectedDiv((prevSelectedItems) => {
      const updatedItems = [...prevSelectedItems];
      updatedItems[index] = !updatedItems[index];
      return updatedItems;
    });
  };


  return (
    <div className="step10">
      <main id="app" role="main">
        <section className="page-wrapper">
          <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
            <Nav value="4" />
            <div className="step1_sec">
              <div className="inner1_step1">
                <h3 className="headtag">
                  <span className="blue">Choose Profiles To Highlight In The Profile Summary.</span>
                </h3>
                <p style={{ marginBottom: '3%', fontWeight: '200px' }} className="h5 p-sub-title blue">
                  Choose About 3 Profiles Inorder To Be Highlighted In Your Summary.
                </p>
              </div>
              <div className="inner2_step1">

                <div className="col-md-12 center">
                    {results.map((result, index) => ( 
                      <div className="formcontroltext" key={index}>

                        <div key={index} className={`formcontroltextsmall ${selectedDiv[index] ? 'selected' : ''}`} onClick={() => handleDivClick(index)}>
                          
                        </div>
                        <div className="result_title" >{result.title}</div>
                      </div>
                    ))}
                </div>
                <div className="col-lg-6" style={{ clear: "both" }}>
                  <Link to="/step2" className="btn btn-secondary">
                    Back
                  </Link>
                </div>
                <div className="col-lg-6">
                  <button type="button" onClick={handleSubmit} className="btn btn-mangai">
                    Next
                  </button>
                </div>

              </div>
            </div>
          </div>
        </section>
      </main>
      <StepsFooter/>
    </div>
  );
}

export default Step10;


