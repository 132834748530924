import logo from './logo.svg';
import React,{ Component,useState,useEffect  } from 'react';
import axios from "axios";
import Home from './component/Home';
import Register from './component/Register';
import { Routes } from "react-router";
import { commonConfig } from './config.js';
import {
  HashRouter as Router,
  Route,
  Link,
  Navigate ,
  useNavigate , 
  useLocation,
  DefaultRoute
} from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Chat from './component/Chat';
import Faqs from './component/Faqs';
import Viewblog from './component/Viewblog';
import Login from './component/Login';
import MdlLogin from './component/MdlLogin';
import Cart from './component/Cart';
import Step1 from './component/Step1';
import Step2 from './component/Step2';
import Step2_1 from './component/Step2_1';
import Step3_1 from './component/Step3_1';
import Step3 from './component/Step3';
import Pricing from './component/Pricing';
import Plansfront from './component/Plansfront';
import Template from './component/Template';
import Cookie  from './component/Cookie';
import Terms from './component/Terms';
import Test from './component/Test';
import Blogs from './component/Blogs';
import Step4 from './component/Step4';
import Step5 from './component/Step5';
import Step6 from './component/Step6';
import Step7 from './component/Step7';
import Step8 from './component/Step8';
import Step9 from './component/Step9';
import Step10 from './component/Step10';
import Step11 from './component/Step11';
import Step12 from './component/Step12';
import Logobar from './component/Logobar';
import Temp1 from './component/Temp1';
import Temp2 from './component/Temp2';
import Temp3 from './component/Temp3';
import Temp4 from './component/Temp4';
import Temp5 from './component/Temp5';
import Temp6 from './component/Temp6';
import Temp7 from './component/Temp7';
import Temp8 from './component/Temp8';
import Temp9 from './component/Temp9';
import Temp10 from './component/Temp10';
import Temp11 from './component/Temp11';
import Temp12 from './component/Temp12';


import Step13 from './component/Step13';
import Step14 from './component/Step14';
import Settings from './component/Settings';
import PdfGenerator from './component/PdfGenerator';
import PasswordChange from './component/PasswordChange';
import Forgotpassword from './component/Forgotpassword';
import Privacy from './component/Privacy';
import Aboutus from './component/Aboutus';
import Contactus from './component/Contactus';
import Choose from './component/Choose';
import Plans from './component/Plans';
import Cover from './component/Cover';
import CoverStep1 from './component/CoverStep1.jsx';
import CoverStep2 from './component/CoverStep2.jsx';
import CoverStep3 from './component/CoverStep3.jsx';
import CoverStep4 from './component/CoverStep4.jsx';
import Interview from './component/Interview.jsx';
import InterviewStep1 from './component/InterviewStep1.jsx';
import InterviewStep2 from './component/InterviewStep2.jsx';
import InterviewStep3 from './component/InterviewStep3.jsx';
import Dynamictemp1 from './component/Dynamictemp1';
import Dynamictemp2 from './component/Dynamictemp2';
import Dynamictemp3 from './component/Dynamictemp3';
import Dynamictemp4 from './component/Dynamictemp4';
import Dynamictemp5 from './component/Dynamictemp5';
import Dynamictemp6 from './component/Dynamictemp6';
import Dynamictemp7 from './component/Dynamictemp7';
import Dynamictemp8 from './component/Dynamictemp8';
import Dynamictemp9 from './component/Dynamictemp9';
import Dynamictemp10 from './component/Dynamictemp10';
import Dynamictemp11 from './component/Dynamictemp11';
import Dytemp1 from './component/Dytemp1';
import Dytemp2 from './component/Dytemp2';
import Dytemp3 from './component/Dytemp3';
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import Loading from './component/Loading';
import FacebookLoginButton from './component/FacebookLoginButton';
function App(){

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    

    useEffect(() => {
      console.log("currentPath","currentPath");
      // if(ip == null){
        
      // }
    }, []);
   
    return (
        
      <div>
      {loading ? (
        <Loading />
      ) : (
          <Routes basename={"/app"}>
            <Route exact path="/register" element={
                      <Register 
                      />
              } >
            </Route>
            <Route exact path="/login/:email?" element={
                      <Login/>
              } >
            </Route>
            <Route exact path="/mdllogin" element={
                      <MdlLogin/>
              } >
            </Route>
            <Route exact path="/cart" element={
                      <Cart/>
              } >
            </Route>
            <Route exact path="" element={
                      <Home/>
              } >
            </Route>
            <Route exact path="/home" element={
                      <Home/>
              } >
            </Route>
            <Route exact path="/step1" element={
                      <Step1/>
              } >
            </Route>
            <Route exact path="/step2" element={
                      <Step2/>
              } >
            </Route>
            <Route exact path="/step2/:exp_id" element={
                      <Step2/>
              } >
            </Route>
            <Route exact path="/step2_1" element={
                      <Step2_1/>
              } >
            </Route>
            <Route exact path="/step3/:edu_id" element={
                      <Step3/>
              } >
            </Route>
            <Route exact path="/step3" element={
                      <Step3/>
              } >
            </Route>
            <Route exact path="/step3_1" element={
                      <Step3_1/>
              } >
            </Route>
            <Route exact path="/template" element={
                      <Template/>
              } >
            </Route>
            <Route exact path="/template/:pagename" element={
                      <Template/>
              } >
            </Route>
            <Route exact path="/chat" element={
                      <Chat/>
              } >
  
            </Route>
            <Route exact path="/pricing" element={
                      <Plans/>
              } >
            </Route>
            <Route exact path="/plansfront" element={
                      <Plansfront/>
              } >
            </Route>
            <Route exact path="/plans" element={
                      <Plans/>
              } >
            </Route>
            <Route exact path="/cover" element={
                      <Cover/>
              } >
            </Route>
            <Route exact path="/coverStep1" element={
                      <CoverStep1/>
              } >
            </Route>
            <Route exact path="/coverStep2" element={
                      <CoverStep2/>
              } >
            </Route>
            <Route exact path="/coverStep3" element={
                      <CoverStep3/>
              } >
            </Route>
            <Route exact path="/coverStep4" element={
                      <CoverStep4/>
              } >
            </Route>
            <Route exact path="/interview" element={
                      <Interview/>
              } >
            </Route>
            <Route exact path="/interviewStep1" element={
                      <InterviewStep1/>
              } >
            </Route>
            <Route exact path="/interviewStep2" element={
                      <InterviewStep2/>
              } >
            </Route>
            <Route exact path="/interviewStep3" element={
                      <InterviewStep3/>
              } >
            </Route>
            <Route exact path="/test" element={
                      <Test/>
              } >
            </Route>
            <Route exact path="/step4" element={
                      <Step4/>
              } >
            </Route>
            <Route exact path="/step4/:exp_id" element={
                      <Step4/>
              } >
            </Route>
            <Route exact path="/step5" element={
                      <Step5/>
              } >
            </Route>
            <Route exact path="/step6" element={
                      <Step6/>
              } >
            </Route>
            <Route exact path="/step7/:exp_id" element={
                      <Step7/>
              } >
            </Route>
            <Route exact path="/step8/:exp_id" element={
                      <Step8/>
              } >
            </Route>
            <Route exact path="/step9" element={
                      <Step9/>
              } >
            </Route>
            <Route exact path="/step10" element={
                      <Step10/>
              } >
            </Route>
            <Route exact path="/step11" element={
                      <Step11/>
              } >
            </Route>
            <Route exact path="/step12" element={
                      <Step12/>
              } >
            </Route>
            <Route exact path="/temp1" element={
                      <Temp1/>
              } >
            </Route>
            <Route exact path="/blogs" element={
                      <Blogs/>
              } >
            </Route>
            <Route exact path="/dynamictemp1" element={
                      <Dynamictemp1/>
              } >
            </Route>
            <Route exact path="/dynamictemp2" element={
                      <Dynamictemp2/>
              } >
            </Route>
            <Route exact path="/dynamictemp3" element={
                      <Dynamictemp3/>
              } >
            </Route>
            <Route exact path="/dynamictemp4" element={
                      <Dynamictemp4/>
              } >
            </Route>
            
            <Route exact path="/dynamictemp5" element={
                      <Dynamictemp5/>
              } >
            </Route>
            <Route exact path="/dynamictemp6" element={
                      <Dynamictemp6/>
              } >
            </Route>
            <Route exact path="/dynamictemp7" element={
                      <Dynamictemp7/>
              } >
            </Route>
            <Route exact path="/dynamictemp8" element={
                      <Dynamictemp8/>
              } >
            </Route>
            <Route exact path="/dynamictemp9" element={
                      <Dynamictemp9/>
              } >
            </Route>
            <Route exact path="/dynamictemp10" element={
                      <Dynamictemp10/>
              } >
            </Route>
            
            <Route exact path="/dynamictemp11" element={
                      <Dynamictemp11/>
              } >
            </Route>
            <Route exact path="/dytemp1" element={
                      <Dytemp1/>
              } >
            </Route>
            <Route exact path="/dytemp2" element={
                      <Dytemp2/>
              } >
            </Route>
            <Route exact path="/dytemp3" element={
                      <Dytemp3/>
              } >
            </Route>

            
          
            <Route exact path="/temp2" element={
                      <Temp2/>
              } >
            </Route>
            <Route exact path="/temp3" element={
                      <Temp3/>
              } >
            </Route>
            <Route exact path="/temp4" element={
                      <Temp4/>
              } >
            </Route>
            <Route exact path="/temp5" element={
                      <Temp5/>
              } >
            </Route>
            <Route exact path="/temp6" element={
                      <Temp6/>
              } >
            </Route>
            <Route exact path="/temp7" element={
                      <Temp7/>
              } >
            </Route>
            <Route exact path="/temp8" element={
                      <Temp8/>
              } >
            </Route>
            <Route exact path="/temp9" element={
                      <Temp9/>
              } >
            </Route>
            <Route exact path="/temp10" element={
                      <Temp10/>
              } >
            </Route>
            <Route exact path="/temp11" element={
                      <Temp11/>
              } >
            </Route>
            <Route exact path="/temp12" element={
                      <Temp12/>
              } >
            </Route>
            <Route exact path="/step13" element={
                      <Step13/>
              } >
            </Route>
            <Route exact path="/step14" element={
                      <Step14/>
              } >
            </Route>
            <Route exact path="/settings" element={
                      <Settings/>
              } >
            </Route>
            <Route exact path="/faqs" element={
                      <Faqs/>
              } >
            </Route>
            <Route exact path="/logobar" element={
                      <Logobar/>
              } >
            </Route>
            <Route exact path="/viewblog" element={
                      <Viewblog/>
              } >
            </Route>
            <Route exact path="/passwordchange" element={
                      <PasswordChange/>
              } >
            </Route>
            <Route exact path="/forgotpassword" element={
                      <Forgotpassword/>
              } >
            </Route>
            <Route exact path="/privacy" element={
                      <Privacy/>
              } >
            </Route>
            <Route exact path="/cookie" element={
                      <Cookie/>
              } >
            </Route>
            <Route exact path="/terms" element={
                      <Terms/>
              } >
            </Route>
            <Route exact path="/aboutus" element={
                      <Aboutus/>
              } >
            </Route>
            <Route exact path="/contactus" element={
                      <Contactus/>
              } >
            </Route>
            <Route exact path="/facebookloginbutton" element={
                      <FacebookLoginButton/>
              } >
            </Route>
            <Route exact path="/choose" element={
                      <Choose/>
              } >
            </Route>
            <Route exact path="/linkedin" component={LinkedInCallback} />
            
            
           
          </Routes>
          )}
        </div>
            
      );
     
      
}

export default App;
