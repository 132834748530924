import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import logo from "../img/logo.png";
import { BrowserRouter as Router, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { commonConfig } from '../config.js';
import axios from "axios";

function Nav(props) {

  const [step1, setStep1] = useState('');
  const [step2, setStep2] = useState('');
  const [step3, setStep3] = useState('');
  const [step4, setStep4] = useState('');
  const [step5, setStep5] = useState('');
  const [step6, setStep6] = useState('');
  const [step8, setStep8] = useState('');
  const [stepnumber, setStepnumber] = useState(1);
  const [plan, setPlan] = useState([]);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const CommonData = localStorage.getItem('common_data');
  const ip = localStorage.getItem('ip');
  const location = useLocation();
  const currentPath = location.pathname;

  const goBack = () => {
    navigate(-1); // Go back one step in history
  };

  useEffect(() => {
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "get-ip",
      data: { page: currentPath },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data.ip_address;
      localStorage.setItem('ip', decodedData);
    }).catch(err => {

    });

    if (CommonData) {
      if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
        if (props.action != '12') {
          navigate('/step14');
        }
      }
    }

    console.log("props.value", props.value);
    setStepnumber(props.value);
    if (props.value === 100) {
      setStep8("active");
      setStep6("active");
      setStep5("active");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value >= 7) {
      setStep8("progress8");
      setStep6("active");
      setStep5("active");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 6) {
      setStep6("progress6");
      setStep5("active");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    }
    else if (props.value === 5) {
      setStep5("progress5");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 4) {
      setStep4("progress4");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 3) {
      setStep3("progress3");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 2) {

      setStep2("progress2");
      setStep1("active");
    } else if (props.value === 1) {
      setStep1("progress1");
    }
  }, [props.value]);


  return (
    <div>
      <div className="navpage system_view" style={{ marginTop: '1%' }}>
        <div className="col-sm-4">
          <ul className="nav">
            <Link to="/home">
              <img src={logo} alt="Logo" className="logo-image" />
            </Link>
          </ul>
        </div>
        {currentPath != "/plans" && <div className="col-sm-8">
          <nav className="navbar" aria-label="top-navbar">
            <div className="progress-wrapper  " role="navigation" aria-label="Progress bar">
              <ul className="progress-group" id="progressBar">
                <li className={"progress-item cursor-default " + step1} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="CNTC" to="/step1">CONTACT</Link>
                </li>
                <li className={"progress-item cursor-default " + step2} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="EXPR" {...(props.value >= 2 ? { to: '/step2_1' } : {})}>EXPERIENCE</Link>
                </li>
                <li className={"progress-item cursor-default " + step3} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="EDUC" {...(props.value >= 3 ? { to: '/step3_1' } : {})}>EDUCATION</Link>
                </li>
                <li className={"progress-item cursor-default " + step4} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="HILT" {...(props.value >= 4 ? { to: '/step4' } : {})}>SKILLS</Link>
                </li>
                <li className={"progress-item cursor-default " + step5} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="SUMM" {...(props.value >= 5 ? { to: '/step5' } : {})}>SUMMARY</Link>
                </li>
                <li className={"progress-item cursor-default " + step6} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="SUMM" {...(props.value >= 6 ? { to: '/step6' } : {})}>MORE</Link>
                </li>
                {props.value == 100 ? (
                  <li className={"progress-item cursor-default " + step8} style={{ width: '108.4px' }}>
                    <div class="dropdown_nav">
                      <div class="dropbtn_nav"><Link className="progress-link" id="FNLZ" {...(props.value >= 9 ? { to: '/step13' } : {})}>FINALISE</Link></div>
                      {/* <div class="dropdown-content_nav">
                                  <a><Link id="FNLZ" to="/step7">SKILLS</Link></a>
                                  <a><Link id="FNLZ" to="/step8">RESPONSIBILITIES</Link></a>
                                  <a><Link id="FNLZ" to="/step9">SUMMARY</Link></a>
                                  <a><Link id="FNLZ" to="/step11">ACHIEVEMENTS-1</Link></a>
                                  <a><Link id="FNLZ" to="/step12">ACHIEVEMENTS-2</Link></a>
                                </div> */}
                    </div>
                  </li>
                ) : (
                  <li className={"progress-item cursor-default " + step8} style={{ width: '108.4px' }}>
                    <Link className="progress-link" id="SUMM" {...(props.value >= 9 ? { to: '/step13' } : {})}>FINALISE</Link>
                  </li>
                )}
                {/* <li className="nav-item"><Link className="nav-link nav-hide" to="/information/contact.aspx"
                                target="_blank"><i className="fas fa-comment fs-14 icon-contact"></i> Contact Us</Link></li> */}
              </ul>
              <div className="progress">
                <div className="progress-bar" id="activeState" style={{ width: '149.9px' }}></div>
              </div>
            </div>

          </nav>
        </div>}
      </div>
      <div className="mobile_view">
        {currentPath != "/plans" && <div className="dropdown1 center">
          {/* <img className="dropbtn image15" /> */}
          {currentPath == "/step1" && <button type="button" className="btn-joe hdr_btn">Contact</button>}
          {currentPath == "/step2" && <button type="button" className="btn-joe hdr_btn">Experience</button>}
          {currentPath == "/step2_1" && <button type="button" className="btn-joe hdr_btn">Experience</button>}
          {currentPath == "/step3" && <button type="button" className="btn-joe hdr_btn">Education</button>}
          {currentPath == "/step3_1" && <button type="button" className="btn-joe hdr_btn">Education</button>}
          {currentPath == "/step4" && <button type="button" className="btn-joe hdr_btn">Skills</button>}
          {currentPath == "/step5" && <button type="button" className="btn-joe hdr_btn">Summary</button>}
          {currentPath == "/step6" && <button type="button" className="btn-joe hdr_btn">More Details</button>}
          {currentPath == "/step7" && <button type="button" className="btn-joe hdr_btn">Experience</button>}
          {currentPath == "/step8" && <button type="button" className="btn-joe hdr_btn">Experience</button>}
          {currentPath == "/step9" && <button type="button" className="btn-joe hdr_btn">Finalize</button>}
          {currentPath == "/step13" && <button type="button" className="btn-joe hdr_btn">Finalize</button>}
          {/* { props.value == "2" && <button type="button" className="btn-joe hdr_btn">Experience</button>} */}
          <div className="dropdown1-content">
            <Link className={(currentPath === '/step1') ? 'm_active' : ''} to="/step1">Contact</Link>
            <Link className={(currentPath === '/step2' || currentPath === '/step2_1' || currentPath === '/step7' || currentPath === '/step8') ? 'm_active' : ''} {...(props.value >= 2 ? { to: '/step2_1' } : {})}>Experience</Link>
            <Link className={(currentPath === '/step3' || currentPath === '/step3_1') ? 'm_active' : ''} {...(props.value >= 3 ? { to: '/step3_1' } : {})}>Education</Link>
            <Link className={(currentPath === '/step4') ? 'm_active' : ''} {...(props.value >= 4 ? { to: '/step4' } : {})}>Skills</Link>
            <Link className={(currentPath === '/step5') ? 'm_active' : ''} {...(props.value >= 5 ? { to: '/step5' } : {})}>Summary</Link>
            <Link className={(currentPath === '/step6') ? 'm_active' : ''} {...(props.value >= 6 ? { to: '/step6' } : {})}>More Details</Link>
            <Link className={(currentPath === '/step9' || currentPath === '/step13') ? 'm_active' : ''} to={props.value >= 9 ? '/step13' : '#'}>
              Finalize
            </Link>

          </div>
        </div>}
      </div>
    </div>
  );
}

export default Nav;


