import React, { Component } from "react";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import './Css/Privacy.scss';
import Footer from "./Footer";
import MainNav from "./MainNav";
function Cookies() {
    return (
        <div className="privacy">
            <MainNav />
            <div>
            <h4 className="coming_soon">Coming Soon....</h4>
            </div>
            
     
        <Footer />
        </div>

        );
}
export default Cookies;

