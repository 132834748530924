import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { commonConfig } from '../config.js';
import axios from "axios";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import image1 from './Assets/templates/temp_1.png';
import image2 from './Assets/templates/temp_2.png';
import image3 from './Assets/templates/temp_3.png';
import image4 from './Assets/templates/temp_4.png';
import image5 from './Assets/templates/temp_5.png';
import image6 from './Assets/templates/temp_6.png';
import image7 from './Assets/templates/temp_7.png';
import image8 from './Assets/templates/temp_8.png';

function BlurImage({ imageUrl,data,can_edit,can_download }) {
  const [hovered, setHovered] = useState(false);
  const [isDeleteOpen, setisDeleteOpen] = useState(false);
  const [deleteid, setDeleteid] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let template = data.template;
  if(!template){
    template = 1;
  }
  let images = [];
	images = ['',image1, image2, image3, image4, image5, image6, image7, image8];

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const EditCV = (resume_id) => {
    localStorage.setItem('resume_id', resume_id);
    if (can_edit == 1) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        const step = result.contact[0].step;

        localStorage.setItem('resume_data', JSON.stringify(result));
        if (step == 1) {
          navigate('/step2');
        } else if (step == 2) {
          navigate('/step3');
        } else if (step == 3) {
          navigate('/step4');
        } else if (step == 4) {
          navigate('/step5');
        } else if (step == 5) {
          navigate('/step6');
        } else if (step == 6) {
          navigate('/step9');
        } else if (step == 7) {
          navigate('/step2');
        } else if (step == 8) {
          navigate('/step2');
        } else if (step == 9) {
          navigate('/step13');
        } else {
          navigate('/step13');
        }
      }).catch(err => {

      });
    } else {
      navigate('/pricing');
    }
    // navigate('/step2');
  }
  const Deletetrigger = (resume_id) => {
    setDeleteid(resume_id);
    setisDeleteOpen(true);
  }
  const Deleteresume = () => {
    axios({
      method: "POST",
      data: { resume_id: deleteid },
      url: commonConfig.REACT_APP_API_URL + "delete_resume",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      setisDeleteOpen(false);
      window.location.reload();
    }).catch(err => {

    });
  }
  const handleDelClose = () => {
    setisDeleteOpen(false);
  };
  const handleNavPlans = () => {
    navigate('/plans');
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const DownloadCV = (resume_name) => {
    const fileUrl = commonConfig.PDF_URL + resume_name + ".pdf";
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = resume_name+'.pdf'; // Specify the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className={`${screenWidth > 900 ? '' : 'center'}`}>
      <div
        className="image-container hovered"
        // className={`image-container ${hovered ? 'hovered' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img className="temps14" src={images[template]} alt="Hovered Image" />
        {/* {hovered && ( */}
          <div className="icon-container">
            <div className="clickable" onClick={() => EditCV(data.id)} >
              <FontAwesomeIcon icon={faEdit} />
            </div>
            <div className="clickable" onClick={() => Deletetrigger(data.id)}>
              <FontAwesomeIcon icon={faTrashAlt}  />
            </div>
            {can_download==1 ? (<div className="clickable" onClick={() => DownloadCV(data.resumename)}>
              <FontAwesomeIcon icon={faDownload} />
            </div>) : ( <div className="clickable" onClick={handleNavPlans}>
              <FontAwesomeIcon icon={faDownload} />
            </div>)}
          </div>
          {data.resumename && <div className="icon-container1">
            <div className="clickable f-11">
              <div>
                <p className='center white'><b>{data.resumename}</b></p>
                {data.published_on && <p className='center white'><b>Published On: {data.published_on}</b></p>}
              </div>
            </div>
          </div>}
        
      </div>

      {isDeleteOpen && (
        <div className="popup-overlay">
          <div className="popup-container">
            {/* <FontAwesomeIcon className="close-button" onClick={handleClose} icon={faTimes} /> */}
            <h4>Do you want to delete this resume?</h4>
            <button id="btnBack" type="button" className="btn-stepsback" style={{ marginTop: '40px' }} onClick={handleDelClose}>No</button>
            <button id="btnBack" type="submit" onClick={Deleteresume} className="btn-mangaii" style={{ marginTop: '40px' }}  >Yes. Delete it</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlurImage;
