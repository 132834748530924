import React, { Component, useEffect, useState, useRef } from "react";
import CoverNav from "./CoverNav";
import avatar from "./Assets/avatar.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  HashRouter as Router,
  Route,
  Link,
  Navigate,
  useNavigate,
  DefaultRoute
} from "react-router-dom";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { commonConfig } from '../config.js';
import axios from "axios";
import Loading from './Loading';
import StepsFooter from './StepsFooter';

function CoverStep3() {

  const [stepno, setStepno] = useState(3);
  const [page, setPage] = useState(false);
  const [isEdit, setIsEdit] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [customcount, setCustomcount] = useState(0);
  const [expData, setExpData] = useState([]);
  const [eduData, setEduData] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [selectedExpDiv, setSelectedExpDiv] = useState([]);
  const [selectedEduDiv, setSelectedEduDiv] = useState([]);
  const [selectedSkillDiv, setSelectedSkillDiv] = useState([]);

  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const coverResumeId = localStorage.getItem('coverResumeId');
  const cover_id = localStorage.getItem('cover_id');

  const [formData, setFormData] = useState({
    total_experience:false,
    relevant_experience:false,
    total_experience_value:'',
    relevant_experience_value:'',
    experience: [],
    education: [],
    skills: [],
    custom: [{
      custom_name: '',
      ckeditor: '',
    }],
    cover_id: cover_id
  });


  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    import('./Css/CoverStep.scss');

    getEditdata();
    getResumedata();

    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const getEditdata = () =>{
    if (cover_id) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "cover/finalize",
				data: { cover_id: cover_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
        const step3 = result.step3;
        if(step3){
          const step3data = JSON.parse(step3);
          if(step3data.total_experience == 'true'){
            step3data.total_experience = true;
          }
          // const booleanResponse = Object.fromEntries(
          //   Object.entries(step3data).map(([key, value]) => [key, value === "true"])
          // );
          console.log("step3data",step3data);
          setFormData(step3data);
          setSelectedExpDiv(step3data.experience.map(str => parseInt(str, 10)));
          setSelectedEduDiv(step3data.education.map(str => parseInt(str, 10)));
          setSelectedSkillDiv(step3data.skills);
        }
        // setStepno(result.step);
        if(result.step == 4){
          setStepno(result.step);
        }else{
          setStepno(result.step + 1);
        }
        setIsEdit(1);

			}).catch(err => {

			});
		}
  }
  const getResumedata = () =>{
    if (coverResumeId) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "finalize",
				data: { resume_id: coverResumeId },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
        setExpData(result.experience);
        setEduData(result.education);
        let skills =result.skills[0].skills_array;
        
        if(skills){
          skills = JSON.parse(skills);
          setSkillsData(skills.jobskills);
        }
        

			}).catch(err => {

			});
		}
  }

  const handleExpDivClick = (id) => {
    const updatedFormData = { ...formData };
    const experienceIds = updatedFormData.experience;
    const idIndex = experienceIds.indexOf(id);

    if (idIndex !== -1) {
      experienceIds.splice(idIndex, 1);
    } else {
      experienceIds.push(id);
    }
    setSelectedExpDiv(experienceIds);
    setFormData(updatedFormData);
  };
  const handleEduDivClick = (id) => {
    const updatedFormData = { ...formData };
    const experienceIds = updatedFormData.education;
    const idIndex = experienceIds.indexOf(id);
    if (idIndex !== -1) {
      experienceIds.splice(idIndex, 1);
    } else {
      experienceIds.push(id);
    }
    setSelectedEduDiv(experienceIds);
    setFormData(updatedFormData);
  };
  const handleSkillDivClick = (id) => {
    const updatedFormData = { ...formData };
    const experienceIds = updatedFormData.skills;
    const idIndex = experienceIds.indexOf(id);
    if (idIndex !== -1) {
      experienceIds.splice(idIndex, 1);
    } else {
      experienceIds.push(id);
    }
    setSelectedSkillDiv(experienceIds);
    setFormData(updatedFormData);
  };

  
  

  const handleChange = (event) => {
    const { name, value } = event.target || event;

    if (name == 'total_experience') {
      const { name, checked } = event.target || event;
      const updatedFormData = { ...formData };
      updatedFormData[name] = checked;
      setFormData(updatedFormData);
    }else if (name == 'relevant_experience') {
      const { name, checked } = event.target || event;
      const updatedFormData = { ...formData };
      updatedFormData[name] = checked;
      setFormData(updatedFormData);
    } else {
      const updatedFormData = {...formData};
      updatedFormData[name] = value;
      setFormData(updatedFormData);
    }
  };

  const handleChange2 = (event, index) => {
    const { name, value } = event.target;
    const updatedFormData = {...formData};
    updatedFormData['custom'][index][name] = value;
    setFormData(updatedFormData);
  };

  const handleEditorChange = (event, editor, index) => {
    const data = editor.getData();
    const updatedFormData = {...formData};
    updatedFormData['custom'][index]['ckeditor'] = data;
    setFormData(updatedFormData);
  };

  const handleAddField2 = () => {
    let data ={
      custom_name: '',
      ckeditor: '',
    };
    const updatedFormData = {...formData};
    updatedFormData['custom'].push(data);
    setFormData(updatedFormData);
  };

  

  const handleNext = () => {

    setPage(false);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "cover/Step3",
      data: formData,
      headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + commonConfig.API_TOKEN, "user_id": userId, "step": stepno},
    }).then(res => {
      var decodedData = res.data;
      var status = res.status;
      if (status) {
        
        prompt(decodedData.data);
        // navigate('/coverStep2');
      }
    }).catch(err => {

    });
  };


  const prompt = (data) => {

    // if (gpt_token == 0) {
      // setGptloaded(1);
      const pdata = data.step3;
      const experincedata = pdata.experience.map(str => parseInt(str, 10));
      const educationdata = pdata.education.map(str => parseInt(str, 10));
      const customdata = pdata.custom;
      const skillsdata = pdata.skills;
      
      let experience = '';
      expData?.map((item, index) => {
        if (experincedata.includes(item.id)) {
          experience += item.employer+','+item.title+','+item.GPT_resp;
        }
      });

      let education = '';
      eduData?.map((item, index) => {
        if (educationdata.includes(item.id)) {
          education += item.education+','+item.course+','+item.year_of_completion;
        }
      });

      let custom = '';
      customdata?.map((item, index) => {
        custom+=index+1+'. '+item.custom_name+'- '+item.ckeditor+' ';
      });
      console.log("question",education);
      
      let question = 'Hi! You are an expert writer. I am applying for a job and need your expertise to write a cover letter for the following job: Company Applying to: "' + data.company + '", Department Applying to: "' + data.department + '" ,Designation for the applied job:  "' + data.job_applied_to + '", Level of the job according to the company:  "' + data.level + '", Location of the applied job:  "' + data.location + '", Job Details of the applied job:  "' + data.description + '" ';
      question+='I am giving below the details about me which could help you to write an impressive letter:';
      question+='Relevant jobs which I have done: "'+experience+'". ';
      question+='Relevant Education which could be useful: "'+education+'". ';
      question+='Special points which can be added to the cover letter: "'+custom+'". ';
      question+='Skills I possess: "'+skillsdata+'". ';
      question+='With the above information please create a letter within "'+data.length+'" words which is written in the "'+data.tone+'" tone.Please make sure it is written in such a way that it highlights my achievements and after reading the recruiter or the hiring manager calls me for the interview.please give the final letter in short paragraphs which are logically written.Return these statement as a single JSON Object with cover_letter key.Do not return any non-json text or numbering. i want response in below format.{"cover_letter": "content...."} and ignore Sincerely, [Your Name]';

      

      chatGPT(question).then(responseData => {
        save_data(responseData.cover_letter, cover_id);
      }).catch(error => {
        console.error('Error:', error);
      });
  }

  const chatGPT = async (question) => {
		return new Promise(async (resolve, reject) => {
			try {
				const apiUrl = commonConfig.GPT_API_URL;
				const apiKey = commonConfig.GPT_API_KEY;
				const requestBody = {
					"model": "gpt-3.5-turbo",
					"messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
					"temperature": 0.7
				};
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${apiKey}`
				};
				const { data } = await axios.post(apiUrl, requestBody, { headers });
				const result = data.choices[0].message.content;
        let jsonContent = result.replace('```json', '').replace('```', '');
				const json = JSON.parse(jsonContent);
        resolve(json);
			} catch (error) {
				console.error('Error:', error);
				reject(error);
			}
		});
	};

  const save_data = (item, id) => {
		const requestData = {
			resp: item,
			id: id,
			type: 'resp'
		};
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "cover/GPTstore",
			data: requestData,
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN},
		}).then(res => {
      // setPage(true);
      navigate('/coverStep4');
		}).catch(err => {
			// Handle error
		});
	}

  return (

    <div className="step1page">
      {page ? (
        <div>
          <ToastContainer position="top-center" theme="dark" />
          <main id="app" role="main">
            <section className="page-wrapper">
              <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
                <CoverNav value={stepno} />
                <div className="step1_sec">
                  <div className="inner1_step1" style={{ textAlign: 'center' }}>
                    <h3 className="headtag"><span className="blue">Select from Resume (Choose whatever is most relevant for the job you are applying to)</span>
                    </h3>
                    
                  </div>
                  <form action="" method="">
                    <div className="col-md-12 w-80 m-auto shadow w-100">
                      <div className="col-md-12">
                        <div className="bold subtitle_padding">Experience</div>
                      </div>
                      <div className="col-md-12 border-bottom p-20">
                        <div className="col-md-6s checkbox-button">
                          <input className="blue" onChange={(event) => handleChange(event)} checked={formData.total_experience}  type="checkbox" name="total_experience"/>&nbsp;&nbsp;&nbsp; Total Experience &nbsp;&nbsp;&nbsp;
                          <input type="text" value={formData.total_experience_value} className="custom_box" name="total_experience_value" onChange={(event) => handleChange(event)} />
                        </div>
                        <div className="col-md-6s checkbox-button">
                          <input className="blue" onChange={(event) => handleChange(event)} checked={formData.relevant_experience}  type="checkbox" name="relevant_experience"/>&nbsp;&nbsp;&nbsp; Relevant Experience &nbsp;&nbsp;&nbsp;
                          <input type="text" value={formData.relevant_experience_value} className="custom_box" name="relevant_experience_value" onChange={(event) => handleChange(event)} />
                        </div>
                      </div>
                      <div className="col-md-12 p-20">
                        {expData?.map((item, index) => (
                          <div className="m-10 checkbox-button">
                            <input className="blue" onChange={() => handleExpDivClick(item.id)} checked={selectedExpDiv.includes(item.id)}  type="checkbox" name="working"/>&nbsp;&nbsp;&nbsp; 
                            <span>
                              <b>{item.title}</b>, {item.employer}, {item.city} - 
                              <span id="FIELD_JSTD" format="%m/%Y">{item.start_date}</span><span dependency="JSTD+EDDT"> - </span>
                              <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : item.end_date}</span>
                            </span>
                          </div>
                        ))}
                      </div>
                      
                    </div><br/>

                    <div className="col-md-12 w-80 m-auto shadow w-100">
                      <div className="col-md-12">
                        <div className="bold subtitle_padding">Education</div>
                      </div>
                      <div className="col-md-12 mb-20">
                        {eduData?.map((item, index) => (
                          <div className="m-10 checkbox-button">
                            <input className="blue" onChange={() => handleEduDivClick(item.id)} checked={selectedEduDiv.includes(item.id)}  type="checkbox" name="working"/>&nbsp;&nbsp;&nbsp; 
                            <span>
                              {item.education}, {item.course} - {item.year_of_completion}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div><br/>

                    <div className="col-md-12 w-80 m-auto shadow w-100">
                      <div className="col-md-12">
                        <div className="bold subtitle_padding">Skills</div>
                      </div>
                      <div className="col-md-12 mb-20">
                        {skillsData?.map((item, index) => (
                          <div className="col-md-3 m-10 checkbox-button">
                            <input className="blue" onChange={() => handleSkillDivClick(item)} checked={selectedSkillDiv.includes(item)}  type="checkbox" name="working"/>&nbsp;&nbsp;&nbsp; 
                            <span>
                              {item}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div><br/>
                    <div className="col-md-12 w-80 m-auto shadow w-100">
                      <div className="col-md-12">
                        <div className="bold subtitle_padding">Custom</div>
                        <p style={{ paddingBottom: '1%', fontWeight: '200px;' }} className="p_tag ">You can add anything which you would like to showcase in your cover letter like Social Work, Certifications etc. Click on add more headings</p>
                      </div>
                      <div className="col-md-12 mb-20">
                        {formData.custom?.map((field, index) => (
                          <div className="row" key={index}>
                            <div className="col-lg-12">
                              <div className="formgroup">
                                <input
                                  type="text"
                                  name="custom_name"
                                  className="formcontrol w-25"
                                  value={field.custom_name}
                                  onChange={(event) => handleChange2(event, index)}
                                />
                                <label className="formlabel" htmlFor={`custom_name${index}`}>
                                  CUSTOM NAME
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-12" style={{ marginBottom: '55px' }}>
                              <CKEditor
                                editor={ClassicEditor}
                                data={field.ckeditor}
                                onChange={(event, editor) => handleEditorChange(event, editor, index)}

                              />
                            </div>
                            
                          </div>
                        ))}
                        {/* <div className="col-lg-6">
                          <a className="addmore" onClick={handleAddField2}>+ Add Custom</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </div> */}
                      </div>
                    </div><br/>

                    <div className="col-md-12">
                      <div className="col-lg-6 half">
                        <button id="btnBack" type="button"  className="btn-stepsback">Back</button>
                      </div>
                      <div className="col-lg-6 half">
                        <button id="btnBack" type="button" onClick={handleNext} className="btn-mangaii" >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </main>
          <StepsFooter />
        </div>
      ) : (
        <Loading content="Please Wait!!!" />
      )}
    </div>
  );
}

export default CoverStep3;
