import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate,useParams } from "react-router-dom";
import Nav from "./Nav"; import { commonConfig } from "../config.js";
import axios from "axios";
import link_button from "./Assets/plus.png";
import link_button2 from "./Assets/minus.png";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import LoadingGIF from './LoadingGIF';

function Step8() {
    const { exp_id } = useParams();
	const [tabsData, setTabsData] = useState([]);
	const [respData, setRespData] = useState([]);
	const [respokData, setRespokData] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [tagText, setTagText] = useState('');
	const [textareaValue, setTextareaValue] = useState('');
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [gptloaded, setGptloaded] = useState(0);
	let is_edit = 0;
	const [stepno, setStepno] = useState(2);
	const [gptresp, setGptresp] = useState(0);
	const [toggleClass, setToggleClass] = useState(false);
	const [editorContent, setEditorContent] = useState("");
	const [blurredText, setBlurredText] = useState("");
	const [page, setPage] = useState(false);
	const resume_id = localStorage.getItem('resume_id');
	const userId = localStorage.getItem('userId');
	const CommonData = localStorage.getItem('common_data');
	const navigate = useNavigate();


	useEffect(() => {
		if (!userId) {
			navigate('/login');
		}
        if (!exp_id) {
			navigate('/step2');
		}
		if (CommonData) {
			if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
				navigate('/step14');
			}
		}
		import("./Css/Step8.scss");

		if (resume_id) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "finalize",
				data: { resume_id: resume_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;

				const contact = result.contact[0];
				if (contact.step == 100) {
					navigate('/step13');
					setStepno(contact.step);
				} else {
					setStepno(2);
				}


				const GPT_resp = JSON.parse(result.contact[0].Is_GPT_resp);
				setGptresp(GPT_resp);

			}).catch(err => {

			});
		}

		axios({
			method: "GET",
			url: commonConfig.REACT_APP_API_URL + "experience",
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "expid": exp_id },
		}).then(res => {

			var decodedData = res.data;
			const result = decodedData.data;
			setTabsData(result);
			let gpt_token = 1;
			result.forEach(item => {
				if (item.is_response == 0) {
					gpt_token = 0;
					return;
				}

			})

			result.forEach(item => {

				if (gpt_token == 0) {
					setGptloaded(1);
					const question = 'Hi! I need to create my CV or resume and need help in writing the CV. Can you please write up Atlease 5 bullet points which describe my job and highlight my good work with the company as Designation. To generate these bullet points please use the description: "' + item.description + '" List of Key words as chosen by candidate: "' + item.GPT_skills + '" The points should be written in a professional language and should be Applicant Tracking System friendly.Make sure that each bullet point is less than 20 words and maximum of 2 sentences. Ideally it should be 1 sentence. Return these statements as a JSON array inside bullet_points key.Do not return any non-json text or numbering and do not return less than 5 bullet points';

					chatGPT(question).then(responseData => {
						item.resp = responseData;
						console.log(responseData);
						setRespokData(true);
						save_data(item.resp, item.id);
					}).catch(error => {
						console.error('Error:', error);
					});
				} else {
					console.log("item", item);
					setRespokData(true);
					setGptloaded(2);
				}

			});
			// console.log("result", result);
		}).catch(err => {

		});

		setTimeout(() => {
			setPage(true);
		}, commonConfig.LOAD_TIME);
	}, []);



	const save_data = (item, id) => {
		const requestData = {
			resp: item,
			id: id,
			type: 'resp'
		};
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "skills/GPTstore",
			data: requestData,
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
		}).then(res => {

		}).catch(err => {
			// Handle error
		});
	}


	const chatGPT = async (question) => {
		return new Promise(async (resolve, reject) => {
			try {
				const apiUrl = commonConfig.GPT_API_URL;
				const apiKey = commonConfig.GPT_API_KEY;
				const requestBody = {
					"model": "gpt-3.5-turbo",
					"messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
					"temperature": 0.7
				};
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${apiKey}`
				};
				const { data } = await axios.post(apiUrl, requestBody, { headers });
				const result = data.choices[0].message.content;
				const json = JSON.parse(result);
				setRespData(json.bullet_points);
				resolve(json.bullet_points);
			} catch (error) {
				console.error('Error:', error);
				reject(error);
			}
		});
	};



	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setEditorContent(data);
		console.log('editorContent', editorContent)
	};
	const handleTabClick = (index, title) => {
		setActiveTab(index);
		setTextareaValue("");
		if (data[title]) {
			setTextareaValue(data[title]);
		}

		// console.log("selected",);
	};

	const handleTagClick = (title, tag) => {

		// setData((prevData) => {
		// 	const newData = {
		// 		...prevData,
		// 		[title]: editorContent,
		// 	};

		// 	console.log('New Data:', newData);

		// 	return newData;
		// });


		if (textareaValue.includes(tag)) {
			setTextareaValue(textareaValue.replace('<li>' + tag + '</li>', '').trim());
		} else {
			setTextareaValue(textareaValue + '<li>' + tag + '</li>');
		}
		setTimeout(() => {
			console.log("textareaValue", data);
		}, 500);

	};

	const getButtonClass = (tag) => {
		return textareaValue.includes(tag) ? 'tag1 active' : 'tag1';
	};

	const handleSubmit = (event) => {

		event.preventDefault();

		const newData = {
			[exp_id]: editorContent,
		};

		if (gptresp == 1) {
			is_edit = 1;
		}
		setPage(false);
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "experience/resp_update",
			data: newData,
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": '2.2', "isedit": is_edit },
		}).then(res => {
			var decodedData = res.data;
			var status = res.status;
			if (status) {
				navigate('/step2_1');
			}
		}).catch(err => {
			// Handle error
		});
	};

	const handleButtonClick = () => {
		setToggleClass(!toggleClass);
	};

	return (
		<div className="step8page">
			{respokData ? (
				<main id="app" role="main">
					<section className="page-wrapper">
						<div
							id="parent"
							className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
						>
							{page && <Nav value={stepno} />}
							<div className="inner1_step1">
								<h3 className="headtag">
									<span className="blue">Add Job Responsibilities.</span>
								</h3>
								<p
									style={{ marginBottom: "3%", fontWeight: "200" }}
									className="p_tag"
								>
									Choose from the Expert Suggessions, Edit or Write your own
								</p>
								<p
									style={{ marginBottom: "3%", fontWeight: "200" }}
									className="p_tag"
								>
									Select the suggestions in the sequence you want them to appear on your resume. Please edit in case any mistake is made or changes are required
								</p>
							</div>
							
							{/* System View */}
							<form action="" method="" className="system_view" >
								<div className="col-lg-6">
									{gptloaded == 2 && <section className="section_1">
										{JSON.parse(tabsData[activeTab]?.resp)?.map((keyword, index) => (
											<div onClick={() => handleTagClick(tabsData[activeTab].id, keyword)} className={getButtonClass(keyword)}>
												<img id={`image_${index}`} className="img_container1" src={link_button} alt="Image 1" />
												<div id={`container_${index}`} className="container_new">
													{keyword}
												</div>
											</div>
										))}
									</section>}
									{gptloaded == 1 && <section className="section_1">
										{tabsData[activeTab]?.resp?.map((keyword, index) => (
											<div onClick={() => handleTagClick(tabsData[activeTab].id, keyword)} className={getButtonClass(keyword)}>
												<img id={`image_${index}`} className="img_container1" src={link_button} alt="Image 1" />
												<div id={`container_${index}`} className="container_new">
													{keyword}
												</div>
											</div>
										))}
									</section>}
									<div style={{marginTop:'10px'}}>
										<div className="col-lg-6" style={{ clear: "both" }}>
											<Link onClick={() => navigate(-1)} className="btn-stepsback">
												Back
											</Link>
										</div>
										<div className="col-lg-6">
											<button type="button" onClick={handleSubmit} className="btn-mangaii">
												Next
											</button>
										</div>	
									</div>
											
								</div>
								<div className="col-lg-6 system_view">
									<div className="api_right">
										<div className="api_whitesheet">
											<div style={{ height: "50%" }}>
												<CKEditor
													editor={ClassicEditor}
													data={textareaValue}
													onChange={handleEditorChange}
												/>
											</div>
										</div>
									</div>
								</div>
								
							</form>
							{/* Mobile View       */}
							<form action="" method="" className="mobile_view">
								<div className="col-lg-6">
									<div style={{ height: "50%" }}>
										<CKEditor
											editor={ClassicEditor}
											data={textareaValue}
											onChange={handleEditorChange}
										/>
									</div>
								</div>
								<div className={toggleClass ? 'col-lg-6 resp_popup' : 'col-lg-6'}>
									{gptloaded == 2 && <section className={toggleClass ? 'section_1 h_auto' : 'section_1'}>
										<div className="p-10" style={{ height: '40px' }}>
											<div className="col-md-8">
												<div className="f-17 bold">Expert Suggestion</div>
											</div>
											<div className="col-md-4">
												{!toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleUp /></div>}
												{toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleDown /></div>}
											</div>
										</div>
										{JSON.parse(tabsData[activeTab]?.resp)?.map((keyword, index) => (
											<div onClick={() => handleTagClick(tabsData[activeTab].id, keyword)} className={getButtonClass(keyword)}>
												<img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
												<div id={`container_${index}`} className="container_new">
													{keyword}
												</div>
											</div>
										))}
									</section>}
									{gptloaded == 1 && <section className={toggleClass ? 'section_1 h_auto' : 'section_1'}>
										<div className="p-10" style={{ height: '40px' }}>
											<div className="col-md-8">
												<div className="f-17 bold">Expert Suggestion</div>
											</div>
											<div className="col-md-4">
												{!toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleUp /></div>}
												{toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleDown /></div>}
											</div>
										</div>
										{tabsData[activeTab]?.resp?.map((keyword, index) => (
											<div onClick={() => handleTagClick(tabsData[activeTab].id, keyword)} className={getButtonClass(keyword)}>
												<img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
												<div id={`container_${index}`} className="container_new">
													{keyword}
												</div>
											</div>
										))}
									</section>}

								</div>

								<div className="col-lg-6 half" style={{ clear: "both" }}>
									<Link onClick={() => navigate(-1)} className="btn-stepsback">
										Back
									</Link>
								</div>
								<div className="col-lg-6 half">
									<button type="button" onClick={handleSubmit} className="btn-mangaii">
										Next
									</button>
								</div>
							</form>
						</div>
					</section>
					<StepsFooter />
				</main>

			) : (
				<div>
					{/* <div className="system_view">
						<Loading content="Do Not Refresh the page as we are working on your Profile!" />
					</div>
					<div className="mobile_view">
						<Loading />
					</div> */}
          <LoadingGIF />
				</div>

			)}
		</div>
	);
}

export default Step8;