import React, { Component, useEffect, useState, useRef } from "react";
import google_button from "../img/google_button.png";
import axios from "axios";
import link_button from "../img/link_button.png";
import MainNav from "./MainNav";
import Footer from "./Footer";
import Loading from './Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlogImage from "./Assets/blog.png";
import Quotes from "./Assets/quotes.png";
import './Css/Blogs.scss';
import { useNavigate } from 'react-router-dom';
import { commonConfig } from '../config.js';
import('./Assets/CSS/bootstrap.min.css');


const Blogs = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    axios.get('https://gojobs.biz/ai_api/blogs.php')
      .then(response => {
        setBlogs(response.data.data);
      })
      .catch(error => {
        console.error("Error fetching blogs:", error);
      });

      setTimeout(() => {
        setPage(true);
      }, commonConfig.LOAD_TIME);  

  }, []);
  const View = (title, desc, image) => {
    localStorage.setItem('blog_title', title);
    localStorage.setItem('blog_desc', desc);
    localStorage.setItem('blog_image', image);
    navigate('/viewblog');
  };


  return (
    
    <div className="blogpage">
    {page ? (
      <div>
        <div>
          <MainNav value="3"/>
          <img src={BlogImage} alt="Blogs" style={{ marginTop: '72px',width: '100%' }} />
          <p className="p_tag center">Our commitment towards continuing Learning for All!</p>
        </div>

        <div className="searchbar">

        </div>

        <div className="grid-container" style={{marginTop:'2%'}}>
          {blogs.map((blog, index) => (
            <div className="box" style={{cursor:'pointer'}} onClick={() => View(blog.course_name, blog.course_desc, blog.image)} key={index}>
              <div>
                <div className="center"><img src={Quotes} /></div>
                <div>
                  <div style={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{
                      __html: blog.short_desc.slice(0, 100) + (blog.short_desc.length > 100 ? "..." : "")
                    }}
                  />
                </div>
              </div>

              <div className="center">
                <div className="image-wrapper">
                  <img src={blog.image} />
                  <div className="blue-overlay"></div>
                </div>
                <div className="blog_content1" style={{ textAlign: "center" }}>
                  {blog.course_name}
                </div>
              </div>
            </div>

          ))}
        </div>
        <Footer />
      </div>
      ) : (
        <Loading content="Please Wait!!!"/>
      )}
		</div>

  );
};

export default Blogs;

