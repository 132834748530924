import React, { Component, useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Robot from "./Assets/Robot_1.png";
import Ellipse from "./Assets/Ellipse.png";
import resume_1 from "./Assets/resume_1.png";
import resume_2 from "./Assets/resume_2.png";
import resume_3 from "./Assets/resume_3.png";
import section_1_1 from "./Assets/icons/section_1_1.png";
import section_1_2 from "./Assets/icons/section_1_2.png";
import section_1_3 from "./Assets/icons/section_1_3.png";
import section_1_4 from "./Assets/icons/section_1_4.png";
import Layer from "./Assets/Layer.png";
import section_2 from "./Assets/home/home_low_1.png";

import section_3 from "./Assets/home/home_low_2.png";
import section_4 from "./Assets/home/home_low_3.png";
import section_5 from "./Assets/home/home_low_4.png";
import section_6 from "./Assets/home/section_6.png";
import recordings from "./Assets/videos/recordings.mp4";
import line from "./Assets/home/line.png";
import MainNav from "./MainNav";
import Footer from "./Footer";
import "./Assets/CSS/aos.css";
import "./Assets/CSS/glightbox.min.css";
import "./Assets/CSS/swiper-bundle.min.css";
import "./Assets/CSS/style.css";
import LoadingGIF from './LoadingGIF';
import Loading from './Loading';
import count_bg from "./Assets/count_bg.png";
import axios from "axios";
import { commonConfig } from '../config.js';

function Home() {
  const [page, setPage] = useState(false);
  const [count, setCount] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    axios({
      method: "GET",
      url: commonConfig.REACT_APP_API_URL + "getCount",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      setCount(result);
    }).catch(err => {

    });

    import('./Css/Home.scss');
    setTimeout(() => {
      setPage(true);
    }, 1500);
  }, []);



  return (

    <div className="home homepage">
      {page ? (
        <div>
          <MainNav value="1" />
          <section className="banner" style={{ paddingTop: '10px' }}>


            <div className="col-lg-5 order-1 order-lg-2 hero-img bg_image">
              <div className="container_cl">
                <input type="radio" name="slider" id="item-1" defaultChecked />
                <input type="radio" name="slider" id="item-2" />
                <input type="radio" name="slider" id="item-3" />
                <div className="cards">
                  <label className="cardd" htmlFor="item-1" id="song-1">
                    <img src={resume_1} alt="song" />
                  </label>
                  <label className="cardd" htmlFor="item-2" id="song-2">
                    <img src={resume_2} alt="song" />
                  </label>
                  <label className="cardd" htmlFor="item-3" id="song-3">
                    <img src={resume_3} alt="song" />
                  </label>
                </div>
              </div>
            </div>
            <div className="col-lg-5 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" >
              <div className="banner_left">
                <h1 className="head1 blue">Create Your Professional</h1>
                <h1 className="head2 yellow">Resume Free</h1>
                <h3 className="headtag blue">For freshers and less than 2 year experience</h3>
                <h5 className="blue yellow">Download Format : PDF, Word(docx)</h5>
                <div className="row">
                  <div className="col-lg-4">
                    <button name="create" className="btn btn-blue"><Link to="/step14" className="link">Create Now </Link></button>
                  </div>
                  <div className="col-lg-4">

                  </div>
                  <div className="col-lg-4">

                  </div>
                </div>

              </div>

            </div>
            <div className="col-lg-2 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center" >

              <div className="col-lg-12 order-1 order-lg-2 hero-img bg_count_image" style={{ position: 'relative' }}>
                <div className="createdTxt"><div style={{ fontSize: '20px' }}>Resumes Created</div>
                  <h3 style={{ fontWeight: 'bold' }}>{count}</h3>
                </div>
              </div>
            </div>

            <iframe
              className="demoytVideo"
              style={{ marginTop: '2%', marginLeft: '3%' }}
              src="https://www.youtube.com/embed/NnJXw7ueNq8?rel=0&autoplay=0"
              title="Gojobs AI"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </section >

          <section className="section-2 section_sub spider" style={{ marginTop: '3%' }}>
            <div class="container lic_margins">
              <div class="dotted-rectangle">
                <div class="top-images" >
                  <img src={require('./Assets/cover/s_top_1.png')} alt="Image 1" />
                  <div className="tagline1">How it's Easy?</div>
                </div>
                <div class="bottom-images">
                  <div>
                    <img src={require('./Assets/cover/Create_icon.png')} alt="Image 1" />
                    <div className="tagline2">Select Your Resume Template</div>
                  </div>
                  <div>
                    <img src={require('./Assets/cover/Select_icon.png')} alt="Image 1" />
                    <div className="tagline2">Enter Your Details</div>
                  </div>
                  <div>
                    <img src={require('./Assets/cover/Select_icon.png')} alt="Image 1" />
                    <div className="tagline2">Fill Each section with our expert suggestion</div>
                  </div>
                  <div>
                    <img src={require('./Assets/cover/Download_icon.png')} alt="Image 1" />
                    <div className="tagline2">Download your Bag Offers</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-2 section_sub">
            <div className="we_best" style={{ position: 'relative' }}>
              <img src={require('./Assets/cover/s_top_1.png')} alt="Image 1" />
              <div className="tagline7">Why we are the Best?</div>
            </div>

            {/* <div className="col-lg2">
              <div className="center">
                <img className="sec1_image" src={section_1_1} />
              </div>
              <div className="center">
                <p className="blue sec1_f">Select your Resume Template</p>
              </div>
            </div>
            <div className="col-lg2">
              <div className="center">
                <img className="sec1_image" src={Layer} />
              </div>
              <div className="center">
                <p className="blue sec1_f">Upload Your Existing Resume Or Create New</p>
              </div>
            </div>

            <div className="col-lg2">
              <div className="center">
                <img className="sec1_image" src={section_1_2} />
              </div>
              <div className="center">
                <p className="blue sec1_f">Enter Your Details</p>
              </div>
            </div>
            <div className="col-lg2">
              <div className="center">
                <img className="sec1_image" src={section_1_3} />
              </div>
              <div className="center">
                <p className="blue sec1_f">Fill each section with AI based expert suggestions and still have control over what goes in the resume</p>
              </div>
            </div>
            <div className="col-lg2">
              <div className="center">
                <img className="sec1_image" src={section_1_4} />
              </div>
              <div className="center">
                <p className="blue sec1_f">Download your resume to bag Offers</p>
              </div>
            </div> */}
          </section>
          <section className="section-2 section_sub">
            {/* <h3 className="headtag"><span className="blue">Get the Best </span><span className="yellow">Always</span></h3> */}
            <div className="col-lg-6 center">
              <img className="sec2_image" src={section_2} />
            </div>
            <div className="col-lg-6 center">
              <div className="content_area">
                <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Right Templates</h4>
                <p className="p_tag">
                  Professional resume templates are a great way to get  started on the path to creating an effective and compelling resume.  With these templates, you can craft a unique document that  will help you stand out from the crowd and demonstrate  your qualifications for the job. Our clean, easy-to-use designs  make it simple for employers to quickly see what makes you
                </p>

              </div>
            </div>
          </section>
          <section className="section-2 section_sub">
            <div className="sec system_view">
              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Keyword Optimization</h4>
                  <p className="p_tag">
                    Resume keyword optimization is an important part of  creating a standout resume. It involves using relevant keywords  throughout your resume to show employers that you have the skills  and experience necessary for the job. Our Assurance your resume to  standout from the rest!
                  </p>
                </div>
              </div>
              <div className="col-lg-6 center">
                <img className="sec2_image" src={section_3} />
              </div>
            </div>
            <div className="sec mobile_view">

              <div className="col-lg-6 center">
                <img className="sec2_image" src={section_3} />
              </div>
              <div className="col-lg-6 center">

                <div className="content_area">
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Keyword Optimization</h4>
                  <p className="p_tag">
                    Resume keyword optimization is an important part of  creating a standout resume. It involves using relevant keywords  throughout your resume to show employers that you have the skills  and experience necessary for the job. Our Assurance your resume to  standout from the rest!
                  </p>
                </div>
              </div>

            </div>
          </section>

          <section className="section-2 section_sub" style={{ display: "none" }}>
            <div className="sec">
              <div className="col-lg-6 center">
                <img className="sec2_image" src={section_4} />
              </div>
              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Data Privacy</h4>
                  <p className="p_tag">
                    We are committed to protecting the privacy and confidentiality  of our clients. We understand that sharing your job search information  can be a sensitive process, so we take steps to ensure that any  personal or confidential information you provide will remain secure.  Our staff is trained on proper security protocols and  all data is encrypted for added protection.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section className="section-2 section_sub">
            <div className="sec">
              <div className="col-lg-6 center">
                <img className="sec2_image" src={section_4} />
              </div>
              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Easy to Create</h4>
                  <p className="p_tag">
                    Our goal is to make your experience as easy and  straightforward as possible. With our user-friendly  interface, you can quickly get up to speed and  start using the platform without a hassle.
                  </p>
                </div>
              </div>

            </div>
          </section>

          <section className="section-2 section_sub">
            <div className="sec system_view">

              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Committed for Quality</h4>
                  <p className="p_tag">
                    Our commitment to quality is second-to-none and  our team of experienced professionals is here to ensure that all  our customers have a great experience with us.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 center">
                <img className="sec2_image" src={section_5} />
              </div>
            </div>
            <div className="sec mobile_view">
              <div className="col-lg-6 center">
                <img className="sec2_image" src={section_5} />
              </div>
              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Committed for Quality</h4>
                  <p className="p_tag">
                    Our commitment to quality is second-to-none and  our team of experienced professionals is here to ensure that all  our customers have a great experience with us.
                  </p>
                </div>
              </div>

            </div>
          </section>

          {/* <div className="container">
            <div className="row">
              <section className="section_robot col-lg-12" >
                <div style={{ marginTop: "30px", marginBottom: "10px" }}>
                  <div className="head_last text-center blue">Why you must use AI for your Resume</div>
                </div>
                <div className="col-lg-6">
                  <img src={Robot} className="div_ellipse" alt="Robot" />
                </div>
                <div className="col-lg-6">
                  <div style={{ width: '10%', float: 'left' }}> <img className="div_ellipse1" style={{ height: '280px' }} src={Ellipse} alt="Robot" /></div>
                  <div style={{ width: '90%', float: 'left' }}>

                    <div>
                      <p className="elipse_text" style={{ color: 'white' }}>AI can generate a resume that is likely to be
                        compatible with Applicant Tracking Systems (ATS)</p><br />
                      <p className="elipse_text" style={{ color: 'white' }}>As recruiters are using AI for their recruitment process,
                        it would be wise to utilize AI for crafting your resume.</p><br />
                      <p className="elipse_text" style={{ color: 'white' }}>AI offers expert and pertinent content suggestions to
                        help overcome writer's block.</p><br /><br />
                      <p className="elipse_text" style={{ color: 'white' }}>Spelling and grammar errors are inevitable, but you
                        cannot go wrong with ai.</p>
                    </div>
                  </div>
                </div>

              </section>
            </div>
          </div> */}

          {/* <section style={{ marginTop: '3%' }} className="section_sub">
            <div className="col-lg-6">
              <h1 className="head_1 blue">Your dream job is just a <span className="yellow">Resume</span> Away!</h1>
            </div>
            <div className="col-lg-6 center">
              <button name="create" style={{ marginTop: '2%' }} className="btn btn-blue"><Link className="link" to="/step14">Click here to Get Started</Link></button>
            </div>
          </section> */}
          <Footer />
        </div >
      ) : (
        <Loading content="" />
      )
      }
    </div >

  );
}

export default Home;
