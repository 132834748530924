import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate, useParams } from "react-router-dom";
import Nav from "./Nav"; import { commonConfig } from "../config.js";
import axios from "axios";
import link_button from "./Assets/plus.png";
import link_button2 from "./Assets/minus.png";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import Formtemplate from "./Formtemplate";
import LoadingGIF from './LoadingGIF';

function Step4() {
  const { exp_id } = useParams();
  const [tabsData, setTabsData] = useState([]);
  const [respData, setRespData] = useState([]);
  const [exskills, setExskills] = useState([]);
  const [softskills, setSoftskills] = useState([]);
  const [respokData, setRespokData] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tagText, setTagText] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [gptloaded, setGptloaded] = useState(1);
  // let is_edit = 0;
  const [stepno, setStepno] = useState(2);
  const [gptresp, setGptresp] = useState(0);
  const [toggleClass, setToggleClass] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [blurredText, setBlurredText] = useState("");
  const [page, setPage] = useState(false);
  const resume_id = localStorage.getItem('resume_id');
  const userId = localStorage.getItem('userId');
  const CommonData = localStorage.getItem('common_data');
  const [is_edit, setIs_edit] = useState(0);
  const [showform, setShowform] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const [sformData, setSFormData] = useState({
    jobskills: [],
    softskills: []
  });


  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    if (screenWidth < 900) {
      setShowform(false);
    }
    // if (!exp_id) {
    // 	navigate('/step2');
    // }
    if (CommonData) {
      if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
        navigate('/step14');
      }
    }
    import("./Css/Step8.scss");

    if (resume_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;

        const contact = result.contact[0];
        let is_gpt_softskills = contact.Is_GPT_softskills;
        let GPT_softskills_array = contact.GPT_softskills_array;
        const exp = result.experience[0];
        const summery = JSON.parse(result.skills[0].summery);
        let skills_exist_data = result.skills[0].skills;
        let skills_array = result.skills[0].skills_array;
        if (contact.step == 100) {
          setStepno(contact.step);
        } else {
          setStepno(contact.step + 1);
        }

        if (is_gpt_softskills == 0) {
          setGptloaded(1);
          setRespokData(false);
          let question = 'Hi! I am working as ' + exp.title + ' in ' + exp.employer + '  with the experience of ' + exp.description + ' ';
          if (summery) {
            question += 'and have applied for ' + summery.title_1 + ' in ' + summery.company_1 + ' where the Job description is ' + summery.description_1 + '.';
          }
          question += 'Please provide a list of 20 most relevant and impressive Soft Skills in single words which are apt for the above-mentioned jobs. Return these statements as a JSON array inside bullet_points key.Do not return any non-json text or numbering';
          console.log('question',question);

          chatGPT(question).then(responseData => {
            // item.resp = responseData;
            setSoftskills(responseData);
            setRespokData(true);
            save_data(responseData, resume_id);
          }).catch(error => {
            console.error('Error:', error);
          });
        } else {
          setGptloaded(2);
          setRespokData(true);
          // let a = "<ul><li>test-1</li><li>test-2</li><li>test-3</li></ul><ul><li>stest-1</li><li>stest-2</li><li>stest-3</li></ul>";

          
            if(skills_exist_data){
              setTextareaValue(skills_exist_data);
            }
            
            setTimeout(() => {
              setSoftskills(JSON.parse(GPT_softskills_array));
              if(skills_array){
                setSFormData(JSON.parse(skills_array));
              }
            }, 500);
          
          
        }


        // const GPT_resp = JSON.parse(result.contact[0].Is_GPT_resp);
        // setGptresp(GPT_resp);

      }).catch(err => {

      });
    }

    axios({
      method: "GET",
      url: commonConfig.REACT_APP_API_URL + "experience",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
    }).then(res => {

      var decodedData = res.data;
      const result = decodedData.data;
      setTabsData(result);
      let gpt_token = 1;
      result.forEach(item => {
        setExskills(JSON.parse(item.res));

      })
    }).catch(err => {

    });

    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);



  const save_data = (item, id) => {
    const requestData = {
      softskills: item,
      id: id,
      type: 'softskills'
    };
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "skills/GPTstore",
      data: requestData,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
    }).then(res => {

    }).catch(err => {
      // Handle error
    });
  }


  const chatGPT = async (question) => {
    return new Promise(async (resolve, reject) => {
      try {
        const apiUrl = commonConfig.GPT_API_URL;
        const apiKey = commonConfig.GPT_API_KEY;
        const requestBody = {
          "model": "gpt-3.5-turbo",
          "messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
          "temperature": 0.7
        };
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        };
        const { data } = await axios.post(apiUrl, requestBody, { headers });
        const result = data.choices[0].message.content;
        const json = JSON.parse(result);
        setRespData(json.bullet_points);
        resolve(json.bullet_points);
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };



  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
    // console.log('editorContent', editorContent)
  };
  const handleTabClick = (index, title) => {
    setActiveTab(index);
    setTextareaValue("");
    if (data[title]) {
      setTextareaValue(data[title]);
    }

    // console.log("selected",);
  };

  const handleTagClick = (title, tag, type) => {

    setData((prevData) => {
      const newData = {
        ...prevData,
        [title]: editorContent,
      };
      return newData;
    });

    const updatedFormData = { ...sformData };
    
    if(type == 'job'){
      const js = updatedFormData.jobskills;
      console.log("js",updatedFormData);
      const idIndex = js.indexOf(tag);
      if (idIndex !== -1) {
        js.splice(idIndex, 1);
      } else {
        js.push(tag);
      }
    }else{
      const js1 = updatedFormData.softskills;
      const idIndex1 = js1.indexOf(tag);
      if (idIndex1 !== -1) {
        js1.splice(idIndex1, 1);
      } else {
        js1.push(tag);
      }
    }

    
    setSFormData(updatedFormData);

    let content = '<ul>';
    content += (updatedFormData?.jobskills || []).map((item, index) => (
      `<li key=${index}>${item}</li>`
    )).join('');
    content+='</ul><br/>';
    content+= '<ul>';
    content += (updatedFormData?.softskills || []).map((item, index) => (
      `<li key=${index}>${item}</li>`
    )).join('');
    content+='</ul>';
    console.log("updatedFormData",updatedFormData);
    console.log("updatedFormData",content);
    setTextareaValue(content);  

    // if (textareaValue.includes(tag)) {
    //   setTextareaValue(textareaValue.replace('<li>' + tag + '</li>', '').trim());
    // } else {
    //   setTextareaValue(textareaValue + '<li>' + tag + '</li>');
    // }
    

  };


  const getButtonClass = (tag) => {
    return textareaValue.includes(tag) ? 'tag1 active' : 'tag1';
  };

  const handleSubmit = (event) => {

    event.preventDefault();
    setPage(false);
    // if (skills.length > 0) {
    // 	is_edit = 1;
    // }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "skills/store",
      data: { skills: textareaValue,sformData:sformData },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": 4, "isedit": is_edit },
    }).then(res => {
      var decodedData = res.data;
      var status = res.status;
      if (status) {
        navigate('/step5');
      }
    }).catch(err => {
      // Handle error
    });
  };

  const handleButtonClick = () => {
    setToggleClass(!toggleClass);
  };

  const Showform = () => {
    setShowform(true);
  };

  return (
    <div className="step8page">
      {respokData ? (
        <main id="app" role="main">
          <section className="page-wrapper">
            <div
              id="parent"
              className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
            >
              {page && <Nav value={stepno} />}


              {/* System View */}
              <form action="" method="" className="system_view" >
                <div className="col-lg-6">
                  <div className="inner1_step1">
                    <h3 className="headtag">
                      <span className="blue">Select Your Skills</span>
                    </h3>
                    <p
                      style={{ marginBottom: "3%", fontWeight: "200" }}
                      className="p_tag"
                    >
                      Choose, Edit or add your own Skills
                    </p>
                  </div>
                  
                  <section className="section_1s col-lg-6">
                    <button className="button_tag bg_blue">Job Skills</button>
                    {exskills?.map((keyword, index) => (
                      <div onClick={() => handleTagClick(1, keyword,'job')} className={getButtonClass(keyword)}>
                        <img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
                        <div id={`container_${index}`} className="container_new">
                          {keyword}
                        </div>
                      </div>
                    ))}
                  </section>
                  <section className="section_1s col-lg-6">
                    <button className="button_tag bg_blue">Soft Skills</button>
                    {softskills?.map((keyword, index) => (
                      <div onClick={() => handleTagClick(1, keyword,'soft')} className={getButtonClass(keyword)}>
                        <img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
                        <div id={`container_${index}`} className="container_new">
                          {keyword}
                        </div>
                      </div>
                    ))}
                  </section>
                  <div style={{ marginTop: '10px' }}>
                    <div className="col-lg-6 mt-10" style={{ clear: "both" }}>
                      <Link onClick={() => navigate(-1)} className="btn-stepsback">
                        Back
                      </Link>
                    </div>
                    <div className="col-lg-6 mt-10">
                      <button type="button" onClick={handleSubmit} className="btn-mangaii">
                        Next
                      </button>
                    </div>
                  </div>

                </div>
                <div className="col-lg-6 system_view">
                  <div className="api_rights">
                    <div className="api_whitesheet">
                      <div style={{ height: "50%" }}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={textareaValue}
                          onChange={handleEditorChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

              </form>
              {/* Mobile View       */}
              <form action="" method="" className="mobile_view">
                {!showform && <div className="col-lg-12 mobile_view">
                  <h3 className="headtag" onClick={Showform} style={{ textAlign: 'center' }}><span className="blue">Lets move on to Skills</span></h3>
                  <div className="api_right">
                    <Formtemplate />
                    <div className="col-lg-12 center">
                      <Link to="/template/step2" className="clickable p-10">Change Template</Link>
                    </div>
                    <div className="col-lg-6 half" >
                      <Link to="/step1" className="btn-stepsback">Back</Link>
                    </div>
                    <div className="col-lg-6 half" >
                      <button id="btnBack" onClick={Showform} type="submit" className="btn-mangaii">Next</button>
                    </div>
                  </div>
                </div>}
                
                {showform &&<div>
                  <div className="col-lg-6">
                    <div style={{ height: "50%" }}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={textareaValue}
                        onChange={handleEditorChange}
                      />
                    </div>
                  </div>
                  <div className={toggleClass ? 'col-lg-6 resp_popup' : 'col-lg-6'}>
                    <section className={toggleClass ? 'section_1s h_auto' : 'section_1s'}>
                      <div className="p-10" style={{ height: '40px' }}>
                        <div className="col-md-8">
                          <button className="button_tag bg_blue">Job Skills</button>
                        </div>
                        <div className="col-md-4">
                          {!toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleUp /></div>}
                          {toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleDown /></div>}
                        </div>
                      </div>
                      {exskills?.map((keyword, index) => (
                        <div onClick={() => handleTagClick(1, keyword,'job')} className={getButtonClass(keyword)}>
                          <img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
                          <div id={`container_${index}`} className="container_new">
                            {keyword}
                          </div>
                        </div>
                      ))}
                      <div className="p-10" style={{ height: '40px' }}>
                        <div className="col-md-8">
                          <button className="button_tag bg_blue">Soft Skills</button>
                        </div>
                        {/* <div className="col-md-4">
                          {!toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleUp /></div>}
                          {toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleDown /></div>}
                        </div> */}
                      </div>
                      {softskills?.map((keyword, index) => (
                        <div onClick={() => handleTagClick(1, keyword,'soft')} className={getButtonClass(keyword)}>
                          <img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
                          <div id={`container_${index}`} className="container_new">
                            {keyword}
                          </div>
                        </div>
                      ))}
                    </section>
                    {/* {gptloaded == 1 && <section className={toggleClass ? 'section_1s h_auto' : 'section_1s'}>
                      <div className="p-10" style={{ height: '40px' }}>
                        <div className="col-md-8">
                          <div className="f-17 bold">Expert Suggestion</div>
                        </div>
                        <div className="col-md-4">
                          {!toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleUp /></div>}
                          {toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleDown /></div>}
                        </div>
                      </div>
                      {softskills?.map((keyword, index) => (
                        <div onClick={() => handleTagClick(tabsData[activeTab].id, keyword)} className={getButtonClass(keyword)}>
                          <img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
                          <div id={`container_${index}`} className="container_new">
                            {keyword}
                          </div>
                        </div>
                      ))}
                    </section>} */}
                  </div>
                  <div className="col-lg-6 half" style={{ clear: "both" }}>
                    <Link onClick={() => navigate(-1)} className="btn-stepsback">
                      Back
                    </Link>
                  </div>
                  <div className="col-lg-6 half">
                    <button type="button" onClick={handleSubmit} className="btn-mangaii">
                      Next
                    </button>
                  </div>
                </div>}
              </form>
            </div>
          </section>
          <StepsFooter />
        </main>

      ) : (
        <div>
          {/* <div className="system_view">
            <Loading content="Do Not Refresh the page as we are working on your Profile!" />
          </div>
          <div className="mobile_view">
            <Loading />
          </div> */}
          <LoadingGIF />
        </div>

      )}
    </div>
  );
}

export default Step4;