import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./Dytemp1.scss";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Dytemp1 = forwardRef((props, ref) => {
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);

  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  let resume_id = localStorage.getItem('resume_id');

  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }




    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);


    }).catch(err => {

    });
  }, []);



  const printDocument = (title) => {
    const element = document.getElementById('pdfContainer');
    console.log(htmlContent);
    const data = {
      htmlContent: htmlContent,
      resume_id: resume_id,
      template: 21
    };
    console.log('htmlContent', htmlContent);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "generate-pdf",
      data: data,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      if (title == "save") {
        toast.success("Saved Successfully!!!");
      }

    }).catch(err => {

    });
  };

  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print
  }));
  return (
    <div>
      {/* <button onClick={printDocument}>Print</button> */}
      <ToastContainer position="top-center" theme="dark" />
      <div contentEditable id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '10px' }}>
        {respokData ? (
          <li
            className="template-preview"
            data-skinid="MNG3"
            data-skincd="MNG3"
            title=""
          >
            <div className="render-preview">
              <div className="" tabIndex="">
                <div></div>
                <div
                  className="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mng3 MNG3  RNA  "
                  docskinwidth={542}
                >
                  <div className="name-contact2 ">
                    <div className="section name-sec SECTION_NAME">
                      <div className=" doc-item">
                        <div className="">
                          <div className="">
                            <div className="paragraph PARAGRAPH_NAME firstparagraph">
                              <div>
                                <div
                                  className="monogram dynamicIcon"
                                  style={{ display: "none" }}
                                >
                                  <div />
                                </div>
                                <div className="nameLogo">

                                </div>
                                <div className="name">
                                  <span>{contact.f_name}</span>&nbsp;
                                  <span>{contact.l_name}</span>
                                </div>
                                <div className="profTitle" style={{ display: "none" }}>
                                  <span> </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section SECTION_CNTC">
                      <div className=" doc-item">
                        <div className="">
                          <div className="">
                            <div className="paragraph PARAGRAPH_CNTC firstparagraph">
                              <div className="clearfix doc-item">
                                <div className="address">
                                  <span>{contact.email}</span>
                                  <span>
                                    <span className="sprtr"> | </span>
                                    <span className="sprtr"> |</span>
                                  </span>
                                  <span>{contact.mobile}</span>
                                  <span>
                                    <span className="sprtr"> | </span>
                                  </span>
                                  <span className="spaced" style={{ display: "none" }} />
                                  <span className="spaced" style={{ display: "none" }}>
                                    ,{" "}
                                  </span>
                                  <span className="spaced" />
                                  <span style={{ display: "none" }} />
                                  <span>{contact.address_1} {contact.address_2}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="parent-wrapper">
                    <div
                      className="section SECTION_SUMM has-title"
                      data-section-cd="SUMM"
                    >
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle">
                            Summary
                            <span
                              title=" Summary "
                              className="rename-section text-rename"
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="">
                            <div className="paragraph PARAGRAPH_SUMM firstparagraph">
                              <div className="clearfix doc-item">
                                <div className="singlecolumn">
                                  <p>
                                    {contact.summery}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section SECTION_EXPR multi-para has-title" >
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle">
                            Experience
                            <span
                              title=" Experience "
                              className="rename-section text-rename"
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="sortableInner">
                            {experience?.map((item, index) => (
                              <div>
                                <div className="paragraph parlrColmn PARAGRAPH_EXPR firstparagraph">
                                  <div className="clearfix doc-item">
                                    <span className="dates_wrapper">
                                      <span className="paddedline">
                                        <span className="companyname">{item.employer}, </span>
                                        <span className="joblocation jobcity">{item.city}, </span>
                                        <span className="joblocation jobstate">{item.country}</span>
                                      </span>
                                      <span className="paddedline">
                                        <span className="jobtitle">
                                          {item.title}, &nbsp;
                                        </span>
                                        <span className="jobdates">{item.start_date}</span> -&nbsp;
                                        <span className="jobdates">{item.end_date == null ? 'Present' : item.end_date}</span>
                                      </span>
                                    </span>
                                    <div className="singlecolumn">
                                      <span className="jobline">
                                        <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                {item.achievements != '[]' && (
                                  <div className="singlecolumn maincolumn">
                                    <div className="sectiontitle">Achievements
                                      <span
                                        title=" Education and Training "
                                        className="rename-section text-rename"
                                      />
                                    </div>
                                    <ul>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="section SECTION_HILT has-title"
                      data-section-cd="HILT"
                    >
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle">
                            Skills
                            <span
                              title=" Skills "
                              className="rename-section text-rename"
                            />
                          </div>
                        </div>
                        <div className="">
                          <div className="">
                            <div className="paragraph PARAGRAPH_HILT firstparagraph">
                              <div className="clearfix doc-item">
                                <div className="singlecolumn maincolumn">
                                  <ul>
                                    {JSON.parse(skills[0].skills)?.map((item, index) => (
                                      item && <li className="width-50" key={index}>{item}</li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                 
                    <div className="section SECTION_EDUC multi-para has-title">
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle">
                            Education and Training
                            <span
                              title=" Education and Training "
                              className="rename-section text-rename"
                            />
                          </div>
                        </div>
                        {education?.map((item, index) => (
                          <div>
                            <div className="">
                              <div className="">
                                <div className="paragraph PARAGRAPH_EDUC firstparagraph">
                                  <div className="clearfix doc-item">
                                    <div className="singlecolumn">
                                      <span className="paddedline">
                                        <span className="companyname">
                                          {item.university}, &nbsp;
                                        </span>
                                        <span className="degree">
                                          {item.education} - &nbsp;
                                        </span>
                                        <span className="degree">
                                          {item.course}, &nbsp;
                                        </span>
                                        <span className="jobdates">{item.year_of_completion}</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div><br />

                            {item.achievements != '[]' && (
                              <div className="singlecolumn maincolumn">
                                <div className="sectiontitle">Achievements
                                  <span
                                    title=" Education and Training "
                                    className="rename-section text-rename"
                                  />
                                </div>
                                <ul>
                                  {JSON.parse(item.achievements)?.map((item1, index) => (
                                    item1 && <li key={index}>{item1}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            <br />
                          </div>
                        ))}
                      </div>
                    </div>

                    {JSON.parse(more).language &&
                      <div className="section lang-sec infobarsec SECTION_LNGG has-title">
                        <div className=" doc-item">
                          <div className="heading">
                            <div className="sectiontitle">
                              Languages
                              <span
                                title=" Languages "
                                className="rename-section text-rename"
                              />
                            </div>
                          </div>
                          {JSON.parse(more)?.language?.map((item, index) => (
                            <div className="">
                              <div className="sortableInner">
                                <div className="paragraph PARAGRAPH_LNGG para_odd">
                                  <div className="clearfix doc-item">
                                    <div className="singlecolumn infobarpara">
                                      <div className="field">
                                        <span className="txt-bold">{item.language}</span>
                                        <span className="colon txt-bold">:</span>
                                      </div>
                                      <div className="rating-bar">
                                        <div
                                          className="inner-rating"
                                          type="width"
                                          style={{ width: `${item.sliderValue}%` }}
                                        />
                                      </div>
                                      <div className="field field-ratt">
                                        <span>Proficient</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    }

                    {JSON.parse(more).website.work && <div className="singlecolumn maincolumn">
                      
                      <div className="sectiontitle">
                        Website & Profiles:
                        <span
                          title=" Languages "
                          className="rename-section text-rename"
                        />
                      </div>
                      <span className="paddedline" id="FIELD_SKC1">
                        <ul>
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.work && <li key={item.work}>{item.work}</li>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.portfolio && <li key={item.portfolio}>{item.portfolio}</li>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.profile && <li key={item.profile}>{item.profile}</li>
                          ))}
                        </ul>
                      </span>
                    </div>}

                    {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                      {JSON.parse(more)?.custom?.map((item, index) => (
                        <div>
                          
                          <div className="sectiontitle">
                            {item.custom_name}
                            <span
                              title=" Languages "
                              className="rename-section text-rename"
                            />
                          </div>
                          <span className="paddedline" id="FIELD_SKC1">
                            <ul>
                              {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                            </ul>
                          </span>
                        </div>
                      ))}
                    </div>}

                  </div>
                </div>
                <div />
              </div>
            </div>
          </li>

        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
});
export default Dytemp1;