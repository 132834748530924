import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./Dytemp3.scss";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Dytemp3 = forwardRef((props, ref) => {
	const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
	const [contact, setContact] = useState({});
	const [more, setMore] = useState({});
	const [education, setEducation] = useState({});
	const [experience, setExperience] = useState({});
	const [skills, setSkills] = useState({});
	const [skills1, setSkills1] = useState('');
	const [image, setImage] = useState('');
	const [respokData, setRespokData] = useState(false);

	const [htmlContent, setHtmlContent] = useState('');
	const divRef = useRef(null);

	let resume_id = localStorage.getItem('resume_id');

	useEffect(() => {
		if (!resume_id) {
			resume_id = 2;
		} 
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "finalize",
			data: { resume_id: resume_id },
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
		}).then(res => {
			var decodedData = res.data;
			const result = decodedData.data;
			// const result = JSON.parse(results);
			setContact(result.contact[0]);
			setMore(result.contact[0].more);
			setEducation(result.education);
			setExperience(result.experience);
			setSkills(result.skills);
			setImage(result.image);
			setRespokData(true);

			setTimeout(() => {
				if (divRef.current) {
					const inputEvent = new InputEvent('input', {
						bubbles: true,
						cancelable: true,
					});
					divRef.current.dispatchEvent(inputEvent);
				}
			}, 500);


		}).catch(err => {

		});
	}, []);



	const printDocument = (title) => {
		const element = document.getElementById('pdfContainer');
		console.log(htmlContent);
		const data = {
			htmlContent: htmlContent,
			resume_id: resume_id,
			template: 21
		};
		console.log('htmlContent', htmlContent);
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "generate-pdf",
			data: data,
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
		}).then(res => {
			if (title == "save") {
				toast.success("Saved Successfully!!!");
			}

		}).catch(err => {

		});
	};

	const print = () => {
		const printableContent = document.getElementById("pdfContainer");
		const printWindow = window.open("", "", "height=1000,width=1000");
		printWindow.document.write(printableContent.innerHTML);
		printWindow.print();
	}

	useImperativeHandle(ref, () => ({
		printDocument: printDocument,
		print: print
	}));

	return (
		<div>
			{/* <button onClick={printDocument}>Print</button> */}
			<ToastContainer position="top-center" theme="dark" />
			<div contentEditable id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '10px' }}>
				{respokData ? (<li className="template-preview" data-skinid="LCB6" data-skincd="LCB6" title="">
					<div className="selected-badge">
						<div className="fal fa-check icon-badge"></div>
					</div><button type="button" className="btn btn-link preview-btn" aria-label="LCB6 skin preview "></button>
					<div className="render-preview">
						<div className="" tabindex="">
							<div>

							</div>
							<div className="document doc-root fontsize fontface vmargins hmargins pagesize skn-lcb6 LCB6 RNA "
								docskinwidth="538">
								<div className="name-contact ">
									<div className="section name-sec SECTION_NAME     "
										data-section-cd="NAME">
										<div className=" doc-item">
											<div className="">
												<div className="">
													<div className="paragraph PARAGRAPH_NAME firstparagraph  ">
														<div>
															<div className="name">
																<span>{contact.f_name}</span>
																<span>{contact.l_name}</span>
															</div>
															<div className="resumeTitle" style={{ display: 'none' }}>
																<span> </span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="section SECTION_CNTC     "
										data-section-cd="CNTC">
										<div className=" doc-item">
											<div className="">
												<div className="">
													<div className="paragraph PARAGRAPH_CNTC firstparagraph  ">
														<div className="clearfix doc-item">
															<div className="address">
																<span>{contact.email}</span><span> | </span>
																<span>{contact.mobile}</span>
																<br />
																<span>{contact.address_1} {contact.address_2}</span>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="parent-wrapper">
									<div className="section SECTION_SUMM has-title  "
										data-section-cd="SUMM">
										<div className=" doc-item">
											<div className="heading">
												<div className="sectiontitle">Summary<span className="rename-section text-rename"></span></div>
											</div>
											<div className="">
												<div className="">
													<div className="paragraph PARAGRAPH_SUMM firstparagraph  ">
														<div className="clearfix doc-item">
															<div className="singlecolumn">
																<p>
																	{contact.summery}
																</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="section SECTION_HILT has-title  "
										data-section-cd="HILT">
										<div className=" doc-item">
											<div className="heading">
												<div className="sectiontitle">Skills<span className="rename-section text-rename"></span></div>
											</div>
											<div className="">
												<div className="">
													<div className="paragraph PARAGRAPH_HILT firstparagraph  ">
														<div className="clearfix doc-item">
															<div className="singlecolumn maincolumn">
																<table className="twocol">
																	<tbody>
																		<tr>
																			<td className="twocol_1">
																				<ul>
																					{JSON.parse(skills[0].skills)?.map((item, index) => (
																						item && <li className="width-50" key={index}>{item}</li>
																					))}
																				</ul>
																			</td>
																		</tr>
																	</tbody>
																</table>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="section SECTION_EXPR multi-para has-title  " data-section-cd="EXPR">
										<div className=" doc-item">
											<div className="heading">
												<div className="sectiontitle">Experience<span className="rename-section text-rename"></span></div>
											</div>
											{experience?.map((item, index) => (
												<div className="">
													<div className="sortableInner">
														<div className="paragraph PARAGRAPH_EXPR firstparagraph  ">
															<div className="clearfix doc-item">
																<div className="singlecolumn">
																	<span className="paddedline txt-bold">
																		<span className="jobtitle">{item.title}</span>
																	</span>
																	<span className="paddedline txtGray">
																		<span className="companyname">{item.employer}</span>
																		<span className="joblocation jobcity">{item.city}</span>
																		<span className="joblocation jobstate">{item.country}</span>
																		<span className="jobdates">{item.start_date}</span>
																		<span className="jobdates">-{item.end_date == null ? 'Present' : item.end_date}</span>
																	</span>
																	<span className="jobline">
																		<ul>
																			<div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
																		</ul>
																	</span>
																	{item.achievements != '[]' && (
																		<ul>
																			{JSON.parse(item.achievements)?.map((item1, index) => (
																				item1 && <li key={index}>{item1}</li>
																			))}
																		</ul>
																	)}
																</div>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
									<div className="section SECTION_EDUC multi-para has-title  " data-section-cd="EDUC">
										<div className=" doc-item">
											<div className="heading">
												<div className="sectiontitle">Education<span className="rename-section text-rename"></span></div>
											</div>
											{education?.map((item, index) => (
												<div className="">
													<div className="">
														<div className="paragraph PARAGRAPH_EDUC firstparagraph  ">
															
														<span className="paddedline">
																		<span className="degree txt-bold">{item.course}</span>
																	</span>
																	
																	<span className="paddedline txtGray">
																		<span className="companyname companyname_educ">{item.university}</span>
																		<span className="joblocation jobstate">{item.year_of_completion}</span>
																	</span>
															<div className="clearfix doc-item">
															<div className="singlecolumn">{item.achievements != '[]' && (
														
															<ul>
                                  {JSON.parse(item.achievements)?.map((item1, index) => (
                                    item1 && <li key={index}>{item1}</li>
                                  ))}
                                </ul>
															
															)}
																</div>
																
															
															</div>
														
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
									<div className="section lang-sec infobarsec SECTION_LNGG has-title  " data-section-cd="LNGG">
									{JSON.parse(more).language &&
									<div className=" doc-item">
											<div className="heading">
												<div className="sectiontitle">Languages<span className="rename-section text-rename"></span></div>
											</div>
											{JSON.parse(more)?.language?.map((item, index) => (
                            <div className="">
												<div className="sortableInner">
													<div className="paragraph PARAGRAPH_LNGG firstparagraph  nativeLangPara">
														<div className="clearfix doc-item">
															<div className="singlecolumn infobarpara">
																<div className="field">
																	<span className="txt-bold">Hindi</span><span className="colon">:</span>
																</div>
																<div className="field field-ratt">
																	<span>Native speaker</span>
																</div>
															</div>
														</div>
													</div>
													<div className="paragraph PARAGRAPH_LNGG  para_odd">
														<div className="clearfix doc-item">
															<div className="singlecolumn infobarpara">
																<div className="field">
																	<span className="txt-bold">{item.language}</span><span className="colon">:</span>
																
																</div>
																<div className="rating-bar">
																	<div className="inner-rating" type="width" style={{ width: `${item.sliderValue}%` }}></div>
																</div>
																<div className="field field-ratt">
																	<span>Proficient</span>
																</div>
															</div>
														</div>
													</div>
												
												</div>
											</div>
												))}
										</div>
											}
											  {JSON.parse(more).website.work && <div className="singlecolumn maincolumn">
                      
                      <div className="heading">
                        Website & Profiles:
                        <span
                          title=" Languages "
                          className="rename-section text-rename"
                        />
                      </div>
                      <span className="txt-bold" id="FIELD_SKC1">
                        <ul>
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.work && <li key={item.work}>{item.work}</li>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.portfolio && <li key={item.portfolio}>{item.portfolio}</li>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.profile && <li key={item.profile}>{item.profile}</li>
                          ))}
                        </ul>
                      </span>
                    </div>}

                    {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                      {JSON.parse(more)?.custom?.map((item, index) => (
                        <div>
                          
                          <div className="sectiontitle">
                            {item.custom_name}
                            <span
                              title=" Languages "
                              className="rename-section text-rename"
                            />
                          </div>
                          <span className="paddedline" id="FIELD_SKC1">
                            <ul>
                              {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                            </ul>
                          </span>
                        </div>
                      ))}
                    </div>}
									
									</div>
									
							
								
									</div>
											
							</div>
									

						</div>
					</div>
					<div className="template-overlay"><button id="previewTemplate" type="button" className="btn btn-tertiary"
						data-index="26">Preview Template</button> <button id="btnSelectTemplate" type="button"
							className="btn btn-primary  ">Select Template</button></div>
				</li>

				) : (
					<Loading />
				)}
			</div>
		</div>

	);
});
export default Dytemp3;
