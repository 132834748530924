import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate } from "react-router-dom";
import Nav from "./Nav"; import { commonConfig } from "../config.js";
import axios from "axios";
import link_button from "./Assets/plus.png";
import link_button2 from "./Assets/minus.png";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import Formtemplate from "./Formtemplate";

function Step3_1() {
	// const [tabsData, setTabsData] = useState([]);
	const [eduData, setEduData] = useState([]);
	const [respokData, setRespokData] = useState(true);
	const [page, setPage] = useState(false);
	const [stepno, setStepno] = useState(3);
	const resume_id = localStorage.getItem('resume_id');
	const userId = localStorage.getItem('userId');
	const CommonData = localStorage.getItem('common_data');
	const navigate = useNavigate();


	useEffect(() => {
		if (!userId) {
			navigate('/login');
		}
		if (CommonData) {
			if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
				navigate('/step14');
			}
		}
    Getedulist();
		import("./Css/Step8.scss");
		// setTimeout(() => {
		// 	setPage(true);
		// }, commonConfig.LOAD_TIME);
	}, []);

  const Getedulist = () =>{
    if (resume_id) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "finalize",
				data: { resume_id: resume_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
				setEduData(result.education);
				// console.log(result.experience);
        setPage(true);
				const contact = result.contact[0];
				if (contact.step === 100) {
          setStepno(contact.step);
        }else if(contact.step === 3) {
          setStepno(contact.step);
        }else{
          setStepno(contact.step + 1);
        }


			}).catch(err => {

			});
		}
  }


	const EditEdu = (edu_id) => {
		navigate('/step3/'+edu_id);
  };

	const handleDelete = (edu_id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Education?');
    if (isConfirmed) {
      axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "delete_edu",
				data: { edu_id: edu_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				Getedulist();
			}).catch(err => {

			});
    }
  };



	return (
		<div className="step8page">
			{page ? (
				<main id="app" role="main">
					<section className="page-wrapper">
						<div
							id="parent"
							className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
						>
							{page && <Nav value={stepno} />}
							{/* <div className="inner1_step1">
								<h3 className="headtag">
									<span className="blue">Education Summary</span>
								</h3>
							</div> */}
							<div className="col-lg-12">
								<div className="col-lg-7">
									<div className="exp_section">
										<h3 className="headtag">
											<span className="blue">Education Summary</span>
										</h3>
										{eduData?.map((item, index) => (<div className="exp_list">
											<div className="exp_title center-start-end bold">
												<div>{item.course} {item.specialization}</div>
												<div>
													<span className="mr-60 clickable" onClick={(event) => EditEdu(item.id)} ><FontAwesomeIcon icon={faEdit} /></span>
													<span className="clickable"><FontAwesomeIcon  onClick={(event) => handleDelete(item.id)} icon={faTrashAlt} /></span>
												</div>
											</div>
											<div className="exp_loc">
												<div>{item.university}</div>
											</div>
											<div className="exp_loc">
												<div>
													<span id="FIELD_JSTD" format="%m/%Y">{item.year_of_start}</span><span dependency="JSTD+EDDT"> - </span>
													<span id="FIELD_EDDT" format="%m/%Y">{item.year_of_completion}</span>
												</div>
											</div>
											
										</div>))}


										

                    <div className="system_view">
                      <div className="col-lg-4 half" style={{ marginTop: '7%' }} >
                        <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                      </div>
                      <div className="col-lg-8 half" style={{ marginTop: '7%' }} >
                        <Link to="/step4" className="btn-mangaii">Continue to Skills</Link>
                        <Link to="/step3" className="btn-mangaii-dark">+ Add More Education</Link>
                      </div>
                    </div>
                    <div className="mobile_view">
                      <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                        <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                      </div>
                      <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                        <Link to="/step3" className="btn-mangaii-dark">+ Add More Education</Link>
                      </div>
                      <div className="col-lg-12" style={{ marginTop: '7%' }} >
                        <Link to="/step4"  className="btn-mangaii" style={{width:'100%',marginTop: '7%'}}>Continue to Skills</Link>
                      </div>
                    </div>
									</div>
									
								</div>
								<div className="col-lg-5 system_view">
                  
									<div className="api_right">
                    <Link to="/template/step3_1" className="clickable p-10">Change Template</Link>
										<Formtemplate />
									</div>
								</div>
							</div>
						</div>
					</section>
					<StepsFooter />
				</main>

			) : (
				<div>
					<div className="system_view">
						<Loading />
					</div>
					<div className="mobile_view">
						<Loading />
					</div>
				</div>

			)}
		</div>
	);
}

export default Step3_1;