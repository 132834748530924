import React, { Component } from "react";
import "./Temp4.css";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
function Temp4() {
    return (
        <div>
            <li className="template-preview selected" data-skinid="MLB2" data-skincd="MLB2" title="">
                <div className="selected-badge"><div className="fal fa-check icon-badge">
                </div>
                </div>
                <button type="button" className="btn btn-link preview-btn" aria-label="MLB2 skin preview  selected"> 
                </button>
                <div className="render-preview">
                    <div className="" tabindex="">
                    <div></div>
                    <div className="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlb4 MLB2  RNA  " docskinwidth="615"><div id="CONTAINER_PARENT_0" className="topsection"><div id="CONTAINER_0" className="left-box"><div data-react-beautiful-dnd-draggable="181" className="sortable-item section-container SortableItem-sibling  data-NAME"><div className="document-tool sec-tool" id="editIcons" style={{ right: -'2px' }}></div><div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" className="section notdraggable SECTION_NAME     " data-section-cd="NAME"><div className=" doc-item"><div className=""><div className=""><div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" className="paragraph PARAGRAPH_NAME firstparagraph  ">
                        <div>
                            <div className="name">
                                <span id="FIELD_FNAM">Diya</span>
                                <span id="FIELD_LNAM">Agarwal</span>
                                <div className="name-line"></div>
                            </div>
                            <div className="profTitle" dependency="DCTL" style={{ display: 'none' }}><span id="FIELD_DCTL"> 
                            </span>
                            </div>
                            </div>
                    </div></div></div></div></div></div></div>
                    <div id="CONTAINER_1" className="right-box"></div></div><div id="CONTAINER_PARENT_1" className="parentContainer"><div id="CONTAINER_2" className="left-box last-box container"><div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-CNTC"><div className="document-tool sec-tool" id="editIcons" style={{ right: -'2px' }}></div><div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" className="section sec-cntc notdraggable SECTION_CNTC    has-title  " data-section-cd="CNTC"><div className=" doc-item"><div className="heading"><div className="sectiontitle" id="SECTIONNAME_CNTC">Contact<span title=" Contact " className="rename-section text-rename"></span></div></div><div className=""><div className=""><div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" className="paragraph PARAGRAPH_CNTC firstparagraph  "><div className="clearfix doc-item">
                        <div className="address">
                            <div className="singlecolumn">
                                <div dependency="EMAI">
                                    <span id="FIELD_EMAI">d.agarwal@sample.in</span>
                                </div>
                                <div dependency="CPHN">
                                    <span id="FIELD_CPHN">+91 11 5555 3345</span>
                                </div>
                                <div className="zipsuffix" dependency="ADDR|STRT|CITY|STAT|ZIPC">
                                    <span id="FIELD_STRT"></span><span dependency="STRT" style={{ display: 'none' }}><span dependency="CITY|STAT|ZIPC" style={{ display: 'none' }}>, </span></span>
                                    <span id="FIELD_CITY"></span><span dependency="CITY" style={{ display: 'none' }}><span dependency="STAT|ZIPC" style={{ display: 'none' }}>, </span></span>
                                    <span id="FIELD_STAT"></span>
                                    <span id="FIELD_ZIPC"></span>
                                    <span id="FIELD_ADDR">New Delhi, India 110034</span>
                                </div>
                                <div dependency="WEB1" style={{ display: 'none' }}>
                                    <span id="FIELD_WEB1"></span>
                                </div>
                                <div dependency="SOCL" className="social" style={{ display: 'none' }}>

                                </div>
                                <span dependency="DOB1|DRIV" style={{ display: 'none' }}>
                                    <div dependency="DOB1" style={{ display: 'none' }}>
                                        <span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_DOB1">undefined</span><span>: </span></span><span id="FIELD_DOB1"></span>
                                    </div>
                                    <div dependency="DRIV" style={{ display: 'none' }}>
                                        <span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_DRIV">undefined</span><span>: </span></span><span id="FIELD_DRIV"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-EDUC"><div className="document-tool sec-tool" id="editIcons" style={{ right: -'2px' }}></div><div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" className="section education SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC"><div className=" doc-item"><div className="heading"><div className="sectiontitle" id="SECTIONNAME_EDUC">Education and Training<span title=" Education and Training " className="rename-section text-rename"></span></div></div><div className=""><div className=""><div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" className="paragraph PARAGRAPH_EDUC firstparagraph  "><div className="clearfix doc-item">
                        <div className="singlecolumn">
                            <div className="paddedline">
                                <span className="jobdates" dependency="GRYR"><span className="xslt_static_change displayNoneThisField">Expected in </span><span id="FIELD_GRYR" format="%m/%Y">2016</span></span>
                            </div>
                            <div className="paddedline txt-bold" dependency="STUY|DGRE">
                                <span className="degree" id="FIELD_DGRE">Diploma in Financial Accounting</span><span dependency="DGRE+STUY" style={{ display: 'none' }}> - </span>
                                <span className="programline" id="FIELD_STUY"></span>
                            </div>
                            <div className="paddedline" dependency="SCIT|SSTA|SCHO|SCNT">
                                <span className="companyname " id="FIELD_SCHO">Oxford Software Institute &amp; Oxford School of English</span>
                                <span className="joblocation jobcity" id="FIELD_SCIT"> New Delhi, India</span><span dependency="SCIT+SSTA|SCNT" style={{ display: 'none' }}>, </span>
                                <span className="joblocation jobstate" id="FIELD_SSTA"></span><span dependency="SSTA+SCNT" style={{ display: 'none' }}>,</span><span className="joblocation jobcountry" id="FIELD_SCNT"></span>
                            </div>
                            <div className="paddedline degreeGap" dependency="GRPA" style={{ display: 'none' }}>
                                <span>GPA: </span><span id="FIELD_GRPA"></span>
                            </div>
                            <span id="FIELD_FRFM"></span>
                        </div>
                    </div></div></div></div></div></div></div></div><div id="CONTAINER_3" className="right-box last-box container"><div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-SUMM"><div className="document-tool sec-tool" id="editIcons" style={{ right: -'2px' }}></div><div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" className="section SECTION_SUMM    has-title  " data-section-cd="SUMM"><div className=" doc-item"><div className="heading"><div className="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " className="rename-section text-rename"></span></div></div><div className=""><div className=""><div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" className="paragraph PARAGRAPH_SUMM firstparagraph  "><div className="clearfix doc-item">
                        <div className="singlecolumn" id="FIELD_FRFM"><p>Customer-focused Retail Sales professional with solid understanding of retail dynamics, marketing and customer service. Offering 5 years of experience providing quality product recommendations and solutions to meet customer needs and exceed expectations. Demonstrated record of exceeding revenue targets by leveraging communication skills and sales expertise.</p></div>
                    </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-HILT"><div className="document-tool sec-tool" id="editIcons" style={{ right: -'2px' }}></div><div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" className="section SECTION_HILT    has-title  " data-section-cd="HILT"><div className=" doc-item"><div className="heading"><div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div></div><div className=""><div className=""><div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" className="paragraph PARAGRAPH_HILT firstparagraph  "><div className="clearfix doc-item">
                        <div className="singlecolumn maincolumn">
                            <div className="skill">
                                <span className="paddedline" id="FIELD_SKC1"><ul>
                                    <li>Cash register operation&nbsp;</li>
                                    <li>POS system operation&nbsp;</li>
                                    <li>Sales expertise&nbsp;</li>
                                    <li>Teamwork</li>
                                </ul></span>
                                <span className="paddedline" id="FIELD_SKC2"><ul>
                                    <li>Inventory management&nbsp;</li>
                                    <li>Accurate money handling&nbsp;</li>
                                    <li>Documentation and recordkeeping&nbsp;</li>
                                    <li>Retail merchandising expertise</li>
                                </ul></span>
                            </div>
                        </div>
                    </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-EXPR"><div className="document-tool sec-tool" id="editIcons" style={{ right: -'2px' }}></div><div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" className="section experience SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR"><div className=" doc-item"><div className="heading"><div className="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " className="rename-section text-rename"></span></div></div><div className=""><div className="sortableInner"><div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" className="paragraph PARAGRAPH_EXPR firstparagraph  "><div className="clearfix doc-item">
                        <div className="singlecolumn">
                            <span className="paddedline">
                                <span className="jobtitle  txt-bold" id="FIELD_JTIT">Retail Sales Associate</span>
                            </span>
                            <span className="paddedline locationGap" dependency="COMP|JSTA|JCIT|JCNT">
                                <span className="companyname txt-bold" id="FIELD_COMP">ZARA</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> | </span></span>
                                <span className="jobcity" id="FIELD_JCIT">New Delhi</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                <span className="jobstate" id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                            </span>
                            <span className="paddedline dates_wrapper" dependency="JSTD|EDDT">
                                <span>
                                    <span className="jobdates" id="FIELD_JSTD" format="%m/%Y">02/2017</span><span dependency="JSTD+EDDT"> - </span>
                                    <span className="jobdates" id="FIELD_EDDT" format="%m/%Y">Current</span><br dependency="JSTD|EDDT" />
                                </span>
                            </span>
                            <span className="jobline" id="FIELD_JDES"><ul>
                                <li>Increased monthly sales 10% by effectively upselling and cross-selling products to maximize profitability.&nbsp;</li>
                                <li>Prevented store losses by leveraging awareness, attention to detail, and integrity to identify and investigate concerns.&nbsp;</li>
                                <li>Processed payments and maintained accurate drawers to meet financial targets.</li>
                            </ul></span>
                        </div>
                    </div></div><div id="PARAGRAPH_EXPR_d7533686-f260-ba36-1ef4-823e4a95f9c8" className="paragraph PARAGRAPH_EXPR  "><div className="clearfix doc-item">
                        <div className="singlecolumn">
                            <span className="paddedline">
                                <span className="jobtitle  txt-bold" id="FIELD_JTIT">Barista</span>
                            </span>
                            <span className="paddedline locationGap" dependency="COMP|JSTA|JCIT|JCNT">
                                <span className="companyname txt-bold" id="FIELD_COMP">Dunkin’ Donuts</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> | </span></span>
                                <span className="jobcity" id="FIELD_JCIT">New Delhi</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                <span className="jobstate" id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                            </span>
                            <span className="paddedline dates_wrapper" dependency="JSTD|EDDT">
                                <span>
                                    <span className="jobdates" id="FIELD_JSTD" format="%m/%Y">03/2015</span><span dependency="JSTD+EDDT"> - </span>
                                    <span className="jobdates" id="FIELD_EDDT" format="%m/%Y">01/2017</span><br dependency="JSTD|EDDT" />
                                </span>
                            </span>
                            <span className="jobline" id="FIELD_JDES"><ul>
                                <li>Upsold seasonal drinks and pastries, boosting average store sales by ₹1500 weekly.&nbsp;</li>
                                <li>Managed morning rush of over 300 customers daily with efficient, levelheaded customer service.&nbsp;</li>
                                <li>Trained entire staff of 15 baristas in new smoothie program offerings and procedures.</li>
                                <li>Developed creative and appealing latte art techniques and instructed coworkers in method.</li>
                            </ul></span>
                        </div>
                    </div></div></div></div></div></div></div></div></div></div><div></div></div></div><div className="template-overlay"><button id="previewTemplate" type="button" className="btn btn-tertiary" data-index="15">Preview Template</button> <button id="btnSelectTemplate" type="button" className="btn btn-primary  ">Select Template</button></div></li>
        </div>
    );
}
export default Temp4;