import React, { useState } from 'react';
import axios from 'axios';
import google_button from '../img/google_button.png';
import link_button from '../img/link_button.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Css/Contactus.scss';
import Footer from './Footer';
import MainNav from './MainNav';
import Contact from './Assets/Contactus.png';

import { commonConfig } from '../config.js';
function Contactus() {
    const [formData, setFormData] = useState({
        name: '',
        contactNo: '',
        email: '',
        message: '',
    });
    const [focusedTextbox, setFocusedTextbox] = useState('');

    const handleFocus = (event) => {
        const { id } = event.target;
        setFocusedTextbox(id);
      };
    
      const handleBlur = () => {
        setFocusedTextbox('');
      };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Make your API call using Axios here
        axios.post(commonConfig.REACT_APP_API_URL + 'home_mail', formData)
            .then((response) => {
                console.log('API response:', response.data);
                if(response.data.success === true){
                  toast.success('Thanks for submitting. We will contact you soon!!!');
                }
                // alert('Thanks for submitting. We will contact you soon!!!');
                
            })
            .catch((error) => {
                console.error('API error:', error);
                // Handle any error that occurred during the API call
            });
    };

    return (
        <div>
          <ToastContainer position="top-center" theme="dark" />
          <div className="contact">
            <MainNav value="6"/>
            
            <div className='contactus'>
                <div className='col-lg-12'>
                    <div className='col-lg-6'>
                        <section className='contact_section'>
                        <h2 className='blue-contactus' style={{ marginLeft: '11%' }}>Contact us</h2>
                        <h6 style={{ marginLeft: '11%', marginTop: '2%', color: '#000000' }}>
                            Your Questions Matter To Us And We're <br /> Commited To Provide With Accurate And<br /> Timely Solutions.
                            So Go Ahead And Ask!
                        </h6>
                        <form onSubmit={handleSubmit}>
                            <div className='formgroup text-input' style={{ marginTop: '2%', marginRight: '10%' }}>
                                <input
                                    type="text"
                                    required
                                    className="formcontrol-contactus"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    onFocus={handleFocus} 
                                    onBlur={handleBlur}
                                />
                                <label className="formlabel" htmlFor="f_name">
                                    Name
                                </label>
                            </div>
                            <div className='formgroup text-input' style={{ marginTop: '2%', marginRight: '10%' }}>
                                <input
                                    type="text"
                                    required
                                    className="formcontrol-contactus"
                                    name="contactNo"
                                    value={formData.contactNo}
                                    onChange={handleInputChange}
                                />
                                <label className="formlabel" >
                                    Contact No
                                </label>
                            </div>
                            <div className='formgroup text-input' style={{ marginTop: '2%', marginRight: '20%' }}>
                                <input
                                    type="text"
                                    required
                                    className="formcontrol-contactus"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                <label className="formlabel" >
                                    E-Mail
                                </label>
                            </div>
                            <div className='formgroup text-input' style={{ marginTop: '2%', marginRight: '20%' }}>
                              <div className="formgroup">
                                  <textarea
                                      required
                                      className="formcontrol-contactus"
                                      style={{ marginTop: '2%', height: '140px',width:'100%' }}
                                      name="message"
                                      value={formData.message}
                                      onChange={handleInputChange}
                                      placeholder="Your Message"
                                  ></textarea>
                              </div>
                              <div className="formgroup"><button id="btnBack" type="submit" className="btn-mangaii" style={{ marginTop: '3%',float: 'left' }} >Submit</button></div>
                            </div>
                                
                        </form>
                        </section>
                    </div>
                    <div className='col-lg-6'>
                        <img src={Contact} className='contact-us' alt='about-us' />
                        <h6 style={{ marginTop: '2%', marginLeft: '20%' }}>
                            You can also reach out to us at <br />Support@GOjobs.ai for any inquiries<br /> or assistance you may require.
                        </h6>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        </div>
        
    );
}

export default Contactus;
