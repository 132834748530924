import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import './Temp6.css';
import html2pdf from 'html2pdf.js';
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver'; // for downloading the file
import htmlDocx from 'html-docx-js/dist/html-docx';
import jsPDF from 'jspdf';

const Resume = forwardRef((props, ref) => {

  const [contact, setContact] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [respokData, setRespokData] = useState(false);
  const [more, setMore] = useState({});
  const [image, setImage] = useState('');
  let resume_id = localStorage.getItem('resume_id');
  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);


  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);

      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);



  const printDocument = (title, fontsize, spacing, color, resumename, justify) => {
    // const element = document.getElementById('pdfContainer');
    // console.log(htmlContent);
    // const data = {
    //   htmlContent: htmlContent,
    //   resume_id: resume_id,
    //   resumename: resumename,
    //   justify: justify,
    //   fontsize: fontsize,
    //   spacing: spacing,
    //   color: color,
    //   template: 6
    // };
    // console.log('htmlContent', htmlContent);
    // axios({
    //   method: "POST",
    //   url: commonConfig.REACT_APP_API_URL + "generate-pdf",
    //   data: data,
    //   headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    // }).then(res => {
    //   if (title == "save") {
    //     toast.success("Saved Successfully!!!");
    //   }

    // }).catch(err => {

    // });

    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(divRef.current, {
      callback: function (pdf) {
        pdf.save('download.pdf');
      },
      x: 10,          // Set the x offset for margin
      y: 10,          // Set the y offset for margin
      margin: 10,     // Apply a margin to all sides
      width: 550,     // Adjust content width based on A4 width and desired margins
      windowWidth: 900, // Control scaling based on browser window width
    });
  };
 const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print,
    downloadWordDoc: downloadWordDoc,
  }));


  const downloadWordDoc = () => {
    // const content = `
    // `;

    // const blob = new Blob([content], {
    //   type: 'application/msword'
    // });

    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'resume.doc';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);

    const cssStyles = `
      <style>
      .resume-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .resume-header-cell {
    width: 33.33%;
    padding: 10px;
    border-bottom: 1px solid rgb(172 168 170 / 28%);
    vertical-align: bottom;
  }
  
  .resume-name {
    margin: 0;
    line-height: 1;
    font-size: 36px;
    font-family: "Gill Sans MT", sans-serif;
    color: var(--theme-color);
  }
  
  .resume-contact-cell {
    width: 33.33%;
    padding: 10px;
    border-bottom: 1px solid rgb(172 168 170 / 28%);
    vertical-align: bottom;
    text-align: right;
  }
  
  .resume-contact-info {
    margin: 0;
    font-size: 16px;
    font-family: "Gill Sans MT", sans-serif;
    color: black;
  }
  
  .resume-summary-cell {
    width: 100%;
    padding: 10px;
    /* border-bottom: 1px solid rgb(172 168 170 / 28%); */
    vertical-align: top;
  }
  
  .resume-summary {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    font-family: "Gill Sans MT", sans-serif;
    color: #404040;
  }
  
  .resume-experience-cell {
    width: 100%;
    padding: 10px;
    /* border-bottom: 1px solid rgb(172 168 170 / 28%); */
    vertical-align: top;
  }
  
  .resume-section-heading {
    margin: 0;
    font-size: 21px;
    font-family: "Gill Sans MT", sans-serif;
    color: var(--theme-color);
    font-weight: normal;
  }
  
  .resume-subheading {
    margin: 8px 0 4px;
    font-size: 16px;
    font-family: "Gill Sans MT", sans-serif;
    color: black;
  }
  
  .resume-date-range {
    margin: 0;
    font-size: 12px;
    font-family: "Gill Sans MT", sans-serif;
    color: black;
    font-weight: normal;
  }
  
  .resume-description {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    font-family: "Gill Sans MT", sans-serif;
    color: #404040;
  }
  
  .resume-education-cell {
    width: 100%;
    padding: 0 10px 0 10px;
    /* border-bottom: 1px solid rgb(172 168 170 / 28%); */
    vertical-align: top;
  }
  
  .resume-education-details {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    font-family: "Gill Sans MT", sans-serif;
    color: #404040;
  }
  
  .resume-skills-cell {
    width: 100%;
    padding: 10px;
    vertical-align: top;
  }
  
  .resume-skills-list {
    margin: 0;
    line-height: 1.5;
    font-size: 12px;
    font-family: "Gill Sans MT", sans-serif;
    color: #404040;
  }
  
  .resume-empty-row {
    border: none;
  }
  
  .resume-empty-paragraph {
    margin: 0;
    font-size: 12px;
    font-family: "Gill Sans MT", sans-serif;
    color: #404040;
  }
  .resume-container p{
    font-size: var(--font-size) !important;
    line-height: var(--line-height) !important;
  }
  .resume-container .resume-subheading{
    font-size: var(--font-size) !important;
    line-height: var(--line-height) !important;
    font-weight: bold;
  }
  .resume-container .resume-date-range{
    font-size: var(--font-size) !important;
    line-height: var(--line-height) !important;
  }
  .resume-container .resume-contact-info{
    font-size: var(--font-size) !important;
    line-height: var(--line-height) !important;
  }
  .resume-container li{
    font-size: var(--font-size) !important;
    line-height: var(--line-height) !important;
  }
    
  .ptag{
    font-size: var(--font-size);
    line-height: var(--line-height);
  }
  .resume-skills-list{
    font-size: var(--font-size);
  }
  .resume-container ul{
    list-style: circle !important;
  }
  .resume-container p{
    margin-top: 0;
    margin-bottom: 0rem !important;
  }
  .resumemain{
    border-bottom: 1px solid #000; 
  }

      </style>
      `;
const contentElement = document.getElementById('downloadToWord').innerHTML;
    const contentWithStyles = cssStyles + contentElement;
    const docx = htmlDocx.asBlob(contentWithStyles, { orientation: 'portrait' });
    saveAs(docx, 'resume.docx');
  };
  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      <div {...(props.contentNotEditable ? {} : { contentEditable: true })} id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)}>
        {respokData ? (
          <div className="resume-container">
            <div className="resume-table">
              <div>
                <div>
                  <div style={{ width: '50%', float: 'left' }} colspan="2" className="resume-header-cell">
                    <div className="resume-name" style={{ fontsize: '22px !important' }}>{contact.f_name} {contact.l_name}</div>
                    {/* <p className="resume-name"></p> */}
                  </div>
                  <div className="resume-contact-cell" style={{ width: '50%', float: 'right' }}>
                    {contact.city && <span className="resume-contact-info"> {contact.city} </span>}<br />
                    <span className="resume-contact-info">{contact.mobile} | {contact.email}</span>
                  </div>
                </div>
                <div style={{ clear: 'both' }}>
                  <div colspan="3" className="resume-summary-cell">
                    <div className="resume-section-heading">Summary</div>
                    <p>
                      {contact.summery}
                    </p>
                  </div>
                </div>
                <div>
                  <div colspan="3" className="resume-experience-cell">
                    <div className="resume-section-heading">Experience</div>
                    <div className="heading">

                    </div>
                    {experience?.map((item, index) => (
                      <div className="">

                        <div className="">
                          <div colspan="2" className="">
                            <div>
                              <h5 className="resume-subheading">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</h5>
                              <div className="resume-date-range">{item.employer}| {item.city}, {item.country}</div>
                              <div className="resume-date-range">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}– {item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</div>

                              <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />

                            </div>
                            {item.achievements != '[]' && item.achievements != '[null]' && (
                              <div className="mb25">
                                <div colspan="3">
                                  <br />
                                  <ul>
                                    {JSON.parse(item.achievements)?.map((item1, index) => (
                                      item1 && <li key={index}>{item1}</li>
                                    ))}
                                  </ul>

                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                      </div>

                    ))}
                  </div>
                </div>
                <div>
                  <div colspan="3" className="resume-summary-cell">
                    <div className="resume-section-heading">Education</div>
                    {education?.map((item, index) => (
                      <div className="">

                        <p>
                          <div colspan="3" className="">
                            <div>
                              <span className="degree">
                                {item.course} &nbsp;
                                {item.specialization && <span className="degree">,
                                  &nbsp;{item.specialization} &nbsp;
                                </span>}
                                
                                {item.university && <span className="jobdates">
                                  <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                </span>}
                                {item.year_of_completion && <span className="jobdates">
                                  <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                </span>}
                              </span>
                            </div>

                            {item.achievements != '[]' && item.achievements != '[null]' && (
                              <div className="mb25">
                                <div colspan="3">
                                  <br />
                                  <ul>
                                    {JSON.parse(item.achievements)?.map((item1, index) => (
                                      item1 && <li key={index}>{item1}</li>
                                    ))}
                                  </ul>

                                </div>
                              </div>
                            )}

                          </div>
                        </p>


                      </div>

                    ))}
                  </div>
                </div>

                <div>
                  <div className="resume-skills-list">
                    <div colspan="3" className="resume-summary-cell">
                      <div className="resume-section-heading">Skills</div>
                      <div className="resume-skills-list">

                        {skills[0] &&
                          <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                        }

                      </div>
                    </div>
                  </div>
                </div>

                {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                  <div>
                    <div className="resume-skills-list">
                      <div colspan="3" className="resume-skills-cell">
                        <div className="resume-section-heading">Other Information</div>

                        {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null && <div className="singlecolumn maincolumn">
                          <b>Languages:</b>
                          <span className="paddedline" id="FIELD_SKC1">
                            <ul>
                              {JSON.parse(more)?.language?.map((item, index) => (
                                <li key={item.language}>{item.language}</li>
                              ))}
                            </ul>
                          </span>
                        </div>}

                      </div>
                    </div>
                    {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn resume-skills-cell">
                      <b>Website & Profiles:</b>
                      <span className="paddedline" id="FIELD_SKC1">
                        <p className="degree">
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.work && <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.portfolio && <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.profile && <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                          ))}
                        </p>
                      </span>
                    </div>}

                    {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                      {JSON.parse(more)?.custom?.map((item, index) => (
                        <div colspan='3' class="resume-skills-cell">
                          <b>{item.custom_name}</b>
                          <span className="paddedline" id="FIELD_SKC1">
                            <div>
                              {item.ckeditor && <div className="custom_6" dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>}
                  </div>
                }



              </div>
            </div>
            <p className="resume-empty-paragraph">&nbsp;</p>
          </div>

        ) : (
          <Loading />
        )}
      </div>
     <div {...(props.contentNotEditable ? {} : { contentEditable: true })} id="downloadToWord"  onInput={(e) => setHtmlContent(e.target.innerHTML) }style={{ padding: '10px',display:'none' }}>
     {respokData ? (
       <div className="resume-container">
            <div className="resume-table">
              <div>
                <div class ="resumemain">
                  <div style={{ width: '50%', float: 'left' }} colspan="2" className="resume-header-cell">
                    <div className="resume-name" style={{ fontsize: '22px !important' }}>{contact.f_name} {contact.l_name}</div>
                    {/* <p className="resume-name"></p> */}
                  </div>
                  <div className="resume-contact-cell" style={{ width: '50%', float: 'right' }}>
                    {contact.city && <span className="resume-contact-info"> {contact.city} </span>}<br />
                    <span className="resume-contact-info">{contact.mobile} | {contact.email}</span>
                  </div>
                </div>
                <div style={{ clear: 'both' }}>
                  <div colspan="3" className="resume-summary-cell">
                    <div className="resume-section-heading">Summary</div>
                    <p>
                      {contact.summery}
                    </p>
                  </div>
                </div>
                <div>
                  <div colspan="3" className="resume-experience-cell">
                    <div className="resume-section-heading">Experience</div>
                    <div className="heading">

                    </div>
                    {experience?.map((item, index) => (
                      <div className="">

                        <div className="">
                          <div colspan="2" className="">
                            <div>
                              <h5 className="resume-subheading">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</h5>
                              <div className="resume-date-range">{item.employer}| {item.city}, {item.country}</div>
                              <div className="resume-date-range">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}– {item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</div>

                              <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />

                            </div>
                            {item.achievements != '[]' && item.achievements != '[null]' && (
                              <div className="mb25">
                                <div colspan="3">
                                  <br />
                                  <ul>
                                    {JSON.parse(item.achievements)?.map((item1, index) => (
                                      item1 && <li key={index}>{item1}</li>
                                    ))}
                                  </ul>

                                </div>
                              </div>
                            )}

                          </div>
                        </div>
                      </div>

                    ))}
                  </div>
                </div>
                <div>
                  <div colspan="3" className="resume-summary-cell">
                    <div className="resume-section-heading">Education</div>
                    {education?.map((item, index) => (
                      <div className="">

                        <p>
                          <div colspan="3" className="">
                            <div>
                              <span className="degree">
                                {item.course} &nbsp;
                                {item.specialization && <span className="degree">,
                                  &nbsp;{item.specialization} &nbsp;
                                </span>}
                                
                                {item.university && <span className="jobdates">
                                  <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                </span>}
                                {item.year_of_completion && <span className="jobdates">
                                  <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                </span>}
                              </span>
                            </div>

                            {item.achievements != '[]' && item.achievements != '[null]' && (
                              <div className="mb25">
                                <div colspan="3">
                                  <br />
                                  <ul>
                                    {JSON.parse(item.achievements)?.map((item1, index) => (
                                      item1 && <li key={index}>{item1}</li>
                                    ))}
                                  </ul>

                                </div>
                              </div>
                            )}

                          </div>
                        </p>


                      </div>

                    ))}
                  </div>
                </div>

                <div>
                  <div className="resume-skills-list">
                    <div colspan="3" className="resume-summary-cell">
                      <div className="resume-section-heading">Skills</div>
                      <div className="resume-skills-list">

                        {skills[0] &&
                          <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                        }

                      </div>
                    </div>
                  </div>
                </div>

                {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                  <div>
                    <div className="resume-skills-list">
                      <div colspan="3" className="resume-skills-cell">
                        <div className="resume-section-heading">Other Information</div>

                        {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null && <div className="singlecolumn maincolumn">
                          <b>Languages:</b>
                          <span className="paddedline" id="FIELD_SKC1">
                            <ul>
                              {JSON.parse(more)?.language?.map((item, index) => (
                                <li key={item.language}>{item.language}</li>
                              ))}
                            </ul>
                          </span>
                        </div>}

                      </div>
                    </div>
                    {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn resume-skills-cell">
                      <b>Website & Profiles:</b>
                      <span className="paddedline" id="FIELD_SKC1">
                        <p className="degree">
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.work && <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.portfolio && <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                          ))}
                          {JSON.parse(more)?.website?.map((item, index) => (
                            item.profile && <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                          ))}
                        </p>
                      </span>
                    </div>}

                    {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                      {JSON.parse(more)?.custom?.map((item, index) => (
                        <div colspan='3' class="resume-skills-cell">
                          <b>{item.custom_name}</b>
                          <span className="paddedline" id="FIELD_SKC1">
                            <div>
                              {item.ckeditor && <div className="custom_6" dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                            </div>
                          </span>
                        </div>
                      ))}
                    </div>}
                  </div>
                }



              </div>
            </div>
            <p className="resume-empty-paragraph">&nbsp;</p>
          </div>
          
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
});

export default Resume;
