import React, { useEffect, useState } from "react";
import { FaCalendarAlt } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import Nav from "./Nav";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash,faCircleChevronUp,faCircleChevronRight } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import axios from 'axios';
import { commonConfig } from '../config.js';
import Slider from '@mui/material/Slider';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import Formtemplate from "./Formtemplate";


const marks = [
  {
    value: 0,
    label: 'Beginner',
  },
  {
    value: 50,
    label: 'Intermediate',
  },
  {
    value: 100,
    label: 'Proficient',
  },
];

function valuetext(value) {
  return `${value}°C`;
}

function RangeSlider() {
  return (
    <Box sx={{ width: 300 }}>
      <Slider
        aria-label="Always visible"
        getAriaValueText={valuetext}
        step={50}
        marks={marks}
        valueLabelDisplay="on"
      />
    </Box>
  );
}
function Step6() {
  const [formData, setFormData] = useState([
    {

      language: '',
      sliderValue: 0,

    },
  ]);
  const [formData1, setFormData1] = useState([{ work: '', portfolio: '', profile: '', }]);

  const [formData2, setFormData2] = useState([
    {

      custom_name: '',
      ckeditor: '',

    },
  ]);
  const [page, setPage] = useState(false);
  const [stepno, setStepno] = useState(6);
  const [more, setMoreData] = useState([]);
  const resume_data = localStorage.getItem('resume_data');
  const resume_id = localStorage.getItem('resume_id');
  const userId = localStorage.getItem('userId');
  const temp_resume_id = localStorage.getItem('temp_resume_id');
  const parsed_json_data = localStorage.getItem('parsed_json_data');
  let is_edit = 0;
  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);


  const handleEditorChange = (event, editor, index) => {
    const data = editor.getData();
    const updatedFormData2 = [...formData2];
    updatedFormData2[index]['ckeditor'] = data;

    setFormData2(updatedFormData2);
  };



  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    import('./Css/Step6.scss');



    if (resume_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        const more = JSON.parse(result.contact[0].more);

        const contact = result.contact[0];
        if (contact.step === 100) {
          // navigate('/step13');
          setStepno(contact.step);
        } else {
          setStepno(contact.step + 1);
        }

        if (more) {


          setFormData(more.language);
          setFormData1(more.website);
          setFormData2(more.custom);
          setMoreData(more);
        } else {
          if (parsed_json_data != undefined) {
            const parsed_exp = JSON.parse(parsed_json_data)?.languages;
            const updatedFormData = parsed_exp.map((item) => ({
              ...item,
              language: item,
              sliderValue: 0
            }));
            if (parsed_exp.length > 0) {
              setFormData(updatedFormData);
            }
            const parsed_profile = JSON.parse(parsed_json_data);
            const newData = { work: parsed_profile.website, portfolio: parsed_profile.portfolio, profile: '' };
            const updatedFormData2 = [newData];
            setFormData1(updatedFormData2);
          }
          get_temp_data(temp_resume_id);
        }


      }).catch(err => {

      });
    }
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const get_temp_data = (resume_id) => {
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      const more = JSON.parse(result.contact[0].more);

      const contact = result.contact[0];

      if (more) {
        setFormData(more.language);
        setFormData1(more.website);
        setFormData2(more.custom);
        setMoreData(more);
      }


    }).catch(err => {

    });
  }

  const navigate = useNavigate();

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const updatedFormData = [...formData];
    updatedFormData[index][name] = value;
    setFormData(updatedFormData);
  };

  const handleChange1 = (event, index1) => {
    const { name, value } = event.target;
    const updatedFormData1 = [...formData1];
    updatedFormData1[index1][name] = value;
    setFormData1(updatedFormData1);
  };



  const handleChange2 = (event, index) => {
    const { name, value } = event.target;
    const updatedFormData2 = [...formData2];
    updatedFormData2[index][name] = value;
    setFormData2(updatedFormData2);
  };


  const handleAddField = () => {
    setFormData((prevFormData) => [
      ...prevFormData,
      {
        language: '',
        sliderValue: 0,
      },
    ]);
  };
  const handleAddField1 = () => {
    setFormData1((prevFormData1) => [
      ...prevFormData1,
      {
        work: '', portfolio: '', profile: '',
      },
    ]);
  };
  const handleAddField2 = () => {
    setFormData2((prevFormData2) => [
      ...prevFormData2,
      {
        custom_name: '',

      },
    ]);
  };



  const handleSubmit = (event) => {
    event.preventDefault();
    const resume_id = localStorage.getItem('resume_id');
    if (more.length > 0) {
      is_edit = 1;
    }
    setPage(false);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "contacts/more_update",
      data: { language: formData, website: formData1, custom: formData2 },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": 6, "isedit": is_edit },
    }).then(res => {
      var decodedData = res.data;
      var status = res.status;
      if (status) {
        navigate('/step9');
      }
    }).catch(err => {

    });

  };

  const removeLanguage = (event, index) => {
    event.preventDefault();
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };



  const [activeTab, setActiveTab] = useState(0);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  const toggleAccordion1 = () => {
    setIsOpen1(!isOpen1);
  };
  const toggleAccordion2 = () => {
    setIsOpen2(!isOpen2);
  };

  return (
    <div className="step6page">
      {page ? (
        <main id="app" role="main">
          <section className="page-wrapper">
            <div
              id="parent"
              className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
            >
              {page && <Nav value={stepno} />}

              <div className="col-lg-12">
                <div className="col-lg-7">
                  <div className="exp_section">
                    <h3 className="headtag">
                      <span className="blue">Wish to Add More Sections</span>
                    </h3>
                    <p>These are optional</p>
                    <div className="exp_list">
                      <div className="m-10" onClick={toggleAccordion}><span className="f-17 blue fw-500">Language</span>
                      <span>
                        {isOpen ? <FontAwesomeIcon icon={faCircleChevronUp} style={{color: "#95a0b1",}} size="2xl" /> : <FontAwesomeIcon icon={faCircleChevronRight} style={{color: "#95a0b1",}} size="2xl" />}
                      </span></div>
                      {isOpen && <div className="exp_title">
                        <div className="m-10">
                          <div className="tab1-content formgroup">
                            {formData.map((field, index) => (
                              <div className="col-lg-12" style={{ marginBottom: '6rem' }}>
                                <div className="col-lg-2" style={{ clear: "both" }}>
                                  {/* <FontAwesomeIcon icon={faTrash} /> */}
                                </div>
                                <div className="col-lg-4">
                                  <input
                                    type="text"
                                    name="language"
                                    className="formcontrol"
                                    value={field.language}
                                    onChange={(event) => handleChange(event, index)}
                                  />
                                  {/* <label className="formlabel" style={{marginLeft:"10px"}} htmlFor={`language${index}`}>
																	LANGUAGE
																</label> */}
                                </div>
                                <div className="col-lg-6">
                                  <div className="col-lgg-8">
                                    <Box sx={{ width: 250 }}>
                                      <Slider
                                        aria-label="Always visible"
                                        getAriaValueText={valuetext}
                                        step={50}
                                        // color="43b0df"
                                        name="sliderValue"
                                        marks={marks}
                                        value={field.sliderValue}
                                        onChange={(event, newValue) => handleChange(event, index)}
                                      />
                                    </Box>
                                  </div>
                                  <div className="col-lgg-4 center">
                                    {/* <button className="addmore_button_small" onClick={(event) => removeLanguage(event, index)}>-</button> */}
                                    <span className="clickable"><FontAwesomeIcon onClick={(event) => removeLanguage(event, index)} icon={faTrashAlt} /></span>
                                  </div>



                                </div>

                              </div>
                            ))}
                            <div className="col-lg-12" style={{ clear: "both" }}>
                              <a className="addmore" onClick={handleAddField}>+ Add Languages</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            </div>
                          </div>
                        </div>
                      </div>}
                    </div>

                    <div className="exp_list">
                      <div className="m-10" onClick={toggleAccordion1}><span className="f-17 blue fw-500">Profile Link</span>
                      <span>
                        {isOpen1 ? <FontAwesomeIcon icon={faCircleChevronUp} style={{color: "#95a0b1",}} size="2xl" /> : <FontAwesomeIcon icon={faCircleChevronRight} style={{color: "#95a0b1",}} size="2xl" />}
                      </span>
                      </div>
                      {isOpen1 && <div className="exp_title">
                        <div className="m-10">
                          <form>
                            {formData1.map((field, index) => (
                              <div className="col-lg-12" key={index}>
                                <div className="formgroup">
                                  <input
                                    type="text"
                                    name="work"
                                    className="formcontrol"
                                    value={field.work}
                                    onChange={(event) => handleChange1(event, index)}
                                  />
                                  <label className="formlabel" htmlFor={`work${index}`}>
                                    WEBSITE LINK
                                  </label>
                                </div>
                                <div className="formgroup">
                                  <input
                                    type="text"
                                    name="profile"
                                    className="formcontrol"
                                    value={field.profile}
                                    onChange={(event) => handleChange1(event, index)}
                                  />
                                  <label className="formlabel" htmlFor={`profile${index}`}>
                                    PROFILE LINK
                                  </label>
                                </div>

                                <div className="formgroup" style={{ marginBottom: '55px' }}>
                                  <input
                                    type="text"
                                    name="portfolio"
                                    className="formcontrol"
                                    value={field.portfolio}
                                    onChange={(event) => handleChange1(event, index)}
                                  />
                                  <label className="formlabel" htmlFor={`portfolio${index}`}>
                                    PORTFOLIO LINK
                                  </label>
                                </div>



                              </div>
                            ))}
                          </form>
                        </div>
                      </div>}
                    </div>

                    <div className="exp_list">
                      <div className="m-10" onClick={toggleAccordion2}><span className="f-17 blue fw-500">Add Custom</span>
                      <span>
                        {isOpen2 ? <FontAwesomeIcon icon={faCircleChevronUp} style={{color: "#95a0b1",}} size="2xl" /> : <FontAwesomeIcon icon={faCircleChevronRight} style={{color: "#95a0b1",}} size="2xl" />}
                      </span>
                      </div>
                      {isOpen2 && <div className="exp_title">
                        <div className="tab2-content formgroup">
                          <p className="p_tag">You can add anything which you would like to showcase in your resume live Social Work, Certificates etc. Click on Add more to add more headings</p>
                          {formData2.map((field, index) => (
                            <div className="row" key={index}>
                              <div className="col-lg-12">
                                <div className="formgroup">
                                  <input
                                    type="text"
                                    name="custom_name"
                                    className="formcontrol"
                                    value={field.custom_name}
                                    onChange={(event) => handleChange2(event, index)}
                                  />
                                  <label className="formlabel" htmlFor={`custom_name${index}`}>
                                    CUSTOM NAME
                                  </label>
                                </div>
                              </div>
                              <div className="col-lg-12" style={{ marginBottom: '55px' }}>
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={field.ckeditor}
                                  onChange={(event, editor) => handleEditorChange(event, editor, index)}

                                />
                              </div>
                            </div>
                          ))}
                          <div className="col-lg-6">
                            <a className="addmore" onClick={handleAddField2}>+ Add Custom</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          </div>
                        </div>
                      </div>}
                    </div>


                    <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                      <Link onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                    </div>
                    <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                      <button type="submit" onClick={handleSubmit} className="btn-mangaii">Next</button>
                    </div>
                  </div>

                </div>
                <div className="col-lg-5 system_view">
                  <div className="api_right">
                    <Link to="/template/Step5" className="clickable p-10">Change Template</Link>
                    <Formtemplate />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <StepsFooter />
        </main>

      ) : (
        <Loading content="Please Wait!!!" />

      )}
    </div>
  );
}

export default Step6;