import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import logo from "../img/logo.png";
import { BrowserRouter as Router, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { commonConfig } from '../config.js';
import axios from "axios";

function CoverNav(props) {

  const [step1, setStep1] = useState('');
  const [step2, setStep2] = useState('');
  const [step3, setStep3] = useState('');
  const [step4, setStep4] = useState('');
  const [step5, setStep5] = useState('');
  const [step6, setStep6] = useState('');
  const [step8, setStep8] = useState('');
  const [stepnumber, setStepnumber] = useState(1);
  const [plan, setPlan] = useState([]);
  const userId = localStorage.getItem('userId');
  const navigate = useNavigate();
  const CommonData = localStorage.getItem('common_data');
  const ip = localStorage.getItem('ip');
  const location = useLocation();
  const currentPath = location.pathname;

  const goBack = () => {
    navigate(-1); // Go back one step in history
  };

  useEffect(() => {
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "get-ip",
      data: { page: currentPath },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data.ip_address;
      localStorage.setItem('ip', decodedData);
    }).catch(err => {

    });

    if (CommonData) {
      if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
        if (props.action != '12') {
          navigate('/step14');
        }
      }
    }

    console.log("props.value", props.value);
    setStepnumber(props.value);
    if (props.value === 100) {
      setStep8("active");
      setStep6("active");
      setStep5("active");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value >= 7) {
      setStep8("progress8");
      setStep6("active");
      setStep5("active");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 6) {
      setStep6("progress6");
      setStep5("active");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    }
    else if (props.value === 5) {
      setStep5("progress5");
      setStep4("active");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 4) {
      setStep4("progress4");
      setStep3("active");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 3) {
      setStep3("progress3");
      setStep2("active");
      setStep1("active");
    } else if (props.value === 2) {

      setStep2("progress2");
      setStep1("active");
    } else if (props.value === 1) {
      setStep1("progress1");
    }
  }, [props.value]);


  return (
    <div>
      <div className="navpage system_view">
        <div className="col-sm-4">
          <ul className="nav">
            <Link to="/home">
              <img src={logo} alt="Logo" className="logo-image" />
            </Link>
          </ul>
        </div>
        {currentPath != "/plans" && <div className="col-sm-8">
          <nav className="navbar" aria-label="top-navbar">
            <div className="progress-wrapper  " role="navigation" aria-label="Progress bar">
              <ul className="progress-group" id="progressBar">
                <li className={"progress-item cursor-default " + step1} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="CNTC" to="/coverStep1">SELECT TONE</Link>
                </li>
                <li className={"progress-item cursor-default " + step2} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="EXPR" {...(props.value >= 2 ? { to: '/coverStep2' } : {})}>JOB DETAILS</Link>
                </li>
                <li className={"progress-item cursor-default " + step3} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="EDUC" {...(props.value >= 3 ? { to: '/coverStep3' } : {})}>RESUME DETAILS</Link>
                </li>
                <li className={"progress-item cursor-default " + step4} style={{ width: '108.4px' }}>
                  <Link className="progress-link" id="SUMM" {...(props.value >= 5 ? { to: '/coverStep4' } : {})}>FINALIZE</Link>
                </li>
              </ul>
              <div className="progress">
                <div className="progress-bar" id="activeState" style={{ width: '149.9px' }}></div>
              </div>
            </div>

          </nav>
        </div>}
      </div>
      <div className="mobile_view">
        {currentPath != "/plans" && <div className="dropdown1 center">
          {/* <img className="dropbtn image15" /> */}
          {currentPath == "/coverStep1" && <button type="button" className="btn-joe hdr_btn">SELECT TONE</button>}
          {currentPath == "/coverStep2" && <button type="button" className="btn-joe hdr_btn">JOB DETAILS</button>}
          {currentPath == "/coverStep3" && <button type="button" className="btn-joe hdr_btn">RESUME DETAILS</button>}
          {currentPath == "/coverStep4" && <button type="button" className="btn-joe hdr_btn">FINALIZE</button>}
          <div className="dropdown1-content">
            <Link className={(currentPath === '/coverStep1') ? 'm_active' : ''} to="/coverStep1">SELECT TONE</Link>
            <Link className={(currentPath === '/coverStep2') ? 'm_active' : ''} {...(props.value >= 2 ? { to: '/coverStep2' } : {})}>JOB DETAILS</Link>
            <Link className={(currentPath === '/coverStep3') ? 'm_active' : ''} {...(props.value >= 3 ? { to: '/coverStep3' } : {})}>RESUME DETAILS</Link>
            <Link className={(currentPath === '/coverStep4') ? 'm_active' : ''} {...(props.value >= 4 ? { to: '/coverStep4' } : {})}>FINALIZE</Link>
          </div>
        </div>}
      </div>
    </div>
  );
}

export default CoverNav;


