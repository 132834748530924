import React, { Component } from "react";
import "./Temp1.css";

import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";

function Temp1() {
	return (
		<div>
			<li className="template-preview exp" data-skinid="MLK5" data-skincd="MLK5">
				<div className="render-preview">
					<div className="" tabindex="">
						<div></div>
						<div className="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlk1 MLK5  LUK  " docskinwidth="525">
							<div id="CONTAINER_PARENT_0" className="parentContainer">
								<div id="CONTAINER_0" className="left-box last-box">
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-NAME">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }}></div>
										<div id="SECTION_NAME25a3bf91-f80d-4376-95be-f3561350d955" className="section notdraggable name-sec SECTION_NAME    " data-section-cd="NAME">
											<div className=" doc-item">
												<div className="">
													<div className="">
														<div id="PARAGRAPH_NAME_5d630705-f750-47a3-a5ba-01626b4c4bd2" className="paragraph PARAGRAPH_NAME firstparagraph  ">
															<div>
																<div className="resumeTitleParent" dependency="DCTL" style={{ display: 'none' }}>
																	<div className="resumeTitle"><span id="FIELD_DCTL" className="placeholder-text">undefined</span></div>
																</div>
																<div className="name">
																	<span id="FIELD_FNAM">Ishaan</span>
																	<span id="FIELD_LNAM">Agarwal</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-SUMM">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }}></div>
										<div id="SECTION_SUMMc1414a8c-6865-4672-936c-c053bc81b85c" className="section SECTION_SUMM    has-title " data-section-cd="SUMM">
											<div className=" doc-item">
												<div className="heading">
													<div className="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " className="rename-section text-rename"></span></div>
												</div>
												<div className="">
													<div className="">
														<div id="PARAGRAPH_SUMM_962095c5-2dd9-6dd1-7221-e575e11d0b5b" className="paragraph PARAGRAPH_SUMM firstparagraph  ">
															<div className="clearfix doc-item">
																<div className="singlecolumn" id="FIELD_FRFM">
																	<p>Customer-focused Retail Sales professional with solid understanding of retail dynamics, marketing and customer service. Offering 5 years of experience providing quality product recommendations and solutions to meet customer needs and exceed expectations. Demonstrated record of exceeding revenue targets by leveraging communication skills and sales expertise.</p>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-EXPR">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }}></div>
										<div id="SECTION_EXPRdc4cecbc-87e8-4419-8406-94af6897d293" className="section experience SECTION_EXPR   multi-para has-title " data-section-cd="EXPR">
											<div className=" doc-item">
												<div className="heading">
													<div className="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " className="rename-section text-rename"></span></div>
												</div>
												<div className="">
													<div className="">
														<div id="PARAGRAPH_EXPR_c7345e1f-d6d2-9173-0709-23e027c27d63" className="paragraph PARAGRAPH_EXPR firstparagraph  ">
															<div className="clearfix doc-item">
																<div className="singlecolumn">
																	<span className="paddedline" dependency="JTIT|JSTD|EDDT">
																		<span className="jobtitle txt-bold" id="FIELD_JTIT">Retail Sales Associate</span><span dependency="JTIT+JSTD|EDDT">, </span>
																		<span className="jobdates" dependency="JSTD|EDDT">
																			<span id="FIELD_JSTD" format="%m/%Y">02/2017</span><span dependency="JSTD+EDDT"> - </span>
																			<span id="FIELD_EDDT" format="%m/%Y">Current</span>
																		</span>
																	</span>
																	<span className="paddedline locationGap txt-bold" dependency="COMP|JSTA|JCIT|JCNT">
																		<span className="companyname" id="FIELD_COMP">ZARA</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> - </span></span>
																		<span className="jobcity" id="FIELD_JCIT">New Delhi</span><span dependency="JCIT+JSTA|JCNT">, </span>
																		<span className="jobstate" id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
																	</span>
																	<span className="jobline" id="FIELD_JDES">
																		<ul>
																			<li>Increased monthly sales 10% by effectively upselling and cross-selling products to maximize profitability.&nbsp;</li>
																			<li>Prevented store losses by leveraging awareness, attention to detail, and integrity to identify and investigate concerns.&nbsp;</li>
																			<li>Processed payments and maintained accurate drawers to meet financial targets.</li>
																		</ul>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-EDUC">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }}></div>
										<div id="SECTION_EDUC4be5bc21-4afb-4500-b907-608fd306f119" className="section education SECTION_EDUC   multi-para has-title " data-section-cd="EDUC">
											<div className=" doc-item">
												<div className="heading">
													<div className="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education " className="rename-section text-rename"></span></div>
												</div>
												<div className="">
													<div className="">
														<div id="PARAGRAPH_EDUC_a6ed5687-d23c-0e42-7a1d-7c7a5424420a" className="paragraph PARAGRAPH_EDUC firstparagraph  ">
															<div className="clearfix doc-item">
																<div className="singlecolumn">
																	<div className="paddedline" dependency="DGRE|GRYR|STUY|GRHN|GRST|GRED|GRIP">
																		<span className="degree" id="FIELD_DGRE">Diploma in Financial Accounting</span><span dependency="DGRE+STUY" style={{ display: 'none' }}>, </span><span className="programline" id="FIELD_STUY"></span><span dependency="GRHN" className="programline" style={{ display: 'none' }}><span dependency="DGRE|STUY"> - </span></span><span id="FIELD_GRHN" className="programline"></span><span dependency="DGRE|STUY|GRHN"><span dependency="GRYR|GRST|GRED|GRIP">, </span></span><span className="jobdates"><span className="xslt_static_change displayNoneThisField">Expected in </span><span id="FIELD_GRYR" format="%m/%Y">2016</span></span><span dependency="GRST|GRED|GRIP" style={{ display: 'none' }}><span className="jobdates" id="FIELD_GRST" format="%m/%Y"></span><span className="jobdates" dependency="GRST+GRED" style={{ display: 'none' }}> - </span><span className="jobdates" id="FIELD_GRED" format="%m/%Y"></span><span dependency="GRED|GRST" style={{ display: 'none' }}><span dependency="GRIP" className="jobdates" style={{ display: 'none' }}> - </span></span><span id="FIELD_GRIP" className="jobdates"></span></span>
																	</div>
																	<div className="paddedline" dependency="SCIT|SSTA|SCHO|SCNT">
																		<span className="companyname" id="FIELD_SCHO">Oxford Software Institute &amp; Oxford School of English</span><span dependency="SCHO"><span dependency="SCIT|SSTA|SCNT"> - </span></span><span className="joblocation jobcity" id="FIELD_SCIT"> New Delhi, India</span><span dependency="SCIT+SSTA|SCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobstate" id="FIELD_SSTA"></span><span dependency="SSTA+SCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_SCNT"></span>
																	</div>
																	<div className="paddedline degreeGap" dependency="GRPA" style={{ display: 'none' }}>
																		<span className="xslt_static_change">GPA</span><span className="beforecolonspace"> </span><span>: </span><span id="FIELD_GRPA"></span>
																	</div>
																	<span id="FIELD_FRFM"></span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-CUST">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }}></div>
										<div id="SECTION_CUST8bd29544-9b4f-f911-f532-010d9cc83e21" className="section SECTION_CUST    has-title " data-section-cd="CUST">
											<div className=" doc-item">
												<div className="heading">
													<div className="sectiontitle" id="SECTIONNAME_CUST">Interests and Hobbies<span title=" Interests and Hobbies " className="rename-section text-rename"></span></div>
												</div>
												<div className="">
													<div className="">
														<div id="PARAGRAPH_CUST_73c6ae92-bdaa-9034-b07a-c2a34f1eebbe" className="paragraph PARAGRAPH_CUST firstparagraph  ">
															<div className="clearfix doc-item">
																<div className="singlecolumn" id="FIELD_FRFM">
																	<p>Recreational Football League</p>
																	<ul>
																		<li>Team captain</li>
																		<li>Two-time league champions</li>
																	</ul>
																	<p>Red Cross Volunteer</p>
																	<ul>
																		<li>Taught classes in lifesaving skills including: CPR, First Aid</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id="CONTAINER_1" className="right-box last-box">
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-PICT">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }}></div>
										<div id="SECTION_PICT4214b310-3411-4e1d-862f-bc0494836200" className="section notdraggable SECTION_PICT firstsection   " data-section-cd="PICT">
											<div className=" doc-item">
												<div className="">
													<div className="">
														<div id="PARAGRAPH_PICT_e1ebf3dc-8c81-4b3f-a42e-d54881864bc9" className="paragraph PARAGRAPH_PICT firstparagraph placeholder-text ">
															<div className="clearfix doc-item">
																<div className="prflPic">
																	<div className="field" id="FIELD_PURL">
																		<img className="chk" src="https://www.livecareer.co.uk/blobcontent/luk/images/desktop/userPlaceholderPhoto_male.jpg" alt="Smiley face" /> <a href="javascript:;" className="btn btn-primary btn-prfl-edit" title="Edit Photo">Edit Photo</a></div>
																</div>
																<div className="clear"></div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-CNTC">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }} ></div>
										<div id="SECTION_CNTCd5629f30-a014-4b89-bb0a-cff09fe46a11" className="section SECTION_CNTC notdraggable    has-title " data-section-cd="CNTC">
											<div className=" doc-item">
												<div className="heading">
													<div className="sectiontitle" id="SECTIONNAME_CNTC">Contact<span title=" Contact " className="rename-section text-rename"></span></div>
												</div>
												<div className="">
													<div className="">
														<div id="PARAGRAPH_CNTC_35246e0e-286e-47c8-a5f7-8f73ddb714ef" className="paragraph PARAGRAPH_CNTC word-break firstparagraph  ">
															<div className="clearfix doc-item">
																<div className="address word-break">
																	<span className="paddedline adrsDetails zipsuffix" dependency="ADDR|STRT|NBHD|CITY|STAT|ZIPC">
																		<span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_ADDR">Address</span><span><span className="beforecolonspace"> </span><span>: </span></span></span><span id="FIELD_STRT"></span><span dependency="STRT" style={{ display: 'none' }}><span dependency="NBHD|CITY|STAT" style={{ display: 'none' }}>, </span></span>
																		<span id="FIELD_NBHD"></span><span dependency="NBHD" style={{ display: 'none' }} ><span dependency="CITY|STAT" style={{ display: 'none' }}>, </span></span>
																		<span id="FIELD_CITY"></span><span dependency="CITY+STAT" style={{ display: 'none' }}>, </span>
																		<span id="FIELD_STAT"></span>
																		<span id="FIELD_ZIPC"></span>
																		<span id="FIELD_ADDR">New Delhi,India,110034</span>
																	</span>
																	<span className="paddedline nonrnaphonefield" dependency="HPHN|CPHN">
																		<span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_HPHN">Phone</span><span><span className="beforecolonspace"> </span><span>: </span></span></span><span id="FIELD_HPHN"></span><span dependency="HPHN+CPHN" style={{ display: 'none' }}>, </span><span id="FIELD_CPHN">+91 11 5555 3345</span>
																	</span>
																	<span className="paddedline emailfld" dependency="EMAI">
																		<span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_EMAI">Email</span><span><span className="beforecolonspace"> </span><span>: </span></span></span><span id="FIELD_EMAI">d.agarwal@sample.in</span>
																	</span>
																</div>
																<div className="address userdetail word-break" dependency="DOB1|DRIV|NTLY|WPMT|IDNV|IDNT" style={{ display: 'none' }}>
																	<span dependency="DOB1" className="paddedline" style={{ display: 'none' }}>
																		<span className="txt-bold"><span id="DOCDATAINFO_DOB1" className="xslt_static_change">undefined</span><span className="beforecolonspace"> </span><span>: </span></span><span id="FIELD_DOB1"></span>
																	</span>
																	<span dependency="DRIV" className="paddedline" style={{ display: 'none' }}>
																		<span className="txt-bold"><span id="DOCDATAINFO_DRIV" className="xslt_static_change">Permit</span><span className="beforecolonspace"> </span><span>: </span></span><span id="FIELD_DRIV"></span>
																	</span>
																	<span dependency="NTLY" className="paddedline" style={{ display: 'none' }}>
																		<span className="txt-bold"><span id="DOCDATAINFO_NTLY" className="xslt_static_change">Nationality</span><span className="beforecolonspace"> </span><span>: </span></span><span id="FIELD_NTLY"></span>
																	</span>
																	<span dependency="WPMT" className="paddedline" style={{ display: 'none' }}>
																		<span className="txt-bold"><span id="DOCDATAINFO_WPMT" className="xslt_static_change">undefined</span><span className="beforecolonspace"> </span><span>: </span></span><span id="FIELD_WPMT"></span>
																	</span>
																	<div dependency="MSTA" style={{ display: 'none' }}>
																		<span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_MSTA"></span><span>: </span></span><span id="FIELD_MSTA"></span>
																	</div>
																	<span dependency="IDNV|IDNT" className="paddedline" style={{ display: 'none' }}>
																		<span className="txt-bold"><span id="FIELD_IDNT"></span><span dependency="IDNT+IDNV" style={{ display: 'none' }}><span className="beforecolonspace"> </span><span>: </span></span></span><span id="FIELD_IDNV"></span>
																	</span>
																	<span dependency="GNDR" className="paddedline" style={{ display: 'none' }}>
																		<span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_GNDR">Gender</span><span>: </span></span><span id="FIELD_GNDR"></span>
																	</span>
																	<span dependency="WEB1" className="paddedline word-break" style={{ display: 'none' }}>
																		<span className="txt-bold"><span className="xslt_static_change" id="DOCDATAINFO_WEB1">Web</span><span><span className="beforecolonspace"> </span><span>: </span></span></span> <span id="FIELD_WEB1"></span>
																	</span>
																</div>
																<div className="address" dependency="SOCL" style={{ display: 'none' }}>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-HILT">
										<div className="document-tool sec-tool" id="editIcons" style={{ right: '-37px' }}></div>
										<div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
											<div className=" doc-item">
												<div className="heading">
													<div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div>
												</div>
												<div className="">
													<div className="">
														<div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
															<div className="clearfix doc-item">
																<div className="singlecolumn maincolumn">
																	<span className="paddedline" id="FIELD_SKC1">
																		<ul>
																			<li>Cash register operation&nbsp;</li>
																			<li>POS system operation&nbsp;</li>
																			<li>Sales expertise&nbsp;</li>
																			<li>Teamwork</li>
																		</ul>
																	</span>
																	<span className="paddedline" id="FIELD_SKC2">
																		<ul>
																			<li>Inventory management&nbsp;</li>
																			<li>Accurate money handling&nbsp;</li>
																			<li>Documentation and recordkeeping&nbsp;</li>
																			<li>Retail merchandising expertise</li>
																		</ul>
																	</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div id="CONTAINER_PARENT_1">
								<div id="CONTAINER_2" className=""></div>
							</div>
						</div>
						<div></div>
					</div>
				</div>
				
			</li>
		</div>
	);
}
export default Temp1;