import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import logo from './Assets/gojobsai.png';
import Logobar from "./Logobar"



function PasswordChange() {
  useEffect(() => {
    // if (!userId) {
    //   navigate('/login');
    // }

    import('./Css/PasswordChange.css');
  }, []);

  return (
    <main>
        	<Logobar />
      <div>
       
        <div className="password_change">
          <div className="field">
            <span>Email-Id  &nbsp; &nbsp;   :</span>
          </div>
          <div className="field_1">
            <span>Password  &nbsp; &nbsp;   :</span>&nbsp;
            <FontAwesomeIcon icon={faEdit} />
          </div>
          <div className="field">
            <span>Enter New Password  &nbsp;  &nbsp;   :</span>&nbsp;
            <input type="password" />
          </div>
          <div className="field">
            <span>Confirm New Password &nbsp;   &nbsp;   :</span>&nbsp;
            <input type="password" />
          </div>
          <button className="save_btn">Save</button>
        </div>
      </div>
    </main>
  );
}

export default PasswordChange;
