import React, { Component, useState, useEffect } from "react";
import target from "../img/target.png";
import google_button from "../img/google_login.png";
import link_button from "../img/link_button.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import axios from "axios";
import logo from "../img/logo.png";
import MainNav from "./MainNav";
import {
  HashRouter as Router,
  Route,
  Link,
  Navigate,
  useNavigate,
  DefaultRoute,
  useLocation,
  useParams
} from "react-router-dom";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

function Login({ onSubmit }) {

  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [terms, setTerms] = useState(false);
  // const { email } = useParams();
  const query = useQuery();
  const email = query.get('email');
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  let param={
    email: '',
    password: ''
  };
  useEffect(() => {
    if(email && email != ''){
      param={
        email: email,
        password: '12qw!@QW'
      };
      autoLogin(param);
    }
    localStorage.removeItem('authToken');
    localStorage.removeItem('userId');
    localStorage.removeItem('common_data');
    localStorage.removeItem('resume_data');
    localStorage.removeItem('resume_id');
    localStorage.removeItem('temp_resume_id');
    localStorage.removeItem('parsed_json_data');
    localStorage.removeItem('eduindex');
    localStorage.removeItem('educationindex');
    localStorage.removeItem('tempexpindex');
    localStorage.removeItem('template');
    localStorage.removeItem('coverResumeId');
    localStorage.removeItem('cover_id');
    localStorage.removeItem('interviewResumeId');
    const params = new URLSearchParams(window.location.search);
    const myParamValue = params.get('verify');
    const param1 = params.get('userId');
    const param2 = params.get('email');
    const param3 = params.get('social');

    if (myParamValue !== null) {
      toast.success('Thanks for verifying your email id. Please log in to proceed.');
    }
    if (param3 !== null) {
      toast.error('This Email already registered manually.');
    }

    if (param1 !== null) {
      localStorage.setItem('userId', param1);
      localStorage.setItem('email', param2);
      // setTimeout(() => {
      navigate('/step14');
      // }, 1000);
    }
    import('./Css/Login.scss');
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
    // import('./Assets/CSS/bootstrap.min.css');


  }, []);

  

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.email == '') {
      toast.error('EmailAddress Required!!!');
    } else if (formData.password == '') {
      toast.error('Password Required!!!');
    } else {
      const functionThatReturnPromise = () => new Promise((resolve, reject) => {
        axios({
          method: "POST",
          url: commonConfig.REACT_APP_API_URL + "login",
          data: formData,
          headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

        }).then(res => {
          var decodedData = res.data;
          var status = res.status;
          if (status) {
            console.log("decodedData.token", decodedData.data.token);
            localStorage.setItem('authToken', decodedData.data.token);
            localStorage.setItem('userId', decodedData.data.id);
            localStorage.setItem('email', decodedData.data.email);
            const item = localStorage.getItem('choosedplan');
            if(item){
              navigate('/cart');
            }else{
              navigate('/step14');
            }
            resolve(status);
          }

        }).catch(err => {
          // console.log(err);
          reject(err);
        });
      });

      // toast.promise(
      // 	functionThatReturnPromise,
      // 	{
      // 		pending: 'Please Wait...',
      // 		success: 'Loggedin Successfully... 👌',
      // 		error: 'Failed 🤯'
      // 	},
      // )




      const pendingToast = toast('Please Wait...', { autoClose: false });

      try {
        await functionThatReturnPromise();
        // Dismiss the pending toast and show the success toast
        toast.update(pendingToast, {
          render: 'Loggedin Succcessfully...',
          type: toast.TYPE.SUCCESS,
          autoClose: 10000, // Set the duration in milliseconds (5 seconds)
        });
      } catch (error) {
        console.log('error',);
        if (error.response.data.message == 'Email') {
          const msg = 'Please verify your email id before logging in or send us email at support@gojobs.ai';
          toast.update(pendingToast, {
            render: msg,
            type: toast.TYPE.ERROR,
            autoClose: 15000, // Set the duration in milliseconds (5 seconds)
          });
        } else {
          const msg = 'Please login by using your login credentials or reset your password by using Forget Password if you do not remember it.';
          toast.update(pendingToast, {
            render: msg,
            type: toast.TYPE.ERROR,
            autoClose: 15000, // Set the duration in milliseconds (5 seconds)
          });
        }
        // Dismiss the pending toast and show the error toast

      }
    }
  };


  const autoLogin = async (param) => {
    const functionThatReturnPromise = () => new Promise((resolve, reject) => {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "moodlelogin",
        data: param,
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

      }).then(res => {
        var decodedData = res.data;
        var status = res.status;
        if (status) {
          localStorage.setItem('authToken', decodedData.data.token);
          localStorage.setItem('userId', decodedData.data.id);
          localStorage.setItem('email', decodedData.data.email);
          navigate('/step14');
          resolve(status);
        }

      }).catch(err => {
        reject(err);
      });
    });
    const pendingToast = toast('Please Wait...', { autoClose: false });

    try {
      await functionThatReturnPromise();
      toast.update(pendingToast, {
        render: 'Loggedin Succcessfully...',
        type: toast.TYPE.SUCCESS,
        autoClose: 10000, // Set the duration in milliseconds (5 seconds)
      });
    } catch (error) {
      console.log('error',);
      if (error.response.data.message == 'Email') {
        const msg = 'Please verify your email id before logging in or send us email at support@gojobs.ai';
        toast.update(pendingToast, {
          render: msg,
          type: toast.TYPE.ERROR,
          autoClose: 15000, // Set the duration in milliseconds (5 seconds)
        });
      } else {
        const msg = 'Please login by using your login credentials or reset your password by using Forget Password if you do not remember it.';
        toast.update(pendingToast, {
          render: msg,
          type: toast.TYPE.ERROR,
          autoClose: 15000, // Set the duration in milliseconds (5 seconds)
        });
      }
      // Dismiss the pending toast and show the error toast

    }
  };

  const handleGoogle = () => {
    setTerms(true);
  };
  const handleGoogleClose = () => {
    setTerms(false);
  };
  const handleSubmitGoogle = () => {
    setTerms(false);
    const url = commonConfig.REACT_APP_BACKEND_URL + 'auth/google';
    window.open(url);
  };


  return (
    <div className="containers loginpage">
      {page ? (
        <div>
          <MainNav />
          <ToastContainer position="top-center" theme="dark" />
          <div className="left-section"></div>
          <div className="right-section">
            <div className="right_1l loginpage">


              <h1><img src={logo} className="logo" alt="Centered Image" /></h1>
              <p className="subtitle"> <span className="clr_blue">Login to create your Targeted Resume</span> </p>
              <div className="container_inner ">
                <img onClick={handleGoogle} src={google_button} className="social_img" alt="Centered Image" />
                <p className="center">OR</p>
                <form action="" method="" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input type="email" name="email" className="formcontrol" value={formData.email} onChange={handleChange} />
                    <label className="formlabel" htmlFor="f_name">Email address</label>
                  </div>
                  <div className="form-group">
                    <input type="password" name="password" value={formData.password} onChange={handleChange} />
                    <label className="formlabel" htmlFor="f_name">Password</label>
                  </div>
                  <button type="submit" className="btn">SIGN IN</button>

                </form>
                {/* <button onClick={this.props.notify}>Notify!</button> */}
              </div>

              <div className="social center hide">
                <img onClick={handleGoogle} src={google_button} className="social_img" alt="Centered Image" />
                {/* <img src={link_button} className="social_img" alt="Centered Image" /> */}
              </div>

              {terms &&<div className="popup-overlay">
                <div className="popup-container">
                  <p style={{ marginTop: '20px', fontWeight: '500', textAlign: 'center' }} >
                    <span className="blue"> I have read and accept the <Link className="bold" to="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions.</Link> and the <Link className="bold" to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link> of GOjobs.ai </span>
                  </p>
                  <button id="btnBack" onClick={handleGoogleClose} type="button" className="btn-stepsback" style={{ marginTop: '3%' }} >Back</button>
                  <button id="btnBack" onClick={handleSubmitGoogle} className="btn-mangaii" style={{ marginTop: '3%' }} >Agree</button>
                </div>
              </div>}


              <div className="login_extra center-start-end">
                <Link to="/register"><div className="clr_blue bold">New User Sign Up</div></Link>
                <Link to="/forgotpassword"><div className="clr_blue bold">Forgot Password</div></Link>
              </div>
            </div>
          </div>
          <img src={target} className="center-image" alt="Centered Image" />
        </div>
      ) : (
        <Loading content="Please Wait!!!" />
      )}
    </div>
  );
}

export default Login;
