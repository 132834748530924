
import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import logo from "../img/logo.png";
import { BrowserRouter as Router, Route, Link,useLocation } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload, faBars,faShoppingCart   } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { commonConfig } from '../config.js';
function CartNav(props) {
  const [scrolled, setScrolled] = useState(false);

  const ip = localStorage.getItem('ip');
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {

    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "get-ip",
      data: { page: currentPath },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data.ip_address;
      localStorage.setItem('ip',decodedData);
    }).catch(err => {

    });

    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

    

  }, []);
  let headerStyle = '';
  if (props.value == 1) {
    headerStyle = {
      backgroundColor: scrolled ? '#FFFFFF' : 'transparent',
      color: scrolled ? '#000000' : '#ffffff'
    };
  } else {
    headerStyle = {
      backgroundColor: scrolled ? '#FFFFFF' : 'transparent',
      color: scrolled ? '#000000' : '#000000'
    };
  }

  return (
    <div>
      <header id="header" style={headerStyle} className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center">

          <div className="logo me-auto">
            <h1><Link to="/home"><img src={logo} className="logo" alt="Centered Image" /></Link></h1>
          </div>
          <nav id="navbar" className="navbar order-last order-lg-0">
            <ul class="menu">
              <li><Link className="nav-link scrollto myaccount myacc" to="/step14">My Account</Link></li>
              <li><Link className="nav-link scrollto myaccount" to="/step14"><FontAwesomeIcon  icon={faShoppingCart} /></Link></li>
              {/* <li></li> */}
            </ul>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default CartNav;


