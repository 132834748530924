import React, { Component } from "react";
import "./Temp7.css";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
function Temp7() {
    return (
        <div>

            <li class="template-preview selected" data-skinid="MLD9" data-skincd="MLD9" title=""><div class="selected-badge"><div class="fal fa-check icon-badge"></div></div><button type="button" class="btn btn-link preview-btn" aria-label="MLD9 skin preview  selected"></button><div class="render-preview"><div class="" tabindex=""><div></div><div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mld7 MLD9  RNA  " docskinwidth="562"><div id="CONTAINER_PARENT_0" class="parent-container"><div id="CONTAINER_0" class="left-box"><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-CNTC"><div class="document-tool sec-tool" id="editIcons" style={{ right: '-2px' }}></div><div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section cntc-sec notdraggable SECTION_CNTC     " data-section-cd="CNTC"><div class=" doc-item"><div class=""><div class=""><div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  "><div class="clearfix doc-item">
                <div class="address">
                    <ul>
                        <li dependency="EMAI">
                            <span class="mail" id="FIELD_EMAI">d.agarwal@sample.in</span>
                        </li>
                        <li dependency="CPHN">
                            <span class="phone" id="FIELD_CPHN">+91 11 5555 3345</span>
                        </li>
                        <li dependency="ADDR|STRT|CITY|STAT|ZIPC|CNTY">
                            <span id="FIELD_STRT"></span><span dependency="STRT" style={{ display: 'none' }}><span dependency="CITY|STAT|CNTY|ZIPC" style={{ display: 'none' }}>, </span></span>
                            <span id="FIELD_CITY"></span><span dependency="CITY" style={{ display: 'none' }}><span dependency="STAT|CNTY|ZIPC" style={{ display: 'none' }}>, </span></span>
                            <span id="FIELD_STAT"></span><span dependency="STAT+CNTY" style={{ display: 'none' }}>, </span>
                            <span class="spaced" id="FIELD_CNTY"></span><span dependency="ZIPC+STAT|CNTY" style={{ display: 'none' }}> </span>
                            <span id="FIELD_ZIPC"></span>
                            <span id="FIELD_ADDR">New Delhi, India 110034</span>
                        </li>
                        <li dependency="DOB1" style={{ display: 'none' }}>
                            <span class="txt-bold"><span class="xslt_static_change" id="DOCDATAINFO_DOB1">undefined</span><span>: </span></span><span id="FIELD_DOB1" class="txt-normal"></span>
                        </li>
                        <li dependency="DRIV" style={{ display: 'none' }}>
                            <span class="txt-bold"><span class="xslt_static_change" id="DOCDATAINFO_DRIV">undefined</span><span>: </span></span><span id="FIELD_DRIV" class="txt-normal"></span>
                        </li>
                        <li dependency="WEB1" style={{ display: 'none' }}>
                            <span class="txt-normal" id="FIELD_WEB1"></span>
                        </li>

                    </ul>
                </div>
            </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-HILT"><div class="document-tool sec-tool" id="editIcons" style={{ right: '-2px' }}></div><div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div></div><div class=""><div class=""><div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  "><div class="clearfix doc-item">
                <div class="singlecolumn maincolumn">
                    <div class="skill">
                        <span class="paddedline disp-blk" id="FIELD_SKC1"><ul>
                            <li>Cash register operation&nbsp;</li>
                            <li>POS system operation&nbsp;</li>
                            <li>Sales expertise&nbsp;</li>
                            <li>Teamwork</li>
                        </ul></span>
                        <span class="paddedline" id="FIELD_SKC2"><ul>
                            <li>Inventory management&nbsp;</li>
                            <li>Accurate money handling&nbsp;</li>
                            <li>Documentation and recordkeeping&nbsp;</li>
                            <li>Retail merchandising expertise</li>
                        </ul></span>
                    </div>
                </div>
            </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-EDUC"><div class="document-tool sec-tool" id="editIcons" style={{ right: '-2px' }}></div><div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_EDUC">Education and Training<span title=" Education and Training " class="rename-section text-rename"></span></div></div><div class=""><div class=""><div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  "><div class="clearfix doc-item">
                <div class="singlecolumn">
                    <span class="disp-blk txt-italic" dependency="GRYR">
                        <span class="xslt_static_change displayNoneThisField">Expected in </span><span id="FIELD_GRYR" format="%m/%Y">2016</span>
                    </span>
                    <span class="disp-blk" dependency="DGRE|STUY">
                        <span class="degree txt-bold txt-capitalize" id="FIELD_DGRE">Diploma in Financial Accounting</span><span dependency="DGRE"><span dependency="SCHO|SCIT|SSTA|SCNT">: </span></span><span class="programline disp-blk" id="FIELD_STUY"></span>
                    </span>
                    <span class="disp-blk" dependency="SCHO|SCIT|SSTA|SCNT">
                        <span class="company-name txt-bold  txt-capitalize disp-blk" id="FIELD_SCHO">Oxford Software Institute &amp; Oxford School of English</span>
                        <span class="joblocation jobcity" id="FIELD_SCIT"> New Delhi, India</span><span dependency="SCIT+SSTA|SCNT" style={{ display: 'none' }}>, </span>
                        <span class="joblocation jobstate" id="FIELD_SSTA"></span><span dependency="SSTA+SCNT" style={{ display: 'none' }}>, </span>
                        <span class="joblocation jobcountry" id="FIELD_SCNT"></span>
                    </span>
                    <span class="disp-blk" dependency="GRPA" style={{ display: 'none' }}>
                        <span>GPA: </span><span id="FIELD_GRPA"></span>
                    </span>
                    <span class="jobline" id="FIELD_FRFM"></span>
                </div>
            </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-LNGG"><div class="document-tool sec-tool" id="editIcons" style={{ right: '-2px' }}></div><div id="SECTION_LNGG36369f28-1717-c0d3-39a5-96f0b2c27702" class="section lang-sec infobarsec SECTION_LNGG    has-title  " data-section-cd="LNGG"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_LNGG">Languages<span title=" Languages " class="rename-section text-rename"></span></div></div><div class=""><div class="sortableInner"><div id="PARAGRAPH_LNGG_20023c30-89ef-3a99-3f68-d7715a46b8f2" class="paragraph PARAGRAPH_LNGG firstparagraph  nativeLangPara"><div class="clearfix doc-item">
                <div class="singlecolumn infobarpara">
                    <div class="field">
                        <span class="txt-bold" id="FIELD_FRFM">Hindi</span><span class="colon" dependency="FRFM">:</span>
                        <span class="flt-right" id="FIELD_RATG"></span>
                    </div>
                    <div class="rating-bar" dependency="RATV" style={{ display: 'none' }}>
                        <div class="inner-rating" id="FIELD_RATV" type="width" style={{ width: '0%' }}></div>
                    </div>
                    <div class="field field-ratt">
                        <span id="FIELD_RATT">Native speaker</span>
                    </div>
                </div>

            </div></div><div id="PARAGRAPH_LNGG_756f3fd5-3415-d225-25f6-26433cdf5859" class="paragraph PARAGRAPH_LNGG  para_odd"><div class="clearfix doc-item">
                <div class="singlecolumn infobarpara">
                    <div class="field">
                        <span class="txt-bold" id="FIELD_FRFM">English</span><span class="colon" dependency="FRFM">:</span>
                        <span class="flt-right" id="FIELD_RATG">C2</span>
                    </div>
                    <div class="rating-bar" dependency="RATV">
                        <div class="inner-rating" id="FIELD_RATV" type="width" style={{ width: '100%' }}></div>
                    </div>
                    <div class="field field-ratt">
                        <span id="FIELD_RATT">Proficient</span>
                    </div>
                </div>

            </div></div><div id="PARAGRAPH_LNGG_5cf93e39-188c-5232-51b9-f59f27dbcb4d" class="paragraph PARAGRAPH_LNGG  para_even"><div class="clearfix doc-item">
                <div class="singlecolumn infobarpara">
                    <div class="field">
                        <span class="txt-bold" id="FIELD_FRFM">Bengali</span><span class="colon" dependency="FRFM">:</span>
                        <span class="flt-right" id="FIELD_RATG">B2</span>
                    </div>
                    <div class="rating-bar" dependency="RATV">
                        <div class="inner-rating" id="FIELD_RATV" type="width" style={{ width: '66.6667%' }}></div>
                    </div>
                    <div class="field field-ratt">
                        <span id="FIELD_RATT">Upper-intermediate</span>
                    </div>
                </div>

            </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-CUST"><div class="document-tool sec-tool" id="editIcons"></div><div id="SECTION_CUST2d02e9c6-8ba9-ebbc-7361-30aa87657066" class="section SECTION_CUST    has-title  " data-section-cd="CUST"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_CUST">Interests and Hobbies<span title=" Interests and Hobbies " class="rename-section text-rename"></span></div></div><div class=""><div class=""><div id="PARAGRAPH_CUST_df290a2c-9021-8893-18cf-6e0b270064f0" class="paragraph PARAGRAPH_CUST firstparagraph  "><div class="clearfix doc-item">
                <div class="singlecolumn" id="FIELD_FRFM"><p>Recreational Football League</p><ul><li>Team captain</li><li>Two-time league champions</li></ul><p>Red Cross Volunteer</p><ul><li>Taught classes in lifesaving skills including: CPR, First Aid</li></ul></div>
            </div></div></div></div></div></div></div></div><div id="CONTAINER_1" class="right-box"><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-NAME"><div class="document-tool sec-tool" id="editIcons" style={{ right: '-2px' }}></div><div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section name-sec notdraggable SECTION_NAME     " data-section-cd="NAME"><div class=" doc-item"><div class=""><div class=""><div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  "><div>

                <div class="name">
                    <span id="FIELD_FNAM">Diya</span>
                    <span id="FIELD_LNAM">Agarwal</span>
                </div>
                <div class="resume-title" dependency="DCTL" style={{ display: 'none' }}><span id="FIELD_DCTL"> </span></div>
            </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-SUMM"><div class="document-tool sec-tool" id="editIcons" style={{ right: '-2px' }}></div><div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div></div><div class=""><div class=""><div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  "><div class="clearfix doc-item">
                <div class="singlecolumn" id="FIELD_FRFM"><p>Customer-focused Retail Sales professional with solid understanding of retail dynamics, marketing and customer service. Offering 5 years of experience providing quality product recommendations and solutions to meet customer needs and exceed expectations. Demonstrated record of exceeding revenue targets by leveraging communication skills and sales expertise.</p></div>
            </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-EXPR"><div class="document-tool sec-tool" id="editIcons" style={{ right: '-2px' }}></div><div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section expr-sec SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div></div><div class=""><div class="sortableInner"><div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  "><div class="clearfix doc-item">
                <div class="singlecolumn">
                    <span class="disp-blk txt-bold" dependency="COMP|JTIT">
                        <span class="company-name txt-capitalize" id="FIELD_COMP">ZARA</span><span dependency="COMP+JTIT"> - </span>
                        <span class="job-title txt-capitalize" id="FIELD_JTIT">Retail Sales Associate</span>
                    </span>
                    <span class="disp-blk txt-italic" dependency="JCIT|JSTA|JCNT|JSTD|EDDT">
                        <span id="FIELD_JCIT">New Delhi</span><span dependency="JCIT+JSTA|JCNT">, </span>
                        <span id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span>
                        <span id="FIELD_JCNT"></span>
                        <span class="disp-blk" dependency="JSTD|EDDT">
                            <span id="FIELD_JSTD" format="%m/%Y">02/2017</span><span dependency="JSTD+EDDT"> - </span><span id="FIELD_EDDT" format="%m/%Y">Current</span>
                        </span>
                    </span>
                    <span class="jobline" id="FIELD_JDES"><ul>
                        <li>Increased monthly sales 10% by effectively upselling and cross-selling products to maximize profitability.&nbsp;</li>
                        <li>Prevented store losses by leveraging awareness, attention to detail, and integrity to identify and investigate concerns.&nbsp;</li>
                        <li>Processed payments and maintained accurate drawers to meet financial targets.</li>
                    </ul></span>
                </div>
            </div></div><div id="PARAGRAPH_EXPR_d7533686-f260-ba36-1ef4-823e4a95f9c8" class="paragraph PARAGRAPH_EXPR  "><div class="clearfix doc-item">
                <div class="singlecolumn">
                    <span class="disp-blk txt-bold" dependency="COMP|JTIT">
                        <span class="company-name txt-capitalize" id="FIELD_COMP">Dunkin’ Donuts</span><span dependency="COMP+JTIT"> - </span>
                        <span class="job-title txt-capitalize" id="FIELD_JTIT">Barista</span>
                    </span>
                    <span class="disp-blk txt-italic" dependency="JCIT|JSTA|JCNT|JSTD|EDDT">
                        <span id="FIELD_JCIT">New Delhi</span><span dependency="JCIT+JSTA|JCNT">, </span>
                        <span id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span>
                        <span id="FIELD_JCNT"></span>
                        <span class="disp-blk" dependency="JSTD|EDDT">
                            <span id="FIELD_JSTD" format="%m/%Y">03/2015</span><span dependency="JSTD+EDDT"> - </span><span id="FIELD_EDDT" format="%m/%Y">01/2017</span>
                        </span>
                    </span>
                    <span class="jobline" id="FIELD_JDES"><ul>
                        <li>Upsold seasonal drinks and pastries, boosting average store sales by ₹1500 weekly.&nbsp;</li>
                        <li>Managed morning rush of over 300 customers daily with efficient, levelheaded customer service.&nbsp;</li>
                        <li>Trained entire staff of 15 baristas in new smoothie program offerings and procedures.</li>
                    </ul></span>
                </div>
            </div></div><div id="PARAGRAPH_EXPR_a04e74cd-6823-5362-e7e4-e2b92fcd0cb7" class="paragraph PARAGRAPH_EXPR  "><div class="clearfix doc-item">
                <div class="singlecolumn">
                    <span class="disp-blk txt-bold" dependency="COMP|JTIT">
                        <span class="company-name txt-capitalize" id="FIELD_COMP">McDonald's</span><span dependency="COMP+JTIT"> - </span>
                        <span class="job-title txt-capitalize" id="FIELD_JTIT">Cashier</span>
                    </span>
                    <span class="disp-blk txt-italic" dependency="JCIT|JSTA|JCNT|JSTD|EDDT">
                        <span id="FIELD_JCIT">New Delhi</span><span dependency="JCIT+JSTA|JCNT">, </span>
                        <span id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span>
                        <span id="FIELD_JCNT"></span>
                        <span class="disp-blk" dependency="JSTD|EDDT">
                            <span id="FIELD_JSTD" format="%m/%Y">01/2014</span><span dependency="JSTD+EDDT"> - </span><span id="FIELD_EDDT" format="%m/%Y">02/2015</span>
                        </span>
                    </span>
                    <span class="jobline" id="FIELD_JDES"><ul>
                        <li>Processed all sales transactions accurately and promptly to prevent long customer wait times.&nbsp;</li>
                        <li>Entered requested menu items into POS terminal, modifying with substitutions and add-ons to customize orders.&nbsp;</li>
                        <li>Accurately made change for cash transactions.</li>
                        <li>Verified orders and bagged items for easy transport.&nbsp;</li>
                        <li>Completed opening, closing and shift change tasks to promote store efficiency.&nbsp;</li>
                        <li>Monitored and counted food stock and supplies to reorder on time.&nbsp;</li>
                        <li>Kept customer and food preparation areas clean and well organized for maximum efficiency.&nbsp;</li>
                    </ul></span>
                </div>
            </div></div></div></div></div></div></div></div></div></div><div></div></div></div><div class="template-overlay"><button id="previewTemplate" type="button" class="btn btn-tertiary" data-index="0">Preview Template</button> <button id="btnSelectTemplate" type="button" class="btn btn-primary  ">Select Template</button></div></li>
        </div>


    );
}
export default Temp7;