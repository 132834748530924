import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contact_icon from './Assets/icons/contact.png';
import mail_icon from './Assets/icons/mail.png';
import location_icon from './Assets/icons/location.png';
import { saveAs } from 'file-saver'; // for downloading the file
import htmlDocx from 'html-docx-js/dist/html-docx';
import jsPDF from 'jspdf';

const Dynamictemp2 = forwardRef((props, ref) => {
  const contentEditableRef = useRef(null);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);
  let resume_id = localStorage.getItem('resume_id');

  const CommonData = localStorage.getItem('common_data');
  let pack = JSON.parse(CommonData).plan.pack;
  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  const a = ["PHP", "Javascipt", "HTML", "CSS", "Bootstap", "ionic", "Angular", "react", "Laravel", "Codeigniter", "Wordpress"];
  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);
      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);




  const printDocument = (title, fontsize, spacing, color, resumename, justify) => {
    // const element = document.getElementById('pdfContainer');

    // const data = {
    //   htmlContent: htmlContent,
    //   resume_id: resume_id,
    //   resumename: resumename,
    //   fontsize: fontsize,
    //   spacing: spacing,
    //   color: color,
    //   justify: justify,
    //   template: 2
    // };
    // console.log('htmlContent', htmlContent);
    // axios({
    //   method: "POST",
    //   url: commonConfig.REACT_APP_API_URL + "generate-pdf",
    //   data: data,
    //   headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    // }).then(res => {
    //   if (title == "save") {

    //     toast.success("Saved Successfully!!!");
    //   }

    // }).catch(err => {

    // });
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(divRef.current, {
      callback: function (pdf) {
        pdf.save('download.pdf');
      },
      x: 20,          // Set the x offset for margin
      y: 20,          // Set the y offset for margin
      margin: 10,     // Apply a margin to all sides
      width: 550,     // Adjust content width based on A4 width and desired margins
      windowWidth: 900, // Control scaling based on browser window width
    });
  };

  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print,
    downloadWordDoc: downloadWordDoc,
  }));
  const downloadWordDoc = () => {

    const cssStyles = `
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:wght@300&family=Poppins:wght@200;300&family=Roboto:wght@400&display=swap');
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{
    margin:0;
    padding:0;
    border:0;
    outline:0;
    vertical-align:baseline;
    background:transparent;
}

body{
    line-height:1;
    text-align:left;
    font-feature-settings:"liga" 0;
    -moz-font-feature-settings:"liga" off;
}

          table{border-collapse:collapse;border-spacing:0;font-size:inherit;color:inherit;width:100%}

        /*START content disc style for LI*/
          ul,  li{list-style:none;margin:0;padding:0}
          ul li{position:relative;margin:0px;padding-left:10px}
          ul li:before{font-size:9px;position:absolute;left:0;top:0}
        /*END content disc style for LI*/

        /*Helping classes*/
          .txt-bold{font-weight:bold}
          .txtItl{font-style:italic}
          .dispBlock{display:block}
          .flt-right{float:right}

          .ttc-align-left ul{text-align:left}
          .ttc-align-right ul{text-align:right}
          .ttc-align-center ul{text-align:center}
          .ttc-align-justify ul{text-align:justify}
          .ttc-align-right li:before,  .ttc-align-center li:before{position:relative;left:-4px}

        /*Document*/
         .MUK{text-size-adjust:none; -ms-text-size-adjust: none;-moz-text-size-adjust: none;-webkit-text-size-adjust: none}
         {color:#020303;line-height:14px;table-layout:fixed;box-sizing:content-box;word-wrap:break-word;word-break:break-word;min-height:792px}
          .name{color:#576d7b;font-weight:bold;padding:5px 0 4px 0;text-align:left;text-transform:uppercase;position:relative}
          .section.SECTION_CNTC{float:left;margin-top:0}
          .resumeTitle{color:#0e0e0e;text-transform:uppercase;letter-spacing:.86px}
          .parentContainer{clear:both}
          .parentContainer .container{display:table-cell;padding-top:25px;padding-bottom:25px}
          .parentContainer .section{border-top:1px solid #576d7b;padding-top:4px;position:relative;width:100%;}
          .parentContainer .section:after{content:'';display:table;clear:both}
          .singlecolumn,  .sectiontitle{word-wrap:break-word}
          .parentContainer .paragraph:not(.firstparagraph) .singlecolumn{padding-top:3px;border-top:1px dashed #000}
          .SECTION_CNTC,  .topsection,  .parentContainer{width:100%;display:table;table-layout:fixed;}

        /*Common left-right container*/
          .left-box{position:relative;float:left}
          .right-box .section:first-child{padding-top:0;margin-top:0;margin-bottom:16px}
          .left-box .section:first-child{padding-top:20px;margin-top:0}

          .skill{display:table;width:100%;table-layout:fixed}
          .skill .paddedline1{display:table-cell;width:50%}
          .skill .paddedline:last-child{padding-left:10px}

        /*Top section*/
          .topsection{position:relative;border-top:25px solid #576d7b}
          .section:empty{display:none}
          .parentContainer .section:first-child{margin-top:0}

        /*PICT*/
          .PICTPic,  .PICTPic .field{display:table-cell;vertical-align:middle;text-align:center}
          .PICTPic img{width:100%;object-fit: contain;}

        /*Address and ALNK*/
          .adnlLnks ul li{padding:0;margin:0}
          .adnlLnks ul li:before{content:''}

        /*SVG Icon Style*/
          .iconRow{clear:both;margin-bottom:8px;word-wrap:break-word;display:table;table-layout:fixed;width:100%;}
          .iconRow .iconSvg{width:10px;display:table-cell;vertical-align:middle}
          .iconRow .iconSvg svg{display:block;}
          .iconRow .icoTxt{padding-left:6px;vertical-align: middle;display:table-cell}
          .iconRow svg path.rect,  .topshape{fill:#576d7b}
          .topshape{position:relative;top:-1px;vertical-align:top}
          .square{height: 10px;width: 10px;background-color: #576d7b;}
          .svg-inricon{fill: #fff;stroke: #fff;transform: scale(1.1);transform-origin:16px 14px}
          .svg-fillsqrbg{fill:#576d7b}

        /*Heading*/
          .heading{float:left;left:0;position:relative}
          .heading:before{content:'';position:absolute;left:0;top:-8px;border-top:3px solid #576d7b;background:#576d7b}
          .sectiontitle{color:#000;letter-spacing:.1px;text-transform:uppercase;font-weight:bold}
          .jobtitle{text-transform:uppercase}

        /*Signature*/
          .sign .field_sign{font-size:7px;color:#8a8a8a}
          .txtleft + .field_sign{text-align:left}
          .txtcenter + .field_sign{text-align:center}
          .txtright + .field_sign{text-align:right}
          .signPic span:first-child{padding-right:6px}
          .signPic img{max-width:100%}
          .sign.section{padding-top:0;margin-top:25px}

        /*New logic for infographic*/
          .lang-sec .singlecolumn,  .skli-sec .singlecolumn,  .fielditem-olsh{display:none}
          .lang-sec.infobarsec .infobarpara,  .lang-sec.infotilesec .infotilepara,
          .skli-sec.infobarsec .infobarpara,  .skli-sec.infotilesec .infotilepara,  .nativeLangPara .fielditem-olsh{display:block}
          .hide-bar .field-ratt{display:none}

        /*Infographic*/
          .lang-sec.infobarsec,  .skli-sec.infobarsec{font-size:0}
          .lang-sec.infobarsec .field *,  .lang-sec.infobarsec .nativeLangPara .field,
          .skli-sec.infobarsec .field *{display:inline}
          .lang-sec.infobarsec .paragraph,  .skli-sec.infobarsec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
          .lang-sec.infobarsec .singlecolumn,  .skli-sec.infobarsec .singlecolumn{margin-left:0;padding-left:0;position:relative}
          .lang-sec.infobarsec .para_odd,  .skli-sec.infobarsec .para_odd{margin-right:15px}
          .lang-sec.infobarsec .nativeLangPara{width:100%}
          .lang-sec.infobarsec .rating-bar,  .skli-sec.infobarsec .rating-bar{background:#d5d6d6;width:100%;clear:both;margin-top:3px;overflow:hidden}
          .lang-sec.infobarsec .inner-rating,  .skli-sec.infobarsec .inner-rating{position:relative;background-color:#000;height:4px;width:60%}
          .lang-sec.infobarsec > .paragraph:nth-last-child(1),  .lang-sec.infobarsec > .paragraph:nth-last-child(2),  .skli-sec.infobarsec > .paragraph:nth-last-child(1),  .skli-sec.infobarsec > .paragraph:nth-last-child(2){padding-bottom:0!important}
          .lang-sec .paragraph .singlecolumn,  .skli-sec .paragraph .singlecolumn{border:none!important;padding:0px!important}

          .skli-sec .paragraph:nth-last-child(1) .singlecolumn .field:last-child,  .skli-sec .paragraph:nth-last-child(2) .singlecolumn .field:last-child{min-height:0}

        /*Infographic Tiles*/
          .lang-sec.infotilesec,  .skli-sec{font-size:0}
          .lang-sec.infotilesec .paragraph,  .skli-sec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
          .lang-sec.infotilesec > .paragraph:nth-last-child(1),  .lang-sec.infotilesec > .paragraph:nth-last-child(2),  .skli-sec > .paragraph:nth-last-child(1),  .skli-sec > .paragraph:nth-last-child(2){padding-bottom:0!important}
          .lang-sec.infotilesec .field *,  .skli-sec .field *,  .lang-sec.infotilesec .nativeLangPara .field{display:inline}
          .lang-sec.infotilesec .singlecolumn,  .skli-sec .paragraph .singlecolumn{margin-left:0}
          .lang-sec.infotilesec .sliced-rect,  .skli-sec .sliced-rect{height:6px;width:100%;line-height:0px;margin-top:3px;clear:both}
          .lang-sec.infotilesec .paragraph.para_odd,  .skli-sec .paragraph.para_odd{margin-right:15px}
          .lang-sec.infotilesec .sliced-rect .sliced-rect-tile,  .skli-sec .sliced-rect .sliced-rect-tile{height:100%;background-color:#d5d6d6;float:left;margin-right:3px}
          .lang-sec.infotilesec .sliced-rect .sliced-rect-tile:last-child,  .skli-sec .sliced-rect .sliced-rect-tile:last-child{margin-right:0}

        /*Rectangular Rating Blocks*/
          .sliced-rect .sliced-rect-tile.ratvfill{background-color:#4a4a4a}
          .hide-bar .paragraph .rating-bar,  .hide-bar .paragraph .sliced-rect,  .hide-only-bar .rating-bar,  .hide-colon .paragraph .colon{display:none !important}

         
        /*Infographic Languages Ordering Support*/
          .lang-sec.infobarsec .colon,  .lang-sec.infotilesec .colon{display:none}
          .lang-sec.infobarsec .field:first-child .colon,  .lang-sec.infotilesec .field:first-child .colon{display:inline;font-weight:bold}
          .lang-sec.infobarsec .field:first-child > span:first-child,    .lang-sec.infotilesec .field:first-child > span:first-child{font-weight:bold}
        
        /*MFR address order code*/
          .iconRow .icoTxt.zipsuffix{display:block}
          .iconRow .icoTxt.zipprefix, .MFR .iconRow .icoTxt.zipsuffix, .MES .iconRow .icoTxt.zipsuffix{display:none}
         .MFR .iconRow .icoTxt.zipprefix, .MES .iconRow .icoTxt.zipprefix{display:block}
          .address{display:table;table-layout:fixed;width:100%}
          .address-inner{display:table-cell;width:50%;vertical-align:top}
          .address-inner:first-child{padding-right:10px}

        /* PPDT */
          .disclaim .field.singlecolumn{margin-left:0}
          .disclaim .field.singlecolumn,  .disclaim .field.singlecolumn li,  .disclaim .field.singlecolumn p,  .disclaim .field.singlecolumn span{font-size:9px;color:#8a8a8a}
          .section.disclaim .heading:before,  .parentContainer .section.disclaim,  .parentContainer .section.sign{border:none}

        /*For show/hide HPHN field based on portal*/
          .rnaphonefield, .RNA .nonrnaphonefield, .CLN .nonrnaphonefield{display:none}
          .nonrnaphonefield, .RNA .rnaphonefield, .CLN .rnaphonefield{display:block}

        /* GRYR */
          .displayNoneThisField{display:none}/* Keep this class always at bottom */

        /*For Extra Space Before Colon*/
          .beforecolonspace{display:none!important}
         .MFR .beforecolonspace{display:inline!important}
    
    /*HILT multi para/section*/
          .multi-para-hilt:after{content: "";display:block;clear:both;visibility:hidden;line-height:0;height:0} /*Clearfix*/
          .multi-para-hilt .paragraph{margin-bottom:20px;margin-top:0;width:49%;float:left}
          .multi-para-hilt .paragraph:last-child,  .multi-para-hilt .paragraph:nth-last-child(2):nth-child(2n){margin-bottom:0}
          .multi-para-hilt .paragraph:nth-child(2n+1){margin-left:2%}
          .parentContainer .multi-para-hilt .singlecolumn,  .multi-para-hilt .paragraph.firstparagraph{margin:0}
          .multi-section-hilt .multi-para-opt,  .section:not(.multi-para-hilt):not(.multi-section-hilt) .multi-para-opt,  .multi-para-hilt .twocol.skill{display:none}
      .multi-para-hilt .paragraph:nth-child(3) .singlecolumn{border-top:0;padding-top:0}
    
    

         ,  table{line-height:14px}
         .pagesize{width:535px}
         .fontsize,  .lang-sec.infobarsec .paragraph *,  .lang-sec.infotilesec .paragraph *,  .skli-sec .paragraph *{font-size: var(--font-size);
          line-height: var(--line-height);}
         .fontface{font-family:var(--font-family), sans-serif;}
         .hmargins{padding-left:5px;padding-right:5px}
          .section{margin-top:25px}
          .paragraph.firstparagraph{margin-top:0}
          .paragraph{margin-top:20px}
          .parentContainer .singlecolumn,  .parentContainer .maincolumn{margin-left:117px}
    
          .name{font-size:26px;line-height:34px;color:var(--theme-color)}
          .resumeTitle{font-size:12px;line-height:20px}
          .heading,  .heading:before{width:100px}
          .sectiontitle{font-size:13px;line-height:12px}
          .iconRow{line-height:1.5}
          .left-box{width:117px}
          .right-box{margin-left:117px}
      .skli-sec .singlecolumn .field:last-child{min-height:12px}

          .parentContainer .section,  .heading:before{border-color:var(--theme-color)}
          .heading:before{background:var(--theme-color)}
          .svg{color:var(--theme-color)}
          .topshape{fill:var(--theme-color);top:-1px}

        /*Algorithm Width*/
          .PICTPic,  .PICTPic .field{max-width:100px}
          .topsection{border-top:25px solid var(--theme-color);padding-bottom: 10px;}
    
        /*Icons*/
          .iconRow svg path.rect{fill:var(--theme-color)}
          .iconRow svg .svg-fillsqrbg,  .iconRow .new-flds svg path,  .iconRow svg .svg-inriconobj{fill:var(--theme-color)}
          .iconRow .iconSvg{height:10px;width:10px}
          .iconRow .iconSvg svg{height:10px;width:10px}
          .square{height:10px;width:10px;background-color:var(--theme-color)}

        /*Infographic Bar*/
          .lang-sec.infobarsec,  .skli-sec.infobarsec{padding-left:117px;box-sizing:border-box}
          .lang-sec.infobarsec .heading,  .skli-sec.infobarsec .heading,  .multi-para-hilt .heading{margin-left:-117px}
          .lang-sec.infobarsec .inner-rating,  .skli-sec.infobarsec .inner-rating{background-color:var(--theme-color)}
          .lang-sec.infobarsec .paragraph,  .skli-sec.infobarsec .paragraph{width:197px}
          .lang-sec.infobarsec .nativeLangPara{width:413px;max-width:413px}

          .fielditem-olsh{padding-top:5px;margin-bottom:-3px}

        /*Infographic Tiles*/
          .lang-sec.infotilesec,  .skli-sec,  .multi-para-hilt{padding-left:117px;box-sizing:border-box}
          .lang-sec.infotilesec .heading,  .skli-sec .heading{margin-left:-117px}
          .lang-sec.infotilesec .paragraph,  .skli-sec .paragraph{width:197px}
          .lang-sec.infotilesec .nativeLangPara{width:413px;max-width:413px}

        /*Rectangular Rating Blocks*/
          .paragraph .sliced-rect .sliced-rect-tile.ratvfill{background-color:var(--theme-color)}
   
        /*Finalize Fixes*/
          .sortable-item i.far.fa-check{font-family:"Font Awesome 5 Pro"}
          .lang-sec .sortable-item .para_odd,  .lang-sec .sortable-item .para_even{max-width:197px}
          .lang-sec .sortableInner .sortable-item:not(:first-child) .paragraph,  .skli-sec .sortableInner .sortable-item:not(:first-child) .paragraph{width:197px;vertical-align:top}
          .lang-sec .sortable-item .nativeLangPara{width:413px!important;max-width:413px}
          .data-LNGG .sortable-item:nth-last-child(1) .paragraph,  .data-LNGG .sortable-item:nth-last-child(2) .paragraph,  .data-SKLI .sortable-item:nth-last-child(1) .paragraph,  .data-SKLI .sortable-item:nth-last-child(2) .paragraph{padding-bottom:0}
          .name-contact .section.SECTION_CNTC,   .name-contact .section.SECTION_ALNK {float:none;display:inline-block;margin-top:0;padding: 0 1% 0 0;}
         .LUK .parentContainer{padding-bottom:30px}
         .LUK .right-box .SECTION_NAME{margin-top:0}
        .page-finalize   .sortableInner .paragraph-container+.paragraph-container,.page-finalize .sortable-drag-item  .section{margin:0}
          .data-LNGG .sortableInner .sortable-item,  .data-SKLI .sortableInner .sortable-item{display:inline-block;vertical-align:top}
          .lang-sec.infobarsec .sortable-item .para_odd,  .lang-sec.infobarsec .sortable-item .para_even,  .skli-sec.infobarsec .sortable-item .para_odd,  .skli-sec.infobarsec .sortable-item .para_even{max-width:197px}
         .LES .lang-sec .title-edit{margin-left:-117px}
          .left-box .sortable-item:first-child .section,  .right-box .sortable-item:first-child .section{margin-top:0}
          .left-box .sortable-item:first-child .section{padding-top:20px}
         .LES{padding-bottom:25px}
         .LES .data-EDUC .sortableInner, .LES .data-EXPR .sortableInner{display:inline-block;width:calc(100% - 104px)}
         .LES .data-EDUC .sortableInner .singlecolumn, .LES .data-EXPR .sortableInner .singlecolumn{margin-left:16px}
         .LES .data-EDUC .sortableInner .paragraph .doc-overlay, .LES .data-EXPR .sortableInner .paragraph .doc-overlay{left:5px!important}

        /*PPDT*/
          .disclaim.section{margin:0;padding:0;margin-top:50px}

        /*RNA fixes*/
        .RNA .name-contact .firstsection{margin-top:0}
        .RNA  .parentContainer{padding-bottom:25px}
        .RNA  .data-LNGG .sortableInner .sortable-item{display:inline-block;vertical-align:top}
        .RNA  .data-LNGG .nativeLangPara{width:100%!important;max-width:100%!important}
        .RNA  .data-LNGG .para_odd{margin-right:15px}
        .RNA  .data-LNGG .nativeLangPara .singlecolumn{width:413px!important;max-width:413px!important}
        .RNA  .data-LNGG .firstparagraph.para_odd{max-width:197px!important}
        .RNA  .PICTPic, .RNA  .PICTPic .field{height:129px; width:100px}
          .doc-item .heading::before,   .doc-item .heading{width:104px}
         .LDE .left-box .SECTION_PICT{margin-top:0;padding-top:20px}
         .LDE .right-box .SECTION_NAME {margin-top:0}
          .right-box .sortable-item .section.SECTION_NAME{margin-bottom:16px}
    @-moz-document url-prefix() {
          .heading::before{top:-7px}
        .resume-preview   .parentContainer .section{border-width:2px}
        }
        
     /*Fixes for builder for skill*/
          .skli-sec .sortable-item .paragraph:last-child .singlecolumn .field:last-child{min-height:12px} 
          .skli-sec .sortable-item:nth-last-child(1) .paragraph .singlecolumn .field:last-child,  .skli-sec .sortable-item:nth-last-child(2) .paragraph .singlecolumn .field:last-child{min-height:0} 

        .template-2 p{
          margin-bottom: 0rem !important;
        }
        .watermark {
      position: fixed;
      top: 50%; /* Adjust as needed */
      left: 50%; /* Adjust as needed */
      transform: translate(-50%, -50%);
      font-size: 44px; /* Adjust font size */
      color: rgba(0, 0, 0, 0.2); /* Adjust color and opacity */
      z-index: -1; /* Place it behind other content */
  }
  .sectiontitle { 
    border-bottom: 1px solid #7b89a8; 
}
.sectiontitle:last-child { 
    border-bottom: none; 
}
#FIELD_CPIN{
  margin-bottom : -1000px;
}
#FIELD_CPHN{
  margin -bottom : -1000px;
}
#FIELD_EMAI{
  margin-bottom:-1000px;
}
.name .mb75{
  margin-bottom: -1000px;
}

        /* div,p,h1,span {page-break-inside: avoid} */
        </style>
            `;
    const contentElement = document.getElementById('downloadToWord').innerHTML;
    const contentWithStyles = cssStyles + contentElement;
    // const previewArea = document.getElementById('previewArea');
    // previewArea.innerHTML = contentWithStyles;
    const docx = htmlDocx.asBlob(contentWithStyles, { orientation: 'portrait' });
    saveAs(docx, 'resume.docx');
  };


  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      {/* <button onClick={printDocument}>Print</button> */}
      {/* <div id="previewArea">
          
      </div> */}
      {respokData ? (
        <>
          <li id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '50px', listStyle: 'none' }} class="template-preview template-2 exp" data-skinid="MLF1" data-skincd="MLF1">
            <div class="render-preview">
              {pack == 'Free' && <div class="watermark">GOjobs AI</div>}
              <div class="" tabindex="">

                <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlk2 MLF1  LUK  " docskinwidth="595">
                  <div id="CONTAINER_PARENT_0" class="topsection hidesecondpass" >
                    {image &&
                      <div id="CONTAINER_0" class="left-box">
                        <div
                          data-react-beautiful-dnd-draggable="2"
                          className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                        >
                          <div id="SECTION_PICT4214b310-3411-4e1d-862f-bc0494836200" class="section notdraggable SECTION_PICT firstsection   " data-section-cd="PICT">
                            <div class=" doc-item">
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_PICT_e1ebf3dc-8c81-4b3f-a42e-d54881864bc9" class="paragraph PARAGRAPH_PICT firstparagraph placeholder-text ">
                                    <div class="clearfix doc-item">
                                      <div class="PICTPic">
                                        <div class="field" id="FIELD_PURL">
                                          <img class="chk" src={image} alt="Smiley face" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    <div id="CONTAINER_1" class="right-box">
                      <div data-react-beautiful-dnd-draggable="241" class="sortable-item section-container SortableItem-sibling name-contact ">

                        <div id="SECTION_NAME25a3bf91-f80d-4376-95be-f3561350d955" class="section notdraggable SECTION_NAME    " data-section-cd="NAME">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_NAME_5d630705-f750-47a3-a5ba-01626b4c4bd2" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                  <div>
                                    <div class="name mb75" dependency="FNAM|LNAM">
                                      <span id="FIELD_FNAM">{contact.f_name}</span>&nbsp;
                                      <span id="FIELD_LNAM">{contact.l_name}</span>
                                    </div>
                                    <div class="" dependency="DCTL">
                                      <div className="mb75" id="FIELD_CPHN">

                                        <div className="icon_bg">
                                          <img className="temp_icons" src={location_icon} />
                                        </div>
                                        <span className="ml10 mt-align">{contact.city}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg"><img className="temp_icons" src={contact_icon} /></div>
                                        <span className="ml10 mt-align">{contact.mobile}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_EMAI">
                                        <div className="icon_bg"><img className="temp_icons" src={mail_icon} /></div>
                                        <span className="ml10 mt-align">{contact.email}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="CONTAINER_PARENT_1" class="parentContainer hidefirstpass">
                    <div id="CONTAINER_2">
                      <div className={`sortable-item section-container SortableItem-sibling data-SUMM mb175`}>
                        <div id="SECTION_SUMMc1414a8c-6865-4672-936c-c053bc81b85c" class="section SECTION_SUMM    has-title " data-section-cd="SUMM">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_SUMM">
                                Summary <span title=" Summary " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_SUMM_962095c5-2dd9-6dd1-7221-e575e11d0b5b" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn" id="FIELD_FRFM">
                                      <p>
                                        {contact.summery}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`sortable-item section-container SortableItem-sibling data-SUMM `}>
                        <div class="section SECTION_EXPR   multi-para has-title break-after mb175">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EXPR">
                                Experience <span title=" Experience " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_EXPR_c7345e1f-d6d2-9173-0709-23e027c27d63" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    {experience?.map((item, index) => (
                                      <div className="">
                                        <div className="" style={{ marginBottom: '0rem' }}>

                                          <div id="PARAGRAPH_EXPR_c7345e1f-d6d2-9173-0709-23e027c27d63" className="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                            <div className="clearfix doc-item">
                                              <div className="singlecolumn">
                                                <div style={{ marginBottom: '0rem' }}>
                                                  <span className="paddedline" dependency="JTIT|JSTD|EDDT">
                                                    <span className="jobtitle txt-bold" id="FIELD_JTIT">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</span><span dependency="JTIT+JSTD|EDDT">, </span>
                                                    <span className="jobdates" dependency="JSTD|EDDT">
                                                      <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                                      <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                                    </span>
                                                  </span><br />

                                                  <span className="paddedline locationGap txt-bold" dependency="COMP|JSTA|JCIT|JCNT">
                                                    <span className="companyname" id="FIELD_COMP">{item.employer}</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> - </span></span>
                                                    <span className="jobcity" id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                                    <span className="jobstate" id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                                                  </span>
                                                </div>


                                                <span className="jobline" id="FIELD_JDES">
                                                  <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {item.achievements != '[]' && item.achievements != '[null]' && (
                                          <div className="singlecolumn maincolumn mb25">
                                            <br />
                                            <ul>
                                              {JSON.parse(item.achievements)?.map((item1, index) => (
                                                item1 && <li key={index}>{item1}</li>
                                              ))}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`sortable-item section-container SortableItem-sibling data-SUMM mb175`}>
                          <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                            <div className=" doc-item">
                              <div className="heading">
                                <div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div>
                              </div>
                              <div className="">
                                <div className="">
                                  <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                    <div className="clearfix doc-item">
                                      {skills[0] && <div className="singlecolumn maincolumn">
                                        <span className="paddedline" id="FIELD_SKC1">
                                          <ul dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                        </span>

                                      </div>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`sortable-item section-container SortableItem-sibling data-SUMM mb175`}>
                          <div id="SECTION_EDUC4be5bc21-4afb-4500-b907-608fd306f119" class="section SECTION_EDUC   multi-para has-title " data-section-cd="EDUC">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_EDUC">
                                  Education <span title=" Education " class="rename-section text-rename"></span>
                                </div>
                              </div>
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_EDUC_a6ed5687-d23c-0e42-7a1d-7c7a5424420a" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn">
                                        {education?.map((item, index) => (
                                          <div className="">
                                            <div className="">
                                              <div id="PARAGRAPH_EDUC_a6ed5687-d23c-0e42-7a1d-7c7a5424420a" className="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                                <div className="clearfix doc-item" >
                                                  <div className="">

                                                    <div className="paddedline" dependency="SCIT|SSTA|SCHO|SCNT">
                                                      <span className="companyname" id="FIELD_SCHO">
                                                        <span className="degree">
                                                          {item.course}&nbsp;
                                                          {item.specialization && <span className="degree">,
                                                            &nbsp;{item.specialization} &nbsp;
                                                          </span>}

                                                          {item.university && <span className="jobdates">
                                                            <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                                          </span>}
                                                          {item.year_of_completion && <span className="jobdates">
                                                            <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                                          </span>}
                                                        </span>
                                                      </span>
                                                    </div>

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {item.achievements != '[]' && item.achievements != '[null]' && (
                                              <div className="mb25">
                                                <br />
                                                <ul>
                                                  {JSON.parse(item.achievements)?.map((item1, index) => (
                                                    item1 && <li key={index}>{item1}</li>
                                                  ))}
                                                </ul>
                                              </div>
                                            )}
                                          </div>

                                        ))}
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                          <div
                            data-react-beautiful-dnd-draggable="2"
                            className={`sortable-item section-container SortableItem-sibling data-SUMM `}

                          >

                            <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                              <div className=" doc-item">
                                <div className="heading">
                                  <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                </div>
                                <div className="">
                                  <div className="">
                                    <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                      <div className="clearfix doc-item">
                                        <div className="singlecolumn maincolumn">
                                          {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                            <div className="mb75">
                                              <h4>Languages:</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <ul>
                                                  {JSON.parse(more)?.language?.map((item, index) => (
                                                    <li key={item.language}>{item.language}</li>
                                                  ))}
                                                </ul>
                                              </span>
                                            </div>}
                                        </div>

                                        {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                          <h4>Website & Profiles:</h4>
                                          <span className="paddedline" id="FIELD_SKC1">
                                            <div>
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.work && <div key={item.work}><a href={item.work} style={{ color: "#000" }} target="_blank">{item.work}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.portfolio && <div key={item.portfolio}><a href={item.portfolio} style={{ color: "#000" }} target="_blank">{item.portfolio}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.profile && <div key={item.profile}><a href={item.profile} style={{ color: "#000" }} target="_blank">{item.profile}</a></div>
                                              ))}
                                            </div>
                                          </span>
                                        </div>}

                                        {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                          {JSON.parse(more)?.custom?.map((item, index) => (
                                            <div>
                                              <h4>{item.custom_name}</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <p>
                                                  {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                                </p>
                                              </span>
                                            </div>
                                          ))}
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </li>
          <li id="downloadToWord" onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '50px', listStyle: 'none', display: 'none' }} class="template-preview template-2 exp" data-skinid="MLF1" data-skincd="MLF1">

            <div class="render-preview">
              {pack == 'Free' && <div class="watermark">GOjobs AI</div>}
              <div class="" tabindex="">

                <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlk2 MLF1  LUK  " docskinwidth="595">
                  <div
                    id="CONTAINER_PARENT_0"
                    className="topsection hidesecondpass"
                    style={{
                      display: 'flex',               // Align child elements in a row
                      justifyContent: 'space-between', // Space between left and right boxes
                      alignItems: 'flex-start',       // Align items at the start of the container
                    }}
                  >
                    {image && (
                      <div
                        id="CONTAINER_0"
                        className="left-box"
                        style={{
                          flex: '1',                 // Allow the box to take up 50% of the container's width
                          marginRight: '1rem',       // Add spacing between left and right boxes
                        }}
                      >
                        <div
                          data-react-beautiful-dnd-draggable="2"
                          className="sortable-item section-container SortableItem-sibling data-SUMM"
                        >
                          <div
                            id="SECTION_PICT4214b310-3411-4e1d-862f-bc0494836200"
                            className="section notdraggable SECTION_PICT firstsection"
                            data-section-cd="PICT"
                          >
                            <div className="doc-item">
                              <div>
                                <div>
                                  <div
                                    id="PARAGRAPH_PICT_e1ebf3dc-8c81-4b3f-a42e-d54881864bc9"
                                    className="paragraph PARAGRAPH_PICT firstparagraph placeholder-text"
                                  >
                                    <div className="clearfix doc-item">
                                      <div className="PICTPic">
                                        <div className="field" id="FIELD_PURL">
                                          <img
                                            className="chk"
                                            src={image}
                                            alt="Smiley face"
                                            width={'129'} height={'129'}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      id="CONTAINER_1"
                      className="right-box"
                      style={{
                        flex: '1',                 // Allow the box to take up 50% of the container's width
                        marginLeft: '1rem',        // Add spacing between left and right boxes
                      }}
                    >
                      <div
                        data-react-beautiful-dnd-draggable="241"
                        className="sortable-item section-container SortableItem-sibling name-contact"
                      >
                        <div
                          id="SECTION_NAME25a3bf91-f80d-4376-95be-f3561350d955"
                          className="section notdraggable SECTION_NAME"
                          data-section-cd="NAME"
                        >
                          <div className="doc-item">
                            <div>
                              <div>
                                <div
                                  id="PARAGRAPH_NAME_5d630705-f750-47a3-a5ba-01626b4c4bd2"
                                  className="paragraph PARAGRAPH_NAME firstparagraph"
                                >
                                  <div>
                                    <div className="name mb75" dependency="FNAM|LNAM">
                                      <span id="FIELD_FNAM">{contact.f_name}</span>&nbsp;
                                      <span id="FIELD_LNAM">{contact.l_name}</span>
                                    </div>
                                    <div dependency="DCTL">
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg">
                                          <img className="temp_icons" src={location_icon} alt="Location Icon" />
                                        </div>
                                        <span className="ml10 mt-align">{contact.city}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg">
                                          <img className="temp_icons" src={contact_icon} alt="Contact Icon" />
                                        </div>
                                        <span className="ml10 mt-align">{contact.mobile}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_EMAI">
                                        <div className="icon_bg">
                                          <img className="temp_icons" src={mail_icon} alt="Mail Icon" />
                                        </div>
                                        <span className="ml10 mt-align">{contact.email}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="CONTAINER_PARENT_1" class="parentContainer hidefirstpass">
                    <div id="CONTAINER_2">
                      <div className={`sortable-item section-container SortableItem-sibling data-SUMM mb175`}>
                        <div id="SECTION_SUMMc1414a8c-6865-4672-936c-c053bc81b85c" class="section SECTION_SUMM    has-title " data-section-cd="SUMM">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_SUMM">
                                Summary <span title=" Summary " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_SUMM_962095c5-2dd9-6dd1-7221-e575e11d0b5b" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn" id="FIELD_FRFM">
                                      <p>
                                        {contact.summery}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`sortable-item section-container SortableItem-sibling data-SUMM `}>
                        <div class="section SECTION_EXPR   multi-para has-title break-after mb175">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EXPR">
                                Experience <span title=" Experience " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_EXPR_c7345e1f-d6d2-9173-0709-23e027c27d63" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    {experience?.map((item, index) => (
                                      <div className="">
                                        <div className="" style={{ marginBottom: '0rem' }}>

                                          <div id="PARAGRAPH_EXPR_c7345e1f-d6d2-9173-0709-23e027c27d63" className="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                            <div className="clearfix doc-item">
                                              <div className="singlecolumn">
                                                <div style={{ marginBottom: '0rem' }}>
                                                  <span className="paddedline" dependency="JTIT|JSTD|EDDT">
                                                    <span className="jobtitle txt-bold" id="FIELD_JTIT">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</span><span dependency="JTIT+JSTD|EDDT">, </span>
                                                    <span className="jobdates" dependency="JSTD|EDDT">
                                                      <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                                      <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                                    </span>
                                                  </span><br />

                                                  <span className="paddedline locationGap txt-bold" dependency="COMP|JSTA|JCIT|JCNT">
                                                    <span className="companyname" id="FIELD_COMP">{item.employer}</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> - </span></span>
                                                    <span className="jobcity" id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                                    <span className="jobstate" id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                                                  </span>
                                                </div>


                                                <span className="jobline" id="FIELD_JDES">
                                                  <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {item.achievements != '[]' && item.achievements != '[null]' && (
                                          <div className="singlecolumn maincolumn mb25">
                                            <br />
                                            <ul>
                                              {JSON.parse(item.achievements)?.map((item1, index) => (
                                                item1 && <li key={index}>{item1}</li>
                                              ))}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`sortable-item section-container SortableItem-sibling data-SUMM mb175`}>
                          <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                            <div className=" doc-item">
                              <div className="heading">
                                <div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div>
                              </div>
                              <div className="">
                                <div className="">
                                  <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                    <div className="clearfix doc-item">
                                      {skills[0] && <div className="singlecolumn maincolumn">
                                        <span className="paddedline" id="FIELD_SKC1">
                                          <ul dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                        </span>

                                      </div>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={`sortable-item section-container SortableItem-sibling data-SUMM mb175`}>
                          <div id="SECTION_EDUC4be5bc21-4afb-4500-b907-608fd306f119" class="section SECTION_EDUC   multi-para has-title " data-section-cd="EDUC">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_EDUC">
                                  Education <span title=" Education " class="rename-section text-rename"></span>
                                </div>
                              </div>
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_EDUC_a6ed5687-d23c-0e42-7a1d-7c7a5424420a" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn">
                                        {education?.map((item, index) => (
                                          <div className="">
                                            <div className="">
                                              <div id="PARAGRAPH_EDUC_a6ed5687-d23c-0e42-7a1d-7c7a5424420a" className="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                                <div className="clearfix doc-item" >
                                                  <div className="">

                                                    <div className="paddedline" dependency="SCIT|SSTA|SCHO|SCNT">
                                                      <span className="companyname" id="FIELD_SCHO">
                                                        <span className="degree">
                                                          {item.course}&nbsp;
                                                          {item.specialization && <span className="degree">,
                                                            &nbsp;{item.specialization} &nbsp;
                                                          </span>}

                                                          {item.university && <span className="jobdates">
                                                            <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                                          </span>}
                                                          {item.year_of_completion && <span className="jobdates">
                                                            <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                                          </span>}
                                                        </span>
                                                      </span>
                                                    </div>

                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            {item.achievements != '[]' && item.achievements != '[null]' && (
                                              <div className="mb25">
                                                <br />
                                                <ul>
                                                  {JSON.parse(item.achievements)?.map((item1, index) => (
                                                    item1 && <li key={index}>{item1}</li>
                                                  ))}
                                                </ul>
                                              </div>
                                            )}
                                          </div>

                                        ))}
                                      </div>
                                    </div>

                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                          <div
                            data-react-beautiful-dnd-draggable="2"
                            className={`sortable-item section-container SortableItem-sibling data-SUMM `}

                          >

                            <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                              <div className=" doc-item">
                                <div className="heading">
                                  <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                </div>
                                <div className="">
                                  <div className="">
                                    <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                      <div className="clearfix doc-item">
                                        <div className="singlecolumn maincolumn">
                                          {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                            <div className="mb75">
                                              <h4>Languages:</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <ul>
                                                  {JSON.parse(more)?.language?.map((item, index) => (
                                                    <li key={item.language}>{item.language}</li>
                                                  ))}
                                                </ul>
                                              </span>
                                            </div>}
                                        </div>

                                        {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                          <h4>Website & Profiles:</h4>
                                          <span className="paddedline" id="FIELD_SKC1">
                                            <div>
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.work && <div key={item.work}><a href={item.work} style={{ color: "#000" }} target="_blank">{item.work}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.portfolio && <div key={item.portfolio}><a href={item.portfolio} style={{ color: "#000" }} target="_blank">{item.portfolio}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.profile && <div key={item.profile}><a href={item.profile} style={{ color: "#000" }} target="_blank">{item.profile}</a></div>
                                              ))}
                                            </div>
                                          </span>
                                        </div>}

                                        {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                          {JSON.parse(more)?.custom?.map((item, index) => (
                                            <div>
                                              <h4>{item.custom_name}</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <p>
                                                  {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                                </p>
                                              </span>
                                            </div>
                                          ))}
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </li>
        </>
      ) : (
        <Loading />
      )}
    </div>

  );
});
export default Dynamictemp2;
