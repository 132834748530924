import React, { Component, useEffect, useState, useRef } from "react";
import Nav from "./Nav";
import avatar from "./Assets/avatar.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
	HashRouter as Router,
	Route,
	Link,
	Navigate,
	useNavigate,
	DefaultRoute
} from "react-router-dom";
import { commonConfig } from '../config.js';
import axios from "axios";
import Loading from './Loading';
import StepsFooter from './StepsFooter';

function Step1() {

	const [selectedImage, setSelectedImage] = useState(null);
	const [showavatar, setShowavatar] = useState(true);
	const [stepno, setStepno] = useState(1);
	const fileInputRef = useRef(null);
	const [page, setPage] = useState(false);
	const [isname, setIsname] = useState(false);
	const [showaddress, setShowaddress] = useState(false);
	const navigate = useNavigate();
	const userId = localStorage.getItem('userId');

	const [formData, setFormData] = useState({
		f_name: '',
		l_name: '',
		email: '',
		mobile: '',
		city: '',
		pincode: '',
		address_1: '',
		address_2: '',
		state: '',
		country: '',
	});
	// const resume_data = localStorage.getItem('resume_data');
	const temp_resume_id = localStorage.getItem('temp_resume_id');
	const parsed_json_data = localStorage.getItem('parsed_json_data');
	const parsed_exp = JSON.parse(parsed_json_data);
	const resume_id = localStorage.getItem('resume_id');
	const uploaded_resume_id = localStorage.getItem('uploaded_resume_id');
	const user_email = localStorage.getItem('email');
	const [isOpen, setIsOpen] = useState(false);
	const [errors, setErrors] = useState([]);
  const template = localStorage.getItem('template');
	const CommonData = localStorage.getItem('common_data');

	const handleOpen = () => {
		if (validate()) {
			setIsOpen(true);
		}
	};

	const handleClose = () => {
		setIsOpen(false);
	};
	const toggleAddress = () => {
		setShowaddress(!showaddress);
	};
	const handleBack = () => {
		navigate('/template');
	};

	useEffect(() => {
		if (!userId) {
			navigate('/login');
		}
		// if (CommonData) {
		// 	if (JSON.parse(CommonData).plan.pack_status == 1) {
				
		// 	}else{
		// 		navigate('/plansfront');
		// 	} 
		// }
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "contacts/get_name",
			data: { userid: userId },
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
		}).then(res => {
			var decodedData = res.data;
			const result = decodedData.data;
			if (result[0]) {
				formData.f_name = result[0].f_name;
				formData.l_name = result[0].l_name;
				setIsname(true);
			}

		}).catch(err => {

		});

		if (resume_id) {
			console.log("resume_id", resume_id);
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "finalize",
				data: { resume_id: resume_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;

				const contact = result.contact[0];
				setStepno(contact.step);
				if (contact.step == 100) {
					// navigate('/step13');
				}

				if (contact.f_name != '') {
					setFormData({
						f_name: contact.f_name,
						l_name: contact.l_name,
						email: contact.email,
						mobile: contact.mobile,
						city: contact.city,
						pincode: contact.pincode,
						address_1: contact.address_1,
						address_2: contact.address_2,
						state: contact.state,
						country: contact.country,
					});
				} else {
					get_temp_data(temp_resume_id);
				}
			}).catch(err => {

			});
		} else {
			console.log("parsed_exp", parsed_exp);
			if (parsed_exp != undefined) {
				if (isname) {
					setFormData({
						email: parsed_exp.email,
						mobile: parsed_exp.phone,
						city: parsed_exp.address?.city,
						pincode: parsed_exp.address?.pincode,
						address_1: parsed_exp.address?.address1,
						address_2: '',
						state: parsed_exp.address?.state,
						country: 'India',
					});
				} else {
					setFormData({
						f_name: parsed_exp.firstname,
						l_name: parsed_exp.lastname,
						email: parsed_exp.email,
						mobile: parsed_exp.phone,
						city: parsed_exp.address?.city,
						pincode: parsed_exp.address?.pincode,
						address_1: parsed_exp.address?.address1,
						address_2: '',
						state: parsed_exp.address?.state,
						country: 'India',
					});
				}

			}

			get_temp_data(temp_resume_id);
		}

		import('./Css/Step1.scss');

		setTimeout(() => {
			setPage(true);
		}, commonConfig.LOAD_TIME);
	}, []);

	const get_temp_data = (resume_id) => {
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "finalize",
			data: { resume_id: resume_id },
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
		}).then(res => {
			var decodedData = res.data;
			const result = decodedData.data;

			const contact = result.contact[0];
			if (contact.f_name != '') {
				setFormData({
					f_name: contact.f_name,
					l_name: contact.l_name,
					email: contact.email,
					mobile: contact.mobile,
					city: contact.city,
					pincode: contact.pincode,
					address_1: contact.address_1,
					address_2: contact.address_2,
					state: contact.state,
					country: contact.country,
				});
			}


		}).catch(err => {

		});
	}

	const handleButtonClick = () => {
		fileInputRef.current.click();
	};
	const handleImageChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			const fileSizeLimit = 5 * 1024 * 1024; // 10 MB in bytes
			if (file.size <= fileSizeLimit) {
				setSelectedImage(file);
				setShowavatar(false);
			} else {
				toast.error('File size exceeds the limit of 5MB');
				event.target.value = '';
			}
		}
	};

	const [focusedTextbox, setFocusedTextbox] = useState('');

	const handleInputChange = (event) => {
		const { id, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[id]: value
		}));
		validate();
	};

	const handleFocus = (event) => {
		const { id } = event.target;
		setFocusedTextbox(id);
	};

	const handleBlur = () => {
		setFocusedTextbox('');
	};

	const validate = () => {
		let errorObj = {};
		const newErrors = [];
		if (formData.f_name == '') {
			errorObj.f_name = "Firstname required.";
		} else if (formData.l_name == '') {
			errorObj.l_name = "Lastname required.";
		} else if (formData.email == '') {
			errorObj.email = "Email Address required.";
		} else if (formData.city == '') {
			errorObj.city = "City required.";
		} 

		// const phonePattern = /^[0-9]{10,}$/;
		// if (phonePattern.test(formData.mobile)) {

		// }else{
		//   errorObj.mobile = "Mobile Number is Invalid.";
		// }
		// console.log("errorObj",errorObj);
		setErrors(errorObj);
		if (Object.keys(errorObj).length > 0) {
			console.log("errorObj", errorObj); return false;
		}
		return true;
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (resume_id) {
			formData.resume_id = resume_id;
		}
		formData.userId = userId;
		formData.file = selectedImage;

		if (uploaded_resume_id) {
			formData.old_resume_id = uploaded_resume_id;
		} else {
			formData.old_resume_id = 0;
		}

		if (validate()) {
			setPage(false);
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "contacts",
				data: formData,
				headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + commonConfig.API_TOKEN, "user_id": userId, "step": 1,'template':template },
			}).then(res => {
				var decodedData = res.data;
				var status = res.status;
				if (status) {
					localStorage.setItem('resume_id', decodedData.data.id);
					if (stepno >= 9) {
						navigate('/step13');
					} else {
						navigate('/step2');
					}
				}
			}).catch(err => {

			});
		}
	};

	return (

		<div className="step1page">
			{page ? (
				<div>
					<ToastContainer position="top-center" theme="dark" />
					<main id="app" role="main">
						<section className="page-wrapper">
							<div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
								<Nav value={stepno} />
								<div className="step1_sec">
									<div className="inner1_step1">
										<h3 className="headtag"><span className="blue">Enter Your Contact Information</span>
										</h3>
										<p style={{ marginBottom: '3%', fontWeight: '200px;' }} className="p_tag">Fill your Details For Employers To Connect To You.</p>
									</div>
									<div className="inner2_step1">
										<form action="" method="">
											<div className="col-md-12">
												<div className="col-lg-4 mobile_view">

													<input type="file" accept="image/*" onChange={handleImageChange} ref={fileInputRef} style={{ display: 'none' }} />

													<div className="img-content image-content-thumb">
														{showavatar &&
															<img src={avatar} className="avatar" />
														}
														{selectedImage && (
															<div className="">
																<img className="avatar" src={URL.createObjectURL(selectedImage)} alt="Selected" />
															</div>
														)}
														<div className="photo_section">
															<button type="button" className="btn btn-photo" onClick={handleButtonClick}>Choose Image</button>
															<p className="photo-msg">Supported file formats are JPEG and PNG. The file must not exceed 5MB <b className="blue">(Optional)</b></p>
														</div>

													</div>

												</div>
												<div className="col-lg-8">

													<div>
														<div className="col-lg-6 half">
															<div className="formgroup">
																<input type="text" id="f_name" className="formcontrol" value={formData.f_name} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />

																<label className="formlabel" htmlFor="f_name">
																	FIRST NAME
																</label>
																{errors && errors.f_name && (
																	<span className="error">{errors.f_name}</span>
																)}
															</div>
														</div>

														<div className="col-lg-6 half">
															<div className="formgroup">
																<input type="text" id="l_name" className="formcontrol" value={formData.l_name} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
																<label className="formlabel" htmlFor="l_name">
																	LAST NAME
																</label>
																{errors && errors.l_name && (
																	<span className="error">{errors.l_name}</span>
																)}
															</div>
														</div>
													</div>

													<div className="col-lg-6 full" style={{ clear: 'both' }}>
														<div className="formgroup">
															<input type="text" id="email" className="formcontrol" value={formData.email} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="email">
																EMAIL
															</label>
															{errors && errors.email && (
																<span className="error">{errors.email}</span>
															)}
														</div>
													</div>

													<div className="col-lg-6 full">
														<div className="formgroup">
															<input type="text" id="mobile" className="formcontrol" value={formData.mobile} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="mobile">
																MOBILE
															</label>
															{errors && errors.mobile && (
																<span className="error">{errors.mobile}</span>
															)}
														</div>
													</div>

													<div className="col-lg-6 half" style={{ clear: 'both' }}>
														<div className="formgroup">
															<input type="text" id="city" className="formcontrol" value={formData.city} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="city">
																CITY
															</label>
															{errors && errors.city && (
																<span className="error">{errors.city}</span>
															)}
														</div>
													</div>

													<div className="col-lg-6 half">
														<div className="formgroup">

															<input type="text" id="pincode" className="formcontrol" value={formData.pincode} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="pincode">
																PINCODE
															</label>
														</div>
													</div>

													{showaddress && <div className="col-lg-6 full" style={{ clear: 'both' }}>
														<div className="formgroup">

															<input type="text" id="address_1" className="formcontrol" value={formData.address_1} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="address_1">
																ADDRESS-1
															</label>
														</div>
													</div>}

													{showaddress && <div className="col-lg-6 full">
														<div className="formgroup">

															<input type="text" id="address_2" className="formcontrol" value={formData.address_2} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="address_2">
																ADDRESS-2
															</label>
														</div>
													</div>}

													{showaddress && <div className="col-lg-6 half" style={{ clear: 'both' }}>
														<div className="formgroup">

															<input type="text" id="state" className="formcontrol" value={formData.state} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="state">
																STATE
															</label>
														</div>
													</div>}

													{showaddress && <div className="col-lg-6 half">
														<div className="formgroup">
															<input type="text" id="country" className="formcontrol" value={formData.country} onChange={handleInputChange} onFocus={handleFocus} onBlur={handleBlur} />
															<label className="formlabel" htmlFor="country">
																COUNTRY
															</label>
														</div>
													</div>}
													{showaddress && <div className="col-lg-6 half">
														<div className="formgroup">
                            <div className="contact_addmore mt-10 clickable" onClick={toggleAddress}>
															- REMOVE ADDRESS
														</div>
														</div>
													</div>}
													<div className="col-lg-12" style={{clear:'both'}}>
														<div className="contact_note mt-10">
															Note: We request you to add city and pincode, because many recruiters and Applicant Tracking Systems (ATS) use your location as a factor to shortlist your resume
														</div>
														{!showaddress && <div className="contact_addmore mt-10 clickable" onClick={toggleAddress}>
															+ ADD ADDRESS
														</div>}
													</div>
												</div>
												
												
												<div className="col-lg-4 system_view">
													{selectedImage && (
														<div className="center">
															<img style={{ marginTop: '2rem', objectFit: 'contain' }} src={URL.createObjectURL(selectedImage)} alt="Selected" width="200" height="200" />
														</div>
													)}

													<input
														type="file"
														accept="image/*"
														onChange={handleImageChange}
														ref={fileInputRef}
														style={{ display: 'none' }}
													/>

													<div className="img-content image-content-thumb">
														{showavatar &&
															<img src={avatar} className="avatar" />
														}
														<button type="button" className="btn btn-photo" onClick={handleButtonClick}>Choose Image</button>
                            <div className="blue center">(Optional)</div>
													</div>

												</div>
												<div className="col-lg-12" style={{ marginBottom: '4%' }}>

												</div>
											</div>
											<div className="col-lg-6 half">
												<button id="btnBack" type="button" onClick={handleBack} className="btn-stepsback">Back</button>
											</div>
											<div className="col-lg-6 half">
												<button id="btnBack" type="button" className="btn-mangaii f-align" onClick={handleSubmit}>
													Next
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</section>
					</main>
					<StepsFooter />
				</div>
			) : (
				<Loading content="Please Wait!!!" />
			)}
		</div>
	);
}

export default Step1;
