import React, { useEffect, useState } from "react";
import axios from "axios";
import CartNav from "./CartNav";
import { Link, useNavigate } from 'react-router-dom';
import { commonConfig } from '../config.js';
import Loading from './Loading';

function openUrlWithPostData(url, postData) {
  const form = document.createElement('form');
  form.action = url;
  form.method = 'POST';

  for (const [key, value] of Object.entries(postData)) {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  }

  document.body.appendChild(form);
  form.submit();
}


function Cart(props) {
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const [page, setPage] = useState(false);
  const [promocode, setPromocode] = useState('');
  const [promocodeapplied, setPromocodeapplied] = useState(false);
  const [data, setData] = useState([]);
  const [plans, setPlans] = useState([]);
  const choosedplan = localStorage.getItem('choosedplan');
  

  useEffect(() => {
    import('./Css/Pricing.scss');
    import('./Assets/CSS/bootstrap.min.css');

    pricing();
    
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);

  const pricing = () => {
    setPromocodeapplied(false);
    let json={};
    if(choosedplan == 1){
      json = {id:1,price:'253.5',gst:'45.5',name:'14 Days Pack',topay:299,discount:0,promocode_msg:"",promocode:'',userId:userId};
    }else if(choosedplan == 2){
      json = {id:2,price:'1694',gst:'305',name:'Full Pack',topay:1999,discount:0,promocode_msg:"",promocode:'',userId:userId};
    }else if(choosedplan == 3){
      json = {id:3,price:'400',gst:'99',name:'plan-3',topay:499,discount:0,promocode_msg:"",promocode:'',userId:userId};
    }else if(choosedplan == 4){
      json = {id:4,price:'400',gst:'99',name:'Plan-4',topay:499,discount:0,promocode_msg:"",promocode:'',userId:userId};
    }else if(choosedplan == 5){
      json = {id:5,price:'900',gst:'99',name:'Plan-5',topay:999,discount:0,promocode_msg:"",promocode:'',userId:userId};
    }else{
      navigate('/plans');
    }
    setPlans(json);
  }
  
  const updateMultipleKeys = (updates) => {
    setPlans(prevState => ({
      ...prevState,
      ...updates
    }));
  };

  const handleInputChange = (event) => {
    setPromocode(event.target.value);
  };

  const buy = (event) => {
    let url='';
    if(plans.id > 2){
      url = commonConfig.REACT_APP_BACKEND_URL + 'razorpay-regular';
    }else{
      url = commonConfig.REACT_APP_BACKEND_URL + 'razorpay-payment';
    }
    
    openUrlWithPostData(url, plans);
  };

  const ApplyPromocode = (event) => {
    event.preventDefault();
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "checkpromocode",
      data: { promocode: promocode,price: plans.topay },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN},
    }).then(res => {
      var decodedData = res.data.data;
      if(decodedData.status == 1){
        setPromocodeapplied(true);
        updateMultipleKeys({ discount: decodedData.discount, topay: decodedData.topay, gst: decodedData.gst,promocode:decodedData.code,promocode_msg:decodedData.perc });
      }
    }).catch(err => {
      // Handle error
    });
  };
  
 


  return (

    <div className="pricingpage">
      {page ? (
        <div>
          <CartNav value="2" />
          <main id="app" role="main">
            <section className="page-wrapper">
              <div id="parent" style={{ marginTop: '6rem' }} className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">

                <form action="" method="">
                  <div className="col-md-12" >
                    <div className="col-lg-8">{/* Add form fields here */}</div>
                  </div>
                  <div className="inner2_step1">
                    <section className="tab-content-plans mh">
                      <div className="cart">
                        <h4 className="headtag">
                          <span className="blue">Order Details</span>
                        </h4>
                        <div className="center-start-end cart-item">
                          <p>14 Days Pack</p>
                          <p><span >&#8377;</span>{plans.price}</p>
                        </div>
                        <div className="cart-item">
                          <div><b>Apply PromoCode</b></div>
                          {!promocodeapplied &&<div className="center-start-end">
                            <input type="text" value={promocode} onChange={handleInputChange} id="l_name" className="promocode"  />
                            <button id="btnBack" onClick={ApplyPromocode} type="button" className="promocode_submit">Apply</button>
                          </div>}
                          {promocodeapplied &&<div className="center-start-end" style={{marginTop:'20px'}}>
                            <p>{plans.promocode} <span>({plans.promocode_msg}% Applied )</span></p>
                            <p className="blue clickable" onClick={pricing}>Remove</p>
                          </div>}
                        </div>
                          <div className="center-start-end p-30">
                            <p>Total</p>
                            <p><span >&#8377;</span>{plans.price}</p>
                          </div>
                          
                          <div className="center-start-end p-30">
                            <p>GST (18%)</p>
                            <p><span >&#8377;</span>{plans.gst}</p>
                          </div>
                          <div className="center-start-end p-30">
                            <p>Discount</p>
                            <p><span >&#8377;</span>{plans.discount}</p>
                          </div>
                          {/* <div className="center-start-end p-30">
                            <p>GST (18%)</p>
                            <p><span >&#8377;</span>299</p>
                          </div> */}
                        <div className="cart-item">
                          
                        </div>
                        <div className="center-start-end p-30 cart-item">
                          <b>To Pay</b>
                          <p><span >&#8377;</span>{plans.topay}</p>
                        </div>
                        <div className="center cart-item">
                          <button type="button" onClick={buy} className="btn-mangaii ftr_btn proceed_pay">Proceed to Pay</button>
                        </div>
                      </div>
                    </section>
                  </div>
                </form>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <Loading content="Please Wait!!!" />
      )}
    </div>
  );
}

export default Cart;






















