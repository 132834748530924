import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contact_icon from './Assets/icons/contact.png';
import mail_icon from './Assets/icons/mail.png';
import location_icon from './Assets/icons/location.png';
import { saveAs } from 'file-saver'; // for downloading the file
import htmlDocx from 'html-docx-js/dist/html-docx';
import jsPDF from 'jspdf';

const Dynamictemp1 = forwardRef((props, ref) => {
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);
  let resume_id = localStorage.getItem('resume_id');

  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);
      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);



  const printDocument = (title, fontsize, spacing, color, resumename, justify) => {
    // const element = document.getElementById('pdfContainer');
    // console.log(htmlContent);
    // const data = {
    //   htmlContent: htmlContent,
    //   resume_id: resume_id,
    //   resumename: resumename,
    //   justify: justify,
    //   fontsize: fontsize,
    //   spacing: spacing,
    //   color: color,
    //   template: 7
    // };
    // console.log('htmlContent', htmlContent);
    // axios({
    //   method: "POST",
    //   url: commonConfig.REACT_APP_API_URL + "generate-pdf",
    //   data: data,
    //   headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    // }).then(res => {
    //   if (title == "save") {
    //     toast.success("Saved Successfully!!!");
    //   }

    // }).catch(err => {

    // });
    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(divRef.current, {
      callback: function (pdf) {
        pdf.save('download.pdf');
      },
      x: 20,          // Set the x offset for margin
      y: 20,          // Set the y offset for margin
      margin: 10,     // Apply a margin to all sides
      width: 550,     // Adjust content width based on A4 width and desired margins
      windowWidth: 900, // Control scaling based on browser window width
    });
  };


  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print,
    downloadWordDoc: downloadWordDoc,
  }));


  const downloadWordDoc = () => {
    // const content = `
    // `;

    // const blob = new Blob([content], {
    //   type: 'application/msword'
    // });

    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'resume.doc';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);

    const cssStyles = `
      <style>

@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:wght@300&family=Poppins:wght@200;300&family=Roboto:wght@400&display=swap');
        html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;vertical-align:baseline;background:transparent}
        body{background:#FFF;line-height:1;text-align:left}
                 table{border-collapse:collapse;border-spacing:0;font-size:inherit;color:inherit;width:100%}

        /*START content disc style for LI*/
                 ul,         li{list-style:none;margin:0;padding:0}
                 ul li{position:relative;margin:0px;padding-left:7px}
                 ul li:last-child{padding-bottom:0px}
                 ul li:before{position:absolute;left:0;top:0;font-family:'Roboto', sans-serif;}

        /*Helping Classes*/
                 .disp-blk{display:block}
                 .txt-bold{font-weight:bold}
                 .txt-normal{font-weight:normal}
                 .txt-italic{font-style:italic}
                 .txt-cntr{text-align:center}
                 .txt-capitalize{text-transform:capitalize}
                 .flt-right{float:right}
                 .inline-block{display:inline-block}
    
        /*Document*/
        /*         {color:#2a2a2a;border:25px solid var(--theme-color);position:relative;word-wrap:break-word;font-weight:400;word-wrap:break-word;padding:35px 0;min-height:672px} */
        /*         :before{content:'';opacity:.35;border:25px solid #2a2a2a;height:calc(100% + 50px);width:calc(100% + 50px);position:absolute;top:-25px;left:-25px;box-sizing:border-box;border-top:25px solid transparent;border-left:25px solid transparent} */
                :after{border-left:25px solid #2a2a2a;border-top:0;border-bottom:0;opacity:.35;transform:skew(0deg, -45deg);transform-origin:right}
                :after{content:'';position:absolute;border-left:25px solid #2a2a2a; left:-25px;top:539px;height:calc(100% - 539px)}
                 .heading{margin-bottom:5px;position:relative}
                 .name{font-weight:300;position:relative}
        /*          .name:before{content:"";position:absolute;width:4px;background:var(--theme-color);top:0px;height:100%;left:-19.7px} */
                 .parent-container{position:relative;display:table;table-layout:fixed;width:100%}
                 .cntc-sec span {text-align:left;padding-left:0}
                 .resume-title,         .execRsmTitle,         .resumeTitle{font-size:12px;font-weight:400;letter-spacing:0.5px;line-height:15px;text-transform:uppercase;margin-top:6px}
                 .execRsmTitle,         .resumeTitle{margin-top:0}
                 .execRsmTitle + span{margin-top:5px}
                 .address ul li{list-style-type:none;font-size: var(--font-size);line-height: var(--line-height);}
                 .left-box{padding-right:15px;padding-left:20px;padding-bottom:8px;box-sizing:content-box;display:table-cell;border-right:1px solid #404040}
                 .right-box{padding-left:15px;padding-right:20px;display:table-cell;vertical-align:top;position:relative}
                 .right-box .section:first-child + .section:not(.btnlnk){padding-top:12.3%!important}  /* :not Added to implement button */ 
                 .parent-container .name-sec,          .parent-container .cntc-sec{padding-top:0!important}
                 .jobline ul{margin-top:6px}
                 .sectiontitle{text-transform:capitalize}
                 .address .mail,         .address .phone{font-weight:700}

        /*New logic for infographic*/
                 .lang-sec .singlecolumn{display:none}
                 .lang-sec.infobarsec .infobarpara,         .lang-sec.infotilesec .infotilepara{display:block}
        
        /*Infographic*/
                 .lang-sec.infobarsec{font-size:0}
                 .lang-sec.infobarsec .field *,         .lang-sec.infobarsec .nativeLangPara .field{display:inline} 
                 .lang-sec.infobarsec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
                 .lang-sec.infobarsec .singlecolumn{margin-left:0!important;padding-left:0;position:relative}
                 .lang-sec.infobarsec .para_odd{margin-right:15px}
             .lang-sec .nativeLangPara{width:100%!important;max-width:100%!important}
                 .lang-sec.infobarsec .inner-rating{position:relative}
                 .lang-sec.infobarsec .rating-bar{background:#d5d6d6;width:100%;clear:both;margin-top:3px}
                 .lang-sec.infobarsec .inner-rating{background-color:var(--theme-color);height:4px;width:60%}
                 .lang-sec.infobarsec .paragraph:before{display:none}
                 .lang-sec.infobarsec > .paragraph:nth-last-child(1),         .right-box .lang-sec.infobarsec > .paragraph:nth-last-child(2){padding-bottom:0!important}
                 .left-box .lang-sec .paragraph,         .left-box .info-sec .paragraph{width:100%!important;max-width: 100%!important;display:block}
                 .rating-bar{background:#d5d6d6;width:38%;clear:both;margin-top:3px}
                 .inner-rating{background-color:var(--theme-color);height:4px;width:60%}

        /*Infographic Tiles*/
                 .lang-sec.infotilesec,         .skli-sec{font-size:0}
                 .lang-sec.infotilesec .paragraph,         .skli-sec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
                 .lang-sec.infotilesec > .paragraph:nth-last-child(1),         .right-box .lang-sec.infotilesec > .paragraph:nth-last-child(2),         .skli-sec > .paragraph:nth-last-child(1),         .right-box .skli-sec > .paragraph:nth-last-child(2){padding-bottom:0!important}
                 .lang-sec.infotilesec .field *,         .skli-sec .field *,         .lang-sec.infotilesec .nativeLangPara .field{display:inline}
                 .lang-sec.infotilesec .singlecolumn,         .skli-sec .singlecolumn{margin-left:0!important}
                 .lang-sec.infotilesec .sliced-rect,         .skli-sec .sliced-rect{height:6px;width:100%;margin-top:3px;line-height:0px;clear:both}
                 .lang-sec.infotilesec .paragraph.para_odd,         .skli-sec .paragraph.para_odd{margin-right:15px}
                 .lang-sec.infotilesec .sliced-rect .sliced-rect-tile,         .skli-sec .sliced-rect .sliced-rect-tile{height:100%;background-color:#d5d6d6;float:left;margin-right:3px}
                 .lang-sec.infotilesec .sliced-rect .sliced-rect-tile:last-child,         .skli-sec .sliced-rect .sliced-rect-tile:last-child{margin-right:0}
                 .left-box  .lang-sec.infotilesec .paragraph,          .left-box  .skli-sec .paragraph{width:100%!important;max-width: 100%!important;display:block}
        
    /*Rectangular Rating Blocks*/
                 .sliced-rect .sliced-rect-tile.ratvfill{background-color:var(--theme-color)}
                 .hide-bar .rating-bar,         .hide-bar .sliced-rect,         .hide-colon .colon{display:none!important}
                 .hide-bar .field-ratt{display:none}

        /*Skill*/
                 .skill .paddedline{display:table-cell;width:50%;padding-right:10px}
                 .skill .paddedline:last-child{padding-right:0}
                 .left-box .skill{display:table;width:100%;table-layout:fixed}
                 .left-box .skill .paddedline{display:block;width:100%}
                 .left-box .skill .paddedline:last-child li{padding-right:0}


        /*Table*/
                 table.skills,         .table_wrapper{width:100%;margin-top:0}
                 table.skills th,         table.skills td{width:20%;text-align:center}
                 table.skills th{text-decoration:underline}
                 table.skills .skillname,         table.skills .skillrating{text-align:left;width:35%}
                 table.skills .skillrating{width:20%}
                 table.skills .skillyears,         table.skills .skilllast{width:19%}
                 table.skills .pad1{width:5%}
                 table.skills .pad2,         table.skills .pad3{width:1%}
                 .displayNoneThisField{display:none}
                 .firstparagraph{margin-top:0!important}

        /*Address*/
                 .address ul li{position:relative;margin:0;padding-left:0;padding-bottom:5px;font-weight:700}
                 .address ul li:before{display:none}
             .address ul li:last-child{font-weight:400;padding-bottom:0}

        /*Button style*/
                 .section.btnlnk{text-align:left;margin-top:5px;height:18px;font-size:0}
                 .btnlnk .paragraph{display:inline-block;padding-left:10px;margin:0;line-height:normal}
                 .btnlnk .paragraph:first-child{padding-left:0}
                 .txtlnk{display:none}
                :not(.show-btn) .btnlnk{display:none}
  
        /*Button SVG*/
                 .btnlnk a{display:block;width:100%;height:100%;text-decoration:none;position:relative;text-align:center}
                 .btnlnk a:before{content:'';background:#566C7A;left:0;opacity:.83;position:absolute;height:100%;width:100%;z-index:-1}
                 .btnlnk svg text{text-anchor:middle;font-weight:600;fill:#fff;line-height:10px}
                 .btnlnk svg{width:calc(100% - 10px);height:100%;margin:auto}

                ,         table{line-height:13px}
                ,         table{line-height:13px}
        /*         {border:25px solid var(--theme-color)} */
                .pagesize{width:562px}
                .fontsize,         .lang-sec.infobarsec .paragraph *,         .lang-sec.infotilesec .paragraph *,         .skli-sec .paragraph *{font-size: var(--font-size);line-height: var(--line-height);}
                .fontface{font-family:'Roboto', sans-serif;}
                 .name:before{background:var(--theme-color)}
                 .name{font-size:36px;line-height:36px;color: var(--theme-color);}
                 .parent-container .section{padding-top:10px}
                 .sectiontitle{font-size: 18px;
          line-height: 16px;
          font-weight: bold;}
                 .sectiontitle1{font-size: 36px;
          line-height: 16px;
          font-weight: bold;}
                 .cntc-sec{line-height:12px}
                 .paragraph{margin-top:10px}
                 .right-box .singlecolumn,         .right-box .maincolumn{margin-left:0px}
                 table.skills td{padding-top:5px}
                 .expr-sec .paragraph{margin-top:15px}
                 .left-box{width:175px}
                 ul li{padding-left:7px}

       /*Infographic*/
                 .lang-sec.infobarsec .inner-rating{background-color:var(--theme-color)}
                 .right-box .lang-sec.infobarsec{padding-left:0px}
                 .right-box .lang-sec.infobarsec .heading{margin-left:-0px}
                 .right-box .lang-sec.infobarsec .paragraph{width:164px;max-width:164px}
                 .right-box .lang-sec .nativeLangPara{width:100%!important;max-width:100%!important}
                 .right-box .lang-sec,         .right-box .skli-sec{padding-left:0px}
                 .left-box .lang-sec .paragraph,         .left-box .skli-sec .paragraph{padding-bottom:5px}

                 .inner-rating{background-color:var(--theme-color) !important}

        /*Infographic Skills*/
                 .right-box .lang-sec.infotilesec .heading,         .right-box .skli-sec .heading{margin-left:-0px}
                 .right-box .lang-sec.infotilesec .paragraph,         .right-box .skli-sec .paragraph{width:164px}

        /*Button*/
                .vmargins.show-btn-gap,        .vmargins.show-btn{padding-top:100%}
                .show-btn-gap .SECTION_CNTC{padding-bottom:100%}
                 .btnlnk .paragraph{width:96px;height:18px}
                 .section.btnlnk{padding-top:0;height:18px;margin-top:5px}
                 .section.btnlnk + .section{padding-top:15px}
                .show-btn-gap .section.btnlnk + .section{padding-top:38px}
                 .btnlnk a:before{background:var(--theme-color)}

        /*Builder Fixes*/
                 .right-box .SECTION_LNGG .sortable-item{display:inline-block}
                 .right-box .SECTION_LNGG .sortable-item:first-child .nativeLangPara .singlecolumn{width:354px}
                 .right-box .SECTION_LNGG .paragraph{width:172px}
             .right-box .sortable-item:first-child + .sortable-item .section{padding-top:0 !important}
                 .sortable-item:first-child + .sortable-item .section.btnlnk{padding-top:0!important}
                 .sortable-item.data-BUTN + .sortable-item .section{padding-top:15px}
                .show-btn-gap .sortable-item.data-BUTN + .sortable-item .section{padding-top:38px}
    .resume-preview          .right-box .data-LNGG .lang-sec.infobarsec .paragraph{width:151px;max-width:151px}
                 .left-box .lang-sec .sortable-item:last-child .paragraph,         .right-box .lang-sec .sortable-item:nth-last-child(1) .paragraph,         .right-box .lang-sec .sortable-item:nth-last-child(2) .paragraph{padding-bottom:0!important}

    /*Rectangular Rating Blocks*/
                 .paragraph .sliced-rect .sliced-rect-tile.ratvfill{background-color:var(--theme-color)}
 .seperator {
  border-left: 1px solid #000;
  margin-left: 0; /* Remove left margin for closer alignment */
}

.section {
  margin-left: 10px; /* Add a little margin to create some space from the separator */
}

td {
  vertical-align: top;
  padding: 10px;
}

td:first-child {
  width: 60%;
  padding-right: 20px;
}

td:last-child {
  width: 40%;
  padding-left: 10px; /* Reduce left padding */
}

      </style>
        `;
    const contentElement = document.getElementById('downloadToWord').innerHTML;
    const contentWithStyles = cssStyles + contentElement;
    const docx = htmlDocx.asBlob(contentWithStyles, { orientation: 'portrait' });
    saveAs(docx, 'resume.docx');
  };
  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      <div id="pdfContainer" ref={divRef} onInput={(e) =>
        setHtmlContent(e.target.innerHTML)} style={{ padding: '10px' }}>
        {respokData ? (
          <div class="template-preview" data-skinid="MLD9" data-skincd="MLD9" title="">

            <div class="render-preview">
              <div class="" tabindex="">
                <div></div>
                <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mld7 MLD9  RNA  " docskinwidth="562">
                  <div id="CONTAINER_PARENT_0" class="parent-container">
                    <div id="CONTAINER_0" class="left-box">
                      <div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-CNTC">
                        <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section cntc-sec notdraggable SECTION_CNTC     " data-section-cd="CNTC">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="address mb175" style={{ marginTop: '15px' }}>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg">
                                          <img className="temp_icons" src={location_icon} />
                                        </div>
                                        <span className="ml10 mt-10 mt-align">{contact.city}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg">
                                          <img className="temp_icons" src={contact_icon} /></div>
                                        <span className="ml10 mt-10 mt-align">{contact.mobile}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_EMAI">
                                        <div className="icon_bg"><img className="temp_icons" src={mail_icon} /></div>
                                        <span className="ml10 mt-10 mt-align">{contact.email}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-HILT">
                        <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn maincolumn">
                                      {skills[0] && <div class="skill">
                                        <ul dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                      </div>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div id="CONTAINER_1" class="right-box" >
                      <div contentEditable={false} class="sortable-item section-container SortableItem-sibling  data-NAME mb175 " style={{ marginTop: '0px' }}>
                        <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section name-sec notdraggable SECTION_NAME     " data-section-cd="NAME">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                  <div>
                                    <div class="name sectiontitle mt15" style={{ fontSize: '26px' }} >
                                      <span style={{ fontsize: '36px' }} id="FIELD_FNAM">{contact.f_name}</span>
                                      <span style={{ fontSize: '36px !important' }} id="FIELD_LNAM">{contact.l_name}</span>
                                    </div>
                                  </div><br />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sortable-item section-container SortableItem-sibling  data-SUMM mb175">
                        <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn" id="FIELD_FRFM">
                                      <div> {contact.summery}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="sortable-item section-container SortableItem-sibling  data-EXPR mb175">
                        <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section expr-sec SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div>
                            </div>
                            <div class="">
                              <div class="sortableInner">
                                {experience?.map((item, index) => (
                                  <div className="mb75">

                                    <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                      <div class="clearfix doc-item">
                                        <div class="singlecolumn">
                                          <span class="disp-blk txt-bold" dependency="COMP|JTIT">
                                            <span class="company-name txt-capitalize" id="FIELD_COMP">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</span><span dependency="COMP+JTIT"> - </span>
                                            <span class="job-title txt-capitalize" id="FIELD_JTIT">{item.employer}</span>
                                          </span>
                                          <span class="disp-blk txt-italic" style={{ fontWeight: '600' }} >
                                            <span id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                            <span id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>,
                                            </span>
                                            <span className="jobdates" dependency="JSTD|EDDT">
                                              <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                              <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                            </span>
                                          </span>
                                        </div>
                                        <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                      </div>
                                    </div>
                                    {item.achievements != '[]' && item.achievements != '[null]' && (
                                      <div className="singlecolumn maincolumn mb25">
                                        <br />
                                        <ul>
                                          {JSON.parse(item.achievements)?.map((item1, index) => (
                                            item1 && <li key={index}>{item1}</li>
                                          ))}
                                        </ul>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="sortable-item section-container SortableItem-sibling  data-EDUC mb175">
                        <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " class="rename-section text-rename"></span></div>
                            </div>
                            {education?.map((item, index) => (
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn">

                                        <span class="disp-blk" dependency="DGRE|STUY">

                                          <span className="degree">
                                            {item.course} &nbsp;
                                            {item.specialization && <span className="degree">,
                                              &nbsp;{item.specialization} &nbsp;
                                            </span>}

                                            {item.university && <span className="jobdates">
                                              <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                            </span>}
                                            {item.year_of_completion && <span className="jobdates">
                                              <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                            </span>}
                                          </span>
                                        </span>

                                        <span class="jobline" id="FIELD_FRFM"></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item.achievements != '[]' && item.achievements != '[null]' && (
                                  <div className="singlecolumn maincolumn mb25">
                                    <br />
                                    <ul>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                        <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    <div className="singlecolumn maincolumn mb75">
                                      {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                        <div>
                                          <h4>Languages:</h4>
                                          {JSON.parse(more).language && JSON.parse(more)?.language?.map((item, index) => (
                                            <div id="PARAGRAPH_LNGG_756f3fd5-3415-d225-25f6-26433cdf5859" class="paragraph PARAGRAPH_LNGG  para_odd">
                                              <div class="clearfix doc-item">
                                                <div class="singlecolumn infobarpara">
                                                  <div class="field">
                                                    <span class="txt-bold" id="FIELD_FRFM">{item.language}</span><span class="colon" dependency="FRFM">:</span>
                                                  </div>
                                                  <div class="rating-bar" dependency="RATV">
                                                    <div class="inner-rating" id="FIELD_RATV" type="width" style={{ width: `${item.sliderValue}%` }}>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ))}
                                        </div>
                                      }
                                    </div>

                                    {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                      <h4>Website & Profiles:</h4>
                                      <span className="paddedline" id="FIELD_SKC1">
                                        <div>
                                          {JSON.parse(more)?.website?.map((item, index) => (
                                            item.work && <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                                          ))}
                                          {JSON.parse(more)?.website?.map((item, index) => (
                                            item.portfolio && <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                                          ))}
                                          {JSON.parse(more)?.website?.map((item, index) => (
                                            item.profile && <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                                          ))}
                                        </div>
                                      </span>
                                    </div>}
                                    {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                      {JSON.parse(more)?.custom?.map((item, index) => (
                                        <div>
                                          <h4>{item.custom_name}</h4>
                                          <span className="paddedline" id="FIELD_SKC1">
                                            <p>
                                              {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                            </p>
                                          </span>
                                        </div>
                                      ))}
                                    </div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>}
                    </div></div></div>
                <div></div>
              </div></div>
          </div>
        ) : (
          <Loading />
        )}
      </div>

      <div id="downloadToWord" onInput={(e) =>
        setHtmlContent(e.target.innerHTML)} style={{ padding: '10px', display: 'none' }}>
        {respokData ? (

          <div class="template-preview" data-skinid="MLD9" data-skincd="MLD9" title="">

            <div class="render-preview">
              <div class="" tabindex="">
                <div></div>
                <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mld7 MLD9  RNA  " docskinwidth="562">
                  <div id="CONTAINER_PARENT_0" class="parent-container">
                    <table>
                      <tr>
                        <td>
                          <div id="CONTAINER_0" class="left-box">
                            <div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-CNTC">
                              <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section cntc-sec notdraggable SECTION_CNTC     " data-section-cd="CNTC">
                                <div class=" doc-item">
                                  <div class="">
                                    <div class="">
                                      <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                        <div class="clearfix doc-item">
                                          <div class="address mb175" style={{ marginBottom: '15px', }}>
                                            <div className="mb75" id="FIELD_CPHN">
                                              <div className="icon_bg">
                                                <img className="temp_icons" width={'12'} height={'12'} src={location_icon} />
                                              </div>
                                              <span className="ml10 mt-10 mt-align">{contact.city}</span>
                                            </div>
                                            <div className="mb75" id="FIELD_CPHN">
                                              <div className="icon_bg">
                                                <img className="temp_icons" src={contact_icon} /></div>
                                              <span className="ml10 mt-10 mt-align">{contact.mobile}</span>
                                            </div>
                                            <div className="mb75" id="FIELD_EMAI">
                                              <div className="icon_bg"><img className="temp_icons" src={mail_icon} /></div>
                                              <span className="ml10 mt-10 mt-align">{contact.email}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div data-react-beautiful-dnd-draggable="3" class="sortable-item section-container SortableItem-sibling  data-HILT">
                              <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                                <div class=" doc-item">
                                  <div class="heading">
                                    <div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div>
                                  </div>
                                  <div class="">
                                    <div class="">
                                      <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                        <div class="clearfix doc-item">
                                          <div class="singlecolumn maincolumn">
                                            {skills[0] && <div class="skill">
                                              <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                            </div>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </td>

                        <td class="seperator">
                          <div class="seperator"></div>
                          <div id="CONTAINER_1" class="right-box" >
                            <div contentEditable={false} class="sortable-item section-container SortableItem-sibling  data-NAME mb175 " style={{ marginTop: '0px' }}>
                              <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section name-sec notdraggable SECTION_NAME     " data-section-cd="NAME">
                                <div class=" doc-item">
                                  <div class="">
                                    <div class="">
                                      <div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                        <div>
                                          <div class="name sectiontitle mt15" style={{ fontSize: '26px' }} >
                                            <span style={{ fontsize: '36px' }} id="FIELD_FNAM">{contact.f_name}</span>
                                            <span style={{ fontSize: '36px !important' }} id="FIELD_LNAM">{contact.l_name}</span>
                                          </div>
                                        </div><br />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="sortable-item section-container SortableItem-sibling  data-SUMM mb175">
                              <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                                <div class=" doc-item">
                                  <div class="heading">
                                    <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div>
                                  </div>
                                  <div class="">
                                    <div class="">
                                      <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                        <div class="clearfix doc-item">
                                          <div class="singlecolumn" id="FIELD_FRFM">
                                            <div> {contact.summery}</div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="sortable-item section-container SortableItem-sibling  data-EXPR mb175">
                              <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section expr-sec SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                                <div class=" doc-item">
                                  <div class="heading">
                                    <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div>
                                  </div>
                                  <div class="">
                                    <div class="sortableInner">
                                      {experience?.map((item, index) => (
                                        <div className="mb75">

                                          <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                            <div class="clearfix doc-item">
                                              <div class="singlecolumn">
                                                <span class="disp-blk txt-bold" dependency="COMP|JTIT">
                                                  <span class="company-name txt-capitalize" id="FIELD_COMP">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</span><span dependency="COMP+JTIT"> - </span>
                                                  <span class="job-title txt-capitalize" id="FIELD_JTIT">{item.employer}</span>
                                                </span>
                                                <span class="disp-blk txt-italic" style={{ fontWeight: '600' }} >
                                                  <span id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                                  <span id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>,
                                                  </span>
                                                  <span className="jobdates" dependency="JSTD|EDDT">
                                                    <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                                    <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                                  </span>
                                                </span>
                                              </div>
                                              <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                            </div>
                                          </div>
                                          {item.achievements != '[]' && item.achievements != '[null]' && (
                                            <div className="singlecolumn maincolumn mb25">
                                              <br />
                                              <ul>
                                                {JSON.parse(item.achievements)?.map((item1, index) => (
                                                  item1 && <li key={index}>{item1}</li>
                                                ))}
                                              </ul>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="sortable-item section-container SortableItem-sibling  data-EDUC mb175">
                              <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                                <div class=" doc-item">
                                  <div class="heading">
                                    <div class="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " class="rename-section text-rename"></span></div>
                                  </div>
                                  {education?.map((item, index) => (
                                    <div class="">
                                      <div class="">
                                        <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                          <div class="clearfix doc-item">
                                            <div class="singlecolumn">

                                              <span class="disp-blk" dependency="DGRE|STUY">

                                                <span className="degree">
                                                  {item.course} &nbsp;
                                                  {item.specialization && <span className="degree">,
                                                    &nbsp;{item.specialization} &nbsp;
                                                  </span>}

                                                  {item.university && <span className="jobdates">
                                                    <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                                  </span>}
                                                  {item.year_of_completion && <span className="jobdates">
                                                    <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                                  </span>}
                                                </span>
                                              </span>

                                              <span class="jobline" id="FIELD_FRFM"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {item.achievements != '[]' && item.achievements != '[null]' && (
                                        <div className="singlecolumn maincolumn mb25">
                                          <br />
                                          <ul>
                                            {JSON.parse(item.achievements)?.map((item1, index) => (
                                              item1 && <li key={index}>{item1}</li>
                                            ))}
                                          </ul>
                                        </div>
                                      )}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                              <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                                <div className=" doc-item">
                                  <div className="heading">
                                    <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                  </div>
                                  <div className="">
                                    <div className="">
                                      <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                        <div className="clearfix doc-item">
                                          <div className="singlecolumn maincolumn mb75">
                                            {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                              <div>
                                                <h4>Languages:</h4>
                                                {JSON.parse(more).language && JSON.parse(more)?.language?.map((item, index) => (
                                                  <div id="PARAGRAPH_LNGG_756f3fd5-3415-d225-25f6-26433cdf5859" class="paragraph PARAGRAPH_LNGG  para_odd">
                                                    <div class="clearfix doc-item">
                                                      <div class="singlecolumn infobarpara">
                                                        <div class="field">
                                                          <span class="txt-bold" id="FIELD_FRFM">{item.language}</span><span class="colon" dependency="FRFM">:</span>
                                                        </div>
                                                        <div class="rating-bar" dependency="RATV">
                                                          <div class="inner-rating" id="FIELD_RATV" type="width" style={{ width: `${item.sliderValue}%` }}>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            }
                                          </div>

                                          {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                            <h4>Website & Profiles:</h4>
                                            <span className="paddedline" id="FIELD_SKC1">
                                              <div>
                                                {JSON.parse(more)?.website?.map((item, index) => (
                                                  item.work && <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                                                ))}
                                                {JSON.parse(more)?.website?.map((item, index) => (
                                                  item.portfolio && <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                                                ))}
                                                {JSON.parse(more)?.website?.map((item, index) => (
                                                  item.profile && <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                                                ))}
                                              </div>
                                            </span>
                                          </div>}
                                          {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                            {JSON.parse(more)?.custom?.map((item, index) => (
                                              <div>
                                                <h4>{item.custom_name}</h4>
                                                <span className="paddedline" id="FIELD_SKC1">
                                                  <p>
                                                    {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                                  </p>
                                                </span>
                                              </div>
                                            ))}
                                          </div>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>}
                          </div>
                        </td>
                      </tr>
                    </table></div></div>
                <div></div>
              </div></div>
          </div>

        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
});
export default Dynamictemp1;
