import React, { useEffect, useState } from "react";
import Dynamictemp1 from "./Dynamictemp1";
import Dynamictemp2 from "./Dynamictemp2";
import Dynamictemp3 from "./Dynamictemp3";
import Dynamictemp4 from "./Dynamictemp4";
import Dynamictemp5 from "./Dynamictemp5";
import Dynamictemp6 from "./Dynamictemp6";
import Dynamictemp7 from "./Dynamictemp7";
import Dynamictemp8 from "./Dynamictemp8";
import Dytemp1 from "./Dytemp1";

function Formtemplate() {
	// const [tabsData, setTabsData] = useState([]);
	const template = localStorage.getItem('template');
	


	useEffect(() => {
		import('./Css/Formtemplate.scss');
	}, []);



	return (
		<div className="formtemplate" contenteditable="false">
      {template == 1 && <Dynamictemp1 contentNotEditable />}
      {template == 2 && <Dynamictemp2 contentNotEditable />}
      {template == 3 && <Dynamictemp3 contentNotEditable />}
      {template == 4 && <Dynamictemp4 contentNotEditable />}
      {template == 5 && <Dynamictemp5 contentNotEditable />}
      {template == 6 && <Dynamictemp6 contentNotEditable />}
      {template == 7 && <Dynamictemp7 contentNotEditable />}
      {template == 8 && <Dynamictemp8 contentNotEditable />}
      {template == 9 && <Dytemp1 />}
      {!template && <Dytemp1 />}
		</div>
	);
}

export default Formtemplate;