import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Top_1 from "./Assets/interview/top.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import MainNav from "./MainNav";
import Footer from "./Footer";
import "./Assets/CSS/aos.css";
import "./Assets/CSS/glightbox.min.css";
import "./Assets/CSS/swiper-bundle.min.css";
import "./Assets/CSS/style.css";
import LoadingGIF from './LoadingGIF';
import Loading from './Loading';
import CoverModal from './CoverModal';
import ResumeInterviewModal from './ResumeInterviewModal';
import { FileUploader } from "react-drag-drop-files";
import axios from "axios";
import { commonConfig } from '../config.js';

function InterviewStep1() {
  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [boxopen, setBoxopen] = useState(false);
  const [resumeopen, setResumeopen] = useState(false);
  const userId = localStorage.getItem('userId');
  const [errors, setErrors] = useState([]);
  const [coverData, setCoverData] = useState(null);
  const fileTypes = ["JPG", "PNG", "GIF"];
  const interviewResumeId = localStorage.getItem('interviewResumeId');
  const [formData, setFormData] = useState({
    company: '',
    job_applied_to: '',
    department: '',
    location: '',
    level: '',
    description: '',
    reporting_to: '',
    position_ctc: '',
    kra: '',
    date_of_interview: '',
    hr_name: '',
    cover_id: '',
    isEdit:0,
    resume_id:interviewResumeId,
    userId:userId,
  });
  useEffect(() => {
    import('./Css/Interview.scss');
    import('./Css/InterviewStep.scss');
    // import('./Assets/CSS/bootstrap.min.css');
    setTimeout(() => {
      setPage(true);
      setBoxopen(true);
    }, 1500);
  }, []);

  const handleChildData = (data) => {
    // setCoverData(data);
    setFormData(prevFormData => ({
      ...prevFormData,
      company: data.company,
      department: data.department,
      location: data.location,
      job_applied_to: data.job_applied_to,
      level: data.level,
      reporting_to: data.reporting_to,
      description: data.description,
      position_ctc: data.position_ctc,
      hr_name: data.hr_name,
      cover_id: data.id
    }));
  };
  const handleInterviewChildData = (id) => {
    setResumeopen(false);
    if(id != 0){
      setFormData(prevFormData => ({
        ...prevFormData,
        resume_id: id
      }));
    }
    
  };

  const openResumeBoxOpen = () => {
    if (!userId) {
      navigate('/login');
    }
    setResumeopen(true);
  };

  const handleInputChange = (event) => {
		const { id, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[id]: value
		}));
		validate();
	};

  const validate = () => {
		let errorObj = {};
		const newErrors = [];
		if (formData.company == '') {
			errorObj.company = "Company Name required.";
		} else if (formData.job_applied_to == '') {
			errorObj.job_applied_to = "Job Applied To required.";
		} else if (formData.department == '') {
			errorObj.department = "Department required.";
		} else if (formData.location == '') {
			errorObj.location = "Location required.";
		} else if (formData.level == '') {
			errorObj.level = "Level required.";
		} else if (formData.description == '') {
			errorObj.description = "Description required.";
		} else if (formData.hr_name == '') {
			errorObj.hr_name = "Name of the HR required.";
		} else if (formData.hr_email == '') {
			errorObj.hr_email = "Email of the HR required.";
		} 
		setErrors(errorObj);
		if (Object.keys(errorObj).length > 0) {
			console.log("errorObj", errorObj); return false;
		}
		return true;
	};
  const [file, setFile] = useState(null);
  const handleChange = (file) => {
    setFile(file);
  };
  const handleNext = () => {
    if (validate()) {
			// setPage(false);
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "interviewStep1",
				data: formData,
				headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + commonConfig.API_TOKEN, "user_id": userId},
			}).then(res => {
				var decodedData = res.data;
				var status = res.status;
				if (status) {
          setPage(true);
          localStorage.setItem('interviewId', decodedData.data.id);
          navigate('/interviewStep2');
				}
			}).catch(err => {

			});
    }
  };

  
  return (

    <div className="interviewSteppage interviewpage" style={{backgroundColor:'#F5F5F5'}}>
      {page ? (
        <div>
          <MainNav value="7" />
          <section className="progressTop">
              <ol class="ProgressBar" style={{marginTop:'3%'}}>
							 <li class="ProgressBar-step is-complete">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel"><b>Enter Details</b></span>
							 </li>
							 <li class="ProgressBar-step {{step2}}">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel">Verify Details</span>
							 </li>
							 <li class="ProgressBar-step {{step3}}">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel">Interview Preparation</span>
							 </li>
						 </ol>
          </section>
          <section className="banner1">
            <div className="step1_sec shadow">
              <div className="inner1_step1" style={{ textAlign: 'center' }}>
                <h3 className="headtag1"><span className="blue">Auto fill the below details by choosing the job you have applied </span>
                </h3>
              </div>
              <form action="" method="">
                <div className="col-md-12 w-80 m-auto w-100">
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="company" className="formcontrol" value={formData.company} onChange={handleInputChange} />
                          <label className="formlabel">
                            Company Name
                          </label>
                        </div>
                        {formData.company == '' ? (<img className="sideimage"  src={require('./Assets/interview/company.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/company_1.png')} />
                        )}
                      </div>
                      
                      {errors && errors.company && (
                        <span className="error">{errors.company}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="location" className="formcontrol" value={formData.location} onChange={handleInputChange} />
                            <label className="formlabel">
                              Job Location
                            </label>
                        </div>
                        {formData.location == '' ? (<img className="sideimage"  src={require('./Assets/interview/location.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/location_1.png')} />
                        )}
                      </div>
                      {errors && errors.location && (
                        <span className="error">{errors.location}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="department" className="formcontrol" value={formData.department} onChange={handleInputChange} />
                            <label className="formlabel">
                              Department (for ex: Human Resource, Marketing) 
                            </label>
                        </div>
                        {formData.department == '' ? (<img className="sideimage"  src={require('./Assets/interview/depart.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/depart_1.png')} />
                        )}
                      </div>
                      {errors && errors.department && (
                        <span className="error">{errors.department}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="level" className="formcontrol" value={formData.level} onChange={handleInputChange} />
                            <label className="formlabel">
                              Level as per the company
                            </label>
                        </div>
                        {formData.level == '' ? (<img className="sideimage"  src={require('./Assets/interview/level.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/level_1.png')} />
                        )}
                      </div>
                      
                      
                      {errors && errors.level && (
                        <span className="error">{errors.level}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="job_applied_to" className="formcontrol" value={formData.job_applied_to} onChange={handleInputChange} />
                            <label className="formlabel">
                              Designation as per the company
                            </label>
                        </div>
                        {formData.job_applied_to == '' ? (<img className="sideimage"  src={require('./Assets/interview/desi.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/desi_1.png')} />
                        )}
                      </div>
                      {errors && errors.job_applied_to && (
                        <span className="error">{errors.job_applied_to}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="reporting_to" className="formcontrol" value={formData.reporting_to} onChange={handleInputChange} />
                            <label className="formlabel">
                              Job Reporting to (eg. Manager, CEO) - optional
                            </label>
                        </div>
                        {formData.reporting_to == '' ? (<img className="sideimage"  src={require('./Assets/interview/reportingto.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/reportingto_1.png')} />
                        )}
                      </div>
                      
                      {errors && errors.reporting_to && (
                        <span className="error">{errors.reporting_to}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <textarea className="formcontrol"
                              id="description"
                              onChange={handleInputChange}
                              value={formData.description}
                              style={{ height: "140px", padding: '10px' }}>
                            </textarea>
                            <label className="formlabel">
                              Job Description
                            </label>
                        </div>
                        {formData.description == '' ? (<img className="sideimage"  src={require('./Assets/interview/desc.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/desc_1.png')} />
                        )}
                      </div>
                      
                      {errors && errors.company && (
                        <span className="error">{errors.description}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="kra" className="formcontrol" value={formData.kra} onChange={handleInputChange} />
                            <label className="formlabel">
                              KRA’s (eg. Leading the sales team)
                            </label>
                        </div>
                        {formData.kra == '' ? (<img className="sideimage"  src={require('./Assets/interview/kra.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/kra_1.png')} />
                        )}
                      </div>
                      
                      {errors && errors.kra && (
                        <span className="error">{errors.kra}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="position_ctc" className="formcontrol" value={formData.position_ctc} onChange={handleInputChange} />
                            <label className="formlabel">
                              CTC Range - Optional
                            </label>
                        </div>
                        {formData.position_ctc == '' ? (<img className="sideimage"  src={require('./Assets/interview/ctc.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/ctc_1.png')} />
                        )}
                      </div>
                      
                      {errors && errors.position_ctc && (
                        <span className="error">{errors.position_ctc}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="date_of_interview" className="formcontrol" value={formData.date_of_interview} onChange={handleInputChange} />
                            <label className="formlabel">
                              Date of Interview - Optional
                            </label>
                        </div>
                        {formData.date_of_interview == '' ? (<img className="sideimage"  src={require('./Assets/interview/date.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/date_1.png')} />
                        )}
                      </div>
                      {errors && errors.date_of_interview && (
                        <span className="error">{errors.date_of_interview}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <div className="flex">
                        <div className="w-90">
                          <input type="text" id="hr_name" className="formcontrol" value={formData.hr_name} onChange={handleInputChange} />
                            <label className="formlabel">
                              Interviewer Name - Optional
                            </label>
                        </div>
                        {formData.hr_name == '' ? (<img className="sideimage"  src={require('./Assets/interview/inte_name.png')} />) : (
                          <img className="sideimage"  src={require('./Assets/interview/inte_name_1.png')} />
                        )}
                      </div>
                          
                      {errors && errors.hr_name && (
                        <span className="error">{errors.hr_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="formgroup">
                      <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
                    </div>
                    <p style={{textAlign:'center'}}>-OR-</p>
                    <div className="formgroup" style={{textAlign:'center'}}>
                      <div className="blue bold pointer" onClick={openResumeBoxOpen}>Select from your saved Resume</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 w-80 m-auto w-100 flex" style={{justifyContent: 'space-around'}}>
                    <button id="btnBack" onClick={handleNext} type="button" className="btn-mangaii" >
                      Continue
                    </button>
                </div>
              </form>
            </div>
          </section>

          {boxopen &&
            <CoverModal sendDataToParent={handleChildData}/>
          }
          {resumeopen &&
            <ResumeInterviewModal sendDataToInterviewParent={handleInterviewChildData}/>
          }

          <Footer />
        </div>
      ) : (
        <Loading content="" />
      )}
    </div>

  );
}

export default InterviewStep1;
