import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import logo from "../img/logo.png";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import footer_logo from "./Assets/home/footer_logo.png";
import facebook from "./Assets/home/facebook.png";
import instagram from "./Assets/home/instagram.png";
import linkedin from "./Assets/home/linkedin.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import axios from "axios";

function StepsFooter(props) {
  const [formData, setFormData] = useState({
		email: ''
	});
  const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};


  const handleSubmit = (event) => {
		event.preventDefault();

		if (formData.email == '') {
			toast.error('EmailAddress Required!!!');
		}else {
			const functionThatReturnPromise = () => new Promise((resolve, reject) => {
				axios({
					method: "POST",
					url: commonConfig.REACT_APP_API_URL + "home_mail",
					data: formData,
					headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

				}).then(res => {
					var decodedData = res.data;
					var status = res.status;
					if (status) {
						resolve(status);
					}

				}).catch(err => {
					reject();
				});
			});

			toast.promise(
				functionThatReturnPromise,
				{
					pending: 'Please Wait...',
					success: 'Thanks For Submitting. Our Team will contact you soon !!!!',
					error: 'Failed 🤯'
				},
			)
		}
	};
	return (
		  <section className="center_1 bold sticky_footer_1 pale_blue_bg" style={{ marginTop: '5%', minHeight: '50px' }}>
				<div className="col-lg-12 footer_sec">
          <p className="blue footer_nav"><Link to="/home">Home</Link>  &nbsp;&nbsp;|&nbsp;&nbsp;  <Link to="/step14">Dashboard</Link>  &nbsp;&nbsp;|&nbsp;&nbsp; <Link to="/contactus">Need Help?</Link></p>
				</div>
			</section>
	);
}

export default StepsFooter;


