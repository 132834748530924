// chatGPTApi.ts

import axios from 'axios';
import { commonConfig } from '../../config.js'; // Import your common configuration file here

export const chatGPT = async (question) => {
  try {
    const apiUrl = commonConfig.GPT_API_URL;
    const apiKey = commonConfig.GPT_API_KEY;
    const requestBody = {
      "model": "gpt-3.5-turbo",
      "messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
      "temperature": 0.7
    };
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    };
    const { data } = await axios.post(apiUrl, requestBody, { headers });
    const result = data.choices[0].message.content;
    const json = JSON.parse(result);

    return json;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const chatGPTWithPage = async (question) => {
  try {
    const response = await chatGPT(question);
    // Do something with the response
    return response;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

export const chatGPTWithResponseData = async (question) => {
  try {
    const response = await chatGPT(question);
    // Do something with the response data and return the specific data you need
    const responseData = response.bullet_points; // Replace with your actual data extraction logic
    return responseData;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
