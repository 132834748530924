import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link,useNavigate } from 'react-router-dom';
import Robot from "./Assets/Robot_1.png";
import Ellipse from "./Assets/Ellipse.png";
import Top_1 from "./Assets/cover/Top_1.png";
import section_1_1 from "./Assets/icons/section_1_1.png";
import section_1_2 from "./Assets/icons/section_1_2.png";
import section_1_3 from "./Assets/icons/section_1_3.png";
import section_1_4 from "./Assets/icons/section_1_4.png";
import Layer from "./Assets/Layer.png";
import section_2 from "./Assets/home/section_2.png";
import section_3 from "./Assets/home/section_3.png";
import section_4 from "./Assets/home/section_4.png";
import section_5 from "./Assets/home/section_5.png";
import section_6 from "./Assets/home/section_6.png";
import line from "./Assets/home/line.png";
import MainNav from "./MainNav";
import Footer from "./Footer";
import "./Assets/CSS/aos.css";
import "./Assets/CSS/glightbox.min.css";
import "./Assets/CSS/swiper-bundle.min.css";
import "./Assets/CSS/style.css";
import LoadingGIF from './LoadingGIF';
import Loading from './Loading';
import ResumeModal from './ResumeModal';

function Cover() {
  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [boxopen, setBoxopen] = useState(false);
  const userId = localStorage.getItem('userId');
  useEffect(() => {

    import('./Css/Cover.scss');
    // import('./Assets/CSS/bootstrap.min.css');
    setTimeout(() => {
      setPage(true);
    }, 1500);
  }, []);

  const openResumeBoxOpen = () => {
    if (!userId) {
      navigate('/login');
    }
    setBoxopen(true);
  };

  const handleInterviewChildData = (id) => {
    setBoxopen(false);
  };

  return (

    <div className="home coverpage">
      {page ? (
        <div>
          <MainNav value="2" />
          <section className="banner">
            <div className="col-lg-6" >
              <div className="banner_left">
                <h1 className="head1 grey">Get Your</h1>
                <h1 className="head3 blue">cover letter Free!</h1>
                <h3 className="head4 blue">on creating your resume with Gojobs.ai</h3>
                <p className="p_tag" style={{ marginTop: '0%' }}>Let your cover letter be your voice in the job market!<br /> Are you ready to make a lasting impression on potential employers? <br /> create your cover letter with the power of ai!</p>

                <button name="create" onClick={openResumeBoxOpen} className="btn btn-blue">Create now for FREE </button>
              </div>
            </div>

            <div className="col-lg-6">
              <img className="top_1" src={Top_1} alt="song" />
            </div>
          </section>
          <section className="section-2 section_sub">
            <div class="container lic_margins">
              <div class="dotted-rectangle">
                <div class="top-images">
                  <img src={require('./Assets/cover/s_top_1.png')} alt="Image 1" />
                  <div className="tagline1">Simple Steps for a standout cover letter</div>
                </div>
                <div class="bottom-images">
                  <div>
                    <img src={require('./Assets/cover/Create_icon.png')} alt="Image 1" />
                    <div className="tagline2">Create your resume</div>
                  </div>
                  <div>
                    <img src={require('./Assets/cover/Select_icon.png')} alt="Image 1" />
                    <div className="tagline2">Select your cover letter preference</div>
                  </div>
                  <div>
                    <img src={require('./Assets/cover/Download_icon.png')} alt="Image 1" />
                    <div className="tagline2">Download your cover letter</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-2 section_sub">
            <h3 className="headtag" style={{ position: 'relative' }}>
              <img className="stop2" src={require('./Assets/cover/s_top_2.png')} alt="Image 1" />
              <div className="tagline3">What you get!</div>
            </h3>
            <div className="col-lg-6 center">
              <img className="sec2_image" src={require('./Assets/cover/sub_1.png')} />
            </div>
            <div className="col-lg-6 center">
              <div className="content_area">
                <h4 className="grey" style={{ fontWeight: '700', fontSize: 'larger' }}>Cover Letter</h4>
                <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Matching Your Resume</h4>
                <p className="p_tag">
                  You get a cover letter that aligns seamlessly with the content of your resume. <br /> Remember recruiter wants consistency from the candidates.
                </p>

              </div>
            </div>
          </section>
          <section className="section-2 section_sub">
            <div className="sec system_view">
              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="grey" style={{ fontWeight: '700', fontSize: 'larger' }}>Cover Letter</h4>
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Curated for the applying job</h4>
                  <p className="p_tag">
                    Crafting a curated cover letter for each job application is crucial step and we make it easy for you!
                  </p>
                </div>
              </div>
              <div className="col-lg-6 center">
                <img className="sec2_image" src={require('./Assets/cover/sub_2.png')} />
              </div>
            </div>
            <div className="sec mobile_view">

              <div className="col-lg-6 center">
                <img className="sec2_image" src={require('./Assets/cover/sub_2.png')} />
              </div>
              <div className="col-lg-6 center">

                <div className="content_area">
                  <h4 className="grey" style={{ fontWeight: '700', fontSize: 'larger' }}>Cover Letter</h4>
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Curated for the applying job</h4>
                  <p className="p_tag">
                    Crafting a curated cover letter for each job application is crucial step and we make it easy for you!
                  </p>
                </div>
              </div>

            </div>
          </section>



          <section className="section-2 section_sub">
            <div className="sec">
              <div className="col-lg-6 center">
                <img className="sec2_image" src={require('./Assets/cover/sub_3.png')} />
              </div>
              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="grey" style={{ fontWeight: '700', fontSize: 'larger' }}>Cover Letter</h4>
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>Customized with tone and size</h4>
                  <p className="p_tag">
                    You have the control over your cover letter length and tone. Just select your preference and get it per your requirement.
                  </p>
                </div>
              </div>

            </div>
          </section>

          <section className="section-2 section_sub">
            <div className="sec system_view">

              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="grey" style={{ fontWeight: '700', fontSize: 'larger' }}>Cover Letter</h4>
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>With the power of ai</h4>
                  <p className="p_tag">
                    AI can tailor cover letters to specific job requirements by analyzing keywords and incorporating them seamlessly
                  </p>
                </div>
              </div>
              <div className="col-lg-6 center">
                <img className="sec2_image" src={require('./Assets/cover/sub_4.png')} />
              </div>
            </div>
            <div className="sec mobile_view">
              <div className="col-lg-6 center">
                <img className="sec2_image" src={require('./Assets/cover/sub_4.png')} />
              </div>
              <div className="col-lg-6 center">
                <div className="content_area">
                  <h4 className="grey" style={{ fontWeight: '700', fontSize: 'larger' }}>Cover Letter</h4>
                  <h4 className="blue" style={{ fontWeight: '700', fontSize: 'larger' }}>With the power of ai</h4>
                  <p className="p_tag">
                    AI can tailor cover letters to specific job requirements by analyzing keywords and incorporating them seamlessly
                  </p>
                </div>
              </div>

            </div>
          </section>
          {boxopen &&
            <ResumeModal sendDataToInterviewParent={handleInterviewChildData}/>
          }

          <Footer />
        </div>
      ) : (
        <Loading content="" />
      )}
    </div>

  );
}

export default Cover;
