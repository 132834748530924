import React, { Component, useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
// import {DatePicker} from '@gsebdev/react-simple-datepicker';
import { ToastContainer, toast } from 'react-toastify';
// import 'react-datepicker/dist/react-datepicker.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Nav from "./Nav";
import { format, startOfDay, endOfDay, parse } from 'date-fns';
import { commonConfig } from '../config.js';
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import Formtemplate from "./Formtemplate";

function Step2() {
  const { exp_id } = useParams();
  const resume_data = localStorage.getItem('resume_data');
  const resume_id = localStorage.getItem('resume_id');
  const temp_resume_id = localStorage.getItem('temp_resume_id');
  const parsed_json_data = localStorage.getItem('parsed_json_data');
  const local_eduindex = localStorage.getItem('eduindex');
  const local_tempexpindex = localStorage.getItem('tempexpindex');

  const [formData, setFormData] = useState([
    {
      title: '',
      employer: '',
      city: '',
      country: '',
      working: false,
      customize: false,
      intern: false,
      description: '',
      achievements: [], // Array to store multiple achievements
      key_skills: '',
      start_date: null,
      end_date: null,
      title_1: '',
      department_1: '',
      company_1: '',
      location_1: '',
      description_1: '',
    }
  ]);

  const [formData2, setFormData2] = useState([{ acheivements: '', }]);
  const [selectedid, setSelectedid] = useState(100);
  const navigate = useNavigate();
  const [exp, setExpData] = useState([]);
  const userId = localStorage.getItem('userId');
  const [stepno, setStepno] = useState(2);
  const [is_edit, setIs_edit] = useState(0);
  const [page, setPage] = useState(false);
  const [customize, setCustomize] = useState(false);
  const [showform, setShowform] = useState(true);
  const [eduindex, setEduindex] = useState(0);
  const [tempexpindex, setTempexpindex] = useState(0);
  const [errors, setErrors] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let edi_index1=0;
  let tempexp_index1=0;
  const CommonData = localStorage.getItem('common_data');

  let title_1='';
  let department_1='';
  let company_1='';
  let location_1='';
  let description_1='';
   

  const currentYear = new Date().getFullYear();
  const lastTenYears = Array.from({ length: 30 }, (_, index) => currentYear - index);

  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }

    
    // localStorage.removeItem('eduindex');
    addMoreAchievements(0);
    import('./Css/Step2.scss');
    import('./Css/datepicker.scss');
    if (screenWidth < 900) {
      setShowform(false);
    }
    if (resume_id) {

      if(local_eduindex){
        edi_index1 = local_eduindex
      }
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        const experience = result.experience;
        const diff = result.diff;
        const contact = result.contact[0];
        if(experience.length > 0){
          const customize = result.skills[0].summery;
          let customize1 = JSON.parse(customize);
          title_1 = customize1.title_1;
          department_1 = customize1.department_1;
          company_1 = customize1.company_1;
          location_1 = customize1.location_1;
          description_1 = customize1.description_1;

          if(diff > 12){
            if (CommonData) {
              if (JSON.parse(CommonData).plan.pack_status == 1) {
                
              }else{
                navigate('/plansfront');
              } 
            }
          }

          setFormData([
            {
              title: '',
              employer: '',
              city: '',
              country: '',
              working: false,
              customize: false,
              intern: false,
              description: '',
              achievements: [], // Array to store multiple achievements
              key_skills: '',
              start_date: null,
              end_date: null,
              title_1: title_1,
              department_1: department_1,
              company_1: company_1,
              location_1: location_1,
              description_1: description_1,
            }
          ]);
        }
        

        console.log("customize",customize);
        if (contact.step === 100) {
          setStepno(contact.step);
        }else if(contact.step === 2) {
          setStepno(contact.step);
        }else{
          setStepno(contact.step + 1);
        }

        if (experience.length > 0 && exp_id) {
          setIs_edit(1);
          setExpData(experience);
          const updatedFormData = experience.map((item) => {
            if (item.id == exp_id) {
              return {
                ...item,
                title_1: title_1,
                department_1: department_1,
                company_1: company_1,
                location_1: location_1,
                description_1: description_1,
                achievements: JSON.parse(item.achievements),
              };
            }
          });
          const h = updatedFormData.filter(item => item !== null);
          const i = h.filter(item => item !== undefined);
          setFormData(i);
          // addMoreAchievements(0);
        }else {

          if (parsed_json_data != undefined) {
            const parsed_exp = JSON.parse(parsed_json_data)?.experience;
            const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
            const updatedFormData = parsed_exp.map((item,index) => {
              if(index == edi_index1){
                const isStartDateValid = dateRegex.test(item.start_date);
                const isEndDateValid = dateRegex.test(item.end_date);
                const startDate = isStartDateValid ? item.start_date : null;
                const endDate = isEndDateValid ? item.end_date : null;

                setEduindex(edi_index1);
                return {
                  ...item,
                  title: item.title,
                  employer: item.company,
                  description: item.responsibilities,
                  country: 'India',
                  working: false,
                  intern: false,
                  achievements: [],
                  key_skills: '',
                  start_date: startDate,
                  end_date: endDate,
                  title_1: title_1,
                  department_1: department_1,
                  company_1: company_1,
                  location_1: location_1,
                  description_1: description_1,
                };
                
              }

            });
            
            const filteredFormData = updatedFormData.filter(item => item !== undefined);
            console.log("updatedFormData",parsed_exp);
            if(filteredFormData.length != 0){
              setFormData(filteredFormData);
            }
            
          }
          get_temp_data(temp_resume_id);
        }

      }).catch(err => {

      });
    }
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const get_temp_data = (resume_id) => {
    if(local_tempexpindex){
      tempexp_index1 = local_tempexpindex;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      const experience = result.experience;
      const contact = result.contact[0];

      if (experience.length > 0) {
        setExpData(experience);
        const updatedFormData = experience.map((item,index) => {
          if(index == tempexp_index1){
            setTempexpindex(tempexp_index1);
            return {
              ...item,
              title_1: '',
              department_1: '',
              company_1: '',
              location_1: '',
              description_1: '',
              achievements: JSON.parse(item.achievements),
            }
          }
        });
        // console.log("updatedFormData1",updatedFormData);
        const filteredFormData1 = updatedFormData.filter(item => item !== undefined);
        if(filteredFormData1.length != 0){
          setFormData(filteredFormData1);
        }
      }

    }).catch(err => {

    });
  }


  const handleChange = (event, index) => {
    const { name, value } = event.target || event;

    if (name == 'working') {
      const { name, checked } = event.target || event;
      console.log("value", checked);
      if (checked) {
        setSelectedid(index);
      } else {
        setSelectedid(100);
      }
      const updatedFormData = [...formData];
      updatedFormData[index][name] = checked;
      setFormData(updatedFormData);
    } else if (name == 'customize') {
      const { name, checked } = event.target || event;
      if (checked) {
        setCustomize(true);
      } else {
        setCustomize(false);
      }
      const updatedFormData = [...formData];
      updatedFormData[index][name] = checked;
      setFormData(updatedFormData);
    } else if (name == 'intern') {
      const { name, checked } = event.target || event;
      const updatedFormData = [...formData];
      updatedFormData[index][name] = checked;
      setFormData(updatedFormData);
    } else {
      const updatedFormData = [...formData];
      updatedFormData[index][name] = value;
      setFormData(updatedFormData);
    }

    validate();
  };


  const handleDateChange = (date, index, field) => {

    const formattedDate = date ? format(startOfDay(date), 'yyyy-MM-dd') : null;
    const updatedFormData = [...formData];
    updatedFormData[index][field] = formattedDate;
    setFormData(updatedFormData);
    console.log(formData);
  };




  const handleAddField = () => {
    setFormData([...formData, { title: '', employer: '', city: '', country: '', description: '', working: false, achievements: [], key_skills: '', start_date: null, end_date: null }]);
  };

  const handleRemoveField = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };

  const updateAchievement = (index, achievementIndex, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index].achievements[achievementIndex] = value;
    setFormData(updatedFormData);
  };

  const addMoreAchievements = (index) => {
    console.log("joe");
    const updatedFormData = [...formData];
    updatedFormData[index].achievements.push('');
    setFormData(updatedFormData);
  };
  const removeAchievements = (index, index1) => {
    // console.log("index",index);
    const updatedFormData = [...formData];
    updatedFormData[index].achievements.splice(index1, 1);
    setFormData(updatedFormData);
  };

  const validate = () => {
    const newErrors = [];

    formData.forEach((item, index) => {
      let errorObj = {};
      if (!item.title) {
        errorObj.title = "Title required.";
      }
      //   if (!item.start_date) {
      //     errorObj.start_date = "Start Date required.";
      //   }
      if (!item.employer) {
        errorObj.employer = "Company Name required.";
      }
      if (!item.city) {
        errorObj.city = "City required.";
      }
      if (!item.description) {
        errorObj.description = "Description required.";
      }
      if (!item.key_skills) {
        errorObj.key_skills = "Keyskills required.";
      }
      newErrors.push(errorObj);
    });
    setErrors(newErrors);
    if (newErrors.some(error => Object.keys(error).length > 0)) {
      return false;
    }
    return true;
  }

  const handleSubmit = (event) => {

    event.preventDefault();
    if (validate()) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "experience/store",
        data: formData,
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": 2, "isedit": is_edit },

      }).then(res => {
        var decodedData = res.data;
        var status = res.status;
        localStorage.setItem('eduindex',parseInt(eduindex)+1);
        localStorage.setItem('tempexpindex',parseInt(tempexpindex)+1);
        navigate('/step7/' + decodedData.data);

      }).catch(err => {
        setPage(true);
        if (err.response.status === 422) {
          toast.error("Please fill all required fields");
        }
      });
    }

  };

  const formatDate = (date) => {
    // Format the date as you desire, e.g., using toLocaleString
    return date.toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };
  const Showform = () => {
    setShowform(true);
  };


  return (
    <div className="step2page stepspage">
      {page ? (
        <div>
          <ToastContainer position="top-center" theme="dark" />
          <main id="app" role="main">
            <section className="page-wrapper">
              <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
                <Nav value={stepno} />
                <div className="step1_sec">
                  <div className="inner2_step1">
                    {!showform &&<div className="col-lg-12 mobile_view">
                      <h3 className="headtag" onClick={Showform} style={{textAlign:'center'}}><span className="blue">Lets move on to Experience</span></h3>
                      <div className="api_right">
                        <Formtemplate />
                        <div className="col-lg-12 center">
                          <Link to="/template/step2" className="clickable p-10 bold">Change Template</Link>
                        </div>
                        <div className="col-lg-6 half" >
                          <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                        </div>
                        <div className="col-lg-6 half" >
                          <button id="btnBack" onClick={Showform} type="submit" className="btn-mangaii">Next</button>
                        </div>
                      </div>
                    </div>}
                    {showform &&<div className="col-md-12">
                      <div className="col-lg-7">
                        <div className="inner1_step1">
                          <h3 className="headtag"><span className="blue">Enter Your Work Experience</span>
                          </h3>
                          <p style={{ marginBottom: '3%', fontWeight: '200px' }} className="p_tag"><b>Please Provide Your Most Recent Job First.</b> You Can Also Add Voluntary Work, Internships and Extra-Curricular Activities.  </p>
                        </div>
                        {formData.map((field, index) => (
                          <div className="col-lg-12 each_section">
                            <div className="col-lg-6 full">
                              <div className="formgroup">
                                <input type="text" className="formcontrol"
                                  name="title"
                                  value={field.title}
                                  onChange={(event) => handleChange(event, index)} />
                                <label className="formlabel" htmlFor={`title${index}`}>
                                  JOB TITLE
                                </label>
                                {errors[index] && errors[index].title && (
                                  <span className="error">{errors[index].title}</span>
                                )}
                              </div>

                            </div>

                            <div className="col-lg-6 full">
                              <div className="formgroup">
                                <input type="text" className="formcontrol"
                                  name="employer"
                                  value={field.employer}
                                  onChange={(event) => handleChange(event, index)}
                                />
                                <label className="formlabel" htmlFor={`employer${index}`}>
                                  EMPLOYER
                                </label>
                                {errors[index] && errors[index].employer && (
                                  <span className="error">{errors[index].employer}</span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 half" style={{ clear: 'both' }}>
                              <div className="formgroup">

                                <input type="text" className="formcontrol"
                                  name="city"
                                  value={field.city}
                                  onChange={(event) => handleChange(event, index)} />
                                <label className="formlabel" htmlFor={`city${index}`}>
                                  CITY
                                </label>
                                {errors[index] && errors[index].city && (
                                  <span className="error">{errors[index].city}</span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 half">
                              <div className="formgroup">
                                <input type="text" className="formcontrol"
                                  name="country"
                                  value={field.country}
                                  onChange={(event) => handleChange(event, index)} />
                                <label className="formlabel" htmlFor={`country${index}`}>
                                  COUNTRY
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-6 half" style={{ clear: 'both' }}>
                              <div className="formgroup">
                                <DatePicker className="formcontrol"
                                  selected={field.start_date ? parse(field.start_date, 'yyyy-MM-dd', new Date()) : null}
                                  name='start_date'
                                  onChange={(date) => handleDateChange(date, index, 'start_date')}
                                  dateFormat="MMMM yyyy"
                                  placeholderText="START DATE"
                                  showMonthYearPicker
                                  minDate={new Date(lastTenYears[lastTenYears.length - 1], 0)}
                                  maxDate={new Date(currentYear, 11, 31)}
                                />
                                {/* {errors[index] && errors[index].start_date && (
                                    <span className="error">{errors[index].start_date}</span>
                                  )} */}
                              </div>
                              <div className="system_view" style={{ clear: "both", marginTop: "3%", marginBottom: "4%", fontSize: "14px", fontWeight: "bold" }}>

                                <input className="blue" onChange={(event) => handleChange(event, index)} type="checkbox" checked={field.intern} name="intern"
                                />&nbsp;&nbsp;&nbsp; <span className="blue">This my Internship</span>
                              </div>
                            </div>

                            <div className="col-lg-6 half">
                              {selectedid != index &&
                                <div className="formgroup">
                                  <DatePicker className="formcontrol"
                                    selected={field.end_date ? parse(field.end_date, 'yyyy-MM-dd', new Date()) : null}
                                    onChange={(date) => handleDateChange(date, index, 'end_date')}
                                    dateFormat="MMMM yyyy"
                                    placeholderText="END DATE"
                                    showMonthYearPicker
                                    minDate={new Date(lastTenYears[lastTenYears.length - 1], 0)}
                                    maxDate={new Date(currentYear, 11, 31)}
                                  />
                                </div>
                              }
                              <div className="system_view" style={{ clear: "both", marginTop: "3%", marginBottom: "4%", fontSize: "14px", fontWeight: "bold" }}>

                                <input className="blue" onChange={(event) => handleChange(event, index)} type="checkbox" checked={field.working} name="working"
                                />&nbsp;&nbsp;&nbsp; <span className="blue">I Currently Work Here</span>
                              </div>
                            </div>


                            <div className="col-lg-6 full mobile_view" >
                              <div style={{ clear: "both", marginTop: "3%", marginBottom: "4%", fontSize: "14px", fontWeight: "bold" }}>

                                <input className="blue" onChange={(event) => handleChange(event, index)} type="checkbox" checked={field.working} name="working"
                                />&nbsp;&nbsp;&nbsp; <span className="blue">I Currently Work Here</span>
                              </div>
                            </div>


                            <div className="col-lg-6 full mobile_view" >
                              <div style={{ clear: "both", marginTop: "3%", marginBottom: "4%", fontSize: "14px", fontWeight: "bold" }}>

                                <input className="blue" onChange={(event) => handleChange(event, index)} type="checkbox" checked={field.intern} name="intern"
                                />&nbsp;&nbsp;&nbsp; <span className="blue">This my Internship</span>
                              </div>
                            </div>

                            <div className="col-lg-12 full" style={{ clear: 'both' }}>
                              <div className="formgroup" htmlFor={`description${index}`}>
                                <textarea className="formcontrol"
                                  name="description"
                                  placeholder="Please enter what you do as your daily work or job description. With this we will build and suggest you the responsibilities and work-related experience which should be written here. For example:

                                  Sales Executive: Responsible for selling and promoting brand, Products etc. Also, I upsell to existing customers.
                                 Accounting Assistant: Manage company accounts using Tally Software. Responsible for Month End closing.
                                 React Developer: Developed 5 cross-platform applications in the area of Sales, Finance and Inventory Control."
                                  value={field.description}
                                  onChange={(event) => handleChange(event, index)}
                                  style={{ height: "140px", padding: '10px' }}>
                                </textarea>
                                <label className="formlabel label_textarea" htmlFor={`country${index}`}>
                                  JOB DESCRIPTION
                                </label>
                                {errors[index] && errors[index].description && (
                                  <span className="error">{errors[index].description}</span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-12 full" style={{ clear: 'both' }}>

                              <p style={{ marginBottom: '3%', fontWeight: '200px' }} className="p_tag blue">Please enter your key skills for this job separating by comma </p>

                              <div className="formgroup" htmlFor={`key_skills${index}`}>
                                <textarea className="formcontrol"
                                  name="key_skills"
                                  value={field.key_skills}
                                  onChange={(event) => handleChange(event, index)}
                                  style={{ height: "100px", padding: '10px' }}>
                                </textarea>
                                <label className="formlabel" htmlFor={`country${index}`}>
                                  KEY SKILLS
                                </label>
                                {errors[index] && errors[index].key_skills && (
                                  <span className="error">{errors[index].key_skills}</span>
                                )}
                              </div>
                            </div>


                            {/* {formData2.map((field, index) => ( */}
                            <div className="col-lg-12 full" style={{ padding: '0' }}>
                              {field.achievements?.map((achievement, achievementIndex) => (
                                <div className="col-lg-12 full" key={achievementIndex}>
                                  <div className="formgroup">
                                    <input
                                      type="text"
                                      className="formcontrol remove-control"
                                      value={achievement}
                                      onChange={(e) => updateAchievement(index, achievementIndex, e.target.value)}
                                    />
                                    {/* <button className="addmore_button_small" onClick={() => removeAchievements(index, achievementIndex)}>-</button> */}
                                    <span className="clickable"><FontAwesomeIcon onClick={() => removeAchievements(index, achievementIndex)} icon={faTrashAlt} /></span>
                                    <label className="formlabel" htmlFor={`achievements${index}`}>
                                      ACHIEVEMENTS
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="col-lg-12 full addmore_parent" style={{ padding: '0' }}>
                              <div className="col-lg-4 half">
                                <button className="addmore_button clickable" onClick={() => addMoreAchievements(index)}>+ Achievements</button>
                              </div>
                            </div>
                            <div className="system_view" style={{ clear: "both", marginTop: "3%", marginBottom: "4%", fontSize: "14px", fontWeight: "bold" }}>

                              <input className="blue" onChange={(event) => handleChange(event, index)} type="checkbox" checked={field.customize} name="customize"
                              />&nbsp;&nbsp;&nbsp; <span className="blue">I want to customize this resume for a particular(applying) job</span>
                            </div>
                            {customize &&<div className="shadowbox">
                              <div className="blue bold p-10">Please enter the details of the job you are applying, for us to generate your target resume.</div>
                              <div className="col-lg-6 full">
                                <div className="formgroup">
                                  <input type="text" className="formcontrol"
                                    name="title_1"
                                    value={field.title_1}
                                    onChange={(event) => handleChange(event, index)} />
                                  <label className="formlabel" htmlFor={`title${index}`}>
                                    JOB TITLE
                                  </label>
                                </div>

                              </div>

                              <div className="col-lg-6 full">
                                <div className="formgroup">
                                  <input type="text" className="formcontrol"
                                    name="department_1"
                                    value={field.department_1}
                                    onChange={(event) => handleChange(event, index)}
                                  />
                                  <label className="formlabel" htmlFor={`employer${index}`}>
                                    DEPARTMENT
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-6 full">
                                <div className="formgroup">
                                  <input type="text" className="formcontrol"
                                    name="company_1"
                                    value={field.company_1}
                                    onChange={(event) => handleChange(event, index)} />
                                  <label className="formlabel" htmlFor={`title${index}`}>
                                    COMPANY
                                  </label>
                                </div>

                              </div>

                              <div className="col-lg-6 full">
                                <div className="formgroup">
                                  <input type="text" className="formcontrol"
                                    name="location_1"
                                    value={field.location_1}
                                    onChange={(event) => handleChange(event, index)}
                                  />
                                  <label className="formlabel" htmlFor={`employer${index}`}>
                                    LOCATION
                                  </label>
                                </div>
                              </div>

                              <div className="col-lg-12 full" style={{ clear: 'both' }}>
                                <div className="formgroup" htmlFor={`description_1${index}`}>
                                  <textarea className="formcontrol"
                                    name="description_1"
                                    value={field.description_1}
                                    onChange={(event) => handleChange(event, index)}
                                    style={{ height: "140px", padding: '10px' }}>
                                  </textarea>
                                  <label className="formlabel" htmlFor={`country${index}`}>
                                    DESCRIPTION OF THE JOB APPLYING FOR
                                  </label>
                                </div>
                              </div>
                            </div>}
                          </div>))}
                        <div className="blue bold p-10">We will work on one work experience at one time and will add other jobs once we have completed writing about this job/experience.</div>        

                        <div className="col-lg-6 half" >
                          <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                        </div>
                        <div className="col-lg-6 half" >
                          <button id="btnBack" onClick={handleSubmit} type="submit" className="btn-mangaii">Save Current Data </button>
                        </div>
                      </div>
                      <div className="col-lg-5 system_view">
                        <div className="api_right">
                          <Link to="/template/step2" className="clickable p-10 bold">Change Template</Link>
                          <Formtemplate />
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>

              </div>
            </section>
          </main>
          <StepsFooter />
        </div>
      ) : (
        <Loading content="Please Wait!!!" />
      )}
    </div>
  );
}

export default Step2;
