import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Nav from "./Nav";
import { commonConfig } from '../config.js';
import axios from "axios";
import logo from "../img/logo.png";
import temp1 from './Assets/temp1.jpeg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import account from './Assets/account.png';
import Logobar from "./Logobar";
import first_cv from './Assets/first_cv.png';
import Loading from './Loading';
import { ToastContainer, toast } from 'react-toastify';

import { FaWeight } from "react-icons/fa";
function Settings() {
	

	const navigate = useNavigate();
	const userId = localStorage.getItem('userId');
	const email = localStorage.getItem('email');
  const [page, setPage] = useState(false);
  const [form, setForm] = useState(false);
  const [plan, setPlan] = useState([]);
  const [isAccount, setIsAccount] = useState(true);
  const [formData, setFormData] = useState({
		password: '',
		cnf_password: '',
		email: email
	});

	useEffect(() => {

		if (!userId) {
			navigate('/login');
		}

    my_cv();
    // setTimeout(() => {
      
    // }, commonConfig.LOAD_TIME); 

		import("./Css/Step14.scss");
		
	}, []);

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};
	const showhideacc = (value) => {
		setIsAccount(value);
	};
	const my_cv = () => {
		axios({
      method: "POST",
      data: { userId: userId },
      url: commonConfig.REACT_APP_API_URL + "my_cvs",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      setPlan(result.plan);
      setPage(true);
    }).catch(err => {

    });
	};


  const showform = () => {
    setForm(true);
  }
  const cancel = (subscription_id) => {
    setPage(false);
    axios({
      method: "POST",
      data: { subscription_id: subscription_id },
      url: commonConfig.REACT_APP_API_URL + "cancel_subscription",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // setPage(true);
      my_cv();
    }).catch(err => {

    });
  }

  const handleSubmit = (event) => {
		event.preventDefault();

		if (formData.password == '') {
			toast.error('Password Required!!!');
		} else if (formData.password != formData.cnf_password) {
			toast.error('Password Not Matched!!!');
		} else {
			const functionThatReturnPromise = () => new Promise((resolve, reject) => {
				axios({
					method: "POST",
					url: commonConfig.REACT_APP_API_URL + "change_password",
					data: formData,
					headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

				}).then(res => {
					var decodedData = res.data;
					var status = res.status;
					if (status) {
            
						// navigate('/login');
						resolve(status);
					}

				}).catch(err => {
					reject();
				});
			});

			toast.promise(
				functionThatReturnPromise,
				{
					pending: 'Please Wait...',
					success: 'Password Updated Successfully... 👌',
					error: 'Failed 🤯'
				},
			)
		}
	};

	return (
		<div className="step14">
    {page ? (
      <div>
			<main id="app" role="main">
				<section className="page-wrapper">
          <Logobar />
          <ToastContainer position="top-center" theme="dark" />
					<div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
            <div style={{width:'80%',margin:'auto'}}>
              <div className="col-lg-12">
                <div className="col-lg-2">
                  <div onClick={() => showhideacc(true)} className={`p-10 bold clickable ${isAccount ? 'blue' : ''}`}>Account</div>
                  <div onClick={() => showhideacc(false)} className={`p-10 bold clickable ${!isAccount ? 'blue' : ''}`}>Subscription</div>
                </div>
                <div className="inner1_settings col-lg-8">

                  {isAccount && <div className="center-start"> <div className="set_email f-17 ">
                    <div className="center-start p-10"><span className="set_label">Email Id</span>: <span className="set_value">{email}</span> </div> 
                    <div className="center-start p-10"><span className="set_label">Password</span>: <span className="set_value">*******</span><div className="clickable set_icon" onClick={showform} ><FontAwesomeIcon icon={faEdit} /></div></div> 
                    {form &&
                    <div>
                      <div className="center-start p-10"><span className="set_label">Enter New Password </span>: <span className="set_value"><input type="password" name="password" value={formData.password} onChange={handleChange} id="l_name" className="formcontrol"  /></span> </div> 
                      <div className="center-start p-10"><span className="set_label">Confirm New Password </span>: <span className="set_value"><input type="password" name="cnf_password" value={formData.cnf_password} onChange={handleChange} id="l_name" className="formcontrol"  /></span> </div> 
                      <button id="btnBack" type="submit" onClick={handleSubmit} className="set_save" style={{ marginTop: '3%' }} >Save </button>
                    </div>
                    }
                  </div>
                  </div>
                  }


                  {!isAccount && <div className=" f-17 ">
                    {plan.subscription_id == '' ? (
                    <div className=" p-10">
                      <Link to="/plans" style={{float:'left'}} className="up_btn btn btn-mangai clickable center" >Get Now</Link>
                    </div> ):(
                      <div>
                        <div className=" p-10">
                          <div className=" p-10"><span className="set_label">Plan Name</span>: <span className="set_value">{plan.pack}</span> </div> 
                        </div>
                        <div className=" p-10">
                          <div className=" p-10"><span className="set_label">Status</span>: <span className="set_value">{plan.subscription_details.status}</span> </div> 
                        </div>
                        <div className=" p-10">
                          <button onClick={() => cancel(plan.subscription_id)} className="up_btn btn btn-mangai clickable center" >Cancel Subscription</button> 
                        </div>
                      </div>
                    )}
                  </div>}
                </div>
              </div>
            </div>
					</div>
					
				</section>
			</main>
		</div>
    ) : (
        <Loading content="Please Wait!!!"/>
      )}
		</div>
	);
}

export default Settings;




