import React, { Component, useState, useEffect } from "react";
import target from "../img/target.png";
// import './Css/Register.css';
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import axios from "axios";
import MainNav from "./MainNav";
import {
    HashRouter as Router,
    Route,
    Link,
    Navigate ,
    useNavigate , 
    DefaultRoute
  } from "react-router-dom";

function Register({ onSubmit }) {
    const navigate = useNavigate();
    const [page, setPage] = useState(false);

	useEffect(() => {
		import('./Css/Register.scss');
    import('./Assets/CSS/bootstrap.min.css');

    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME); 
	}, []);


	const [formData, setFormData] = useState({
		name: '',
		email: '',
		mobile: '',
		password: '',
		c_password: '',
		adult: false,
		terms: false,
	});

	const handleChange = (event) => {
		const { name, value } = event.target;

    if(name == 'adult'){
      const { name, checked } = event.target || event;
      setFormData({ ...formData, [name]: checked });
    }else if(name == 'terms'){
      const { name, checked } = event.target || event;
      setFormData({ ...formData, [name]: checked });
    }else{
      setFormData({ ...formData, [name]: value });
    }
		
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
     console.log(formData);     
		if (formData.email == '') {
			toast.error('EmailAddress Required!!!');
		} else if (formData.name == '') {
			toast.error('Fullname Required!!!');
		} else if (formData.mobile == '') {
			toast.error('Mobile Number Required!!!');
		} else if (formData.password == '') {
			toast.error('Password Required!!!');
		} else if (formData.c_password == '') {
			toast.error('Confirm Password Required!!!');
		}else if (formData.password != formData.c_password) {
			toast.error('The Confirm Password field must match password');
		}else if (!formData.terms) {
			toast.error('Please read and accept the Terms and Conditions and Privacy Policy of GOjobs.ai by checking the box before proceeding.', {
        autoClose: 15000, 
      });
		}else if (!formData.adult) {
			toast.error('Please confirm your age by checking the box indicating that you are above 18 years old.', {
        autoClose: 12000, 
      });
		} else {
			const functionThatReturnPromise = () => new Promise((resolve, reject) => {
				axios({
					method: "POST",
					url: commonConfig.REACT_APP_API_URL + "register",
					data: formData,
				}).then(res => {
					var decodedData = res.data;
					var status = res.status;
					if (status) {
						resolve(status);
              setTimeout(() => {
                  navigate('/login');
              }, 10000);
					}

				}).catch(err => {
					reject();
				});
			});

			const pendingToast = toast('Please Wait...', { autoClose: false });

      try {
        await functionThatReturnPromise();
        // Dismiss the pending toast and show the success toast
        toast.update(pendingToast, {
          render: 'Please verify the email id before proceeding.',
          type: toast.TYPE.SUCCESS,
          autoClose: 10000, // Set the duration in milliseconds (5 seconds)
        });
      } catch (error) {
        // Dismiss the pending toast and show the error toast
        toast.update(pendingToast, {
          render: 'Please login by using your login credentials or reset your password by using Forget Password if you do not remember it.',
          type: toast.TYPE.ERROR,
          autoClose: 15000, // Set the duration in milliseconds (5 seconds)
        });
      }
		}
	};


	return (

		<div className="registerpage">
    {page ? (
      <div>
      <MainNav/>
			<ToastContainer position="top-center" theme="dark"/>
			<div className="containers">
				<div className="left-section"></div>
				<div className="right-section">
					<div className="right_1r">
						<p className="subtitle"> <span className="clr_blue">Create Your Account</span> </p>
						<div className="container_inner registerpage">
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<input type="text" className="formcontrol" name="name" value={formData.name} onChange={handleChange} />
                  <label className="formlabel" htmlFor="f_name">Fullname</label>
								</div>
								<div className="form-group">
									<input type="email" className="formcontrol" name="email" value={formData.email} onChange={handleChange} />
                  <label className="formlabel" htmlFor="f_name">Email Address</label>
								</div>
								<div className="form-group">
									<input type="number" className="formcontrol" name="mobile" value={formData.mobile} onChange={handleChange}/>
                  <label className="formlabel" htmlFor="f_name">Mobile</label>
								</div>
								<div className="form-group">
									<input type="password" className="formcontrol" name="password" value={formData.password} onChange={handleChange}/>
                  <label className="formlabel" htmlFor="f_name">Password</label>
								</div>
								<div className="form-group">
									<input type="password" className="formcontrol" name="c_password" value={formData.c_password} onChange={handleChange} />
                  <label className="formlabel" htmlFor="f_name">Confirm Password</label>
								</div>
                <div className="form-group center">
                  <input className="blue" style={{width: '10%'}} onChange={handleChange} type="checkbox" checked={formData.terms} name="terms"/>&nbsp; <span className="blue"> I have read and accept the <Link className="bold" to="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions.</Link> and the <Link className="bold" to="/privacy" target="_blank" rel="noopener noreferrer">Privacy Policy</Link> of GOjobs.ai </span>
								</div>
								<div className="form-group center">
                  <input className="blue" style={{width: '9%'}} onChange={handleChange} type="checkbox" checked={formData.adult} name="adult"/>&nbsp;<span className="blue center">I confirm that I am at least 18 years old.</span>
								</div>
								
								<button type="submit" className="btn">SIGN UP</button>

							</form>
						</div>

						{/* <div className="social">
							<img src={google_button} className="social_img" alt="Centered Image" />
							<img src={link_button} className="social_img" alt="Centered Image" />
						</div> */}
						<div className="login_extra center-start-end">
              <Link to="/login"><div className="clr_blue bold">Already Registered?</div></Link>
              <Link to="/login"><div className="clr_blue bold">Forgot Password</div></Link>
						</div>
					</div>
				</div>
				<img src={target} className="center-image" alt="Centered Image" />
			</div>
		</div>
      ) : (
        <Loading content="Please Wait!!!"/>
      )}
		</div>
	);
}

export default Register;
