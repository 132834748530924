import React from 'react';
import './Temp6.css';

const Resume = () => {
  return (
    <div className="resume-container">
      <table className="resume-table">
        <tbody>
          <tr>
            <td colspan="2" className="resume-header-cell">
              <p className="resume-name">Donna</p>
              <p className="resume-name">Robbins</p>
            </td>
            <td className="resume-contact-cell">
              <p className="resume-contact-info">Detroit, MI | www.greatsiteaddress.com</p>
              <p className="resume-contact-info">313.555.0100 | donna@example.com</p>
            </td>
          </tr>
          <tr>
            <td colspan="3" className="resume-summary-cell">
              <p className="resume-summary">
                Analytical, organized and detail-oriented accountant with GAAP expertise and experience in the full spectrum of public accounting. Collaborative team player with ownership mentality and a track record of delivering the highest quality strategic solutions to resolve challenges and propel business growth.
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="3" className="resume-experience-cell">
              <h1 className="resume-section-heading">Experience</h1>
              <h2 className="resume-subheading">Accountant</h2>
              <h3 className="resume-subheading">Trey Research | San Francisco, CA</h3>
              <h4 className="resume-date-range">20XX – PRESENT</h4>
              <p className="resume-description">
                Working in a mid-sized public accounting firm to provide professional accounting services for individuals and business clients. Provide full range of services, include income tax preparation, audit support, preparation of financial statements, pro forma budgeting, general ledger accounting, and bank reconciliation.
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="2" className="resume-experience-cell">
              <h2 className="resume-subheading">Bookkeeper</h2>
              <h3 className="resume-subheading">Bandter Real Estate | Berkeley, CA</h3>
              <h4 className="resume-date-range">20XX – 20XX</h4>
              <p className="resume-description">
                Inhouse bookkeeper for a real estate development company. Maintained financial books, tracked expenses, prepared and submitted invoices, and oversaw payroll.
              </p>
            </td>
            <td className="resume-experience-cell">
              <h2 className="resume-subheading">Accounting Intern</h2>
              <h3 className="resume-subheading">Olson Harris Ltd. | Vallejo, CA</h3>
              <h4 className="resume-date-range">20XX – 20XX</h4>
              <p className="resume-description">
                Assisted with payroll and Pensions service management for 150+ employees. Prepared invoices for more than 200 clients.
              </p>
            </td>
          </tr>
          <tr>
            <td colspan="3" className="resume-education-cell">
              <h1 className="resume-section-heading">Education</h1>
              <h2 className="resume-subheading">Bachelor of Science in Accounting, Minor in Business Administration</h2>
              <h3 className="resume-subheading">Bellows College</h3>
              <h4 className="resume-date-range">JUNE 20XX</h4>
              <div className="resume-education-details">
                <ul>
                  <li>Distinguished member of university's Accounting Society</li>
                  <li>Relevant Coursework: Advanced Financial Accounting &amp; Reporting</li>
                  <li>GPA 3.8</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="3" className="resume-skills-cell">
              <h1 className="resume-section-heading">Skills</h1>
              <div className="resume-skills-list">
                <ul>
                  <li>Microsoft NAV Dynamics</li>
                  <li>Cashflow planning and management</li>
                  <li>State &amp; federal tax codes</li>
                  <li>Bookkeeping</li>
                  <li>Exceptional communication</li>
                  <li>Fluent in German</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr className="resume-empty-row">
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>
      <p className="resume-empty-paragraph">&nbsp;</p>
    </div>
  );
};

export default Resume;
