import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver'; // for downloading the file
import htmlDocx from 'html-docx-js/dist/html-docx';
import jsPDF from 'jspdf';

const Dynamictemp4 = forwardRef((props, ref) => {

  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);
  let resume_id = localStorage.getItem('resume_id');
  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  const a = ["PHP", "Javascipt", "HTML", "CSS", "Bootstap", "ionic", "Angular", "react", "Laravel", "Codeigniter", "Wordpress"];

  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);
      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);



  const printDocument = (title, fontsize, spacing, color, resumename, justify) => {
    // const element = document.getElementById('pdfContainer');
    // // console.log(htmlContent); 
    // const data = {
    //   htmlContent: htmlContent,
    //   resume_id: resume_id,
    //   fontsize: fontsize,
    //   resumename: resumename,
    //   spacing: spacing,
    //   color: color,
    //   justify: justify,
    //   template: 4
    // };
    // console.log('htmlContent', htmlContent);
    // axios({
    //   method: "POST",
    //   url: commonConfig.REACT_APP_API_URL + "generate-pdf",
    //   data: data,
    //   headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    // }).then(res => {
    //   if (title == "save") {
    //     toast.success("Saved Successfully!!!");
    //   }

    // }).catch(err => {

    // });

    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(divRef.current, {
      callback: function (pdf) {
        pdf.save('download.pdf');
      },
      x: 20,          // Set the x offset for margin
      y: 20,          // Set the y offset for margin
      margin: 10,     // Apply a margin to all sides
      width: 550,     // Adjust content width based on A4 width and desired margins
      windowWidth: 900, // Control scaling based on browser window width
    });
  };

  useImperativeHandle(ref, () => ({
    printDocument: printDocument
  }));
  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print,
    downloadWordDoc: downloadWordDoc,
  }));
  const downloadWordDoc = () => {
    // const content = `
    // `;

    // const blob = new Blob([content], {
    //   type: 'application/msword'
    // });

    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'resume.doc';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);

    const cssStyles = `
    <style>
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;vertical-align:baseline;background:transparent}
body{line-height:1;text-align:left;font-feature-settings:"liga" 0;-moz-font-feature-settings:"liga" off}
  table{border-collapse:collapse;border-spacing:0;font-size:inherit;color:inherit;width:100%}

/*START content disc style for LI*/
  ul,  li{list-style:none;margin:0 0 0 8px;padding:0}
  ul li{position:relative;margin:0}
  ul li:before{position:absolute;left:-8px;top:0;font-family:'Century Gothic'}
  .jobline ul{margin-top:6px}
/*END content disc style for LI*/

  .clear{clear:both;height:0}
  .txt-bold{font-weight:bold}
  .paddedline{display:block}
  .flt-right{float:right}
  .inline-block{display:inline-block}

/*Document*/
 {color:#6d6d6d;line-height:16px;font-variant-ligatures:none;min-height:792px;overflow-x:hidden}
  .name{padding:0;text-align:left;word-wrap:break-word;letter-spacing:1.44px;color:#2a2a2a;text-transform:uppercase;}
  .fname{color:#2a2a2a}
  .resumeTitle{color:#3b3b3b;letter-spacing:.67px;text-transform:uppercase}
  .parentContainer{display:table;table-layout:fixed;width:100%;min-height:inherit}
  .right-box{color:#6d6d6d}
  .topsection .section,   .parentContainer > .containers > .section:last-child{margin-bottom:0}
  .right-box .skill{display:table;width:100%;table-layout:fixed}
  .right-box .skill .paddedline{display:table-cell;width:50%}
/*   .right-box .skill .paddedline:last-child{padding-left:10px} */

/* Common style for left and right box */
  .right-box{padding:30px 36px 30px 30px;display:table-cell;vertical-align:top;word-wrap:break-word}
  .left-box{background-color:#f3f3f3;box-sizing:content-box;display:table-cell;background-size:100%;padding:9px 0px 17px 19px;word-wrap:break-word}
  .left-box .section{color:#2a2a2a}
  .left-box .section:first-child,  .right-box .section:first-child{border:none;padding-top:0px}
  .left-box .section:first-child .paragraph{padding:0;display:block}
  .left-box .heading{padding:0!important}
  .education .paddedline{margin-bottom:8px}
  .education .paddedline:last-child{margin-bottom:0}
  .education .companyname{display:block}
  .experience .locationGap{margin-bottom:8px}
  .topsection{position:relative}
  .topsection:before{content:" ” ";position:absolute;color:#e7c324;font-family:Arial;font-weight:700}
  .topsection .left-box,  .topsection .right-box{background:none;padding-top:0;padding-bottom:0}
  .topsection .left-box .name{margin-top:14px}
  .topsection .left-box:before{content:'';background:#f3f3f3;height:100%;position:absolute;left:0;padding-right:56px;box-sizing:content-box}
  .experience .dates_wrapper{display:block;float:left;font-style:italic}
  .topsection .section:first-child{position:relative}

/* Heading */
  .heading{line-height:15px;margin-bottom:15px;position:relative;color:#000}
  .sectiontitle, .profTitle{text-transform:uppercase;letter-spacing:2px;color:#8d9ec4 !important}
  .sectiontitle,  .parentContainer .left-box .singlecolumn{word-wrap:break-word}
  .right-box .firstsection .txt-bold{color:#3b3b3b}

  .heading:before{content:"";position:absolute;height:0.5px;background:#000;top:21px}

/* section */
  .section:empty{display:none}
  .septr:before{content:"|";font-size:9px;vertical-align:top;padding-left:2px;padding-right:2px}
  .left-box .section:after{content:'';display:block}
  .sec-cntc{padding-top:0;border-top:none!important}
/*   .sec-cntc .firstparagraph .singlecolumn{font-style:italic} */
  .sec-cntc .singlecolumn div{padding-bottom:10px;position:relative}
  .sec-cntc .singlecolumn div:last-child{padding-bottom:0}
  .sec-cntc .singlecolumn div:before{content:'';height:14px;position:absolute;background:#e7c324;border-radius:50%;-webkit-border-radius:50%;-moz-border-radius:50%;-ms-border-radius:50%}
  .left-box .additional_lnk{border-top:none}
  table.skills .skillname,  table.skills .skillrating{text-align:left;width:35%}
  table.skills .skillrating{width:20%}
  table.skills .skillyears,  table.skills .skilllast{width:19%;text-align:center}
  table.skills .pad1{width:5%}
  table.skills .pad2,  table.skills .pad3{width:1%}
  .displayNoneThisField{display:none}

/*New logic for infographic*/
  .lang-sec .singlecolumn{display:none}
  .lang-sec.infobarsec .infobarpara,  .lang-sec.infotilesec .infotilepara{display:block}

/*Infographic*/
  .lang-sec.infobarsec{font-size:0}
  .lang-sec.infobarsec .field *,  .lang-sec.infobarsec .nativeLangPara .field{display:inline} 
  .lang-sec.infobarsec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
  .lang-sec.infobarsec .singlecolumn{margin-left:0!important;padding-left:0;position:relative}
  .lang-sec.infobarsec .para_odd{margin-right:15px}
  .lang-sec.infobarsec .inner-rating{position:relative}
  .lang-sec.infobarsec .rating-bar{background:#d5d6d6;width:100%;clear:both;margin-top:3px}
  .lang-sec.infobarsec .inner-rating{background-color:#10657e;height:4px;width:60%}
  .lang-sec.infobarsec .paragraph:before{display:none}
  .lang-sec.infobarsec > .paragraph:nth-last-child(1),  .right-box .lang-sec.infobarsec > .paragraph:nth-last-child(2){padding-bottom:0!important}
  .left-box .lang-sec .paragraph,  .left-box .info-sec .paragraph{width:100%!important;max-width: 100%!important;display:block}
  .rating-bar{background:#d5d6d6;width:100%;clear:both;margin-top:3px}
  .inner-rating{background-color:#10657e;height:4px;width:60%}

/*Infographic Tiles*/
  .lang-sec.infotilesec,  .skli-sec{font-size:0}
  .lang-sec.infotilesec .paragraph,  .skli-sec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
  .lang-sec.infotilesec > .paragraph:nth-last-child(1),  .right-box .lang-sec.infotilesec > .paragraph:nth-last-child(2),  .skli-sec > .paragraph:nth-last-child(1),  .right-box .skli-sec > .paragraph:nth-last-child(2){padding-bottom:0!important}
  .lang-sec.infotilesec .field *,  .skli-sec .field *,  .lang-sec.infotilesec .nativeLangPara .field{display:inline}
  .lang-sec.infotilesec .singlecolumn,  .skli-sec .singlecolumn{margin-left:0!important}
  .lang-sec.infotilesec .sliced-rect,  .skli-sec .sliced-rect{height:6px;width:100%;margin-top:3px;line-height:0px;clear:both}
  .lang-sec.infotilesec .paragraph.para_odd,  .skli-sec .paragraph.para_odd{margin-right:15px}
  .lang-sec.infotilesec .sliced-rect .sliced-rect-tile,  .skli-sec .sliced-rect .sliced-rect-tile{height:100%;background-color:#d5d6d6;float:left;margin-right:3px}
  .lang-sec.infotilesec .sliced-rect .sliced-rect-tile:last-child,  .skli-sec .sliced-rect .sliced-rect-tile:last-child{margin-right:0}
  .left-box  .lang-sec.infotilesec .paragraph,   .left-box  .skli-sec .paragraph{width:100%!important;max-width: 100%!important;display:block}
/*Rectangular Rating Blocks*/
  .sliced-rect .sliced-rect-tile.ratvfill{background-color:#10657e}
  .hide-bar .rating-bar,  .hide-bar .sliced-rect,  .hide-colon .colon{display:none!important}
  .hide-bar .field-ratt{display:none}

  .section[id*="SECTION_ERTL"] .paragraph,  .section[id*="SECTION_RTTL"] .paragraph{border:none;margin:0;padding:0;text-align:center}
  .section[id*="SECTION_RTTL"] .paragraph .resumeTitle{text-align:center}

/* Builder CSS */
  .parentContainer > .container .section-container:last-child .section{margin-bottom:0}

/*Button style*/
  .btnlnk{position:relative;font-size:0;line-height:12px}
  .btnlnk a{display:inline-block;background:#e7c324;width:100%;height:100%;text-decoration:none}
  .txtlnk{display:none}
 :not(.show-btn) .btnlnk{visibility:hidden}
 .show-btn .btnlnk .paragraph{margin-top:0;display:inline-block}
 .show-btn .btnlnk .paragraph:first-child{margin-left:0}

/*Button SVG*/
  .btnlnk svg{width:100%;height:100%}
  .btnlnk svg text{text-anchor:middle;font-weight:bold;fill:#fff;line-height:12px}

 ,  table,  .right-box .experience .paddedline.dates_wrapper{line-height:16px}
 .pagesize{width:612px}
 .fontsize,  .lang-sec.infobarsec .paragraph *,  .lang-sec.infotilesec .paragraph *,  .skli-sec .paragraph *{font-size:var(--font-size);line-height: var(--line-height);}
 .fontface{font-family:Verdana}
  .section{margin-bottom:5px}
  .firstparagraph{margin-top:0!important}
  .paragraph{margin-top:30px;line-height: var(--line-height);}
  .right-box .singlecolumn,  .right-box .maincolumn{margin-left:0px;word-break:break-word}
  .name{font-size: 16px;
  line-height: 48px;
  /* width: 391px; */
  word-wrap: break-word;}
  .resumeTitle{font-size:12px;line-height:12px}
  .profTitle{font-size:13px;line-height:27px;margin-top:10px}
  .address2 {
    font-size: 9px;
  }
  
    .heading {
    font-size: 14px;
    line-height: 21px;
  }
  
  .left-box,  .topsection .left-box:before{width:225px}
  .left-box .sec-cntc .singlecolumn div:before{background:#e7c324}
/*   .topsection:before{color:#e7c324;font-size:430px;top:169px;right:-31px} */
  .experience .dates_wrapper{font-size:8px;width:90px}
  ul li{padding-left:-1px}
  .right-box .experience .paddedline,  .address,  .education .singlecolumn{}
  .topsection .left-box,  .topsection .right-box{height:46px}

/*Button style*/
  .btnlnk a{background:#e7c324}
  .btnlnk{height:15px}
 .show-btn .btnlnk .paragraph{width:108px;height:15px;margin-left:7px}
  .btnlnk svg text {
    fill: #FF0000; 
  }
  
 .show-btn .name:last-child{margin-bottom:15px}
 .show-btn .parentContainer .left-box, .show-btn .parentContainer .right-box,
 .show-btn-gap .parentContainer .left-box, .show-btn-gap .parentContainer .right-box{padding-top:14px}

/*Infographic*/
  .lang-sec.infobarsec .inner-rating{background-color:#e7c324}
  .right-box .lang-sec.infobarsec{padding-left:0px}
  .right-box .lang-sec.infobarsec .heading{margin-left:-0px}
  .right-box .lang-sec.infobarsec .paragraph{width:160px;max-width:160px}
  .right-box .lang-sec .nativeLangPara{width:336px!important;max-width:336px!important}
  .right-box .lang-sec,  .right-box .skli-sec{padding-left:0px}
  .left-box .lang-sec .paragraph,  .left-box .skli-sec .paragraph{padding-bottom:5px}

  .inner-rating{background-color:#e7c324 !important}

/*Infographic Skills*/
  .right-box .lang-sec.infotilesec .heading,  .right-box .skli-sec .heading{margin-left:-0px}
  .right-box .lang-sec.infotilesec .paragraph,  .right-box .skli-sec .paragraph{width:160px}

/*Algorithm Width*/
  .heading:before{width:45px}
  .sec-cntc .singlecolumn div:before{width:14px;height:14px;left:-21px}
  .experience .jobline ul{margin-left:10px}
  .sec-cntc .firstparagraph .singlecolumn{padding-left:21px}

/*Builder fixes*/
  .experience .sortable-item .jobline ul{margin-left:100px}
  .right-box .infoSec .sortableInner .sortable-item .paragraph,.RNA  .right-box .data-LNGG .sortableInner .sortable-item + .sortable-item .paragraph{max-width:168px;vertical-align:top}
  .right-box .data-LNGG .sortableInner .sortable-item{display:inline-block;vertical-align:top}
  .right-box .data-LNGG .nativeLangPara{width:100%!important;max-width:100%!important;}
  .right-box .data-LNGG .para_odd{margin-right:15px}
  .right-box .data-LNGG .nativeLangPara .singlecolumn{width:336px!important;max-width:336px!important}
  .right-box .langSec .sortable-item .emptyBarDiv + .emptyWidthDiv,  .right-box .infoSec .sortable-item .paragraph{width:168px;max-width:168px}
  .right-box .data-LNGG .firstparagraph.para_odd{max-width:168px!important}
.resume-preview   .right-box .SECTION_LNGG.lang-sec.infobarsec .paragraph{width:141px;max-width:141px}

/*Builder button style*/
 .show-btn .sortable-item .name{margin-bottom:15px}

/*Expr and EDUC section on drag*/
/*   .PARAGRAPH_EXPR .locationGap,  .PARAGRAPH_EDUC .paddedline{margin-bottom:8px} */
  .PARAGRAPH_EXPR .paddedline.dates_wrapper{display:block;float:left;font-style:italic;font-size:8px;width:90px;line-height:16px}
  .PARAGRAPH_EXPR .paddedline,  .PARAGRAPH_EDUC .singlecolumn{line-height:12px}
  .PARAGRAPH_EXPR .jobline ul{margin-left:10px}
  .PARAGRAPH_EDUC .paddedline:last-child{margin-bottom:0}
  .PARAGRAPH_EDUC .companyname{display:block}

@-moz-document url-prefix(){  .parentContainer{height:600px}}

/*Rectangular Rating Blocks*/
  .paragraph .sliced-rect .sliced-rect-tile.ratvfill{background-color:#e7c324}

.pro_image{
  width: 150px;
  height: 150px;
  object-fit: contain;
}
.template-4 .singlecolumn span{
  font-size: 13px;
  line-height: 1.5;
}
ul {
  margin-top: 0;
  margin-bottom: 0rem !important;
}
     table {
            width: 100%;
            table-layout: fixed;
           
          }
          td {
            vertical-align: top;
            padding: 10px;
            
          }
          td:last-child {
            border-right: none;
          }
           td:first-child {
            width: 60%;
             border-right: 2px solid #8d9ec4;
            padding-right: 20px;
          }
          td:last-child {
            width: 40%;
            padding-left: 20px;
          }
          .sectiontitle:after {
            background-color: #8d9ec4;
          }


</style>
`;
    const contentElement = document.getElementById('downloadToWord').innerHTML;
    const contentWithStyles = cssStyles + contentElement;
    const docx = htmlDocx.asBlob(contentWithStyles, { orientation: 'portrait' });
    saveAs(docx, 'resume.docx');
  };

  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      {respokData ? (
        <>
        <li id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ listStyle: 'none' }} className="template-preview" data-skinid="MLB2" data-skincd="MLB2" title="">
            <div className="render-preview">
              <div className="" tabindex="">
                <div></div>
                <div className="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlb4 MLB2  RNA  " docskinwidth="615">
                  <div id="CONTAINER_PARENT_1" className="parentContainer">
                    <div id="CONTAINER_2" style={{ pageBreakAfter: 'avoid' }} className="left-box last-box containers">
                      <div className="name mb175">
                        <span id="FIELD_FNAM">{contact.f_name} {contact.l_name}</span>
                      </div>
                      <div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-CNTC">
                        <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" className="section sec-cntc notdraggable SECTION_CNTC    has-title  " data-section-cd="CNTC">
                          <div className=" doc-item mb175">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_CNTC">Contact<span title=" Contact " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" className="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    <div className="address">
                                      <div className="singlecolumn">
                                        {contact.city &&
                                          <div className="zipsuffix" dependency="ADDR|STRT|CITY|STAT|ZIPC">
                                            <span id="FIELD_STRT"></span><span dependency="STRT" style={{ display: 'none' }}><span dependency="CITY|STAT|ZIPC" style={{ display: 'none' }}>, </span></span>
                                            <span id="FIELD_CITY"></span><span dependency="CITY" style={{ display: 'none' }}><span dependency="STAT|ZIPC" style={{ display: 'none' }}>, </span></span>
                                            <span id="FIELD_STAT"></span>
                                            <span id="FIELD_ZIPC"></span>
                                            <span id="FIELD_ADDR">{contact.city}</span>
                                          </div>
                                        }
                                        <div dependency="CPHN">
                                          <span id="FIELD_CPHN">{contact.mobile}</span>
                                        </div>
                                        <div dependency="EMAI">
                                          <span id="FIELD_EMAI">{contact.email}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-EDUC">
                        <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" className="section education SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" className="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    {education?.map((item, index) => (
                                      <div className="mb75">
                                        <div className="singlecolumn" style={{ lineHeight: 'var(--line-height)' }}>
                                          <div className="paddedline" dependency="SCIT|SSTA|SCHO|SCNT">
                                            <span className="companyname" id="FIELD_SCHO">
                                              <span className="degree">
                                                {item.course}&nbsp;
                                                {item.specialization && <span className="degree">,
                                                  &nbsp;{item.specialization} &nbsp;
                                                </span>}
                                                {item.university && <span className="jobdates">
                                                  <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                                </span>}
                                                {item.year_of_completion && <span className="jobdates">
                                                  <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                                </span>}
                                              </span>
                                            </span>
                                          </div>
                                        </div>
                                        {item.achievements != '[]' && item.achievements != '[null]' && (
                                          <div className="singlecolumn maincolumn" style={{ lineHeight: 'var(--line-height)' }}>
                                            <br />
                                            <ul>
                                              {JSON.parse(item.achievements)?.map((item1, index) => (
                                                item1 &&
                                                <li key={index}>{item1}</li>
                                              ))}
                                            </ul>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="CONTAINER_3" className="right-box last-box containers">
                      <div className="sortable-item section-container SortableItem-sibling  data-SUMM mb175">
                        <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" className="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" className="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    <div className="singlecolumn" id="FIELD_FRFM">
                                      <p>{contact.summery}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sortable-item section-container SortableItem-sibling  data-HILT mb175">
                        <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" className="section SECTION_HILT    has-title  " data-section-cd="HILT">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    <div className="singlecolumn maincolumn">
                                      {skills[0] &&
                                        <div className="skill">
                                          <span className="paddedline" id="FIELD_SKC1">
                                            <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                          </span>
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="sortable-item section-container SortableItem-sibling  data-EXPR mb175">
                        <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" className="section experience SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="sortableInner">
                                <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" className="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    {experience?.map((item, index) => (
                                      <div className="mb75">
                                        <div className="singlecolumn">
                                          <h4 className="paddedline locationGap" dependency="COMP|JSTA|JCIT|JCNT">
                                            <span className="companyname txt-bold" id="FIELD_COMP">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>} - {item.employer}</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> | </span></span>
                                            <span className="jobcity" id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                            <span className="jobstate" id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                                          </h4>
                                          <h4 className="paddedline">
                                            <span className="jobdates" dependency="JSTD|EDDT">
                                              <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                              <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                            </span>
                                          </h4>
                                          <span className="jobline" id="FIELD_JDES">
                                            <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                          </span>
                                        </div>
                                        {item.achievements != '[]' && item.achievements != '[null]' && (
                                          <div className="singlecolumn maincolumn mb25">
                                            <br />
                                            <div>
                                              {JSON.parse(item.achievements)?.map((item1, index) => (
                                                item1 &&
                                                <li key={index}>{item1}</li>
                                              ))}
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                        <div
                          data-react-beautiful-dnd-draggable="2"
                          className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                        >
                          <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                            <div className=" doc-item">
                              <div className="heading">
                                <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                              </div>
                              <div className="">
                                <div className="">
                                  <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                    <div className="clearfix doc-item">
                                      <div className="singlecolumn maincolumn">
                                        {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                          <div className="mb75">
                                            <h4>Languages:</h4>
                                            <span className="paddedline" id="FIELD_SKC1">
                                              <ul>
                                                {JSON.parse(more)?.language?.map((item, index) => (
                                                  <li key={item.language}>{item.language}</li>
                                                ))}
                                              </ul>
                                            </span>
                                          </div>
                                        }
                                      </div>
                                      {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) &&
                                        <div className="singlecolumn maincolumn mb75">
                                          <h4>Website & Profiles:</h4>
                                          <span className="paddedline" id="FIELD_SKC1">
                                            <div>
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.work &&
                                                <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.portfolio &&
                                                <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.profile &&
                                                <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                                              ))}
                                            </div>
                                          </span>
                                        </div>
                                      }
                                      {JSON.parse(more).language &&
                                        <div className="singlecolumn maincolumn mb75">
                                          {JSON.parse(more)?.custom?.map((item, index) => (
                                            <div>
                                              <h4>{item.custom_name}</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <p>
                                                  {item.ckeditor &&
                                                    <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />
                                                  }
                                                </p>
                                              </span>
                                            </div>
                                          ))}
                                        </div>
                                      }
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div><div></div></div></div></li>

          <li id="downloadToWord" onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ listStyle: 'none',display:'none' }} className="template-preview" data-skinid="MLB2" data-skincd="MLB2" title="">

            <div className="render-preview">
              <div className="" tabindex="">
                <div></div>

                <div className="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlb4 MLB2  RNA  " docskinwidth="615">

                  <div id="CONTAINER_PARENT_1" className="parentContainer">
                    <table>
                      <tr>
                        <td style={{backgroundColor:'#f3f3f3'}}>
                          <div id="CONTAINER_2" style={{ pageBreakAfter: 'avoid' }} className="left-box last-box containers">
                            <div className="name mb175">
                              <span id="FIELD_FNAM">{contact.f_name} {contact.l_name}</span>
                            </div>
                            <div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-CNTC">
                              <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" className="section sec-cntc notdraggable SECTION_CNTC    has-title  " data-section-cd="CNTC">
                                <div className=" doc-item mb175">
                                  <div className="heading">
                                    <div className="sectiontitle" id="SECTIONNAME_CNTC">Contact<span title=" Contact " className="rename-section text-rename"></span></div>
                                  </div>
                                  <div className="">
                                    <div className="">
                                      <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" className="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                        <div className="clearfix doc-item">
                                          <div className="address">
                                            <div className="singlecolumn">
                                              {contact.city &&
                                                <div className="zipsuffix" dependency="ADDR|STRT|CITY|STAT|ZIPC">
                                                  <span id="FIELD_STRT"></span><span dependency="STRT" style={{ display: 'none' }}><span dependency="CITY|STAT|ZIPC" style={{ display: 'none' }}>, </span></span>
                                                  <span id="FIELD_CITY"></span><span dependency="CITY" style={{ display: 'none' }}><span dependency="STAT|ZIPC" style={{ display: 'none' }}>, </span></span>
                                                  <span id="FIELD_STAT"></span>
                                                  <span id="FIELD_ZIPC"></span>
                                                  <span id="FIELD_ADDR">{contact.city}</span>
                                                </div>
                                              }
                                              <div dependency="CPHN">
                                                <span id="FIELD_CPHN">{contact.mobile}</span>
                                              </div>
                                              <div dependency="EMAI">
                                                <span id="FIELD_EMAI">{contact.email}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div data-react-beautiful-dnd-draggable="182" className="sortable-item section-container SortableItem-sibling  data-EDUC">
                              <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" className="section education SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                                <div className=" doc-item">
                                  <div className="heading">
                                    <div className="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " className="rename-section text-rename"></span></div>
                                  </div>
                                  <div className="">
                                    <div className="">
                                      <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" className="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                        <div className="clearfix doc-item">
                                          {education?.map((item, index) => (
                                            <div className="mb75">
                                              <div className="singlecolumn" style={{ lineHeight: 'var(--line-height)' }}>
                                                <div className="paddedline" dependency="SCIT|SSTA|SCHO|SCNT">
                                                  <span className="companyname" id="FIELD_SCHO">
                                                    <span className="degree">
                                                      {item.course}&nbsp;
                                                      {item.specialization && <span className="degree">,
                                                        &nbsp;{item.specialization} &nbsp;
                                                      </span>}
                                                      {item.university && <span className="jobdates">
                                                        <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                                      </span>}
                                                      {item.year_of_completion && <span className="jobdates">
                                                        <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                                      </span>}
                                                    </span>
                                                  </span>
                                                </div>
                                              </div>
                                              {item.achievements != '[]' && item.achievements != '[null]' && (
                                                <div className="singlecolumn maincolumn" style={{ lineHeight: 'var(--line-height)' }}>
                                                  <br />
                                                  <ul>
                                                    {JSON.parse(item.achievements)?.map((item1, index) => (
                                                      item1 &&
                                                      <li key={index}>{item1}</li>
                                                    ))}
                                                  </ul>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div id="CONTAINER_3" className="right-box last-box containers">
                            <div className="sortable-item section-container SortableItem-sibling  data-SUMM mb175">
                              <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" className="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                                <div className=" doc-item">
                                  <div className="heading">
                                    <div className="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " className="rename-section text-rename"></span></div>
                                  </div>
                                  <div className="">
                                    <div className="">
                                      <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" className="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                        <div className="clearfix doc-item">
                                          <div className="singlecolumn" id="FIELD_FRFM">
                                            <p>{contact.summery}</p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sortable-item section-container SortableItem-sibling  data-HILT mb175">
                              <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" className="section SECTION_HILT    has-title  " data-section-cd="HILT">
                                <div className=" doc-item">
                                  <div className="heading">
                                    <div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div>
                                  </div>
                                  <div className="">
                                    <div className="">
                                      <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                        <div className="clearfix doc-item">
                                          <div className="singlecolumn maincolumn">
                                            {skills[0] &&
                                              <div className="skill">
                                                <span className="paddedline" id="FIELD_SKC1">
                                                  <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                                </span>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sortable-item section-container SortableItem-sibling  data-EXPR mb175">
                              <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" className="section experience SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                                <div className=" doc-item">
                                  <div className="heading">
                                    <div className="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " className="rename-section text-rename"></span></div>
                                  </div>
                                  <div className="">
                                    <div className="sortableInner">
                                      <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" className="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                        <div className="clearfix doc-item">
                                          {experience?.map((item, index) => (
                                            <div className="mb75">
                                              <div className="singlecolumn">
                                                <h4 className="paddedline locationGap" dependency="COMP|JSTA|JCIT|JCNT">
                                                  <span className="companyname txt-bold" id="FIELD_COMP">{item.title}{item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>} - {item.employer}</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> | </span></span>
                                                  <span className="jobcity" id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                                  <span className="jobstate" id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                                                </h4>
                                                <h4 className="paddedline">
                                                  <span className="jobdates" dependency="JSTD|EDDT">
                                                    <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                                    <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                                  </span>
                                                </h4>
                                                <span className="jobline" id="FIELD_JDES">
                                                  <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                                </span>
                                              </div>
                                              {item.achievements != '[]' && item.achievements != '[null]' && (
                                                <div className="singlecolumn maincolumn mb25">
                                                  <br />
                                                  <div>
                                                    {JSON.parse(item.achievements)?.map((item1, index) => (
                                                      item1 &&
                                                      <li key={index}>{item1}</li>
                                                    ))}
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                              <div
                                data-react-beautiful-dnd-draggable="2"
                                className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                              >
                                <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                                  <div className=" doc-item">
                                    <div className="heading">
                                      <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                    </div>
                                    <div className="">
                                      <div className="">
                                        <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                          <div className="clearfix doc-item">
                                            <div className="singlecolumn maincolumn">
                                              {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                                <div className="mb75">
                                                  <h4>Languages:</h4>
                                                  <span className="paddedline" id="FIELD_SKC1">
                                                    <ul>
                                                      {JSON.parse(more)?.language?.map((item, index) => (
                                                        <li key={item.language}>{item.language}</li>
                                                      ))}
                                                    </ul>
                                                  </span>
                                                </div>
                                              }
                                            </div>
                                            {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) &&
                                              <div className="singlecolumn maincolumn mb75">
                                                <h4>Website & Profiles:</h4>
                                                <span className="paddedline" id="FIELD_SKC1">
                                                  <div>
                                                    {JSON.parse(more)?.website?.map((item, index) => (
                                                      item.work &&
                                                      <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                                                    ))}
                                                    {JSON.parse(more)?.website?.map((item, index) => (
                                                      item.portfolio &&
                                                      <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                                                    ))}
                                                    {JSON.parse(more)?.website?.map((item, index) => (
                                                      item.profile &&
                                                      <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                                                    ))}
                                                  </div>
                                                </span>
                                              </div>
                                            }
                                            {JSON.parse(more).language &&
                                              <div className="singlecolumn maincolumn mb75">
                                                {JSON.parse(more)?.custom?.map((item, index) => (
                                                  <div>
                                                    <h4>{item.custom_name}</h4>
                                                    <span className="paddedline" id="FIELD_SKC1">
                                                      <p>
                                                        {item.ckeditor &&
                                                          <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />
                                                        }
                                                      </p>
                                                    </span>
                                                  </div>
                                                ))}
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            }
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div><div></div></div></div></li>

        </>
      ) : (
        <Loading />
      )}
    </div>


  );
});
export default Dynamictemp4;
