import React, { useState, useEffect } from 'react';
import Temp1 from "./Temp1";
import Temp2 from "./Temp2";
import Temp3 from "./Temp3";
import image1 from './Assets/templates/temp_1.png';
import image2 from './Assets/templates/temp_2.png';
import image3 from './Assets/templates/temp_3.png';
import image4 from './Assets/templates/temp_4.png';
import image5 from './Assets/templates/temp_5.png';
import image6 from './Assets/templates/temp_6.png';
import image7 from './Assets/templates/temp_7.png';
import image8 from './Assets/templates/temp_8.png';

import logo from "../img/logo.jpg";
import Loading from './Loading';
import { commonConfig } from '../config.js';
import {
	HashRouter as Router,
	Route,
	Link,
  useParams,
	Navigate,
	useNavigate,
	DefaultRoute
} from "react-router-dom";

const Template = () => {
  const { pagename } = useParams();
	const authToken = localStorage.getItem('authToken');
	const userId = localStorage.getItem('userId');
	const CommonData = localStorage.getItem('common_data');
	const navigate = useNavigate();
	const [page, setPage] = useState(false);

	const imageSources = [image1, image2, image3, image4, image5, image6, image7, image8];

	useEffect(() => {
		console.log('authToken', authToken);
		if (!userId) {
			navigate('/login');
		}

		// if (CommonData) {
		// 	if (JSON.parse(CommonData).plan.pack_status == 1) {
				
		// 	}else{
		// 		navigate('/plansfront');
		// 	} 
		// }

		import('./Css/Template.scss');
		setTimeout(() => {
			setPage(true);
		}, commonConfig.LOAD_TIME);
	}, [userId, navigate]);


	const [selectedTemplate, setSelectedTemplate] = useState(null);

	const handleTemplateSelect = (templateId) => {
		setSelectedTemplate(templateId);
	};

	const handleChooseLater = () => {
    if(pagename){
      navigate('/'+pagename);
    }else{
      navigate('/choose');
    }
		
	};

	const handleNext = () => {
		localStorage.setItem('template', selectedTemplate);
		if(pagename){
      navigate('/'+pagename);
    }else{
      navigate('/choose');
    }
	};

	return (
		<div className='templatepage'>
			{page ? (
				<div>
					<div className="page-title">
						<div>
							<h1 className="h1 p-title blue">Select Your Template</h1>
							<h3 className="h5 p-sub-title blue" style={{ marginTop: '1rem' }}>
								You Can Always Change Your Template Later.
							</h3>
						</div>
					</div>

					<div className="page1">
						{imageSources.map((imageSource, index) => (
							<div className="section_1 center">
								<div
									key={index}
									className='template center'
									onClick={() => handleTemplateSelect(index + 1)}
								>
									<img className={`template center ${selectedTemplate === index + 1 ? 'selected' : ''}`} src={imageSource} alt={`Template ${index + 1}`} />
								</div>
							</div>
						))}
					</div>
					<div className="button-container center template_buttons">
						<button className="btn btn-secondary" onClick={handleChooseLater}>
							Choose Later
						</button>&nbsp;&nbsp;&nbsp;
						{selectedTemplate && (
							<button onClick={handleNext} className="btn btn-mangai">
								Choose Template {selectedTemplate}
							</button>
						)}
					</div>


				</div>
			) : (
				<Loading content="Please Wait!!!" />
			)}
		</div>
	);
};

export default Template;







