import React, { Component, useEffect, useState, useRef } from "react";
import google_button from "../img/google_button.png";
import axios from "axios";
import link_button from "../img/link_button.png";
import MainNav from "./MainNav";
import Footer from "./Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BlogImage from "./Assets/blog.png";
import Quotes from "./Assets/quotes.png";

import Blog12 from "./Assets/blogs12.png";
import './Css/Viewblog.scss';
const Viewblog = (props) => {
	const title = localStorage.getItem('blog_title');
	const desc = localStorage.getItem('blog_desc');
	const image = localStorage.getItem('blog_image');

	const handleBlogClick = (blogData) => {

		console.log('Clicked blog data:', blogData);
	};
	

	useEffect(() => {
		axios.get('https://gojobs.biz/ai_api/blogs.php')
			.then(response => {
				setBlogs(response.data.data);
				// console.log("blogs",blogs);
			})
			.catch(error => {
				console.error("Error fetching blogs:", error);
			});

		console.log(title);
		// console.log(desc);
		console.log(image);
	}, []);
	const [blogs, setBlogs] = useState([]);


	const View = (title, desc, image) => {
		localStorage.setItem('blog_title', title);
		localStorage.setItem('blog_desc', desc);
		localStorage.setItem('blog_image', image);
		// navigate('/viewblog');
	};
	const getRandomBlogs = (blogsArray, currentTitle, count) => {
    const shuffledBlogs = shuffleArray(blogsArray);
    const uniqueBlogs = shuffledBlogs.filter(blog => blog.course_name !== currentTitle).slice(0, count);
    return uniqueBlogs;
  };

  // Helper function to shuffle the array
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

	return (
		<div className="viewblog">
			{title &&
				<div>
					<MainNav />
					<section className="banner">
						<div>
							<div className="col-lg-6" >
								<div className="banner_left">
									<h1 className="head1blog" style={{ lineHeight: '1.5' }}>{title}</h1>
								</div>
								<div
									className="head2blog"
									style={{ lineHeight: '1.5' }}
									dangerouslySetInnerHTML={{ __html: desc }}
								/>
							</div>
							<div className="col-lg-6" >
								<div className="image-container">
									{/* <img src={BlueCircle} className="blue_circle" alt="Blue Circle" /> */}
									<img src={image} className="blog_img" alt="Blog img" />


								</div>
							</div>

							{/* <div className="col-lg-8 order-1 order-lg-2 hero-img bg_image_blog">

                        </div> */}

						</div>

					</section>
					<h4 style={{display:'none'}} className="related_blogs">Related Blogs</h4>
					<div style={{display:'none'}}>
						
						<div className="grid-container">
						{getRandomBlogs(blogs, title, 3).map((blog, index) => (
								<div key={index}>
									{blog.course_name !== title ? (
										<div className="box" onClick={() => View(blog.course_name, blog.course_desc, blog.image)}>
											<div className="center">
												<img src={Quotes} />
											</div>
											<div>
                      <div style={{ textAlign: 'center' }}
                        dangerouslySetInnerHTML={{
                          __html: blog.short_desc.slice(0, 100) + (blog.short_desc.length > 100 ? "..." : "")
                        }}
                      />
                    </div>
											<div className="center">
												<div className="image-wrapper">
													<img src={blog.image} />
													<div className="blue-overlay"></div>
												</div>
												<div className="blog_content1" style={{ textAlign: "center" }}>
													{blog.course_name}
												</div>
											</div>
										</div>
									) : (
										<div></div>
									)}
								</div>
							))}
						</div>
					</div>

					<Footer />

				</div>

			}
		</div>

	);
};

export default Viewblog;




