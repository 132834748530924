import React, { useEffect } from 'react';
import google_button from '../img/google_button.png';
import link_button from '../img/link_button.png';
import './Css/Faqs.scss';
import Footer from './Footer';
import MainNav from './MainNav';
import Faq from './Assets/faq.png';
function Faqs() {
    useEffect(() => {
        const accordions = document.querySelectorAll('.accordion');

        const toggleAccordion = (accordion) => {
            accordion.classList.toggle('active');
            const panel = accordion.nextElementSibling;
            if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
            } else {
                panel.style.maxHeight = panel.scrollHeight + 'px';
            }
        };
        accordions.forEach((accordion) => {
            accordion.addEventListener('click', () => toggleAccordion(accordion));
        });
    }, []);

    return (
        <div className="faqs">
            <MainNav value="5"/>
            <div className="center" >
                <img src={Faq} alt="faq-img" style={{ marginTop: '77px' }} />
            </div>

            <div className='faq-1'>
                <button className="accordion">
                    <span className="circle-badge">1</span> How To Register?
                </button>
                <div className='panel'>
                    <p className='p_ans' >
                        Ans: Registration is effortless with just your email and basic details; no credit card details are required for a free trial
                    </p>
                </div>



                <button className="accordion"><span className="circle-badge">2</span>Can I Create My Resume For Free?</button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Certainly! The initial resume you generate is completely free of charge with some restrictions.
                    </p>
                </div>

                <button className="accordion">  <span className="circle-badge">3</span> Will I have access to all features during my free trial?</button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Being a Free trial we have a few restrictions which include restricted keyword selection, Customization of profile based on the job applied etc.


                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">4</span> What is a resume template?</button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: The resume template displays how your resume will appear, with three options available for free users to choose from, while paid users gain access to all available templates.
                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">5</span> Are the resume templates provided of professional quality?</button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Our resume templates are all recruiter-approved, reflecting our 25 years of experience as recruiters in the field.
                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">6</span> Can I change my template after creating my resume?? </button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Absolutely! You have the flexibility to make changes as many times as needed before finalizing and publishing your resume.
                    </p>
                </div>

                <button className="accordion"> <span className="circle-badge">7</span> What is the estimated time required to create my resume? </button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: The time taken to create a Resume depends on how much of the information you already have and how much in detail you would like to write. Generally, we have seen that it takes approximately 10 to 30 minutes to a new resume.


                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">8</span> What is the maximum number of times I can download my resume? </button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: You have the benefit of unlimited downloads.
                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">9</span> Is it possible to download my resume in either PDF or MS Word format?</button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: The option available for downloading your resume is limited to PDF format only.
                    </p>
                </div>

                <button className="accordion"> <span className="circle-badge">10</span> Is it possible to create resumes for multiple users using a single user account? </button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Unfortunately, you can create a resume for only one user using the provided user account.
                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">11</span> Is it possible to modify my name? </button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Your name gets locked once you create your first resume and you are expected to use the same name for all your future resumes.
                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">12</span> Is it possible to discontinue my usage and receive a refund? </button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Please note that we do not offer refunds for any of our packages. However, we provide a free plan for users to experience our product before making a purchase decision.
                    </p>
                </div>
                <button className="accordion"> <span className="circle-badge">13</span> Is it possible to utilize your resume builder to create my cover letter? </button>
                <div className="panel">
                    <p className='p_ans'>
                        Ans: Currently, we only support resume creation, but we assure you that cover letter creation will be available in the near future.
                    </p>
                </div>
                <button class="accordion" onclick="togglePanel()"> <span class="circle-badge">14</span>I don't see my question listed above! </button>
                <div class="panel" id="panel">
                    <p>
                        Ans: Feel free to reach out to us at support@gojobs.ai
                    </p>
                </div>
            </div>

            <Footer />
        </div>
    );
}
export default Faqs;
