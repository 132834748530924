import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate } from "react-router-dom";
import Nav from "./Nav"; import { commonConfig } from "../config.js";
import axios from "axios";
import link_button from "./Assets/plus.png";
import link_button2 from "./Assets/minus.png";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import Formtemplate from "./Formtemplate";

function Step5() {
  // const [tabsData, setTabsData] = useState([]);
  const [expData, setExpData] = useState([]);
  const [eduData, setEduData] = useState([]);
  const [respokData, setRespokData] = useState(true);
  const [page, setPage] = useState(false);
  const [stepno, setStepno] = useState(2);
  const resume_id = localStorage.getItem('resume_id');
  const userId = localStorage.getItem('userId');
  const CommonData = localStorage.getItem('common_data');
  const navigate = useNavigate();
  const [selectedExpDiv, setSelectedExpDiv] = useState([]);
  const [selectedEduDiv, setSelectedEduDiv] = useState([]);
  let is_edit = 0;

  const [formData, setFormData] = useState({
    experience: [],
    education: []
  });


  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    if (CommonData) {
      if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
        navigate('/step14');
      }
    }
    import("./Css/Step8.scss");

    if (resume_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        setExpData(result.experience);
        setEduData(result.education);

        result.education.forEach(item => {
          if(item.is_summery == 1){
            handleEduDivClick(item.id);
          }
        });
        result.experience.forEach(item => {
          if(item.is_summery == 1){
            handleExpDivClick(item.id);
          }
        });
        
        // console.log(result.experience);

        const contact = result.contact[0];
        if (contact.step == 100) {
          // navigate('/step13');
          setStepno(contact.step);
        } else {
          setStepno(contact.step + 1);
        }


      }).catch(err => {

      });
    }



    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const EditExp = (exp_id) => {
    navigate('/step2/' + exp_id);
  };

  const handleDelete = (exp_id) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this Experience?');
    if (isConfirmed) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "delete_exp",
        data: { exp_id: exp_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {

      }).catch(err => {

      });
    }
  };

  const handleExpDivClick = (id) => {
    const updatedFormData = { ...formData };
    // console.log("updatedFormData",updatedFormData);
    const experienceIds = updatedFormData.experience;
    const idIndex = experienceIds.indexOf(id);

    if (idIndex !== -1) {
      experienceIds.splice(idIndex, 1);
    } else {
      experienceIds.push(id);
    }
    setSelectedExpDiv(experienceIds);
    console.log("updatedFormData",updatedFormData);
    setFormData(updatedFormData);
  };
  const handleEduDivClick = (id) => {
    const updatedFormData = { ...formData };
    const experienceIds = updatedFormData.education;
    const idIndex = experienceIds.indexOf(id);
    

    if (idIndex !== -1) {
      experienceIds.splice(idIndex, 1);
    } else {
      experienceIds.push(id);
    }
    setSelectedEduDiv(experienceIds);
    console.log("updatedFormData",updatedFormData);
    setFormData(updatedFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setPage(false);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "summery/store",
      data: formData,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": 5, "isedit": is_edit },
    }).then(res => {
      var decodedData = res.data; 
      var status = res.status;
      if (status) {
        navigate('/step6');
      }
    }).catch(err => {

    });
  };



  return (
    <div className="step8page">
      {respokData ? (
        <main id="app" role="main">
          <section className="page-wrapper">
            <div
              id="parent"
              className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
            >
              {page && <Nav value={stepno} />}

              <div className="col-lg-12">
                <div className="col-lg-7">
                  <div className="exp_section">
                    <h3 className="headtag">
                      <span className="blue">Select Experience, Education and other highlights to create an impressive Profile Summary.</span>
                    </h3>
                    <p>Make your profile summary the right fit for the job you are applying to by providing all the information.</p>
                    <div className="exp_list">
                      <div className="m-10"><span className="f-17 blue fw-500">Select Profile</span> <span>(Max 2 Choices)</span></div>
                      <div className="exp_title">
                        {expData?.map((item, index) => (
                          <div className="m-10">
                            <input className="blue" onChange={() => handleExpDivClick(item.id)} checked={selectedExpDiv.includes(item.id)}  type="checkbox" name="working"/>&nbsp;&nbsp;&nbsp; 
                            <span>
                              {item.title}, {item.employer}, {item.city} - 
                              <span id="FIELD_JSTD" format="%m/%Y">{item.start_date}</span><span dependency="JSTD+EDDT"> - </span>
                              <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : item.end_date}</span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="exp_list">
                      <div className="m-10"><span className="f-17 blue fw-500">Select Education</span> <span>(Max 2 Choices)</span></div>
                      <div className="exp_title">
                        {eduData?.map((item, index) => (
                          <div className="m-10">
                            <input className="blue" onChange={() => handleEduDivClick(item.id)} checked={selectedEduDiv.includes(item.id)}  type="checkbox" name="working"/>&nbsp;&nbsp;&nbsp; 
                            <span>
                              {item.education}, {item.course} - {item.year_of_completion}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                      <Link onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                    </div>
                    <div className="col-lg-6 half" style={{ marginTop: '7%' }} >
                      <button type="submit" onClick={handleSubmit} className="btn-mangaii">Next</button>
                    </div>
                  </div>

                </div>
                <div className="col-lg-5 system_view">
                  <div className="api_right">
                    <Link to="/template/Step5" className="clickable p-10">Change Template</Link>
                    <Formtemplate />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <StepsFooter />
        </main>

      ) : (
        <div>
          <div className="system_view">
            <Loading content="Do Not Refresh the page as we are working on your Profile!" />
          </div>
          <div className="mobile_view">
            <Loading />
          </div>
        </div>

      )}
    </div>
  );
}

export default Step5;