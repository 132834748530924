import React, { Component, useEffect, useState, useRef } from "react";
import CoverNav from "./CoverNav";
import avatar from "./Assets/avatar.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  HashRouter as Router,
  Route,
  Link,
  Navigate,
  useNavigate,
  DefaultRoute
} from "react-router-dom";
import { commonConfig } from '../config.js';
import axios from "axios";
import Loading from './Loading';
import StepsFooter from './StepsFooter';

function CoverStep2() {

  const [stepno, setStepno] = useState(2);
  const [page, setPage] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const cover_id = localStorage.getItem('cover_id');

  const [formData, setFormData] = useState({
    company: '',
    job_applied_to: '',
    department: '',
    location: '',
    level: '',
    description: '',
    reporting_to: '',
    current_ctc: '',
    position_ctc: '',
    hr_name: '',
    hr_email: '',
    cover_id: cover_id
  });

  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    getEditdata();
    import('./Css/CoverStep.scss');
    console.log("cover_id",cover_id);
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const getEditdata = () =>{
    if (cover_id) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "cover/finalize",
				data: { cover_id: cover_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;

        setFormData({
          company: result.company,
          job_applied_to: result.job_applied_to,
          department: result.department,
          location: result.location,
          level: result.level,
          description: result.description,
          reporting_to: result.reporting_to,
          current_ctc: result.current_ctc,
          position_ctc: result.position_ctc,
          hr_name: result.hr_name,
          hr_email: result.hr_email,
          cover_id: cover_id
        });
        if(result.step == 4){
          setStepno(result.step);
        }else{
          setStepno(result.step + 1);
        }
        

			}).catch(err => {

			});
		}
  }

  const handleInputChange = (event) => {
		const { id, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[id]: value
		}));
		validate();
	};

  
  const handleNext = () => {
    if (validate()) {
      console.log("formData",formData);
			setPage(false);
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "cover/Step2",
				data: formData,
				headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + commonConfig.API_TOKEN, "user_id": userId, "step": stepno},
			}).then(res => {
				var decodedData = res.data;
				var status = res.status;
				if (status) {
          setPage(true);
          navigate('/coverStep3');
				}
			}).catch(err => {

			});
		}
  };

  const validate = () => {
		let errorObj = {};
		const newErrors = [];
    
		if (formData.company == null) {
			errorObj.company = "Company Name required.";
		} else if (formData.job_applied_to == null) {
			errorObj.job_applied_to = "Job Applied To required.";
		} else if (formData.department == null) {
			errorObj.department = "Department required.";
		} else if (formData.location == null) {
			errorObj.location = "Location required.";
		} else if (formData.level == null) {
			errorObj.level = "Level required.";
		} else if (formData.description == null) {
			errorObj.description = "Description required.";
		} else if (formData.hr_name == null) {
			errorObj.hr_name = "Name of the HR required.";
		} else if (formData.hr_email == null) {
			errorObj.hr_email = "Email of the HR required.";
		} 
		setErrors(errorObj);
		if (Object.keys(errorObj).length > 0) {
			console.log("errorObj", errorObj); return false;
		}
		return true;
	};

  



  return (

    <div className="step1page">
      {page ? (
        <div>
          <ToastContainer position="top-center" theme="dark" />
          <main id="app" role="main">
            <section className="page-wrapper">
              <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
                <CoverNav value={stepno} />
                <div className="step1_sec">
                  <div className="inner1_step1" style={{ textAlign: 'center' }}>
                    <h3 className="headtag"><span className="blue">Job Application Information</span>
                    </h3>
                    <p style={{ paddingBottom: '6%', fontWeight: '200px;' }} className="p_tag w-50 m-auto w-100">Please provides the specifics of the position you are applying for so that we can craft a more tailored and effective cover letter for you.</p>
                  </div>
                  <form action="" method="">
                    <div className="col-md-12 w-80 m-auto w-100">
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="company" className="formcontrol" value={formData.company} onChange={handleInputChange} />
                          <label className="formlabel">
                            Company Applied
                          </label>
                          {errors && errors.company && (
                            <span className="error">{errors.company}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="job_applied_to" className="formcontrol" value={formData.job_applied_to} onChange={handleInputChange} />
                          <label className="formlabel">
                            Job Applied To
                          </label>
                          {errors && errors.job_applied_to && (
                            <span className="error">{errors.job_applied_to}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="department" className="formcontrol" value={formData.department} onChange={handleInputChange} />
                          <label className="formlabel">
                            Department/Division/Area of work
                          </label>
                          {errors && errors.department && (
                            <span className="error">{errors.department}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="location" className="formcontrol" value={formData.location} onChange={handleInputChange} />
                          <label className="formlabel">
                            Location
                          </label>
                          {errors && errors.location && (
                            <span className="error">{errors.location}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="level" className="formcontrol" value={formData.level} onChange={handleInputChange} />
                          <label className="formlabel">
                            Level
                          </label>
                          {errors && errors.level && (
                            <span className="error">{errors.level}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="description" className="formcontrol" value={formData.description} onChange={handleInputChange} />
                          <label className="formlabel">
                            Job Description
                          </label>
                          {errors && errors.description && (
                            <span className="error">{errors.description}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="reporting_to" className="formcontrol" value={formData.reporting_to} onChange={handleInputChange} />
                          <label className="formlabel">
                            Job Reporting to Designation (optional)
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="col-lg-6" style={{padding:'0 3px 0 0px'}}>
                          <div className="formgroup">
                            <input type="text" id="current_ctc" className="formcontrol" value={formData.current_ctc} onChange={handleInputChange} />
                            <label className="formlabel">
                              Current CTC (optional)
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-6" style={{padding:'0px'}}>
                          <div className="formgroup">
                            <input type="text" id="position_ctc" className="formcontrol" value={formData.position_ctc} onChange={handleInputChange} />
                            <label className="formlabel">
                              Position CTC (optional)
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="hr_name" className="formcontrol" value={formData.hr_name} onChange={handleInputChange} />
                          <label className="formlabel">
                            Name of the HR Manager/Recruiter
                          </label>
                          {errors && errors.hr_name && (
                            <span className="error">{errors.hr_name}</span>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="formgroup">
                          <input type="text" id="hr_email" className="formcontrol" value={formData.hr_email} onChange={handleInputChange} />
                          <label className="formlabel">
                            Email id of the HR Manager/Recruiter
                          </label>
                          {errors && errors.hr_email && (
                            <span className="error">{errors.hr_email}</span>
                          )}
                        </div>
                      </div>

                    </div>
                    <div className="col-md-12 w-80 m-auto w-100">
                      <div className="col-lg-6 half">
                        <button id="btnBack" type="button" className="btn-stepsback">Back</button>
                      </div>
                      <div className="col-lg-6 half">
                        <button id="btnBack" type="button" onClick={handleNext} className="btn-mangaii" >
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </main>
          <StepsFooter />
        </div>
      ) : (
        <Loading content="Please Wait!!!" />
      )}
    </div>
  );
}

export default CoverStep2;
