import React, { useEffect, useState, useRef } from "react";
import { commonConfig } from '../config.js';
import Modal from 'react-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Dynamictemp1 from "./Dynamictemp1";
import Dynamictemp2 from "./Dynamictemp2";
import Dynamictemp3 from "./Dynamictemp3";
import Dynamictemp4 from "./Dynamictemp4";
import Dynamictemp5 from "./Dynamictemp5";
import Dynamictemp6 from "./Dynamictemp6";
import Dynamictemp7 from "./Dynamictemp7";
import Dynamictemp8 from "./Dynamictemp8";
import Dytemp1 from "./Dytemp1";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Nav from "./Nav";
import image1 from './Assets/templates/temp_1.png';
import image2 from './Assets/templates/temp_2.png';
import image3 from './Assets/templates/temp_3.png';
import image4 from './Assets/templates/temp_4.png';
import image5 from './Assets/templates/temp_5.png';
import image6 from './Assets/templates/temp_6.png';
import image7 from './Assets/templates/temp_7.png';
import image8 from './Assets/templates/temp_8.png';
import Preview from './Assets/Preview.jpeg';
import button1 from './Assets/Button_1.png';
import button2 from './Assets/Button_2.png';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faDownload, faPrint, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ChromePicker, TwitterPicker,CompactPicker } from 'react-color';
import { FaTimes } from 'react-icons/fa';

Modal.setAppElement('#root');

const CustomPrevArrow = (props) => {

	const { className, style, onClick } = props;


	return (
		<div
			className={className}
			style={{ ...style, width: '50px', backgroundSize: 'cover', height: '50px', backgroundImage: `url(${button1})` }}
			onClick={onClick}
		/>
	);
}

const CustomNextArrow = (props) => {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, width: '50px', backgroundSize: 'cover', height: '50px', backgroundImage: `url(${button2})` }}
			onClick={onClick}
		/>
	);
}

function Step13() {



	const [isModalOpen, setIsModalOpen] = useState(false);
	const [selectedImageIndex, setSelectedImageIndex] = useState(null);
	const [selectedComponent, setSelectedComponent] = useState(null);
	const myComponentRef = useRef(null);
	const resume_id = localStorage.getItem('resume_id');
	const [isOpen, setIsOpen] = useState(false);
	const [options, setOptions] = useState(false);
	const [publish, setPublish] = useState(true);
	const [download, setDownload] = useState(false);
	const navigate = useNavigate();
	const [resp, setResp] = useState([]);
	const [plan, setPlan] = useState([]);
	const [stepno, setStepno] = useState(12);
	const userId = localStorage.getItem('userId');
	const email = localStorage.getItem('email');
	const CommonData = localStorage.getItem('common_data');
	const authToken = localStorage.getItem('authToken');
	const [page, setPage] = useState(false);
	const [templates, setTemplates] = useState(false);
	const [coloropen, setColoropen] = useState(false);
	const [editing, setEditing] = useState(false);
	const [ispreview, setIsPreview] = useState(false);
	const [ispaid, setIsPaid] = useState(false);
	const [pdfUrl, setPdfUrl] = useState('');
	const [iframeKey, setIframeKey] = useState(0);
	const [fontsize, setFontsize] = useState("12px");
	const [spacing, setSpcing] = useState("1.3");
	const [justify, setJustify] = useState("left");
	const [color, setColor] = useState("#8d9ec4");
	const [resumename, setResumename] = useState(email + '_' + resume_id);
	const [family, setFamily] = useState("roboto");
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const template = localStorage.getItem('template');

	const computedStyle = getComputedStyle(document.documentElement);
	const color1 = computedStyle.getPropertyValue('--color-1');
	const color2 = computedStyle.getPropertyValue('--color-2');
	const color3 = computedStyle.getPropertyValue('--color-3');
	const color4 = computedStyle.getPropertyValue('--color-4');

	useEffect(() => {
		import('./Css/Step13.scss');

		document.documentElement.style.setProperty('--font-size', '12px');
    if(template){
      handleClickImage(template-1);
    }else{
      handleClickImage(0);
    }
		
		if (!userId) {
			navigate('/login');
		}
		my_cvs();
		// toggleModal();
		if (resume_id) {


			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "finalize",
				data: { resume_id: resume_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
				const step = result.contact[0]?.step;
				if (step == 100) {
					setDownload(true);
					setStepno(step);
					// setPublish(false);
				}
			}).catch(err => {

			});
		}

		if (CommonData) {
			if (JSON.parse(CommonData).plan.pack_status == 1) {
				setIsPaid(true);
			} else {
				setIsPaid(false);
			}
			setPlan(JSON.parse(CommonData).plan);
			console.log("plan", CommonData)
		}

		setTimeout(() => {
			setPage(true);
		}, commonConfig.LOAD_TIME);

		// my_cvs();
	}, []);



	const handlefontChange = (event) => {
		const selectedValue = event.target.value;
		console.log("selectedValue", selectedValue);
		setFontsize(selectedValue);
		document.documentElement.style.setProperty('--font-size', selectedValue);
	};
	const handlespaceChange = (event) => {
		const selectedValue = event.target.value;
		setSpcing(selectedValue);
		document.documentElement.style.setProperty('--line-height', selectedValue);
	};
	const handlejustifyChange = (event) => {
		const selectedValue = event.target.value;
		setJustify(selectedValue);
		document.documentElement.style.setProperty('--justify', selectedValue);
	};
	const handlecolorChange = (selectedColor) => {
		console.log(selectedColor);
		setColor(selectedColor.hex);
		document.documentElement.style.setProperty('--theme-color', selectedColor.hex);
	};

	// const handlecolorChange = (event) => {
	//   const selectedValue = event.target.value;
	//   console.log(selectedValue);
	//   setColor(selectedValue);
	//   document.documentElement.style.setProperty('--theme-color', selectedValue);
	// };
	const handlefamilyChange = (event) => {
		const selectedValue = event.target.value;
		setFamily(selectedValue);
		document.documentElement.style.setProperty('--font-family', selectedValue);
	};
	const handleNameChange = (event) => {
		// const selectedValue = 
		setResumename(event.target.value);
	};
	const handleOpen = () => {
		if (resumename == '') {
			toast.error('Resume Name Required!!!');
			return false;
		}
		setIsOpen(true);
	};

	const preview = () => {

		if (screenWidth > 900) {
			const pdf = commonConfig.PDF_URL + resumename + ".pdf#toolbar=0";
			setPdfUrl(pdf);
		} else {
			const pdf = commonConfig.PDF_URL + resumename + ".pdf";
			const pdf_id = 'https://docs.google.com/viewer?url=' + pdf + '&embedded=true';
			setPdfUrl(pdf_id);

		}
		setIsPreview(true);

		setIframeKey(prevKey => prevKey + 1);
	};

	const handleClose = () => {
		setIsOpen(false);
	};
	const handleClose1 = () => {
		setIsPreview(false);
	};
	const openTemplates = () => {
		setTemplates(!templates);
		setEditing(false);
	};
	const openColorchange = () => {
		setColoropen(!coloropen);
	};
	const handleChangename = () => {
		setTemplates(!templates);
	};
	const openEditing = () => {
		setEditing(!editing);
		setTemplates(false);
	};

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
		setSelectedImageIndex(null); // Reset the selected image index
	};

	const carouselSettings = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 1,
	};
	const settings = {
		arrows: true,
		dots: true,
		infinite: false,
		speed: 500,
		slidesToShow: 3,
		mobileFirst: true,
		nextArrow: <CustomNextArrow />,
		prevArrow: <CustomPrevArrow />,
		className: 'react__slick__slider__parent'
	};
	let pack = JSON.parse(CommonData).plan.pack;
	let images = [];
	if (pack == 'Free') {
		images = [image1, image2, image3];
	} else {
		images = [image1, image2, image3, image4, image5, image6, image7, image8];

	}
	let slidesToShow = "";
	if (screenWidth > 900) {
		slidesToShow = images.length <= 7 ? images.length : 7;
	} else {
		slidesToShow = images.length <= 3 ? images.length : 3;
	}


	const handleClickImage = (index) => {
		setSelectedImageIndex(index); // Set the selected image index
		console.log("joe",index);
		// Set the selected component based on the index
		switch (index) {
			case 0:
				setSelectedComponent(<Dynamictemp1 ref={myComponentRef} />);
				break;
			case 1:
				setSelectedComponent(<Dynamictemp2 ref={myComponentRef} />);
				break;
			case 2:
				setSelectedComponent(<Dynamictemp3 ref={myComponentRef} />);
				break;
			case 3:
				setSelectedComponent(<Dynamictemp4 ref={myComponentRef} />);
				break;
			case 4:
				setSelectedComponent(<Dynamictemp5 ref={myComponentRef} />);
				break;
			case 5:
				setSelectedComponent(<Dynamictemp6 ref={myComponentRef} />);
				break;
			case 6:
				setSelectedComponent(<Dynamictemp7 ref={myComponentRef} />);
				break;
			case 7:
				setSelectedComponent(<Dytemp1 ref={myComponentRef} />);
				break;
			default:
				setSelectedComponent(<Dynamictemp1 ref={myComponentRef} />);
		}
		setTemplates(!templates);
	};

	const ModalContent = ({ closeModal }) => {
		return (
			<div>
				
				<div className="system_view">
					
					{publish && <div className={`josh pale_blue_bg p-10 ${templates ? "" : "hide"
						}`}>
						<button onClick={openTemplates} className="close_icon">
							<FaTimes style={{ width: '15px',height:'20px',color:'#000' }} />
						</button>	
						<Slider {...settings} slidesToShow={slidesToShow}>
							{images.map((image, index) => (
								<div
									key={index}
									onClick={() => handleClickImage(index)}
									className={`image-container ${selectedImageIndex === index ? "" : ""
										}`}
								>
									<img
										src={image}
										className={`image-container ${selectedImageIndex === index ? "selected" : ""
											}`}
										alt={`Image ${index + 1}`}
										style={{ width: "150px", height: "auto", }} // Set the desired image size
									/>
								</div>
							))}
						</Slider>
					</div>}
					<div className={`josh pale_blue_bg p-10 center ${editing ? "" : "hide"}`}>
						<button className="close_icon" onClick={openEditing}>
							<FaTimes style={{ width: '15px',height:'20px',color:'#000' }} />
						</button>
						<div className="formgroup">
							<b style={{ color: '#000' }}>Font Size : </b>
							<select name="font-size" className="d_down" value={fontsize} onChange={handlefontChange}>
								<option value="9px">9px</option>
								<option value="10px">10px</option>
								<option value="11px">11px</option>
								<option value="12px">12px</option>
								<option value="13px">13px</option>
								<option value="14px">14px</option>
								<option value="15px">15px</option>
								<option value="16px">16px</option>
								<option value="17px">17px</option>
								<option value="18px">18px</option>
								<option value="19px">19px</option>
							</select>
						</div>

						<div className="formgroup" style={{ marginLeft: '20px' }}>
							<b style={{ color: '#000' }}>Line Spacing : </b>
							<select name="font-size" className="d_down" value={spacing} onChange={handlespaceChange}>
								<option value="0.9">0.9</option>
								<option value="1.0">1.0</option>
								<option value="1.1">1.1</option>
								<option value="1.2">1.2</option>
								<option value="1.3">1.3</option>
								<option value="1.4">1.4</option>
								<option value="1.5">1.5</option>
								<option value="1.6">1.6</option>
								<option value="1.7">1.7</option>
								<option value="1.8">1.8</option>
								<option value="1.9">1.9</option>
							</select>
						</div>
						<div className="formgroup" style={{ marginLeft: '20px' }}>
							<b style={{ color: '#000' }}>Alignment : </b>
							<select name="justify" className="d_down" value={justify} onChange={handlejustifyChange}>
								<option value="left">Left</option>
								<option value="justify">Justify</option>
							</select>
						</div>

						<div className="formgroup" style={{ marginLeft: '20px' }}>
							{/* <b style={{color:'#000'}}>Color : </b>         */}
							<button type="button" onClick={openColorchange} className="btn-riz ftr_btn">Color</button>
							{coloropen && <CompactPicker color={color} onChange={handlecolorChange} />}
						</div>
					</div>
					<div>
						<div className="center bg_blue">
							{publish && <button type="button" onClick={openTemplates} className="btn-riz ftr_btn">Templates</button>}
							{publish && <button type="button" onClick={openEditing} className="btn-riz ftr_btn">Edit</button>}
							{/* {publish && <button type="button" onClick={() => downloadPdf('save')} className="btn-mangaii ftr_btn">Save and Preview</button>} */}
							{publish && <button type="button" className="btn-riz ftr_btn">Save</button>}
							{publish && <button type="button" onClick={handleSubmit} className="btn-mangaii ftr_btn">Download</button>}
							{publish && <button type="button" onClick={handleSubmitWord} className="btn-mangaii ftr_btn">Download Word</button>}
						</div>
					</div>
				</div>

				<div className="mobile_view">
					{publish && <div className={`josh pale_blue_bg p-10 ${templates ? "" : "hide"
						}`}>
						<button onClick={openTemplates} className="close_icon">
							<FaTimes style={{ width: '15px',height:'20px',color:'#000' }} />
						</button>	
						<Slider {...settings} slidesToShow={slidesToShow}>
							{images.map((image, index) => (
								<div
									key={index}
									onClick={() => handleClickImage(index)}
									className={`image-container ${selectedImageIndex === index ? "" : ""
										}`}
								>
									<img
										src={image}
										className={`image-container slider-temp ${selectedImageIndex === index ? "selected" : ""
											}`}
										alt={`Image ${index + 1}`}
										style={{ width: "150px", height: "auto", }} // Set the desired image size
									/>
								</div>
							))}
						</Slider>
					</div>}
					<div className={`josh pale_blue_bg p-10 center ${editing ? "" : "hide"}`}>
						<button className="close_icon" onClick={openEditing}>
							<FaTimes style={{ width: '15px',height:'20px',color:'#000' }} />
						</button>
						<div className="formgroup">
							<b style={{ color: '#000' }}>Font Size : </b>
							<select name="font-size" className="d_down" value={fontsize} onChange={handlefontChange}>
								<option value="9px">9px</option>
								<option value="10px">10px</option>
								<option value="11px">11px</option>
								<option value="12px">12px</option>
								<option value="13px">13px</option>
								<option value="14px">14px</option>
								<option value="15px">15px</option>
								<option value="16px">16px</option>
								<option value="17px">17px</option>
								<option value="18px">18px</option>
								<option value="19px">19px</option>
							</select>
						</div>

						<div className="formgroup" >
							<b style={{ color: '#000' }}>Line Spacing : </b>
							<select name="font-size" className="d_down" value={spacing} onChange={handlespaceChange}>
								<option value="0.9">0.9</option>
								<option value="1.0">1.0</option>
								<option value="1.1">1.1</option>
								<option value="1.2">1.2</option>
								<option value="1.3">1.3</option>
								<option value="1.4">1.4</option>
								<option value="1.5">1.5</option>
								<option value="1.6">1.6</option>
								<option value="1.7">1.7</option>
								<option value="1.8">1.8</option>
								<option value="1.9">1.9</option>
							</select>
						</div>
						<div className="formgroup" >
							<b style={{ color: '#000' }}>Alignment : </b>
							<select name="justify" className="d_down" value={justify} onChange={handlejustifyChange}>
								<option value="left">Left</option>
								<option value="justify">Justify</option>
							</select>
						</div>

						<div className="formgroup" >
							<b style={{ color: '#000' }}>Color : </b>
							{/* <input type="color" value="#CB454E" onChange={handlecolorChange} /> */}
							<button type="button" onClick={openColorchange} className="btn-riz ftr_btn1">Color</button>
							{coloropen && <CompactPicker color={color} onChange={handlecolorChange} />}
						</div>
					</div>
					<div>
						<div className="bg_blue">
							<div className="half">
								{publish && <button type="button" onClick={openTemplates} className="btn-riz ftr_btn">Templates</button>}
							</div>
							<div className="half">
								{publish && <button type="button" onClick={openEditing} className="btn-riz ftr_btn">Edit</button>}
							</div>
							<div className="half">
                {publish && <button type="button" className="btn-riz ftr_btn">Save</button>}    
              </div>
							<div className="half">
								{publish && <button type="button" onClick={handleSubmit} className="btn-riz ftr_btn">Download</button>}
							</div>
						</div>
					</div>
				</div>
			</div>


		);
	};

	const downloadPdf = (title) => {

		if (resumename == '') {
			toast.error('Resume Name Required!!!');
			return false;
		}

		setPage(false);
		myComponentRef.current.printDocument(title, fontsize, spacing, color, resumename, justify);

		setTimeout(() => {
			setPage(true);
			preview();
		}, 2000);
	};
	const printPdf = () => {
		myComponentRef.current.print();
	};

  const DownloadCV = (resume_name) => {
    const fileUrl = commonConfig.PDF_URL + resume_name + ".pdf";
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = resume_name+'.pdf'; // Specify the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

	const handleSubmit = (event) => {
		event.preventDefault();
		myComponentRef.current.printDocument('save', fontsize, spacing, color, resumename, justify);

    // if(response_from_template){
    //   console.log("response_from_template",response_from_template);
    // }
    if(ispaid){
      setTimeout(() => {
        // DownloadCV(resumename);
      }, 2000);
    }else{
      navigate('/plans');
    }
    
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "experience/store",
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": 100, 'isedit': 0, "name": resumename,template:selectedImageIndex+1 },
		}).then(res => {
			var decodedData = res.data;
			var status = res.status;
			if (status) {
				navigate('/plans');
				// navigate('/step14');
			}
		}).catch(err => {

		});

	};
	const handleSubmitWord = (event) => {
		event.preventDefault();
		myComponentRef.current.downloadWordDoc();

	};


	const my_cvs = () => {
		axios({
			method: "POST",
			data: { userId: userId },
			url: commonConfig.REACT_APP_API_URL + "my_cvs",
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
		}).then(res => {
			var decodedData = res.data;
			const result = decodedData.data;
			localStorage.setItem('common_data', JSON.stringify(result));
			setResp(result.contact);
			setPlan(result.plan);
		}).catch(err => {

		});
	}


	return (
		<div className="step13page">
			{/* <ToastContainer position="top-center" theme="dark" /> */}
			{page ? (
				<div>
					<main id="app" role="main">
						<section className="page-wrapper">
							<div
								id="parent"
								className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
							>
								<Nav value={stepno} action="12" />
								<div className={` ${isModalOpen ? "centered-content" : "centered-content-1"
									}`}>
									<div className="note">
										<p className="p_tag note_tag">
											{publish && <p><b style={{ color: '#000', width: '20%' }}>Resume Name : <input type="text" value={resumename} onChange={handleNameChange} /></b></p>}
										</p>
									</div>

									<div className="col-lg-6 resume_pdf_preview">
										{ispreview && <div>
											{pdfUrl != '' && <div style={{ width: '100%', height: '1000px' }}>
												<iframe
													src={pdfUrl}
													title="PDF Preview"
													key={iframeKey}
													width="100%"
													height="100%"
													style={{ border: 'none' }}
												></iframe>
											</div>}
										</div>}
										{!ispreview && <div className="center"> <img style={{ width: '50%' }} src={Preview} /> </div>}
									</div>

									<div className="preview_html">
										{selectedComponent}
									</div>

								</div>
							</div>

							<div>

							</div>



							{isOpen && (
								<div className="popup-overlay">
									<div className="popup-container">
										<p style={{ marginTop: '20px', fontSize: '20px', fontWeight: '500', textAlign: 'center', color: 'red' }}>This is the final submission</p>

										<p style={{ marginTop: '20px', fontSize: '16px', fontWeight: '600', textAlign: 'center' }}>Downloading, Printing, Emailing or Publishing </p>
										<p style={{ marginTop: '20px', fontWeight: '500', textAlign: 'center' }} >Will restrict further editing of this resume. However, you are free to download, print, or email the same resume multiple times </p>
										<p style={{ marginTop: '20px', fontWeight: '500', textAlign: 'center' }} >Would you like to proceed? </p>

										<button id="btnBack" type="button" className="btn-stepsback" style={{ marginTop: '40px' }} onClick={handleClose}>No</button>
										<button id="btnBack" onClick={handleSubmit} type="submit" className="btn-mangaii" style={{ marginTop: '40px' }}  >Yes</button>
									</div>
								</div>
							)}
							{ispreview && (
								<div className="popup-overlay mobile_view">
									<div className="popup-container pdf_preview_popup">
										<FontAwesomeIcon className="close-button" onClick={handleClose1} icon={faTimes} />
										<div style={{ width: '100%', height: '1000px' }}>
											{/* <iframe
                          src={pdfUrl}
                          title="PDF Preview"
                          key={iframeKey}
                          width="100%"
                          height="100%"
                          style={{ border: 'none' }}
                        ></iframe> */}
											<iframe src={pdfUrl} width="100%" height="100%"> </iframe>


										</div>
									</div>
								</div>
							)}
						</section>
					</main>
					<div className="st_footer">
						<ModalContent closeModal={toggleModal} />
					</div>
				</div>
			) : (
				<Loading />
			)}
		</div>
	);
}

export default Step13;