import React, { Component, useEffect, useState, useRef } from "react";
import CoverNav from "./CoverNav";
import avatar from "./Assets/avatar.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  HashRouter as Router,
  Route,
  Link,
  Navigate,
  useNavigate,
  DefaultRoute
} from "react-router-dom";
import { commonConfig } from '../config.js';
import axios from "axios";
import Loading from './Loading';
import StepsFooter from './StepsFooter';

function CoverStep1() {

  const [stepno, setStepno] = useState(1);
  const [page, setPage] = useState(false);
  const [isEdit, setIsEdit] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [customcount, setCustomcount] = useState(0);
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  const coverResumeId = localStorage.getItem('coverResumeId');
  const cover_id = localStorage.getItem('cover_id');

  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    console.log("coverResumeId",coverResumeId);
    import('./Css/CoverStep.scss');

    getEditdata();

    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);

  const handleOptionChange = (event) => {
    console.log(event.target.value);
    setSelectedOption(event.target.value);
  };
  const handleOptionChange1 = (event) => {
    setSelectedOption1(event.target.value);
  };

  const getEditdata = () =>{
    if (cover_id) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "cover/finalize",
				data: { cover_id: cover_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
        setSelectedOption(result.tone);
        if(result.length != 250 && result.length != 400){
          setSelectedOption1('custom');
          setCustomcount(result.length);
        }else{
          setSelectedOption1(result.length);
        }
        if(result.step == 4){
          setStepno(result.step);
        }else{
          setStepno(result.step + 1);
        }
        setIsEdit(1);

			}).catch(err => {

			});
		}
  }

  const handleNext = () => {
    
    let data = {
      'tone':selectedOption,
      'length':selectedOption1,
      'size':customcount,
      "resume_id":coverResumeId,
      "isEdit":isEdit,
      "cover_id":cover_id,
      "user_id": userId
    }
    setPage(false);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "cover/Step1",
      data: data,
      headers: { "Content-Type": "multipart/form-data", "Authorization": "Bearer " + commonConfig.API_TOKEN,  "step": 1},
    }).then(res => {
      var decodedData = res.data;
      var status = res.status;
      if (status) {
        localStorage.setItem('cover_id', decodedData.data.id);
        navigate('/coverStep2');
      }
    }).catch(err => {

    });
    // navigate('/coverStep3');
  };

  return (

    <div className="step1page">
      {page ? (
        <div>
          <ToastContainer position="top-center" theme="dark" />
          <main id="app" role="main">
            <section className="page-wrapper">
              <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
                <CoverNav value={stepno} />
                <div className="step1_sec">
                  <div className="inner1_step1" style={{ textAlign: 'center' }}>
                    <h3 className="headtag"><span className="blue">Select the tone & size of the cover letter</span>
                    </h3>
                    <p style={{ paddingBottom: '6%', fontWeight: '200px;' }} className="p_tag w-50 m-auto w-100">Customize the tone based on the job requirements and the nature of the job industry. If the inductry is more creative, you can infuse a bit of creativity into the tone.</p>
                  </div>
                  <form action="" method="">
                    <div className="col-md-12 w-80 m-auto shadow w-100">
                      <div className="col-md-12">
                        <div className="bold subtitle_padding">Select the tone of the cover letter</div>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Assertive" checked={selectedOption === "Assertive"} onChange={handleOptionChange} />
                          <span className="radio-label">Assertive</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Candid" checked={selectedOption === "Candid"} onChange={handleOptionChange} />
                          <span className="radio-label">Candid</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Casual" checked={selectedOption === "Casual"} onChange={handleOptionChange} />
                          <span className="radio-label">Casual</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Compassionate" checked={selectedOption === "Compassionate"} onChange={handleOptionChange} />
                          <span className="radio-label">Compassionate</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Convincing" checked={selectedOption === "Convincing"} onChange={handleOptionChange} />
                          <span className="radio-label">Convincing</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Earnest" checked={selectedOption === "Earnest"} onChange={handleOptionChange} />
                          <span className="radio-label">Earnest</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Enthusiastic" checked={selectedOption === "Enthusiastic"} onChange={handleOptionChange} />
                          <span className="radio-label">Enthusiastic</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Formal" checked={selectedOption === "Formal"} onChange={handleOptionChange} />
                          <span className="radio-label">Formal</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Humble" checked={selectedOption === "Humble"} onChange={handleOptionChange} />
                          <span className="radio-label">Humble</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Humors" checked={selectedOption === "Humors"} onChange={handleOptionChange} />
                          <span className="radio-label">Humors</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Informative" checked={selectedOption === "Informative"} onChange={handleOptionChange} />
                          <span className="radio-label">Informative</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Inspirational" checked={selectedOption === "Inspirational"} onChange={handleOptionChange} />
                          <span className="radio-label">Inspirational</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Throughtful" checked={selectedOption === "Throughtful"} onChange={handleOptionChange} />
                          <span className="radio-label">Throughtful</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Urgent" checked={selectedOption === "Urgent"} onChange={handleOptionChange} />
                          <span className="radio-label">Urgent</span>
                        </label>
                      </div>
                      <div className="col-md-2-5">
                        <label className="radio-button">
                          <input type="radio" value="Worried" checked={selectedOption === "Worried"} onChange={handleOptionChange} />
                          <span className="radio-label">Worried</span>
                        </label>
                      </div>
                    </div><br/>

                    <div className="col-md-12 w-80 m-auto shadow w-100">
                      <div className="col-md-12">
                        <div className="bold subtitle_padding">Select length of the cover letter</div>
                      </div>
                      
                      <div className="col-md-12 p-30">
                        <label className="radio-button">
                          <input type="radio" value="250" checked={selectedOption1 == "250"} onChange={handleOptionChange1} />
                          <span className="radio-label">Cover letter approx. 250 words</span>
                        </label>
                      </div>
                      <div className="col-md-12 p-30">
                        <label className="radio-button">
                          <input type="radio" value="400" checked={selectedOption1 == "400"} onChange={handleOptionChange1} />
                          <span className="radio-label">Cover letter approx. 400 words</span>
                        </label>
                      </div>
                      <div className="col-md-12 p-30">
                        <label className="radio-button">
                          <input type="radio" value="custom" checked={selectedOption1 == "custom"} onChange={handleOptionChange1} />
                          <span className="radio-label">Custom length cover letter <input type="text" value={customcount} className="custom_box" onChange={(e) => setCustomcount(e.target.value)} /> words</span>
                        </label>
                      </div>
                    </div><br/>

                    <div className="col-md-12">
                      <div className="col-lg-6 half">
                        <button id="btnBack" type="button"  className="btn-stepsback">Back</button>
                      </div>
                      <div className="col-lg-6 half">
                        <button id="btnBack" type="button" onClick={handleNext} className="btn-mangaii" >
                          Next
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </main>
          <StepsFooter />
        </div>
      ) : (
        <Loading content="Please Wait!!!" />
      )}
    </div>
  );
}

export default CoverStep1;
