import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link,useLocation } from 'react-router-dom';
import account from './Assets/account.png';
import logo from "../img/logo.png";
import changes from "./Css/Logobar.css";
import axios from "axios";
import { commonConfig } from '../config.js';

const Logobar = () => {

  const ip = localStorage.getItem('ip');
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {

    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "get-ip",
      data: { page: currentPath },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data.ip_address;
      localStorage.setItem('ip',decodedData);
    }).catch(err => {

    });

    

  }, []);
  return (
    <div className="col-lg-12 log_header">
      <div className="col-lg6">
        <Link to="/home" className=""><img src={logo} className="image14" alt="Logo" /></Link>
      </div>
      <div className="col-lg6">
        <div className="dropdown center">
          <img className="dropbtn image15" src={account} />
          <div className="dropdown-content">
            <Link to="#">Refer Your Friend</Link>
            <Link to="/settings">Settings</Link>
            <Link to="/login">Logout</Link>
          </div>
        </div>
      </div>
    </div>

  );
};

export default Logobar;
