import React,{ useState,useEffect }  from 'react'
import loading_video from "./Assets/loading_video.mp4";
import loading_gif from "./Assets/gif123.gif";

const LoadingGIF = props => {
    const [isPlaying, setIsPlaying] = useState(true);

    useEffect(() => {
      
    }, []);
    return (
        
        <div>
          <div className="centered-video-container">
            {/* <video
              autoPlay
              muted 
              width="500"
              height="300"
            >
              <source src={loading_video} type="video/mp4" />
              Your browser does not support the video tag.
            </video> */}
            <div className='loading_gif'>
              <img className='gif' src={loading_gif} alt="" />
              <p className='loading_text'>Hi!<br/>I am Shilpa, your resume AI assistant. <br/>I am working on your resume, meanwhile I suggest that you browse some interesting jobs on <a href="http://gojobs.biz" target="_blank" rel="noopener noreferrer">GOjobs.biz</a>  or prepare for your next job at <a href="http://goaccomplish.com" target="_blank" rel="noopener noreferrer">GOaccomplish.com</a></p>
            </div>
            
          </div>
        </div>
    );
}


export default LoadingGIF