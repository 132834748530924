import React, { useEffect, useState } from "react";
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format, startOfDay, parse } from 'date-fns';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Nav from "./Nav";
import { commonConfig } from '../config.js';
import Loading from './Loading';
import { ToastContainer, toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import StepsFooter from './StepsFooter';
import Formtemplate from "./Formtemplate";

function Step3() {
  const { edu_id } = useParams();
  const [formData, setFormData] = useState([{ education: '', university: '', course: '', specialization: '', year_of_start: '', year_of_completion: '', percentage: '', achievements: [], studying: false }]);

  const [formData2, setFormData2] = useState([{ achievements: '', }]);
  const [stepno, setStepno] = useState(3);
  const [edu, setEduData] = useState([]);
  const [selectedid, setSelectedid] = useState(100);
  const [page, setPage] = useState(false);
  const resume_data = localStorage.getItem('resume_data');
  const resume_id = localStorage.getItem('resume_id');
  const temp_resume_id = localStorage.getItem('temp_resume_id');
  const userId = localStorage.getItem('userId');
  const [is_edit, setIs_edit] = useState(0);
  const parsed_json_data = localStorage.getItem('parsed_json_data');
  const [errors, setErrors] = useState([]);
  const [showform, setShowform] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const local_educationindex = localStorage.getItem('educationindex');
  const local_tempeduindex = localStorage.getItem('tempeduindex');
  const [educationindex, setEducationindex] = useState(0);
  const [tempeduindex, setTempeduindex] = useState(0);
  let education_index1=0;
  let tempedu_index1=0;
  const CommonData = localStorage.getItem('common_data');

  useEffect(() => {

    if (!userId) {
      navigate('/login');
    }
    if(local_educationindex){
      education_index1 = local_educationindex
    }
    if (screenWidth < 900) {
      setShowform(false);
    }
    import('./Css/Step3.scss');
    addMoreAchievements(0);
    if (resume_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        const diff = result.diff;
        const education = result.education;

        if(diff > 12){
          if (CommonData) {
            if (JSON.parse(CommonData).plan.pack_status == 1) {
              
            }else{
              navigate('/plansfront');
            } 
          }
        }

        const contact = result.contact[0];
        console.log("contact", contact);
        if (contact.step === 100) {
          setStepno(contact.step);
          // navigate('/step13');
        } else {
          setStepno(contact.step + 1);
        }

        if (education.length > 0 && edu_id) {
          console.log("joe", edu_id);
          setIs_edit(1);
          setEduData(education);
          const updatedFormData = education.map((item) => {
            if (item.id == edu_id) {
              return {
                ...item,
                achievements: JSON.parse(item.achievements),
              };
            }
          });
          const h = updatedFormData.filter(item => item !== null);
          const i = h.filter(item => item !== undefined);

          setFormData(i);
        } else {

          if (parsed_json_data != undefined) {
            const parsed_exp = JSON.parse(parsed_json_data)?.education;
            console.log("parsed_exp", parsed_exp);
            const updatedFormData = parsed_exp.map((item,index) => {
              if(index == education_index1){
                setEducationindex(education_index1);
                return {
                  ...item,
                  education: item.title,
                  university: item.university,
                  course: item.degree,
                  specialization: item.specialization,
                  year_of_start: item?.from_year,
                  year_of_completion: item?.to_year,
                  percentage: '',
                  achievements: [],
                  studying: false
                };
              }

            });
            const filteredFormData = updatedFormData.filter(item => item !== undefined);
            console.log("updatedFormData",parsed_exp);
            if(filteredFormData.length != 0){
              setFormData(filteredFormData);
            }
            // setFormData(updatedFormData);
          }
          get_temp_data(temp_resume_id);
        }


      }).catch(err => {

      });

    }
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const get_temp_data = (resume_id) => {
    if(local_tempeduindex){
      tempedu_index1 = local_tempeduindex;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      const education = result.education;
      const contact = result.contact[0];

      if (education.length > 0) {
        setEduData(education);
        // const updatedFormData = education.map((item) => ({
        //   ...item,
        //   achievements: JSON.parse(item.achievements),
        // }));
        // setFormData(updatedFormData);

        const updatedFormData = education.map((item,index) => {
          if(index == tempedu_index1){
            setTempeduindex(tempedu_index1);
            return {
              ...item,
              achievements: JSON.parse(item.achievements),
            }
          }
        });
        const filteredFormData1 = updatedFormData.filter(item => item !== undefined);
        if(filteredFormData1.length != 0){
          setFormData(filteredFormData1);
        }
      }

    }).catch(err => {

    });
  }

  const navigate = useNavigate();

  const handleChange = (event, index) => {
    const { name, value, type, checked } = event.target;
    const updatedFormData = [...formData];
    console.log(type);
    if (type === "checkbox") {
      if (checked) {
        setSelectedid(index);
      } else {
        setSelectedid(100);
      }
      updatedFormData[index][name] = checked;
    } else {
      updatedFormData[index][name] = value;
    }
    setFormData(updatedFormData);
    validate();
  };




  const handleAddField = () => {
    setFormData([...formData, { education: '', university: '', course: '', specialization: '', year_of_start: '', year_of_completion: '', percentage: '', achievements: [], studying: false }]);
  };

  const handleRemoveField = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
  };

  const updateAchievement = (index, achievementIndex, value) => {
    const updatedFormData = [...formData];
    updatedFormData[index].achievements[achievementIndex] = value;
    setFormData(updatedFormData);
  };

  const addMoreAchievements = (index) => {

    const updatedFormData = [...formData];
    console.log(updatedFormData);
    updatedFormData[index].achievements.push('');
    setFormData(updatedFormData);
  };

  const removeAchievements = (index, index1) => {
    const updatedFormData = [...formData];
    updatedFormData[index].achievements.splice(index1, 1);
    setFormData(updatedFormData);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      setTimeout(() => {
        setPage(false);
        axios({
          method: "POST",
          url: commonConfig.REACT_APP_API_URL + "education/store",
          data: formData,
          headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": 3, "isedit": is_edit },

        }).then(res => {
          var decodedData = res.data;
          var status = res.status;
          localStorage.setItem('educationindex',parseInt(educationindex)+1);
          localStorage.setItem('tempeduindex',parseInt(tempeduindex)+1);
          if (status) {
            navigate('/step3_1');
          }

        }).catch(err => {
          setPage(true);
          if (err.response.status === 422) {
            toast.error("Please fill all required fields");
          }
        });
      }, 500);
    }
  };

  const validate = () => {
    const newErrors = [];
    formData.forEach((item, index) => {
      let errorObj = {};
      if (!item.education) {
        errorObj.education = "Education required.";
      }
      if (!item.university) {
        errorObj.university = "University required.";
      }
      if (!item.course) {
        errorObj.course = "Course required.";
      }
      if (!item.year_of_start) {
        errorObj.year_of_start = "Start Year required.";
      }
      if (!item.year_of_completion && !item.studying) {
        errorObj.year_of_completion = "Year of completion required.";
      }

      newErrors.push(errorObj);
    });
    setErrors(newErrors);
    if (newErrors.some(error => Object.keys(error).length > 0)) {
      return false;
    }
    return true;
  }

  const options = [
    { value: 'Doctorate', label: 'Doctorate' },
    { value: 'Masters/Post-Graduation', label: 'Masters/Post-Graduation' },
    { value: 'Graduation/Diploma', label: 'Graduation/Diploma' },
    { value: '12th', label: '12th' },
    { value: '10th', label: '10th' }
  ];

  const handleDateChange = (date, index, field) => {
    const formattedDate = date ? format(startOfDay(date), 'yyyy-MM-dd') : null;
    const updatedFormData = [...formData];
    updatedFormData[index][field] = formattedDate;
    setFormData(updatedFormData);

  };


  const renderYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= currentYear - 50; year--) {
      years.push(
        <option key={year} value={year}>
          {year}
        </option>
      );
    }
    return years;
  };

  const Showform = () => {
    setShowform(true);
  };

  return (
    <div className="step3page">
      {page ? (
        <div>
          <ToastContainer position="top-center" theme="dark" />
          <main id="app" role="main">
            <section className="page-wrapper">
              <div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
                <Nav value={stepno} />
                <div className="step1_sec">
                  
                  <div className="inner2_step1">
                    {!showform &&<div className="col-lg-12 mobile_view">
                      <h3 className="headtag" onClick={Showform} style={{textAlign:'center'}}><span className="blue">Lets move on to Education</span></h3>
                      <div className="api_right">
                        <Formtemplate />
                        <div className="col-lg-12 center">
                          <Link to="/template/step2" className="clickable p-10 bold">Change Template</Link>
                        </div>
                        <div className="col-lg-6 half" >
                          <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                        </div>
                        <div className="col-lg-6 half" >
                          <button id="btnBack" onClick={Showform} type="submit" className="btn-mangaii">Next</button>
                        </div>
                      </div>
                    </div>}
                    {showform &&<div className="col-md-12 "  >
                      
                      <div className="col-lg-7">
                        <div className="inner1_step1">
                          <h3 className="headtag"><span className="blue">Enter Your Educational Details</span></h3>
                          <p style={{ marginBottom: '3%', fontWeight: '200px' }} className="p_tag">Tell Us About Your Colleges, Vocational Programs, Or Training Courses.</p>
                        </div>
                        {formData.map((field, index) => (
                          <div className="col-lg-12 each_section">
                            {/* <div className="blue bold" style={{marginBottom:'2%'}}>Education - {index +1}</div> */}
                            <div className="col-lg-6 full">
                              <div className="formgroup">
                                <select
                                  id={`classDropdown${index}`}
                                  name="education"
                                  className="formcontrol"
                                  value={field.education}
                                  onChange={(event) => handleChange(event, index)}
                                  htmlFor={`education${index}`}
                                >
                                  <option value="">-SELECT EDUCATION-</option>
                                  {options.map(option => (
                                    <option key={option.value} value={option.value}>{option.label}</option>
                                  ))}
                                </select>
                                {errors[index] && errors[index].education && (
                                  <span className="error">{errors[index].education}</span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 full">
                              <div className="formgroup">
                                <input
                                  type="text"
                                  name="university"
                                  className="formcontrol"
                                  value={field.university}
                                  onChange={(event) => handleChange(event, index)}
                                />
                                <label className="formlabel" htmlFor={`university${index}`}>
                                  UNIVERSITY/INSTITUTE
                                </label>
                                {errors[index] && errors[index].university && (
                                  <span className="error">{errors[index].university}</span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 half" style={{ clear: 'both' }}>
                              <div className="formgroup">
                                <input
                                  type="text"
                                  name="course"
                                  className="formcontrol"
                                  value={field.course}
                                  onChange={(event) => handleChange(event, index)}
                                />
                                <label className="formlabel" htmlFor={`course${index}`}>
                                  COURSE
                                </label>
                                {errors[index] && errors[index].course && (
                                  <span className="error">{errors[index].course}</span>
                                )}
                              </div>
                            </div>

                            <div className="col-lg-6 half">
                              <div className="formgroup">
                                <input
                                  type="text"
                                  name="specialization"
                                  className="formcontrol"
                                  value={field.specialization}
                                  onChange={(event) => handleChange(event, index)}
                                />
                                <label className="formlabel" htmlFor={`specialization${index}`}>
                                  SPECIALIZATION
                                </label>
                              </div>
                            </div>

                            <div className="col-lg-3 half" style={{ clear: 'both' }}>
                              <div className="formgroup">
                                <div className="date-picker-wrapper">

                                  <select
                                    id={`classDropdown${index}`}
                                    name="year_of_start"
                                    className="formcontrol"
                                    value={field.year_of_start}
                                    onChange={(event) => handleChange(event, index)}
                                    htmlFor={`year_of_start${index}`}
                                  >
                                    <option value="">Start Year</option>
                                    {renderYearOptions()}
                                  </select>
                                  {errors[index] && errors[index].year_of_start && (
                                    <span className="error">{errors[index].year_of_start}</span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 half">
                              {selectedid != index && <div className="formgroup">
                                <div className="date-picker-wrapper">

                                  <select
                                    id={`classDropdown${index}`}
                                    name="year_of_completion"
                                    className="formcontrol"
                                    value={field.year_of_completion}
                                    onChange={(event) => handleChange(event, index)}
                                    htmlFor={`year_of_completion${index}`}
                                  >
                                    <option value="">End Year</option>
                                    {renderYearOptions()}
                                  </select>
                                  {errors[index] && errors[index].year_of_completion && (
                                    <span className="error">{errors[index].year_of_completion}</span>
                                  )}
                                </div>
                              </div>}
                            </div>

                            <div className="col-lg-6 full" >
                              <div className="formgroup">
                                <input
                                  type="text"
                                  name="percentage"
                                  className="formcontrol"
                                  value={field.percentage}
                                  onChange={(event) => handleChange(event, index)}
                                />
                                <label className="formlabel" htmlFor={`percentage${index}`}>
                                  PERCENTAGE OBTAINED
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-12 full" style={{ clear: "both", marginTop: "3%", marginBottom: "4%" }}>
                              <label className="studying">
                                I Am Currently Studying
                              </label>
                              <input type="checkbox" name="studying" checked={field.studying} onChange={(event) => handleChange(event, index)}
                              />
                            </div>
                            <div className="col-lg-12 full" style={{ padding: '0', clear: 'both' }}>
                              {field.achievements?.map((achievement, achievementIndex) => (
                                <div className="col-lg-12 full" key={index}>
                                  <div className="formgroup">
                                    <input
                                      type="text"
                                      className="formcontrol  remove-control"
                                      value={achievement}
                                      onChange={(e) => updateAchievement(index, achievementIndex, e.target.value)}
                                    />
                                    <span className="clickable"><FontAwesomeIcon onClick={() => removeAchievements(index, achievementIndex)} icon={faTrashAlt} /></span>
                                    <label className="formlabel" htmlFor={`achievements${index}`}>
                                      ACHIEVEMENTS
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <div className="col-lg-12 full addmore_parent" style={{ padding: '0' }}>
                              <div className="col-lg-4 half">
                                <button className="addmore_button clickable" onClick={() => addMoreAchievements(index)}>Achievements +</button>
                              </div>
                              
                            </div>

                          </div>))}
                        <div className="col-lg-6 half" style={{ clear: "both", marginTop: "5%" }}>
                          <Link to="#" onClick={() => navigate(-1)} className="btn-stepsback">Back</Link>
                        </div>
                        <div className="col-lg-6 half " style={{ marginTop: "5%" }}>
                          <button id="btnBack" onClick={handleSubmit} type="submit" className="btn-mangaii">Next</button>
                        </div>
                      </div>
                      <div className="col-lg-5 system_view">
                        <div className="api_right">
                          <Link to="/template/step3" className="clickable p-10 bold">Change Template</Link>
                          <Formtemplate />
                        </div>
                      </div>
                    </div>}
                  </div>
                </div>

              </div>
            </section>
          </main>
          <StepsFooter />
        </div>
      ) : (
        <Loading content="Please Wait!!!" />
      )}
    </div>
  );
}

export default Step3;
