import React, { useEffect, useState,useRef  } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import logo from "../img/logo.jpg";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import LoadingGIF from './LoadingGIF';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Tab({ onChildData,onChildData1,exp_id }) {

	const [tabsData, setTabsData] = useState([]);
	const [respData, setRespData] = useState([]);
	const [respokData, setRespokData] = useState(false);
	const [tagText, setTagText] = useState('');
	const [textareaValue, setTextareaValue] = useState('');
  
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(true);
	const [gptloaded, setGptloaded] = useState(0);
	const resume_id = localStorage.getItem('resume_id');
	const CommonData = localStorage.getItem('common_data');

	const [formData, setFormData] = useState({
		keywords: '',
	});


	const handleTagClick = (title, tag) => {
		let pack = JSON.parse(CommonData).plan.pack;

		setData((prevData) => {

			const existingTags = prevData[title] || [];

			console.log("existingTags", existingTags);
			const newTags = existingTags.includes(tag)
				? existingTags.filter(existingTag => existingTag !== tag)
				: [...existingTags, tag];

			// if (pack == 'Free' && newTags.length > 3) {
			// 	toast.error('For free trial you can only add up to 3 keywords'); return prevData;
			// } else {
				const newData = {
					...prevData,
					[title]: newTags.length > 0 ? newTags : null,
				};
				console.log("newData", newData);
				onChildData(newData);
				return newData;
			// }


		});
	};

	const handleTextareaChange = (event) => {
		setTextareaValue(event.target.value);
	};

	const getButtonClass = (title, tag) => {
		// return textareaValue.includes(title + '_' + tag) ? 'tag active_tag' : 'tag';
		console.log('data[title]', data[title]);
		onChildData1(data[title]);
		return data[title]?.indexOf(tag) > -1 ? 'tag active_tag' : 'tag';
		// if (skillExists) {
		// }
	};
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(0);
	const [activeTabTitle, setActiveTabTitle] = useState('');
	useEffect(() => {
		console.log("exp_id",exp_id);
		// import('./Css/Step2.css');
		if (resume_id) {
			axios({
				method: "POST",
				url: commonConfig.REACT_APP_API_URL + "finalize",
				data: { resume_id: resume_id },
				headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
			}).then(res => {
				var decodedData = res.data;
				const result = decodedData.data;
				const contact = result.contact[0];

				// if (contact.step == 100) {
				// 	navigate('/step13');
				// }
			}).catch(err => {

			});
		}
		axios({
			method: "GET",
			url: commonConfig.REACT_APP_API_URL + "experience",
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "expid": exp_id },
		}).then(res => {
			var decodedData = res.data;
			const result = decodedData.data;
			setTabsData(result);
			result.forEach((item, index) => {

				if (item.is_skills == 0) {
					setGptloaded(1);
					const question = "Hi! I need to create my CV or resume and need help in writing the CV. I work in Industry as Designation. My job involved '" + item.description + "'  and some of the common key words in my area are '" + item.key_skills + "' Please suggest about 20 key words which I can use in my resume which will help me in getting a good job. Also, many times recruiters do a key word search to shortlist the Resumes based on job Descriptions of the prospective jobs. Keeping this in mind please suggest the key words should be added to my resume. Return these statements as a JSON array inside keywords key with the structure.Do not return any non-json text or numbering or boolean";
					chatGPT(question).then(responseData => {
						item.res = responseData;
						setRespokData(true);
						setTimeout(() => {
							setActiveTab(index);
							setActiveTabTitle(item.id);
						}, 500);
						save_data(item.res, item.id);
						console.log("items", item);
					}).catch(error => {
						console.error('Error:', error);
					});

				} else {
					console.log("item", item);
          // videoRef.current.play();
					setRespokData(true);
					setGptloaded(2);
					setActiveTab(index);
					setActiveTabTitle(item.id);
					// return;
					// setGptloaded1(true);

				}


			});

		}).catch(err => {

		});


	}, []);



	const handleTabClick = (index, id) => {
		console.log("tabsdata", tabsData);
		setActiveTab(index);
		setActiveTabTitle(id);
		// console.log('activeTabTitle',activeTabTitle);
	};
	const handleAddmore = () => {
		let pack = JSON.parse(CommonData).plan.pack;
		const keywords = formData.keywords;
		// setData((prevData) => {
		//   const newData = {
		//     ...prevData,
		//     [activeTabTitle]: prevData[activeTabTitle] ? [...prevData[activeTabTitle], keywords] : [keywords],
		//   };

		//   onChildData(newData);
		//   return newData;
		// });

		setData((prevData) => {
			const existingTags = prevData[activeTabTitle] || [];
			const newTags = existingTags.includes(keywords)
				? existingTags.filter(existingTag => existingTag !== keywords)
				: [...existingTags, keywords];

				const newData = {
					...prevData,
					[activeTabTitle]: newTags.length > 0 ? newTags : null,
				};

				onChildData(newData);
				return newData;


		});
		formData.keywords = '';

	};
	const handleInputChange = (event) => {
		const { id, value } = event.target;
		setFormData((prevFormData) => ({
			...prevFormData,
			[id]: value
		}));
	};

	const save_data = (item, id) => {
		const requestData = {
			skills: item,
			id: id,
			type: 'skills'
		};
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "skills/GPTstore",
			data: requestData,
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
		}).then(res => {

		}).catch(err => {
			// Handle error
		});
	}

	const chatGPT = async (question) => {
		return new Promise(async (resolve, reject) => {
			try {
				const apiUrl = commonConfig.GPT_API_URL;
				const apiKey = commonConfig.GPT_API_KEY;
				const requestBody = {
					"model": "gpt-3.5-turbo",
					"messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
					"temperature": 0.7
				};
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${apiKey}`
				};
				const { data } = await axios.post(apiUrl, requestBody, { headers });
				const result = data.choices[0].message.content;
				const json = JSON.parse(result);
				setRespData(json.keywords);
				resolve(json.keywords);
			} catch (error) {
				console.error('Error:', error);
				reject(error);
			}
		});
	};

	return (
		<div>
      
			{respokData ? (
				<div>
					{/* <div className="tab-header">
						{tabsData.map((tab, index) => (
							<div key={index} className={`tab-item bold ${activeTab === index ? 'active' : ''}`} onClick={() => handleTabClick(index, tab.id)}>
								{tab.title}
							</div>
						))}
					</div> */}
					<ToastContainer position="top-center" />
					<div>
						{/* <p className="h5 p-sub-title blue" style={{ marginTop: '2%' }}>Select Key words which you want to be included in your Resume. You can also add more if they are not listed below : </p> */}
						{gptloaded == 2 && <div>
							{JSON.parse(tabsData[activeTab]?.res)?.map((keyword, index) => (
								<div key={index} onClick={() => handleTagClick(tabsData[activeTab].id, keyword)} className={getButtonClass(tabsData[activeTab].id, keyword)} >{keyword}</div>
							))}
						</div>}
						{gptloaded == 1 && <div>
							{tabsData[activeTab]?.res?.map((keyword, index) => (
								<div key={index} onClick={() => handleTagClick(tabsData[activeTab].id, keyword)} className={getButtonClass(tabsData[activeTab].id, keyword)} >{keyword}</div>
							))}
						</div>}
					</div> <br />
					<div>
						<p className="h5 p-sub-title blue">Description and Keywords Entered by you</p>
						<p className="desc">{tabsData[activeTab].key_skills}</p>
						<p className="desc">{tabsData[activeTab].description}</p>

					</div>


					{/* <div>
						<p className="h5 p-sub-title blue" style={{ marginTop: '3%' }}>Selected Keywords</p>
						<div>
							{data[activeTabTitle]?.map((keyword, index) => (
								<div key={index} className="tag active" >{keyword}</div>
							))}
						</div>
					</div> */}

					<div style={{ marginTop: '4%' }}>
						<div className="formgroup">
							<input type="text" name="keywords" id="keywords" onChange={handleInputChange} className="formcontrol1" value={formData.keywords} placeholder="Add your own keyword" />
							<button id="btnBack" onClick={handleAddmore} type="button" className="addmore">Add +</button>
						</div>
					</div>
					<div style={{ marginTop: '4%', display: "none" }}>
						<textarea
							value={textareaValue}
							className="formcontrol"
							onChange={handleTextareaChange}
							placeholder="Type or select a tag"
						/>
					</div>

				</div>
			) : (
				<LoadingGIF />
        // <div className="centered-video-container">
        //   <video
        //     autoPlay
        //     muted 
        //     width="500"
        //     height="300"
        //   >
        //     <source src={loading_video} type="video/mp4" />
        //     Your browser does not support the video tag.
        //   </video>
        // </div>
			)}


		</div>
	);
}

export default Tab;


