import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { commonConfig } from '../config.js';
import axios from "axios";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import image1 from './Assets/templates/temp_1.png';
import image2 from './Assets/templates/temp_2.png';
import image3 from './Assets/templates/temp_3.png';
import image4 from './Assets/templates/temp_4.png';
import image5 from './Assets/templates/temp_5.png';
import image6 from './Assets/templates/temp_6.png';
import image7 from './Assets/templates/temp_7.png';
import image8 from './Assets/templates/temp_8.png';

function BlurCoverImage({ imageUrl,data,can_edit,can_download }) {
  const [hovered, setHovered] = useState(false);
  const [isDeleteOpen, setisDeleteOpen] = useState(false);
  const [deleteid, setDeleteid] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  let template = data.template;
  if(!template){
    template = 1;
  }
  let images = [];
	images = ['',image1, image2, image3, image4, image5, image6, image7, image8];

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const EditCover = (cover_id) => {
    localStorage.setItem('cover_id', cover_id);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "cover/finalize",
      data: { cover_id: cover_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      const step = result.step;
      if (step == 1) {
        navigate('/coverStep2');
      } else if (step == 2) {
        navigate('/coverStep3');
      } else if (step >= 3) {
        navigate('/coverStep4');
      }
    }).catch(err => {

    });
  }
  const Deletetrigger = (resume_id) => {
    setDeleteid(resume_id);
    setisDeleteOpen(true);
  }
  const Deleteresume = () => {
    axios({
      method: "POST",
      data: { resume_id: deleteid },
      url: commonConfig.REACT_APP_API_URL + "delete_resume",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      setisDeleteOpen(false);
      window.location.reload();
    }).catch(err => {

    });
  }
  const handleDelClose = () => {
    setisDeleteOpen(false);
  };
  const handleNavPlans = () => {
    navigate('/plans');
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const DownloadCV = (cover_id) => {
    const fileUrl = commonConfig.COVERPDF_URL + cover_id + ".pdf";
    const link = document.createElement('a');
    link.href = fileUrl;
    link.target = '_blank';
    link.download = cover_id+'.pdf'; // Specify the desired filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <div className={`${screenWidth > 900 ? '' : 'center'}`}>
      <div
        className="image-container hovered"
        // className={`image-container ${hovered ? 'hovered' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img className="temps14" src={images[template]} alt="Hovered Image" />
        {/* {hovered && ( */}
          <div className="icon-container">
            <div className="clickable" onClick={() => EditCover(data.id)} >
              <FontAwesomeIcon icon={faEdit} />
            </div>
            <div className="clickable" onClick={() => Deletetrigger(data.id)}>
              <FontAwesomeIcon icon={faTrashAlt}  />
            </div>
            {can_download==1 ? (<div className="clickable" onClick={() => DownloadCV(data.id)}>
              <FontAwesomeIcon icon={faDownload} />
            </div>) : ( <div className="clickable" onClick={handleNavPlans}>
              <FontAwesomeIcon icon={faDownload} />
            </div>)}
          </div>
          {/* {data.resumename && <div className="icon-container1">
            <div className="clickable f-11">
              <div>
                <p className='center white'><b>{data.resumename}</b></p>
                {data.published_on && <p className='center white'><b>Published On: {data.published_on}</b></p>}
              </div>
            </div>
          </div>} */}
        
      </div>

      {isDeleteOpen && (
        <div className="popup-overlay">
          <div className="popup-container">
            {/* <FontAwesomeIcon className="close-button" onClick={handleClose} icon={faTimes} /> */}
            <h4>Do you want to delete this resume?</h4>
            <button id="btnBack" type="button" className="btn-stepsback" style={{ marginTop: '40px' }} onClick={handleDelClose}>No</button>
            <button id="btnBack" type="submit" onClick={Deleteresume} className="btn-mangaii" style={{ marginTop: '40px' }}  >Yes. Delete it</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default BlurCoverImage;
