import React, { useState,useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import { commonConfig } from '../config.js';
import axios from "axios";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import image1 from './Assets/templates/temp_1.png';
import image2 from './Assets/templates/temp_2.png';
import image3 from './Assets/templates/temp_3.png';
import image4 from './Assets/templates/temp_4.png';
import image5 from './Assets/templates/temp_5.png';
import image6 from './Assets/templates/temp_6.png';
import image7 from './Assets/templates/temp_7.png';
import image8 from './Assets/templates/temp_8.png';
import image21 from './Assets/Temp21.jpeg';
import Loading from './Loading';

function CoverModal({ sendDataToParent }) {
  const navigate = useNavigate();
  const [resp, setResp] = useState([]);
  const [page, setPage] = useState(false);
  const [open, setOpen] = useState(true);
  const [tabdata, setTabdata] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const userId = localStorage.getItem('userId');

  let images = [];
	images = [image1, image2, image3, image4, image5, image6, image7, image8, image21];

  useEffect(() => {
    
    import("./Css/Step14.scss");
    
    axios({
      method: "POST",
      data: { userId: userId },
      url: commonConfig.REACT_APP_API_URL + "my_coverletter",
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      setResp(result.cover);
      setPage(true);
    }).catch(err => {

    });


  }, []);

  const handleTemplateSelect = (tab) => {
    
    setSelectedTemplate(tab.id);
    
    setTabdata(tab);
    // localStorage.setItem('interviewCoverData',tab);
  };
  const Next = () => {
    sendDataToParent(tabdata);
    setOpen(false);
  };
  const Close = () => {
    setOpen(false);
  };

  return (
    <div>
    {open && <div className="popup-overlay">
      <div className="popup-container">
        <FontAwesomeIcon className="close-button" icon={faTimes} />
        {/* <h2 className='blue' style={{height:'auto',textAlign:'center'}}>Select the Cover Letter</h2> */}
        <p style={{ marginTop: '20px', fontSize: '20px', fontWeight: '500', textAlign: 'center' }}>Select the  job applied for to auto fill the details</p>
        <div className="resume_list">
          <div class="container">
            {page ? (
              <div class="row">
                {resp.map((tab, index) => (
                  <div className='col-lg-6'>
                    <div className={`image-container hovered ${selectedTemplate === tab.id ? 'selected1' : ''}`} onClick={() => handleTemplateSelect(tab)} style={{ cursor: "pointer" }}> 
                      <img className="temps14" src={images[1]} alt="Hovered Image" />
                        {tab.step >= 3 && <div className="icon-container1">
                          <div className="clickable f-11">
                            <div>
                              <div className='center white f-11'><b>{tab.job_applied_to}</b></div>
                            </div>
                          </div>
                        </div>}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <Loading content="" />
            )}
          </div>
        </div>

        <button id="btnBack" type="button" className="btn-stepsback full" onClick={Close} style={{ marginTop: '40px' }} >Close</button>
        <button id="btnBack" type="submit" className="btn-mangaii full" style={{ marginTop: '40px' }} onClick={Next}  >Select</button>
      </div>
    </div>}
    </div>
  );
}

export default CoverModal;
