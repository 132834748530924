import React, { Component, useState, useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { commonConfig } from '../config.js';
import axios from "axios";
import {
  HashRouter as Router,
  Route,
  Link,
  Navigate,
  useNavigate,
  useLocation,
  DefaultRoute
} from "react-router-dom";

function MdlLogin({ onSubmit }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const [formData, setFormData] = useState({
    email: params.get('email'),
    password: '12qw!@QW'
  });

  useEffect(() => {
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "login",
      data: formData,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

    }).then(res => {
      var decodedData = res.data;
      var status = res.status;
      if (status) {
        localStorage.setItem('authToken', decodedData.data.token);
        localStorage.setItem('userId', decodedData.data.id);
        localStorage.setItem('email', decodedData.data.email);
        navigate('/step14');
      }

    }).catch(err => {
    });


  }, []);

}

export default MdlLogin;
