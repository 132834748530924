import React, { useEffect, useState } from "react";
import { FaCalendarAlt } from 'react-icons/fa';
import { Link, useNavigate,useParams } from 'react-router-dom';
import Nav from "./Nav";
import Tab from "./Tab";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import axios from 'axios';
import StepsFooter from './StepsFooter';
import { commonConfig } from '../config.js';



function Step7() {
	const { exp_id } = useParams();
	const [childData, setChildData] = useState('');
	const [selected, setSelected] = useState([]);
	let is_edit = 0;
	const [stepno, setStepno] = useState(2);
	const [gptskills, setGptskills] = useState(0);
	const [page, setPage] = useState(false);
	const resume_id = localStorage.getItem('resume_id');
	const handleChildData = (data) => {

		setChildData(data);

	};
	const handleChildData1 = (selected) => {
		setSelected(selected);
	};
	const userId = localStorage.getItem('userId');


	useEffect(() => {
		if (!userId) {
			navigate('/login');
		}
		if (!exp_id) {
			navigate('/step2');
		}
		// console.log("type",exp_id);



		import('./Css/Step8.scss');

		setTimeout(() => {
			setPage(true);
		}, commonConfig.LOAD_TIME);
	}, []);

	const navigate = useNavigate();



	const handleSubmit = (event) => {

		event.preventDefault();

		if (gptskills == 1) {
			is_edit = 1;
		}
		setPage(false);
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "experience/skill_update",
			data: childData,
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": '2.1', "isedit": is_edit },
		}).then(res => {
			var decodedData = res.data;
			var status = res.status;
			if (status) {
				navigate('/step8/'+exp_id);
			}
		}).catch(err => {
			// Handle error
		});
	};
	const [activeTab, setActiveTab] = useState(0);

	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	return (
		<div className="step8page">
			{page &&
				<div>
					<main id="app" role="main">
						<section className="page-wrapper">
							<div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
								<Nav value={stepno} />
								<div className="step1_sec">
									<div className="col-lg-12">
										<div className="col-lg-8">
											<div className="inner1_step11">
												<h3 className="headtag">
													<span className="blue">Select Keywords</span>
												</h3>
												<p style={{ marginBottom: '3%', fontWeight: '200px' }} className="h5 p-sub-title blue">
                        Select Keywords – We want to make your resume better for that please select the keywords which match your work profile and you want them to be included in your resume. Also please add more keywords if they are not listed below:
												</p>
											</div>
											<div className="inner2_step1">
												<div className="col-md-12" >
													<div className="col-lg-8">{/* Add form fields here */}</div>
												</div>
												<div className="inner2_step1">
													<Tab exp_id={exp_id} onChildData={handleChildData} onChildData1={handleChildData1} />
													{/* <p>Child Data: {JSON.stringify(childData)}</p> */}
												</div>
											</div>
										</div>
										<div className="col-lg-4">
											<div className="api_right">
												<div className="api_whitesheet">
													<div className="h5 p-sub-title blue" style={{ padding: '2%' }}>
														<p>Selected Keywords</p>

														<div>
															{selected?.map((keyword, index) => (
																<div key={index} className="tag active" >{keyword}</div>
															))}
														</div>
													</div>

												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-8" style={{ marginTop: '0%' }}>
									<div className="col-lg-6 half" style={{ clear: "both" }}>
										<Link to={`/step2/${exp_id}`} className="btn-stepsback">Back</Link>
									</div>
									<div className="col-lg-6 half " >
										<button id="btnBack" type="button" onClick={handleSubmit} className="btn-mangaii">Next</button>
									</div>
								</div>
							</div>
						</section>
					</main>
					<StepsFooter />
				</div>}
		</div>
	);
}

export default Step7;
