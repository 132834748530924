import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Top_1 from "./Assets/interview/top.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar,faQuoteLeft,faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import MainNav from "./MainNav";
import Footer from "./Footer";
import "./Assets/CSS/aos.css";
import "./Assets/CSS/glightbox.min.css";
import "./Assets/CSS/swiper-bundle.min.css";
import "./Assets/CSS/style.css";
import LoadingGIF from './LoadingGIF';
import Loading from './Loading';
import ResumeModal from './ResumeModal';

function Interview() {
  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [boxopen, setBoxopen] = useState(false);
  const userId = localStorage.getItem('userId');
  useEffect(() => {

    import('./Css/Interview.scss');
    // import('./Assets/CSS/bootstrap.min.css');
    setTimeout(() => {
      setPage(true);
    }, 1500);
  }, []);

  const openResumeBoxOpen = () => {
    // if (!userId) {
      navigate('/interviewStep1');
    // }
    // setBoxopen(true);
  };

  return (

    <div className="home interviewpage">
      {page ? (
        <div>
          <MainNav value="7" />
          <section className="banner">
            <div className="col-lg-6" >
              <div className="banner_left">
                <h1 className="head3 blue">Get any</h1>
                <h1 className="head3 blue">Interview Ready</h1>
                <p className="p_tag" style={{ marginTop: '0%' }}>Interviews can be challenging, but with the right guidance, you can significantly improve your performance. </p>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <FontAwesomeIcon className="lightblue" icon={faStar} /> <p className="p_tag" style={{ marginTop: '-5px', marginLeft: '15px' }}>Job Specific Questions </p>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <FontAwesomeIcon className="lightblue" icon={faStar} /> <p className="p_tag" style={{ marginTop: '-5px', marginLeft: '15px' }}>Company Specific Questions</p>
                </div>
                <div style={{ display: 'flex', marginBottom: '10px' }}>
                  <FontAwesomeIcon className="lightblue" icon={faStar} /> <p className="p_tag" style={{ marginTop: '-5px', marginLeft: '15px' }}>Candidate Specific Questions </p>
                </div>
                <button name="create" onClick={openResumeBoxOpen} className="btn btn-blue">Start Preparing Now</button>
              </div>
            </div>

            <div className="col-lg-6">
              <img className="top_1" src={Top_1} alt="song" />
            </div>
          </section>
          <section className="section-2 section_sub">
            <div class="container lic_margins">
              <div class="dotted-rectangle">
                <div class="top-images">
                  <img src={require('./Assets/cover/s_top_1.png')} alt="Image 1" />
                  <div className="tagline1">Simple Steps for a standout cover letter</div>
                </div>
                <div class="bottom-images">
                  <div style={{textAlign: 'center'}}>
                    <img src={require('./Assets/interview/know.png')} alt="Image 1" />
                    
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img src={require('./Assets/interview/answer.png')} alt="Image 1" />
                    
                  </div>
                  <div style={{textAlign: 'center'}}>
                    <img src={require('./Assets/interview/present.png')} alt="Image 1" />
                    
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-3 section_sub">
            <div className="col-lg-12">
              <div className="col-lg-4">
                <div className="bg_blue s2_height">
                  <div className="bold f-20">Enter Details</div>
                  <ul>
                    <li>Know every possible question of your interview</li>
                    <li>Deep dive into the important topics with more questions</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="bg_lightblue s2_height">
                  <div className="bold f-20">Know your Answers</div>
                  <ul>
                    <li>Get to view the expert answers for every question</li>
                    <li>Request multiple answers if you are not happy with one</li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="bg_blue s2_height">
                  <div className="bold f-20">Get FREE Course</div>
                  <ul>
                    <li>Get access to the interview preparation courses</li>
                    <li>Understand how to present you and your answers</li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
          <section className="section-2 section_sub">
            <h3 className="headtag blue" style={{ position: 'relative' }}>
              <div>Practice Makes Perfect</div>
            </h3>
            <div style={{width:'60%',margin:'auto',textAlign:'center'}}>
              <span style={{ fontSize: '4em',float: 'left',marginTop: '-53px' }} className="lightblue"><FontAwesomeIcon icon={faQuoteLeft} /></span>
              <p>
                Mastering job interviews demands a diverse skill set, including problem-solving,
                effective communication, product knowledge, and robust technical abilities.
                There's a guaranteed method to significantly enhance your performance.
                
              </p>
              <h3 className="blue">That’s Practice!</h3>
              <span style={{ fontSize: '4em',float: 'right',marginTop: '-83px' }} className="lightblue"><FontAwesomeIcon icon={faQuoteRight} /></span>
            </div>
          </section>
          
          {boxopen &&
            <ResumeModal to="interview"/>
          }

          <Footer />
        </div>
      ) : (
        <Loading content="" />
      )}
    </div>

  );
}

export default Interview;
