import React, { Component, useEffect, useState, useRef,forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import { FaEdit } from "react-icons/fa";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Dynamictemp11 = forwardRef((props, ref) => { 
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);

  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  let resume_id = localStorage.getItem('resume_id');
  const a = ["PHP", "Javascipt", "HTML", "CSS", "Bootstap", "ionic", "Angular", "react", "Laravel", "Codeigniter", "Wordpress"];
  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }

    

    
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);


      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);

 

  const printDocument = (title) => {
    const element = document.getElementById('pdfContainer');
    console.log(htmlContent); 
    const data = {
      htmlContent: htmlContent,
      resume_id:resume_id,
      template:1
    };
    console.log('htmlContent',htmlContent);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "generate-pdf",
      data: data,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      if(title == "save"){
        toast.success("Saved Successfully!!!");
      }
      
    }).catch(err => {

    });
  };

  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print:print
  }));
  
  return (
    <div>
      {/* <button onClick={printDocument}>Print</button> */}
      <ToastContainer position="top-center" theme="dark" />
      <div contentEditable id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{padding: '10px'}}>
        {respokData ? (
            <li class="template-preview" data-skinid="MLT5" data-skincd="MLT5" title="">
            <div class="selected-badge">
              <div class="fal fa-check icon-badge"></div>
            </div>
            <button type="button" class="btn btn-link preview-btn" aria-label="MLT5 skin preview "></button>
            <div class="render-preview">
              <div class="" tabindex="">
                <div></div>
                <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlt5 MLT5  RNA  " docskinwidth="512">
                  <div id="CONTAINER_PARENT_0">
                    <div id="CONTAINER_0" class="topContainer">
                      <div data-react-beautiful-dnd-draggable="26" class="sortable-item section-container SortableItem-sibling name-contact ">
                        <div class="document-tool sec-tool" id="editIcons" style={{right: '-2px'}}></div>
                        <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section nameSec notdraggable SECTION_NAME     " data-section-cd="NAME">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                  <div>
                                    <div class="monogram dynamicIcon" dependency="MURL" style={{display:'none'}}>
                                      <div id="FIELD_MURL"></div>
                                    </div>
                                    <div class="monogram" dependency="FNAM|LNAM">
                                      <svg viewBox="0 0 107 93" enable-background="0 0 107 93">
                                        <path d="M75.25 131.94L49 86.47 75.25 41h52.5L154 86.47l-26.25 45.47z" fill="#fff" stroke="#0967c4" stroke-width="1" transform="translate(-48 -40)"></path>
                                        <text id="SUBSTR_FALN" text-anchor="middle" x="53" y="57" fill="#0967c4" font-size="36">DA</text>
                                      </svg>
                                    </div>
                                    <div class="name">
                                      <span id="FIELD_FNAM" dependency="FNAM">{contact.f_name}</span>
                                      <br dependency="LNAM" />
                                      <span id="FIELD_LNAM">{contact.l_name}</span>
                                    </div>
                                    <div class="profTitle" dependency="DCTL" style={{display:'none'}}>
                                      <span id="FIELD_DCTL"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section SECTION_CNTC cntcSec notdraggable     " data-section-cd="CNTC">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="address">
                                      <span class="paddedline">
                                        <span id="FIELD_EMAI">{contact.email}</span>
                                      </span>
                                      <span class="paddedline">
                                        <span id="FIELD_CPHN">{contact.mobile}</span>
                                      </span>
                                      <span class="paddedline">
                                        <span id="FIELD_STRT"></span>
                                        <span dependency="STRT+CITY|STAT" style={{display:'none'}}>, </span>
                                        <span id="FIELD_CITY"></span>
                                        <span dependency="CITY+STAT" style={{display:'none'}}>, </span>
                                        <span id="FIELD_STAT"></span>
                                        <span id="FIELD_ZIPC"></span>
                                        <span id="FIELD_ADDR">{contact.address_1} {contact.address_2}</span>
                                      </span>
                                      <span class="paddedline">
                                        <span id="FIELD_WEB1"></span>
                                      </span>
                                      <span dependency="SOCL" class="social paddedline" style={{display:'none'}}></span>
                                      <div dependency="DOB1|DRIV" style={{display:'none'}}>
                                        <span dependency="DOB1" class="paddedline" style={{display:'none'}}>
                                          <span class="txt-bold">
                                            <span class="xslt_static_change" id="DOCDATAINFO_DOB1">undefined</span>
                                            <span>: </span>
                                          </span>
                                          <span id="FIELD_DOB1"></span>
                                        </span>
                                        <span dependency="DRIV" class="paddedline" style={{display:'none'}}>
                                          <span class="txt-bold">
                                            <span class="xslt_static_change" id="DOCDATAINFO_DRIV">undefined</span>
                                            <span>: </span>
                                          </span>
                                          <span id="FIELD_DRIV"></span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="doc-overlay section-overlay"style={{left:'-2px',right:'-2px'}}>
                          <span></span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="CONTAINER_PARENT_1" class="parentContainer">
                    <div id="CONTAINER_1" class="left-box">
                      <div data-react-beautiful-dnd-draggable="27" class="sortable-item section-container SortableItem-sibling  data-SUMM">
                        <div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div>
                        <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary <span title=" Summary " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn" id="FIELD_FRFM">
                                      <p>{contact.summery}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-react-beautiful-dnd-draggable="27" class="sortable-item section-container SortableItem-sibling  data-HILT">
                        <div class="document-tool sec-tool" id="editIcons" style={{right: '-2px'}}></div>
                        <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_HILT">Skills <span title=" Skills " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn maincolumn">
                                      <span class="paddedline" id="FIELD_SKC1">
                                      <ul>
                                          {JSON.parse(skills[0].skills)?.map((item, index) => (
                                            item && <li key={index}>{item}</li>
                                          ))}
                                        </ul>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div data-react-beautiful-dnd-draggable="27" class="sortable-item section-container SortableItem-sibling  data-EDUC">
                        <div class="document-tool sec-tool" id="editIcons" style={{right: '-2px'}}></div>
                        <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                          <div class=" doc-item">
                            <div class="heading">
                             
                           <div class="sectiontitle" id="SECTIONNAME_EDUC">Education and Training <span title=" Education and Training " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            {education?.map((item, index) => (<div class="">
                              <div class="">
                                <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn">
                                      <span class="paddedline" dependency="DGRE">
                                        <span class="degree txt-bold" id="FIELD_DGRE">{item.education}s</span>
                                      </span>
                                      <span class="paddedline" dependency="SCHO">
                                        <span id="FIELD_SCHO">{item.university}</span>
                                      </span>
                                      <span class="paddedline" dependency="SCIT|SSTA|SCNT">
                                        <span class="joblocation jobcity" id="FIELD_SCIT"> New Delhi, India</span>
                                        <span dependency="SCIT+SSTA" style={{display:'none'}}>,</span>
                                        <span class="joblocation jobstate" id="FIELD_SSTA"></span>
                                        <span dependency="SCIT+SSTA|SCNT" style={{display:'none'}}>,</span>
                                        <span class="joblocation jobcountry" id="FIELD_SCNT"></span>
                                      </span>
                                      <span class="paddedline" dependency="STUY|GRPA|GRYR">
                                        <span class="programline" id="FIELD_STUY"></span>
                                        <span dependency="STUY+GRPA|GRYR" style={{display:'none'}}>, </span>
                                        <span dependency="GRPA" style={{display:'none'}}>GPA: </span>
                                        <span id="FIELD_GRPA"></span>
                                        <span dependency="GRPA+GRYR" style={{display:'none'}}>, </span>
                                        <span class="xslt_static_change displayNoneThisField">Expected in </span>
                                        <span id="FIELD_GRYR" format="%m/%Y">{item.year_of_completion}</span>
                                      </span>
                                      <span id="FIELD_FRFM"></span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                              ))}
                          </div>
                          
                        </div>
                      </div>
                    </div>
                    <div id="CONTAINER_2" class="right-box">
                      <div data-react-beautiful-dnd-draggable="27" class="sortable-item section-container SortableItem-sibling  data-EXPR">
                        <div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div>
                        <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience <span title=" Experience " class="rename-section text-rename"></span>
                              </div>
                            </div>
                            {experience?.map((item, index) => (<div class="">
                              <div class="sortableInner">
                                <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn">
                                      <span class="paddedline" dependency="COMP">
                                        <span class="companyname txt-bold" id="FIELD_COMP">{item.employer}</span>
                                      </span>
                                      <span class="paddedline txtItl" dependency="JTIT|JCIT|JSTA|JCNT|JSTD|EDDT">
                                        <span class="jobtitle" id="FIELD_JTIT">{item.title}</span>
                                        <span dependency="JTIT">
                                          <span dependency="JCIT|JSTA|JCNT" class="sprtr"> | </span>
                                        </span>
                                        <span class="joblocation jobcity" id="FIELD_JCIT">{item.city}</span>
                                        <span dependency="JCIT+JSTA|JCNT">, </span>
                                        <span class="joblocation jobstate" id="FIELD_JSTA">{item.country}</span>
                                        <span dependency="JSTA+JCNT" style={{display:'none'}}>, </span>
                                        <span class="joblocation jobcountry" id="FIELD_JCNT"></span>
                                        <span class="datesWrapper" dependency="JSTD|EDDT">
                                          <span class="jobdates" id="FIELD_JSTD" format="%m/%Y">{item.city}</span>
                                          <span dependency="JSTD+EDDT"> to </span>
                                          <span class="jobdates" id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present':item.end_date}</span>
                                        </span>
                                      </span>
                                      <span class="jobline" id="FIELD_JDES">
                                        <ul>
                                        <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                        </ul>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                               
                              </div>
                            </div>
                               ))}
                          </div>
                        </div>
                      </div>
                      <div data-react-beautiful-dnd-draggable="27" class="sortable-item section-container SortableItem-sibling  data-LNGG">
                        <div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div>
                        <div id="SECTION_LNGG36369f28-1717-c0d3-39a5-96f0b2c27702" class="section lang-sec infobarsec SECTION_LNGG    has-title  " data-section-cd="LNGG">
                          <div class=" doc-item">
                            <div class="heading">
                            {JSON.parse(more).language &&
                            <div class="sectiontitle" id="SECTIONNAME_LNGG">Languages <span title=" Languages " class="rename-section text-rename"> <ul>
                                          {JSON.parse(more)?.language?.map((item, index) => (
                                            <li key={item.language}>{item.language}</li>
                                          ))}   </ul></span>
                                       
                              </div>}
                            </div>
                            <div class="">
                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div class="template-overlay">
              <button id="previewTemplate" type="button" class="btn btn-tertiary" data-index="20">Preview Template</button>
              <button id="btnSelectTemplate" type="button" class="btn btn-primary  ">Select Template</button>
            </div>
          </li>
           
            
            ) : (
            
                <Loading />
              )}
            </div>
          </div>
        );
      });
      export default Dynamictemp11;