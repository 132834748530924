import React, { useState } from 'react';
import axios from 'axios';

function Chat() {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('Not Yet called');
//   const [joshua, setJoshua] = useState('Joe');

    

  const askQuestion = async () => {
    try {
      const apiUrl = 'https://api.openai.com/v1/chat/completions';

      const apiKey = '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'; // Replace with your OpenAI API key

      const requestBody = {
        // prompt: `Q: ${question}`,
        // max_tokens: 50,
        // temperature: 0.7,
        // top_p: 1.0
        "model": "gpt-3.5-turbo",
        "messages": [{"role": "user", "content": question}],
        "temperature": 0.7
      };

      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`
      };

      const { data } = await axios.post(apiUrl, requestBody, { headers });
      setAnswer(data.choices[0].message.content.trim());
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div>
      <input
        type="text"
        placeholder="Ask a question"
        value={question}
        onChange={(e) => setQuestion(e.target.value)}
      />
      
      
      <button onClick={askQuestion}>Ask</button>
      <p>{answer}</p>
    </div>
  );
}

export default Chat;
