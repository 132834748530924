import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver'; // for downloading the file
import htmlDocx from 'html-docx-js/dist/html-docx';


const Dynamictemp8 = forwardRef((props, ref) => {
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  let resume_id = localStorage.getItem('resume_id');

  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);

      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);



  const printDocument = (title, fontsize, spacing, color, resumename, justify) => {
    const element = document.getElementById('pdfContainer');
    console.log(htmlContent);
    const data = {
      htmlContent: htmlContent,
      resume_id: resume_id,
      resumename: resumename,
      justify: justify,
      fontsize: fontsize,
      spacing: spacing,
      color: color,
      template: 8
    };
    console.log('htmlContent', htmlContent);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "generate-pdf",
      data: data,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      if (title == "save") {
        toast.success("Saved Successfully!!!");
      }

    }).catch(err => {

    });
  };

const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print,
    downloadWordDoc: downloadWordDoc,
  }));


  const downloadWordDoc = () => {
    // const content = `
    // `;

    // const blob = new Blob([content], {
    //   type: 'application/msword'
    // });

    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'resume.doc';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);

    const cssStyles = `
      <style>
      @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:wght@300&family=Poppins:wght@200;300&family=Roboto:wght@400&display=swap');
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;vertical-align:baseline;background:transparent}
body{line-height:1;text-align:left;font-feature-settings:"liga" 0;-moz-font-feature-settings:"liga" off}
.skn-mlf8 table{border-collapse:collapse;border-spacing:0;font-size:inherit;color:inherit;width:100%}

/*START content disc style for LI*/
.skn-mlf8 ul,.skn-mlf8 li{list-style:none;margin:0;padding:0}
.skn-mlf8 ul li{position:relative;margin:0px;padding-left:10px;line-height: 1.5;}
.skn-mlf8 ul li:before{font-size:9px;position:absolute;left:0;top:0}
/*END content disc style for LI*/

/*Helping classes*/
.skn-mlf8 .txt-bold{font-weight:bold}
.skn-mlf8 .txtItl{font-style:italic}
.skn-mlf8 .dispBlock{display:block}
.skn-mlf8 .flt-right{float:right}

.skn-mlf8 .ttc-align-left ul{text-align:left}
.skn-mlf8 .ttc-align-right ul{text-align:right}
.skn-mlf8 .ttc-align-center ul{text-align:center}
.skn-mlf8 .ttc-align-justify ul{text-align:justify}
.skn-mlf8 .ttc-align-right li:before,.skn-mlf8 .ttc-align-center li:before{position:relative;left:-4px}

/*Document*/
.skn-mlf8.MUK{text-size-adjust:none; -ms-text-size-adjust: none;-moz-text-size-adjust: none;-webkit-text-size-adjust: none}
.skn-mlf8{color:#020303;line-height:14px;table-layout:fixed;box-sizing:content-box;word-wrap:break-word;word-break:break-word;min-height:792px}
.skn-mlf8 .name{color:var(--theme-color);font-weight:bold;padding:5px 0 4px 0;text-align:left;text-transform:uppercase;position:relative}
.skn-mlf8 .section.SECTION_CNTC{float:left;margin-top:0}
.skn-mlf8 .resumeTitle{color:#0e0e0e;text-transform:uppercase;letter-spacing:.86px}
.skn-mlf8 .parentContainer{clear:both}
.skn-mlf8 .parentContainer .container{display:table-cell;padding-top:25px;padding-bottom:25px}
.skn-mlf8 .parentContainer .section{border-top:1px solid var(--theme-color);padding-top:4px;position:relative;width:100%;}
.skn-mlf8 .parentContainer .section:after{content:'';display:table;clear:both}
.skn-mlf8 .singlecolumn,.skn-mlf8 .sectiontitle{word-wrap:break-word}
.skn-mlf8 .parentContainer .paragraph:not(.firstparagraph) .singlecolumn{padding-top:3px;border-top:1px dashed #000}
.skn-mlf8 .SECTION_CNTC,.skn-mlf8 .topsection,.skn-mlf8 .parentContainer{width:100%;display:table;table-layout:fixed;}

/*Common left-right container*/
.skn-mlf8 .left-box{position:relative;float:left}
.skn-mlf8 .right-box .section:first-child{padding-top:0;margin-top:0;margin-bottom:16px}
.skn-mlf8 .left-box .section:first-child{padding-top:20px;margin-top:0}

.skn-mlf8 .skill{display:table;width:100%;table-layout:fixed}
.skn-mlf8 .skill .paddedline1{display:table-cell;width:50%}
.skn-mlf8 .skill .paddedline:last-child{padding-left:10px}

/*Top section*/
.skn-mlf8 .topsection{position:relative;border-top:25px solid var(--theme-color)}
.skn-mlf8 .section:empty{display:none}
.skn-mlf8 .parentContainer .section:first-child{margin-top:0}

/*PICT*/
.skn-mlf8 .PICTPic,.skn-mlf8 .PICTPic .field{display:table-cell;vertical-align:middle;text-align:center}
.skn-mlf8 .PICTPic img{width:100%}

/*Address and ALNK*/
.skn-mlf8 .adnlLnks ul li{padding:0;margin:0}
.skn-mlf8 .adnlLnks ul li:before{content:''}

/*Personal details section*/
.skn-mlf8 .pdet-sec .singlecolumn{display:flex;justify-content:space-between;flex-wrap:wrap}
.skn-mlf8 .details-wrap{width:49%}

/*SVG Icon Style*/
.skn-mlf8 .iconRow{clear:both;margin-bottom:8px;word-wrap:break-word;display:table;table-layout:fixed;width:100%;}
.skn-mlf8 .iconRow .iconSvg{width:10px;display:table-cell;vertical-align:middle}
.skn-mlf8 .iconRow .iconSvg svg{display:block;}
.skn-mlf8 .iconRow .icoTxt{padding-left:6px;vertical-align: middle;display:table-cell}
.skn-mlf8 .iconRow svg path.rect,.skn-mlf8 .topshape{fill:var(--theme-color)}
.skn-mlf8 .topshape{position:relative;top:-1px;vertical-align:top}
.skn-mlf8 .square{height: 10px;width: 10px;background-color: var(--theme-color);}
.skn-mlf8 .svg-inricon{fill: #fff;stroke: #fff;transform: scale(1.1);transform-origin:16px 14px}
.skn-mlf8 .svg-fillsqrbg{fill:var(--theme-color)}

/*Heading*/
.skn-mlf8 .heading{float:left;left:0;position:relative}
.skn-mlf8 .heading:before{content:'';position:absolute;left:0;top:-8px;border-top:3px solid var(--theme-color);background:var(--theme-color)}
.skn-mlf8 .sectiontitle{color:#000;letter-spacing:.1px;text-transform:uppercase;font-weight:bold}
.skn-mlf8 .jobtitle{text-transform:uppercase}

/*Signature*/
.skn-mlf8 .sign .field_sign{font-size:7px;color:#8a8a8a}
.skn-mlf8 .txtleft + .field_sign{text-align:left}
.skn-mlf8 .txtcenter + .field_sign{text-align:center}
.skn-mlf8 .txtright + .field_sign{text-align:right}
.skn-mlf8 .signPic span:first-child{padding-right:6px}
.skn-mlf8 .signPic img{max-width:100%}
.skn-mlf8 .sign.section{padding-top:0;margin-top:25px}

/*New logic for infographic*/
.skn-mlf8 .lang-sec .singlecolumn,.skn-mlf8 .skli-sec .singlecolumn,.skn-mlf8 .fielditem-olsh{display:none}
.skn-mlf8 .lang-sec.infobarsec .infobarpara,.skn-mlf8 .lang-sec.infotilesec .infotilepara,
.skn-mlf8 .skli-sec.infobarsec .infobarpara,.skn-mlf8 .skli-sec.infotilesec .infotilepara,.skn-mlf8 .nativeLangPara .fielditem-olsh{display:block}
.skn-mlf8 .hide-bar .field-ratt{display:none}

/*Infographic*/
.skn-mlf8 .lang-sec.infobarsec,.skn-mlf8 .skli-sec.infobarsec{font-size:0}
.skn-mlf8 .lang-sec.infobarsec .field *,.skn-mlf8 .lang-sec.infobarsec .nativeLangPara .field,
.skn-mlf8 .skli-sec.infobarsec .field *{display:inline}
.skn-mlf8 .lang-sec.infobarsec .paragraph,.skn-mlf8 .skli-sec.infobarsec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
.skn-mlf8 .lang-sec.infobarsec .singlecolumn,.skn-mlf8 .skli-sec.infobarsec .singlecolumn{margin-left:0;padding-left:0;position:relative}
.skn-mlf8 .lang-sec.infobarsec .para_odd,.skn-mlf8 .skli-sec.infobarsec .para_odd{margin-right:15px}
.skn-mlf8 .lang-sec.infobarsec .nativeLangPara{width:100%}
.skn-mlf8 .lang-sec.infobarsec .rating-bar,.skn-mlf8 .skli-sec.infobarsec .rating-bar{background:#d5d6d6;width:100%;clear:both;margin-top:3px;overflow:hidden}
.skn-mlf8 .lang-sec.infobarsec .inner-rating,.skn-mlf8 .skli-sec.infobarsec .inner-rating{position:relative;background-color:#000;height:4px;width:60%}
.skn-mlf8 .lang-sec.infobarsec > .paragraph:nth-last-child(1),.skn-mlf8 .lang-sec.infobarsec > .paragraph:nth-last-child(2),.skn-mlf8 .skli-sec.infobarsec > .paragraph:nth-last-child(1),.skn-mlf8 .skli-sec.infobarsec > .paragraph:nth-last-child(2){padding-bottom:0!important}
.skn-mlf8 .lang-sec .paragraph .singlecolumn,.skn-mlf8 .skli-sec .paragraph .singlecolumn{border:none!important;padding:0px!important}

.skn-mlf8 .skli-sec .paragraph:nth-last-child(1) .singlecolumn .field:last-child,.skn-mlf8 .skli-sec .paragraph:nth-last-child(2) .singlecolumn .field:last-child{min-height:0}

/*Infographic Tiles*/
.skn-mlf8 .lang-sec.infotilesec,.skn-mlf8 .skli-sec{font-size:0}
.skn-mlf8 .lang-sec.infotilesec .paragraph,.skn-mlf8 .skli-sec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
.skn-mlf8 .lang-sec.infotilesec > .paragraph:nth-last-child(1),.skn-mlf8 .lang-sec.infotilesec > .paragraph:nth-last-child(2),.skn-mlf8 .skli-sec > .paragraph:nth-last-child(1),.skn-mlf8 .skli-sec > .paragraph:nth-last-child(2){padding-bottom:0!important}
.skn-mlf8 .lang-sec.infotilesec .field *,.skn-mlf8 .skli-sec .field *,.skn-mlf8 .lang-sec.infotilesec .nativeLangPara .field{display:inline}
.skn-mlf8 .lang-sec.infotilesec .singlecolumn,.skn-mlf8 .skli-sec .paragraph .singlecolumn{margin-left:0}
.skn-mlf8 .lang-sec.infotilesec .sliced-rect,.skn-mlf8 .skli-sec .sliced-rect{height:6px;width:100%;line-height:0px;margin-top:3px;clear:both}
.skn-mlf8 .lang-sec.infotilesec .paragraph.para_odd,.skn-mlf8 .skli-sec .paragraph.para_odd{margin-right:15px}
.skn-mlf8 .lang-sec.infotilesec .sliced-rect .sliced-rect-tile,.skn-mlf8 .skli-sec .sliced-rect .sliced-rect-tile{height:100%;background-color:#d5d6d6;float:left;margin-right:3px}
.skn-mlf8 .lang-sec.infotilesec .sliced-rect .sliced-rect-tile:last-child,.skn-mlf8 .skli-sec .sliced-rect .sliced-rect-tile:last-child{margin-right:0}

/*Rectangular Rating Blocks*/
.skn-mlf8 .sliced-rect .sliced-rect-tile.ratvfill{background-color:#4a4a4a}
.skn-mlf8 .hide-bar .paragraph .rating-bar,.skn-mlf8 .hide-bar .paragraph .sliced-rect,.skn-mlf8 .hide-only-bar .rating-bar,.skn-mlf8 .hide-colon .paragraph .colon{display:none !important}

 
/*Infographic Languages Ordering Support*/
.skn-mlf8 .lang-sec.infobarsec .colon,.skn-mlf8 .lang-sec.infotilesec .colon{display:none}
.skn-mlf8 .lang-sec.infobarsec .field:first-child .colon,.skn-mlf8 .lang-sec.infotilesec .field:first-child .colon{display:inline;font-weight:bold}
.skn-mlf8 .lang-sec.infobarsec .field:first-child > span:first-child,  .skn-mlf8 .lang-sec.infotilesec .field:first-child > span:first-child{font-weight:bold}

/*MFR address order code*/
.skn-mlf8 .iconRow .icoTxt.zipsuffix{display:block}
.skn-mlf8 .iconRow .icoTxt.zipprefix,.skn-mlf8.MFR .iconRow .icoTxt.zipsuffix,.skn-mlf8.MES .iconRow .icoTxt.zipsuffix{display:none}
.skn-mlf8.MFR .iconRow .icoTxt.zipprefix,.skn-mlf8.MES .iconRow .icoTxt.zipprefix{display:block}
.skn-mlf8 .address{display:table;table-layout:fixed;width:100%;line-height: var(--line-height);}
.skn-mlf8 .address-inner{display:table-cell;width:50%;vertical-align:top}
.skn-mlf8 .address-inner:first-child{padding-right:10px}

/* PPDT */
.skn-mlf8 .disclaim .field.singlecolumn{margin-left:0}
.skn-mlf8 .disclaim .field.singlecolumn,.skn-mlf8 .disclaim .field.singlecolumn li,.skn-mlf8 .disclaim .field.singlecolumn p,.skn-mlf8 .disclaim .field.singlecolumn span{font-size:9px;color:#8a8a8a}
.skn-mlf8 .section.disclaim .heading:before,.skn-mlf8 .parentContainer .section.disclaim,.skn-mlf8 .parentContainer .section.sign{border:none}

/*For show/hide HPHN field based on portal*/
.skn-mlf8 .rnaphonefield,.skn-mlf8.RNA .nonrnaphonefield,.skn-mlf8.CLN .nonrnaphonefield{display:none}
.skn-mlf8 .nonrnaphonefield,.skn-mlf8.RNA .rnaphonefield,.skn-mlf8.CLN .rnaphonefield{display:block}

/* GRYR */
.skn-mlf8 .displayNoneThisField{display:none}/* Keep this class always at bottom */

/*For Extra Space Before Colon*/
.skn-mlf8 .beforecolonspace{display:none!important}
.skn-mlf8.MFR .beforecolonspace{display:inline!important}

/*HILT multi para/section*/
.skn-mlf8 .multi-para-hilt:after{content: "";display:block;clear:both;visibility:hidden;line-height:0;height:0} /*Clearfix*/
.skn-mlf8 .multi-para-hilt .paragraph{margin-bottom:20px;margin-top:0;width:49%;float:left}
.skn-mlf8 .multi-para-hilt .paragraph:last-child,.skn-mlf8 .multi-para-hilt .paragraph:nth-last-child(2):nth-child(2n){margin-bottom:0}
.skn-mlf8 .multi-para-hilt .paragraph:nth-child(2n+1){margin-left:2%}
.skn-mlf8 .parentContainer .multi-para-hilt .singlecolumn,.skn-mlf8 .multi-para-hilt .paragraph.firstparagraph{margin:0}
.skn-mlf8 .multi-section-hilt .multi-para-opt,.skn-mlf8 .section:not(.multi-para-hilt):not(.multi-section-hilt) .multi-para-opt,.skn-mlf8 .multi-para-hilt .twocol.skill{display:none}
.skn-mlf8 .multi-para-hilt .paragraph:nth-child(3) .singlecolumn{border-top:0;padding-top:0}


.skn-mlf8,.skn-mlf8 table{line-height:14px}
.skn-mlf8.pagesize{width:552px}
.skn-mlf8.fontsize,.skn-mlf8 .lang-sec.infobarsec .paragraph *,.skn-mlf8 .lang-sec.infotilesec .paragraph *,.skn-mlf8 .skli-sec .paragraph *{font-size:var(--font-size); line-height: var(--line-height);}
.skn-mlf8.fontface{font-family:'Nunito+Sans',sans-serif}
.skn-mlf8.hmargins{padding-left:30px;padding-right:30px}
.skn-mlf8 .section{margin-top:25px}
.skn-mlf8 .paragraph.firstparagraph{margin-top:0}
.skn-mlf8 .paragraph{margin-top:20px}
.skn-mlf8 .parentContainer .singlecolumn,.skn-mlf8 .parentContainer .maincolumn{margin-left:117px}

.skn-mlf8 .name{font-size:26px;line-height:34px;color:var(--theme-color)}
.skn-mlf8 .resumeTitle{font-size:12px;line-height:20px}
.skn-mlf8 .heading,.skn-mlf8 .heading:before{width:100px}
.skn-mlf8 .sectiontitle{font-size:10px;line-height:12px}
.skn-mlf8 .iconRow{line-height:12px}
.skn-mlf8 .left-box{width:117px}
.skn-mlf8 .right-box{margin-left:117px}
.skn-mlf8 .skli-sec .singlecolumn .field:last-child{min-height:12px}

.skn-mlf8 .parentContainer .section,.skn-mlf8 .heading:before{border-color:var(--theme-color)}
.skn-mlf8 .heading:before{background:var(--theme-color)}
.skn-mlf8 .topshape{fill:var(--theme-color);top:-1px}

/*Algorithm Width*/
.skn-mlf8 .PICTPic,.skn-mlf8 .PICTPic .field{max-width:100px}
.skn-mlf8 .topsection{border-top:25px solid var(--theme-color);min-height:150px}

/*Icons*/
.skn-mlf8 .iconRow svg path.rect{fill:var(--theme-color)}
.skn-mlf8 .iconRow svg .svg-fillsqrbg,.skn-mlf8 .iconRow .new-flds svg path,.skn-mlf8 .iconRow svg .svg-inriconobj{fill:var(--theme-color)}
.skn-mlf8 .iconRow .iconSvg{height:10px;width:10px}
.skn-mlf8 .iconRow .iconSvg svg{height:10px;width:10px}
.skn-mlf8 .square{height:10px;width:10px;background-color:var(--theme-color)}

/*Infographic Bar*/
.skn-mlf8 .lang-sec.infobarsec,.skn-mlf8 .skli-sec.infobarsec{padding-left:117px;box-sizing:border-box}
.skn-mlf8 .lang-sec.infobarsec .heading,.skn-mlf8 .skli-sec.infobarsec .heading,.skn-mlf8 .multi-para-hilt .heading{margin-left:-117px}
.skn-mlf8 .lang-sec.infobarsec .inner-rating,.skn-mlf8 .skli-sec.infobarsec .inner-rating{background-color:var(--theme-color)}
.skn-mlf8 .lang-sec.infobarsec .paragraph,.skn-mlf8 .skli-sec.infobarsec .paragraph{width:206px}
.skn-mlf8 .lang-sec.infobarsec .nativeLangPara{width:430px;max-width:430px}

.skn-mlf8 .fielditem-olsh{padding-top:5px;margin-bottom:-3px}

/*Infographic Tiles*/
.skn-mlf8 .lang-sec.infotilesec,.skn-mlf8 .skli-sec,.skn-mlf8 .multi-para-hilt{padding-left:117px;box-sizing:border-box}
.skn-mlf8 .lang-sec.infotilesec .heading,.skn-mlf8 .skli-sec .heading{margin-left:-117px}
.skn-mlf8 .lang-sec.infotilesec .paragraph,.skn-mlf8 .skli-sec .paragraph{width:206px}
.skn-mlf8 .lang-sec.infotilesec .nativeLangPara{width:430px;max-width:430px}

/*Rectangular Rating Blocks*/
.skn-mlf8 .paragraph .sliced-rect .sliced-rect-tile.ratvfill{background-color:var(--theme-color)}

/*Finalize Fixes*/
.skn-mlf8 .sortable-item i.far.fa-check{font-family:'Nunito+Sans',sans-serif}
.skn-mlf8 .lang-sec .sortable-item .para_odd,.skn-mlf8 .lang-sec .sortable-item .para_even{max-width:206px}
.skn-mlf8 .lang-sec .sortableInner .sortable-item:not(:first-child) .paragraph,.skn-mlf8 .skli-sec .sortableInner .sortable-item:not(:first-child) .paragraph{width:206px;vertical-align:top}
.skn-mlf8 .lang-sec .sortable-item .nativeLangPara{width:430px!important;max-width:430px}
.skn-mlf8 .data-LNGG .sortable-item:nth-last-child(1) .paragraph,.skn-mlf8 .data-LNGG .sortable-item:nth-last-child(2) .paragraph,.skn-mlf8 .data-SKLI .sortable-item:nth-last-child(1) .paragraph,.skn-mlf8 .data-SKLI .sortable-item:nth-last-child(2) .paragraph{padding-bottom:0}
.skn-mlf8 .name-contact .section.SECTION_CNTC, .skn-mlf8 .name-contact .section.SECTION_ALNK {float:none;display:inline-block;margin-top:0;padding: 0 1% 0 0;}
.skn-mlf8.LUK .parentContainer{padding-bottom:30px}
.skn-mlf8.LUK .right-box .SECTION_NAME{margin-top:0}
.page-finalize .skn-mlf8 .sortableInner .paragraph-container+.paragraph-container,.page-finalize .sortable-drag-item.skn-mlf8 .section{margin:0}
.skn-mlf8 .data-LNGG .sortableInner .sortable-item,.skn-mlf8 .data-SKLI .sortableInner .sortable-item{display:inline-block;vertical-align:top}
.skn-mlf8 .lang-sec.infobarsec .sortable-item .para_odd,.skn-mlf8 .lang-sec.infobarsec .sortable-item .para_even,.skn-mlf8 .skli-sec.infobarsec .sortable-item .para_odd,.skn-mlf8 .skli-sec.infobarsec .sortable-item .para_even{max-width:206px}
.skn-mlf8.LES .lang-sec .title-edit{margin-left:-117px}
.skn-mlf8 .left-box .sortable-item:first-child .section,.skn-mlf8 .right-box .sortable-item:first-child .section{margin-top:0}
.skn-mlf8 .left-box .sortable-item:first-child .section{padding-top:20px}
.skn-mlf8.LES{padding-bottom:25px}
.skn-mlf8.LES .data-EDUC .sortableInner,.skn-mlf8.LES .data-EXPR .sortableInner{display:inline-block;width:calc(100% - 104px)}
.skn-mlf8.LES .data-EDUC .sortableInner .singlecolumn,.skn-mlf8.LES .data-EXPR .sortableInner .singlecolumn{margin-left:16px}
.skn-mlf8.LES .data-EDUC .sortableInner .paragraph .doc-overlay,.skn-mlf8.LES .data-EXPR .sortableInner .paragraph .doc-overlay{left:5px!important}

/*PPDT*/
.skn-mlf8 .disclaim.section{margin:0;padding:0;margin-top:50px}

/*RNA fixes*/
.RNA .name-contact .firstsection{margin-top:0}
.RNA.skn-mlf8 .parentContainer{padding-bottom:25px}
.RNA.skn-mlf8 .data-LNGG .sortableInner .sortable-item{display:inline-block;vertical-align:top}
.RNA.skn-mlf8 .data-LNGG .nativeLangPara{width:100%!important;max-width:100%!important}
.RNA.skn-mlf8 .data-LNGG .para_odd{margin-right:15px}
.RNA.skn-mlf8 .data-LNGG .nativeLangPara .singlecolumn{width:430px!important;max-width:430px!important}
.RNA.skn-mlf8 .data-LNGG .firstparagraph.para_odd{max-width:206px!important}
.RNA.skn-mlf8 .PICTPic, .RNA.skn-mlf8 .PICTPic .field{height:129px; width:100px}
.skn-mlf8 .doc-item .heading::before, .skn-mlf8 .doc-item .heading{width:104px}
.skn-mlf8.LDE .left-box .SECTION_PICT{margin-top:0;padding-top:20px}
.skn-mlf8.LDE .right-box .SECTION_NAME {margin-top:0}
.skn-mlf8 .right-box .sortable-item .section.SECTION_NAME{margin-bottom:16px}
@-moz-document url-prefix() {
.skn-mlf8 .heading::before{top:-7px}
.resume-preview .skn-mlf8 .parentContainer .section{border-width:2px}
}

 /*Fixes for builder for skill*/
.skn-mlf8 .skli-sec .sortable-item .paragraph:last-child .singlecolumn .field:last-child{min-height:12px} 
.skn-mlf8 .skli-sec .sortable-item:nth-last-child(1) .paragraph .singlecolumn .field:last-child,.skn-mlf8 .skli-sec .sortable-item:nth-last-child(2) .paragraph .singlecolumn .field:last-child{min-height:0} 

/*PDF Flex Handling Code - Personal Information*/
.skn-mlf8.for-pdf .pdet-sec .singlecolumn{display:block}
.skn-mlf8.for-pdf .pdfpdwrapper{display:block}
.skn-mlf8.for-pdf .pdfpdwrapper .details-wrap:first-child{float:left;padding-right:5px}
.skn-mlf8.for-pdf .pdfpdwrapper .details-wrap:nth-child(2){float:right}
.skn-mlf8.for-pdf .pdfpdwrapper .details-wrap{width:203px;}
      </style>
      `;
  const contentElement = document.getElementById('downloadToWord').innerHTML;
    const contentWithStyles = cssStyles + contentElement;
    const docx = htmlDocx.asBlob(contentWithStyles, { orientation: 'portrait' });
    saveAs(docx, 'resume.docx');
  };

  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      <div {...(props.contentNotEditable ? {} : { contentEditable: true })} id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '10px' }}>
        {respokData ? (
          <div>
            <div class="template-preview selected" data-skinid="MLF1" data-skincd="MLF1" title="">

              <div class="render-preview">
                <div class="" tabindex="">
                  <div></div>
                  <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlf8 MLF1  RNA  " docskinwidth="612">
                    <div id="CONTAINER_PARENT_0" class="topsection hidesecondpass">
                      
                      <div id="CONTAINER_1" class="right-box">
                        <div data-react-beautiful-dnd-draggable="18" class="sortable-item section-container SortableItem-sibling name-contact ">

                          <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section notdraggable SECTION_NAME     " contentEditable={false} data-section-cd="NAME">
                            <div class=" doc-item">
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                    <div>

                                      <div class="name" dependency="FNAM|LNAM">
                                        <span id="FIELD_FNAM">{contact.f_name}</span>
                                        <span id="FIELD_LNAM">{contact.l_name}</span>
                                      </div>
                                      <div class="resumeTitle" dependency="DCTL" style={{ display: 'none' }}><span id="FIELD_DCTL"> </span></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section SECTION_CNTC notdraggable     " data-section-cd="CNTC">
                            <div class=" doc-item">
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="address">
                                        <div class="address-inner">
                                          {contact.city && <div class="iconRow" dependency="ADDR|STRT|CITY|STAT|ZIPC">
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M5.004 5.287a1.143 1.143 0 0 1 0-2.284 1.143 1.143 0 0 1 0 2.284zm0-3.407A2.266 2.266 0 0 0 2.74 4.143c0 1.55 2.026 3.823 2.112 3.92.08.09.222.09.303 0 .086-.097 2.112-2.37 2.112-3.92A2.266 2.266 0 0 0 5.003 1.88z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt zipsuffix">
                                              <span id="FIELD_STRT"></span><span dependency="STRT" style={{ display: 'none' }}><span dependency="NBHD|CITY|STAT" style={{ display: 'none' }}>,</span></span>
                                              <span id="FIELD_NBHD"></span><span dependency="NBHD" style={{ display: 'none' }}><span dependency="CITY|STAT" style={{ display: 'none' }}>, </span></span>
                                              <span id="FIELD_CITY"></span><span dependency="CITY+STAT" style={{ display: 'none' }}>,</span>
                                              <span id="FIELD_STAT"></span>
                                              <span id="FIELD_ZIPC"></span>
                                              <span id="FIELD_ADDR">{contact.city}</span>
                                            </div>
                                          </div>}
                                          <div class="iconRow" dependency="REMW" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_REMW"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow rnaphonefield" dependency="CPHN">
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M4.58 5.382c.62.606 1.166.801 1.366.853l.746-.73c.063-.061.168-.061.241 0l1.418 1.387c.032.031.053.072.053.113 0 .041-.01.093-.042.123l-.83.812-.02.02-.022.01c-.21.165-.483.247-.82.247-.934 0-2.237-.678-3.308-1.725-1.524-1.47-2.185-3.246-1.524-4.047l.032-.031.83-.812c.063-.061.168-.061.241 0L4.36 2.99c.031.03.052.072.052.113a.163.163 0 0 1-.052.113l-.746.73c.063.174.325.8.966 1.437zM0 0v9.768h9.99V0z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_CPHN">{contact.mobile}</span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="EMAI">
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M8.044 2.968v4.058c0 .073-.06.136-.131.136H1.72a.136.136 0 0 1-.13-.136V2.968c0-.074.06-.137.13-.137h6.192c.07 0 .13.063.13.137zM9.614 0H0v9.973h.161c.02.01.05.01.07.01h9.162c.03 0 .05-.01.07-.01h.151z"></path>
                                                <path class="rect" fill="#576d7b" d="M7.592 3.475a.087.087 0 0 0-.1 0L4.88 4.898c-.04.02-.09.02-.119 0L2.138 3.475c-.03-.02-.069-.01-.099 0-.03.021-.049.052-.049.083v.515c0 .042.02.073.05.093L4.76 5.723c.02.01.03.01.05.01.02 0 .03 0 .05-.01l2.72-1.557a.11.11 0 0 0 .05-.093v-.515c.01-.031-.01-.062-.04-.083z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_EMAI">{contact.email}</span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="AVAI" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M8,3.6c0.1,0,0.2-0.1,0.2-0.2V2.9c0-0.4-0.3-0.7-0.7-0.7H6.8V1.5c0-0.1-0.1-0.2-0.2-0.2H6.1
                                                               C6,1.3,5.9,1.4,5.9,1.5v0.7H4.1V1.5c0-0.1-0.1-0.2-0.2-0.2H3.3c-0.1,0-0.2,0.1-0.2,0.2v0.7H2.5c-0.4,0-0.7,0.3-0.7,0.7v0.5
                                                               c0,0.1,0.1,0.2,0.2,0.2H8z M7.5,8.7c0.4,0,0.7-0.3,0.7-0.7V4.3c0-0.1-0.1-0.2-0.2-0.2H2c-0.1,0-0.2,0.1-0.2,0.2V8
                                                               c0,0.4,0.3,0.7,0.7,0.7H7.5z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_AVAI"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="WEB1" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M4.6,6.2l0.3-0.3C5,5.8,5.1,5.7,5,5.5c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1
                                                               c-0.4-0.4-0.4-1,0-1.4l1-1c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5L7,4.4C6.9,4.5,6.9,4.6,7,4.7C7,4.9,7.1,5.2,7.1,5.4
                                                               c0,0.2,0.2,0.3,0.4,0.2L8,5C8.9,4.2,8.9,2.8,8,2C7.2,1.1,5.8,1.1,5,2l-1,1c0,0,0,0,0,0C3.2,3.8,3.1,5.2,4,6
                                                               c0.1,0.1,0.2,0.2,0.3,0.2C4.4,6.3,4.5,6.3,4.6,6.2z M5,8l1-1l0,0C6.8,6.2,6.9,4.8,6,4C5.9,3.9,5.8,3.8,5.7,3.8
                                                               c-0.1-0.1-0.2-0.1-0.3,0L5.1,4.1C5,4.2,4.9,4.3,5,4.5c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.4,0.4,0.4,1,0,1.4l-1,1
                                                               c-0.4,0.4-1.1,0.4-1.5,0c-0.4-0.4-0.4-1.1,0-1.5L3,5.6C3.1,5.5,3.1,5.4,3,5.3C3,5.1,2.9,4.8,2.9,4.6c0-0.2-0.2-0.3-0.4-0.2L2,5
                                                               C1.1,5.8,1.1,7.2,2,8C2.8,8.9,4.2,8.9,5,8z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_WEB1"></span>
                                            </div>
                                          </div>
                                          <div dependency="SOCL" style={{ display: 'none' }}>
                                          </div>
                                        </div>
                                        <div class="address-inner">
                                          <div class="iconRow" dependency="DOB1" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M2.4,5.2v2.3c0,0.2,0.2,0.4,0.4,0.4h1.9V5.2H2.4z"></path>
                                                <path fill="#fff" d="M7.6,3.7H6.4c0.1-0.1,0.2-0.1,0.2-0.2C7,3.2,7,2.7,6.6,2.4c-0.3-0.3-0.8-0.3-1.1,0
                                                               C5.4,2.6,5,3.2,5,3.7h0C5,3.2,4.6,2.6,4.5,2.4c-0.3-0.3-0.8-0.3-1.1,0C3,2.7,3,3.2,3.4,3.5c0.1,0.1,0.1,0.1,0.2,0.2H2.4
                                                               C2.2,3.7,2,3.9,2,4.1v0.6c0,0.1,0.1,0.2,0.2,0.2h2.4V4.1h0.8v0.8h2.4C7.9,4.8,8,4.7,8,4.6V4.1C8,3.9,7.8,3.7,7.6,3.7z M4.6,3.7
                                                               C4.6,3.7,4.6,3.7,4.6,3.7c-0.3,0-0.8-0.3-1-0.4c-0.2-0.2-0.2-0.4,0-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
                                                               C4.4,2.9,4.7,3.6,4.6,3.7z M5.5,3.7C5.4,3.7,5.4,3.7,5.5,3.7c-0.2-0.1,0.1-0.8,0.3-1c0.2-0.2,0.4-0.2,0.6,0c0.2,0.2,0.2,0.4,0,0.6
                                                               C6.2,3.4,5.7,3.7,5.5,3.7z"></path>
                                                <path fill="#fff" d="M5.4,5.2v2.6h1.9c0.2,0,0.4-0.2,0.4-0.4V5.2H5.4z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_DOB1"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="IDNV|IDNT" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 15 15">
                                                <path class="rect" fill="#576d7b" d="M14.3,0C14.7,0,15,0.3,15,0.8v13.5c0,0.4-0.3,0.7-0.7,0.7H0.8C0.3,15,0,14.7,0,14.3V0.8C0,0.3,0.3,0,0.8,0H14.3z"></path>
                                                <rect x="5.4" y="11.3" fill="#fff" width="4.2" height="0.8"></rect>
                                                <path fill="#fff" d="M7.5,2.9c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7C11.2,4.5,9.6,2.9,7.5,2.9z
                                                               M10.4,6.2H9.2c0-0.9-0.2-1.7-0.5-2.2C9.6,4.4,10.2,5.2,10.4,6.2z M4.6,7h1.2c0,0.9,0.2,1.7,0.5,2.2C5.4,8.8,4.8,8,4.6,7z M5.8,6.2
                                                               H4.6c0.1-1,0.8-1.8,1.6-2.2C6,4.5,5.8,5.3,5.8,6.2z M7.5,9.5c-0.3,0-0.8-1-0.9-2.5h1.8C8.3,8.5,7.8,9.5,7.5,9.5z M6.6,6.2
                                                               c0.1-1.5,0.6-2.5,0.9-2.5c0.3,0,0.8,1,0.9,2.5H6.6z M8.7,9.2C9,8.6,9.2,7.8,9.2,7h1.2C10.2,8,9.6,8.8,8.7,9.2z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span>
                                                <span>
                                                  <span id="FIELD_IDNT"></span><span dependency="IDNT+IDNV" style={{ display: 'none' }}>: </span>
                                                </span>
                                                <span id="FIELD_IDNV"></span>
                                              </span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="NTLY" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M6.3,3.6C6,2.4,5.6,1.5,5,1.5S4,2.4,3.7,3.6H6.3z M8.2,3.6C7.8,2.7,7,1.9,6,1.6
                                                               c0.3,0.5,0.6,1.2,0.7,2H8.2z M3.3,3.6c0.1-0.8,0.4-1.5,0.7-2c-1,0.3-1.8,1-2.2,2H3.3z M6.3,5.9c0-0.3,0-0.6,0-0.9
                                                               c0-0.3,0-0.6,0-0.9H3.7c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9H6.3z M8.4,5.9C8.5,5.6,8.5,5.3,8.5,5c0-0.3,0-0.6-0.1-0.9H6.8
                                                               c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9H8.4z M3.2,5.9c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9H1.6C1.5,4.4,1.5,4.7,1.5,5
                                                               c0,0.3,0,0.6,0.1,0.9H3.2z M5,8.5c0.6,0,1-0.9,1.3-2.2H3.7C4,7.6,4.4,8.5,5,8.5z M6,8.4c1-0.3,1.8-1,2.2-2H6.7
                                                               C6.6,7.2,6.3,7.9,6,8.4z M4,8.4c-0.3-0.5-0.6-1.2-0.7-2H1.8C2.2,7.3,3,8.1,4,8.4z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_NTLY"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="DRIV" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M2.8,7.7c0.2,0,0.4-0.2,0.4-0.4V6.8h3.6v0.4c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4
                                                               V6.5c0.1-0.2,0.2-0.4,0.2-0.6V5.2C8.3,5,8.2,4.7,8,4.6h0.3c0.1,0,0.1-0.1,0.2-0.1l0.1-0.3c0-0.1-0.1-0.2-0.2-0.2H7.6L7.3,3.3
                                                               c-0.2-0.6-0.8-1-1.4-1H4.1c-0.6,0-1.2,0.4-1.4,1L2.4,3.9H1.6C1.5,3.9,1.4,4,1.4,4.1l0.1,0.3c0,0.1,0.1,0.1,0.2,0.1H2
                                                               C1.8,4.7,1.7,5,1.7,5.2v0.7c0,0.2,0.1,0.4,0.2,0.6v0.8c0,0.2,0.2,0.4,0.4,0.4H2.8z M6.8,4.3H3.2l0.3-0.7c0.1-0.3,0.3-0.4,0.6-0.4
                                                               h1.8c0.3,0,0.5,0.2,0.6,0.4L6.8,4.3z M2.8,5.9c-0.3,0-0.4-0.2-0.4-0.4C2.3,5.2,2.5,5,2.8,5s0.7,0.4,0.7,0.7C3.4,5.9,3,5.9,2.8,5.9z
                                                               M6.6,5.7C6.6,5.4,7,5,7.2,5c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4C7,5.9,6.6,5.9,6.6,5.7z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_DRIV"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="MSTA" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M6.1,8.6c1.4,0,2.5-1.1,2.5-2.5v0c0-1.3-1-2.3-2.3-2.5c0.2,0.3,0.3,0.7,0.4,1
                                                               c0.5,0.2,1,0.9,1,1.4C7.7,7,7,7.7,6.1,7.7C5.3,7.7,4.6,7,4.6,6.2l0-0.1c0-0.6,0.3-1.1,0.8-1.4c0,0.1,0.1,0.3,0.1,0.5
                                                               C5.4,5.6,5.3,6,5,6.3c0,0.3,0.3,0.6,0.5,0.8c0.4-0.4,0.8-1.1,0.8-1.7l0-0.1c0-1.1-0.8-2.1-1.8-2.4l0.5-1L4.6,1.4H3.2L2.8,1.9l0.5,1
                                                               C2.2,3.1,1.4,4.1,1.4,5.2c0,1.4,1.1,2.5,2.5,2.5c0.1,0,0.2,0,0.3,0C4.6,8.2,5.5,8.6,6.1,8.6z M3.7,6.8C2.9,6.7,2.3,6,2.3,5.2
                                                               c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.7,0.1,1,0.4C4.1,4.4,3.7,5.2,3.7,6.1C3.7,6.3,3.7,6.6,3.7,6.8z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_MSTA"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="GNDR" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 13.5 13.5">
                                                <path class="rect" fill="#576d7b" d="M0,0h13.5v13.5H0V0z"></path>
                                                <path fill="#fff" d="M8.6,7.6C8.1,7.6,7.9,7.9,7,7.9S5.9,7.6,5.4,7.6c-1.4,0-2.5,1.1-2.5,2.5v0.5c0,0.5,0.4,0.9,0.9,0.9h6.4
                                                               c0.5,0,0.9-0.4,0.9-0.9v-0.5C11.1,8.7,10,7.6,8.6,7.6z M9.4,10.3H4.6V10c0-0.7,0.5-1.2,1.2-1.2C6,8.8,6.3,9,7,9c0.7,0,1-0.2,1.2-0.2
                                                               c0.7,0,1.2,0.5,1.2,1.2V10.3z M7,7.3c1.5,0,2.6-1.2,2.6-2.6S8.5,2.1,7,2.1S4.4,3.2,4.4,4.7S5.5,7.3,7,7.3z M7,3.2
                                                               c0.8,0,1.5,0.7,1.5,1.5S7.8,6.2,7,6.2S5.5,5.5,5.5,4.7S6.2,3.2,7,3.2z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_GNDR"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="PRTF" style={{ display: 'none' }}>
                                            <div class="iconSvg new-flds">
                                              <svg width="14px" height="14px" viewBox="0 0 14 14">
                                                <path fill="#576d7b" d="M12.9313286,0 C13.5191405,0 14.0000533,0.480910943 14.0000533,1.0687206 L14.0000533,1.0687206 L14.0000533,12.9312794 C14.0000533,13.5190891 13.5191405,14 12.9313286,14 L12.9313286,14 L1.06872467,14 C0.480912775,14 0,13.5190891 0,12.9312794 L0,12.9312794 L0,1.0687206 C0,0.480910943 0.480912775,0 1.06872467,0 L1.06872467,0 Z M10.5443657,4.00071875 C9.80572964,4.04275233 8.33750709,4.19412181 7.43084358,4.74541774 C7.36847531,4.78332085 7.33325558,4.85086615 7.33325558,4.921813 L7.33325558,4.921813 L7.33325558,9.79479277 C7.33325558,9.94932085 7.50348428,10.0469943 7.64656444,9.9753185 C8.57964274,9.50906161 9.92826495,9.38198899 10.594994,9.3470015 C10.8231885,9.33509604 11.0000209,9.1538414 11.0000209,8.93638444 L11.0000209,8.93638444 L11.0000209,4.41206472 C11.0000209,4.17492729 10.7928604,3.98686953 10.5443657,4.00071875 Z M3.45541641,4.00071875 C3.20716622,3.98686953 3.00000571,4.17492729 3.00000571,4.41206472 L3.00000571,4.41206472 L3.00000571,8.93662741 C3.00000571,9.15408437 3.17683811,9.33533901 3.40429888,9.3470015 C4.0717617,9.38198899 5.42136224,9.50954755 6.35395138,9.97604741 C6.49703154,10.0477232 6.66677108,9.95004976 6.66677108,9.79576465 L6.66677108,9.79576465 L6.66677108,4.91938331 C6.66677108,4.84843646 6.63130677,4.78380679 6.56893849,4.74541774 C5.66251957,4.19412181 4.19405244,4.04275233 3.45541641,4.00071875 Z" id="Combined-Shape"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_PRTF"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="VDCV" style={{ display: 'none' }}>
                                            <div class="iconSvg new-flds">
                                              <svg width="14px" height="14px" viewBox="0 0 14 14">
                                                <path fill="#576d7b" d="M12.9313286,0 C13.5191405,0 14.0000533,0.480910943 14.0000533,1.0687206 L14.0000533,1.0687206 L14.0000533,12.9312794 C14.0000533,13.5190891 13.5191405,14 12.9313286,14 L12.9313286,14 L1.06872467,14 C0.480912775,14 0,13.5190891 0,12.9312794 L0,12.9312794 L0,1.0687206 C0,0.480910943 0.480912775,0 1.06872467,0 L1.06872467,0 Z M7.66964423,4 L3.66371068,4 C3.29748776,4 3.00000571,4.27833333 3.00000571,4.62222222 L3.00000571,4.62222222 L3.00000571,8.37777778 C3.00000571,8.72111111 3.29748776,9 3.66371068,9 L3.66371068,9 L7.66964423,9 C8.03586715,9 8.3333492,8.72111111 8.3333492,8.37777778 L8.3333492,8.37777778 L8.3333492,4.62222222 C8.3333492,4.27833333 8.03586715,4 7.66964423,4 L7.66964423,4 Z M11.0000209,4.82666667 C11.0000209,4.49611111 10.595872,4.30055556 10.2995752,4.49111111 L10.2995752,4.49111111 L8.77779449,5.475 L8.77779449,7.52444444 L10.2995752,8.50777778 C10.5940942,8.69777778 11.0000209,8.50388889 11.0000209,8.17166667 L11.0000209,8.17166667 Z" id="Combined-Shape"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_VDCV"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="doc-overlay section-overlay" style={{ left: '-2px', right: '-2px' }}><span> </span></div>
                        </div>
                      </div>
                    </div>
                    <div id="CONTAINER_PARENT_1" class="parentContainer hidefirstpass">
                      <div id="CONTAINER_2">
                        <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-SUMM">

                          <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div>
                              </div>
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn" id="FIELD_FRFM">
                                        <p> {contact.summery}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-HILT">

                          <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div>
                              </div>
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn maincolumn">
                                        {skills[0] && <table class="twocol skill">
                                          <tbody>
                                            <ul dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                          </tbody>
                                        </table>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-EXPR">

                          <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div>
                              </div>
                              {experience?.map((item, index) => (
                                <div className="">
                                  <div class="">
                                    <div class="sortableInner">
                                      <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                        <div class="clearfix doc-item">
                                          <div class="singlecolumn" dependency="JTIT|JSTD|EDDT|COMP|JSTA|JCIT|JCNT|JCTR|JDES">
                                          <span className="jobdates" style={{ fontWeight: 'bold' }} dependency="JSTD|EDDT">
                                            <span class="jobtitle" id="FIELD_JTIT" style={{ fontWeight: 'bold' }}>{item.title} - {item.employer}</span><span dependency="JTIT"><br dependency="JCIT|JSTA|JCNT" /></span>
                                            <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                            <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                                            {item.intern == 1 && <b id="FIELD_EDDT"> (INTERNSHIP)</b>}
                                          </span>
                                            <span class="dispBlock locationGap" dependency="COMP|JSTA|JCIT|JCNT|JCTR">
                                              <span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span class="joblocation jobcountry" id="FIELD_JCNT"></span><span dependency="JCTR" style={{ display: 'none' }}><span dependency="COMP|JCIT|JSTA|JCNT"> - </span></span><span id="FIELD_JCTR"></span>
                                            </span>
                                            <span class="jobline" id="FIELD_JDES">
                                              <ul>
                                                <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                              </ul>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {item.achievements != '[]' && item.achievements != '[null]' && (
                                    <div className="singlecolumn maincolumn">

                                      <ul>
                                        {JSON.parse(item.achievements)?.map((item1, index) => (
                                          item1 && <li key={index}>{item1}</li>
                                        ))}
                                      </ul><br />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-EDUC">

                            <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                              <div class=" doc-item">
                                <div class="heading">
                                  <div class="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " class="rename-section text-rename"></span></div>
                                </div>
                                {education?.map((item, index) => (
                                  <div class="">
                                    <div class="">
                                      <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                        <div class="clearfix doc-item">
                                          <div class="singlecolumn">
                                            <div dependency="SCIT|SSTA|SCHO|GRYR|GRST|GRED|GRIP|SCNT" class="txtItl">
                                              
                                            </div>
                                            <div class="degreeGap" dependency="DGRE|STUY|GRPA|GRHN">
                                              
                                            <span className="degree">
                                              {item.course}&nbsp;
                                              {item.specialization && <span className="degree">,
                                                &nbsp;{item.specialization} &nbsp;
                                              </span>}

                                              {item.university && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                              </span>}
                                              {item.year_of_completion && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                              </span>}
                                            </span>
                                            </div>
                                            <span id="FIELD_FRFM"></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div><br />
                                    {item.achievements != '[]' && item.achievements != '[null]' && (
                                      <div className="singlecolumn maincolumn">

                                        <ul>
                                          {JSON.parse(item.achievements)?.map((item1, index) => (
                                            item1 && <li key={index}>{item1}</li>
                                          ))}
                                        </ul><br />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                            <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                              <div className=" doc-item">
                                <div className="heading">
                                  <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                </div>
                                <div className="">
                                  <div className="">
                                    <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                      <div className="clearfix doc-item">
                                        <div className="singlecolumn maincolumn">
                                          {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                            <div>
                                              <h4>Languages:</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <ul>
                                                  {JSON.parse(more)?.language?.map((item, index) => (
                                                    <li key={item.language}>{item.language}</li>
                                                  ))}
                                                </ul>
                                              </span>
                                            </div>}
                                        </div>

                                        {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn">
                                          <h4>Website & Profiles:</h4>
                                          <span className="paddedline" id="FIELD_SKC1">
                                            <ul>
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.work && <li key={item.work}><a href={item.work} target="_blank">{item.work}</a></li>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.portfolio && <li key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></li>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.profile && <li key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></li>
                                              ))}
                                            </ul>
                                          </span>
                                        </div>}

                                        {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                          {JSON.parse(more)?.custom?.map((item, index) => (
                                            <div>
                                              <h4>{item.custom_name}</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <p>
                                                  {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                                </p>
                                              </span>
                                            </div>
                                          ))}
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div></div>
        ) : (
          <Loading />
        )}
      </div>
     <div {...(props.contentNotEditable ? {} : { contentEditable: true })} id="downloadToWord" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '10px' }}>
        {respokData ? (
          <div>
            <div class="template-preview selected" data-skinid="MLF1" data-skincd="MLF1" title="">

              <div class="render-preview">
                <div class="" tabindex="">
                  <div></div>
                  <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlf8 MLF1  RNA  " docskinwidth="612">
                    <div id="CONTAINER_PARENT_0" class="topsection hidesecondpass">
                      
                      <div id="CONTAINER_1" class="right-box">
                        <div data-react-beautiful-dnd-draggable="18" class="sortable-item section-container SortableItem-sibling name-contact ">

                          <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section notdraggable SECTION_NAME     " contentEditable={false} data-section-cd="NAME">
                            <div class=" doc-item">
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                    <div>

                                      <div class="name" dependency="FNAM|LNAM">
                                        <span id="FIELD_FNAM">{contact.f_name}</span>
                                        <span id="FIELD_LNAM">{contact.l_name}</span>
                                      </div>
                                      <div class="resumeTitle" dependency="DCTL" style={{ display: 'none' }}><span id="FIELD_DCTL"> </span></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section SECTION_CNTC notdraggable     " data-section-cd="CNTC">
                            <div class=" doc-item">
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="address">
                                        <div class="address-inner">
                                          {contact.city && <div class="iconRow" dependency="ADDR|STRT|CITY|STAT|ZIPC">
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M5.004 5.287a1.143 1.143 0 0 1 0-2.284 1.143 1.143 0 0 1 0 2.284zm0-3.407A2.266 2.266 0 0 0 2.74 4.143c0 1.55 2.026 3.823 2.112 3.92.08.09.222.09.303 0 .086-.097 2.112-2.37 2.112-3.92A2.266 2.266 0 0 0 5.003 1.88z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt zipsuffix">
                                              <span id="FIELD_STRT"></span><span dependency="STRT" style={{ display: 'none' }}><span dependency="NBHD|CITY|STAT" style={{ display: 'none' }}>,</span></span>
                                              <span id="FIELD_NBHD"></span><span dependency="NBHD" style={{ display: 'none' }}><span dependency="CITY|STAT" style={{ display: 'none' }}>, </span></span>
                                              <span id="FIELD_CITY"></span><span dependency="CITY+STAT" style={{ display: 'none' }}>,</span>
                                              <span id="FIELD_STAT"></span>
                                              <span id="FIELD_ZIPC"></span>
                                              <span id="FIELD_ADDR">{contact.city}</span>
                                            </div>
                                          </div>}
                                          <div class="iconRow" dependency="REMW" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_REMW"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow rnaphonefield" dependency="CPHN">
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M4.58 5.382c.62.606 1.166.801 1.366.853l.746-.73c.063-.061.168-.061.241 0l1.418 1.387c.032.031.053.072.053.113 0 .041-.01.093-.042.123l-.83.812-.02.02-.022.01c-.21.165-.483.247-.82.247-.934 0-2.237-.678-3.308-1.725-1.524-1.47-2.185-3.246-1.524-4.047l.032-.031.83-.812c.063-.061.168-.061.241 0L4.36 2.99c.031.03.052.072.052.113a.163.163 0 0 1-.052.113l-.746.73c.063.174.325.8.966 1.437zM0 0v9.768h9.99V0z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_CPHN">{contact.mobile}</span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="EMAI">
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M8.044 2.968v4.058c0 .073-.06.136-.131.136H1.72a.136.136 0 0 1-.13-.136V2.968c0-.074.06-.137.13-.137h6.192c.07 0 .13.063.13.137zM9.614 0H0v9.973h.161c.02.01.05.01.07.01h9.162c.03 0 .05-.01.07-.01h.151z"></path>
                                                <path class="rect" fill="#576d7b" d="M7.592 3.475a.087.087 0 0 0-.1 0L4.88 4.898c-.04.02-.09.02-.119 0L2.138 3.475c-.03-.02-.069-.01-.099 0-.03.021-.049.052-.049.083v.515c0 .042.02.073.05.093L4.76 5.723c.02.01.03.01.05.01.02 0 .03 0 .05-.01l2.72-1.557a.11.11 0 0 0 .05-.093v-.515c.01-.031-.01-.062-.04-.083z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_EMAI">{contact.email}</span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="AVAI" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M8,3.6c0.1,0,0.2-0.1,0.2-0.2V2.9c0-0.4-0.3-0.7-0.7-0.7H6.8V1.5c0-0.1-0.1-0.2-0.2-0.2H6.1
                                                               C6,1.3,5.9,1.4,5.9,1.5v0.7H4.1V1.5c0-0.1-0.1-0.2-0.2-0.2H3.3c-0.1,0-0.2,0.1-0.2,0.2v0.7H2.5c-0.4,0-0.7,0.3-0.7,0.7v0.5
                                                               c0,0.1,0.1,0.2,0.2,0.2H8z M7.5,8.7c0.4,0,0.7-0.3,0.7-0.7V4.3c0-0.1-0.1-0.2-0.2-0.2H2c-0.1,0-0.2,0.1-0.2,0.2V8
                                                               c0,0.4,0.3,0.7,0.7,0.7H7.5z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_AVAI"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="WEB1" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M4.6,6.2l0.3-0.3C5,5.8,5.1,5.7,5,5.5c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1
                                                               c-0.4-0.4-0.4-1,0-1.4l1-1c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5L7,4.4C6.9,4.5,6.9,4.6,7,4.7C7,4.9,7.1,5.2,7.1,5.4
                                                               c0,0.2,0.2,0.3,0.4,0.2L8,5C8.9,4.2,8.9,2.8,8,2C7.2,1.1,5.8,1.1,5,2l-1,1c0,0,0,0,0,0C3.2,3.8,3.1,5.2,4,6
                                                               c0.1,0.1,0.2,0.2,0.3,0.2C4.4,6.3,4.5,6.3,4.6,6.2z M5,8l1-1l0,0C6.8,6.2,6.9,4.8,6,4C5.9,3.9,5.8,3.8,5.7,3.8
                                                               c-0.1-0.1-0.2-0.1-0.3,0L5.1,4.1C5,4.2,4.9,4.3,5,4.5c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.4,0.4,0.4,1,0,1.4l-1,1
                                                               c-0.4,0.4-1.1,0.4-1.5,0c-0.4-0.4-0.4-1.1,0-1.5L3,5.6C3.1,5.5,3.1,5.4,3,5.3C3,5.1,2.9,4.8,2.9,4.6c0-0.2-0.2-0.3-0.4-0.2L2,5
                                                               C1.1,5.8,1.1,7.2,2,8C2.8,8.9,4.2,8.9,5,8z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_WEB1"></span>
                                            </div>
                                          </div>
                                          <div dependency="SOCL" style={{ display: 'none' }}>
                                          </div>
                                        </div>
                                        <div class="address-inner">
                                          <div class="iconRow" dependency="DOB1" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M2.4,5.2v2.3c0,0.2,0.2,0.4,0.4,0.4h1.9V5.2H2.4z"></path>
                                                <path fill="#fff" d="M7.6,3.7H6.4c0.1-0.1,0.2-0.1,0.2-0.2C7,3.2,7,2.7,6.6,2.4c-0.3-0.3-0.8-0.3-1.1,0
                                                               C5.4,2.6,5,3.2,5,3.7h0C5,3.2,4.6,2.6,4.5,2.4c-0.3-0.3-0.8-0.3-1.1,0C3,2.7,3,3.2,3.4,3.5c0.1,0.1,0.1,0.1,0.2,0.2H2.4
                                                               C2.2,3.7,2,3.9,2,4.1v0.6c0,0.1,0.1,0.2,0.2,0.2h2.4V4.1h0.8v0.8h2.4C7.9,4.8,8,4.7,8,4.6V4.1C8,3.9,7.8,3.7,7.6,3.7z M4.6,3.7
                                                               C4.6,3.7,4.6,3.7,4.6,3.7c-0.3,0-0.8-0.3-1-0.4c-0.2-0.2-0.2-0.4,0-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
                                                               C4.4,2.9,4.7,3.6,4.6,3.7z M5.5,3.7C5.4,3.7,5.4,3.7,5.5,3.7c-0.2-0.1,0.1-0.8,0.3-1c0.2-0.2,0.4-0.2,0.6,0c0.2,0.2,0.2,0.4,0,0.6
                                                               C6.2,3.4,5.7,3.7,5.5,3.7z"></path>
                                                <path fill="#fff" d="M5.4,5.2v2.6h1.9c0.2,0,0.4-0.2,0.4-0.4V5.2H5.4z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_DOB1"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="IDNV|IDNT" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 15 15">
                                                <path class="rect" fill="#576d7b" d="M14.3,0C14.7,0,15,0.3,15,0.8v13.5c0,0.4-0.3,0.7-0.7,0.7H0.8C0.3,15,0,14.7,0,14.3V0.8C0,0.3,0.3,0,0.8,0H14.3z"></path>
                                                <rect x="5.4" y="11.3" fill="#fff" width="4.2" height="0.8"></rect>
                                                <path fill="#fff" d="M7.5,2.9c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7C11.2,4.5,9.6,2.9,7.5,2.9z
                                                               M10.4,6.2H9.2c0-0.9-0.2-1.7-0.5-2.2C9.6,4.4,10.2,5.2,10.4,6.2z M4.6,7h1.2c0,0.9,0.2,1.7,0.5,2.2C5.4,8.8,4.8,8,4.6,7z M5.8,6.2
                                                               H4.6c0.1-1,0.8-1.8,1.6-2.2C6,4.5,5.8,5.3,5.8,6.2z M7.5,9.5c-0.3,0-0.8-1-0.9-2.5h1.8C8.3,8.5,7.8,9.5,7.5,9.5z M6.6,6.2
                                                               c0.1-1.5,0.6-2.5,0.9-2.5c0.3,0,0.8,1,0.9,2.5H6.6z M8.7,9.2C9,8.6,9.2,7.8,9.2,7h1.2C10.2,8,9.6,8.8,8.7,9.2z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span>
                                                <span>
                                                  <span id="FIELD_IDNT"></span><span dependency="IDNT+IDNV" style={{ display: 'none' }}>: </span>
                                                </span>
                                                <span id="FIELD_IDNV"></span>
                                              </span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="NTLY" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M6.3,3.6C6,2.4,5.6,1.5,5,1.5S4,2.4,3.7,3.6H6.3z M8.2,3.6C7.8,2.7,7,1.9,6,1.6
                                                               c0.3,0.5,0.6,1.2,0.7,2H8.2z M3.3,3.6c0.1-0.8,0.4-1.5,0.7-2c-1,0.3-1.8,1-2.2,2H3.3z M6.3,5.9c0-0.3,0-0.6,0-0.9
                                                               c0-0.3,0-0.6,0-0.9H3.7c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9H6.3z M8.4,5.9C8.5,5.6,8.5,5.3,8.5,5c0-0.3,0-0.6-0.1-0.9H6.8
                                                               c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9H8.4z M3.2,5.9c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9H1.6C1.5,4.4,1.5,4.7,1.5,5
                                                               c0,0.3,0,0.6,0.1,0.9H3.2z M5,8.5c0.6,0,1-0.9,1.3-2.2H3.7C4,7.6,4.4,8.5,5,8.5z M6,8.4c1-0.3,1.8-1,2.2-2H6.7
                                                               C6.6,7.2,6.3,7.9,6,8.4z M4,8.4c-0.3-0.5-0.6-1.2-0.7-2H1.8C2.2,7.3,3,8.1,4,8.4z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_NTLY"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="DRIV" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M2.8,7.7c0.2,0,0.4-0.2,0.4-0.4V6.8h3.6v0.4c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4
                                                               V6.5c0.1-0.2,0.2-0.4,0.2-0.6V5.2C8.3,5,8.2,4.7,8,4.6h0.3c0.1,0,0.1-0.1,0.2-0.1l0.1-0.3c0-0.1-0.1-0.2-0.2-0.2H7.6L7.3,3.3
                                                               c-0.2-0.6-0.8-1-1.4-1H4.1c-0.6,0-1.2,0.4-1.4,1L2.4,3.9H1.6C1.5,3.9,1.4,4,1.4,4.1l0.1,0.3c0,0.1,0.1,0.1,0.2,0.1H2
                                                               C1.8,4.7,1.7,5,1.7,5.2v0.7c0,0.2,0.1,0.4,0.2,0.6v0.8c0,0.2,0.2,0.4,0.4,0.4H2.8z M6.8,4.3H3.2l0.3-0.7c0.1-0.3,0.3-0.4,0.6-0.4
                                                               h1.8c0.3,0,0.5,0.2,0.6,0.4L6.8,4.3z M2.8,5.9c-0.3,0-0.4-0.2-0.4-0.4C2.3,5.2,2.5,5,2.8,5s0.7,0.4,0.7,0.7C3.4,5.9,3,5.9,2.8,5.9z
                                                               M6.6,5.7C6.6,5.4,7,5,7.2,5c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4C7,5.9,6.6,5.9,6.6,5.7z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_DRIV"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="MSTA" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 10 10">
                                                <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                                <path fill="#fff" d="M6.1,8.6c1.4,0,2.5-1.1,2.5-2.5v0c0-1.3-1-2.3-2.3-2.5c0.2,0.3,0.3,0.7,0.4,1
                                                               c0.5,0.2,1,0.9,1,1.4C7.7,7,7,7.7,6.1,7.7C5.3,7.7,4.6,7,4.6,6.2l0-0.1c0-0.6,0.3-1.1,0.8-1.4c0,0.1,0.1,0.3,0.1,0.5
                                                               C5.4,5.6,5.3,6,5,6.3c0,0.3,0.3,0.6,0.5,0.8c0.4-0.4,0.8-1.1,0.8-1.7l0-0.1c0-1.1-0.8-2.1-1.8-2.4l0.5-1L4.6,1.4H3.2L2.8,1.9l0.5,1
                                                               C2.2,3.1,1.4,4.1,1.4,5.2c0,1.4,1.1,2.5,2.5,2.5c0.1,0,0.2,0,0.3,0C4.6,8.2,5.5,8.6,6.1,8.6z M3.7,6.8C2.9,6.7,2.3,6,2.3,5.2
                                                               c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.7,0.1,1,0.4C4.1,4.4,3.7,5.2,3.7,6.1C3.7,6.3,3.7,6.6,3.7,6.8z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_MSTA"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="GNDR" style={{ display: 'none' }}>
                                            <div class="iconSvg">
                                              <svg viewBox="0 0 13.5 13.5">
                                                <path class="rect" fill="#576d7b" d="M0,0h13.5v13.5H0V0z"></path>
                                                <path fill="#fff" d="M8.6,7.6C8.1,7.6,7.9,7.9,7,7.9S5.9,7.6,5.4,7.6c-1.4,0-2.5,1.1-2.5,2.5v0.5c0,0.5,0.4,0.9,0.9,0.9h6.4
                                                               c0.5,0,0.9-0.4,0.9-0.9v-0.5C11.1,8.7,10,7.6,8.6,7.6z M9.4,10.3H4.6V10c0-0.7,0.5-1.2,1.2-1.2C6,8.8,6.3,9,7,9c0.7,0,1-0.2,1.2-0.2
                                                               c0.7,0,1.2,0.5,1.2,1.2V10.3z M7,7.3c1.5,0,2.6-1.2,2.6-2.6S8.5,2.1,7,2.1S4.4,3.2,4.4,4.7S5.5,7.3,7,7.3z M7,3.2
                                                               c0.8,0,1.5,0.7,1.5,1.5S7.8,6.2,7,6.2S5.5,5.5,5.5,4.7S6.2,3.2,7,3.2z"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_GNDR"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="PRTF" style={{ display: 'none' }}>
                                            <div class="iconSvg new-flds">
                                              <svg width="14px" height="14px" viewBox="0 0 14 14">
                                                <path fill="#576d7b" d="M12.9313286,0 C13.5191405,0 14.0000533,0.480910943 14.0000533,1.0687206 L14.0000533,1.0687206 L14.0000533,12.9312794 C14.0000533,13.5190891 13.5191405,14 12.9313286,14 L12.9313286,14 L1.06872467,14 C0.480912775,14 0,13.5190891 0,12.9312794 L0,12.9312794 L0,1.0687206 C0,0.480910943 0.480912775,0 1.06872467,0 L1.06872467,0 Z M10.5443657,4.00071875 C9.80572964,4.04275233 8.33750709,4.19412181 7.43084358,4.74541774 C7.36847531,4.78332085 7.33325558,4.85086615 7.33325558,4.921813 L7.33325558,4.921813 L7.33325558,9.79479277 C7.33325558,9.94932085 7.50348428,10.0469943 7.64656444,9.9753185 C8.57964274,9.50906161 9.92826495,9.38198899 10.594994,9.3470015 C10.8231885,9.33509604 11.0000209,9.1538414 11.0000209,8.93638444 L11.0000209,8.93638444 L11.0000209,4.41206472 C11.0000209,4.17492729 10.7928604,3.98686953 10.5443657,4.00071875 Z M3.45541641,4.00071875 C3.20716622,3.98686953 3.00000571,4.17492729 3.00000571,4.41206472 L3.00000571,4.41206472 L3.00000571,8.93662741 C3.00000571,9.15408437 3.17683811,9.33533901 3.40429888,9.3470015 C4.0717617,9.38198899 5.42136224,9.50954755 6.35395138,9.97604741 C6.49703154,10.0477232 6.66677108,9.95004976 6.66677108,9.79576465 L6.66677108,9.79576465 L6.66677108,4.91938331 C6.66677108,4.84843646 6.63130677,4.78380679 6.56893849,4.74541774 C5.66251957,4.19412181 4.19405244,4.04275233 3.45541641,4.00071875 Z" id="Combined-Shape"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_PRTF"></span>
                                            </div>
                                          </div>
                                          <div class="iconRow" dependency="VDCV" style={{ display: 'none' }}>
                                            <div class="iconSvg new-flds">
                                              <svg width="14px" height="14px" viewBox="0 0 14 14">
                                                <path fill="#576d7b" d="M12.9313286,0 C13.5191405,0 14.0000533,0.480910943 14.0000533,1.0687206 L14.0000533,1.0687206 L14.0000533,12.9312794 C14.0000533,13.5190891 13.5191405,14 12.9313286,14 L12.9313286,14 L1.06872467,14 C0.480912775,14 0,13.5190891 0,12.9312794 L0,12.9312794 L0,1.0687206 C0,0.480910943 0.480912775,0 1.06872467,0 L1.06872467,0 Z M7.66964423,4 L3.66371068,4 C3.29748776,4 3.00000571,4.27833333 3.00000571,4.62222222 L3.00000571,4.62222222 L3.00000571,8.37777778 C3.00000571,8.72111111 3.29748776,9 3.66371068,9 L3.66371068,9 L7.66964423,9 C8.03586715,9 8.3333492,8.72111111 8.3333492,8.37777778 L8.3333492,8.37777778 L8.3333492,4.62222222 C8.3333492,4.27833333 8.03586715,4 7.66964423,4 L7.66964423,4 Z M11.0000209,4.82666667 C11.0000209,4.49611111 10.595872,4.30055556 10.2995752,4.49111111 L10.2995752,4.49111111 L8.77779449,5.475 L8.77779449,7.52444444 L10.2995752,8.50777778 C10.5940942,8.69777778 11.0000209,8.50388889 11.0000209,8.17166667 L11.0000209,8.17166667 Z" id="Combined-Shape"></path>
                                              </svg>
                                            </div>
                                            <div class="icoTxt">
                                              <span id="FIELD_VDCV"></span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="doc-overlay section-overlay" style={{ left: '-2px', right: '-2px' }}><span> </span></div>
                        </div>
                      </div>
                    </div>
                    <div id="CONTAINER_PARENT_1" class="parentContainer hidefirstpass">
                      <div id="CONTAINER_2">
                        <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-SUMM">

                          <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div>
                              </div>
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn" id="FIELD_FRFM">
                                        <p> {contact.summery}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-HILT">

                          <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div>
                              </div>
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn maincolumn">
                                        {skills[0] && <table class="twocol skill">
                                          <tbody>
                                            <ul dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                          </tbody>
                                        </table>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-EXPR">

                          <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                            <div class=" doc-item">
                              <div class="heading">
                                <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div>
                              </div>
                              {experience?.map((item, index) => (
                                <div className="">
                                  <div class="">
                                    <div class="sortableInner">
                                      <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                        <div class="clearfix doc-item">
                                          <div class="singlecolumn" dependency="JTIT|JSTD|EDDT|COMP|JSTA|JCIT|JCNT|JCTR|JDES">
                                          <span className="jobdates" style={{ fontWeight: 'bold' }} dependency="JSTD|EDDT">
                                            <span class="jobtitle" id="FIELD_JTIT" style={{ fontWeight: 'bold' }}>{item.title} - {item.employer}</span><span dependency="JTIT"><br dependency="JCIT|JSTA|JCNT" /></span>
                                            <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                            <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                                            {item.intern == 1 && <b id="FIELD_EDDT"> (INTERNSHIP)</b>}
                                          </span>
                                            <span class="dispBlock locationGap" dependency="COMP|JSTA|JCIT|JCNT|JCTR">
                                              <span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span class="joblocation jobcountry" id="FIELD_JCNT"></span><span dependency="JCTR" style={{ display: 'none' }}><span dependency="COMP|JCIT|JSTA|JCNT"> - </span></span><span id="FIELD_JCTR"></span>
                                            </span>
                                            <span class="jobline" id="FIELD_JDES">
                                              <ul>
                                                <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                              </ul>
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {item.achievements != '[]' && item.achievements != '[null]' && (
                                    <div className="singlecolumn maincolumn">

                                      <ul>
                                        {JSON.parse(item.achievements)?.map((item1, index) => (
                                          item1 && <li key={index}>{item1}</li>
                                        ))}
                                      </ul><br />
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                          <div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-EDUC">

                            <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                              <div class=" doc-item">
                                <div class="heading">
                                  <div class="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " class="rename-section text-rename"></span></div>
                                </div>
                                {education?.map((item, index) => (
                                  <div class="">
                                    <div class="">
                                      <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                        <div class="clearfix doc-item">
                                          <div class="singlecolumn">
                                            <div dependency="SCIT|SSTA|SCHO|GRYR|GRST|GRED|GRIP|SCNT" class="txtItl">
                                              
                                            </div>
                                            <div class="degreeGap" dependency="DGRE|STUY|GRPA|GRHN">
                                              
                                            <span className="degree">
                                              {item.course}&nbsp;
                                              {item.specialization && <span className="degree">,
                                                &nbsp;{item.specialization} &nbsp;
                                              </span>}

                                              {item.university && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                              </span>}
                                              {item.year_of_completion && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                              </span>}
                                            </span>
                                            </div>
                                            <span id="FIELD_FRFM"></span>
                                          </div>
                                        </div>
                                      </div>
                                    </div><br />
                                    {item.achievements != '[]' && item.achievements != '[null]' && (
                                      <div className="singlecolumn maincolumn">

                                        <ul>
                                          {JSON.parse(item.achievements)?.map((item1, index) => (
                                            item1 && <li key={index}>{item1}</li>
                                          ))}
                                        </ul><br />
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                            <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                              <div className=" doc-item">
                                <div className="heading">
                                  <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                </div>
                                <div className="">
                                  <div className="">
                                    <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                      <div className="clearfix doc-item">
                                        <div className="singlecolumn maincolumn">
                                          {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null &&
                                            <div>
                                              <h4>Languages:</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <ul>
                                                  {JSON.parse(more)?.language?.map((item, index) => (
                                                    <li key={item.language}>{item.language}</li>
                                                  ))}
                                                </ul>
                                              </span>
                                            </div>}
                                        </div>

                                        {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn">
                                          <h4>Website & Profiles:</h4>
                                          <span className="paddedline" id="FIELD_SKC1">
                                            <ul>
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.work && <li key={item.work}><a href={item.work} target="_blank">{item.work}</a></li>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.portfolio && <li key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></li>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.profile && <li key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></li>
                                              ))}
                                            </ul>
                                          </span>
                                        </div>}

                                        {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                          {JSON.parse(more)?.custom?.map((item, index) => (
                                            <div>
                                              <h4>{item.custom_name}</h4>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <p>
                                                  {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                                </p>
                                              </span>
                                            </div>
                                          ))}
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div></div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
});
export default Dynamictemp8;
