import React, { Component, useState, useEffect } from "react";
import target from "../img/target.png";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import axios from "axios";
import logo from "../img/logo.png";
import MainNav from "./MainNav";
import {
	HashRouter as Router,
	Route,
	Link,
	Navigate,
	useNavigate,
	DefaultRoute
} from "react-router-dom";

function Forgotpassword({ onSubmit }) {

	const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [reset, setReset] = useState(false);
  const [emailid, setEmailid] = useState('');

  const [formData1, setFormData1] = useState({
    password: '',
    cnf_password: '',
    email: ''
  });

	useEffect(() => {

		import('./Css/Login.scss');

    const params = new URLSearchParams(window.location.search);
    const myParamValue = params.get('email');

    if(myParamValue  !== null){

      formData1.email = myParamValue;
      setReset(true);
      setEmailid(myParamValue);
    }

    

    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME); 
    
    
	}, []);


	const [formData, setFormData] = useState({
		email: ''
	});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};
	const handleChange1 = (event) => {
		const { name, value } = event.target;
		setFormData1({ ...formData1, [name]: value });
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		if (formData.email == '') {
			toast.error('EmailAddress Required!!!');
		}else {
			const functionThatReturnPromise = () => new Promise((resolve, reject) => {
				axios({
					method: "POST",
					url: commonConfig.REACT_APP_API_URL + "forgot_password",
					data: formData,
					headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

				}).then(res => {
					var decodedData = res.data;
					var status = res.status;
					if (status) {
						resolve(status);
					}

				}).catch(err => {
					reject();
				});
			});

			toast.promise(
				functionThatReturnPromise,
				{
					pending: 'Please Wait...',
					success: 'Reset link sent to your mailId...',
					error: 'Failed 🤯'
				},
			)
		}
	};

	const handleSubmit1 = (event) => {
		event.preventDefault();

		if (formData1.password == '') {
			toast.error('Password Required!!!');
		} else if (formData1.password != formData1.cnf_password) {
			toast.error('Password Not Matched!!!');
		} else {
			const functionThatReturnPromise = () => new Promise((resolve, reject) => {
				axios({
					method: "POST",
					url: commonConfig.REACT_APP_API_URL + "change_password",
					data: formData1,
					headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

				}).then(res => {
					var decodedData = res.data;
					var status = res.status;
					if (status) {
            
						resolve(status);
            setTimeout(() => {
              navigate('/login')
            }, 500);
					}

				}).catch(err => {
					reject();
				});
			});

			toast.promise(
				functionThatReturnPromise,
				{
					pending: 'Please Wait...',
					success: 'Password Changed Successfully... 👌',
					error: 'Failed 🤯'
				},
			)
		}
	};


	return (
		<div className="containers loginpage">
      {page ? (
      <div>
        <MainNav/>
        <ToastContainer position="top-center" theme="dark" />
        <div className="left-section"></div>
        <div className="right-section">
          <div className="right_1l loginpage">
            <p className="subtitle"> <span className="clr_blue">Reset your password</span> </p>
            
            <h1><img src={logo} className="logo" alt="Centered Image" /></h1>
            {!reset ? (
            <div className="container_inner ">
              <form action="" method="" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input type="email" name="email" value={formData.email} onChange={handleChange} />
                  <label className="formlabel" htmlFor="f_name">Email address</label>
                </div>
                <button type="submit" className="reset_btn">Send Reset Link</button>

              </form>
            </div>
            ) : (
              <div className="container_inner ">
                <form action="" method="" onSubmit={handleSubmit1}>
                  <div className="form-group">
                    <input type="password" name="password" value={formData1.password} onChange={handleChange1} placeholder="Password" />
                  </div>
                  <div className="form-group">
                    <input type="password" name="cnf_password" value={formData1.cnf_password} onChange={handleChange1} placeholder="Confirm Password" />
                  </div>
                  <button type="submit" className="reset_btn">Reset Password</button>

                </form>
              </div>
            )}
            <div className="login_extra center-start-end">
              <Link to="/register"><div className="clr_blue bold">New User Sign Up</div></Link>
              <Link to="/login"><div className="clr_blue bold">Already Registered?</div></Link>
            </div>
          </div>
        </div>
        <img src={target} className="center-image" alt="Centered Image" />
      </div>
      ) : (
        <Loading content="Please Wait!!!"/>
      )}
		</div>
	);
}

export default Forgotpassword;
