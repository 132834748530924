import React, { Component, useState, useEffect,useRef } from "react";
import target from "../img/target.png";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import axios from "axios";
import logo from "../img/logo.png";
import choose_1 from "./Assets/choose_1.png";
import choose_2 from "./Assets/choose_2.png";
import MainNav from "./MainNav";
import { chatGPT } from './GPT/chatGPTApi';
import {
	HashRouter as Router,
	Route,
	Link,
	Navigate,
	useNavigate,
	DefaultRoute
} from "react-router-dom";
import LoadingGIF from './LoadingGIF';

function Choose({ onSubmit }) {

  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const fileInputRef = useRef(null);
  const userId = localStorage.getItem('userId');  

	useEffect(() => {
    
		import('./Css/Login.scss');
    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME); 

	}, []);


  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const handlenextClick = () => {
    navigate('/step1');
  };

  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    
    const formDatas = new FormData();
    formDatas.append('file', event.target.files[0]);
    formDatas.append('userId', userId);
    setPage(false);
      axios({
        method: "POST",
        url: "https://gojobs.ai/parsing/parse.php",
        data: formDatas,
        headers: { "Content-Type": "multipart/form-data"},
      }).then(res => {
        if(res.status == 200){
          let resp = res.data;
					let id= resp.id;
					let question= resp.PDFContent;
					localStorage.setItem('uploaded_resume_id',id);
					// return;
          // setPage(true);
          chatGPT(question).then(responseData => {
            const jsonobj = responseData;
            console.log('jsonobj',jsonobj);
            localStorage.setItem('parsed_json_data',JSON.stringify(jsonobj));
            setPage(true);
            navigate('/step1');
          }).catch(error => {

          });
          
        }
      }).catch(err => {

      });
  };

	


	return (
		<div className="containers loginpage">
      {page ? (
      <div>
        <MainNav/>
        <ToastContainer position="top-center" theme="dark" />
        <div className="choose_section">
          <div className="choose_heading center">
            <h4>Do u have a resume, or can we start blank?</h4>
          </div>

          <input
              type="file"
              onChange={handleResumeChange}
              accept=".pdf, .docx"
              ref={fileInputRef}
              style={{ display: 'none' }}
            />

          <div className="col-lg-12 mt-5">
            <div className="col-lg-6" >
              <img src={choose_1} className="choose-image-1 a1 pointer " onClick={handlenextClick} alt="Centered Image" />
            </div>
            <div className="col-lg-6">
              <img src={choose_2} className="choose-image-1 a2 pointer" onClick={handleButtonClick} alt="Centered Image" />
            </div>
          </div>
        </div>
        
      </div>
      ) : (
        <LoadingGIF/>
      )}
		</div>
	);
}

export default Choose;
