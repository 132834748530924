import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate } from "react-router-dom";
import Nav from "./Nav";
import { format, startOfDay, endOfDay, parse } from "date-fns";
import { commonConfig } from "../config.js";
import axios from "axios";
import link_button from "./Assets/plus.png";
import link_button2 from "./Assets/minus.png";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import LoadingGIF from './LoadingGIF';

function Step9() {
  const [tabsData, setTabsData] = useState([]);
  const [respData, setRespData] = useState([]);
  const [respokData, setRespokData] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [tagText, setTagText] = useState('');
  const [textareaValue, setTextareaValue] = useState('');
  const [data, setData] = useState({});
  const [skillsArray, setSkillsArray] = useState([]);
  const [descriptionArray, setDescriptionArray] = useState([]);
  const [resp, setResp] = useState([]);
  const [loading, setLoading] = useState(true);
  const [gptloaded, setGptloaded] = useState(0);
  const [page, setPage] = useState(false);
  let is_edit = 0;
  const [stepno, setStepno] = useState(9);
  const [rhtml, setRHtml] = useState('');
  const [rhtml1, setRHtml1] = useState('');
  const [rhtml2, setRHtml2] = useState('');
  const [gptsummery, setGptsummery] = useState([]);
  const [toggleClass, setToggleClass] = useState(false);

  const resume_id = localStorage.getItem('resume_id');
  const userId = localStorage.getItem('userId');
  const CommonData = localStorage.getItem('common_data');
  const navigate = useNavigate();
  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }
    if (CommonData) {
      if (JSON.parse(CommonData).plan.can_create == 0 && JSON.parse(CommonData).plan.can_edit == 0) {
        navigate('/step14');
      }
    }
    import("./Css/Step8.scss");

    if (resume_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        const contact = result.contact[0];

        if (contact.step === 100) {
          navigate('/step13');
          setStepno(contact.step);
        } else {
          setStepno(contact.step + 1);
        }
        const GPT_summery = JSON.parse(result.contact[0].summery);
        if (GPT_summery) {
          setGptsummery(GPT_summery);
        }



      }).catch(err => {

      });
    }

    let isMounted = true;



    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      const exp = result.experience;
      const edu = result.education;
      const contact = result.contact[0];
      const summery = JSON.parse(result.skills[0].summery);
      let html = '';
      let html1 = '';
      let html2 = '';
      let html3 = '';
      let html4 = '';
      let html5 = '';
      let html6 = '';
      exp.forEach(item => {
        if (item.is_summery == 1) {
          html += item.GPT_skills + " ,";
          html1 += item.description + " ,";
        }
      });

      if (html == '') {
        exp.forEach(item => {
          html += item.GPT_skills + " ,";
          html1 += item.description + " ,";
        });
      }
      edu.forEach(item => {
        if (item.is_summery == 1) {
          html2 += item.education + ", " + item.course + ", " + item.specialization;
        }
      });

      html3 = summery.to_position;
      html4 = summery.to_company;
      html5 = summery.to_jobdesc;
      html6 = summery.summery;

      console.log("question", html);

      let question = '';

      // if (contact.is_summery == 0) {
        if (html2 != '' && html1 != '' && html3 != null && html6 != null) {
          question = 'I am applying for a job of "' + html3 + '" in the company "' + html4 + '"  having the job description "' + html5 + '"  Now  I want to  customize my resume for this and add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '".Key words for these jobs are: "' + html + '". Also incorporate my Education Qualification "' + html2 + '"  and "' + html6 + '" in the write up.';

          question += 'Please create a short profile summery customizing my current experience and education with the job "' + html3 + '"  I am applying to. The profile summery created by you should take the reference from my work experience and education making the profile summery apt for the "' + html3 + '"  without mentioning the job title of "' + html3 + '"  or the "' + html4 + '" ';

          question += 'The profile summery should be less than 75 words and not more than 6 sentences which highlights my profile and justifies as how I am suitable for "' + html3 + '" Job. This profile should impress the prospective recruiters to select my Resume. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summaries in single json array format without key.';
        } else if (html2 != '' && html1 != '' && html3 != null) {
          question = 'I am applying for a job of "' + html3 + '" in the company "' + html4 + '"  having the job description "' + html5 + '"  Now  I want to  customize my resume for this and add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '".Key words for these jobs are: "' + html + '". Also include my Education Qualification "' + html2 + '" in the write up.';

          question += 'Please create a short profile summery customizing my current experience and education with the job "' + html3 + '"  I am applying to. The profile summery created by you should take the reference from my work experience and education making the profile summery apt for the "' + html3 + '"  without mentioning the job title of "' + html3 + '"  or the "' + html4 + '" ';

          question += 'The profile summery should be less than 75 words and not more than 6 sentences which highlights my profile and justifies as how I am suitable for "' + html3 + '" Job. This profile should impress the prospective recruiters to select my Resume. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summaries in single json array format without key.';
        } else if (html1 != '' && html3 != null && html6 != null) {
          question = 'I am applying for a job of "' + html3 + '" in the company "' + html4 + '"  having the job description "' + html5 + '"  Now  I want to  customize my resume for this and add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '".Key words for these jobs are: "' + html + '". Also incorporate  "' + html6 + '" in the write up.';

          question += 'Please create a short profile summery customizing my current experience and education with the job "' + html3 + '"  I am applying to. The profile summery created by you should take the reference from my work experience and education making the profile summery apt for the "' + html3 + '"  without mentioning the job title of "' + html3 + '"  or the "' + html4 + '" ';

          question += 'The profile summery should be less than 75 words and not more than 6 sentences which highlights my profile and justifies as how I am suitable for "' + html3 + '" Job. This profile should impress the prospective recruiters to select my Resume. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summaries in single json array format without key.';
        } else if (html1 != '' && html3 != null) {
          question = 'I am applying for a job of "' + html3 + '" in the company "' + html4 + '"  having the job description "' + html5 + '"  Now  I want to  customize my resume for this and add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '".Key words for these jobs are: "' + html + '".';

          question += 'Please create a short profile summery customizing my current experience and education with the job "' + html3 + '"  I am applying to. The profile summery created by you should take the reference from my work experience and education making the profile summery apt for the "' + html3 + '"  without mentioning the job title of "' + html3 + '"  or the "' + html4 + '" ';

          question += 'The profile summery should be less than 75 words and not more than 6 sentences which highlights my profile and justifies as how I am suitable for "' + html3 + '" Job. This profile should impress the prospective recruiters to select my Resume. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summaries in single json array format without key.';
        } else if (html2 != '' && html1 != '' && html6 != null) {
          question = 'I want to add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '" "Key words for these jobs: "' + html + '". Also include my Education Qualification "' + html2 + '" and "' + html6 + '" in the write up. Please create a short summery for me which is less than 75 words and not more than 6 sentences which highlight my profile and will impress the prospective recruiters to select my CV. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summeries in single json array format without key.';
        } else if (html1 != '' && html6 != null) {
          question = 'I want to add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '" "Key words for these jobs: "' + html + '". Also incorporate "' + html6 + '" in the write up. Please create a short summery for me which is less than 75 words and not more than 6 sentences which highlight my profile and will impress the prospective recruiters to select my CV. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summeries in single json array format without key.';
        } else if (html2 != '' && html1 != '') {
          question = 'I want to add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '" "Key words for these jobs: "' + html + '". Also include my Education Qualification "' + html2 + '". Please create a short summery for me which is less than 75 words and not more than 6 sentences which highlight my profile and will impress the prospective recruiters to select my CV. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summeries in single json array format without key.';
        } else if (html1 != '') {
          question = 'I want to add a short profile to my resume on top. In this profile I want to include the following jobs which I have done earlier: "' + html1 + '" "Key words for these jobs: "' + html + '" Please create a short summery for me which is less than 75 words and not more than 6 sentences which highlight my profile and will impress the prospective recruiters to select my CV. Make sure this profile is written in a professional tone, style and language. Please provide two alternate summeries in single json array format without key.';
        }
      // } else {

      //   setResp(contact.summery_resp);
      //   setGptloaded(2);
      //   setRespokData(true);

      // }
      // console.log("question",question);

      if (question != '') {
        setGptloaded(1);
        chatGPT(question).then(responseData => {
          const resp = responseData;
          setResp(responseData);
          setRespokData(true);
          save_data(responseData, 1);
        }).catch(error => {
          console.error('Error:', error);
        });
      }

    }).catch(err => {

    });

    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);

  const save_data = (item, id) => {
    const requestData = {
      summary: item,
      id: id,
      type: 'summary'
    };
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "skills/GPTstore",
      data: requestData,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
    }).then(res => {

    }).catch(err => {
      // Handle error
    });
  }


  const chatGPT = async (question) => {
    return new Promise(async (resolve, reject) => {
      try {
        const apiUrl = commonConfig.GPT_API_URL;
        const apiKey = commonConfig.GPT_API_KEY;
        const requestBody = {
          "model": "gpt-3.5-turbo",
          "messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
          "temperature": 0.7
        };
        const headers = {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${apiKey}`
        };
        const { data } = await axios.post(apiUrl, requestBody, { headers });
        const result = data.choices[0].message.content;
        const json = JSON.parse(result);
        setRespData(json);
        resolve(json);
      } catch (error) {
        console.error('Error:', error);
        reject(error);
      }
    });
  };



  const [editorContent, setEditorContent] = useState("");
  const [blurredText, setBlurredText] = useState("");

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setEditorContent(data);
  };

  const handleTabClick = (index) => {
    setActiveTab(index);
  };


  const handleTagClick = (tag) => {
    setData(tag);
    if (textareaValue.includes(tag)) {
      setTextareaValue(tag);
    } else {
      setTextareaValue(tag);
    }
  };




  const getButtonClass = (tag) => {
    return textareaValue.includes(tag) ? 'tag1 active' : 'tag1';
  };

  const handleSubmit = (event) => {
    // const resume_id = localStorage.getItem('resume_id');
    // console.log("sss",JSON.stringify(childData));
    event.preventDefault();

    if (gptsummery.length > 0) {
      is_edit = 1;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "contacts/summery_update",
      data: { data: data },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id, "step": 9, "isedit": is_edit },
    }).then(res => {
      var decodedData = res.data;
      var status = res.status;
      if (status) {
        navigate('/step13');
      }
    }).catch(err => {
      // Handle error
    });
  };

  const handleButtonClick = () => {
    setToggleClass(!toggleClass);
  };


  return (
    <div className="step8page">

      <main id="app" role="main">
        <section className="page-wrapper">
          <div
            id="parent"
            className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
          >
            {page && <Nav value={stepno} />}
            <div className="inner1_step1">
              <h3 className="headtag">
                <span className="blue">Enter Your Profile Summary.</span>
              </h3>
              <p style={{ marginBottom: "3%", fontWeight: "200" }} className="p_tag">
                Choose your profile summary from expert suggessions.
              </p>
            </div>
            {respokData ? (
              <div>
                {/* system view */}
                <form action="" method="" className="system_view">
                  <div className="col-lg-6">

                    {gptloaded == 2 && <section className="section_1">
                      {JSON.parse(resp)?.map((keyword, index) => (
                        <div onClick={() => handleTagClick(keyword)} className={getButtonClass(keyword)}>
                          <img id={`image_${index}`} className="img_container1" src={link_button} alt="Image 1" />
                          <div id={`container_${index}`} className="container_new">
                            {keyword}
                          </div>
                        </div>
                      ))}
                    </section>}


                    {gptloaded == 1 && <section className="section_1">
                      {resp?.map((keyword, index) => (
                        <div onClick={() => handleTagClick(keyword)} className={getButtonClass(keyword)}>
                          <img id={`image_${index}`} className="img_container1" src={link_button} alt="Image 1" />
                          <div id={`container_${index}`} className="container_new">
                            {keyword}
                          </div>
                        </div>
                      ))}
                    </section>}
                  </div>
                  <div className="col-lg-6">
                    <div style={{ height: "50%" }}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={textareaValue}
                        onChange={handleEditorChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6" style={{ clear: "both", marginTop: '2rem' }}>
                    <div className="col-lg-6" style={{ clear: "both", marginTop: '2rem' }}>
                      <Link to="/step2" className="btn-stepsback">
                        Back
                      </Link>
                    </div>
                    <div className="col-lg-6" style={{ marginTop: '2rem' }}>
                      <button type="button" onClick={handleSubmit} className="btn-mangaii">
                        Next
                      </button>
                    </div>    
                  </div>
                  
                </form>


                {/* mobile view         */}
                <form action="" method="" className="mobile_view">
                  <div className="col-lg-6">
                    <div style={{ height: "50%" }}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={textareaValue}
                        onChange={handleEditorChange}
                      />
                    </div>
                  </div>
                  <div className={toggleClass ? 'col-lg-6 resp_popup' : 'col-lg-6'}>
                    {gptloaded == 2 && <section className={toggleClass ? 'section_1 h_auto' : 'section_1'}>
                      <div className="p-10" style={{ height: '40px' }}>
                        <div className="col-md-8">
                          <div className="f-17 bold">Expert Suggestion</div>
                        </div>
                        <div className="col-md-4">
                          {!toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleUp /></div>}
                          {toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleDown /></div>}
                        </div>
                      </div>
                      {JSON.parse(resp)?.map((keyword, index) => (
                        <div onClick={() => handleTagClick(keyword)} className={getButtonClass(keyword)}>
                          <img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
                          <div id={`container_${index}`} className="container_new">
                            {keyword}
                          </div>
                        </div>
                      ))}
                    </section>}


                    {gptloaded == 1 && <section className={toggleClass ? 'section_1 h_auto' : 'section_1'}>
                      <div className="p-10" style={{ height: '40px' }}>
                        <div className="col-md-8">
                          <div className="f-17 bold">Expert Suggestion</div>
                        </div>
                        <div className="col-md-4">
                          {!toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleUp /></div>}
                          {toggleClass && <div className="f-17 bold f-right" onClick={handleButtonClick}><FaArrowCircleDown /></div>}
                        </div>
                      </div> 
                      {resp?.map((keyword, index) => (
                        <div onClick={() => handleTagClick(keyword)} className={getButtonClass(keyword)}>
                          <img id={`image_${index}`} className="img_container" src={link_button} alt="Image 1" />
                          <div id={`container_${index}`} className="container_new">
                            {keyword}
                          </div>
                        </div>
                      ))}
                    </section>}
                  </div>

                  <div className="col-lg-6 half" style={{ clear: "both", marginTop: '2rem' }}>
                    <Link onClick={() => navigate(-1)} className="btn-stepsback">
                      Back
                    </Link>
                  </div>
                  <div className="col-lg-6 half" style={{ marginTop: '2rem' }}>
                    <button type="button" onClick={handleSubmit} className="btn-mangaii">
                      Next
                    </button>
                  </div>
                </form>
              </div>

            ) : (
              // <Loading content="Do Not Refresh the page as we are working on your Profile!" />
              <LoadingGIF />
            )}
          </div>
        </section>
        <StepsFooter/>
      </main>
    </div>
  );
}

export default Step9;