import React, { Component, useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Link, useNavigate } from 'react-router-dom';
import Top_1 from "./Assets/interview/top.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import MainNav from "./MainNav";
import Footer from "./Footer";
import "./Assets/CSS/aos.css";
import "./Assets/CSS/glightbox.min.css";
import "./Assets/CSS/swiper-bundle.min.css";
import "./Assets/CSS/style.css";
import LoadingGIF from './LoadingGIF';
import Loading from './Loading';
import CoverModal from './CoverModal';
import ResumeInterviewModal from './ResumeInterviewModal';
import { FileUploader } from "react-drag-drop-files";
import { commonConfig } from '../config.js';
import axios from "axios";
import { faEdit, faTrashAlt, faDownload } from '@fortawesome/free-solid-svg-icons';

function InterviewStep2() {
  const navigate = useNavigate();
  const [page, setPage] = useState(false);
  const [boxopen, setBoxopen] = useState(false);
  const [resumeopen, setResumeopen] = useState(false);
  const userId = localStorage.getItem('userId');
  const interviewResumeId = localStorage.getItem('interviewResumeId');
  const [errors, setErrors] = useState([]);
  const [contact, setContact] = useState({});
  const [expData, setExpData] = useState([]);
  const [eduData, setEduData] = useState([]);
  const [skills, setSkills] = useState([]);
  const [coverData, setCoverData] = useState(null);
  const fileTypes = ["JPG", "PNG", "GIF"];
  const [formData, setFormData] = useState({
    company: '',
    job_applied_to: '',
    department: '',
    location: '',
    level: '',
    description: '',
    reporting_to: '',
    postion_ctc: '',
    kra: '',
    date_of_interview: '',
    hr_name: '',
    cover_id: ''
  });
  useEffect(() => {
    import('./Css/Interview.scss');
    import('./Css/InterviewStep.scss');
    // import('./Assets/CSS/bootstrap.min.css');
    getResumeDetails();
    setTimeout(() => {
      setPage(true);
      setBoxopen(true);
    }, 1500);
  }, []);

  const getResumeDetails = () => {
    if (interviewResumeId) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: interviewResumeId },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        setContact(result.contact[0]);
        setExpData(result.experience);
        setEduData(result.education);
        setSkills(result.skills[0]);

      }).catch(err => {

      });
    }
  }

  const handleNext = () => {
    navigate('/interviewStep3');
  };



  return (

    <div className="interviewSteppage interviewpage" style={{backgroundColor:'#F5F5F5'}}>
      {page ? (
        <div>
          <MainNav value="7" />
          <section className="progressTop">
              <ol class="ProgressBar" style={{marginTop:'3%'}}>
							 <li class="ProgressBar-step is-complete">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel"><b>Enter Details</b></span>
							 </li>
							 <li class="ProgressBar-step is-complete">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel"><b>Verify Details</b></span>
							 </li>
							 <li class="ProgressBar-step {{step3}}">
								 <svg class="ProgressBar-icon"></svg>
								 <span class="ProgressBar-stepLabel">Interview Preparation</span>
							 </li>
						 </ol>
          </section>
          <section className="banner2">
            <div className="col-lg-12 center-start-end resumeSection">
              <div>
                <h6>Please verify your details to proceed further</h6>
              </div>
              <div>
                <button id="btnBack" onClick={handleNext} type="button" className="btn-mangaii" >
                  Continue
                </button>
              </div>
            </div>
            <div className="mt-10">
              <div className="step2_sec shadow">
                <h6>{contact.f_name} {contact.l_name}</h6>
                <div>{contact.mobile}</div>
                <div>{contact.email}</div>
              </div>
              <div className="step2_sec shadow">
                <h6>Professional Summary</h6>
                <div>{contact.summery}</div>
              </div>
              <div className="step2_sec shadow">
                <h6>Experience</h6>
                {expData?.map((item, index) => (<div className="exp_list">
                  <div className="exp_title center-start-end bold" style={{color:'#000000'}}>
                    <div>{item.title}, {item.employer}</div>
                    <div>
                      <span className="mr-60 clickable" ></span>
                      <span className="clickable"></span>
                    </div>
                  </div>
                  <div className="exp_loc">
                    <div>
                      <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                      <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>
                    </div>
                  </div>

                  <div className="exp_loc">
                    <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                    {item.achievements != '[]' && item.achievements != '[null]' && <div>
                      <b>Achievements:</b>
                      <ul>
                        {JSON.parse(item.achievements)?.map((item1, index) => (
                          item1 && <li key={index}>{item1}</li>
                        ))}
                      </ul><br />
                    </div>}
                  </div><br/>
                </div>))}
              </div>
              <div className="step2_sec shadow">
                <h6>Education</h6>
                {eduData?.map((item, index) => (<div className="exp_list">
                  <div className="exp_title center-start-end bold">
                    <div>{item.course} {item.specialization}</div>
                    <div>
                      <span className="mr-60 clickable" ></span>
                      <span className="clickable"></span>
                    </div>
                  </div>
                  <div className="exp_loc">
                    <div>{item.university}</div>
                  </div>
                  <div className="exp_loc">
                    <div>
                      <span id="FIELD_JSTD" format="%m/%Y">{item.year_of_start}</span><span dependency="JSTD+EDDT"> - </span>
                      <span id="FIELD_EDDT" format="%m/%Y">{item.year_of_completion}</span>
                    </div>
                  </div><br/>
                  
                </div>))}
              </div>
              <div className="step2_sec shadow">
                <h6>Skills</h6>
                <div dangerouslySetInnerHTML={{ __html: skills?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
              </div>
            </div>
          </section>


          <Footer />
        </div>
      ) : (
        <Loading content="" />
      )}
    </div>

  );
}

export default InterviewStep2;
