import React, { useEffect, useState } from "react";
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Link, useNavigate } from 'react-router-dom';
import Nav from "./Nav";
import { commonConfig } from '../config.js';
import Loading from './Loading';
import StepsFooter from './StepsFooter';


function Step12() {
	const [tabsData, setTabsData] = useState([]);
	const [respData, setRespData] = useState([]);
	const [respokData, setRespokData] = useState(false);
	const [activeTab, setActiveTab] = useState(0);
	const [tagText, setTagText] = useState('');
	const [textareaValue, setTextareaValue] = useState('');
	const [data, setData] = useState({});
	const [skillsArray, setSkillsArray] = useState([]);
    const [loading, setLoading] = useState(true);
	const [resp, setResp] = useState([]);
  const [stepno, setStepno] = useState(12);
  const userId = localStorage.getItem('userId');
	const resume_id = localStorage.getItem('resume_id');
  const CommonData = localStorage.getItem('common_data');
	const navigate = useNavigate();
	useEffect(() => {
    if (!userId) {
      navigate('/login');
    }

    if(CommonData){
      if(JSON.parse(CommonData).plan.can_create  == 0 && JSON.parse(CommonData).plan.can_edit == 0){
        navigate('/step14');
      }
    }
    
		import("./Css/Step8.scss");
		let isMounted = true;

    if(resume_id){
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        const contact = result.contact[0];

        if(contact.step === 100){
          navigate('/step13');
        }
      }).catch(err => {

      });
    }

    
		axios({
			method: "GET",
			url: commonConfig.REACT_APP_API_URL + "education",
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
		}).then(res => {

			var decodedData = res.data;
			const result = decodedData.data;
			setTabsData(result);

			const processedData = [];
			let html="";
			result.forEach(item => {
        if(item.achievements != '[]'){
				  html+="While doing "+item.education+", in the "+item.university+", I achieved "+item.achievements+" ";
        }
				
			});
			
      if(html != ''){
        const question = 'During my education, I have achieved the following mile stones:"'+html+'"Please rewrite the above-mentioned achievements in bullet format in a professional manner. I would like to add them to my CV and impress the recruiters. Please provide the in the form of a html list with list-type bullets .';

        console.log('question:', question);
        chatGPT(question).then(responseData => {
            const resp = responseData;
            setResp(responseData);
            setRespokData(true);
        }).catch(error => {
            console.error('Error:', error);
        });
      }else{
        navigate('/step13');
      }
		
		}).catch(err => {


		});
	}, []);


	const chatGPT = async (question) => {
		return new Promise(async (resolve, reject) => {
			try {
				const apiUrl = commonConfig.GPT_API_URL;
				const apiKey = commonConfig.GPT_API_KEY;
				const requestBody = {
					"model": "gpt-3.5-turbo",
					"messages": [{ role: 'system', content: 'You are a helpful assistant.' }, { "role": "user", "content": question }],
					"temperature": 0.7
				};
				const headers = {
					'Content-Type': 'application/json',
					'Authorization': `Bearer ${apiKey}`
				};
				const { data } = await axios.post(apiUrl, requestBody, { headers });
				const result = data.choices[0].message.content;
				setEditorContent(result);
				
				resolve(result);
			} catch (error) {
				console.error('Error:', error);
				reject(error);
			}
		});
	};

	const [editorContent, setEditorContent] = useState("");
	const [blurredText, setBlurredText] = useState("");

	const handleEditorChange = (event, editor) => {
		const data = editor.getData();
		setEditorContent(data);
	};
	const handleTabClick = (index) => {
		setActiveTab(index);
	};



	const handleTagClick = (tag) => {

		setData(tag);

		if (textareaValue.includes(tag)) {
			setTextareaValue(tag);
		} else {
			setTextareaValue(tag);
		}
	};


	const getButtonClass = (tag) => {
		return textareaValue.includes(tag) ? 'tag1 active' : 'tag1';
	};

	const handleSubmit = (event) => {
		const resume_id = localStorage.getItem('resume_id');
		event.preventDefault();
		axios({
			method: "POST",
			url: commonConfig.REACT_APP_API_URL + "contacts/edu_achievements",
			data: {data:editorContent},
			headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN, "resumeid": resume_id },
		}).then(res => {
			var decodedData = res.data;
			var status = res.status;
			if (status) {
				navigate('/step13');
			}
		}).catch(err => {
			// Handle error
		});
	};

	return (
		<div className="step8page">
    {respokData ? (
      <div>
			<main id="app" role="main">
				<section className="page-wrapper">
					<div id="parent" className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB">
          <Nav value={stepno} />
						<div className="step1_sec">
							<div className="inner1_step1">
								<h3 className="headtag">
									<span className="blue">Your Academic Achievements</span>
								</h3>
								<p style={{ marginBottom: '3%', fontWeight: '200' }} className="h5 p-sub-title blue">
									Add Or Remove Academic Achievements As Required.
								</p>
							</div>
							<div className="inner2_step1">
								
								<form action="" method="" onSubmit={handleSubmit}>
									<div className="col-lg-12">
										<div className="ckeditor-container ">
											<CKEditor
												editor={ClassicEditor}
												data={editorContent}
												onChange={handleEditorChange}
												config={{
													toolbar: {
														items: [
															'heading',
															'|',
															'bold',
															'italic',
															'link',
															'bulletedList',
															'numberedList',
															'blockQuote',
															'alignment',
															'|',
															'undo',
															'redo',
														],
													},

												}}


											/>
										</div>
									</div>

									<div className="col-lg-12 btn1">
										<div className="col-lg-6" style={{ clear: "both" }}>
											<Link to="/step2" className="btn-stepsback">Back</Link>
										</div>
										<div className="col-lg-6">
											<button type="submit" className="btn-mangaii">Next</button>
										</div>
									</div>

								</form>
												
							</div>
						</div>
					</div>
				</section>
			</main>
      <StepsFooter/>
		</div>
    ) : (
      <Loading content="Do Not Refresh the page as we are working on your Profile!"/>
    )}
    </div>

	);
}

export default Step12;
