import React, { Component, useEffect, useState, useRef,forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import { FaEdit } from "react-icons/fa";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const Dynamictemp10 = forwardRef((props, ref) => { 
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);

  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  let resume_id = localStorage.getItem('resume_id');
  const a = ["PHP", "Javascipt", "HTML", "CSS", "Bootstap", "ionic", "Angular", "react", "Laravel", "Codeigniter", "Wordpress"];
  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }

    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);


      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);

 

  const printDocument = (title) => {
    const element = document.getElementById('pdfContainer');
    console.log(htmlContent); 
    const data = {
      htmlContent: htmlContent,
      resume_id:resume_id,
      template:1
    };
    console.log('htmlContent',htmlContent);
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "generate-pdf",
      data: data,
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      if(title == "save"){
        toast.success("Saved Successfully!!!");
      }
      
    }).catch(err => {

    });
  };

  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print:print
  }));
  
  return (
    <div>
          {/* <button onClick={printDocument}>Print</button> */}
      <ToastContainer position="top-center" theme="dark" />
      <div contentEditable id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{padding: '10px'}}>
        {respokData ? (
               <li class="template-preview" data-skinid="HDR1" data-skincd="HDR1" title="">
               <div class="selected-badge">
                 <div class="fal fa-check icon-badge"></div>
               </div>
               <button type="button" class="btn btn-link preview-btn" aria-label="HDR1 skin preview "></button>
               <div class="render-preview">
                 <div class="" tabindex="">
                   <div>
                
                   </div>
                   <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-hdr1 HDR1  RNA  " docskinwidth="542">
                     <div class="name-contact ">
                       <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section nameSec SECTION_NAME     " data-section-cd="NAME">
                         <div class=" doc-item">
                           <div class="">
                             <div class="">
                               <div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                 <div>
                                   <div class="monogram dynamicIcon" dependency="MURL" style={{display: 'none'}}>
                                     <div id="FIELD_MURL"></div>
                                   </div>
                                   <div class="monogram monogram-flname" dependency="FNAM|LNAM">
                                     <svg viewBox="0 0  67  64" enable-background="0 0 67 64" class="processlogopdf">
                                       <rect x="0px" y="0px" width="67px" height="64px" fill="none"></rect>
                                       <text id="SUBSTR_FALN" text-anchor="middle" x="34px" y="42px" fill="#ffffff" font-size="20px">DA</text>
                                     </svg>
                                   </div>
                                   <div class="name">
                                     <span id="FIELD_FNAM">{contact.f_name}</span>
                                     <span dependency="FNAM+LNAM"></span>
                                     <span id="FIELD_LNAM">{contact.l_name}</span>
                                   </div>
                                   <div class="profTitle" dependency="DCTL" style={{display: 'none'}}>
                                     <span id="FIELD_DCTL"></span>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                       <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section SECTION_CNTC sec-cntc     " data-section-cd="CNTC">
                         <div class=" doc-item">
                           <div class="">
                             <div class="">
                               <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                 <div class="clearfix doc-item">
                                   <div class="address">
                                     <div>
                                       <span class="zipsuffix" dependency="ADDR|STRT|CITY|STAT|ZIPC">
                                         <span id="FIELD_STRT"></span>
                                         <span dependency="STRT" style={{display: 'none'}}>
                                           <span dependency="NBHD|CITY|STAT|ZIPC" style={{display: 'none'}}>, </span>
                                         </span>
                                         <span id="FIELD_NBHD"></span>
                                         <span dependency="NBHD" style={{display: 'none'}}>
                                           <span dependency="CITY|STAT|ZIPC" style={{display: 'none'}}>, </span>
                                         </span>
                                         <span class="spaced" id="FIELD_CITY"></span>
                                         <span dependency="CITY+STAT|ZIPC" style={{display: 'none'}}>, </span>
                                         <span class="spaced" id="FIELD_STAT"></span>
                                         <span dependency="STAT+ZIPC" style={{display: 'none'}}>, </span>
                                         <span class="spaced" id="FIELD_ZIPC"></span>
                                         <span id="FIELD_ADDR">{contact.address_1} {contact.address_2}</span>
                                       </span>
                                     </div>
                                     <span id="FIELD_EMAI">{contact.email}</span>
                                     <span class="sprtr" dependency="EMAI+CPHN"> / </span>
                                     <span id="FIELD_CPHN" dependency="CPHN">{contact.mobile}</span>
                                     <div dependency="DOB1|DRIV|NTLY|MSTA|IDNV|IDNT|WEB1|AVAI|SOCL" style={{display: 'none'}}>
                                       <span dependency="DOB1" style={{display: 'none'}}>
                                         <span class="txt-bold">
                                           <span class="xslt_static_change" id="DOCDATAINFO_DOB1">undefined</span>
                                           <span class="beforecolonspace"></span>
                                           <span>: </span>
                                         </span>
                                         <span id="FIELD_DOB1"></span>
                                       </span>
                                       <span class="sprtr" dependency="DOB1" style={{display: 'none'}}>
                                         <span dependency="DRIV|NTLY|MSTA|IDNV|IDNT|WEB1|AVAI|SOCL" style={{display: 'none'}}> / </span>
                                       </span>
                                       <span dependency="DRIV" style={{display: 'none'}}>
                                         <span class="txt-bold">
                                           <span class="xslt_static_change" id="DOCDATAINFO_DRIV">undefined</span>
                                           <span class="beforecolonspace"></span>
                                           <span>: </span>
                                         </span>
                                         <span id="FIELD_DRIV"></span>
                                       </span>
                                       <span class="sprtr" dependency="DRIV" style={{display: 'none'}}>
                                         <span dependency="NTLY|MSTA|IDNV|IDNT|WEB1|AVAI|SOCL" style={{display: 'none'}}> / </span>
                                       </span>
                                       <span dependency="NTLY" style={{display: 'none'}}>
                                         <span class="txt-bold">
                                           <span class="xslt_static_change" id="DOCDATAINFO_NTLY"></span>
                                           <span class="beforecolonspace"></span>
                                           <span>: </span>
                                         </span>
                                         <span id="FIELD_NTLY"></span>
                                       </span>
                                       <span class="sprtr" dependency="NTLY" style={{display: 'none'}}>
                                         <span dependency="MSTA|IDNV|IDNT|WEB1|AVAI|SOCL" style={{display: 'none'}}> / </span>
                                       </span>
                                       <span dependency="MSTA" style={{display: 'none'}}>
                                         <span class="txt-bold">
                                           <span class="xslt_static_change" id="DOCDATAINFO_MSTA"></span>
                                           <span class="beforecolonspace"></span>
                                           <span>: </span>
                                         </span>
                                         <span id="FIELD_MSTA"></span>
                                       </span>
                                       <span class="sprtr" dependency="MSTA" style={{display: 'none'}}>
                                         <span dependency="IDNV|IDNT|WEB1|AVAI|SOCL" style={{display: 'none'}}> / </span>
                                       </span>
                                       <span dependency="IDNV|IDNT" style={{display: 'none'}}>
                                         <span dependency="IDNV|IDNT" style={{display: 'none'}}>
                                           <span class="txt-bold">
                                             <span id="FIELD_IDNT"></span>
                                             <span class="beforecolonspace"></span>
                                             <span dependency="IDNT+IDNV" style={{display: 'none'}}>: </span>
                                           </span>
                                           <span id="FIELD_IDNV"></span>
                                         </span>
                                       </span>
                                       <span class="sprtr" dependency="IDNV|IDNT" style={{display: 'none'}}>
                                         <span dependency="WEB1|AVAI|SOCL" style={{display: 'none'}}> / </span>
                                       </span>
                                       <span class="xslt_static_change txt-bold" dependency="AVAI" id="DOCDATAINFO_AVAI" style={{display: 'none'}}>Other</span>
                                       <span class="beforecolonspace"></span>
                                       <span dependency="AVAI" class="txt-bold" style={{display: 'none'}}>: </span>
                                       <span id="FIELD_AVAI"></span>
                                       <span class="sprtr" dependency="AVAI+WEB1|SOCL" style={{display: 'none'}}> / </span>
                                       <span dependency="WEB1" class="dispInBlk social" style={{display: 'none'}}>
                                         <span class="xslt_static_change txt-bold" id="DOCDATAINFO_WEB1">Website</span>
                                         <span class="beforecolonspace"></span>
                                         <span class="txt-bold">: </span>
                                         <span class="field wrd-brk" id="FIELD_WEB1"></span>
                                       </span>
                                       <span class="sprtr" dependency="WEB1+SOCL" style={{display: 'none'}}> / </span>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <div class="parent-wrapper">
                       <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                         <div class=" doc-item">
                           <div class="heading">
                             <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary <span title=" Summary " class="rename-section text-rename"></span>
                             </div>
                           </div>
                           <div class="">
                             <div class="">
                               <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                 <div class="clearfix doc-item">
                                   <div class="singlecolumn" id="FIELD_FRFM">
                                     <p>  {contact.summery}</p>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                       <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                         <div class=" doc-item">
                           <div class="heading">
                             <div class="sectiontitle" id="SECTIONNAME_HILT">Skills <span title=" Skills " class="rename-section text-rename"></span>
                             </div>
                           </div>
                           <div class="">
                             <div class="">
                               <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                 <div class="clearfix doc-item">
                                   <div class="singlecolumn maincolumn">
                                     <table class="twocol skill">
                                       <tbody>
                                         <tr>
                                         <ul>
                                          {JSON.parse(skills[0].skills)?.map((item, index) => (
                                            item && <li key={index}>{item}</li>
                                          ))}
                                        </ul>
                                         </tr>
                                       </tbody>
                                     </table>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                         </div>
                       </div>
                       <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                         <div class=" doc-item">
                           <div class="heading">
                             <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience <span title=" Experience " class="rename-section text-rename"></span>
                             </div>
                           </div>
                           {experience?.map((item, index) => ( <div class="">
                             <div class="sortableInner">
                               <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                 <div class="clearfix doc-item">
                                   <div class="singlecolumn">
                                     <span class="paddedline padb5 txtRglr" dependency="JTIT|COMP|JSTD|EDDT|JCIT|JSTA|JCNT|JCTR">
                                       <span class="jobtitle" id="FIELD_JTIT">{item.title}</span>
                                       <span dependency="JTIT" class="sprtr">
                                         <span dependency="COMP|JCIT|JSTA|JCNT|JCTR"> / </span>
                                       </span>
                                       <span class="companyname" id="FIELD_COMP">{item.employer}</span>
                                       <span dependency="COMP">
                                         <span dependency="JSTA|JCIT|JCNT|JCTR"> - </span>
                                       </span>
                                       <span class="jobcity" id="FIELD_JCIT">{item.city}</span>
                                       <span dependency="JCIT+JSTA|JCNT">, </span>
                                       <span class="jobstate" id="FIELD_JSTA">{item.country}</span>
                                       <span dependency="JSTA+JCNT" style={{display: 'none'}}>, </span>
                                       <span class="joblocation jobcountry" id="FIELD_JCNT"></span>
                                       <span dependency="JCTR" style={{display: 'none'}}>
                                         <span dependency="JSTA|JCIT|JCNT"> - </span>
                                       </span>
                                       <span id="FIELD_JCTR"></span>
                                       <span class="datesWrapper txtItl" dependency="JSTD|EDDT">
                                         <span class="jobdates" id="FIELD_JSTD" format="%m/%Y">{item.start_date}</span>
                                         <span dependency="JSTD+EDDT"> - </span>
                                         <span class="jobdates" id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present':item.end_date}</span>
                                       </span>
                                     </span>
                                     <span class="jobline" id="FIELD_JDES">
                                       <ul>
                                       <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                       </ul>
                                     </span>
                                   </div>
                                 </div>
                               </div>
                              
                             </div>
                           </div>
                               ))}
                         </div>
                       </div>
                       <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                         <div class=" doc-item">
                           <div class="heading">
                             <div class="sectiontitle" id="SECTIONNAME_EDUC">Education and Training <span title=" Education and Training " class="rename-section text-rename"></span>
                             </div>
                           </div>
                           {education?.map((item, index) => (<div class="">
                             <div class="">
                               <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                 <div class="clearfix doc-item">
                                   <div class="singlecolumn">
                                     <span class="paddedline">
                                       <span class="paddedline" dependency="DGRE|STUY|GRHN|GRYR|GRST|GRED|GRIP">
                                         <span class="degree" id="FIELD_DGRE">{item.education}</span>
                                         <span dependency="DGRE">
                                           <span dependency="STUY" style={{display: 'none'}}>
                                             <span class="beforecolonspace"></span>
                                             <span>: </span>
                                           </span>
                                         </span>
                                         <span class="programline" id="FIELD_STUY"></span>
                                         <span dependency="DGRE|STUY">
                                           <span dependency="GRHN" style={{display: 'none'}}> - </span>
                                         </span>
                                         <span id="FIELD_GRHN"></span>
                                         <span class="datesWrapper txtItl" dependency="GRYR">
                                           <span class="xslt_static_change displayNoneThisField">Expected in </span>
                                           <span id="FIELD_GRYR" format="%m/%Y">{item.year_of_completion}</span>
                                         </span>
                                         <span class="datesWrapper txtItl" dependency="GRST|GRED|GRIP" style={{display: 'none'}}>
                                           <span class="jobdates" id="FIELD_GRST" format="%m/%Y"></span>
                                           <span dependency="GRST+GRED" style={{display: 'none'}}> - </span>
                                           <span class="jobdates" id="FIELD_GRED" format="%m/%Y"></span>
                                           <span dependency="GRED|GRST" style={{display: 'none'}}>
                                             <span dependency="GRIP" style={{display: 'none'}}>-</span>
                                           </span>
                                           <span id="FIELD_GRIP"></span>
                                         </span>
                                       </span>
                                       <span class="paddedline" dependency="SCIT|SCHO|SSTA|SCNT">
                                         <span class="companyname companyname_educ" id="FIELD_SCHO">{item.university}</span>
                                         <span class="hyphen" dependency="SCHO+SCIT|SSTA"> - </span>
                                         <span class="hyphen" dependency="SCHO+SCNT" style={{display: 'none'}}> - </span>
                                         <span class="joblocation jobcity" id="FIELD_SCIT"> New Delhi, India</span>
                                         <span dependency="SCIT+SSTA|SCNT" style={{display: 'none'}}>, </span>
                                         <span class="joblocation jobstate" id="FIELD_SSTA"></span>
                                         <span dependency="SSTA+SCNT" style={{display: 'none'}}>, </span>
                                         <span class="joblocation jobcountry" id="FIELD_SCNT"></span>
                                       </span>
                                     </span>
                                     <span class="paddedline" dependency="GRPA" style={{display: 'none'}}>
                                       <span dependency="GRPA" style={{display: 'none'}}>GPA <span class="beforecolonspace"></span>: </span>
                                       <span id="FIELD_GRPA"></span>
                                       <br dependency="GRPA" style={{display: 'none'}} />
                                     </span>
                                     <span id="FIELD_FRFM"></span>
                                   </div>
                                 </div>
                               </div>
                             </div>
                           </div>
                            ))}
                         </div>
                        
                       </div>
                       <div id="SECTION_LNGG36369f28-1717-c0d3-39a5-96f0b2c27702" class="section lang-sec infobarsec SECTION_LNGG    has-title  " data-section-cd="LNGG">
                         <div class=" doc-item">
                           <div class="heading">
                             <div class="sectiontitle" id="SECTIONNAME_LNGG">Languages <span title=" Languages " class="rename-section text-rename"></span>
                             </div>
                           </div>
                           {JSON.parse(more).language &&<div class="">
                           <ul>
                                          {JSON.parse(more)?.language?.map((item, index) => (
                                            <li key={item.language}>{item.language}</li>
                                          ))}
                                        </ul>
                             <div class="sortableInner">
                               <div id="PARAGRAPH_LNGG_20023c30-89ef-3a99-3f68-d7715a46b8f2" class="paragraph paragraph-fieldgroup PARAGRAPH_LNGG firstparagraph  nativeLangPara">
                                 <div class="clearfix doc-item">
                                   <div class="singlecolumn infobarpara">
                                     <div class="fieldgroup-0">
                                       <div class="field fielditem-frfm">
                                         <span id="FIELD_FRFM">Hindi</span>
                                         <span class="colon" dependency="FRFM">
                                           <span class="beforecolonspace"></span>: </span>
                                         <span class="flt-right" id="FIELD_RATG"></span>
                                       </div>
                                       <div class="rating-bar fielditem-ratv" dependency="RATV" style={{display: 'none'}}>
                                         <div class="inner-rating" id="FIELD_RATV" type="width"style={{width: '0%'}}></div>
                                       </div>
                                       <div class="field field-ratt fielditem-ratt">
                                         <span id="FIELD_RATT">Native speaker</span>
                                         <span class="colon" dependency="RATT">
                                           <span class="beforecolonspace"></span>: </span>
                                       </div>
                                       <div class="fielditem-olsh txt-bold" dependency="OLSH" style={{display: 'none'}}>
                                         <span id="FIELD_OLSH" dependency="OLSH" style={{display: 'none'}}></span>
                                       </div>
                                       <div class="field fielditem-adif">
                                         <span id="FIELD_ADIF"></span>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               <div id="PARAGRAPH_LNGG_756f3fd5-3415-d225-25f6-26433cdf5859" class="paragraph paragraph-fieldgroup PARAGRAPH_LNGG  para_odd">
                                 <div class="clearfix doc-item">
                                   <div class="singlecolumn infobarpara">
                                     <div class="fieldgroup-0">
                                       <div class="field fielditem-frfm">
                                         <span id="FIELD_FRFM">English</span>
                                         <span class="colon" dependency="FRFM">
                                           <span class="beforecolonspace"></span>: </span>
                                         <span class="flt-right" id="FIELD_RATG">C2</span>
                                       </div>
                                       <div class="rating-bar fielditem-ratv" dependency="RATV">
                                         <div class="inner-rating" id="FIELD_RATV" type="width" style={{width: '100%'}}></div>
                                       </div>
                                       <div class="field field-ratt fielditem-ratt">
                                         <span id="FIELD_RATT">Proficient</span>
                                         <span class="colon" dependency="RATT">
                                           <span class="beforecolonspace"></span>: </span>
                                       </div>
                                       <div class="fielditem-olsh txt-bold" dependency="OLSH" style={{display: 'none'}}>
                                         <span id="FIELD_OLSH" dependency="OLSH" style={{display: 'none'}}></span>
                                       </div>
                                       <div class="field fielditem-adif">
                                         <span id="FIELD_ADIF"></span>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               <div id="PARAGRAPH_LNGG_5cf93e39-188c-5232-51b9-f59f27dbcb4d" class="paragraph paragraph-fieldgroup PARAGRAPH_LNGG  para_even">
                                 {/* <div class="clearfix doc-item">
                                   <div class="singlecolumn infobarpara">
                                     <div class="fieldgroup-0">
                                       <div class="field fielditem-frfm">
                                         <span id="FIELD_FRFM">Bengali</span>
                                         <span class="colon" dependency="FRFM">
                                           <span class="beforecolonspace"></span>: </span>
                                         <span class="flt-right" id="FIELD_RATG">B2</span>
                                       </div>
                                       <div class="rating-bar fielditem-ratv" dependency="RATV">
                                         <div class="inner-rating" id="FIELD_RATV" type="width" style={{width:'66.6667%'}}></div>
           
                                       </div>
                                       <div class="field field-ratt fielditem-ratt">
                                         <span id="FIELD_RATT">Upper-intermediate</span>
                                         <span class="colon" dependency="RATT">
                                           <span class="beforecolonspace"></span>: </span>
                                       </div>
                                       <div class="fielditem-olsh txt-bold" dependency="OLSH" style={{display: 'none'}}>
                                         <span id="FIELD_OLSH" dependency="OLSH" style={{display: 'none'}}></span>
                                       </div>
                                       <div class="field fielditem-adif">
                                         <span id="FIELD_ADIF"></span>
                                       </div>
                                     </div>
                                   </div>
                                 </div> */}
                               </div>
                             </div>
                           </div>}
                         </div>
                       </div>
                     </div>
                   </div>
                   <div></div>
                 </div>
               </div>
               <div class="template-overlay">
                 <button id="previewTemplate" type="button" class="btn btn-tertiary" data-index="18">Preview Template</button>
                 <button id="btnSelectTemplate" type="button" class="btn btn-primary  ">Select Template</button>
               </div>
             </li>
             
          
                 
  ) : (
    <Loading />
  )}
</div>
</div>

);
});
export default Dynamictemp10;
