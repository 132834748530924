import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { saveAs } from 'file-saver'; // for downloading the file
import htmlDocx from 'html-docx-js/dist/html-docx';
import jsPDF from 'jspdf';


const Dynamictemp1 = forwardRef((props, ref) => {
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);

  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);

  let resume_id = localStorage.getItem('resume_id');
  const CommonData = localStorage.getItem('common_data');
  let pack = JSON.parse(CommonData)?.plan.pack;
  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }

    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);


    }).catch(err => {

    });
  }, []);



  const printDocument = (title, fontsize, spacing, color, resumename, justify) => {
    // const element = document.getElementById('pdfContainer');
    // const data = {
    //   htmlContent: htmlContent,
    //   resume_id: resume_id,
    //   fontsize: fontsize,
    //   resumename: resumename,
    //   spacing: spacing,
    //   color: color,
    //   justify: justify,
    //   template: 1
    // };
    // axios({
    //   method: "POST",
    //   url: commonConfig.REACT_APP_API_URL + "generate-pdf",
    //   data: data,
    //   headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    // }).then(res => {
    //   if (title == "save") {
    //     toast.success("Saved Successfully!!!");
    //   }

    // }).catch(err => {

    // });

    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(divRef.current, {
      callback: function (pdf) {
        pdf.save('download.pdf');
      },
      x: 20,          // Set the x offset for margin
      y: 20,          // Set the y offset for margin
      margin: 10,     // Apply a margin to all sides
      width: 550,     // Adjust content width based on A4 width and desired margins
      windowWidth: 900, // Control scaling based on browser window width
    });
  };

  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print,
    downloadWordDoc: downloadWordDoc,
  }));


  const downloadWordDoc = () => {
    // const content = `
    // `;

    // const blob = new Blob([content], {
    //   type: 'application/msword'
    // });

    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'resume.doc';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);

    const cssStyles = `
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:wght@300&family=Poppins:wght@200;300&family=Roboto:wght@400&display=swap');
        html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;vertical-align:baseline;background:transparent}
        body{line-height:1;text-align:left;font-feature-settings:"liga" 0;-moz-font-feature-settings:"liga" off}
         table{border-collapse:collapse;border-spacing:0;font-size:inherit;color:inherit;width:100%}

        /*START content disc style for LI*/
         ul, li{list-style:none;margin:0 0 0 8px;padding:0}
         ul li{position:relative;margin:0; margin-bottom:2px}
         ul li:before{position:absolute;left:-8px;top:0;font-family:'Nunito+Sans',sans-serif}
        /*END content disc style for LI*/

         .paddedline{display:block}
         .txt-bold{font-weight:700}
         strong{font-weight:bold} /*Added strong to fix PDF issue*/
         .flt-right{float:right}
         .word-break{word-wrap:break-word}
         .rna-view{display:none}

        /*Document*/
        {font-family:'Nunito+Sans',sans-serif;color:#000;line-height:16px;font-variant-ligatures:none;min-height:792px;font-weight:300}
         .parentContainer{display:table;table-layout:fixed;width:100%;border-collapse:collapse; position:relative;padding-top:45px}
         .parentContainer:before{content:'';background:var(--theme-color);position:absolute;width:100%;top:0;left:0}

         .section:last-child{border-bottom:none}
         .parentContainer .left-box > .section:last-child{margin-bottom:0!important}
         .section:empty{display:none}
         .firstparagraph{margin-top:0!important}

        /*Name and CNTC*/
         .name{font-size:36px;line-height:34px;font-weight:700;text-align:left;text-transform:uppercase;word-wrap:break-word}
         .name, .sectiontitle, .singlecolumn{word-wrap:break-word}
         .companyname{font-weight:700}
         .jobcity, .jobstate, .degree, .programline, .jobdates, .jobcountry{font-weight:500}
         .education .jobcity, .education .jobstate, .education .jobcountry{font-weight:300}
         .resumeTitleParent{position:relative}
         .resumeTitle{text-align:right;text-transform:uppercase;position:absolute;color:white;letter-spacing:.5px;font-weight:500;line-height:normal}
         .address .adrsDetails{word-wrap:break-word}
         .adnlLnks{margin-left:0!important}
         .address, .adnlLnks{word-wrap:break-word}
         .emailfld{word-wrap:break-word}

        /* Common style for left and right box */
         .left-box{box-sizing:content-box;padding:51px 20px 25px 0;display:table-cell;width:100%}
         .right-box{box-sizing:content-box;padding:60px 0 25px 20px;display:table-cell;vertical-align:top}
         .right-box .section[id^="SECTION_PICT"]{padding-top:0;padding-bottom:0!important;margin-bottom:25px;border:none;text-align:center;position:relative}
         .right-box .section:first-child{border:none;padding-bottom:0}
        /*  .left-box .section:first-child{margin-bottom:15px!important} */
         .right-box .section:first-child{margin-bottom:15px!important}
         .right-box .SECTION_CNTC{border-bottom:0;margin-bottom:0}

        /* PRFL */
        /*  .right-box .section:first-child:not(.SECTION_PICT), .right-box .SECTION_PICT:empty+.section{margin-top:203px!important} */

        /*heading*/
         .heading{margin-bottom:5px;font-weight:700;font-size:18px;overflow:hidden}
         .sectiontitle{text-transform:uppercase;padding-right:5px;letter-spacing:.5px;word-wrap:break-word}
         .sectiontitle:after{content:'';display:inline-block;width:100%;margin-right:-100%;margin-left:5px;background:#dfe6eb;opacity:.15}
         .disclaim .sectiontitle:after, .disclaim .sectiontitle:before{background:none}
         .jobline ul{margin-top:3px}

        /*New logic for infographic*/
         .lang-sec .singlecolumn, .skli-sec .singlecolumn, .fielditem-olsh{display:none}
         .lang-sec.infobarsec .infobarpara, .lang-sec.infotilesec .infotilepara, .skli-sec.infobarsec .infobarpara, .skli-sec.infotilesec .infotilepara, .nativeLangPara .fielditem-olsh{display:block}

        /*Infographic*/
         .lang-sec.infobarsec, .skli-sec.infobarsec{font-size:0}
         .lang-sec.infobarsec .field *, .lang-sec.infobarsec .nativeLangPara .field, .skli-sec.infobarsec .field *, .skli-sec.infobarsec .nativeLangPara .field{display:inline}
         .lang-sec.infobarsec .paragraph, .skli-sec.infobarsec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
         .lang-sec.infobarsec .singlecolumn, .skli-sec.infobarsec .singlecolumn{margin-left:0!important;padding-left:0;position:relative}
         .left-box .lang-sec.infobarsec .para_odd, .left-box .skli-sec.infobarsec .para_odd{margin-right:15px}
         .lang-sec.infobarsec > .paragraph:nth-last-child(1), .left-box .lang-sec.infobarsec > .paragraph:nth-last-child(2), .skli-sec.infobarsec > .paragraph:nth-last-child(1), .left-box .skli-sec.infobarsec > .paragraph:nth-last-child(2){padding-bottom:0!important}
         .lang-sec.infobarsec .nativeLangPara{width:100%!important}
         .left-box .lang-sec.infobarsec .inner-rating, .left-box .skli-sec.infobarsec .inner-rating{position:relative}
         .lang-sec.infobarsec .rating-bar, .skli-sec.infobarsec .rating-bar{background:#d5d6d6;width:100%;clear:both;margin-top:3px;position:relative;page-break-inside:avoid}
         .lang-sec.infobarsec .inner-rating, .skli-sec.infobarsec .inner-rating{background-color:var(--theme-color);height:4px;position:relative;width:60%}
         .lang-sec.infobarsec .paragraph:before, .skli-sec.infobarsec .paragraph:before{display:none}
         .right-box .lang-sec.infobarsec .rating-bar:before, .right-box .skli-sec.infobarsec .rating-bar:before{content:'';position:absolute;height:4px;width:100%;left:0;top:0;background:#d5d6d6}
         .right-box .lang-sec.infobarsec .paragraph, .right-box .skli-sec.infobarsec .paragraph{display:block;margin-right:0!important;width:100%!important;max-width:100%!important}
         .lang-sec.infobarsec .paragraph:nth-last-child(1),  .lang-sec.infobarsec .paragraph:nth-last-child(2),  .skli-sec.infobarsec .paragraph:nth-last-child(1),  .skli-sec.infobarsec .paragraph:nth-last-child(2){padding-bottom:0}
        /*Infographic Tiles*/
         .lang-sec.infotilesec, .skli-sec{font-size:0}
         .lang-sec.infotilesec .paragraph, .skli-sec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
         .lang-sec.infotilesec > .paragraph:nth-last-child(1), .left-box .lang-sec.infotilesec > .paragraph:nth-last-child(2){padding-bottom:0!important}
         .lang-sec.infotilesec .field *, .skli-sec > .paragraph:nth-last-child(1), .left-box .skli-sec > .paragraph:nth-last-child(2){padding-bottom:0!important}
         .lang-sec.infotilesec .field *, .skli-sec .field *, .lang-sec.infotilesec .nativeLangPara .field{display:inline}
         .lang-sec.infotilesec .singlecolumn, .skli-sec .singlecolumn{margin-left:0!important;padding-left:0;position:relative}
         .lang-sec.infotilesec .sliced-rect, .skli-sec .sliced-rect{height:6px;width:100%;line-height:0;margin-top:3px;clear:both}
         .left-box .lang-sec.infotilesec .paragraph.para_odd, .left-box .skli-sec .paragraph.para_odd{margin-right:15px}
         .right-box .lang-sec.infotilesec .paragraph, .right-box .skli-sec .paragraph{width:100%!important;max-width:100%!important;margin-top:0}
         .lang-sec.infotilesec .sliced-rect .sliced-rect-tile, .skli-sec .sliced-rect .sliced-rect-tile{height:100%;background-color:#d5d6d6;float:left;margin-right:3px}
         .lang-sec.infotilesec .sliced-rect .sliced-rect-tile:last-child, .skli-sec .sliced-rect .sliced-rect-tile:last-child{margin-right:0}

        /*Infographic small box*/
         .right-box .ratingBar:before{content:'';position:absolute;height:3px;width:100%;left:0;top:0}
         .right-box .lang-sec .paragraph, .right-box .skli-sec .paragraph{display:block;margin-right:0!important;padding-bottom:0px}
         .lang-sec > .paragraph:nth-last-child(1), .left-box .lang-sec > .paragraph:nth-last-child(2), .skli-sec > .paragraph:nth-last-child(1), .left-box .skli-sec > .paragraph:nth-last-child(2){margin-bottom:0!important}
         .hide-bar .rating-bar, .hide-only-bar .rating-bar, .hide-bar .field-ratt, .hide-bar .sliced-rect, .hide-colon .colon{display:none!important}


     .right-box .skli-sec .paragraph:last-child .singlecolumn  .field:last-child{min-height:0}
     .left-box .skli-sec .paragraph:nth-last-child(1) .singlecolumn .field:last-child, .left-box .skli-sec .paragraph:nth-last-child(2) .singlecolumn .field:last-child{min-height:0}

         .right-box .lang-sec .sortable-item .paragraph,  .left-box .lang-sec .sortable-item .paragraph,  .left-box .lang-sec .sortable-item .paragraph{padding-bottom:5px}
         .right-box .lang-sec .sortable-item:last-child .paragraph,  .left-box .lang-sec .sortable-item:nth-last-child(1) .paragraph,  .left-box .lang-sec .sortable-item:nth-last-child(2) .paragraph{padding-bottom:0}

        /* Additional Link */
         .SECTION_ALNK .paragraph{margin-top:0!important}
         .adnlLnks ul, .adnlLnks li{padding:0;margin:0}
         .adnlLnks li:last-child{padding:0}
         .adnlLnks li:before{content:''}

        /* Style for Signature */
         .disclaim .singlecolumn, .signPic > .field_sign{margin-left:0!important}
         .disclaim .singlecolumn, .disclaim .singlecolumn li, .disclaim .singlecolumn p, .disclaim .singlecolumn span{font-size:9px;color:#8a8a8a}
         .sign .field_sign{font-size:7px;color:#8a8a8a}
         .txtleft + .field_sign{text-align:left}
         .txtcenter + .field_sign{text-align:center}
         .txtright + .field_sign{text-align:right}
         .signPic span:first-child{padding-right:6px}
         .signPic img{max-width:100%}
         .lastcontainer{padding-bottom:30px}
         .lastcontainer .section{border:none}
     .sign.section{padding-bottom:0;margin-bottom:0;padding-top:25px}

        /*Button style*/
         .section.btnlnk{height:19px;padding-top:0!important;padding-bottom:0px;margin-bottom:25px!important;font-size:0;line-height:0;display:table;width:100%}
         .btnlnk a{display:inline-block;width:100%;height:100%;text-decoration:none}
        :not(.show-btn) .btnlnk{display:none}
        .show-btn .btnlnk .paragraph{margin-right:5px;margin-bottom:6px;margin-top:0;height:17px;display:inline-block;border:1px solid #34393D;border-radius:2px;-webkit-border-radius:2px;-moz-border-radius:2px;-ms-border-radius:2px}
        .show-btn .btnlnk .paragraph:last-child{margin-right:0}
         .btnlnk + div{border:none}
        .show-btn .section.name-sec{border:none;margin-bottom:0!important}
        .show-btn-gap .section.name-sec{padding-bottom:30px}

        /*Button SVG*/
         .btnlnk svg{width:100%;height:inherit}
         .btnlnk svg text{text-anchor:middle;font-weight:700;line-height:13px}

        /*For show/hide HPHN field based on portal*/
         .rnaphonefield{display:none}
         .nonrnaphonefield{display:block}
        .RNA .rnaphonefield{display:block}
        .RNA .nonrnaphonefield{display:none}
    .CLN .rnaphonefield{display:block}
        .CLN .nonrnaphonefield{display:none}

        /*MFR address*/
         .zipsuffix,.MFR .zipprefix,.MES .zipprefix{display:inline}
         .zipprefix,.MFR .zipsuffix,.MES .zipsuffix{display:none}

         .displayNoneThisField{display:none}/* Keep this class always at bottom */

        /*For Extra Space Before Colon*/
         .beforecolonspace{display:none!important}
        .MFR .beforecolonspace{display:inline!important}
   
        , table{line-height:15px}
        .pagesize{width:525px}
        .fontsize, .lang-sec.infobarsec .paragraph *, .lang-sec.infotilesec .paragraph *, .skli-sec .paragraph *{font-size:var(--font-size);line-height:var(--line-height);}
        .fontface, .lang-sec .paragraph, .skli-sec .paragraph{font-family:'Nunito+Sans',sans-serif;}
        .hmargins{padding-left:35px;padding-right:35px}
        /*  .section{margin-bottom:25px} */
         .disclaim{margin:0;padding:0;padding-top:50px}
         .sectiontitle{font-size:14px;line-height:16px}
         .paragraph{margin-top:10px}
         .left-box .singlecolumn, .left-box .maincolumn{margin-left:0px}
         table.skills td{padding-top:5px}
         .name{font-size:24px;line-height:33px}
         .address{font-size:var(--font-size);line-height:var(--line-height);}
         .address2{font-size:10px;line-height:15px}
         .right-box, .right-box .firstparagraph{width:175px;padding-right: 20px;}
         ul li{padding-left:0px}
         .sectiontitle:after{height:10px}
         .parentContainer:before, .sectiontitle:after{background-color:var(--theme-color)}
         .SECTION_CNTC + .section:not(.SECTION_ALNK){padding-top:25px;border-top:1px solid #000}
         .prflPic, .prflPic .field{height:129px;width:129px}
         .prflPic img{min-height:129px;min-width:129px;max-height:129px;max-width:129px !important;object-fit: contain;}
        .show-btn .btnlnk .paragraph{width:105px;height:17px}
        .skli-sec .singlecolumn .field:last-child{min-height:13px}


         .parentContainer:before{height:35px}
         .left-box{padding-top:51px}
         .right-box{padding-top:60px}
         .resumeTitle{top:-34px;width:calc(100% + 30px + 129px)}

        /*Infographic Bar*/
         .lang-sec.infobarsec .inner-rating, .skli-sec.infobarsec .inner-rating{background-color:var(--theme-color)}
         .left-box .lang-sec.infobarsec{padding-left:0px}
         .left-box .lang-sec.infobarsec .heading{margin-left:-0px}
         .lang-sec.infobarsec .paragraph{width:313px;max-width:147px}
         .lang-sec.infobarsec .nativeLangPara{width:313px !important;max-width:313px}

        /*Infographic Tile*/
         .left-box .lang-sec.infotilesec, .left-box .skli-sec{padding-left:0px}
         .left-box .lang-sec.infotilesec .heading, .left-box .skli-sec .heading{margin-left:-0px}
         .lang-sec.infotilesec .paragraph, .left-box .skli-sec .paragraph{width:147px}
         .right-box .lang-sec.infotilesec .paragraph, .right-box .skli-sec .paragraph{padding-bottom:5px}
         .lang-sec.infotilesec .nativeLangPara{width:313px!important;max-width:313px!important}

        /*Rectangular Rating Blocks*/
         .paragraph .sliced-rect .sliced-rect-tile.ratvfill{background-color:var(--theme-color)}

        /*Infographic Languages Ordering Support*/
         .lang-sec.infobarsec .colon, .lang-sec.infotilesec .colon{display:none}
         .lang-sec.infobarsec .field:first-child .colon, .lang-sec.infotilesec .field:first-child .colon{display:inline;font-weight:700}
         .lang-sec.infobarsec .field:first-child > span:first-child,   .lang-sec.infotilesec .field:first-child > span:first-child{font-weight:700}

        /*Fixes for Builder*/
         .right-box .data-CNTC + .sortable-item:not(.data-ALNK){padding-top:25px;border-top:1px solid #000}
         .right-box .data-CNTC .section{border:none}
        /*  .sortable-item:last-child .section{border-bottom:none!important} */
        /*  .left-box .sortable-item:first-child .section{margin-bottom:5px!important} */
        /*  .sortable-item .section{border-bottom:1px solid #000} */
     .right-box .sortable-item{width:100%}
         .sortable-item .section.btnlnk{height:auto}
        .show-btn-gap .section.name-sec{padding-bottom:31px}
        .show-btn .left-box .sortable-item:first-child .section{margin-bottom:0px!important}
         .left-box .data-LNGG .lang-sec .sortable-item{vertical-align:top;display:inline-block}
        .page-final .LES .left-box .data-LNGG .lang-sec .sortable-item .paragraph{width:154px;max-width:154px}
        .LES .active-drag .lang-sec .sortable-item{display:inline-block}
         .title-edit{margin-left:-0px}

    /*Fixes for builder for skill*/
         .skli-sec .sortable-item .paragraph:last-child .singlecolumn .field:last-child{min-height:13px}
         .right-box .skli-sec .sortable-item:last-child .paragraph .singlecolumn .field:last-child, .left-box .skli-sec .sortable-item:nth-last-child(1) .paragraph .singlecolumn .field:last-child, .left-box .skli-sec .sortable-item:nth-last-child(2) .paragraph .singlecolumn .field:last-child{min-height:0}

         .fielditem-olsh{padding-top:5px;margin-bottom:-3px}

        /* RNA Fixes */
        .RNA .rna-view{display:block}
        .RNA .right-box .data-CNTC .section,.RNA .right-box .SECTION_CNTC{margin-bottom:25px;border-bottom:1px solid #000}
        .RNA .right-box .data-CNTC + .sortable-item:not(.data-ALNK),.RNA .SECTION_CNTC + .section:not(.SECTION_ALNK){padding-top:0px;border-top:none}
        .RNA.show-btn .sortable-item .SECTION_BUTN {display:inherit}
        .RNA .left-box .skli-sec .sortableInner .sortable-item .paragraph,.RNA .left-box .data-LNGG .sortableInner .sortable-item + .sortable-item .paragraph{max-width:154px;vertical-align:top}
        .RNA .left-box .data-LNGG .sortableInner .sortable-item{display:inline-block;vertical-align:top}
        .RNA .left-box .data-LNGG .nativeLangPara{width:100%!important;max-width:100%!important;}
        .RNA .left-box .data-LNGG .para_odd{margin-right:15px}
        .RNA .left-box .data-LNGG .nativeLangPara .singlecolumn{width:313px!important;max-width:313px!important}
        .RNA .left-box .lang-sec .emptyBarDiv + .emptyWidthDiv,.RNA .left-box .skli-sec .paragraph{width:154px;max-width:154px}
        .RNA .left-box .data-LNGG .firstparagraph.para_odd{max-width:154px!important}
    .resume-preview .RNA .left-box .data-LNGG .sortableInner .sortable-item + .sortable-item .paragraph{width:142px!important;max-width:142px!important}
    .watermark {
      position: fixed;
      top: 50%; /* Adjust as needed */
      left: 50%; /* Adjust as needed */
      transform: translate(-50%, -50%);
      font-size: 44px; /* Adjust font size */
      color: rgba(0, 0, 0, 0.2); /* Adjust color and opacity */
      z-index: -1; /* Place it behind other content */
  }
  .sectiontitle {
    width: 1px; /* Thickness */
    background-color: #8d9ec4; /* Color */
    height: 100%; /* Full height */
    position: relative; /* Positioning context */
    margin: 0 auto; /* Centering */
}

   table {
            width: 100%;
            table-layout: fixed;
           
          }
          td {
            vertical-align: top;
            padding: 10px;
            
          }
         .name .mb175{
           vertical-align: top;
         } 
          .sectiontitle:after {
            background-color:sky blue;
          }
            .joshua{
              color:red;
            }
      </style>
    `;

    const contentElement = document.getElementById('downloadToWord').innerHTML;
    const contentWithStyles = cssStyles + contentElement;
    const docx = htmlDocx.asBlob(contentWithStyles, { orientation: 'portrait' });
    saveAs(docx, 'resume1.docx');
  };

  return (
    <div>
      <ToastContainer position="top-center" theme="dark" />
      <div id="pdfContainer" ref={divRef} onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '10px' }}>
        {respokData ? (

          <li className="template-preview template-1 exp" style={{ listStyle: 'none' }} data-skinid="MLK5" data-skincd="MLK5">
            <div className="render-preview">
              {pack == 'Free' && <div class="watermark">GOjobs AI</div>}
              <div className="" tabindex="">

                <div className="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlk1 MLK5  LUK  " docskinwidth="525">
                  <div id="CONTAINER_PARENT_0" className="parentContainer">
                    <div id="CONTAINER_1" className="right-box last-box">
                      <div
                        data-react-beautiful-dnd-draggable="2"
                        className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                      >
                        <div id="SECTION_PICT4214b310-3411-4e1d-862f-bc0494836200" className="section notdraggable SECTION_PICT firstsection   " data-section-cd="PICT">
                          <div className=" doc-item">
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_PICT_e1ebf3dc-8c81-4b3f-a42e-d54881864bc9" className="paragraph PARAGRAPH_PICT firstparagraph placeholder-text ">
                                  {image &&
                                    <div className="clearfix doc-item">
                                      <div className="prflPic">
                                        <div className="field" id="FIELD_PURL">
                                          <img className="chk" style={{ objectFit: 'cover' }} src={image} alt="Smiley face" /> </div>

                                      </div>
                                      <div className="clear"></div>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-react-beautiful-dnd-draggable="2"
                        className={`sortable-item section-container SortableItem-sibling data-SUMM `}

                      >
                        {/* <div className="document-tool sec-tool" id="editIcons" style={{ right: "-37px" }}>

                        </div> */}
                        <div id="SECTION_CNTCd5629f30-a014-4b89-bb0a-cff09fe46a11" className="section SECTION_CNTC notdraggable    has-title " data-section-cd="CNTC">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_CNTC">Contact<span title=" Contact " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_CNTC_35246e0e-286e-47c8-a5f7-8f73ddb714ef" className="paragraph PARAGRAPH_CNTC word-break firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    <div className="address word-break">
                                      {contact.address_1 && <div className="paddedline adrsDetails mb75" dependency="ADDR|STRT|NBHD|CITY|STAT|ZIPC">
                                        <span className="txt-bold">
                                          <span className="xslt_static_change" id="DOCDATAINFO_ADDR">Address</span>
                                          <span>
                                            <span className="beforecolonspace"> </span>
                                            <span>: </span>
                                          </span>
                                        </span>
                                        <br />
                                        <span id="FIELD_ADDR">{contact.address_1}</span>
                                        {contact.address_2 &&<span id="FIELD_ADDR">{contact.address_2}</span>}
                                      </div>}
                                      {contact.city && <div className="paddedline adrsDetails mb75" dependency="ADDR|STRT|NBHD|CITY|STAT|ZIPC">
                                        <span className="txt-bold">
                                          <span className="xslt_static_change" id="DOCDATAINFO_ADDR">Location</span>
                                          <span>
                                            <span className="beforecolonspace"> </span>
                                            <span>: </span>
                                          </span>
                                        </span>
                                        <br />
                                        <span id="FIELD_ADDR">{contact.city}</span>
                                      </div>}
                                      <span className="paddedline nonrnaphonefield mb75" dependency="HPHN|CPHN">
                                        <span className="txt-bold">
                                          <span className="xslt_static_change" id="DOCDATAINFO_HPHN">Phone</span>
                                          <span>
                                            <span className="beforecolonspace"> </span>
                                            <span>: </span>
                                          </span>
                                        </span><br />
                                        <span id="FIELD_HPHN"></span>
                                        <span id="FIELD_CPHN">{contact.mobile}</span>
                                      </span>
                                      <span className="paddedline emailfld mb175" dependency="EMAI">
                                        <span className="txt-bold">
                                          <span className="xslt_static_change" id="DOCDATAINFO_EMAI">Email</span>
                                          <span>
                                            <span className="beforecolonspace"> </span>
                                            <span>: </span>
                                          </span>
                                        </span><br />
                                        <span id="FIELD_EMAI">{contact.email}</span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        data-react-beautiful-dnd-draggable="2"
                        className={`sortable-item section-container SortableItem-sibling data-SUMM `}

                      >
                        {/* <div className="document-tool sec-tool" id="editIcons" style={{ right: "-37px" }}>

                        </div> */}
                        <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div>
                            </div>
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                  <div className="clearfix doc-item">
                                    {skills[0] && <div className="singlecolumn maincolumn">
                                      <span className="paddedline" id="FIELD_SKC1">
                                        <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                      </span>
                                    </div>}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                    <div id="CONTAINER_0" className="left-box last-box">
                      <div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-NAME">

                        <div id="SECTION_NAME25a3bf91-f80d-4376-95be-f3561350d955" className="section notdraggable name-sec SECTION_NAME    " data-section-cd="NAME">
                          <div className=" doc-item">
                            <div className="">
                              <div className="">
                                <div id="PARAGRAPH_NAME_5d630705-f750-47a3-a5ba-01626b4c4bd2" className="paragraph PARAGRAPH_NAME firstparagraph  ">
                                  <div>

                                    <div className="name mb175 temp_bb">
                                      <span id="FIELD_FNAM">{contact.f_name}</span>&nbsp;
                                      <span id="FIELD_LNAM">{contact.l_name}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div
                          data-react-beautiful-dnd-draggable="2"
                          className={`sortable-item mb175  section-container SortableItem-sibling data-SUMM`}
                        >
                          <div id="SECTION_SUMMc1414a8c-6865-4672-936c-c053bc81b85c" className="section SECTION_SUMM has-title" data-section-cd="SUMM">
                            <div className="doc-item">
                              <div className="heading">
                                <div className="sectiontitle" id="SECTIONNAME_SUMM">
                                  Summary
                                  <span title=" Summary " className="rename-section text-rename"></span>
                                </div>
                              </div>
                              <div className="">
                                <div className="">
                                  <div id="PARAGRAPH_SUMM_962095c5-2dd9-6dd1-7221-e575e11d0b5b" className="paragraph PARAGRAPH_SUMM firstparagraph">
                                    <div className="clearfix doc-item">
                                      <div className="singlecolumn" id="FIELD_FRFM">
                                        <div>
                                          <span>{contact.summery}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`sortable-item section-container mb175 SortableItem-sibling data-SUMM`}>
                        <div className="section experience SECTION_EXPR   multi-para has-title " data-section-cd="EXPR">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" style={{ marginBottom: '0rem' }} id="SECTIONNAME_EXPR">Experience<span title=" Experience " className="rename-section text-rename"></span></div>
                            </div>

                            {experience?.map((item, index) => (
                              <div className="">
                                <div className="">
                                  <div id="PARAGRAPH_EXPR_c7345e1f-d6d2-9173-0709-23e027c27d63" className="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                    <div className="clearfix doc-item">
                                      <div className="singlecolumn">
                                        <span className="paddedline" dependency="JTIT|JSTD|EDDT">
                                          <span className="jobtitle txt-bold" id="FIELD_JTIT">{item.title} {item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</span><span dependency="JTIT+JSTD|EDDT">, </span>
                                          <span className="jobdates " dependency="JSTD|EDDT">
                                            <span id="FIELD_JSTD jobtitle" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                            <span id="FIELD_EDDT jobtitle" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                          </span>
                                        </span>
                                        <span className="paddedline locationGap txt-bold" dependency="COMP|JSTA|JCIT|JCNT">
                                          <span className="companyname" id="FIELD_COMP">{item.employer}</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> - </span></span>
                                          <span className="jobcity" id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                          <span className="jobstate" id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                                        </span>
                                        <span className="jobline" id="FIELD_JDES">

                                          <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item.achievements != '[]' && item.achievements != '[null]' && (
                                  <div className="singlecolumn maincolumn mb25">
                                    <br />
                                    <ul>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>



                      <div className={`sortable-item mb175 section-container SortableItem-sibling data-SUMM $`}>
                        <div id="SECTION_EDUC4be5bc21-4afb-4500-b907-608fd306f119" className="section education SECTION_EDUC   multi-para has-title " data-section-cd="EDUC">
                          <div className=" doc-item">
                            <div className="heading">
                              <div className="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education " className="rename-section text-rename"></span></div>
                            </div>
                            {education?.map((item, index) => (
                              <div className="">
                                <div className="" dependency="SCIT|SSTA|SCHO|SCNT">
                                  <span className="" id="FIELD_SCHO">
                                    <p>
                                      <span>{item.course}</span>
                                      &nbsp;
                                      {item.specialization && <span className="">,
                                        &nbsp;{item.specialization} &nbsp;
                                      </span>}

                                      {item.university && <span className="">
                                        <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                      </span>}
                                      {item.year_of_completion && <span className="">
                                        <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                      </span>}
                                    </p>
                                  </span>
                                </div>
                                {item.achievements != '[]' && item.achievements != '[null]' && (
                                  <div className="singlecolumn maincolumn mb25">
                                    <br />
                                    <ul>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>


                      <div className={`sortable-item section-container SortableItem-sibling data-SUMM `}>

                        {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                          <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                            <div className=" doc-item">
                              <div className="heading">
                                <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                              </div>
                              <div className="">
                                <div className="">
                                  <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                    <div className="clearfix doc-item">

                                      {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null && <div className="singlecolumn maincolumn mb75">
                                        <h4>Languages:</h4>
                                        <span className="paddedline" id="FIELD_SKC1">
                                          <ul>
                                            {JSON.parse(more)?.language?.map((item, index) => (
                                              item.language && <li key={item.language}>{item.language}</li>
                                            ))}
                                          </ul>
                                        </span>
                                      </div>}

                                      {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                        <h4>Website & Profiles:</h4>
                                        <span className="paddedline" id="FIELD_SKC1">
                                          <div>
                                            {JSON.parse(more)?.website?.map((item, index) => (
                                              item.work && <div key={item.work}><a href={item.work} style={{ color: "#000" }} target="_blank">{item.work}</a></div>
                                            ))}
                                            {JSON.parse(more)?.website?.map((item, index) => (
                                              item.portfolio && <div key={item.portfolio}><a href={item.portfolio} style={{ color: "#000" }} target="_blank">{item.portfolio}</a></div>
                                            ))}
                                            {JSON.parse(more)?.website?.map((item, index) => (
                                              item.profile && <div key={item.profile}><a href={item.profile} style={{ color: "#000" }} target="_blank">{item.profile}</a></div>
                                            ))}
                                          </div>
                                        </span>
                                      </div>}

                                      {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                        {JSON.parse(more)?.custom?.map((item, index) => (
                                          <div className="singlecolumn maincolumn">
                                            <h4>{item.custom_name}</h4>
                                            <span className="paddedline" id="FIELD_SKC1">
                                              <div>
                                                {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                              </div>
                                            </span>
                                          </div>
                                        ))}
                                      </div>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>

                  </div>
                  <div id="CONTAINER_PARENT_1">
                    <div id="CONTAINER_2" className=""></div>
                  </div>
                </div>
                <div></div>
              </div>
            </div>

          </li>
        ) : (
          <Loading />
        )}
      </div>


      <div id="downloadToWord" onInput={(e) => setHtmlContent(e.target.innerHTML)} style={{ padding: '10px', display: 'none' }}>
        {respokData ? (

          <table>
            <tr>
              <td>
                <div id="CONTAINER_1" className="right-box last-box">
                  <div
                    data-react-beautiful-dnd-draggable="2"
                    className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                  >
                    <div id="SECTION_PICT4214b310-3411-4e1d-862f-bc0494836200" className="section notdraggable SECTION_PICT firstsection   " data-section-cd="PICT">
                      <div className=" doc-item">
                        <div className="">
                          <div className="">
                            <div id="PARAGRAPH_PICT_e1ebf3dc-8c81-4b3f-a42e-d54881864bc9" className="paragraph PARAGRAPH_PICT firstparagraph placeholder-text ">
                              {image &&
                                <div className="clearfix doc-item">
                                  <div className="prflPic">
                                    <div className="field" id="FIELD_PURL">
                                      <img className="chk" width={'129'} height={'129'} src={image} alt="Smiley face" /> </div>
                                  </div>
                                  <div className="clear"></div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-react-beautiful-dnd-draggable="2"
                    className={`sortable-item section-container SortableItem-sibling data-SUMM `}

                  >
                    {/* <div className="document-tool sec-tool" id="editIcons" style={{ right: "-37px" }}>

                        </div> */}
                    <div id="SECTION_CNTCd5629f30-a014-4b89-bb0a-cff09fe46a11" className="section SECTION_CNTC notdraggable    has-title " data-section-cd="CNTC">
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle" id="SECTIONNAME_CNTC">Contact<span title=" Contact " className="rename-section text-rename"></span></div>
                        </div>
                        <div className="">
                          <div className="">
                            <div id="PARAGRAPH_CNTC_35246e0e-286e-47c8-a5f7-8f73ddb714ef" className="paragraph PARAGRAPH_CNTC word-break firstparagraph  ">
                              <div className="clearfix doc-item">
                                <div className="address word-break">
                                  {contact.city && <div className="paddedline adrsDetails mb75" dependency="ADDR|STRT|NBHD|CITY|STAT|ZIPC">
                                    <span className="txt-bold">
                                      <span className="xslt_static_change" id="DOCDATAINFO_ADDR">Location</span>
                                      <span>
                                        <span className="beforecolonspace"> </span>
                                        <span>: </span>
                                      </span>
                                    </span>
                                    <br />
                                    <span id="FIELD_ADDR">{contact.city}</span>
                                  </div>}
                                  <span className="paddedline nonrnaphonefield mb75" dependency="HPHN|CPHN">
                                    <span className="txt-bold">
                                      <span className="xslt_static_change" id="DOCDATAINFO_HPHN">Phone</span>
                                      <span>
                                        <span className="beforecolonspace"> </span>
                                        <span>: </span>
                                      </span>
                                    </span><br />
                                    <span id="FIELD_HPHN"></span>
                                    <span id="FIELD_CPHN">{contact.mobile}</span>
                                  </span>
                                  <span className="paddedline emailfld mb175" dependency="EMAI">
                                    <span className="txt-bold">
                                      <span className="xslt_static_change" id="DOCDATAINFO_EMAI">Email</span>
                                      <span>
                                        <span className="beforecolonspace"> </span>
                                        <span>: </span>
                                      </span>
                                    </span><br />
                                    <span id="FIELD_EMAI">{contact.email}</span>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    data-react-beautiful-dnd-draggable="2"
                    className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                  >
                    {/* <div className="document-tool sec-tool" id="editIcons" style={{ right: "-37px" }}>

                        </div> */}
                    <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " className="rename-section text-rename"></span></div>
                        </div>
                        <div className="">
                          <div className="">
                            <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                              <div className="clearfix doc-item">
                                {skills[0] && <div className="singlecolumn maincolumn">
                                  <span className="paddedline" id="FIELD_SKC1">
                                    <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                  </span>
                                </div>}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                </div>
              </td>
              <td>
                <div id="CONTAINER_0" className="left-box last-box">
                  <div data-react-beautiful-dnd-draggable="2" className="sortable-item section-container SortableItem-sibling  data-NAME">

                    <div id="SECTION_NAME25a3bf91-f80d-4376-95be-f3561350d955" className="section notdraggable name-sec SECTION_NAME    " data-section-cd="NAME">
                      <div className=" doc-item">
                        <div className="">
                          <div className="">
                            <div id="PARAGRAPH_NAME_5d630705-f750-47a3-a5ba-01626b4c4bd2" className="paragraph PARAGRAPH_NAME firstparagraph  ">
                              <div>

                                <div className="name mb175 temp_bb">
                                  <span id="FIELD_FNAM">{contact.f_name}</span>&nbsp;
                                  <span id="FIELD_LNAM">{contact.l_name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      data-react-beautiful-dnd-draggable="2"
                      className={`sortable-item mb175  section-container SortableItem-sibling data-SUMM`}
                    >
                      <div id="SECTION_SUMMc1414a8c-6865-4672-936c-c053bc81b85c" className="section SECTION_SUMM has-title" data-section-cd="SUMM">
                        <div className="doc-item">
                          <div className="heading">
                            <div className="sectiontitle" id="SECTIONNAME_SUMM">
                              Summary
                              <span title=" Summary " className="rename-section text-rename"></span>
                            </div>
                          </div>
                          <div className="">
                            <div className="">
                              <div id="PARAGRAPH_SUMM_962095c5-2dd9-6dd1-7221-e575e11d0b5b" className="paragraph PARAGRAPH_SUMM firstparagraph">
                                <div className="clearfix doc-item">
                                  <div className="singlecolumn" id="FIELD_FRFM">
                                    <p>
                                      {contact.summery}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={`sortable-item section-container mb175 SortableItem-sibling data-SUMM`}>
                    <div className="section experience SECTION_EXPR   multi-para has-title " data-section-cd="EXPR">
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle" style={{ marginBottom: '0rem' }} id="SECTIONNAME_EXPR">Experience<span title=" Experience " className="rename-section text-rename"></span></div>
                        </div>

                        {experience?.map((item, index) => (
                          <div className="">
                            <div className="">
                              <div id="PARAGRAPH_EXPR_c7345e1f-d6d2-9173-0709-23e027c27d63" className="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                <div className="clearfix doc-item">
                                  <div className="singlecolumn">
                                    <span className="paddedline" dependency="JTIT|JSTD|EDDT">
                                      <span className="jobtitle txt-bold" id="FIELD_JTIT">{item.title} {item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>}</span><span dependency="JTIT+JSTD|EDDT">, </span>
                                      <span className="jobdates " dependency="JSTD|EDDT">
                                        <span id="FIELD_JSTD jobtitle" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                        <span id="FIELD_EDDT jobtitle" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                      </span>
                                    </span>
                                    <span className="paddedline locationGap txt-bold" dependency="COMP|JSTA|JCIT|JCNT">
                                      <span className="companyname" id="FIELD_COMP">{item.employer}</span><span dependency="COMP"><span dependency="JCIT|JSTA|JCNT"> - </span></span>
                                      <span className="jobcity" id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                      <span className="jobstate" id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span><span className="joblocation jobcountry" id="FIELD_JCNT"></span>
                                    </span>
                                    <span className="jobline" id="FIELD_JDES">

                                      <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {item.achievements != '[]' && item.achievements != '[null]' && (
                              <div className="singlecolumn maincolumn mb25">
                                <br />
                                <ul>
                                  {JSON.parse(item.achievements)?.map((item1, index) => (
                                    item1 && <li key={index}>{item1}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>



                  <div className={`sortable-item mb175 section-container SortableItem-sibling data-SUMM $`}>
                    <div id="SECTION_EDUC4be5bc21-4afb-4500-b907-608fd306f119" className="section education SECTION_EDUC   multi-para has-title " data-section-cd="EDUC">
                      <div className=" doc-item">
                        <div className="heading">
                          <div className="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education " className="rename-section text-rename"></span></div>
                        </div>
                        {education?.map((item, index) => (
                          <div className="">
                            <div className="" dependency="SCIT|SSTA|SCHO|SCNT">
                              <span className="" id="FIELD_SCHO">
                                <p>
                                  {item.course}&nbsp;
                                  {item.specialization && <span className="">,
                                    &nbsp;{item.specialization} &nbsp;
                                  </span>}

                                  {item.university && <span className="">
                                    <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                  </span>}
                                  {item.year_of_completion && <span className="">
                                    <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                  </span>}
                                </p>
                              </span>
                            </div>
                            {item.achievements != '[]' && item.achievements != '[null]' && (
                              <div className="singlecolumn maincolumn mb25">
                                <br />
                                <ul>
                                  {JSON.parse(item.achievements)?.map((item1, index) => (
                                    item1 && <li key={index}>{item1}</li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>


                  <div className={`sortable-item section-container SortableItem-sibling data-SUMM `}>

                    {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                      <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                        <div className=" doc-item">
                          <div className="heading">
                            <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                          </div>
                          <div className="">
                            <div className="">
                              <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                <div className="clearfix doc-item">

                                  {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null && <div className="singlecolumn maincolumn mb75">
                                    <h4>Languages:</h4>
                                    <span className="paddedline" id="FIELD_SKC1">
                                      <ul>
                                        {JSON.parse(more)?.language?.map((item, index) => (
                                          item.language && <li key={item.language}>{item.language}</li>
                                        ))}
                                      </ul>
                                    </span>
                                  </div>}

                                  {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                    <h4>Website & Profiles:</h4>
                                    <span className="paddedline" id="FIELD_SKC1">
                                      <div>
                                        {JSON.parse(more)?.website?.map((item, index) => (
                                          item.work && <div key={item.work}><a href={item.work} style={{ color: "#000" }} target="_blank">{item.work}</a></div>
                                        ))}
                                        {JSON.parse(more)?.website?.map((item, index) => (
                                          item.portfolio && <div key={item.portfolio}><a href={item.portfolio} style={{ color: "#000" }} target="_blank">{item.portfolio}</a></div>
                                        ))}
                                        {JSON.parse(more)?.website?.map((item, index) => (
                                          item.profile && <div key={item.profile}><a href={item.profile} style={{ color: "#000" }} target="_blank">{item.profile}</a></div>
                                        ))}
                                      </div>
                                    </span>
                                  </div>}

                                  {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                    {JSON.parse(more)?.custom?.map((item, index) => (
                                      <div className="singlecolumn maincolumn">
                                        <h4>{item.custom_name}</h4>
                                        <span className="paddedline" id="FIELD_SKC1">
                                          <div>
                                            {item.ckeditor && <li dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                          </div>
                                        </span>
                                      </div>
                                    ))}
                                  </div>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </td>
            </tr>
          </table>
        ) : (
          <Loading />
        )}
      </div>
    </div >
  );
});
export default Dynamictemp1;
