import React, { useEffect } from 'react';
import google_button from '../img/google_button.png';
import link_button from '../img/link_button.png';
import './Css/Aboutus.scss';
import Footer from './Footer';
import MainNav from './MainNav';
import About from './Assets/aboutus.png';
function Aboutus() {
    return (
        <div className="about">
            <MainNav value="4"/>
            <div className='aboutus'>
                <div className='col-lg-12'>
                    <div className='col-lg-8'>
                        <h1 className='blue-aboutus'>About us</h1>
                        <p className='content-aboutus'>We understand how long the process of creating a resume can be and how it can be hard for you to make your experience stand out. That's why our team set out to create an intuitive and powerful AI/artificial intelligence-based system geared towards helping you build an amazing resume in no time.</p>
                        <p className='content-aboutus'> Our team has worked in the recruitment industry for many years and has accumulated over 100 years of cumulative experience. With this knowledge, we are well-versed in what employers look for in a candidate and have honed our skills to be the best possible recruiters. We've had the fortune of working across multiple industries at different levels, in various locations, and on different recruitment software and technologies - which gives us an unparalleled understanding of the recruitment system and how an applicant tracking system (ATS) searches and selects resumes.</p>
                        
                    </div>

                    <div className='col-lg-4'>
                        <img src={About} className=' about-us' alt='about-us' />
                    </div>






                </div>
                <div className='col-lg-12'>
                <p className='content-aboutus'>At GOjobs.ai, our mission is to empower you with the tools you need to build an amazing resume that stands out from the competition. We believe in working smarter, not harder - so let us do the hard work for you! Sign up now and get started on your journey to creating a resume that screams success.<br /></p>
                        <p className='content-aboutus'>Ready to get started? Sign up now and let the power of AI create an amazing resume for you! Apply now and join the thousands of happy users who have used GOjobs.ai to get their dream job. Let us help you take the first step towards achieving your career goals - sign up today!

                        </p>
                </div>


            </div>
            <Footer />
        </div>
    );
}
export default Aboutus;
