import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Link, useNavigate } from "react-router-dom";
import Nav from "./Nav"; import { commonConfig } from "../config.js";
import axios from "axios";
import link_button from "./Assets/plus.png";
import link_button2 from "./Assets/minus.png";
import Loading from './Loading';
import StepsFooter from './StepsFooter';
import { FaArrowCircleUp, FaArrowCircleDown } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faDownload, faCheck } from '@fortawesome/free-solid-svg-icons';
import Formtemplate from "./Formtemplate";

function Plans() {
  // const [tabsData, setTabsData] = useState([]);
  const [expData, setExpData] = useState([]);
  const [respokData, setRespokData] = useState(true);
  const [page, setPage] = useState(false);
  const [stepno, setStepno] = useState(2);
  const resume_id = localStorage.getItem('resume_id');
  const userId = localStorage.getItem('userId');
  const CommonData = localStorage.getItem('common_data');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [pactiveTab, setPActiveTab] = useState(1);


  useEffect(() => {
    if (!userId) {
      navigate('/login');
    }

    import("./Css/Step8.scss");

    if (resume_id) {
      axios({
        method: "POST",
        url: commonConfig.REACT_APP_API_URL + "finalize",
        data: { resume_id: resume_id },
        headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
      }).then(res => {
        var decodedData = res.data;
        const result = decodedData.data;
        setExpData(result.experience);
        // console.log(result.experience);

        const contact = result.contact[0];
        // if (contact.step == 100) {
        //   navigate('/step13');
        //   setStepno(contact.step);
        // } else {
        //   setStepno(contact.step + 1);
        // }


      }).catch(err => {

      });
    }



    setTimeout(() => {
      setPage(true);
    }, commonConfig.LOAD_TIME);
  }, []);


  const EditExp = (exp_id) => {
    navigate('/step2/' + exp_id);
  };

  const handleSubmit = () => {
    localStorage.setItem('choosedplan', activeTab);
    navigate('/cart');
  };



  return (
    <div className="step8page">
      {respokData ? (
        <main id="app" role="main">
          <section className="page-wrapper">
            <div
              id="parent"
              className="page-cntc sticky tips-popup-cntc page-split-footer page-cntc-pic joshua-tree big-preview section-break-page custom-section-page-break culture-en-GB"
            >
              {page && <Nav value={stepno} />}

              <div className="col-lg-12 system_view">
                <div className="col-lg-7">
                  <div className="tab-header">
                    <div className={`tab-item1 bold ${pactiveTab === 1 ? 'active' : ''}`} onClick={() => setPActiveTab(1)}>
                      Subscription
                    </div>
                    <div className={`tab-item2 bold ${pactiveTab === 2 ? 'active' : ''}`} onClick={() => setPActiveTab(2)}>
                      Regular
                    </div>
                  </div>
                  <div className="exp_section">
                    <h3 className="headtag ">
                      <center className="blue">Our Affordable Plans</center>
                    </h3>
                    {pactiveTab == 1 && <div className="center" style={{ marginTop: '7%' }}>
                      <div className={`planlist plan1 ${activeTab === 1 ? 'selected_plan' : ''}`} onClick={() => setActiveTab(1)}>
                        <div className={`plan_top blue_color p-10 text-center ${activeTab === 1 ? 'active' : ''}`}>
                          <div className="price f-20 bold"><span >&#8377;</span>299</div>
                          <div className="price f-17 bold">Basic Plan</div>
                        </div>
                        <div className="plan_bottom p-10">
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited Resume Creation</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited PDF Downloads</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Full Access for 14 Days</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Auto Renews at <span >&#8377;</span>449 per month after 14 Days, Cancel Anytime</p>
                        </div>
                      </div>
                      <div className={`planlist plan1 ${activeTab === 2 ? 'selected_plan' : ''}`} onClick={() => setActiveTab(2)}>
                        <div className={`plan_top blue_color p-10 text-center ${activeTab === 2 ? 'active' : ''}`}>
                          <div className="price"><span className="f-20 bold">&#8377;166.59</span> / month</div>
                          <div className="price f-17 bold">Full Pack</div>
                        </div>
                        <div className="plan_bottom p-10">
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited Resume Creation, Save a resume for every job</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited PDF Downloads</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Full Access for 365 Days</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Billed annually at <span >&#8377;</span>1999</p>
                        </div>
                      </div>
                    </div>}

                    {pactiveTab == 2 && <div className="center" style={{ marginTop: '7%' }}>
                      <div className={`planlist plan1 ${activeTab === 3 ? 'selected_plan' : ''}`} onClick={() => setActiveTab(3)}>
                        <div className={`plan_top blue_color p-10 text-center ${activeTab === 3 ? 'active' : ''}`}>
                          <div className="price f-20 bold"><span >&#8377;</span>499</div>
                          <div className="price f-17 bold">Plan - 3</div>
                        </div>
                        <div className="plan_bottom p-10">
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">2 Resume Creation</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">2 Cover Letter Creation</p>
                        </div>
                      </div>
                      <div className={`planlist plan1 ${activeTab === 4 ? 'selected_plan' : ''}`} onClick={() => setActiveTab(4)}>
                        <div className={`plan_top blue_color p-10 text-center ${activeTab === 4 ? 'active' : ''}`}>
                          <div className="price f-20 bold"><span >&#8377;</span>499</div>
                          <div className="price f-17 bold">Plan - 4</div>
                        </div>
                        <div className="plan_bottom p-10">
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Interview Preparation</p>
                        </div>
                      </div>
                      <div className={`planlist plan1 ${activeTab === 5 ? 'selected_plan' : ''}`} onClick={() => setActiveTab(5)}>
                        <div className={`plan_top blue_color p-10 text-center ${activeTab === 5 ? 'active' : ''}`}>
                          <div className="price f-20 bold"><span >&#8377;</span>999</div>
                          <div className="price f-17 bold">Plan - 3</div>
                        </div>
                        <div className="plan_bottom p-10">
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">2 Resume Creation</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">2 Cover Letter Creation</p>
                          <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Interview Preparation</p>
                        </div>
                      </div>
                    </div>}

                    
                    <div className="center">
                      <button style={{ marginTop: '7%' }} onClick={handleSubmit} className="btn-mangaii">Next</button>
                    </div>
                  </div>

                </div>
                <div className="col-lg-5">
                  <div className="api_right">
                    <Link to="/template/step2_1" className="clickable p-10">Change Template</Link>
                    <Formtemplate />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mobile_view">
                <div className="col-lg-7">
                  <div className="exp_section">
                    <div className="price_section">
                      <h3 className="headtag ">
                        <center className="blue">Our Affordable Plans</center>
                      </h3>
                      <div className="center" style={{ marginTop: '7%' }}>
                        <div className={`planlist plan1 ${activeTab === 1 ? 'selected_plan' : ''}`} onClick={() => setActiveTab(1)}>
                          <div className={`plan_top blue_color p-10 text-center ${activeTab === 1 ? 'active' : ''}`}>
                            <div className="price f-20 bold"><span >&#8377;</span>299</div>
                            <div className="price f-17 bold">Basic Plan</div>
                          </div>
                        </div>
                        <div className={`planlist plan1 ${activeTab === 2 ? 'selected_plan' : ''}`} onClick={() => setActiveTab(2)}>
                          <div className={`plan_top blue_color p-10 text-center ${activeTab === 2 ? 'active' : ''}`}>
                            <div className="price"><span className="f-20 bold">&#8377;166.59</span> / month</div>
                            <div className="price f-17 bold">Full Pack</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="price_desc_section">
                      <div className={`plan_bottom p-10 ${activeTab === 2 ? 'hide' : ''}`}>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited Resume Creation</p>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited PDF Downloads</p>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Full Access for 14 Days</p>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Auto Renews at <span >&#8377;</span>449 per month after 14 Days, Cancel Anytime</p>
                      </div>
                      <div className={`plan_bottom p-10 ${activeTab === 1 ? 'hide' : ''}`}>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited Resume Creation, Save a resume for every job</p>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Unlimited PDF Downloads</p>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Full Access for 365 Days</p>
                        <div className="iconperc"><span className="icon-circle"><FontAwesomeIcon className="tickicon" icon={faCheck} /></span></div><p className="textperc">Billed annually at <span >&#8377;</span>1999</p>
                      </div>
                    </div>
                    <div className="center" style={{ clear: 'both' }}>
                      <button style={{ marginTop: '7%' }} onClick={handleSubmit} className="btn-mangaii">Next</button>
                    </div>
                  </div>

                </div>
                <div className="col-lg-5 system_view">
                  <div className="api_right">
                    <Link to="/template/step2_1" className="clickable p-10">Change Template</Link>
                    <Formtemplate />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <StepsFooter />
        </main>

      ) : (
        <div>
          <div className="system_view">
            <Loading content="Do Not Refresh the page as we are working on your Profile!" />
          </div>
          <div className="mobile_view">
            <Loading />
          </div>
        </div>

      )}
    </div>
  );
}

export default Plans;