import React, { Component } from "react";
import "./Temp8.css";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";

function Temp8() {
	return (
        <div>
		<li class="template-preview selected" data-skinid="MLF1" data-skincd="MLF1" title=""><div class="selected-badge"><div class="fal fa-check icon-badge"></div></div><button type="button" class="btn btn-link preview-btn" aria-label="MLF1 skin preview  selected"></button><div class="render-preview"><div class="" tabindex=""><div></div><div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlf8 MLF1  RNA  " docskinwidth="612"><div id="CONTAINER_PARENT_0" class="topsection hidesecondpass"><div id="CONTAINER_0" class="left-box"><div data-react-beautiful-dnd-draggable="18" class="sortable-item section-container SortableItem-sibling  data-PICT"><div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div><div id="SECTION_PICTb1c221f4-09de-446a-953a-c82f52bb926d" class="section notdraggable SECTION_PICT firstsection    " data-section-cd="PICT"><div class=" doc-item"><div class=""><div class=""><div id="PARAGRAPH_PICT_5144ddba-4d43-4b1a-ab27-d541e8693428" class="paragraph PARAGRAPH_PICT firstparagraph  "><div class="clearfix doc-item">
                        <div class="PICTPic">
                            <div class="field" id="FIELD_PURL">
                                <img class="chk" src="/blobcontent/rsm/images/desktop/noImageFemale.jpg?undefined" alt="Smiley face" /> <a href="javascript:;" class="btn btn-primary btn-prfl-edit photo-added" title="Edit">Edit</a></div>
                        </div>
                    </div></div></div></div></div></div></div></div><div id="CONTAINER_1" class="right-box"><div data-react-beautiful-dnd-draggable="18" class="sortable-item section-container SortableItem-sibling name-contact "><div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div><div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section notdraggable SECTION_NAME     " data-section-cd="NAME"><div class=" doc-item"><div class=""><div class=""><div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  "><div>
                        <svg class="topshape" height="18px" width="18px" viewBox="0 0 18 18">
                            <polygon points="0,0 0,18 18,0"></polygon>
                        </svg>
                        <div class="name" dependency="FNAM|LNAM">
                            <span id="FIELD_FNAM">Diya</span>
                            <span id="FIELD_LNAM">Agarwal</span>
                        </div>
                        <div class="resumeTitle" dependency="DCTL" style={{display:'none'}}><span id="FIELD_DCTL"> </span></div>
                    </div></div></div></div></div></div><div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section SECTION_CNTC notdraggable     " data-section-cd="CNTC"><div class=" doc-item"><div class=""><div class=""><div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  "><div class="clearfix doc-item">
                        <div class="address">
                            <div class="address-inner">
                                <div class="iconRow" dependency="ADDR|STRT|CITY|STAT|ZIPC">
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10"><path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path><path fill="#fff" d="M5.004 5.287a1.143 1.143 0 0 1 0-2.284 1.143 1.143 0 0 1 0 2.284zm0-3.407A2.266 2.266 0 0 0 2.74 4.143c0 1.55 2.026 3.823 2.112 3.92.08.09.222.09.303 0 .086-.097 2.112-2.37 2.112-3.92A2.266 2.266 0 0 0 5.003 1.88z"></path></svg>
                                    </div>
                                    <div class="icoTxt zipsuffix">
                                        <span id="FIELD_STRT"></span><span dependency="STRT" style={{display:'none'}}><span dependency="NBHD|CITY|STAT" style={{display:'none'}}>,</span></span>
                                        <span id="FIELD_NBHD"></span><span dependency="NBHD" style={{display:'none'}}><span dependency="CITY|STAT" style={{display:'none'}}>, </span></span>
                                        <span id="FIELD_CITY"></span><span dependency="CITY+STAT" style={{display:'none'}}>,</span>
                                        <span id="FIELD_STAT"></span>
                                        <span id="FIELD_ZIPC"></span>
                                        <span id="FIELD_ADDR">New Delhi, India 110034</span>
                                    </div>
                                    
                                </div>
                                <div class="iconRow" dependency="REMW" style={{display:'none'}}>
                                    <div class="iconSvg">
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_REMW"></span>
                                    </div>
                                </div>   
                                <div class="iconRow rnaphonefield" dependency="CPHN">
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10"><path class="rect" fill="#576d7b" d="M4.58 5.382c.62.606 1.166.801 1.366.853l.746-.73c.063-.061.168-.061.241 0l1.418 1.387c.032.031.053.072.053.113 0 .041-.01.093-.042.123l-.83.812-.02.02-.022.01c-.21.165-.483.247-.82.247-.934 0-2.237-.678-3.308-1.725-1.524-1.47-2.185-3.246-1.524-4.047l.032-.031.83-.812c.063-.061.168-.061.241 0L4.36 2.99c.031.03.052.072.052.113a.163.163 0 0 1-.052.113l-.746.73c.063.174.325.8.966 1.437zM0 0v9.768h9.99V0z"></path></svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_CPHN">+91 11 5555 3345</span>
                                    </div>
                                </div>
                                
                                <div class="iconRow" dependency="EMAI">
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10"><path class="rect" fill="#576d7b" d="M8.044 2.968v4.058c0 .073-.06.136-.131.136H1.72a.136.136 0 0 1-.13-.136V2.968c0-.074.06-.137.13-.137h6.192c.07 0 .13.063.13.137zM9.614 0H0v9.973h.161c.02.01.05.01.07.01h9.162c.03 0 .05-.01.07-.01h.151z"></path><path class="rect" fill="#576d7b" d="M7.592 3.475a.087.087 0 0 0-.1 0L4.88 4.898c-.04.02-.09.02-.119 0L2.138 3.475c-.03-.02-.069-.01-.099 0-.03.021-.049.052-.049.083v.515c0 .042.02.073.05.093L4.76 5.723c.02.01.03.01.05.01.02 0 .03 0 .05-.01l2.72-1.557a.11.11 0 0 0 .05-.093v-.515c.01-.031-.01-.062-.04-.083z"></path></svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_EMAI">d.agarwal@sample.in</span>
                                    </div>
                                </div>                                                               
                                <div class="iconRow" dependency="AVAI" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10">
                                            <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                            <path fill="#fff" d="M8,3.6c0.1,0,0.2-0.1,0.2-0.2V2.9c0-0.4-0.3-0.7-0.7-0.7H6.8V1.5c0-0.1-0.1-0.2-0.2-0.2H6.1
                                            C6,1.3,5.9,1.4,5.9,1.5v0.7H4.1V1.5c0-0.1-0.1-0.2-0.2-0.2H3.3c-0.1,0-0.2,0.1-0.2,0.2v0.7H2.5c-0.4,0-0.7,0.3-0.7,0.7v0.5
                                            c0,0.1,0.1,0.2,0.2,0.2H8z M7.5,8.7c0.4,0,0.7-0.3,0.7-0.7V4.3c0-0.1-0.1-0.2-0.2-0.2H2c-0.1,0-0.2,0.1-0.2,0.2V8
                                            c0,0.4,0.3,0.7,0.7,0.7H7.5z"></path>
                                        </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_AVAI"></span>
                                    </div>
                                </div>
                                <div class="iconRow" dependency="WEB1" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10">
                                            <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                            <path fill="#fff" d="M4.6,6.2l0.3-0.3C5,5.8,5.1,5.7,5,5.5c0-0.1,0-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1
                                            c-0.4-0.4-0.4-1,0-1.4l1-1c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5L7,4.4C6.9,4.5,6.9,4.6,7,4.7C7,4.9,7.1,5.2,7.1,5.4
                                            c0,0.2,0.2,0.3,0.4,0.2L8,5C8.9,4.2,8.9,2.8,8,2C7.2,1.1,5.8,1.1,5,2l-1,1c0,0,0,0,0,0C3.2,3.8,3.1,5.2,4,6
                                            c0.1,0.1,0.2,0.2,0.3,0.2C4.4,6.3,4.5,6.3,4.6,6.2z M5,8l1-1l0,0C6.8,6.2,6.9,4.8,6,4C5.9,3.9,5.8,3.8,5.7,3.8
                                            c-0.1-0.1-0.2-0.1-0.3,0L5.1,4.1C5,4.2,4.9,4.3,5,4.5c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0.4,0.4,0.4,1,0,1.4l-1,1
                                            c-0.4,0.4-1.1,0.4-1.5,0c-0.4-0.4-0.4-1.1,0-1.5L3,5.6C3.1,5.5,3.1,5.4,3,5.3C3,5.1,2.9,4.8,2.9,4.6c0-0.2-0.2-0.3-0.4-0.2L2,5
                                            C1.1,5.8,1.1,7.2,2,8C2.8,8.9,4.2,8.9,5,8z"></path>
                                        </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_WEB1"></span>
                                    </div>
                                </div> 
                                <div dependency="SOCL" style={{display:'none'}}>
                                    
                                </div>
                            </div>
                            <div class="address-inner">
                                <div class="iconRow" dependency="DOB1" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10">
                                            <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                            <path fill="#fff" d="M2.4,5.2v2.3c0,0.2,0.2,0.4,0.4,0.4h1.9V5.2H2.4z"></path>
                                            <path fill="#fff" d="M7.6,3.7H6.4c0.1-0.1,0.2-0.1,0.2-0.2C7,3.2,7,2.7,6.6,2.4c-0.3-0.3-0.8-0.3-1.1,0
                                            C5.4,2.6,5,3.2,5,3.7h0C5,3.2,4.6,2.6,4.5,2.4c-0.3-0.3-0.8-0.3-1.1,0C3,2.7,3,3.2,3.4,3.5c0.1,0.1,0.1,0.1,0.2,0.2H2.4
                                            C2.2,3.7,2,3.9,2,4.1v0.6c0,0.1,0.1,0.2,0.2,0.2h2.4V4.1h0.8v0.8h2.4C7.9,4.8,8,4.7,8,4.6V4.1C8,3.9,7.8,3.7,7.6,3.7z M4.6,3.7
                                            C4.6,3.7,4.6,3.7,4.6,3.7c-0.3,0-0.8-0.3-1-0.4c-0.2-0.2-0.2-0.4,0-0.6c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1
                                            C4.4,2.9,4.7,3.6,4.6,3.7z M5.5,3.7C5.4,3.7,5.4,3.7,5.5,3.7c-0.2-0.1,0.1-0.8,0.3-1c0.2-0.2,0.4-0.2,0.6,0c0.2,0.2,0.2,0.4,0,0.6
                                            C6.2,3.4,5.7,3.7,5.5,3.7z"></path>
                                            <path fill="#fff" d="M5.4,5.2v2.6h1.9c0.2,0,0.4-0.2,0.4-0.4V5.2H5.4z"></path>
                                        </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_DOB1"></span>
                                    </div>
                                </div>
                                <div class="iconRow" dependency="IDNV|IDNT" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 15 15">
                                            <path class="rect" fill="#576d7b" d="M14.3,0C14.7,0,15,0.3,15,0.8v13.5c0,0.4-0.3,0.7-0.7,0.7H0.8C0.3,15,0,14.7,0,14.3V0.8C0,0.3,0.3,0,0.8,0H14.3z"></path>
                                            <rect x="5.4" y="11.3" fill="#fff" width="4.2" height="0.8"></rect>
                                            <path fill="#fff" d="M7.5,2.9c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7c2.1,0,3.7-1.7,3.7-3.7C11.2,4.5,9.6,2.9,7.5,2.9z
                                                    M10.4,6.2H9.2c0-0.9-0.2-1.7-0.5-2.2C9.6,4.4,10.2,5.2,10.4,6.2z M4.6,7h1.2c0,0.9,0.2,1.7,0.5,2.2C5.4,8.8,4.8,8,4.6,7z M5.8,6.2
                                                H4.6c0.1-1,0.8-1.8,1.6-2.2C6,4.5,5.8,5.3,5.8,6.2z M7.5,9.5c-0.3,0-0.8-1-0.9-2.5h1.8C8.3,8.5,7.8,9.5,7.5,9.5z M6.6,6.2
                                                c0.1-1.5,0.6-2.5,0.9-2.5c0.3,0,0.8,1,0.9,2.5H6.6z M8.7,9.2C9,8.6,9.2,7.8,9.2,7h1.2C10.2,8,9.6,8.8,8.7,9.2z"></path>
                                        </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span>
                                            <span>
                                                <span id="FIELD_IDNT"></span><span dependency="IDNT+IDNV" style={{display:'none'}}>: </span>
                                            </span>
                                            <span id="FIELD_IDNV"></span>
                                        </span>
                                    </div>
                                </div>
                                <div class="iconRow" dependency="NTLY" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10">
                                            <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                            <path fill="#fff" d="M6.3,3.6C6,2.4,5.6,1.5,5,1.5S4,2.4,3.7,3.6H6.3z M8.2,3.6C7.8,2.7,7,1.9,6,1.6
                                        c0.3,0.5,0.6,1.2,0.7,2H8.2z M3.3,3.6c0.1-0.8,0.4-1.5,0.7-2c-1,0.3-1.8,1-2.2,2H3.3z M6.3,5.9c0-0.3,0-0.6,0-0.9
                                        c0-0.3,0-0.6,0-0.9H3.7c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9H6.3z M8.4,5.9C8.5,5.6,8.5,5.3,8.5,5c0-0.3,0-0.6-0.1-0.9H6.8
                                        c0,0.3,0,0.6,0,0.9c0,0.3,0,0.6,0,0.9H8.4z M3.2,5.9c0-0.3,0-0.6,0-0.9c0-0.3,0-0.6,0-0.9H1.6C1.5,4.4,1.5,4.7,1.5,5
                                        c0,0.3,0,0.6,0.1,0.9H3.2z M5,8.5c0.6,0,1-0.9,1.3-2.2H3.7C4,7.6,4.4,8.5,5,8.5z M6,8.4c1-0.3,1.8-1,2.2-2H6.7
                                        C6.6,7.2,6.3,7.9,6,8.4z M4,8.4c-0.3-0.5-0.6-1.2-0.7-2H1.8C2.2,7.3,3,8.1,4,8.4z"></path>
                                        </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_NTLY"></span>
                                    </div>
                                </div>
                                <div class="iconRow" dependency="DRIV" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10">
                                            <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                            <path fill="#fff" d="M2.8,7.7c0.2,0,0.4-0.2,0.4-0.4V6.8h3.6v0.4c0,0.2,0.2,0.4,0.4,0.4h0.4c0.2,0,0.4-0.2,0.4-0.4
                                            V6.5c0.1-0.2,0.2-0.4,0.2-0.6V5.2C8.3,5,8.2,4.7,8,4.6h0.3c0.1,0,0.1-0.1,0.2-0.1l0.1-0.3c0-0.1-0.1-0.2-0.2-0.2H7.6L7.3,3.3
                                            c-0.2-0.6-0.8-1-1.4-1H4.1c-0.6,0-1.2,0.4-1.4,1L2.4,3.9H1.6C1.5,3.9,1.4,4,1.4,4.1l0.1,0.3c0,0.1,0.1,0.1,0.2,0.1H2
                                            C1.8,4.7,1.7,5,1.7,5.2v0.7c0,0.2,0.1,0.4,0.2,0.6v0.8c0,0.2,0.2,0.4,0.4,0.4H2.8z M6.8,4.3H3.2l0.3-0.7c0.1-0.3,0.3-0.4,0.6-0.4
                                            h1.8c0.3,0,0.5,0.2,0.6,0.4L6.8,4.3z M2.8,5.9c-0.3,0-0.4-0.2-0.4-0.4C2.3,5.2,2.5,5,2.8,5s0.7,0.4,0.7,0.7C3.4,5.9,3,5.9,2.8,5.9z
                                             M6.6,5.7C6.6,5.4,7,5,7.2,5c0.3,0,0.4,0.2,0.4,0.4c0,0.3-0.2,0.4-0.4,0.4C7,5.9,6.6,5.9,6.6,5.7z"></path>
                                        </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_DRIV"></span>
                                    </div>
                                </div>
                                <div class="iconRow" dependency="MSTA" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 10 10">
                                            <path class="rect" fill="#576d7b" d="M0 0h10v10H0z"></path>
                                            <path fill="#fff" d="M6.1,8.6c1.4,0,2.5-1.1,2.5-2.5v0c0-1.3-1-2.3-2.3-2.5c0.2,0.3,0.3,0.7,0.4,1
                                            c0.5,0.2,1,0.9,1,1.4C7.7,7,7,7.7,6.1,7.7C5.3,7.7,4.6,7,4.6,6.2l0-0.1c0-0.6,0.3-1.1,0.8-1.4c0,0.1,0.1,0.3,0.1,0.5
                                            C5.4,5.6,5.3,6,5,6.3c0,0.3,0.3,0.6,0.5,0.8c0.4-0.4,0.8-1.1,0.8-1.7l0-0.1c0-1.1-0.8-2.1-1.8-2.4l0.5-1L4.6,1.4H3.2L2.8,1.9l0.5,1
                                            C2.2,3.1,1.4,4.1,1.4,5.2c0,1.4,1.1,2.5,2.5,2.5c0.1,0,0.2,0,0.3,0C4.6,8.2,5.5,8.6,6.1,8.6z M3.7,6.8C2.9,6.7,2.3,6,2.3,5.2
                                            c0-0.9,0.7-1.6,1.6-1.6c0.4,0,0.7,0.1,1,0.4C4.1,4.4,3.7,5.2,3.7,6.1C3.7,6.3,3.7,6.6,3.7,6.8z"></path>
                                        </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_MSTA"></span>
                                    </div>
                                </div>                                
                                <div class="iconRow" dependency="GNDR" style={{display:'none'}}>
                                    <div class="iconSvg">
                                        <svg viewBox="0 0 13.5 13.5">
                                            <path class="rect" fill="#576d7b" d="M0,0h13.5v13.5H0V0z"></path>
                                            <path fill="#fff" d="M8.6,7.6C8.1,7.6,7.9,7.9,7,7.9S5.9,7.6,5.4,7.6c-1.4,0-2.5,1.1-2.5,2.5v0.5c0,0.5,0.4,0.9,0.9,0.9h6.4
                                                c0.5,0,0.9-0.4,0.9-0.9v-0.5C11.1,8.7,10,7.6,8.6,7.6z M9.4,10.3H4.6V10c0-0.7,0.5-1.2,1.2-1.2C6,8.8,6.3,9,7,9c0.7,0,1-0.2,1.2-0.2
                                                c0.7,0,1.2,0.5,1.2,1.2V10.3z M7,7.3c1.5,0,2.6-1.2,2.6-2.6S8.5,2.1,7,2.1S4.4,3.2,4.4,4.7S5.5,7.3,7,7.3z M7,3.2
                                                c0.8,0,1.5,0.7,1.5,1.5S7.8,6.2,7,6.2S5.5,5.5,5.5,4.7S6.2,3.2,7,3.2z"></path>
                                            </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_GNDR"></span>
                                    </div>
                                </div> 
                                <div class="iconRow" dependency="PRTF" style={{display:'none'}}>
                                    <div class="iconSvg new-flds">
                                        <svg width="14px" height="14px" viewBox="0 0 14 14">
                                            <path fill="#576d7b" d="M12.9313286,0 C13.5191405,0 14.0000533,0.480910943 14.0000533,1.0687206 L14.0000533,1.0687206 L14.0000533,12.9312794 C14.0000533,13.5190891 13.5191405,14 12.9313286,14 L12.9313286,14 L1.06872467,14 C0.480912775,14 0,13.5190891 0,12.9312794 L0,12.9312794 L0,1.0687206 C0,0.480910943 0.480912775,0 1.06872467,0 L1.06872467,0 Z M10.5443657,4.00071875 C9.80572964,4.04275233 8.33750709,4.19412181 7.43084358,4.74541774 C7.36847531,4.78332085 7.33325558,4.85086615 7.33325558,4.921813 L7.33325558,4.921813 L7.33325558,9.79479277 C7.33325558,9.94932085 7.50348428,10.0469943 7.64656444,9.9753185 C8.57964274,9.50906161 9.92826495,9.38198899 10.594994,9.3470015 C10.8231885,9.33509604 11.0000209,9.1538414 11.0000209,8.93638444 L11.0000209,8.93638444 L11.0000209,4.41206472 C11.0000209,4.17492729 10.7928604,3.98686953 10.5443657,4.00071875 Z M3.45541641,4.00071875 C3.20716622,3.98686953 3.00000571,4.17492729 3.00000571,4.41206472 L3.00000571,4.41206472 L3.00000571,8.93662741 C3.00000571,9.15408437 3.17683811,9.33533901 3.40429888,9.3470015 C4.0717617,9.38198899 5.42136224,9.50954755 6.35395138,9.97604741 C6.49703154,10.0477232 6.66677108,9.95004976 6.66677108,9.79576465 L6.66677108,9.79576465 L6.66677108,4.91938331 C6.66677108,4.84843646 6.63130677,4.78380679 6.56893849,4.74541774 C5.66251957,4.19412181 4.19405244,4.04275233 3.45541641,4.00071875 Z" id="Combined-Shape"></path>
                                         </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_PRTF"></span>
                                    </div>
                                </div> 
                                <div class="iconRow" dependency="VDCV" style={{display:'none'}}>
                                    <div class="iconSvg new-flds">
                                        <svg width="14px" height="14px" viewBox="0 0 14 14">
                                            <path fill="#576d7b" d="M12.9313286,0 C13.5191405,0 14.0000533,0.480910943 14.0000533,1.0687206 L14.0000533,1.0687206 L14.0000533,12.9312794 C14.0000533,13.5190891 13.5191405,14 12.9313286,14 L12.9313286,14 L1.06872467,14 C0.480912775,14 0,13.5190891 0,12.9312794 L0,12.9312794 L0,1.0687206 C0,0.480910943 0.480912775,0 1.06872467,0 L1.06872467,0 Z M7.66964423,4 L3.66371068,4 C3.29748776,4 3.00000571,4.27833333 3.00000571,4.62222222 L3.00000571,4.62222222 L3.00000571,8.37777778 C3.00000571,8.72111111 3.29748776,9 3.66371068,9 L3.66371068,9 L7.66964423,9 C8.03586715,9 8.3333492,8.72111111 8.3333492,8.37777778 L8.3333492,8.37777778 L8.3333492,4.62222222 C8.3333492,4.27833333 8.03586715,4 7.66964423,4 L7.66964423,4 Z M11.0000209,4.82666667 C11.0000209,4.49611111 10.595872,4.30055556 10.2995752,4.49111111 L10.2995752,4.49111111 L8.77779449,5.475 L8.77779449,7.52444444 L10.2995752,8.50777778 C10.5940942,8.69777778 11.0000209,8.50388889 11.0000209,8.17166667 L11.0000209,8.17166667 Z" id="Combined-Shape"></path>
                                         </svg>
                                    </div>
                                    <div class="icoTxt">
                                        <span id="FIELD_VDCV"></span>
                                    </div>
                                </div>                               
                            </div>
                        </div>
                        
                    </div></div></div></div></div></div><div class="doc-overlay section-overlay" style={{left:'-2px',right:'-2px'}}><span> </span></div></div></div></div><div id="CONTAINER_PARENT_1" class="parentContainer hidefirstpass"><div id="CONTAINER_2"><div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-SUMM"><div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div><div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div></div><div class=""><div class=""><div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  "><div class="clearfix doc-item">
                        <div class="singlecolumn" id="FIELD_FRFM"><p>Customer-focused Retail Sales professional with solid understanding of retail dynamics, marketing and customer service. Offering 5 years of experience providing quality product recommendations and solutions to meet customer needs and exceed expectations. Demonstrated record of exceeding revenue targets by leveraging communication skills and sales expertise.</p></div>
                    </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-HILT"><div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div><div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div></div><div class=""><div class=""><div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  "><div class="clearfix doc-item">
                <div class="singlecolumn maincolumn">
                    <table class="twocol skill">
                        <tbody><tr>
                            <td class="field twocol_1 paddedline" id="FIELD_SKC1"><ul>
  <li>Cash register operation&nbsp;</li>
  <li>POS system operation&nbsp;</li>
  <li>Sales expertise&nbsp;</li>
  </ul></td>
                            <td class="field twocol_2 paddedline" id="FIELD_SKC2"><ul>
  <li>Inventory management&nbsp;</li>
  <li>Accurate money handling&nbsp;</li>
  <li>Documentation and recordkeeping&nbsp;</li>
  </ul></td>
                        </tr>
                    </tbody></table>
                    <div class="multi-para-opt">
                        <div id="FIELD_PTTL" class="txt-bold"></div>
                            <div class="multi-para-content">
                                <div id="FIELD_SKC1"><ul>
  <li>Cash register operation&nbsp;</li>
  <li>POS system operation&nbsp;</li>
  <li>Sales expertise&nbsp;</li>
  </ul></div>
                                <div id="FIELD_SKC2"><ul>
  <li>Inventory management&nbsp;</li>
  <li>Accurate money handling&nbsp;</li>
  <li>Documentation and recordkeeping&nbsp;</li>
  </ul></div>
                            </div>
                    </div>
                </div>
            </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-EXPR"><div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div><div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div></div><div class=""><div class="sortableInner"><div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  "><div class="clearfix doc-item">
                        <div class="singlecolumn" dependency="JTIT|JSTD|EDDT|COMP|JSTA|JCIT|JCNT|JCTR|JDES">
                            <span class="dispBlock" dependency="JTIT|JSTD|EDDT">
								<span class="jobtitle txtCaps txt-bold" id="FIELD_JTIT">Retail Sales Associate</span><span dependency="JTIT"><span dependency="JSTD|EDDT">, </span></span>
                                <span class="jobdates" id="FIELD_JSTD" format="%m/%Y">02/2017</span><span dependency="JSTD+EDDT"> - </span>
                                <span class="jobdates" id="FIELD_EDDT" format="%m/%Y">Current</span>
                            </span>
                            <span class="dispBlock locationGap" dependency="COMP|JSTA|JCIT|JCNT|JCTR">
                                <span class="companyname txt-bold txtItl" id="FIELD_COMP">ZARA</span><span dependency="COMP"><span dependency="JSTA|JCIT|JCNT">, </span></span><span class="jobcity" id="FIELD_JCIT">New Delhi</span><span dependency="JCIT"><span dependency="JSTA|JCNT">, </span></span><span class="jobstate" id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{display:'none'}}>, </span><span class="joblocation jobcountry" id="FIELD_JCNT"></span><span dependency="JCTR" style={{display:'none'}}><span dependency="COMP|JCIT|JSTA|JCNT"> - </span></span><span id="FIELD_JCTR"></span>
                            </span>
                            <span class="jobline" id="FIELD_JDES"><ul>
  <li>Increased monthly sales 10% by effectively upselling and cross-selling products to maximize profitability.&nbsp;</li>
  <li>Prevented store losses by leveraging awareness, attention to detail, and integrity to identify and investigate concerns.&nbsp;</li>
  <li>Processed payments and maintained accurate drawers to meet financial targets.</li>
 </ul></span>
                        </div>
                    </div></div><div id="PARAGRAPH_EXPR_d7533686-f260-ba36-1ef4-823e4a95f9c8" class="paragraph PARAGRAPH_EXPR  "><div class="clearfix doc-item">
                        <div class="singlecolumn" dependency="JTIT|JSTD|EDDT|COMP|JSTA|JCIT|JCNT|JCTR|JDES">
                            <span class="dispBlock" dependency="JTIT|JSTD|EDDT">
								<span class="jobtitle txtCaps txt-bold" id="FIELD_JTIT">Barista</span><span dependency="JTIT"><span dependency="JSTD|EDDT">, </span></span>
                                <span class="jobdates" id="FIELD_JSTD" format="%m/%Y">03/2015</span><span dependency="JSTD+EDDT"> - </span>
                                <span class="jobdates" id="FIELD_EDDT" format="%m/%Y">01/2017</span>
                            </span>
                            <span class="dispBlock locationGap" dependency="COMP|JSTA|JCIT|JCNT|JCTR">
                                <span class="companyname txt-bold txtItl" id="FIELD_COMP">Dunkin’ Donuts</span><span dependency="COMP"><span dependency="JSTA|JCIT|JCNT">, </span></span><span class="jobcity" id="FIELD_JCIT">New Delhi</span><span dependency="JCIT"><span dependency="JSTA|JCNT">, </span></span><span class="jobstate" id="FIELD_JSTA">India</span><span dependency="JSTA+JCNT" style={{display:'none'}}>, </span><span class="joblocation jobcountry" id="FIELD_JCNT"></span><span dependency="JCTR" style={{display:'none'}}><span dependency="COMP|JCIT|JSTA|JCNT"> - </span></span><span id="FIELD_JCTR"></span>
                            </span>
                            <span class="jobline" id="FIELD_JDES"><ul>
  <li>Upsold seasonal drinks and pastries, boosting average store sales by ₹1500 weekly.&nbsp;</li>
  <li>Managed morning rush of over 300 customers daily with efficient, levelheaded customer service.&nbsp;</li>
  <li>Trained entire staff of 15 baristas in new smoothie program offerings and procedures.</li>
 </ul></span>
                        </div>
                    </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-EDUC"><div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div><div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_EDUC">Education and Training<span title=" Education and Training " class="rename-section text-rename"></span></div></div><div class=""><div class=""><div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  "><div class="clearfix doc-item">
                        <div class="singlecolumn">
                            <div dependency="SCIT|SSTA|SCHO|GRYR|GRST|GRED|GRIP|SCNT" class="txtItl">
                                <span class="companyname txt-bold" id="FIELD_SCHO">Oxford Software Institute &amp; Oxford School of English</span><span dependency="SCHO"><span dependency="SCIT|SSTA|SCNT|GRYR|GRST|GRED|GRIP">, </span></span><span class="joblocation jobcity" id="FIELD_SCIT"> New Delhi, India</span><span dependency="SCIT"><span dependency="SSTA|SCNT|GRYR|GRST|GRED|GRIP">, </span></span><span class="joblocation jobstate" id="FIELD_SSTA"></span><span dependency="SSTA" style={{display:'none'}}><span dependency="SCNT|GRYR|GRST|GRED|GRIP">, </span></span><span class="joblocation jobcountry" id="FIELD_SCNT"></span><span dependency="SCNT" style={{display:'none'}}><span dependency="GRYR|GRST|GRED|GRIP">,</span></span>
								<span class="xslt_static_change displayNoneThisField">Expected in </span>
								<span id="FIELD_GRYR" format="%m/%Y">2016</span>
								<span class="jobdates" id="FIELD_GRST" format="%m/%Y"></span><span dependency="GRST+GRED" style={{display:'none'}}> - </span><span class="jobdates" id="FIELD_GRED" format="%m/%Y"></span>
								<span dependency="GRED|GRST" style={{display:'none'}}><span dependency="GRIP" style={{display:'none'}}>-</span></span>
								<span id="FIELD_GRIP"></span>
                            </div>
                            <div class="degreeGap" dependency="DGRE|STUY|GRPA|GRHN">
                                <span class="degree txt-bold" id="FIELD_DGRE">Diploma in Financial Accounting</span><span dependency="DGRE+STUY" style={{display:'none'}}><span class="beforecolonspace"> </span><span>: </span></span><span class="programline txt-bold" id="FIELD_STUY"></span>
								<span class="txt-bold" dependency="GRHN" style={{display:'none'}}><span dependency="DGRE|STUY"> - </span></span><span class="txt-bold" id="FIELD_GRHN"></span>
                                <span dependency="GRPA" style={{display:'none'}}>
                                    <span class="xslt_static_change">GPA</span><span class="beforecolonspace"> </span><span>: </span><span id="FIELD_GRPA"></span>
                                </span>
                            </div>
                            <span id="FIELD_FRFM"></span>
                        </div>
                    </div></div></div></div></div></div></div><div data-react-beautiful-dnd-draggable="19" class="sortable-item section-container SortableItem-sibling  data-LNGG"><div class="document-tool sec-tool" id="editIcons" style={{right:'-2px'}}></div><div id="SECTION_LNGG36369f28-1717-c0d3-39a5-96f0b2c27702" class="section lang-sec infobarsec SECTION_LNGG    has-title  " data-section-cd="LNGG"><div class=" doc-item"><div class="heading"><div class="sectiontitle" id="SECTIONNAME_LNGG">Languages<span title=" Languages " class="rename-section text-rename"></span></div></div><div class=""><div class="sortableInner"><div id="PARAGRAPH_LNGG_20023c30-89ef-3a99-3f68-d7715a46b8f2" class="paragraph paragraph-fieldgroup PARAGRAPH_LNGG firstparagraph  nativeLangPara"><div class="clearfix doc-item">
                        <div class="singlecolumn infobarpara">
                            <div class="fieldgroup-0">
                                <div class="field fielditem-frfm">
                                    <span id="FIELD_FRFM">Hindi</span><span class="colon" dependency="FRFM"><span class="beforecolonspace"> </span>:</span>
                                    <span class="flt-right" id="FIELD_RATG"></span>
                                </div>
                                <div class="rating-bar fielditem-ratv" dependency="RATV" style={{display:'none'}}>
                                    <div class="inner-rating" id="FIELD_RATV" type="width" style={{width:'0%'}}></div>
                                </div>
                                <div class="field field-ratt fielditem-ratt">
                                    <span id="FIELD_RATT">Native speaker</span><span class="colon" dependency="RATT">: </span>
                                </div>
                                <div class="fielditem-olsh txt-bold" dependency="OLSH" style={{display:'none'}}>
                                    <span id="FIELD_OLSH" dependency="OLSH" style={{display:'none'}}></span>
                                </div>
                                <div class="field fielditem-adif">
                                    <span id="FIELD_ADIF"></span>
                                </div>
                            </div>
                        </div>
                        
                    </div></div><div id="PARAGRAPH_LNGG_756f3fd5-3415-d225-25f6-26433cdf5859" class="paragraph paragraph-fieldgroup PARAGRAPH_LNGG  para_odd"><div class="clearfix doc-item">
                        <div class="singlecolumn infobarpara">
                            <div class="fieldgroup-0">
                                <div class="field fielditem-frfm">
                                    <span id="FIELD_FRFM">English</span><span class="colon" dependency="FRFM"><span class="beforecolonspace"> </span>:</span>
                                    <span class="flt-right" id="FIELD_RATG">C2</span>
                                </div>
                                <div class="rating-bar fielditem-ratv" dependency="RATV">
                                    <div class="inner-rating" id="FIELD_RATV" type="width" style={{width: '100%'}}></div>
                                </div>
                                <div class="field field-ratt fielditem-ratt">
                                    <span id="FIELD_RATT">Proficient</span><span class="colon" dependency="RATT">: </span>
                                </div>
                                <div class="fielditem-olsh txt-bold" dependency="OLSH" style={{display:'none'}}>
                                    <span id="FIELD_OLSH" dependency="OLSH" style={{display:'none'}}></span>
                                </div>
                                <div class="field fielditem-adif">
                                    <span id="FIELD_ADIF"></span>
                                </div>
                            </div>
                        </div>
                        
                    </div></div><div id="PARAGRAPH_LNGG_5cf93e39-188c-5232-51b9-f59f27dbcb4d" class="paragraph paragraph-fieldgroup PARAGRAPH_LNGG  para_even"><div class="clearfix doc-item">
                        <div class="singlecolumn infobarpara">
                            <div class="fieldgroup-0">
                                <div class="field fielditem-frfm">
                                    <span id="FIELD_FRFM">Bengali</span><span class="colon" dependency="FRFM"><span class="beforecolonspace"> </span>:</span>
                                    <span class="flt-right" id="FIELD_RATG">B2</span>
                                </div>
                                <div class="rating-bar fielditem-ratv" dependency="RATV">
                                    <div class="inner-rating" id="FIELD_RATV" type="width" style={{width: '66.6667%'}}></div>
                                </div>
                                <div class="field field-ratt fielditem-ratt">
                                    <span id="FIELD_RATT">Upper-intermediate</span><span class="colon" dependency="RATT">: </span>
                                </div>
                                <div class="fielditem-olsh txt-bold" dependency="OLSH" style={{display:'none'}}>
                                    <span id="FIELD_OLSH" dependency="OLSH" style={{display:'none'}}></span>
                                </div>
                                <div class="field fielditem-adif">
                                    <span id="FIELD_ADIF"></span>
                                </div>
                            </div>
                        </div>
                        
                    </div></div></div></div></div></div></div></div></div></div><div></div></div></div><div class="template-overlay"><button id="previewTemplate" type="button" class="btn btn-tertiary" data-index="1">Preview Template</button> <button id="btnSelectTemplate" type="button" class="btn btn-primary  ">Select Template</button></div></li></div>
                  
                    );
}
export default Temp8;