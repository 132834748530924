import React, { Component } from "react";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import './Css/Terms.scss';
import Footer from "./Footer";
import MainNav from "./MainNav";
function Terms() {
  return (
    <div className="terms">

      <MainNav />
      <div>
        <p className="p_privacy">

          <h4 className="blue">Terms and Conditions</h4><br />
          <p>For the purpose of this document GOjobs.ai includes the Website <a href="http://www.GOjobs.ai">www.GOjobs.ai</a>, Companies and Organizations: G O BPO Services Pvt Ltd, Golden Opportunities Pvt Ltd, Yellow Co, Rohin Chawla HUF and various other websites owned and/or managed by them. The names of these entities or any reference to one can be interchanged in this document and will still refer and be considered as any or all of these entities. It also includes parents, directors, subsidiaries, Affiliate organizations, officers, employees, affiliates, agents, vendors, contractors, interns, suppliers, service providers, agents or licensors.</p>
          <p>&nbsp;</p>
          <p>This Terms and Conditions Policy describes under which how our site should be used by the users and what are the terms and conditions of the usage. This policy should be seen and read along with the Privacy Policy and the Cookie Policy of this web site which is published on this site.</p>
          <p>&nbsp;</p>
          <p>Capitalized terms defined in these Terms of Use shall have no other meaning but set forward in this section. The following terminology is applied to these Terms of Use and Privacy Policy: &ldquo;Client&rdquo;, &ldquo;User&rdquo;, &ldquo;You&rdquo; and &ldquo;Your&rdquo; refers to you, the person accessing this Website and accepting these Terms of Use. &ldquo;We&rdquo;, &ldquo;Us&rdquo; and &ldquo;Ourselves&rdquo; refers to Website. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p>
          <p>&nbsp;</p>
          <p>Your access to and use of Website are conditioned on your full acceptance and compliance with these Terms of Use and this Website Privacy Policy, which are published at Website and which are incorporated herein by reference (&ldquo;Terms and Conditions&rdquo;). These Terms and Conditions of Use and Privacy Policy are applied to all visitors, users and others who access or use this Website.</p>
          <p>&nbsp;</p>
          <h4>1.&nbsp;&nbsp; Introduction</h4>
          <p>Welcome to GOjobs.ai, your dedicated career development platform. Our cutting-edge services are designed to empower jobseekers and professionals to excel in their careers. Here's what we offer:</p>
          <ul>
            <li>Resume writing Services- Help you to develop your resume using Artificial Intelligence.</li>
            <li>Resume Analysis Engine: Our AI-driven engine identifies errors and suggests improvements for your resume.</li>
            <li>Job Description Analysis Engine: We extract keywords and skills from job descriptions and resumes, helping you stand out.</li>
            <li>LinkedIn Profile Analysis Engine: Enhance your LinkedIn profile with personalized suggestions for improvement.</li>
            <li>Comprehensive Career Content: Access valuable career advice and insights to navigate the job market.</li>
          </ul>
          <p>&nbsp;</p>
          <p>Our Goal: GOjobs.ai aims to help you create compelling resumes and LinkedIn profiles, leading to more interview opportunities and career growth.</p>
          <p>&nbsp;</p>
          <p><strong>Important Note:</strong></p>
          <p>&nbsp;</p>
          <p>While we strive for excellence, please understand that our services do not guarantee specific results. Also, as the service is based on Artificial Intelligence (AI) it is also known and accepted that AI cannot be 100% accurate. Hence there could be some errors in the resume which gets generated through GOjobs.ai. To resolve this issue, we give you full control to edit, modify the resume which is generated which can be accurate and meet your needs.</p>
          <p>&nbsp;</p>
          <p>We are not a recruitment agency and cannot ensure job offers. Decisions made by employers are beyond our control.</p><br/>
          <h4>2.&nbsp;&nbsp; Account Creation and Responsibilities:</h4>
          <p>By creating an account and utilizing our AI-powered resume writing services, you acknowledge and consent to the following:</p>
          <ul>
            <li>Eligibility for Usage</li>
            <li>You need to be minimum of 18 Years to use our services</li>
            <li>Your use of the Services does not violate any applicable law and/or regulation</li>
            <li>Your profile may be deleted and Services provided to you may be terminated without warning, if we believe that you are less than 18 years of age and/or do not have full legal capacity to enter into legally binding relations.</li>
            <li>Account Security:
              <ul>
                <li>You are solely responsible for maintaining the security of your account and all activities conducted under it.</li>
                <li>To ensure the safety of your private information, resumes, and billing details, please employ a robust password or opt for third-party authentication.</li>
                <li>ai cannot be held liable for any loss or damage arising from non-compliance with security measures.</li>
              </ul>
            </li>
            <li>Your Responsibilities: Account Usage:
              <ul>
                <li>Access to the Service(s) is subject to the restrictions outlined in your subscribed Service Plan.</li>
                <li>Each user must possess unique login credentials (User Login) and refrain from sharing them with external parties.</li>
                <li>Safeguard your data and User Login, and report unauthorized use promptly to mitigate liability.</li>
                <li>Utilize the Service(s) in accordance with relevant laws and ensure its accuracy for your intended purposes.</li>
              </ul>
            </li>
            <li>Service Usage: You agree not to:
              <ul>
                <li>Commercially exploit, sublicense, or distribute the Service(s) to third parties, except where permitted.</li>
                <li>data for third parties, excluding your Users and End-Customers.</li>
                <li>Unauthorized access, modification, or disruption of the Service(s) is prohibited.</li>
                <li>false claims of association or sponsorship with GOjobs.ai.</li>
                <li>Engage in unlawful use, breach privacy rights, or send unsolicited communication.</li>
                <li>Infringe upon intellectual property rights or compromise Service(s) integrity.</li>
                <li>Copy, misuse, or employ the Service(s) maliciously.</li>
                <li>Post harmful or discriminatory content, or misuse the Service(s) for unlawful purposes.</li>
                <li>Transmit malware, viruses, or engage in prohibited activities as outlined in the Terms.</li>
                <li>Establish misleading links to GOjobs.ai's websites or violate applicable laws.</li>
              </ul>
            </li>
          </ul>
          <p>&nbsp;</p>
          <p>Suspension or termination may result from failure to adhere to ethical guidelines and responsibilities.</p>
          <p>&nbsp;</p>
          <ul>
            <li>Content Usage and Liability:
              <ul>
                <li>Users undertake not to create derivative works for commercial purposes without prior written consent from ai</li>
                <li>Prohibits use of the services to upload, post, or transmit unsolicited bulk or commercial emails.</li>
                <li>ai reserves the right to monitor and block emails for spam prevention.</li>
                <li>Spamming, indiscriminate job posting, or mail forwarding may lead to suspension and forfeiture of payments.</li>
                <li>Content or information uploaded must not violate laws, be harmful, threatening, or objectionable.</li>
                <li>Users certify the accuracy of resume/information submitted and indemnify ai for false claims.</li>
                <li>Confidentiality of user password and transactions involving credit/debit cards is the user's sole responsibility.</li>
              </ul>
            </li>
            <li>Legal Compliance and Usage:
              <ul>
                <li>Users commit to complying with the Information Technology Act, 2000, and related laws.</li>
                <li>Users hold sole responsibility for any violations, both civil and criminal, arising from their actions.</li>
                <li>Obtaining necessary licenses, permits, and rights for Service usage is the user's responsibility.</li>
              </ul>
            </li>
            <li>Additional Usage Restrictions:
              <ul>
                <li>Users must not misuse GOjobs.ai to extract content for AI training without written permission.</li>
                <li>Users must not violate local and international laws, impersonate, or harass others.</li>
                <li>Unauthorized access, interference, or exploitation of GOjobs.ai is prohibited.</li>
                <li>Violations can lead to suspension or termination of services.</li>
              </ul>
            </li>
          </ul>
          <p>&nbsp;</p>
          <h4>3.&nbsp;&nbsp; Use to be in conformity of the purpose:</h4>
          <ul>
            <li>Conformity with Purpose:
              <ul>
                <li>The use of GOjobs.ai and related products is exclusively for the intended purpose. Use our Site and/or Services directly and strictly only for the authorised purpose, and not for any other individual or entity;</li>
                <li>Unauthorized copying, sharing passwords, or sublicensing is misuse and may result in action by ai</li>
                <li>Only one individual may access the Website using the same email or password, as stated in the Accounts section above. GOjobs.ai reserves the right, in our sole discretion, to immediately revoke your account and/or seek all other legal remedies available for copying copyrighted text (including the recommendations, resume lines, metrics, or any site content), or otherwise misusing or misappropriating site content, including but not limited to, use on a "mirrored", competitive, or third party site.</li>
              </ul>
            </li>
            <li>Quality and Responses:
              <ul>
                <li>ai is a public platform; responses' quality and authenticity are not guaranteed.</li>
                <li>Users are responsible for verifying the nature of responses they receive.</li>
              </ul>
            </li>
            <li>Permission for Data Usage:
              <ul>
                <li>You grant us permission to use your actions on GOjobs.ai for ads and content without compensation.</li>
                <li>Data is used to provide relevant suggestions and recommendations.</li>
              </ul>
            </li>
            <li>Third-Party Links:
              <ul>
                <li>The platform may include links to third-party websites for convenience.</li>
                <li>Accessing these links is at your own risk; we do not endorse their contents.</li>
              </ul>
            </li>
            <li>Accurate Information:
              <ul>
                <li>Users must provide accurate information and keep profiles up to date.</li>
                <li>not use a Social Media like Facebook or Google, Linkedin account etc. with a fake/false identity (one that is not yourself);</li>
                <li>ai is not liable for inaccuracies or breaches of privacy.</li>
              </ul>
            </li>
            <li>Confidentiality and Privacy:
              <ul>
                <li>ai does not share personally identifiable data apart from what is mentioned in the Privacy Policy of this website.</li>
                <li>Information may be disclosed in response to legal and processes.</li>
                <li>Not permit others to use your account;</li>
                <li>Not use someone else's account(s);</li>
                <li>Not share account(s);</li>
                <li>Not sell, trade, or transfer your our account to another party;</li>
                <li>Not create multiple/duplicate accounts</li>
                <li>You are solely responsible for all the activities performed on/using your account unless you report misuse.</li>
                <li>You must notify us immediately of any breach of security or unauthorized access of your account.</li>
              </ul>
            </li>
            <li>Payment and Refunds:
              <ul>
                <li>Payments are 100% advance; refunds are at ai&rsquo;s discretion.</li>
                <li>ai assumes no liability for refunds, server uptime, or application functionality.</li>
              </ul>
            </li>
            <li>Amendments and Liability:
              <ul>
                <li>ai reserves the right to alter disclaimers and terms without notice.</li>
                <li>Usage limitations are defined by subscription terms.</li>
              </ul>
            </li>
            <li>Set-off and Postings:
              <ul>
                <li>ai can set off amounts payable against other obligations.</li>
                <li>Data may be posted on the website or affiliated sites as deemed fit.</li>
                <li>Poach Agreement:</li>
                <li>Subscription does not confer exclusivity of service.</li>
              </ul>
            </li>
            <li>Spam and Virus Responsibility:
              <ul>
                <li>ai is not responsible for spam or virus-containing links.</li>
              </ul>
            </li>
            <li>Agent Disclaimer:
              <ul>
                <li>ai does not act as an agent for users.</li>
              </ul>
            </li>
            <li>Dispute Resolution:
              <ul>
                <li>Disputes shall be referred to arbitration in Chennai, India.</li>
              </ul>
            </li>
            <li>Governing Law:
              <ul>
                <li>These terms are governed by the laws of the Republic of India.</li>
              </ul>
            </li>
            <li>Termination for Non-Compliance:
              <ul>
                <li>ai may terminate usage rights for non-compliance.</li>
              </ul>
            </li>
            <li>Liability and Taxation:
              <ul>
                <li>ai is not liable for damages and includes GST.</li>
              </ul>
            </li>
            <li>Prohibited usage - In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content:
              <ul>
                <li>Copying / selling resume lines we offer</li>
                <li>for any unlawful purpose;</li>
                <li>to solicit others to perform or participate in any unlawful acts;</li>
                <li>to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances;</li>
                <li>to infringe upon or violate our intellectual property rights or the intellectual property rights of others;</li>
                <li>to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability;</li>
                <li>to submit false or misleading information;</li>
                <li>to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet;</li>
                <li>to collect or track the personal information of others;</li>
                <li>to spam, phish, pharm, pretext, spider, crawl, or scrape;</li>
                <li>for any obscene or immoral purpose; or</li>
                <li>to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet.</li>
                <li>We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses.</li>
                <li>Upload any content that contains nudity or graphic or gratuitous violence or is pornographic or sexually explicit in any way.<br /> </li>
              </ul>
            </li>
          </ul><br/>
          <h4>4.&nbsp;&nbsp; Content and Resume</h4>
          <p>You are solely responsible for all the content you create using our Services. We may review content to determine whether it violates our policies, copyright laws, and we may remove content in those cases. But that does not necessarily mean that we review content, so please don&rsquo;t assume that we do.</p>
          <ul>
            <li>You will have access only to the free plan or the plan you buy.</li>
            <li>In case necessary inputs required by us for commencing the services are not received by us within the term as mentioned in the plan chosen by you then the order can be cancelled and there shall be no refund.</li>
            <li>The User shall certify that the information/data supplied by it to GOjobs.ai is accurate and correct. We shall only use the materials and/or information you provide to us. All information provided must be accurate and up to date. We shall not be liable for any inaccuracies in the Documents produced where such inaccuracies are a result of the materials and/or information you have provided.</li>
            <li>You are solely responsible for any possible consequences and misunderstandings; in case you provide us with inaccurate and/or incorrect and/or unfaithful information.</li>
            <li>Based on the above-mentioned information we would suggest you keywords and content for the Resume. It is your responsibility to see that everything suggested is put in the resume ONLY if it is true and matches with your profile, education, job and other aspects etc.</li>
            <li>All information provided by the user will not be confidential or any information over which the user does not have the ownership rights</li>
            <li>Any information provided to GOjobs.ai should be in public domain and the copyright should be owned by the user or the user will have a right to use that information.</li>
            <li>At no point GOjobs.ai will be held responsible for any information which is provided by the user.</li>
            <li>The User shall give reasonable time to GOjobs.ai for writing of resumes.</li>
            <li>The User must respond to the queries raised by the application immediately to get the results. In case this does not happen then the results may not be as desired or expected.</li>
            <li>The subscription amount once paid for by the Users is not refundable and all amount(s) paid on behalf of the User shall stand appropriated.</li>
            <li>The liability, if any, of GOjobs.ai is limited to the extent of the amount paid by the User.</li>
            <li>ai offers no guarantee nor warranties that there would be a satisfactory response or any response at all once the resume is written and used by the User to apply for jobs.</li>
            <li>ai shall not be held liable for loss of any data technical or otherwise, information, particulars supplied by User due to reasons beyond its control like corruption of data or delay or AI failure or AI malfunction or AI service disruption by the AI provider or failure to perform as a result of any causes or conditions that are beyond GOjobs.ai's reasonable control including but not limited to strikes, riots, civil unrest, Govt. policies, tampering of data by unauthorized persons like hackers, war and natural calamities.</li>
            <li>ai reserves its right to reject any insertion or information/data provided by the User without assigning any reason, but in such an eventuality, any amount so paid for, shall be refunded to the User on a pro-rata basis at the sole discretion of GOjobs.ai.</li>
            <li>We may require you to supply us with personal identifying information, and we may also legally consult other sources to obtain information about you. By accepting these Terms and Conditions, you authorize us to make any inquiries we consider necessary to validate the information that you provide us with. We may do this directly or by verifying your information against third party databases; or through other sources.</li>
            <li>You consent to our processing your personal information for the purposes of providing the Services, including for verification purposes as set out herein. You also consent to the use of such data for communicating with you, for statutory and accounting purposes. You acknowledge that you have read and consented to the Website&rsquo;s Privacy Policy</li>
            <li>ai will commence providing services only upon receipt of amount/charges upfront either from User or from a third party on behalf of the User.</li>
            <li>This subscription is not transferable i.e. it is for the same person throughout the period of subscription.</li>
            <li>The User shall be assigned password(s) by GOjobs.ai to enable the User to access all the information received through GOjobs.ai, but the sole responsibility of the safe custody of the password shall be that of the User and GOjobs.ai shall not be responsible for data loss/theft of data/corruption of data or the wrong usage/misuse of the password and any damage or leak of information and its consequential usage by a third party. GOjobs.ai undertakes to take all reasonable precautions at its end to ensure that there is no leakage/misuse of the password granted to the User.</li>
            <li>The User of these services does not claim any copyright or other Intellectual Property Right over the data uploaded by him/her on the website.</li>
            <li>Content and Images (Visuals) in visual resume are provided by User.</li>
            <li>ai disclaims all warranties against infringement.</li>
            <li>Once you publish the resume there shall be NO revisions. That resume will be considered as final and in case you need to make any revision then it would be considered as new resume.</li>
            <li>We may assign these Terms of Use to any person or entity without your consent. You may not assign these Terms of Use without our prior written consent. If any provision of these Terms of Use is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavor to give effect to the parties&rsquo; intentions as reflected in the provision, and the other provisions of these Terms of Use remain in full force and effect.</li>
          </ul>
          <p>&nbsp;</p>
          <h4>5.&nbsp;&nbsp; Modification to Services</h4>
          <ul>
            <li>Our Service(s): We may update the Service(s) from time to time and You may receive notifications of such upgrades, enhancements or updates (&ldquo;Updates&rdquo;). Any new or modified features added to or augmenting or otherwise modifying the Service(s) or other updates, modifications or enhancements to the Service(s) are also subject to these Terms and We reserve the right to deploy Updates at any time.</li>
            <li>Third party services and network connections: We are not responsible for notifying You of any upgrades, fixes or enhancements to any such services or for any compromise of data, including Your Data, transmitted across computer networks or telecommunications facilities (including but not limited to the internet) which are not owned, operated or controlled by Us. You agree that We are not responsible for the reliability or performance of any services or connections as described in this sub-section.</li>
            <li>Websites: We may also change content on Our Websites at any time. However, please note that any of the content on Our Websites may be out of date at any given time, and We are under no obligation to update it. For clarity, this sub-section refers to Our Websites excluding the Service(s). We may discontinue or change any part of Our Websites, that does not affect the Service(s), without notifying You. Our Websites may contain links to websites, content and resources provided by third parties (&ldquo;Third Party Links&rdquo;). These Third Party Links are governed by their own terms and privacy policies and You agree that We have no control over these Third Party Links and are not responsible for Your access or use of these Third Party Links.</li>
            <li>We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate.</li>
            <li>We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis.</li>
            <li>We reserve the right to limit the quantities of any products or services that we offer.</li>
            <li>All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us.</li>
            <li>We reserve the right to discontinue any product at any time.</li>
            <li>Any offer for any product or service made on this site is void where prohibited.</li>
            <li>We may modify, suspend, disrupt or discontinue your access to the Website, any part of the Website, or the use of the Website, whether to all Users or to you specifically, at any time with or without notice to you. You agree and acknowledge that we will not be liable for any of the aforementioned actions or for any losses or damages that are caused by any of the aforementioned actions. For the removal of any doubt, we may terminate or prevent your use of the Website and any services provided by through the Website (including but not limited to Services) at our sole discretion for any reason and for any period of time.</li>
          </ul>
          <p>&nbsp;</p>
          <h4>6.&nbsp;&nbsp; Termination</h4>
          <p>You agree that GOjobs.ai may terminate your access to the Site and/or Services without notice if:</p>
          <ul>
            <li>ai determines that you have violated these Terms of Service, or</li>
            <li>ai is requested or required to do so by any court of competent jurisdiction or government authority in any country.</li>
          </ul>
          <p>&nbsp;</p>
          <p>You agree that the Site and/or Services are not intended to act as an indefinite information repository and, therefore, GOjobs.ai may, upon such termination, deactivate or delete your account and any related data, information, and files, and bar any further access to such data, information, and files. Such action may include, among other things, accessing your content or data and/or discontinuing your use of the Site and/or Services without refund or compensation.</p>
          <p>&nbsp;</p>
          <p>In case of Termination or deletion request by you &ndash; We delete the account as per the privacy policy</p><br/>
          <h4>7.&nbsp;&nbsp; Payment, Cancellation and Refund Policy</h4>
          <ul>
            <li>In consideration of the Services provided, you should pay us all fees due according to the prices and terms listed on this Website.</li>
            <li>Our services are prepaid. If We did not receive your payment or the payment method was declined, you would forfeit of services.</li>
            <li>All fees are exclusive of all taxes and/or levies, and/or duties imposed by taxing authorities, and You shall be responsible for payment of all such taxes and/or levies, and/or duties. You agree to pay any such taxes that might be applicable to your use of the Services and payments made by you under these Terms of Use.</li>
            <li>If, at any time, you contact your bank or credit card company and decline or otherwise reject the charge of any payment, this act will be considered as a breach of your obligation hereunder and your use of the Services will be automatically terminated.</li>
            <li>In case of any online fraud on your side, appropriate state authorities will be contacted immediately.</li>
            <li>If You initiate a merchant dispute (chargeback) with your credit/debit card issuing bank, you automatically give up all your rights to the Documents. At the same time, when doing a chargeback, you abandon your rights to the Documents. We may sue for compensation for the incurred expenses from the chargeback.</li>
            <li>By providing payment method information and authorizing payments, you warrant that:
              <ul>
                <li>You are legally authorized to provide such information;</li>
                <li>You are legally authorized to make payments using the payment method.</li>
              </ul>
            </li>
            <li>As far as it's allowed by applicable law and according to our Privacy Policy, you acknowledge and agree that we may use third-party service providers to process payments (payment facilitators).</li>
          </ul>
          <p><strong>&nbsp;</strong></p>
          <p><strong>Price Modification</strong></p>
          <ul>
            <li>Prices for our products are subject to change without notice. We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service.</li>
          </ul>
          <p>&nbsp;</p>
          <p><strong>Accuracy of Billing and Accounting information</strong></p>
          <ul>
            <li>We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address.</li>
            <li>In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made.</li>
            <li>We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. You agree to provide current, complete and accurate purchase and account information for all purchases made at our store.</li>
            <li>You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed. For more detail, please review our Returns Policy.</li>
          </ul>
          <p>&nbsp;</p>
          <p><strong>Cancellations/Renewals/Refunds</strong></p>
          <ul>
            <li>If you have opted for subscription mode then at the end of the term as per your plan</li>
            <li>Your payment to GOjobs.ai will automatically renew at the end of the applicable subscription period, unless you cancel your paid subscription before the end of the then-current subscription.</li>
            <li>You can cancel your subscription at any time, and you will continue to have access to the GOjobs.ai service through the end of your subscription period. The cancellation will take effect the day after the last day of the current subscription period and you will be downgraded to the Free Service.</li>
            <li>To cancel, go to the "Account-&gt;Login" page and select the subscription you wish to cancel and proceed by clicking on the Cancel button.</li>
            <li>We are not always able to respond to cancellation requests via email, nor to issue a refund if have not cancelled (unless there is evidence of a technical issue on our side that prevented you from cancelling).</li>
            <li>We have no refund policy. We would strongly recommend that before you buy any service you should take a free trial. You should buy our products and services only of you are fully satisfied and convinced about the product and service.</li>
            <li>Due to the nature of our offering, we are unable to provide refunds to new users who purchase for the first time. The reason is that GOjobs.ai is a Freemium product and you can "inspect the product" and all the site's features in a limited capacity for free, before becoming a paid member (i.e. You get a subset of resume lines for free). Our free tier is extremely generous, and we encourage you to use all features so you know exactly what's in the paid version. To protect ourselves from abuse (i.e. people buying the product, using it to the fullest, and requesting a refund when they are done using it), we can't refund new users. Since you were/are able to inspect what you buy before buying, we can't offer refunds. We appeal any chargebacks aggressively, which means you may have to pay a fine for purchases you made where you used the product but still decided to request a refund.</li>
          </ul>
          <p>&nbsp;</p>
          <h4>8.&nbsp;&nbsp; Software and Technology</h4>
          <p>GOjobs.ai software and technology ("Software") includes the Site and/or Services and is hosted by GOjobs.ai and accessed over the Internet. GOjobs.ai agrees to provide you with a limited, personal, non-exclusive, non-transferable, non-sublicensable, revocable license to use the Software in accordance with these Terms of Service. You may not use the Software for anything other than as intended by GOjobs.ai. You may not use the Software with any device, program or service designed to circumvent technological measures employed to control access to, or the rights in work protected by copyright laws. All rights not expressly granted by GOjobs.ai are hereby reserved. You agree not to take any action to interfere with GOjobs.ai's ownership of or rights in the Software. You agree that, unless otherwise permitted in this section or by law, you will not</p>
          <ul>
            <li>reproduce, republish, display, frame, download, distribute, copy or transmit the Software;</li>
            <li>to the extent permitted under applicable law, redistribute, encumber, sell, rent, lease, loan, sublicense, assign, or otherwise transfer rights to the Software;</li>
            <li>modify or create any derivative works based on the Software, including but not limited to customization, translation, or localization;</li>
            <li>attempt to probe, scan or test the vulnerability of the Software or any associated system or network, or breach any security or authentication feature or measure of the Software;</li>
            <li>copy, reproduce, reuse in another product or service, modify, alter, or display in any manner any software or files, or parts thereof, included as part of the Software;</li>
            <li>except to the extent expressly permitted by law, decompile, disassemble, reverse engineer, or otherwise attempt to access or derive the source code or architecture of the Software, or in any way ascertain, decipher, or obtain the communications protocols for accessing the Software, or the underlying ideas or algorithms of the Software;</li>
            <li>attempt to gain unauthorized access to the Software or to any account, application, platform, computer system or network associated with the Software (and, if you are blocked by GOjobs.ai from accessing the Software, such as if GOjobs.ai blocks your IP address, you will not implement any measure to circumvent such blocking, such as by masking your IP address or using a proxy IP address);</li>
            <li>use the Software in any way that violates these Terms of Service, or any applicable law or regulation.</li>
          </ul><br/>
          <h4>9.&nbsp;&nbsp; Limitations of liability</h4>
          <p>GOjobs.ai does not make any warranty about the reliability of the Services and does not guarantee the security of user data despite best efforts. The Service is provided &ldquo;AS IS&rdquo; and you agree to not hold us responsible nor to seek indemnification for any damages that may arise as a result of the loss of use, data, or profits connected to the performance of the Services or failure in such performance. In no event shall We or our suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption,) arising out of the use or inability to use the materials on Website, even if We or a Our authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to You. The maximum liability if any shall be restricted to the amount of money paid to us. In case you are a free user then there shall be liability.</p>
          <p>&nbsp;</p>
          <h4>10. Accuracy of material</h4>
          <p>Please be aware that Artificial intelligence or AI on which the GOjobs.ai is based is not 100% accurate and is known to have errors.</p>
          <p>&nbsp;</p>
          <p>We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable.</p>
          <p>&nbsp;</p>
          <p>We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk.</p>
          <p>&nbsp;</p>
          <p>The materials appearing on GOjobs.ai website could include factual, content, technical, typographical, or photographic errors. GOjobs.ai does not warrant that any of the materials on its website are accurate, complete or current. GOjobs.ai may make changes to the materials contained on its website at any time without notice. However, GOjobs.ai does not make any commitment to update the materials.</p>
          <p>&nbsp;</p>
          <p>We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated.</p>
          <p>&nbsp;</p>
          <p>To eliminate these errors we give you full rights to modify, edit, add or delete any content in your resume which gets generated after the AI recommendations. Please read, edit and proof read the final resume before publishing or producing the final output.</p>
          <p>&nbsp;</p>
          <h4>11. DISCLAIMER</h4>
          <p>GOJOBS.AI MAKES NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE CONTENT ON THE SITE AND/OR SERVICES , INCLUDING BUT NOT LIMITED TO, RESUME SCORING, SUGGESTED FEEDBACK, SUGGESTED LINES, SUGGESTED METRICS AND OTHER RECOMMENDATIONS GIVEN TO THE USER, AND OR THE CONTENT OF ANY THIRD PARTY SITES LINKED TO THIS SITE AND/OR SERVICES AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES OR INACCURACIES OF CONTENT, (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR SITE AND/OR SERVICES, (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM OUR SITE AND/OR SERVICES, (V) ANY BUGS, VIRUSES, TROJAN HORSES AND/OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR SITE AND/OR SERVICES BY ANY THIRD PARTY, OR (V) ANY LOSS OR DAMAGE TO CONTENT OR DATA (WHETHER STORED THROUGH THE SITE AND/OR SERVICE OR OTHERWISE), OR (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE VIA THE SITE AND/OR SERVICES. GOJOBS.AI DOES NOT WARRANT, ENDORSE, GUARANTEE OR ASSUME RESPONSIBILITY FOR ANY PRODUCT, SERVICE OR OPPORTUNITY ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE AND/OR SERVICES OR ANY HYPER-LINKED SITES AND/OR SERVICES.</p>
          <p>&nbsp;</p>
          <p>YOU ALSO ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE SERVICE(S) OR WEBSITES WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE AND YOU FURTHER ACKNOWLEDGE THAT WE DO NOT WARRANT THAT THE ACCESS TO THE SERVICE(S), WHICH IS PROVIDED OVER INTERNET AND VARIOUS TELECOMMUNICATIONS NETWORKS, ALL OF WHICH ARE BEYOND OUR CONTROL, WILL BE UNINTERRUPTED, TIMELY, SECURE, ERROR-FREE OR FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE.</p>
          <p>&nbsp;</p>
          <p>THE CONTENT ON OUR WEBSITES IS PROVIDED FOR GENERAL INFORMATION ONLY. IT IS NOT INTENDED TO AMOUNT TO ADVICE ON WHICH YOU SHOULD RELY. YOU MUST OBTAIN PROFESSIONAL OR SPECIALIST ADVICE BEFORE TAKING, OR REFRAINING FROM, ANY ACTION ON THE BASIS OF THE CONTENT ON OUR WEBSITES. NO INFORMATION OR ADVICE OBTAINED BY YOU FROM US IS THROUGH.</p>
          <p>&nbsp;</p>
          <p>YOU AGREE THAT USE OF THE SITE AND/OR SERVICES IS ENTIRELY AT YOUR OWN RISK.</p>
          <p>&nbsp;</p>
          <p>THIS AGREEMENT CONSTITUTES THE ENTIRE AGREEMENT BETWEEN YOU AND US. YOU CONFIRM THAT YOU HAVE NOT RELIED UPON ANY PROMISES OR REPRESENTATIONS BY US EXCEPT AS SET FORTH IN THIS AGREEMENT.</p>
          <p>&nbsp;</p>
          <h4>12. Third Party Links</h4>
          <p>Certain content, products and services available via our Service may include materials from third-parties.</p>
          <p>&nbsp;</p>
          <p>We have not reviewed all of the sites linked to its website and are not responsible for the contents of any such linked site. The inclusion of any link does not imply our endorsement of the site. Use of any such linked site is at the user's own risk.</p>
          <p>&nbsp;</p>
          <p>Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties.</p>
          <p>&nbsp;</p>
          <p>We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party.</p><br/>
          <h4>13.Intellectual property rights</h4>
          <p>Ownership of IPR: All rights, title and interest in and to all Our patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights in or related to the Service(s), including the Websites, and any part of it (collectively, &ldquo;Intellectual Property Rights&rdquo;) shall belong to and remain exclusively with Us. We are the owner or the licensee of all Intellectual Property Rights in Our Websites, and the content or material published on it.</p>
          <p>&nbsp;</p>
          <p>Those works are protected by copyright laws and treaties around the world. You must not use any part of the content on Our Websites for commercial purposes without obtaining a license to do so from Us or Our licensors. Further, We claim no intellectual property rights over the content You upload or provide to the Service(s).</p>
          <p>&nbsp;</p>
          <p>Unless You notify Us otherwise by an e-mail to Support@GOjobs.ai, You agree to grant Us a royalty-free, worldwide, transferable license to use Your content, trademark or logo to identify You as Our customer on Our Websites and/or marketing collateral.</p>
          <p>&nbsp;</p>
          <p>Grant of License to You: Our product and service names, and logos used or displayed on the Service(s) or Websites are Our registered or unregistered trademarks (collectively, &ldquo;Marks&rdquo;), and You may only use such Marks to identify You as a user of the Service(s) You have subscribed to.</p>
          <p>&nbsp;</p>
          <p>Reservation of Rights: All rights not expressly provided to You herein are reserved.</p>
          <p>&nbsp;</p>
          <p>When we use the other service providers to provide you with various services or support any trademark of intellectual property which is owned by them remains in their name only. We respect the ownership of such IP.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h4>14. Confidentiality obligations:</h4>
          <p>Each of us will protect the other&rsquo;s Confidential Information from unauthorized use, access or disclosure in the same manner as each of us protects our own Confidential Information, and in any event, no less than reasonable care. Except as otherwise expressly permitted pursuant to these Terms, each of us may use the other&rsquo;s Confidential Information solely to exercise our respective rights and perform our respective obligations under these Terms and shall disclose such Confidential Information solely to those of our respective employees, representatives and agents who have a need to know such Confidential Information for such purposes and who are bound to maintain the confidentiality of, and not misuse, such Confidential Information. The provisions of this sub-section shall supersede any non-disclosure agreement by and between You and Us entered prior to these Terms that would purport to address the confidentiality of Your Data and such agreement shall have no further force or effect with respect to Your Data.</p>
          <p>&nbsp;</p>
          <h4>15.Indemnification</h4>
          <p>You agree to defend, indemnify and hold harmless GOjobs.ai, its parents, subsidiaries, affiliates, and their respective officers, employees and agents from any claim or demand including reasonable attorneys' fees and costs, made by any third party arising out of or related to</p>
          <ul>
            <li>Your use of the Site,</li>
            <li>Your use of any content contained therein,</li>
            <li>Your Content or any information or material that You post to or transmit through the Site,</li>
            <li>Your violation of these Terms of Service, or</li>
            <li>infringement by You or another User using Your computer or account, of any intellectual property or any other right of any person or entity.</li>
          </ul>
          <p>&nbsp;</p>
          <p>GOjobs.ai shall provide notice to You promptly of any such claim, suit or proceeding, and GOjobs.ai reserves the right and shall have the option, at its own expense, to assume the exclusive defence and control of any matter otherwise subject to indemnification by You, and in such case, You agree to cooperate with GOjobs.ai's defence of such claim.</p>
          <p>&nbsp;</p>
          <h4>16. Governing Law</h4>
          <p>These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions. The jurisdiction will be City of Chennai in Tamil Nadu.<br /> <br /> Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service, and supersede and replace any prior agreements we might have between us regarding the Service.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <h4>17.Voice Call</h4>
          <p>You agree and consent to receive all communications at the mobile number provided, even if this mobile number is registered under DND/NCPR list under TRAI regulations. And for that purpose, You further authorize the Company to share/disclose the information to any third party service provider or any affiliates, group companies, their authorized agents or third party service providers.</p>
          <p>&nbsp;</p>
          <h4>18.Assignment; Entire Agreement; Revisions; Waivers Severability</h4>
          <p>You shall not, directly or indirectly, assign all or any Your rights under these Terms or delegate performance of Your duties under these Terms without Our prior written consent. We may, without Your consent, assign Our agreement with You under these Terms to any member of the Group Companies or in connection with any merger or change of Our control or the sale of all or substantially all of Our assets provided that any such successor agrees to fulfil its obligations pursuant to these Terms. Subject to the foregoing restrictions, these Terms will be fully binding upon, inure to the benefit of and be enforceable by the parties and their respective successors and assigns.</p>
          <p>&nbsp;</p>
          <p>These Terms, together with any Form(s), constitute the entire agreement, and supersede any and all prior agreements between You and Us with regard to the subject matter hereof. These Terms and any Form(s) shall prevail over the terms or conditions in any purchase order or other order documentation You or any Entity You represent provides (all such terms or conditions being null and void), and, except as expressly stated herein, there are no other agreements, representations, warranties, or commitments which may be relied upon by either party with respect to the subject matter hereof. In the event of a conflict between any Form and these Terms, these Terms shall prevail.</p>
          <p>&nbsp;</p>
          <p>We may amend these Terms from time to time, in which case the new Terms will supersede prior versions. Please read these Terms of use carefully before You start to use Our Service(s) or Websites, as these will apply to Your use of the Service(s) and Our Websites. Please check these Terms from time to time to take notice of any changes We made, as they will be binding on You. We will notify about these changes on our website by updating the Terms and conditions and Privacy policy. When you use our website please do check for any updates in these before using the services.</p>
          <p>&nbsp;</p>
          <p>The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision.</p>
          <p>&nbsp;</p>
          <p>These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service).</p>
          <p>&nbsp;</p>
          <p>Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party.</p>
          <p>&nbsp;</p>
          <p><strong>Severability</strong></p>
          <p>If any provision in these Terms is held by a court of competent jurisdiction to be unenforceable, such provision shall be modified by the court and interpreted so as to best accomplish the original provision to the fullest extent permitted by applicable law, and the remaining provisions of these Terms shall remain in effect. Our non-exercise of any right under or provision of these Terms does not constitute a waiver of that right or provision of the Terms.</p>
          <p>&nbsp;</p>
          <p><strong>Amendment; No Waiver:</strong></p>
          <p>If you do not agree with a modification to our Terms of Service, you must notify us in writing within seven (7) days after logging in to the site. If you give us this notice, your subscription will continue to be governed by the terms and conditions of our Terms of Service prior to modification for the remainder of your current term. Upon renewal, the Terms of Service published by us on our website will apply.</p>
          <p>&nbsp;</p>
          <p>No delay in exercising any right or remedy or failure to object will be a waiver of such right or remedy or any other right or remedy. A waiver on one occasion will not be a waiver of any right or remedy on any future occasion.</p>
          <p>&nbsp;</p>
          <h4>19.User comments, feedback and other submissions</h4>
          <p>If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, references, reviews and recommendations, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation:</p>
          <ul>
            <li>to maintain any comments in confidence;</li>
            <li>to pay compensation for any comments; or</li>
            <li>to respond to any comments.</li>
          </ul>
          <p>&nbsp;</p>
          <p>We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party&rsquo;s intellectual property or these Terms of Service.</p>
          <p>&nbsp;</p>
          <p>You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website.</p>
          <p>&nbsp;</p>
          <p>You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party.</p>
          <p>&nbsp;</p>
          <h4>20.Questions and Contact Information</h4>
          <p>If you have any inquiries about the Terms and Conditions or the Privacy Policy, please refer to our FAQ page or visit our contact us page for various methods to get in touch with us.</p><br/>
          <h4>21.Consent</h4>
          <p>If you are a registered user or a visitor to the Website, the Company acts as Manager and owner of the website.</p>
          <p>&nbsp;</p>
          <p>By utilizing GOjobs.ai's services and our platform, you affirm your agreement with these Terms and Conditions. Upholding security measures, responsible usage, commercial terms, Privacy Policy and legal compliance contribute to an optimal experience.</p>
          <p>&nbsp;</p>
          <p>Please carefully read and understand these Terms and Conditions before using GOjobs.ai's services. Your agreement and adherence to these terms ensure a positive and compliant experience on our platform.</p>
          <p>&nbsp;</p>
          <p>You are free to delete your account and data any time. However please note that some data will be stored and retained by us as per the law. This data may include but not limited to your billing details, invoices and payments made etc.</p>
          <p>&nbsp;</p>
          <p>We appreciate your trust in GOjobs.ai for your resume writing needs.</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </p>
      </div>


      <Footer />
    </div>

  );
}
export default Terms;

