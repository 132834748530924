import React, { useEffect, useState } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import logo from "../img/logo.png";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import footer_logo from "./Assets/home/footer_logo.png";
import facebook from "./Assets/home/facebook.png";
import instagram from "./Assets/home/instagram.png";
import linkedin from "./Assets/home/linkedin.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import axios from "axios";
function Footer(props) {
  const [formData, setFormData] = useState({
		email: ''
	});
  const handleChange = (event) => {
		const { name, value } = event.target;
		setFormData({ ...formData, [name]: value });
	};
  const handleSubmit = (event) => {
		event.preventDefault();

		if (formData.email == '') {
			toast.error('EmailAddress Required!!!');
		}else {
			const functionThatReturnPromise = () => new Promise((resolve, reject) => {
				axios({
					method: "POST",
					url: commonConfig.REACT_APP_API_URL + "home_mail",
					data: formData,
					headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },

				}).then(res => {
					var decodedData = res.data;
					var status = res.status;
					if (status) {
						resolve(status);
					}

				}).catch(err => {
					reject();
				});
			});

			toast.promise(
				functionThatReturnPromise,
				{
					pending: 'Please Wait...',
					success: 'Thanks For Submitting. Our Team will contact you soon !!!!',
					error: 'Failed 🤯'
				},
			)
		}
	};
	return (
		  <section className="bg_blue center_1" style={{ marginTop: '5%', minHeight: '185px' }}>
      {/* <ToastContainer position="top-center" theme="dark" /> */}
				<div className="col-lg-12 footer_sec">
					<a href="https://www.facebook.com/profile.php?id=100094153024177" target="_blank"><img className="social_image center" src={facebook} /></a> 
					
					{/* <img className="social_image center" src={instagram} /> */}
          <a href="https://www.linkedin.com/company/gojobsai/?viewAsMember=true" target="_blank"><img className="social_image center" src={linkedin} /></a> 
					
				</div>
				<div className="col-lg-12 footer_sec">
          <p className="white footer_nav"><Link to="/home" style={{color:'#fff'}}>Home</Link></p>
          <p className="white footer_nav"><Link to="/plansfront" style={{color:'#fff'}}>Plans</Link></p>
          <p className="white footer_nav"><Link to="/blogs" style={{color:'#fff'}}>Blogs</Link></p>
          <p className="white footer_nav"><Link to="/aboutus" style={{color:'#fff'}}>About Us</Link></p>
          <p className="white footer_nav"><Link to="/faqs" style={{color:'#fff'}}>FAQs</Link></p>
          <p className="white footer_nav"><Link to="/contactus" style={{color:'#fff'}}>Contact Us</Link></p>
				</div>
				<div className="col-lg-12 footer_sec" style={{marginTop:'10px'}}>
          <p className="white footer_nav_1"><Link to="/terms" style={{color:'#fff',fontSize: '13px'}}>Terms & Conditions</Link></p>
          <p className="white footer_nav_1"><Link to="/privacy" style={{color:'#fff',fontSize: '13px'}}>Privacy Policy</Link></p>
          
				</div>
				<div className="col-lg-12 footer_sec" style={{marginTop:'10px'}}>
          <div className="white footer_nav_1 f-11">© 2023 G O BPO Services Pvt Ltd  &nbsp;&nbsp;|&nbsp;&nbsp;  All Rights Reserved</div>
				</div>
				{/* <div className="col-lg-4">
					<div style={{marginLeft: '20%'}}>
						<h4 className="white">Quick Links</h4>
						<p className="white"><Link to="/home" style={{color:'#fff'}}>Home</Link></p>
						<p className="white"><Link to="/pricing" style={{color:'#fff'}}>Plans</Link></p>
						<p className="white">Resources</p>
						<p className="white">About Us</p>
					</div>
				</div>
				<div className="col-lg-4">
					<div className="row" style={{ width: '100%' }}>
          <h4 className="white">Lets Connect</h4>
					</div>
					<div className="row" style={{ width: '100%' }}>
						<div className="col-lg-6">
							<input type="text" name="email" value={formData.email} onChange={handleChange} placeholder="Email Address" className="form-control" style={{ width: '100%' }} />
						</div>
						
						<div className="col-lg-12" style={{ paddingTop: '1%' }}>
							<button onClick={handleSubmit} style={{marginTop: '2%',float: 'left'}} className="btn btn-mangaii">Submit</button>
						</div>
					</div>
				</div> */}
			</section>
	);
}

export default Footer;


