import React, { Component, useEffect, useState, useRef,forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import { FaEdit } from "react-icons/fa";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
const Dynamictemp9 = forwardRef((props, ref) => { 
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState({});
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);
  let resume_id = localStorage.getItem('resume_id');
  const a = ["PHP", "Javascipt", "HTML", "CSS", "Bootstap", "ionic", "Angular", "react", "Laravel", "Codeigniter", "Wordpress"];
  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);


      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);

 

  const printDocument = () => {
    const element = document.getElementById('pdfContainer');

    const opt = {
      margin: [5, 5, 5, 5],
      filename: 'download.pdf',
      image: { type: 'jpeg', quality: 2 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    };

    html2pdf()
      .set(opt)
      .from(element)
      .then(() => {
        const images = element.getElementsByTagName('img');
        const imagePromises = [];

        for (let i = 0; i < images.length; i++) {
          const img = images[i];
          const src = img.getAttribute('src');

          if (src && !src.startsWith('data:image')) {
            const promise = new Promise((resolve, reject) => {
              const xhr = new XMLHttpRequest();
              xhr.open('GET', src);
              xhr.responseType = 'blob';

              xhr.onload = () => {
                if (xhr.status === 200) {
                  const blob = xhr.response;
                  const reader = new FileReader();

                  reader.onloadend = () => {
                    img.src = reader.result;
                    resolve();
                  };

                  reader.readAsDataURL(blob);
                } else {
                  reject();
                }
              };

              xhr.onerror = reject;

              xhr.send();
            });

            imagePromises.push(promise);
          }
        }

        return Promise.all(imagePromises);
      })
      .save();
  };

  const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print:print
  }));
  
  return (
    <div>
      {/* <button onClick={printDocument}>Print</button> */}
      <div id="pdfContainer" style={{padding: '10px'}}>
        {respokData ? (
            <li class="template-preview selected" data-skinid="MLM5" data-skincd="MLM5" title="">
            <div class="selected-badge">
               <div class="fal fa-check icon-badge"></div>
            </div>
            <button type="button" class="btn btn-link preview-btn" aria-label="MLM5 skin preview  selected"></button>
            <div class="render-preview">
               <div class="" tabindex="">
                  <div>
                  </div>
                  <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-mlm5 MLM5  RNA  " docskinwidth="552">
                     <div id="CONTAINER_PARENT_0" class="topSection">
                        <div id="CONTAINER_0">
                           <div data-react-beautiful-dnd-draggable="21" class="sortable-item section-container SortableItem-sibling  data-CNTC">
                              <div class="document-tool sec-tool" id="editIcons" style={{right:'-44px'}}></div>
                              <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section cntcSec notdraggable SECTION_CNTC     " data-section-cd="CNTC">
                                 <div class=" doc-item">
                                    <div class="">
                                       <div class="">
                                          <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                             <div class="clearfix doc-item">
                                                <div class="address" style={{display:'none'}}>
                                                   <span class="innerAdd zipsuffix" dependency="ADDR|STRT|CITY|STAT">
                                                   <span id="FIELD_STRT"></span><span dependency="STRT+CITY|STAT" style={{display:'none'}}>, </span>
                                                   <span id="FIELD_CITY"></span><span dependency="CITY+STAT" style={{display:'none'}}>, </span>
                                                   <span id="FIELD_STAT"></span>
                                                   <span id="FIELD_ZIPC"></span>
                                                   <span id="FIELD_ADDR">New Delhi, India 110034</span>
                                                   </span>
                                                   
                                                   <span class="rnaphonefield"><span dependency="ADDR|STRT|CITY|STAT|ZIPC"><span class="sprtr" dependency="CPHN|EMAI">|</span></span><span class="innerAdd" dependency="CPHN"><span id="FIELD_CPHN">+91 11 5555 3345</span></span><span dependency="CPHN"><span class="sprtr" dependency="EMAI">|</span></span><span class="innerAdd emailFld" dependency="EMAI"><span id="FIELD_EMAI">d.agarwal@sample.in</span></span>
                                                   </span>
                                                   <span dependency="ADDR|STRT|CITY|STAT|ZIPC|HPHN|CPHN|EMAI"><span class="sprtr" dependency="DOB1|NTLY|DRIV|MSTA|GNDR|WEB1|SOCL" style={{display:'none'}}>|</span></span>
                                                   <span dependency="DOB1|NTLY|DRIV|MSTA|AVAI|GNDR|WEB1|SOCL" style={{display:'none'}}>
                                                   <span class="innerAdd" dependency="DOB1" style={{display:'none'}}>
                                                   <span class="txt-bold" preference="DOB1_LABL_1"><span class="xslt_static_change" id="DOCDATAINFO_DOB1">undefined</span><span class="beforecolonspace"> </span><span class="colon">: </span></span><span id="FIELD_DOB1"></span>
                                                   </span>
                                                   <span dependency="DOB1" style={{display:'none'}}><span class="sprtr" dependency="NTLY|DRIV|MSTA|AVAI|GNDR|WEB1|SOCL" style={{display:'none'}}>|</span></span>
                                                   <span class="innerAdd" dependency="NTLY" style={{display:'none'}}>
                                                   <span class="txt-bold" preference="NTLY_LABL_1"><span class="xslt_static_change" id="DOCDATAINFO_NTLY"></span><span class="beforecolonspace"> </span><span class="colon">: </span></span><span id="FIELD_NTLY"></span>
                                                   </span>
                                                   <span dependency="NTLY" style={{display:'none'}}><span class="sprtr" dependency="DRIV|MSTA|AVAI|GNDR|WEB1|SOCL" style={{display:'none'}}>|</span></span>
                                                   <span class="innerAdd" dependency="DRIV" style={{display:'none'}}>
                                                   <span class="txt-bold" preference="DRIV_LABL_1"><span class="xslt_static_change" id="DOCDATAINFO_DRIV">undefined</span><span class="beforecolonspace"> </span><span class="colon">: </span></span><span id="FIELD_DRIV"></span>
                                                   </span>
                                                   <span dependency="DRIV" style={{display:'none'}}><span class="sprtr" dependency="MSTA|AVAI|GNDR|WEB1|SOCL" style={{display:'none'}}>|</span></span>
                                                   <span class="innerAdd" dependency="MSTA" style={{display:'none'}}>
                                                   <span class="txt-bold" preference="MSTA_LABL_1"><span class="xslt_static_change" id="DOCDATAINFO_MSTA"></span><span class="beforecolonspace"> </span><span class="colon">: </span></span><span id="FIELD_MSTA"></span>
                                                   </span>
                                                   <span dependency="MSTA" style={{display:'none'}}><span class="sprtr" dependency="AVAI|GNDR|WEB1|SOCL" style={{display:'none'}}>|</span></span>                                    
                                                   <span class="innerAdd" dependency="AVAI" style={{display:'none'}}>
                                                   <span class="txt-bold" preference="AVAI_LABL_1"><span class="xslt_static_change" id="DOCDATAINFO_AVAI">Other</span><span class="beforecolonspace"> </span><span class="colon">: </span></span><span id="FIELD_AVAI"></span>
                                                   </span>
                                                   <span dependency="AVAI" style={{display:'none'}}><span class="sprtr" dependency="GNDR|WEB1|SOCL" style={{display:'none'}}>|</span></span>
                                                   <span class="innerAdd" dependency="GNDR" style={{display:'none'}}>
                                                   <span preference="GNDR_LABL_1"><span class="xslt_static_change  txt-bold" id="DOCDATAINFO_GNDR">Gender</span><span class="beforecolonspace"> </span><span class="txt-bold colon">: </span></span><span id="FIELD_GNDR"></span>
                                                   </span>
                                                   <span dependency="GNDR" style={{display:'none'}}><span class="sprtr" dependency="WEB1|SOCL" style={{display:'none'}}>|</span></span>
                                                   <span dependency="WEB1" class="innerAdd" style={{display:'none'}}>
                                                   <span preference="WEB1_LABL_1"><span class="xslt_static_change txt-bold" id="DOCDATAINFO_WEB1">Website</span><span class="beforecolonspace"> </span><span class="txt-bold colon">: </span></span>
                                                   <span class="wrd-brk" id="FIELD_WEB1"></span>
                                                   </span> 
                                                   <span class="sprtr" dependency="WEB1+SOCL" style={{display:'none'}}>|</span>
                                                   </span>
                                                </div>
                                                <div class="polyBg">
                                                   <svg viewBox="0 0 622 18">
                                                      <polygon fill="#FFF" fill-opacity="0.6" points="659,-0.2 659,18 -47,18"></polygon>
                                                   </svg>
                                                </div>
                                                <div class="whteBg"></div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div id="CONTAINER_PARENT_1" class="parentContainer">
                        <div id="CONTAINER_1">
                           <div data-react-beautiful-dnd-draggable="22" class="sortable-item section-container SortableItem-sibling  data-NAME">
                              <div class="document-tool sec-tool" id="editIcons" style={{right:'-44px'}}></div>
                              <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section nameSec notdraggable SECTION_NAME     " data-section-cd="NAME">
                                 <div class=" doc-item">
                                    <div class="">
                                       <div class="">
                                          <div id="PARAGRAPH_NAME_c506b585-ce5a-edb1-0b0d-90bb25d5144d" class="paragraph PARAGRAPH_NAME firstparagraph  ">
                                             <div>
                                                <div class="name">
                                                   <span id="FIELD_FNAM">{contact.f_name}</span>
                                                   <span id="FIELD_LNAM">{contact.l_name}</span>
                                                </div>
                                                <div class="resumeTitle" dependency="DCTL" style={{display:'none'}}><span id="FIELD_DCTL"> </span></div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-react-beautiful-dnd-draggable="22" class="sortable-item section-container SortableItem-sibling  data-SUMM">
                              <div class="document-tool sec-tool" id="editIcons" style={{right:'-44px'}}></div>
                              <div id="SECTION_SUMMb31c8680-0f77-40de-87b7-b415abbdb0d3" class="section SECTION_SUMM    has-title  " data-section-cd="SUMM">
                                 <div class=" doc-item">
                                    <div class="heading">
                                       <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div>
                                    </div>
                                    <div class="">
                                       <div class="">
                                          <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                             <div class="clearfix doc-item">
                                                <div class="singlecolumn" id="FIELD_FRFM">
                                                   <p>  {contact.summery}</p>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-react-beautiful-dnd-draggable="22" class="sortable-item section-container SortableItem-sibling  data-HILT">
                              <div class="document-tool sec-tool" id="editIcons" style={{right:'-44px'}}></div>
                              <div id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                                 <div class=" doc-item">
                                    <div class="heading">
                                       <div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div>
                                    </div>
                                    <div class="">
                                       <div class="">
                                          <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                             <div class="clearfix doc-item">
                                                <div class="singlecolumn maincolumn">
                                                   <div class="skill">
                                                      <span class="paddedline" id="FIELD_SKC1">
                                                      <ul>
                                          {JSON.parse(skills[0].skills)?.map((item, index) => (
                                            item && <li key={index}>{item}</li>
                                          ))}
                                        </ul>
                                                      </span>
                                                      <span class="paddedline" id="FIELD_SKC3"></span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div data-react-beautiful-dnd-draggable="22" class="sortable-item section-container SortableItem-sibling  data-EXPR">
                              <div class="document-tool sec-tool" id="editIcons" style={{right:'-44px'}}></div>
                              <div id="SECTION_EXPR106767ef-9f26-4772-aa69-49692b236bad" class="section exprSec SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                                 <div class=" doc-item">
                                    <div class="heading">
                                       <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div>
                                    </div>
                                    {experience?.map((item, index) => (
                              <div className="">
                                    <div class="">
                                       <div class="sortableInner">
                                          <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                             <div class="clearfix doc-item">
                                                <div class="singlecolumn">
                                                   <span class="dispBlk" dependency="JTIT|JSTD|EDDT">
                                                   <span class="txt-bold txtCaps" id="FIELD_JTIT">{item.title}</span>
                                                   <span dependency="JSTD|EDDT">
                                                   <span id="FIELD_JSTD" format="%m/%Y">{item.start_date}</span><span dependency="JSTD+EDDT"> - </span><span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present':item.end_date}</span>
                                                   </span>
                                                   </span>                            
                                                   <span class="dispBlk" dependency="COMP|JSTA|JCIT|JCNT|JCTR">
                                                   <span class="txt-bold" id="FIELD_COMP">{item.employer}</span><span dependency="COMP"><span dependency="JSTA|JCIT|JCNT|JCTR">, </span></span><span class="jobcity" id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span><span class="jobstate" id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{display:'none'}}>, </span><span class="joblocation jobcountry" id="FIELD_JCNT"></span><span dependency="JCTR" style={{display:'none'}}><span dependency="JSTA|JCIT|JCNT"> -</span></span>
                                                   <span id="FIELD_JCTR"></span>
                                                   </span>
                                                   <span id="FIELD_JDES">
                                                      <ul>
                                                      <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                                      </ul>
                                                   </span>
                                                </div>
                                             </div>
                                          </div>
                                          
                                       </div>
                                    </div>
                                 </div>
                                 ))}
                                 </div>
                                 
                              </div>
                           </div>
                           <div data-react-beautiful-dnd-draggable="22" class="sortable-item section-container SortableItem-sibling  data-EDUC">
                              <div class="document-tool sec-tool" id="editIcons" style={{right:'-44px'}}></div>
                              <div id="SECTION_EDUC6481c545-a7e0-477e-ad64-7aa6660f8855" class="section SECTION_EDUC   multi-para has-title  " data-section-cd="EDUC">
                                 <div class=" doc-item">
                                    <div class="heading">
                                       <div class="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " class="rename-section text-rename"></span></div>
                                    </div>

                                   {education?.map((item, index) => (
                             <div class="">
                                       <div class="">
                                          <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                             <div class="clearfix doc-item">
                                                <div class="singlecolumn">
                                                   <span class="dispBlk" dependency="DGRE|STUY|GRHN|GRYR">
                                                   <span class="txt-bold"><span id="FIELD_DGRE">{item.education}</span><span dependency="DGRE"><span dependency="STUY|GRYR|GRHN">, </span></span><span id="FIELD_STUY"></span></span><span dependency="STUY+GRHN" style={{display:'none'}}> - </span><span class="programline" id="FIELD_GRHN"></span><span dependency="GRYR+GRHN|STUY" style={{display:'none'}}>, </span>
                                                   <span dependency="GRYR"><span class="xslt_static_change displayNoneThisField">Expected in </span><span id="FIELD_GRYR" format="%m/%Y">{item.year_of_completion}</span></span>
                                                   </span>
                                                   <span class="dispBlk" dependency="SCHO|SCIT|SSTA|SCNT">
                                                   <span class="txt-bold" id="FIELD_SCHO">{item.university}</span><span dependency="SCHO"><span dependency="SCIT|SSTA|SCNT">, </span></span>
                                                   <span id="FIELD_SCIT"> New Delhi, India</span><span dependency="SCIT+SSTA|SCNT" style={{display:'none'}}>, </span>
                                                   <span id="FIELD_SSTA"></span><span dependency="SSTA+SCNT" style={{display:'none'}}>, </span>
                                                   <span id="FIELD_SCNT"></span>
                                                   </span>
                                                   <span class="dispBlk" dependency="GRST|GRED" style={{display:'none'}}>
                                                   <span class="jobdates" id="FIELD_GRST" format="%B %Y"></span><span dependency="GRST+GRED" style={{display:'none'}}> - </span>
                                                   <span class="jobdates" id="FIELD_GRED" format="%B %Y"></span>
                                                   <span dependency="GRED|GRST" style={{display:'none'}}><span dependency="GRIP" style={{display:'none'}}>-</span></span>
                                                   <span id="FIELD_GRIP"></span>
                                                   </span>
                                                   <span class="dispBlk" dependency="GRPA" style={{display:'none'}}>
                                                   <span>GPA<span class="beforecolonspace"> </span><span class="colon">: </span></span><span id="FIELD_GRPA"></span>
                                                   </span>
                                                   <span id="FIELD_FRFM"></span>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                    ))}
                                 </div>
                                 
                              </div>
                           </div>
                           <div data-react-beautiful-dnd-draggable="22" class="sortable-item section-container SortableItem-sibling  data-LNGG">
                              <div class="document-tool sec-tool" id="editIcons" style={{right:'-44px'}}></div>
                              <div id="SECTION_LNGG36369f28-1717-c0d3-39a5-96f0b2c27702" class="section lang-sec infobarsec SECTION_LNGG    has-title  " data-section-cd="LNGG">
                                 <div class=" doc-item">
                                    <div class="heading">
                                    {JSON.parse(more).language &&<div class="sectiontitle" id="SECTIONNAME_LNGG">Languages<span title=" Languages " class="rename-section text-rename"><ul>
                                          {JSON.parse(more)?.language?.map((item, index) => (
                                            <li key={item.language}>{item.language}</li>
                                          ))}
                                        </ul></span></div>}
                                    </div>
                                    <div class="">
                                       <div class="sortableInner">
                                          <div id="PARAGRAPH_LNGG_20023c30-89ef-3a99-3f68-d7715a46b8f2" class="paragraph PARAGRAPH_LNGG firstparagraph  nativeLangPara">
                                             <div class="clearfix doc-item">
                                                <div class="singlecolumn infobarpara">
                                                   <div class="field">
                                                      <span class="txt-bold" id="FIELD_FRFM">Hindi</span><span class="colon txt-bold" dependency="FRFM"><span class="beforecolonspace"> </span>:</span>
                                                      <span class="flt-right" id="FIELD_RATG"></span>
                                                   </div>
                                                   <div class="rating-bar" dependency="RATV" style={{display:'none'}}>
                                                      <div class="inner-rating" id="FIELD_RATV" type="width" style={{width:'0'}}></div>
                                                   </div>
                                                   <div class="field field-ratt">
                                                      <span id="FIELD_RATT">Native speaker</span>
                                                   </div>
                                                   <div class="field">
                                                      <span id="FIELD_ADIF"></span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div id="PARAGRAPH_LNGG_756f3fd5-3415-d225-25f6-26433cdf5859" class="paragraph PARAGRAPH_LNGG  para_odd">
                                             <div class="clearfix doc-item">
                                                <div class="singlecolumn infobarpara">
                                                   <div class="field">
                                                      <span class="txt-bold" id="FIELD_FRFM">English</span><span class="colon txt-bold" dependency="FRFM"><span class="beforecolonspace"> </span>:</span>
                                                      <span class="flt-right" id="FIELD_RATG">C2</span>
                                                   </div>
                                                   <div class="rating-bar" dependency="RATV">
                                                      <div class="inner-rating" id="FIELD_RATV" type="width" style={{width:'100%'}}></div>
                                                   </div>
                                                   <div class="field field-ratt">
                                                      <span id="FIELD_RATT">Proficient</span>
                                                   </div>
                                                   <div class="field">
                                                      <span id="FIELD_ADIF"></span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                          <div id="PARAGRAPH_LNGG_5cf93e39-188c-5232-51b9-f59f27dbcb4d" class="paragraph PARAGRAPH_LNGG  para_even">
                                             <div class="clearfix doc-item">
                                                <div class="singlecolumn infobarpara">
                                                   <div class="field">
                                                      <span class="txt-bold" id="FIELD_FRFM">Bengali</span><span class="colon txt-bold" dependency="FRFM"><span class="beforecolonspace"> </span>:</span>
                                                      <span class="flt-right" id="FIELD_RATG">B2</span>
                                                   </div>
                                                   <div class="rating-bar" dependency="RATV">
                                                      <div class="inner-rating" id="FIELD_RATV" type="width" style={{width: '66.6667%'}}></div>
                                                   </div>
                                                   <div class="field field-ratt">
                                                      <span id="FIELD_RATT">Upper-intermediate</span>
                                                   </div>
                                                   <div class="field">
                                                      <span id="FIELD_ADIF"></span>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div></div>
               </div>
            </div>
            <div class="template-overlay"><button id="previewTemplate" type="button" class="btn btn-tertiary" data-index="11">Preview Template</button> <button id="btnSelectTemplate" type="button" class="btn btn-primary  ">Select Template</button></div>
         </li>
               ) : (
                <Loading />
              )}
            </div>
          </div>
        );
      });
      export default Dynamictemp9;
