import React, { Component, useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import "./Dynamictemp1.css";
import { FaEdit } from "react-icons/fa";
import google_button from "../img/google_button.png";
import link_button from "../img/link_button.png";
import axios from 'axios';
import { commonConfig } from '../config.js';
import Loading from './Loading';
import html2pdf from 'html2pdf.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import contact_icon from './Assets/icons/contact.png';
import mail_icon from './Assets/icons/mail.png';
import location_icon from './Assets/icons/location.png';
import { saveAs } from 'file-saver'; // for downloading the file
import htmlDocx from 'html-docx-js/dist/html-docx';
import jsPDF from 'jspdf';

const Dynamictemp5 = forwardRef((props, ref) => {
  const [isSummaryHighlighted, setIsSummaryHighlighted] = useState(false);
  const [contact, setContact] = useState({});
  const [more, setMore] = useState({});
  const [education, setEducation] = useState({});
  const [experience, setExperience] = useState({});
  const [skills, setSkills] = useState([]);
  const [skills1, setSkills1] = useState('');
  const [image, setImage] = useState('');
  const [respokData, setRespokData] = useState(false);
  let resume_id = localStorage.getItem('resume_id');
  const [htmlContent, setHtmlContent] = useState('');
  const divRef = useRef(null);
 
  useEffect(() => {
    if (!resume_id) {
      resume_id = 2;
    }
    axios({
      method: "POST",
      url: commonConfig.REACT_APP_API_URL + "finalize",
      data: { resume_id: resume_id },
      headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    }).then(res => {
      var decodedData = res.data;
      const result = decodedData.data;
      // const result = JSON.parse(results);
      setContact(result.contact[0]);
      setMore(result.contact[0].more);
      setEducation(result.education);
      setExperience(result.experience);
      setSkills(result.skills);
      setImage(result.image);
      setRespokData(true);

      setTimeout(() => {
        if (divRef.current) {
          const inputEvent = new InputEvent('input', {
            bubbles: true,
            cancelable: true,
          });
          divRef.current.dispatchEvent(inputEvent);
        }
      }, 500);
      // setSkills1(skills[0].skills);
      console.log(contact);
    }).catch(err => {

    });
  }, []);

  const printDocument = (title, fontsize, spacing, color, resumename, justify) => {
    // const element = document.getElementById('pdfContainer');
    // console.log(htmlContent);
    // const data = {
    //   htmlContent: htmlContent,
    //   resume_id: resume_id,
    //   fontsize: fontsize,
    //   resumename: resumename,
    //   spacing: spacing,
    //   color: color,
    //   justify: justify,
    //   template: 5
    // };
    // console.log('htmlContent', htmlContent);
    // axios({
    //   method: "POST",
    //   url: commonConfig.REACT_APP_API_URL + "generate-pdf",
    //   data: data,
    //   headers: { "Authorization": "Bearer " + commonConfig.API_TOKEN },
    // }).then(res => {
    //   if (title == "save") {
    //     toast.success("Saved Successfully!!!");
    //   }

    // }).catch(err => {

    // });

    const doc = new jsPDF('p', 'pt', 'a4');
    doc.html(divRef.current, {
      callback: function (pdf) {
        pdf.save('download.pdf');
      },
      x: 20,          // Set the x offset for margin
      y: 20,          // Set the y offset for margin
      margin: 10,     // Apply a margin to all sides
      width: 550,     // Adjust content width based on A4 width and desired margins
      windowWidth: 900, // Control scaling based on browser window width
    });
  };

  useImperativeHandle(ref, () => ({
    printDocument: printDocument
  }));
     const print = () => {
    const printableContent = document.getElementById("pdfContainer");
    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  }

  useImperativeHandle(ref, () => ({
    printDocument: printDocument,
    print: print,
    downloadWordDoc: downloadWordDoc,
  }));
  const downloadWordDoc = () => {
    // const content = `
    // `;

    // const blob = new Blob([content], {
    //   type: 'application/msword'
    // });

    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'resume.doc';
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    // URL.revokeObjectURL(url);

    const cssStyles = `
    <style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Nunito+Sans:wght@300&family=Poppins:wght@200;300&family=Roboto:wght@400&display=swap');
        html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td{margin:0;padding:0;border:0;outline:0;vertical-align:baseline;background:transparent}
        body{background:#FFF;line-height:1;text-align:left;font-feature-settings:"liga" 0;-moz-font-feature-settings:"liga" off}
                table{border-collapse:collapse;border-spacing:0;font-size:inherit;color:inherit;width:100%}

        /*START Image treatment for LI*/
                ul,        li{padding:0;list-style-type:disc}
                ul li{margin:0 0 0 16px;padding:0 0 0 3px}
        /*END Image treatment for LI*/
       
        /*Dyanmic BG*/
               .dynamicbg{position:relative}
               .dynamicbg:before{z-index:-1;background-image:url("");background-repeat:no-repeat;background-position: 0 top;background-size:contain;content:'';position:absolute;left:0;top:0;height:100%}
                span.paddedline{display:block}
                span.jobtitle,        span.companyname,        span.degree,        span.programline{font-weight:inherit}
                span.jobtitle,        span.degree,        span.programline{font-family:var(--font-family), sans-serif;}
                span.cdesc{font-style:italic}
                .heading,        .resumeTitle,        .execRsmTitle{clear:both; font-weight:bold}
                .profTitle{text-align:right;font-weight:bold}
                .flt-right{float:right}
                .txt-bold{font-weight:700}
            .heading{margin-bottom:3px}

               {overflow:hidden;word-wrap:break-word;color:#000}
                .name{font-size:18px; line-height:17px; font-weight:700; padding:0; text-align:right;color:var(--theme-color);font-family:var(--font-family), sans-serif;}
                .address,        .address2{position:relative; text-align:left; font-size:0.917em; line-height:1.25em}
                .address2{text-align:right;margin-top:0}
                .table_wrapper{width:100%; margin-top:0}
                table.twocol td{width:50%}
                table.skills th,        table.skills td{width:20%; text-align:center}
                table.skills th{text-decoration:underline}
                table.skills .skillname,        table.skills .skillrating{text-align:left; width:35%}
                table.skills .skillrating{width:20%}
                table.skills .skillyears,        table.skills .skilllast{width:19%}
                table.skills .pad1{width:5%}
                table.skills .pad2,table.skills .pad3{width:1%}
                .lowerborder{padding-top:2px}
                .parent-container{position:relative}
                .parent-container::after{content:"";display:block;clear:both}

        /*New logic for infographic*/
                .lang-sec .singlecolumn{display:none}
                .lang-sec.infobarsec .infobarpara,        .lang-sec.infotilesec .infotilepara{display:block}
        
        /*Infographic*/
                .lang-sec.infobarsec{font-size:0}
                .lang-sec.infobarsec .field *,        .lang-sec.infobarsec .nativeLangPara .field{display:inline} 
                .lang-sec.infobarsec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px;margin-top:0}
                .lang-sec.infobarsec .singlecolumn{margin-left:0!important;padding-left:0;position:relative}
                .lang-sec.infobarsec .para_odd{margin-right:15px}
                .lang-sec.infobarsec .inner-rating{position:relative}
                .lang-sec.infobarsec .rating-bar{background:#d5d6d6;width:100%;clear:both;margin-top:3px}
                .lang-sec.infobarsec .inner-rating{background-color:#576d7b;height:4px;width:60%}
                .lang-sec.infobarsec .paragraph:before{display:none}
                .lang-sec.infobarsec > .paragraph:nth-last-child(1),        .lang-sec.infobarsec > .paragraph:nth-last-child(2){padding-bottom:0!important}
      
                .ratingBar{background:#d5d6d6;width:100%;clear:both;margin-top:3px}
                .innerRating{background-color:#576d7b;height:4px;width:60%}

        /*Infographic Tiles*/
                .lang-sec.infotilesec,        .skli-sec{font-size:0}
                .lang-sec.infotilesec .paragraph,        .skli-sec .paragraph{display:inline-block;vertical-align:top;padding-bottom:5px!important;margin-top:0}
                .lang-sec.infotilesec > .paragraph:nth-last-child(1),        .lang-sec.infotilesec > .paragraph:nth-last-child(2),        .skli-sec > .paragraph:nth-last-child(1),        .skli-sec > .paragraph:nth-last-child(2){padding-bottom:0!important}
                .lang-sec.infotilesec .field *,        .skli-sec .field *,        .lang-sec.infotilesec .nativeLangPara .field{display:inline}
                .lang-sec.infotilesec .singlecolumn,        .skli-sec .singlecolumn{margin-left:0!important}
                .lang-sec.infotilesec .sliced-rect,        .skli-sec .sliced-rect{height:6px;width:100%;margin-top:3px;line-height:0px;clear:both}
                .lang-sec.infotilesec .paragraph.para_odd,        .skli-sec .paragraph.para_odd{margin-right:15px}
                .lang-sec.infotilesec .sliced-rect .sliced-rect-tile,        .skli-sec .sliced-rect .sliced-rect-tile{height:100%;background-color:#d5d6d6;float:left;margin-right:3px}
                .lang-sec.infotilesec .sliced-rect .sliced-rect-tile:last-child,        .skli-sec .sliced-rect .sliced-rect-tile:last-child{margin-right:0}

        /*Rectangular Rating Blocks*/
                .sliced-rect .sliced-rect-tile.ratvfill{background-color:#1D4871}
                .hide-bar .rating-bar,        .hide-bar .sliced-rect,        .hide-colon .colon{display:none!important}
                .hide-bar .field-ratt{display:none}

        /*FIX for FORCEFULLY making left margin ZERO for CL*/
                .sectionCL .paragraph{margin-top:0!important}

        /*Button style*/
                .section.btnlnk{text-align:left;font-size:0}
                .btnlnk .paragraph{display:block;margin-top:5px;line-height:normal}
                .btnlnk .paragraph:first-child{margin-top:0}
                .txtlnk{display:none}
               :not(.show-btn) .btnlnk{display:none}
  
        /*Button SVG*/
                .btnlnk a{display:block;width:100%;height:100%;text-decoration:none;position:relative;text-align:center}
                .btnlnk a:before{content:'';background:#566C7A;left:0;opacity:.15;position:absolute;height:100%;width:100%;z-index:-1;border-radius:22px;-webkit-border-radius:22px;-moz-border-radius:22px;-ms-border-radius:22px}
                .btnlnk svg text{text-anchor:middle;font-weight:bold;text-transform:uppercase;fill:#fff;line-height:14px}
                .btnlnk svg{width:100%;height:100%;margin:auto}

        /* For RBN1 */
                .sectiontitle{font-family:var(--font-family), sans-serif;;font-weight:500;text-transform:uppercase;color: var(--theme-color);}
                .separator-left{float:left;padding-right:15px;text-align:right;position:absolute}
                .separator-main{float:left;padding-left:15px;border-left:1px solid #ddd}
                .separator-main .section:first-child{margin-top:0}
                .separator-left .name,        .separator-left .address{text-align:right;word-break:break-word;word-wrap:break-word}
                .separator-left .address .field{display:block}
                .separator-left .address .spaced{display:inline}
                .namebox span{width:100px;height:100px;background:#000;text-align:center!important;text-transform:uppercase;font-family:var(--font-family), sans-serif;; font-weight:500;font-size:52px; color:var(--theme-color); line-height:70px;margin-bottom:15px; display:inline-block}

                .displayNoneThisField{display:none}

        /*textAlign changes*/
                .sectionTextAlignLeft *{text-align:left!important}
                .sectionTextAlignRight *{text-align:right!important;list-style-position:inside}
                .sectionTextAlignCenter *{text-align:center!important;list-style-position:inside}
                .sectionTextAlignJustify *{text-align:justify!important}

        @media all and (-ms-high-contrast:none)
            {
                    .namebox span{box-sizing:border-box;padding-top:6px}/* IE11 */
        }
   
               ,        table{line-height:var(--line-height)}
               .pagesize{width:532px}
               .fontsize,        .lang-sec.infobarsec .paragraph *,        .lang-sec.infotilesec .paragraph *,        .skli-sec .paragraph *{font-size:var(--font-size)}
               .fontface{font-family:var(--font-family), sans-serif;}
               .vmargins{padding-top:17px; padding-bottom:37px}
               .hmargins{padding-left:40px; padding-right:40px}
                .section{margin-top:9px;position: relative;}
               .RBN1 .separator-main .section{margin-top:9px} /*To use for RN builder*/
                .firstsection,        .SECTION_NAME,        .SECTION_CNTC{margin-top:0}
                .paragraph{margin-top:5px}
                .firstparagraph{margin-top:0}
                .singlecolumn,        .maincolumn{margin-left:0px;width:auto !important}
                .sectiontitle{font-size:16px; line-height:19px;font-weight: bold;}
                .name{font-size:17px;color:var(--theme-color); line-height:26px}
                .thinbottomborder{border-bottom:0px solid}
                .address{font-size:var(--font-size); line-height:var(--line-height)}
                table.skills td{padding-top:10px}
                .resumeTitle{font-size:13px; line-height:var(--line-height)}
                .execRsmTitle{font-size:12px; line-height:var(--line-height)}
                .profTitle{font-size:13px;line-height:var(--line-height);margin-bottom:7px;}
                span.compDescWrap{margin-bottom:2px}
                .separator-left{width:30%}
        /*         .separator-main{margin-left:180px} */
                .separator-main{margin-left:30%}
                .namebox span{font-size:52px;color:var(--theme-color);line-height:70px;width:67px;height:67px}
        /*        .dynamicbg:before{background-image:url({$DYBG});width:612px} */

        /*Infographic*/
                .lang-sec.infobarsec .inner-rating{background-color:var(--theme-color)}
                .lang-sec.infobarsec{padding-left:0px}
                .lang-sec.infobarsec .heading{margin-left:-0px}
                .lang-sec.infobarsec .paragraph{width:192px;max-width:192px}
                .lang-sec .nativeLangPara{width:400px!important;max-width:400px!important}
                .lang-sec,        .skli-sec{padding-left:0px}
        
                .innerRating{background-color:var(--theme-color)}

        /*Infographic Skills*/
                .lang-sec.infotilesec .heading,        .skli-sec .heading{margin-left:-0px}
                .lang-sec.infotilesec .paragraph,        .skli-sec .paragraph{width:192px}

        /*Button*/
        /*        .vmargins.show-btn-gap,       .vmargins.show-btn{padding-top:{$BTNG}px}
               .show-btn-gap .SECTION_CNTC{padding-bottom:{$BTNG}px} */
                .btnlnk .paragraph{width:100px;height:19px;margin-top:5px}
                .section.btnlnk{padding-top:0;margin-top:10px}
                .btnlnk a:before{background:var(--theme-color)}
                .btnlnk svg text{fill:var(--theme-color)}

        /*Finalize Fixes*/
                .skli-sec .sortableInner .sortable-item .paragraph,        .lang-sec .sortableInner .sortable-item .paragraph{max-width:178px;vertical-align:top;width:178px}
        .resume-preview         .skli-sec .sortableInner .sortable-item .paragraph,.resume-preview         .lang-sec .sortableInner .sortable-item + .sortable-item .paragraph{max-width:180px}
                .SECTION_LNGG .sortableInner .sortable-item{display:inline-block;vertical-align:top}
                .SECTION_LNGG .para_odd{margin-right:15px}
                .SECTION_LNGG .emptyBarDiv + .emptyWidthDiv{max-width:178px !important}

        /*.separator-left{left:40px}*/
                .separator-main{width:70%;padding-right: 10px;}
                .parent-container{min-height:562px}

        /*FIX for Re-calculating width of singlecolumn for CL*/
                .sectionCL .singlecolumn{margin-left:0!important; width:100%;font-size:11px}
                .address2{font-size:11px; line-height:16px}
                .sectionDT{margin-top:14px}
        /*textAlign changes*/
                .sectionTextAlignLeft *{text-align:left!important}
                .sectionTextAlignRight *{text-align:right!important;list-style-position:inside}
                .sectionTextAlignCenter *{text-align:center!important;list-style-position:inside}
                .sectionTextAlignJustify *{text-align:justify!important}
        /* Style for Signature */
                .txtleft + .field_sign{text-align:left}
                .txtcenter + .field_sign{text-align:center}
                .txtright + .field_sign{text-align:right}
                .sign-pic img{max-width:100%}
        
        /*Rectangular Rating Blocks*/
                .paragraph .sliced-rect .sliced-rect-tile.ratvfill{background-color:var(--theme-color)}
        /* builder fix */
        .signPic .txtleft+.field_sign{line-height:normal}
        /* ul{
          padding-left: 24px !important;
        } */
         

         .watermark {
      position: fixed;
      top: 50%; /* Adjust as needed */
      left: 50%; /* Adjust as needed */
      transform: translate(-50%, -50%);
      font-size: 44px; /* Adjust font size */
      color: rgba(0, 0, 0, 0.2); /* Adjust color and opacity */
      z-index: -1; /* Place it behind other content */
  }
 .seperator {
  border-left: 1px solid #000;
  margin-left: 0; /* Remove left margin for closer alignment */
}

.section {
  margin-left: 10px; /* Add a little margin to create some space from the separator */
}

.sectiontitle {
  width: 1px; /* Thickness */
  background-color: #8d9ec4; /* Color */
  height: 100%; /* Full height */
  position: relative; /* Positioning context */
  margin: 0; /* Remove auto centering */
}

td {
  vertical-align: top;
  padding: 10px;
}

td:first-child {
  width: 60%;
  padding-right: 20px;
}

td:last-child {
  width: 40%;
  padding-left: 10px; /* Reduce left padding */
}

    </style>
          }
`;
const contentElement = document.getElementById('downloadToWord').innerHTML;
    const contentWithStyles = cssStyles + contentElement;
    const docx = htmlDocx.asBlob(contentWithStyles, { orientation: 'portrait' });
    saveAs(docx, 'resume.docx');
  };

  return (
    <div>
      <div>
        <ToastContainer position="top-center" theme="dark" />
        {respokData ? (
          <>
          <li {...(props.contentNotEditable ? {} : { contentEditable: true })} id="pdfContainer" className="template-preview" ref={divRef} onInput={(e) =>
            setHtmlContent(e.target.innerHTML)} data-skinid="RBN1" data-skincd="RBN1" title="">
            <div className="selected-badge">
              <div className="render-preview">
                <div className="" tabindex="">
                  <div>
                    <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-rbn5 RBN1 clearfix RNA  " docskinwidth="532">
                      <div class="name-contact separator-left">
                        <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section SECTION_NAME   " data-section-cd="NAME">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div class="paragraph PARAGRAPH_NAME firstparagraph  mb175">
                                  <div>
                                    <div class="name" style={{ textAlign: 'left' }}>
                                      <span id="FIELD_FNAM">{contact.f_name}</span><span dependency="FNAM+LNAM"></span> <span id="FIELD_LNAM">{contact.l_name}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section SECTION_CNTC     " data-section-cd="CNTC">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="address" style={{ textAlign: 'left' }}>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg">
                                          <img className="temp_icons" src={location_icon} />
                                        </div>
                                        <span className="ml10">{contact.city}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg"><img className="temp_icons" src={contact_icon} /></div>
                                        <span className="ml10">{contact.mobile}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_EMAI">
                                        <div className="icon_bg"><img className="temp_icons" src={mail_icon} /></div>
                                        <span className="ml10">{contact.email}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: '2rem' }} id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                          <div class=" doc-item">
                            <div class="heading" style={{ textAlign: 'left' }}>
                              <div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn maincolumn">
                                      {skills[0] && <table class="twocol" style={{ textAlign: 'left' }}>
                                        <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                      </table>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="separator-main">
                        <div class="section SECTION_SUMM    has-title mb175 " data-section-cd="SUMM">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn" id="FIELD_FRFM">
                                      <div> {contact.summery}</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="section SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div>
                            </div>
                            {experience?.map((item, index) => (
                              <div class="mb175">
                                <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn">
                                      <span class="paddedline" dependency="JTIT|JSTA|JCIT|JCNT">
                                        <span class="jobtitle" id="FIELD_JTIT" style={{ fontWeight: 'bold' }}>{item.title} {item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>} - {item.employer}</span><span dependency="JTIT"><br dependency="JCIT|JSTA|JCNT" /></span>
                                        <span class="joblocation jobcity" style={{ fontWeight: 'bold' }} id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                        <span class="joblocation jobstate" style={{ fontWeight: 'bold' }} id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span>
                                        <span class="joblocation jobcountry" id="FIELD_JCNT"></span><br dependency="JCIT|JSTA|JCNT" />
                                      </span>
                                      <span class="paddedline" dependency="COMP|JSTD|EDDT">

                                        <span className="jobdates" dependency="JSTD|EDDT">
                                          <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                          <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                        </span>
                                      </span>
                                      <span class="paddedline">
                                        <span class="jobline" id="FIELD_JDES">
                                          <div>
                                            <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                          </div>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {item.achievements != '[]' && item.achievements != '[null]' && (
                                  <div className="singlecolumn maincolumn mb25">
                                    <br />
                                    <ul style={{ fontSize: 'var(--font-size)' }}>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div class="section SECTION_EDUC   multi-para has-title mb175 " data-section-cd="EDUC">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " class="rename-section text-rename"></span></div>
                            </div>
                            {education?.map((item, index) => (
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn">

                                        <span class="paddedline" dependency="SCHO|GRYR">
                                          <span className="companyname" id="FIELD_SCHO">
                                            <span className="degree">
                                              {item.course}&nbsp;
                                              {item.specialization && <span className="degree">,
                                                &nbsp;{item.specialization} &nbsp;
                                              </span>}

                                              {item.university && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                              </span>}
                                              {item.year_of_completion && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                              </span>}
                                            </span>
                                          </span><br />
                                          <span dependency="DGRE"></span>
                                          <span class="datesWrapper" dependency="GRYR">
                                            <span class="xslt_static_change displayNoneThisField">Expected in </span>
                                          </span>
                                        </span>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item.achievements != '[]' && item.achievements != '[null]' && (
                                  <div className="singlecolumn maincolumn mb25">
                                    <br />
                                    <ul style={{ fontSize: 'var(--font-size)' }}>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                          <div
                            data-react-beautiful-dnd-draggable="2"
                            className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                          >

                            <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                              <div className=" doc-item">
                                <div className="heading">
                                  <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                </div>
                                <div className="">
                                  <div className="">
                                    <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                      <div className="clearfix doc-item">
                                        <div className="singlecolumn maincolumn mb75">
                                          {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null && <div>
                                            <b >Languages:</b>
                                            <span className="paddedline " id="FIELD_SKC1">
                                              <ul>
                                                {JSON.parse(more)?.language?.map((item, index) => (
                                                  <li key={item.language}>{item.language}</li>
                                                ))}
                                              </ul>
                                            </span>
                                          </div>}
                                        </div>

                                        {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                          <b>Website & Profiles:</b>
                                          <span className="paddedline " id="FIELD_SKC1">
                                            <div>
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.work && <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.portfolio && <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.profile && <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                                              ))}
                                            </div>
                                          </span>
                                        </div>}

                                        {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                          {JSON.parse(more)?.custom?.map((item, index) => (
                                            <div>
                                              <b>{item.custom_name}</b>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <div>
                                                  {item.ckeditor && <div dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                                </div>
                                              </span>
                                            </div>
                                          ))}
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                    <div></div>
                  </div></div>
              </div>
            </div>
          </li>
          <li {...(props.contentNotEditable ? {} : { contentEditable: true })} id="downloadToWord" className="template-preview" onInput={(e) =>
            setHtmlContent(e.target.innerHTML)} data-skinid="RBN1" data-skincd="RBN1" title="" style={{ padding: '10px',display:'none' }}>
            
             <div className="selected-badge">
              <div className="render-preview">
                <div className="" tabindex="">
                  <div>
                    <div class="document doc-root fontsize fontface vmargins hmargins   pagesize skn-rbn5 RBN1 clearfix RNA  " docskinwidth="532">
                    <table>
                    <tr>
                    <td>
                      <div class="name-contact separator-left">
                        <div id="SECTION_NAME952a5bc1-5ef8-4882-b4d2-26c6e3be147f" class="section SECTION_NAME   " data-section-cd="NAME">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div class="paragraph PARAGRAPH_NAME firstparagraph  mb175">
                                  <div>
                                    <div class="name" style={{ textAlign: 'left' }}>
                                      <span id="FIELD_FNAM">{contact.f_name}</span><span dependency="FNAM+LNAM"></span> <span id="FIELD_LNAM">{contact.l_name}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="SECTION_CNTCc5262a04-bcfb-4d27-9dea-67daf11aa0dc" class="section SECTION_CNTC     " data-section-cd="CNTC">
                          <div class=" doc-item">
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_CNTC_7ab68409-48ac-ae65-6122-d533b7a85654" class="paragraph PARAGRAPH_CNTC firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="address" style={{ textAlign: 'left' }}>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg">
                                          <img className="temp_icons" src={location_icon} />
                                        </div>
                                        <span className="ml10">{contact.city}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_CPHN">
                                        <div className="icon_bg"><img className="temp_icons" src={contact_icon} /></div>
                                        <span className="ml10">{contact.mobile}</span>
                                      </div>
                                      <div className="mb75" id="FIELD_EMAI">
                                        <div className="icon_bg"><img className="temp_icons" src={mail_icon} /></div>
                                        <span className="ml10">{contact.email}</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: '2rem' }} id="SECTION_HILT2623a66d-3a37-48c7-8312-2afde3481844" class="section SECTION_HILT    has-title  " data-section-cd="HILT">
                          <div class=" doc-item">
                            <div class="heading" style={{ textAlign: 'left' }}>
                              <div class="sectiontitle" id="SECTIONNAME_HILT">Skills<span title=" Skills " class="rename-section text-rename"></span></div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_HILT_42010f6e-3132-5d28-c6a3-d9136d6d3f0b" class="paragraph PARAGRAPH_HILT firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn maincolumn">
                                      {skills[0] && <table class="twocol" style={{ textAlign: 'left' }}>
                                        <div dangerouslySetInnerHTML={{ __html: skills[0]?.skills?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                      </table>}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      </td>
                      <td class= "seperator">
                      <div class="separator-main">
                        <div class="section SECTION_SUMM    has-title mb175 " data-section-cd="SUMM">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_SUMM">Summary<span title=" Summary " class="rename-section text-rename"></span></div>
                            </div>
                            <div class="">
                              <div class="">
                                <div id="PARAGRAPH_SUMM_7f9c042d-b5f2-c45e-a414-5c86a97981c3" class="paragraph PARAGRAPH_SUMM firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn" id="FIELD_FRFM">
                                      <div> {contact.summery}</div>         
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="section SECTION_EXPR   multi-para has-title  " data-section-cd="EXPR">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EXPR">Experience<span title=" Experience " class="rename-section text-rename"></span></div>
                            </div>
                            {experience?.map((item, index) => (
                              <div class="mb175">
                                <div id="PARAGRAPH_EXPR_3e3aa949-f856-d1c2-ea5a-6ffc91424457" class="paragraph PARAGRAPH_EXPR firstparagraph  ">
                                  <div class="clearfix doc-item">
                                    <div class="singlecolumn">
                                      <span class="paddedline" dependency="JTIT|JSTA|JCIT|JCNT">
                                        <span class="jobtitle" id="FIELD_JTIT" style={{ fontWeight: 'bold' }}>{item.title} {item.intern == 1 && <span id="FIELD_EDDT"> (INTERNSHIP)</span>} - {item.employer}</span><span dependency="JTIT"><br dependency="JCIT|JSTA|JCNT" /></span>
                                        <span class="joblocation jobcity" style={{ fontWeight: 'bold' }} id="FIELD_JCIT">{item.city}</span><span dependency="JCIT+JSTA|JCNT">, </span>
                                        <span class="joblocation jobstate" style={{ fontWeight: 'bold' }} id="FIELD_JSTA">{item.country}</span><span dependency="JSTA+JCNT" style={{ display: 'none' }}>, </span>
                                        <span class="joblocation jobcountry" id="FIELD_JCNT"></span><br dependency="JCIT|JSTA|JCNT" />
                                      </span>
                                      <span class="paddedline" dependency="COMP|JSTD|EDDT">

                                        <span className="jobdates" dependency="JSTD|EDDT">
                                          <span id="FIELD_JSTD" format="%m/%Y">{new Date(item.start_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span><span dependency="JSTD+EDDT"> - </span>
                                          <span id="FIELD_EDDT" format="%m/%Y">{item.end_date == null ? 'Present' : new Date(item.end_date).toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</span>

                                        </span>
                                      </span>
                                      <span class="paddedline">
                                        <span class="jobline" id="FIELD_JDES">
                                          <div>
                                            <div dangerouslySetInnerHTML={{ __html: item?.GPT_resp?.replace(/\\n/g, '').replace(/\\\//g, '/').replace(/"/g, '') }} />
                                          </div>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {item.achievements != '[]' && item.achievements != '[null]' && (
                                  <div className="singlecolumn maincolumn mb25">
                                    <br />
                                    <ul style={{ fontSize: 'var(--font-size)' }}>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        <div class="section SECTION_EDUC   multi-para has-title mb175 " data-section-cd="EDUC">
                          <div class=" doc-item">
                            <div class="heading">
                              <div class="sectiontitle" id="SECTIONNAME_EDUC">Education<span title=" Education and Training " class="rename-section text-rename"></span></div>
                            </div>
                            {education?.map((item, index) => (
                              <div class="">
                                <div class="">
                                  <div id="PARAGRAPH_EDUC_95eaf4ba-ee3c-27ba-2523-308b7a2a242f" class="paragraph PARAGRAPH_EDUC firstparagraph  ">
                                    <div class="clearfix doc-item">
                                      <div class="singlecolumn">

                                        <span class="paddedline" dependency="SCHO|GRYR">
                                          <span className="companyname" id="FIELD_SCHO">
                                            <span className="degree">
                                              {item.course}&nbsp;
                                              {item.specialization && <span className="degree">,
                                                &nbsp;{item.specialization} &nbsp;
                                              </span>}

                                              {item.university && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y">,&nbsp;{item.university}&nbsp;</span>
                                              </span>}
                                              {item.year_of_completion && <span className="jobdates">
                                                <span className="" id="FIELD_GRYR" format="%m/%Y"> - {item.year_of_completion}</span>
                                              </span>}
                                            </span>
                                          </span><br />
                                          <span dependency="DGRE"></span>
                                          <span class="datesWrapper" dependency="GRYR">
                                            <span class="xslt_static_change displayNoneThisField">Expected in </span>
                                          </span>
                                        </span>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {item.achievements != '[]' && item.achievements != '[null]' && (
                                  <div className="singlecolumn maincolumn mb25">
                                    <br />
                                    <ul style={{ fontSize: 'var(--font-size)' }}>
                                      {JSON.parse(item.achievements)?.map((item1, index) => (
                                        item1 && <li key={index}>{item1}</li>
                                      ))}
                                    </ul>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                        {(JSON.parse(more)?.language[0]?.language != null || JSON.parse(more)?.website[0]?.work != null || JSON.parse(more)?.website[0]?.portfolio != null || JSON.parse(more)?.website[0]?.profile != null || JSON.parse(more)?.custom[0]?.custom_name != null) &&
                          <div
                            data-react-beautiful-dnd-draggable="2"
                            className={`sortable-item section-container SortableItem-sibling data-SUMM `}
                          >

                            <div id="SECTION_HILT25e34365-1ca1-4bee-8cdd-0192198842e6" className="section SECTION_HILT    has-title " data-section-cd="HILT">
                              <div className=" doc-item">
                                <div className="heading">
                                  <div className="sectiontitle" id="SECTIONNAME_HILT">Other Information<span title=" Skills " className="rename-section text-rename"></span></div>
                                </div>
                                <div className="">
                                  <div className="">
                                    <div id="PARAGRAPH_HILT_f728a933-614c-0f8e-b660-9e574801f851" className="paragraph PARAGRAPH_HILT firstparagraph  ">
                                      <div className="clearfix doc-item">
                                        <div className="singlecolumn maincolumn mb75">
                                          {JSON.parse(more).language && JSON.parse(more)?.language[0]?.language != null && <div>
                                            <b >Languages:</b>
                                            <span className="paddedline " id="FIELD_SKC1">
                                              <ul>
                                                {JSON.parse(more)?.language?.map((item, index) => (
                                                  <li key={item.language}>{item.language}</li>
                                                ))}
                                              </ul>
                                            </span>
                                          </div>}
                                        </div>

                                        {(JSON.parse(more).website[0].work || JSON.parse(more).website[0].portfolio || JSON.parse(more).website[0].profile) && <div className="singlecolumn maincolumn mb75">
                                          <b>Website & Profiles:</b>
                                          <span className="paddedline " id="FIELD_SKC1">
                                            <div>
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.work && <div key={item.work}><a href={item.work} target="_blank">{item.work}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.portfolio && <div key={item.portfolio}><a href={item.portfolio} target="_blank">{item.portfolio}</a></div>
                                              ))}
                                              {JSON.parse(more)?.website?.map((item, index) => (
                                                item.profile && <div key={item.profile}><a href={item.profile} target="_blank">{item.profile}</a></div>
                                              ))}
                                            </div>
                                          </span>
                                        </div>}

                                        {JSON.parse(more).language && <div className="singlecolumn maincolumn">
                                          {JSON.parse(more)?.custom?.map((item, index) => (
                                            <div>
                                              <b>{item.custom_name}</b>
                                              <span className="paddedline" id="FIELD_SKC1">
                                                <div>
                                                  {item.ckeditor && <div dangerouslySetInnerHTML={{ __html: item.ckeditor }} />}
                                                </div>
                                              </span>
                                            </div>
                                          ))}
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                      </td>
                      </tr>
                      </table>
                    </div>
                    <div></div>
                  </div></div>
              </div>
            </div>
            </li>
          </>
        ) : (
          <Loading />
        )}
      </div>
    </div >

  );
})
export default Dynamictemp5;
